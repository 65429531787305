<template>
  <va-form>
    <v-card v-for="(item, index) in tableData" :key="index">
      <v-card-text style="width: 97%; margin: 0 auto 1vw">
        <v-row>
          <ul style="padding: 1vw 0.5vw 0.5vw">
            <li>{{ item.name }}</li>
            <li style="list-style: none; font-size: 10px; color: #999">
              {{ item.en_name }}
            </li>
          </ul>
        </v-row>
        <v-row>
          <v-textarea
            filled
            name="input-7-4"
            :label="item.name"
            v-model="item.value"
          ></v-textarea>
        </v-row>
      </v-card-text>
    </v-card>

    <div class="text-center">
      <v-btn
        @click="isSure"
        style="background-color: #42b6c4; margin-right: 50px"
        >保存</v-btn
      >
    </div>
  </va-form>
</template>

<script>
export default {
  data() {
    return {
      tableData: [
        {
          name: "预约说明",
          en_name: "OPPOINTMENT DESCRIPT",
          value:"1"
        },
        {
          name: "预约说明",
          en_name: "OPPOINTMENT DESCRIPT",
          value:"2"
        },
      ],
    };
  },
  async mounted() {},
  methods: {
    isSure() {
    },
  },
};
</script>

<style scoped>
ul li::marker {
  color: #42b6c4;
}
.row + .row {
  margin-top: 0px;
}
.row > .col {
  padding: 0 12px !important;
}
.text-center {
  width: 87%;
  background-color: #fff;
  height: 80px;
  position: fixed;
  bottom: 0px;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>