<template>
  <div>
    <div style="">我是首页</div>
  </div>
</template>


<script>
import moment from "moment";
import { mapState } from "vuex";
export default {
  data() {
    return {
      ip: this.global.url,
      moment,
      party: null,
      informat: null,
      month_data: moment().format("YYYY-MM"),
      CountData: null,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  mounted() {
    // this.getData();
  },
  watch: {},
  filters: {
    keepTwoNum: function(value) {
      value = Number(value);
      return ((value + '').indexOf('.') == -1) ? value : value.toFixed(2);
    }
  },
  methods: {
    detail(val, id) {
      this.$router.push(`${val}/${id}`);
    },
    more(val) {
      this.$router.push(val);
    },
    // 页面关闭之前，触发提示框
    beforeunloadHandler(e) {
      if (e) {
        e = e || window.event;
        if (e) {
          this.$admin.http.get(
            `config-manage/logout_log/?user_id=${this.user.id}&operat_content=关闭网页退出成功&log_type=退出登录`
          );
          e.returnValue = "关闭提示";
        }
        return "关闭提示";
      }
    },
    getData() {
      this.$admin.http
        .get(
          `patient-account/homepage_data/?month=${this.month_data}&hospital_id=${this.user.hospital}`
        )
        .then((res) => {
          this.CountData = res.data;
        });
      this.$store
        .dispatch("cm_medical_trends_config/getList", {
          filter: {
            distinguish: "4",
            hospital_id: this.user.hospital,
          },
          sort: [{ by: "id", desc: true }],
          include: ["article_images"],
        })
        .then((item) => {
          if (item.data.length > 5) {
            this.party = item.data.slice(0, 5);
          } else {
            this.party = item.data;
          }
        });
      this.$store
        .dispatch("health_information/getList", {
          filter: {
            htm_type_id: "2",
          },
          sort: [{ by: "id", desc: true }],
          include: ["health_images"],
        })
        .then((item) => {
          if (item.data.length > 5) {
            this.informat = item.data.slice(0, 5);
          } else {
            this.informat = item.data;
          }
        });
    },
  },
  watch: {
    month_data: {
      deep: true,
      handler: function (newVal) {
        this.$admin.http
          .get(
            `patient-account/homepage_data/?month=${moment(newVal).format(
              "YYYY-MM"
            )}&hospital_id=${this.user.hospital}`
          )
          .then((res) => {
            this.CountData = res.data;
          });
      },
    },
  },
};
</script>
<style scoped>
.title {
  background: #eaecff;
  color: #586afc;
  line-height: 27px;
  margin-bottom: 2rem;
}
.el-input--suffix .el-input__inner {
  height: 4rem;
}

.el-carousel__item h3 {
  font-size: 18px;
  opacity: 1;
  line-height: 40px;
  margin: 0 auto;
  text-align: center;
}

::v-deep .el-carousel__arrow {
  background-color: rgba(0, 0, 0, 0);
}
::v-deep .el-carousel__arrow i {
  font-size: 20px;
  font-weight: 600;
  color: #000;
}
.left {
  width: 49.25%;
  background-color: #fff;
  margin-right: 1.5%;
}
.right {
  width: 49.25%;
  background-color: #fff;
}
ul li::marker {
  color: #42b6c4;
}
.row + .row {
  margin-top: 0px;
}
.row > .col {
  padding: 0 12px !important;
}
.num {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
}
.icon {
  width: 80px;
  height: 80px;
  font-size: 30px;
  color: white;
  text-align: center;
  line-height: 80px;
  background-color: #42b6c4;
}
.detail {
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.price {
  font-size: 30px;
  margin-bottom: 10px;
  line-height: 30px;
  height: 30px;
}
.desc {
  font-size: 14px;
  color: #999;
  text-align: center;
}
.el-card {
  width: 24%;
  margin-bottom: 20px;
}
</style>
