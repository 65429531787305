<!--
 * @Description: 
 * @Author: your name
 * @Date: 2023-04-27 14:54:55
 * @LastEditors: your name
 * @LastEditTime: 2023-05-11 14:23:32
-->
<template>
  <va-form :id="id" :item="item">
    <v-card class="v_card">
      <div style="line-height: 60px">
        <span class="dot_circle"></span><span class="title_con">患者信息</span>
      </div>
      <v-row>
        <v-col sm="4">
          <va-text-input
            label="身份证号"
            source="id_num"
            v-model="form.id_num"
          ></va-text-input>
        </v-col>

        <v-col sm="4">
          <va-text-input
            label="患者姓名"
            source="patient_name"
            v-model="form.patient_name"
          ></va-text-input>
        </v-col>
        <v-col sm="4">
          <va-date-input
            source="planned_admission_time"
            v-model="form.planned_admission_time"
            label="入住时间"
            format="short"
          ></va-date-input>
        </v-col>
      </v-row>
    </v-card>
    <v-card class="v_card">
      <div style="line-height: 60px">
        <span class="dot_circle"></span><span class="title_con">患者信息</span>
      </div>
      <v-row>
        <v-col sm="4">
          <va-select-input
            label="预约医院"
            item-value="codenum"
            reference="bs_hospital"
            :items-per-page="1000"
            source="hospital_code"
            v-model="form.hospital_code"
          ></va-select-input>
        </v-col>
        <v-col sm="4">
          <SelectInput
            item-value="ward_code"
            source="ward_code"
            v-model="form.ward_code"
            reference="hap_inpatient_ward"
            label="预约病区"
            :items-per-page="1000"
            ref="dept"
            :filter="ward_filter"
          ></SelectInput>
        </v-col>
        <v-col sm="4">
          <SelectInput
            item-value="room_num"
            source="room_num"
            v-model="form.room_num"
            reference="hap_inpatient_room"
            label="预约病房"
            :items-per-page="1000"
            ref="dept"
            :filter="room_filter"
          ></SelectInput>
        </v-col>
        <v-col sm="4" @click="handleClick(event)">
          <va-text-input
            label="预约床位"
            source="bed_num"
            v-model="form.bed_num"
          ></va-text-input>
        </v-col>
        <v-col sm="4">
          <va-number-input
            label="计划入住天数"
            source="planned_length_stay"
            v-model="form.planned_length_stay"
          ></va-number-input>
        </v-col>
      </v-row>
      <v-row v-if="isShow">
        <span v-for="(item, index) in bedList" :key="index">
          <v-col sm="1">
            <v-btn @click="bedNumAdd(item)" color="primary">{{
              item.bed_num + isState(item)
            }}</v-btn></v-col
          >
        </span>
      </v-row>
    </v-card>

    <div class="btn_bg">
      <v-btn @click="close" style="color: #42b6c4; margin-right: 50px"
        >取消</v-btn
      >
      <v-btn @click="isSure" color="primary">保存</v-btn>
    </div>
  </va-form>
</template>

<script>
import moment from "moment";
import SelectInput from "@/components/VaCustomSelectInput.vue";
export default {
  props: ["id", "title", "item"],
  components: {
    SelectInput,
  },
  data() {
    return {
      itemState: this.$route.query.type,
      form: {
        hospital_code: null,
        hospital_name: "",
        ward_code: null,
        ward_name: "",
        room_num: null,
        room_name: "",
        is_active: true,
        state: "0",
        bed_num: "",
        id_num: "",
        patient_name: "",
        planned_length_stay: null,
        planned_admission_time: moment().format("YYYY-MM-DD"),
      },
      bedList: [],
    };
  },
  beforeDestroy() {
    this.itemState = null;
  },
  methods: {
    isSure() {
      if (this.itemState == 3) {
        this.form.state = '0';
        this.$store
          .dispatch("hap_bed_apt_record/update", {
            id: this.id,
            data: this.form,
          })
          .then((res) => {
            this.close();
          });
      } else {
        if (this.id) {
          this.$store
            .dispatch("hap_bed_apt_record/update", {
              id: this.id,
              data: this.form,
            })
            .then((res) => {
              this.close();
            });
        } else {
          this.$store
            .dispatch("hap_bed_apt_record/create", {
              data: this.form,
            })
            .then((res) => {
              this.close();
            });
        }
      }

      // this.$store.dispatch("hap_sickbed/create",this.form)
    },
    close() {
      this.$router.push("/hap-bed-apt-record");
    },
    isState(item) {
      if (item.state == 0) {
        return "空位";
      } else if (item.state == 1) {
        return "已预约";
      } else if (item.state == 2) {
        return "已入住";
      }
    },
    bedNumAdd(item) {
      this.form.bed_num = item.bed_num;
    },
    handleClick(event) {
      event.preventDefault();
    },
  },
  computed: {
    ward_filter() {
      if (this.form.hospital_code) {
        return { hospital_code: this.form.hospital_code, is_active: true };
      } else {
        return {};
      }
    },
    room_filter() {
      if (this.form.ward_code) {
        return { ward_code: this.form.ward_code, is_active: true };
      } else {
        return {};
      }
    },
    isShow() {
      if (this.form.room_num) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    "form.hospital_code": {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue) {
          this.$store
            .dispatch("bs_hospital/getList", {
              filter: {
                codenum: newValue,
              },
            })
            .then((res) => {
              this.form.hospital_name = res.data[0].name;
            });
        }
      },
    },
    "form.ward_code": {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue) {
          this.$store
            .dispatch("hap_inpatient_ward/getList", {
              filter: {
                ward_code: newValue,
              },
            })
            .then((res) => {
              this.form.ward_name = res.data[0].name;
            });
        }
      },
    },
    "form.room_num": {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue) {
          this.$store
            .dispatch("hap_inpatient_room/getList", {
              filter: {
                room_num: newValue,
              },
            })
            .then((res) => {
              this.form.room_name = res.data[0].name;
            });

          this.$store
            .dispatch("hap_sickbed/getList", {
              filter: {
                room_num: newValue,
              },
            })
            .then((res) => {
              console.log(res, "res1212121");
              this.bedList = res.data;
              // this.form.room_name = res.data[0].name;
            });
        }
      },
    },
  },
  created() {},
};
</script>
<style scoped>
.btn_bg {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0px;
  padding: 10px 10px;
  background-color: white;
}
.v_card {
  padding: 20px;
  margin-bottom: 20px;
}

.red_sp {
  color: red;
}

.dot_circle {
  display: inline-block;
  background-color: #069cae;
  width: 8px;
  height: 8px;
  margin-right: 10px;
  border-radius: 8px;
  margin-bottom: 2px;
}

.title_con {
  display: inline-block;
  font-size: 20px;
}
</style>