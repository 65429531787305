
<template>
  <div>
    <va-aside-layout :title="asideTitle">
      <bs-extra-group-show v-if="show" :item="item"></bs-extra-group-show>
      <bs-extra-group-form
        v-else
        :id="id"
        width="500px"
        :item="item"
        @saved="onSaved"
      ></bs-extra-group-form>
    </va-aside-layout>
    <v-card :icon="resource.icon" :title="title">
      <va-list
        ref="list"
        :filters="filters"
        :filter="filter"
        disableExport
        disable-global-search
        disable-create-redirect
        @action="onAction"
        :include="['bs_hospital', 'auth_group']"
        :sort-by="['id']"
        :sort-desc="[true]"
      >
        <template v-slot:actions>
          <!-- <va-action-button
            icon="mdi-briefcase-download-outline"
            label="下载模版"
            :href="template_download_url"
            color="green"
            text
          >
          </va-action-button> -->
          <!-- <va-action-button
            icon="mdi-import"
            color="green"
            label="导入"
            @click="on_show_import"
            text
          >
          </va-action-button> -->
          <!-- <va-action-button
            icon="mdi-arrow-collapse-down"
            color="green"
            label="导出"
            text
            @click="on_export_excel"
          >
          </va-action-button> -->
        </template>
        
        <va-data-table
          :fields="fields"
          disable-show-redirect
          disable-clone
          disable-edit-redirect
          @item-action="onAction"
          disable-delete
        >
        <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  class="mr-2"
                  color="red"
                  @click="deleteData(item)"
                  v-bind="attrs"
                  v-on="on"
                  v-if="$admin.can(['delete_extragroup'])"
                >
                mdi-trash-can
                </v-icon>
              </template>
              <span>删除</span>
            </v-tooltip>
          </template>
        </va-data-table>
      </va-list>
      <!-- 导入框 -->
      <v-dialog v-model="dialog" width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">批量导入</span>
          </v-card-title>
          <v-card-text>
            <el-form label-width="80px">
              <el-form-item label="上传文件">
                <el-upload
                  class="upload-demo"
                  action
                  :auto-upload="false"
                  :on-change="handleChange"
                  :file-list="fileList"
                  multiple
                >
                  <el-button
                    type="primary"
                    style="
                      color: white;
                      background-color: #14b2c5;
                      border: #14b2c5 1px solid;
                    "
                    size="small"
                    >点击上传</el-button
                  >
                </el-upload>
              </el-form-item>
            </el-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog = false"> 取消 </v-btn>
            <v-btn color="primary" @click="isSure"> 确认 </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import { exportResource } from "@/util/excel_export";
const static_server_url =
  process.env.VUE_APP_API_URL || "http://localhost:28000/static";
export default {
  props: ["resource", "title"],
  data() {
    return {
      template_download_url: ``,
      // ${static_server_url}static/indications.xls
      filter: {},
      filters: [
      {
          source: "role_name",
          label: "角色名称",
          type: "text",
          alwaysOn: true,
        },
        // {
        //     source: "group_id",
        //     type: "text",
        //     label:'角色名称',
        //     alwaysOn: true,
        //     attributes: { reference: "auth_group" },
        //   },
        // {
        //   source: "hospital_id",
        //   label: "所属医院",
        //   type: "select",
        //   alwaysOn: true,
        //   attributes: { reference: "bs_hospital" },
        //   // attributes: { reference: "auth_system_user.id" },
        // },
      ],
      fileList: [],
      dialog: false,
      fields: [
        { source: "role_code", label: "角色编码" },
        { source: "role_name", label: "角色名称" },
        { source: "is_active", label: "是否启用", type: "boolean" },
        { source: "bs_hospital.name", label: "所属医院" },
        { source: "created_user", label: "创建人" },
        {
          source: "created_at",

          type: "date",
          attributes: { format: "longTime" },
          label: "创建时间",
        },
      ],
      asideTitle: null,
      id: null,
      item: null,
      show: false,
    };
  },
  created() {
    this.getSupper();
  },
  methods: {
    getSupper() {
      let user_data = JSON.parse(
        window.sessionStorage.getItem("user")
      );
      if (user_data.is_superuser) {
        this.filters.push({
          source: "hospital_id",
          label: "所属医院",
          type: "select",
          alwaysOn: true,
          attributes: { reference: "bs_hospital" },
          // attributes: { reference: "auth_system_user.id" },
        });
      }else{
        this.filter = {hospital_id: user_data.hospital}
      }
    },
    async deleteData(item) {
      this.$confirm("此操作将删除该数据，是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$store
            .dispatch("auth_group/delete", {
              id: item.group_id,
            })
            .then((res) => {
              this.onSaved()
            });
        })
        .catch(() => {});
    },
    //导出
    on_export_excel() {
      let params = this.$router.currentRoute.query;
      exportResource(
        this.$admin.http,
        "/base-system/export_group",
        params,
        "角色信息.xlsx"
      );
    },
    //导入
    async isSure() {
      let excel_file = this.fileList[0].raw;
      let formData = new FormData();
      formData.append("excel_file", excel_file);
      // await this.$admin.http.post("/indications/upload/", formData);
      this.dialog = false;
    },
    handleChange(file, fileList) {
      this.fileList = fileList;
    },
    on_show_import() {
      this.fileList = []
      this.dialog = true;
    },
    //导入
    async onAction({ action, title, id, item }) {
      this.asideTitle = title;
      this.id = id;
      this.show = action === "show";
      this.item = item;
      //如果点击的是新增,请求新增的接口
      if (action === "create") {
        this.id = null;
      } else if (action === "edit") {
        this.id = item.id;
      }
    },
    onSaved() {
      this.asideTitle = null;
      this.$refs.list.fetchData();
    },
  },
};
</script>

