<template>
  <div>
    <v-card :id="id">
      <div style="height: 60px; margin-left: 20px; padding-top: 15px">
        <va-text-input
          label="模板名称"
          v-model="data_list.name"
          style="width: 400px"
          source="codenum"
          required
        >
        </va-text-input>
      </div>
      <div style="padding: 20px; margin-top: 8px">
        <div class="first_header">
          <div class="first_header_cell" style="width: 9%">
            <span>星期</span>
          </div>
          <div
            class="first_header_cell"
            v-for="(item, index) of this.data_list.ws_schedule_days.data"
            :key="index"
          >
            <!-- <span >{{item.week_day}}</span> -->
            <span v-if="item.week_day == 1">星期一</span>
            <span v-else-if="item.week_day == 2">星期二</span>
            <span v-else-if="item.week_day == 3">星期三</span>
            <span v-else-if="item.week_day == 4">星期四</span>
            <span v-else-if="item.week_day == 5">星期五</span>
            <span v-else-if="item.week_day == 6">星期六</span>
            <span v-else-if="item.week_day == 7">星期日</span>
          </div>
        </div>
        <div class="first_header sec_header">
          <div class="first_header_cell" style="width: 9%">
            <span>午别</span>
          </div>
          <div
            class="sec_header_cell"
            v-for="(mitem, m) of this.data_list.ws_schedule_days.data"
            :key="m"
          >
            <div
              class="sec_header_title"
              v-for="(nitem, n) of mitem.ws_work_shifts.data"
              :key="n"
            >
              <!-- <input style="width:100%;height:100%;line-height:38px;text-align: center;outline: none;" value="44"> -->
              {{ nitem.name }}
            </div>
          </div>
        </div>
        <div class="first_header sec_header" style="margin-top: -2px">
          <div class="first_header_cell" style="width: 9%">
            <span>号源量</span>
          </div>
          <div
            class="sec_header_cell"
            v-for="(mitem, m) of this.data_list.ws_schedule_days.data"
            :key="m"
          >
            <div
              class="sec_header_title"
              v-for="(nitem, n) of mitem.ws_work_shifts.data"
              :key="n"
            >
              <div v-if="nitem.is_active">
                {{
                  nitem.is_active == true
                    ? (nitem.num_count = 0)
                    : (nitem.num_count = null)
                }}
              </div>
              <!-- <div v-else-if="!nitem.is_active" class="input">
                {{
                  nitem.is_active == false
                    ? (nitem.num_count = null)
                    : (nitem.num_count = 0)
                }}
              </div> -->
              <input
                v-model="nitem.num_count"
                style="
                  width: 100%;
                  height: 100%;
                  line-height: 38px;
                  text-align: center;
                  outline: none;
                "
              />
            </div>
          </div>
        </div>
        <div class="first_header sec_header" style="margin-top: -2px">
          <div class="first_header_cell" style="width: 9%">
            <span>开始时间</span>
          </div>
          <div
            class="sec_header_cell"
            v-for="(mitem, m) of this.data_list.ws_schedule_days.data"
            :key="m"
          >
            <div
              class="sec_header_title"
              v-for="(nitem, n) of mitem.ws_work_shifts.data"
              :key="n"
            >
              <template v-if="nitem.is_active">
                <el-time-picker
                  disabled
                  style="width: 100%"
                  id="time"
                  v-model="nitem.start_time"
                  format="HH:mm"
                  value-format="HH:mm"
                  :clearable="false"
                >
                </el-time-picker>
              </template>
              <template v-else>
                <el-time-picker
                  style="width: 100%"
                  id="time"
                  v-model="nitem.start_time"
                  format="HH:mm"
                  value-format="HH:mm"
                  :clearable="false"
                >
                </el-time-picker>
              </template>
            </div>
          </div>
        </div>
        <div class="first_header sec_header" style="margin-top: -3px">
          <div class="first_header_cell" style="width: 9%">
            <span>结束时间</span>
          </div>
          <div
            class="sec_header_cell"
            v-for="(mitem, m) of this.data_list.ws_schedule_days.data"
            :key="m"
          >
            <div
              class="sec_header_title"
              v-for="(nitem, n) of mitem.ws_work_shifts.data"
              :key="n"
            >
              <template v-if="nitem.is_active">
                <el-time-picker
                  disabled
                  style="width: 100%"
                  id="time_0"
                  v-model="nitem.end_time"
                  format="HH:mm"
                  value-format="HH:mm"
                >
                </el-time-picker>
              </template>
              <template v-else>
                <el-time-picker
                  style="width: 100%"
                  id="time_0"
                  v-model="nitem.end_time"
                  format="HH:mm"
                  value-format="HH:mm"
                >
                </el-time-picker>
              </template>
            </div>
          </div>
        </div>
        <div class="first_header sec_header" style="margin-top: -3px">
          <div class="first_header_cell" style="width: 9%">
            <span>是否休息</span>
          </div>
          <div
            class="sec_header_cell"
            v-for="(mitem, m) of this.data_list.ws_schedule_days.data"
            :key="m"
          >
            <div
              class="sec_header_title"
              v-for="(nitem, n) of mitem.ws_work_shifts.data"
              :key="n"
            >
              <!-- <el-time-select style="width:100%"
                    v-model="nitem.end_time"
                    >
                  </el-time-select> -->
              <el-checkbox v-model="nitem.is_active">休息</el-checkbox>
            </div>
          </div>
        </div>
      </div>

      <div class="btn_bg">
        <v-btn @click="isSure" color="primary">保存</v-btn>
        <v-btn @click="notSure" style="color: #14b2c5; margin-left: 50px"
          >取消</v-btn
        >
      </div>
    </v-card>
  </div>
</template>

<script>
import { log } from "console";
export default {
  props: ["id"],
  data() {
    return {
      data_list: {},
    };
  },
  mounted() {
    this.getdatas();
    // this.get_data();
  },
  methods: {
    //前端排序方法
    compare(property) {
      return function (a, b) {
        var value1 = a[property];
        var value2 = b[property];
        return value1 - value2;
      };
    },

    // async get_data(){

    // },
    async getdatas() {
      if (this.id) {
        await this.$admin.http
          .get("work-scheduling/worksche/" + this.id + "/")
          .then((res) => {
            if (res.data) {
              this.data_list = res.data;
            }
          });
      } else {
        let ws_work_shift_types = [];
        await this.$admin.http
          .get("work-scheduling/ws_work_shift_type/")
          .then((res) => {
            if (res.data) {
              //新增创建数据
              ws_work_shift_types = res.data.sort(this.compare("id"));
              let ws_schedule_days = [];
              for (let i = 0; i < 7; i++) {
                let ws_schedule_day = {
                  // created_time: "",
                  is_active: true,
                  // updated_time: "",
                  week_day: i + 1,
                  ws_work_shifts: {
                    data: [],
                  },
                };
                for (let j = 0; j < ws_work_shift_types.length; j++) {
                  let ws_work_shift = {
                    // created_time: "",
                    end_time: null,
                    is_active: false,
                    name: ws_work_shift_types[j].name,
                    num_count: null,
                    start_time: null,
                    // updated_time: "",
                    ws_type_id: ws_work_shift_types[j].id,
                  };
                  ws_schedule_day.ws_work_shifts.data.push(ws_work_shift);
                }
                ws_schedule_days.push(ws_schedule_day);
              }
              this.data_list = {
                // created_time: "",
                hospital_id: JSON.parse(window.sessionStorage.getItem("user")).hospital,
                is_active: true,
                name: "",
                office: "",
                updated_by: null,
                // updated_time: "",
                ws_schedule_days: {
                  data: [],
                },
              };
              this.data_list.ws_schedule_days.data = ws_schedule_days;
              this.data_list = JSON.parse(JSON.stringify(this.data_list));
            }
          });
      }
    },
    async notSure() {
      this.$router.push(
        "/ws-schedule-day" //跳转
      );
    },
    async isSure() {
      if (this.id) {
        await this.$store.dispatch("ws_work_scheduling_main/update", {
          id: this.id,
          data: JSON.parse(JSON.stringify(this.data_list)),
        });
        let data_data = JSON.parse(JSON.stringify(this.data_list));
        let data = [];
        data_data.ws_schedule_days.data.forEach((item) => {
          item.ws_work_shifts.data.forEach((res) => {
            data.push(res);
          });
        });
        // console.log(JSON.stringify(data));
        this.$admin.http
          .post(`work-scheduling/work_shifts/change/`, data)
          .then((res) => {
            this.$message({
              message: "修改成功",
              type: "success",
            });
            this.notSure();
          })
          .catch((err) => {
            this.$message({
              message: "修改失败",
              type: "warning",
            });
          });
      } else {
        try {
          await this.$store
            .dispatch("ws_work_scheduling_main/create", {
              data: JSON.parse(JSON.stringify(this.data_list)),
            })
            .then((res) => {
              this.notSure();
            });
        } catch {
          this.$message({
            message: "添加失败",
            type: "warning",
          });
        }
      }
    },
  },
};
</script>
<style scoped>
.input {
  outline: none;
}
.first_header {
  display: flex;
  width: 100%;
  margin: 0 auto;
  border: 0.5px solid #f8f8f8;
}

.first_header_cell {
  padding: 5px 10px;
  width: 13%;
  height: 40px;
  line-height: 30px;
  text-align: center;
  border: 0.5px solid #f8f8f8;
}

.sec_header_cell {
  display: flex;
  width: 13%;
  height: 40px;
  text-align: center;
}

.sec_header_title {
  width: 33.33%;
  line-height: 38px;
  border: 1px solid #f8f8f8;
}

.sec_header {
  margin-top: -2px;
}

.el-input--suffix .el-input__inner {
  padding-right: 0 !important;
}

.el-icon-circle-close,
.el-input__prefix {
  display: none !important;
}

.btn_bg {
  width: 87%;
  position: fixed;
  bottom: 0px;
  padding: 10px 0;
  text-align: center;
  background-color: white;
  z-index: 99;
}
.el-input--suffix .el-input__inner {
  padding-left: 12% !important;
}
.el-date-editor /deep/ .el-input__prefix {
  display: none !important;
}
.el-input--prefix /deep/ .el-input__inner {
  text-align: center;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
</style>

