<template>
	<div>
		<keep-alive>
			<router-view v-if="isRouterAlive" />
		</keep-alive>
		<!-- 系统提醒 -->
		<div class="systemMessage" v-if="systemMessage">
			<div class="systemMessageTitle">
				<span>系统消息({{ systemMessages.length }})</span>
				<i class="el-icon-close" @click="systemMessage = false"></i>
			</div>
			<div class="systemMessageItemBox">
				<div class="systemMessageItem" v-for="(item, index) in systemMessages" :key="index"
					@click="status_true(item.id)">
					<div class="systemMessageContent">
						<div class="content">
							{{ item.from_user }}通知您{{ item.content }}
						</div>
						<div class="data">
							<span>{{ item.inform_type }}</span>
							<span>{{
                item.created_at | dateFmt("YYYY-MM-DD HH:mm:ss")
              }}</span>
						</div>
					</div>
				</div>
			</div>
			<div class="systemMessageOperation">
				<div @click="closeAll">忽略全部</div>
				<div @click="showAll">查看全部</div>
			</div>
		</div>

		<el-dialog :close-on-click-modal="false" :show-close="false" :visible.sync="centerDialogVisible"
			:close-on-press-escape="false" title="修改密码" center width="25%">
			<el-form :model="passwordForm" :rules="rules" ref="passwordForm" @submit.prevent="changePassword">
				<el-form-item label="" prop="origin_password">
					<el-input v-model="passwordForm.origin_password" :type="type" placeholder="请输入当前密码">
						<i slot="suffix" class="icon-style" :class="elIcon" autocomplete="auto" @click="flag = !flag" />
					</el-input>
				</el-form-item>
				<el-form-item label="" prop="password">
					<el-input v-model="passwordForm.password" :type="type1" placeholder="请输入新密码">
						<i slot="suffix" class="icon-style" :class="elIcon1" autocomplete="auto"
							@click="flag1 = !flag1" />
					</el-input>
				</el-form-item>
				<el-form-item label="" prop="password2">
					<el-input v-model="passwordForm.password2" :type="type2" placeholder="请确认新密码">
						<i slot="suffix" class="icon-style" :class="elIcon2" autocomplete="auto"
							@click="flag2 = !flag2" />
					</el-input>
				</el-form-item>
				<div style="font-size: 12px; text-align: center; color: red">
					密码强度至少为8位（包含字母、数字、特殊字符），区分英文大小写
				</div>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<v-btn color="#586afc" @click="changePassword">确 定</v-btn>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		mapState
	} from "vuex";
	import {
		checkStrong
	} from "@/components/checkpassStrong/checkpassStrong";
	export default {
		name: "App",
		provide() {
			return {
				reload: this.reload,
				path: "",
			};
		},
		data() {
			return {
				same_password: false,
				new_data: null,
				isRouterAlive: true,
				systemMessage: false,
				systemMessages: [],
				count: 0,

				centerDialogVisible: false,
				dialog: false,
				passwordChanging: false,
				passwordForm: {
					origin_password: null,
					password: null,
					password2: null,
					name: null,
				},
				flag: false,
				flag1: false,
				flag2: false,
				rules: {
					origin_password: [{
							required: true,
							message: "请输入当前密码",
							trigger: "blur"
						},
						{
							min: 8,
							message: "密码强度至少为8位（包含字母、数字、特殊字符），区分英文大小写",
							trigger: "blur",
						},
					],
					password: [{
							required: true,
							message: "请输入新密码",
							trigger: "blur"
						},
						{
							min: 8,
							message: "密码强度至少为8位（包含字母、数字、特殊字符），区分英文大小写",
							trigger: "blur",
						},
					],
					password2: [{
							required: true,
							message: "请输入确认新密码",
							trigger: "blur"
						},
						{
							min: 8,
							message: "密码强度至少为8位（包含字母、数字、特殊字符），区分英文大小写",
							trigger: "blur",
						},
					],
				},
			};
		},
		methods: {
			async changePassword() {
				let passwordLevel = checkStrong(this.passwordForm.password);
				if (this.same_password) {
					if (this.passwordForm.origin_password == this.passwordForm.password) {
						this.$message({
							message: "原密码和新密码一致",
							type: "warning",
						});
						return;
					}
				} else if (this.passwordForm.password != this.passwordForm.password2) {
					this.$message({
						message: "两次密码不一致",
						type: "warning",
					});
					return;
				} else if (this.passwordForm.password.length < 8) {
					this.$message({
						message: "请输入8位以上密码",
						type: "warning",
					});
					return;
				} else if (passwordLevel < 3) {
					this.$message({
						message: "密码至少8位且包含大小写字母、数字、字符任意三种组合",
						type: "warning",
					});
					return;
				}
				let data = await this.$admin.http.put(
					`/auth-system/users/${this.user.id}/change_password/`,
					this.passwordForm
				);
				this.dialog = false;
				this.$message({
					message: "修改成功",
					type: "success",
				});

				if (data.status === 205) {
					sessionStorage.clear();
					this.$admin.http.get(
						`config-manage/logout_log/?user_id=${this.user.id}&operat_content=修改密码退出成功&log_type=退出登录`
					);
					this.$router.push(`/login?redirect=${this.$route.fullPath}`); //跳回登录地址
				}
				this.centerDialogVisible = false;
			},

			reload() {
				this.isRouterAlive = false;
				this.$nextTick(function() {
					this.isRouterAlive = true;
				});
			},
			// async getInform(to_user) {
			//   let inform = await this.$store.dispatch("inform_inform/getList", {
			//     to_user,
			//     filter: {
			//       is_read: false,
			//       to_user_id: this.user.id,
			//     },
			//     sort: [{ by: "id", desc: true }],
			//   });
			//   this.systemMessages = inform.data;
			// },
			showAll() {
				this.systemMessage = false;
				// this.$router.push("/inform-inform/");
			},
			closeAll() {
				this.systemMessage = false;
			},
			async status_true(id) {
				this.systemMessage = false;
				// this.$router.push({ path: "/inform-inform/" + id });

				this.$store.dispatch("inform_inform/update", {
					id: id,
					data: {
						is_read: true,
					},
				});
			},
		},
		computed: {
			// routeNameChange() {
			//   return this.$route.name;
			// },
			...mapState({
				user: (state) => state.auth.user,
			}),
			type() {
				return this.flag ? "text" : "password";
			},
			elIcon() {
				return this.flag ? "el-icon-view" : "el-icon-minus";
			},
			type1() {
				return this.flag1 ? "text" : "password";
			},
			elIcon1() {
				return this.flag1 ? "el-icon-view" : "el-icon-minus";
			},
			type2() {
				return this.flag2 ? "text" : "password";
			},
			elIcon2() {
				return this.flag2 ? "el-icon-view" : "el-icon-minus";
			},
		},
		watch: {
			user: {
				deep: true,
				immediate: true,
				handler(val) {
					if (!val) return;
					// this.count++;
					// this.count < 2 && this.getInform(val.id);
				},
			},
			async routeNameChange(routeName) {
				if (routeName == "dashboard") {
					let data = await this.$admin.http.get(
						`/auth-system/changepwdremind/?user_id=${this.user.id}`
					);
					if (data.data.code == 2001) {
						this.centerDialogVisible = true;
						if (data.data.strategy.is_same_oldpwd) {
							this.same_password = true;
						}
						return;
					} else if (data.data.code == 2000) {
						if (sessionStorage.getItem("show")) {
							this.systemMessage = false;
						} else {
							sessionStorage.setItem("show", "true");
							this.getInform();
							if (this.systemMessages.length > 0) {
								this.systemMessage = true;
							} else {
								this.systemMessage = false;
							}
						}
					}
				}
			},
		},
	};
</script>

<style>
	.theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr>th {
		color: #586afc;
		font-size: 16px;
		font-style: inherit;
		background-color: #ebedff;
	}

	.el-tree-node:focus>.el-tree-node__content {
		background-color: #ebedff !important;
		color: #586afc;
	}

	.el-tree-node__content {
		color: #000;
		height: 50px !important;
	}

	.el-input__suffix {
		line-height: 40px;
		padding-right: 15px;
	}

	.v-application ul {
		padding-left: 0;
	}

	.v-breadcrumbs li:nth-child(1) {
		margin-left: 20px;
	}

	.hidden-sm-and-down {
		margin-left: -15px;
	}

	::-webkit-scrollbar {
		background-color: #182b5c;
		border-radius: 8px;
		width: 8px;
		height: 10px;
	}

	::-webkit-scrollbar-thumb {
		background-color: rgba(255, 255, 255, 0.3);
		border-radius: 8px;
		height: 10px;
	}

	::-webkit-scrollbar-button {
		display: none;
	}

	.v-toolbar__title {
		width: 240px !important;
	}

	.v-app-bar--fixed>.v-toolbar__content {
		/* background-color: #069cae!important; */
		background-color: #14b2c5 !important;
	}

	.el-submenu__title:hover {
		background-color: #069cae !important;
	}

	::-webkit-scrollbar {
		background-color: #42b6c4 !important;
		border-radius: 8px;
		width: 8px;
		height: 10px;
	}

	::-webkit-scrollbar-thumb {
		background-color: rgba(255, 255, 255, 0.3);
		border-radius: 8px;
		height: 10px;
	}

	.v-application .v-breadcrumbs a {
		color: #069cae !important;
	}

	/* .v-list-item__content{
  color:white!important;
} */
	.v-breadcrumbs__item {
		color: #069cae !important;
	}

	.theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr>th {
		color: #069cae !important;
		background-color: #f6f9fd !important;
	}

	.el-submenu__title i {
		color: white !important;
	}

	.el-input__inner {
		padding-bottom: 0px !important;
	}

	.v-application .primary {
		background-color: #14b2c5 !important;
		border-color: var(--v-primary-base) !important;
	}

	.el-menu {
		border-right: 0 !important;
	}

	.v-item-group {
		margin-left: 15px;
	}

	.theme--light.v-btn[data-v-44b3a4c3]:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
		background-color: #f6f9fd;
		color: #069cae;
	}

	.theme--light.v-btn[data-v-44b3a4c3]:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined):hover {
		/* background-color: #d7dfeb; */
		color: #069cae;
	}
</style>

<style lang="scss">
	@import "@/sass/styles.scss";
</style>

<style lang="scss" scoped>
	.systemMessage {
		z-index: 9999;
		position: fixed;
		right: 10px;
		bottom: 0;
		background: #fff;
		width: 330px;
		max-height: 500px;

		// border-top: 6px solid #2E398D;
		border-top: 6px solid #586afc;
		box-sizing: border-box;

		.systemMessageTitle {
			line-height: 50px;
			width: 100%;
			padding-left: 20px;
			padding-right: 20px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			box-sizing: border-box;
			overflow: hidden;
		}

		.systemMessageItemBox {
			width: 100%;
			max-height: 500px;
			overflow: auto;
		}

		.systemMessageItem {
			width: 100%;
			margin-top: 5px;
			display: flex;
			justify-content: space-around;
			cursor: pointer;

			.systemMessageIcon {
				width: 20%;
				text-align: center;
				display: flex;
				justify-content: center;
				align-items: center;

				img {
					width: 80%;
				}
			}

			.systemMessageContent {
				width: 90%;

				.content {
					border-top: 1px solid rgba($color: #182b5c, $alpha: 0.2);
					padding: 5px 10px;
					line-height: 20px;
					word-break: break-all;
					text-overflow: ellipsis;
					overflow: hidden;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
				}

				.data {
					padding: 5px 10px;
					font-size: 11px;
					display: flex;
					justify-content: space-between;
					box-sizing: border-box;
					padding-right: 20px;
					color: #818181;
				}
			}
		}

		.systemMessageOperation {
			width: 100%;
			display: flex;
			border-top: 1px solid #d0c7c7;
			box-sizing: border-box;
			margin-top: 5px;
			cursor: pointer;

			div:first-child {
				border-right: 1px solid #d0c7c7;
			}

			div {
				text-align: center;
				line-height: 40px;
				width: 50%;
				font-size: 12px;
				color: #586afc;
			}
		}
	}
</style>
<style scoped>
	@import "/css/materialdesignicons.min.css";
</style>