<template>
  <va-form :id="id" :item="item" @saved="$emit('saved')" ref="form">
    <v-card-text>
      <va-text-input
        source="code_srvtp"
        required
      ></va-text-input>
      <va-text-input
        source="name_srvtp"
        required
      ></va-text-input>
      <va-select-input
        source="parent_id"
        reference="bs_examination_type"
        label="上级类型"
        itemText="name_srvtp"
        :items-per-page="1000"
        ref="dept"
      ></va-select-input>
      <va-boolean-input
        source="is_active"
        v-model="is_active"
      ></va-boolean-input>
    </v-card-text>
    <div class="btn_bg">
      <va-save-button></va-save-button>
      <!-- <v-btn @click="isSure" color="primary">保存</v-btn> -->
    </div>
  </va-form>
</template>

<script>
import moment from "moment";
export default {
  props: ["id", "title", "item"],
  data() {
    return {
      is_active: true
    };
  },
};
</script>
<style scoped>
.btn_bg {
  width: 95%;
  position: fixed;
  bottom: 0px;
  padding: 10px 0;
  text-align: center;
  background-color: white;
}
</style>