<template>
  <div>
    <va-aside-layout :title="asideTitle">
      <ws-appointment-type-name-show
        v-if="show"
        :item="item"
      ></ws-appointment-type-name-show>
      <ws-appointment-type-name-form
        v-else
        :id="id"
        width="500px"
        :item="item"
        @saved="onSaved"
        ref="bsform"
      ></ws-appointment-type-name-form>
    </va-aside-layout>
    <v-card :icon="resource.icon" :title="title">
      <va-list
        ref="list"
        :filters="filters"
        disableExport
        disable-global-search
        disable-create-redirect
        @action="onAction"
        :sort-desc="[true]"
      >
        <va-data-table
          :fields="fields"
          disable-show-redirect
          disable-clone
          disable-edit-redirect
          @item-action="onAction"
        >
        </va-data-table>
      </va-list>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["resource", "title"],
  data() {
    return {
      filters: [
        {
          source: "name",
          type: "text",
          alwaysOn: true,
          // attributes: { reference: "auth_system_user.id" },
        },
      ],
      fields: [
        { source: "name", sortable: true },
        { source: "is_active", type: "boolean" },
        {
          source: "created_time",
          sortable: true,
          type: "date",
          attributes: { format: "longTime" },
        },
      ],
      asideTitle: null,
      id: null,
      item: null,
      show: false,
      create: false,
    };
  },
  methods: {
    //导入
    async onAction({ action, title, id, item }) {
      this.asideTitle = title;
      this.id = id;
      this.show = action === "show";

      this.item = item;
      //如果点击的是新增,请求新增的接口
      if (action === "create") {
        this.id = null;
        if (this.$refs.bsform) {
          this.$refs.bsform.resetForm();
        }
      }
    },
    onSaved() {
      this.asideTitle = null;
      this.$refs.list.fetchData();
    },
  },
};
</script>
