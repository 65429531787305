var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"icon":_vm.resource.icon,"title":_vm.title}},[_c('va-list',{ref:"list",attrs:{"include":[
      'bs_hospital',
      'bs_office',
      'bs_expense_standard',
      'bs_doctor',
      'bs_extra_group',
      'base_system_user_groups',
    ],"global-search-query":"bs_office#name@_like,bs_hospital#name@_like,bs_doctor#name@_like,username@_like,name@_like","disable-export":"","filters":_vm.filters,"filter":_vm.filter,"sort-by":['id'],"sort-desc":[true]}},[_c('va-data-table',{attrs:{"fields":_vm.fields,"disable-clone":"","disable-show":""}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }