<template>
  <va-form :id="id" :item="item">
    <v-row justify="center">
      <v-col sm="6">
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2">
              {{ title }}
            </div>
          </template>
          <v-card-text>
            <va-text-input source="name" v-model="xman.name"></va-text-input>
			<va-boolean-input source="is_active" v-model="xman.is_active"></va-boolean-input>
            <va-text-input source="remark" v-model="xman.remark"></va-text-input>
          </v-card-text>
		  <div class="btn_bg">
			<v-btn @click="isSure" color="primary">保存</v-btn>
			<v-btn @click="notSure" style="color: #14b2c5; margin-left: 50px;">返回</v-btn>
		  </div>
        </base-material-card>
      </v-col>
    </v-row>
  </va-form>
</template>

<script>
export default {
  props: ["id", "title", "item"],
  data(){
	  return{
			xman:{								
				name: "",
				is_active: true,
				remark: "",
			}
	  }
  },
  methods:{
	  
	  	async isSure() {
	  		let formdata = Object.assign({}, this.xman);
	  		if (this.id) {
	  			try {
	  				await this.$store
	  					.dispatch("ig_device_resource_type/update", {
	  						id: this.id,
	  						data: formdata,
	  					})
	  					.then((res) => {
	  						this.$emit("saved");
	  						this.$router.push({
	  							path: "/ig-device-resource-type/"
	  						});
	  					});
	  			} catch {
	  				// this.$message({
	  				//   message: "医院编码违反唯一性，请修改",
	  				//   type: "error",
	  				// });
	  			}
	  		} else {
	  			await this.$store
	  				.dispatch("ig_device_resource_type/create", {
	  					data: formdata,
	  				})
	  				.then((res) => {
	  					this.$emit("saved");
	  					this.$router.push({
	  						path: "/ig-device-resource-type/"
	  					});
	  					// if (this.file_data && this.file_data.length >= 1) {
	  					//   this.httpRequest(this.imgfile, res.data.id, res.data.logo);
	  					// }
	  				})
	  				.catch((err) => {
	  					// this.$message({
	  					//   message: "医院编码违反唯一性，请修改",
	  					//   type: "error",
	  					// });
	  				});
	  		}
	  	},
	  	notSure() {
	  		this.$router.push(
	  			"/ig-device-resource-type" //跳转
	  		);
	  	},
  }
};
</script>
