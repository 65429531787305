<template>
	<v-container>
		<v-row>
			<v-col cols="12">
				<v-data-table :headers="headers" :items="patients" class="elevation-1">
					<template v-slot:top>
						<v-toolbar flat>
							<v-toolbar-title>已预约清单</v-toolbar-title>
						</v-toolbar>
					</template>
					<template v-slot:[`item.reqTime`]="{ item }">
						<span>{{ formatDate(item.reqTime) }}</span>
					</template>
					<template v-slot:[`item.action`]="{ item }">
						<v-chip class="ma-2" color="#069CAE" @click="openDialog(item)" text-color="white">
							变更预约
						</v-chip>
						<v-chip class="ma-2" color="#069CAE" @click="openPrintDialog(item)" text-color="white">
							补打指引单
						</v-chip>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
		<v-dialog v-model="dialog" max-width="800px">
			<v-card>
				<v-card-title>
					<span class="headline">预约</span>
				</v-card-title>
				<v-card-text>
					<v-form ref="form">
						<v-row>
							<v-col v-for="field in displayFields" :key="field.key" cols="3">
								<v-text-field :label="field.label" v-model="editedItem[field.key]"
									:disabled="!field.editable"
									:type="field.key === 'reqTime' ? 'text' : 'text'"></v-text-field>
							</v-col>
						</v-row>
						<v-tabs style="margin-top: 10px;">
							<v-tab v-for="(item, index) in dates" :key="index"
								@click="fetchSchedule(item.text)">{{ `${item.text} (${item.day})` }}</v-tab>
						</v-tabs>
						<h2 class="pa-2">上午</h2>
						<div class="d-flex flex-nowrap date-scroll pa-2">
							<div class="date-card" :class="active == 'am' + index ? 'active' : ''"
								v-for="(schedule, index) in amSchedule" :key="index"
								@click="selectSchedule(schedule, 'am', index)">
								<p>{{ schedule.group_name }}</p>
								<p>{{ schedule.time_line_name }}</p>
								{{ schedule.used_count }}/{{ schedule.resource_count }}
							</div>
						</div>
						<br />
						<h2 class="pa-2">下午</h2>
						<div class="d-flex flex-nowrap date-scroll pa-2">
							<div class="date-card" :class="active == 'pm' + index ? 'active' : ''"
								v-for="(schedule, index) in pmSchedule" :key="index"
								@click="selectSchedule(schedule, 'pm', index)">
								<p>{{ schedule.group_name }}</p>
								<p>{{ schedule.time_line_name }}</p>
								{{ schedule.used_count }}/{{ schedule.resource_count }}
							</div>
						</div>
						<v-row>
							<v-col sm="6">
								<v-radio-group row v-model="needEscort" label="是否陪送" >
									<v-radio label="轮椅" value="1"></v-radio>
									<v-radio label="平车" value="2"></v-radio>
								</v-radio-group>
							</v-col>
							<v-col sm="3" v-if="sexName !== '男'">
								<el-date-picker class="select" label="末次月经时间" :picker-options="pickerOptions" size="large"
									v-model="endMenstruationDate" type="date" placeholder="末次月经时间">
								</el-date-picker>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click="closeDialog">取消</v-btn>
					<v-btn color="blue darken-1" text @click="save">保存</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogPrint" max-width="800px">
			<v-card>
				<!-- <v-card-title>
					<span class="headline"></span>
				</v-card-title> -->
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click="closePrintDialog">取消</v-btn>
					<v-btn color="blue darken-1" text @click="print">打印</v-btn>
				</v-card-actions>
				<v-card-text>
					<v-form ref="form">
						<div class="image-container" id="print-area" ref="printArea">
							<img :src="generatedImage" alt="Generated Image" v-if="generatedImage" />
						</div>
						<v-row>
							<v-col cols="12" class="d-none">
								<canvas ref="canvas" width="800" height="1000" style="border:1px solid #000000;"></canvas>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>
import JsBarcode from 'jsbarcode';
import moment from "moment";
import 'moment/locale/zh-cn'; // 引入中文语言包

function getNextSevenDays() {
	const dates = [];
	let currentDate = moment(); // 获取当前日期

	for (let i = 0; i < 7; i++) {
		const dateString = currentDate.format('YYYY-MM-DD');
		const dayOfWeek = currentDate.format('dddd');
		dates.push({
			text: dateString,
			day: dayOfWeek
		});
		currentDate = currentDate.add(1, 'days'); // 增加一天
	}

	return dates;
}

export default {
	data() {
		return {
			dialog: false,
			dialogPrint: false,
			patients: [],
			needEscort: null,
			endMenstruationDate: null,
			sexName: null,
			generatedImage: '',
			pickerOptions: {
				disabledDate(time) {
					return time.getTime() > Date.now() - 8.64e7;
				},
			},
			items: [],
			headers: [
				{ text: '患者', value: 'patientName' },
				// { text: '申请单', value: 'requestNo' },
				{ text: '就诊号', value: 'visitNo' },
				{ text: '预约待检时间', value: 'reqTime' },
				{ text: '排号', value: 'reqNo' },
				{ text: '就诊类型', value: 'visitTypeName' },
				{ text: '预约状态', value: 'reqExecStatusName' },
				{ text: '检查项目', value: 'item_name' },
				// { text: '预约编码', value: 'id' },
				{ text: '操作', value: 'action', sortable: false }
			],
			displayFields: [
				{ label: '患者名称', key: 'patientName', editable: false },
				{ label: '年龄', key: 'age', editable: false },
				{ label: '性别', key: 'sexName', editable: false },
				{ label: '电话', key: 'phoneNo', editable: false },
				{ label: '就诊类别名称', key: 'visitTypeName', editable: false },
				{ label: '就诊流水号', key: 'visitOrdNo', editable: false },
				{ label: '床号', key: 'bedNo', editable: false },
				{ label: '就诊号', key: 'visitNo', editable: false },
				{ label: '开立医生', key: 'reqDocName', editable: false },
				{ label: '开立时间', key: 'reqDatetime', editable: false },
				{ label: '开立科室', key: 'deptName', editable: false },
				{ label: '申请单编号', key: 'requestNo', editable: false },
				{ label: '预约排号', key: 'reqNo', editable: true },
				{ label: '预约检查时间', key: 'reqTime', editable: true }
			],
			editedItem: {},
			dates: getNextSevenDays(),
			amSchedule: [],
			pmSchedule: [],
			active: 'am0',
			selectedSchedule: null,
		};
	},
	mounted() {
		this.fetchData();
	},
	methods: {
		async fetchData() {
			try {
				const response = await this.$admin.http.get('/application_form/applicationform/filter_by_req_status/?reqExecStatus=1');
				this.patients = response.data;
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		},
		openDialog(item) {
			console.log('item ', item);
			console.log('item.sexName ', item.sexName);
			this.sexName = item.sexName;
			console.log('this.sexName ', this.sexName);
			this.editedItem = { ...item };
			if (this.editedItem.reqDatetime) {
				this.editedItem.reqDatetime = this.formatDateTime(this.editedItem.reqDatetime);
			}
			this.dialog = true;
			this.fetchSchedule(this.dates[0].text, true); // 加载第一个时间选项卡的数据并默认选择第一个时间块
		},
		openPrintDialog(item) {
			console.log('item ', item);
			console.log('this.editedItem.visitTypeCode ', this.editedItem.reqDatetime);
			this.editedItem.reqDatetime = item.visitTypeCode;
			this.editedItem.escortWay = item.escortWay;
			console.log('this.editedItem.visitTypeCode ', this.editedItem.reqDatetime);
			this.editedItem = { ...item };
			if (this.editedItem.reqDatetime) {
				this.editedItem.reqDatetime = this.formatDateTime(this.editedItem.reqDatetime);
			}
			this.dialogPrint = true;
			this.fetchSchedule(this.dates[0].text, true); // 加载第一个时间选项卡的数据并默认选择第一个时间块
			this.generateImage(); // 生成图片
		},
		closeDialog() {
			this.dialog = false;
			this.$refs.form.reset();
		},
		closePrintDialog() {
			this.dialogPrint = false;
			// this.$refs.form.reset();
		},
		async save() {
			// 将选择的时间块赋值给 reqTime
			if (this.selectedSchedule) {
				this.editedItem.reqTime = this.selectedSchedule.start_time;
			}
			console.log('Saving item:', this.editedItem);
			try {
				await this.$admin.http.post('/application_form/applicationform/update_req_fields/', {
					reqNo: this.editedItem.reqNo,
					reqTime: this.editedItem.reqTime,
					needEscort: this.needEscort,
					endMenstruationDate: this.endMenstruationDate,
					exec_office_name: this.exec_office_name,
					work_addr: this.work_addr,
					id: this.editedItem.id
				});
				this.$message({
					message: '保存成功',
					type: 'success'
				});
				this.fetchData(); // 重新获取数据以刷新列表
			} catch (error) {
				console.error('Error saving data:', error);
				this.$message({
					message: '保存失败',
					type: 'error'
				});
			}
			this.closeDialog();
		},
		async print() {
			if (this.generatedImage) {
				const printArea = document.getElementById('print-area').innerHTML;
				const originalContents = document.body.innerHTML;
				document.body.innerHTML = printArea;
				window.print();
				window.location.reload(); // 重新载入整个页面
				setTimeout(() => {
					document.body.innerHTML = originalContents;
					this.closePrintDialog();
					window.location.reload(); // 重新载入整个页面
				}, 1000);
			} else {
				alert('请先生成图片');
			}
			this.closePrintDialog();
		},
		formatDateTime(dateTime) {
			const date = new Date(dateTime);
			const year = date.getFullYear();
			const month = String(date.getMonth() + 1).padStart(2, '0');
			const day = String(date.getDate()).padStart(2, '0');
			const hours = String(date.getHours()).padStart(2, '0');
			const minutes = String(date.getMinutes()).padStart(2, '0');
			const seconds = String(date.getSeconds()).padStart(2, '0');
			return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
		},
		formatDate(dateTime) {
			const date = new Date(dateTime);
			return moment(date).format('YYYY-MM-DD HH:mm:ss');
		},
		async fetchSchedule(date, selectFirst = false) {
			try {
				const itemCode = this.editedItem.item_code;
				const isInpatient = ["03", "05"].includes(this.xman.visitTypeCode);
				console.log('999 fetchSchedule ', isInpatient);
				// 就诊类型treatment_type【0门诊、1住院】、
				// 预约人类型appointment_maker【0医生、1分诊台】
                const response = await this.$admin.http.get(`/item/itembindinggroup/item-schedule-day?date=${date}&item_code=${itemCode}&treatment_type=${isInpatient ? 1 : 0}&appointment_maker=0`);
				const data = response.data;
				this.amSchedule = data.am;
				this.pmSchedule = data.pm;
				if (selectFirst && this.amSchedule.length > 0) {
					this.selectSchedule(this.amSchedule[0], 'am', 0);
				} else if (selectFirst && this.pmSchedule.length > 0) {
					this.selectSchedule(this.pmSchedule[0], 'pm', 0);
				}
			} catch (error) {
				console.error('Error fetching schedule:', error);
			}
		},
		selectSchedule(schedule, time, index) {
			this.active = time + index;
			this.selectedSchedule = schedule;
			this.editedItem.reqNo = schedule.reqNo;
			this.exec_office_name = schedule.exec_office_name;
			this.work_addr = schedule.work_addr;
			this.editedItem.reqTime = this.formatDateTime(schedule.start_time);
		},
		async fetchApiData() {
			// 模拟从接口获取数据
			return new Promise((resolve) => {
				setTimeout(() => {
					resolve('接口数据');
				}, 1000);
			});
		},
		async generateImage() {
			console.log('in image generate.');
			// 获取接口数据
			this.apiData = await this.fetchApiData();

			// 获取 canvas 上下文
			const canvas = this.$refs.canvas;
			const context = canvas.getContext('2d');

			// 清除之前的内容
			context.clearRect(0, 0, canvas.width, canvas.height);

			// 绘制矩形背景
			context.fillStyle = "#FFFFFF";
			context.fillRect(0, 0, canvas.width, canvas.height);

			// 设置通用文本样式
			context.font = "16px Arial";
			context.fillStyle = "#000000";

			// 根据visitTypeCode决定生成哪种指引单
			const isInpatient = [3, 5].includes(this.editedItem.visitTypeCode);
			isInpatient ? this.generateInpatientGuide(context) : this.generateOutpatientGuide(context);

			// 生成图片
			this.generatedImage = canvas.toDataURL("image/png");
		},
		
		getEscortWay() {
		    switch (this.needEscort) {
		        case "1":
		            return '轮椅';
		        case "2":
		            return '平车';
		        default:
		            return '-';
		    }
		},
		
		// 门诊
		generateOutpatientGuide(context) {
		    this.drawTitle(context, "检查预约指引单（门诊）");
		    this.drawBarcode(context, this.editedItem.reqQr, 70, 60);
		
		    const escortWay = this.getEscortWay(); // 获取当前的陪送方式
		
		    const textItems = [
		        { text: `姓名: ${this.editedItem.patientName}`, x: 20, y: 180, font: "16px Arial" },
		        { text: `性别: ${this.editedItem.sexName}`, x: 20, y: 205 },
		        { text: `年龄: ${this.editedItem.age}岁`, x: 20, y: 230 },
		        { text: `科室: ${this.editedItem.deptName}`, x: 20, y: 255 },
		        { text: `预约号: `, x: 20, y: 280 },
		        { text: this.editedItem.reqNo, x: 100, y: 280, font: "bold 18px Arial" },
		        { text: `候检时间: `, x: 20, y: 305 },
		        { text: this.editedItem.reqTime, x: 100, y: 305, font: "bold 18px Arial"  },
		        { text: `候检地点: ${this.editedItem.work_addr}`, x: 20, y: 330 },
		        { text: `检查诊室: ${this.editedItem.exec_office_name}`, x: 20, y: 355 },
		        { text: `检查项目: ${this.items[0]?.item_name || '-'}`, x: 20, y: 380 },
		        { text: `申请医生: ${this.editedItem.reqDocName}`, x: 20, y: 405 },
		        { text: `诊断: ${this.editedItem.mainDiagnosis}`, x: 20, y: 430 },
		        { text: `病史: ${this.editedItem.presentHistory}`, x: 20, y: 455 },
		        { text: `陪送方式: ${escortWay}`, x: 20, y: 480 },
		        { text: "***过号请重新预约***", x: 20, y: 505 },
		        { text: "申请单及撤费单到超声医学科", x: 20, y: 530 },
		        { text: " 分诊台登记报道。", x: 20, y: 555 },
		        { text: "如未按时报道者(迟到30分钟)视为", x: 20, y: 580 },
		        { text: "自动弃号，请重新改约时间!", x: 20, y: 605 },
		        { text: "看清楚预约时间按时检查，谢谢配合!!", x: 20, y: 630 },
		        { text: "诊患者2小时内未缴费，自动取消预约。", x: 20, y: 655 },
		        { text: "(1)肝、胆、脾、胰、需空腹检查、", x: 20, y: 680 },
		        { text: "   前一日晚餐忌食油腻，当日晨禁食水。", x: 20, y: 705 },
		        { text: "(2)膀胱、前列腺、子宫、附件、早孕应憋尿后", x: 20, y: 730 },
		        { text: "  检查，如无尿，应大量饮水待憋尿后再检查。", x: 20, y: 755 },
		        { text: "(3)其他脏器以及心脏彩超、颅脑彩超及小器官", x: 20, y: 780 },
		        { text: "  检查无需特殊准备。", x: 20, y: 805 },
		        { text: "(4)阴道超声检查:无需膀胱充盈，患者本人有", x: 20, y: 830 },
		        { text: "  性生活史并且自愿检查。", x: 20, y: 855 },
		        // { text: "  地址:1号楼3楼超声医学科", x: 20, y: 880 },
		    ];
		
		    this.drawTextItems(context, textItems);
		},
		
		// 住院
		generateInpatientGuide(context) {
		    this.drawInTitle(context, "检查预约指引单（住院）");
		    this.drawBarcode(context, this.editedItem.reqQr, 50, 70);
		
		    const escortWay = this.getEscortWay(); // 获取当前的陪送方式
		
		    const textItems = [
		        { text: `住院号: `, x: 550, y: 100, font: "16px Arial" },
		        { text: `${this.editedItem.reqQr}`, x: 550, y: 125 },
		        { text: `姓名: ${this.editedItem.patientName}`, x: 50, y: 205, font: "16px Arial" },
		        { text: `性别: ${this.editedItem.sexName}`, x: 450, y: 205 },
		        { text: `年龄: ${this.editedItem.age}岁`, x: 560, y: 205 },
		        { text: `科室: ${this.editedItem.deptName}`, x: 50, y: 230 },
		        { text: `预约号: `, x: 50, y: 255, font: "16px Arial" },
		        { text: this.editedItem.reqNo, x: 120, y: 255, font: "bold 18px Arial" },
		        { text: `候检时间: `, x: 50, y: 280, font: "16px Arial"  },
		        { text: this.editedItem.reqTime, x: 120, y: 280, font: "bold 18px Arial"  },
		        { text: `候检地点: ${this.editedItem.work_addr}`, x: 50, y: 305 },
		        { text: `检查诊室: ${this.editedItem.exec_office_name}`, x: 50, y: 330 },
		        { text: `检查项目: ${this.items[0]?.item_name || '-'}`, x: 50, y: 355 },
		        { text: `申请医生: ${this.editedItem.reqDocName}`, x: 50, y: 380 },
		        { text: `诊断: ${this.editedItem.mainDiagnosis}`, x: 50, y: 405 },
		        { text: `病史: ${this.editedItem.presentHistory}`, x: 50, y: 430 },
		        { text: `陪送方式: ${escortWay}`, x: 50, y: 455 },
		        { text: "***过号请重新预约***", x: 50, y: 480 },
		        // { text: "*注意事项:请于【促检时间】提前15分钟携带申请单及撤费单到超声医学科分诊台登记报道。", x: 50, y: 555 },
		        // { text: "如未按时报道者(迟到30分钟)视为自动弃号，请重新改约时间!因疫情防控要求请各位患者看清楚预约时间按时检查，谢谢配合!!", x: 50, y: 580 },
		        // { text: "诊患者2小时内未缴费，自动取消预约。", x: 50, y: 605 },
		        // { text: "(1)肝、胆、脾、胰、需空腹检查、前一日晚餐忌食油腻，当日晨禁食水。", x: 50, y: 630 },
		        // { text: "(2)膀胱、前列腺、子宫、附件、早孕应憋尿后检查，如无尿，应大量饮水待憋尿后再检查。", x: 50, y: 655 },
		        // { text: "(3)其他脏器以及心脏彩超、颅脑彩超及小器官检查无需特殊准备。", x: 50, y: 680 },
		        // { text: "(4)阴道超声检查:无需膀胱充盈，患者本人有性生活史并且自愿检查。地址:1号楼3楼超声医学科", x: 50, y: 705 },
		    ];
		
		    this.drawTextItems(context, textItems);
		},
		
		drawTitle(context, title) {
		    context.font = "bold 24px Arial";
		    context.fillText(title, 80, 50); // 将标题向左移动60像素
		},
		
		drawInTitle(context, title) {
		    context.font = "bold 24px Arial";
		    context.fillText(title, 280, 50); // 将标题向左移动60像素
		},
		
		drawBarcode(context, data, x, y) {
		    const barcodeCanvas = document.createElement('canvas');
		    JsBarcode(barcodeCanvas, data, {
		        format: 'CODE128',
		        displayValue: true,
		        height: 50,
		        fontSize: 20
		    });
		    context.drawImage(barcodeCanvas, x, y);
		},
		
		drawTextItems(context, textItems) {
		    textItems.forEach(({ text, x, y, font }) => {
		        if (font) context.font = font;
		        context.fillText(text, x, y);
		        context.font = "16px Arial"; // reset font
		    });
		},
		
		drawExamItems(context, startY) {
			this.items.forEach((item, index) => {
				const y = startY + index * 25;
				context.fillText(index === 0 ? `检查项目: ${item.item_name}` : item.item_name, index === 0 ? 50 : 122, y);
			});
		}
	}
};
</script>

<style scoped>
.v-container {
	padding-top: 20px;
}

.date-scroll {
	overflow-x: auto;
}

.date-card {
	flex-shrink: 0;
	padding: 2rem 0;
	cursor: pointer;
	width: 12rem;
	margin-right: 20px;
	background-color: rgb(180, 230, 236);
	align-items: center;
	text-align: center;
	border-radius: 10px;
}

.date-card.active {
	background-color: rgb(100, 230, 236);
	box-shadow: 0 20px 13px -6px rgba(180, 230, 236, .5);
}

.date-card p {
	margin-bottom: 5px !重要;
}
</style>
