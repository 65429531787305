import axios from "axios";
const httpClient = (config) => {
  let axiosInstance = axios.create(config);
  // 添加request拦截器
  axiosInstance.interceptors.request.use(
    (cfg) => {
      let token = sessionStorage.getItem("jwt_token");
      if (token) {
        cfg.headers["Authorization"] = `JWT ${token}`;
      }
      return cfg;
    },
    (error) => {
      Promise.reject(error);
    }
  );
  return axiosInstance;
};
export default httpClient;
