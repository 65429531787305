import Vue from "vue";
import VueRouter from "vue-router";
import AuthLayout from "@/layouts/Auth";
import DefaultLayout from "@/layouts/Default";
import Login from "@/views/auth/Login";
import Register from "@/views/auth/Register";
import ForgotPassword from "@/views/auth/ForgotPassword";
import ResetPassword from "@/views/auth/ResetPassword";
import WhitePage from "@/views/whitePage/index";
import hisappoint from "@/views/appointmentmanagement/hisappoint";
import i18n from "@/i18n";
import breadcrumbs from "@/components/breadcrumbs";

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


Vue.use(VueRouter);

const routes = [
  {
    path: "",
    redirect: "login",
    component: AuthLayout,
    children: [
      {
        path: "/login",
        name: "login",
        component: Login,
        meta: {
          title: i18n.t("routes.login"),
        },
      },
      {
        path: "/register",
        name: "register",
        component: Register,
        meta: {
          title: i18n.t("routes.register"),
        },
      },
      {
        path: "/forgot-password",
        name: "forgot_password",
        component: ForgotPassword,
        meta: {
          title: i18n.t("routes.forgot_password"),
        },
      },
      {
        path: "/reset-password/:token",
        name: "reset_password",
        component: ResetPassword,
        props: true,
        meta: {
          title: i18n.t("routes.reset_password"),
        },
      },
      {
        path: "/breadcrumbs",
        name: "breadcrumbs",
        component: breadcrumbs,
        meta: {
          title: i18n.t("routes.breadcrumbs"),
        },
      },
      // {
      //   path: "/addNew",
      //   name: "addNew",
      //   component:()=>import('../../src/resources/ws-schedule-day/addNew.vue') ,
      //   meta: {
      //     // title: i18n.t("routes.Expstandlist"),
      //   },
      // },
   
      
    ],
  },
  {
   path: "/white",
   redirect: "white-page",
   component: DefaultLayout,
   children:[
      {
         path: "/white-page",
         name: "WhitePage",
         component: WhitePage,
         meta: {
           title: '独立页面'
         },
       },
      {
         path: "/appointmentmanagement/hisappoint",
         name: "hisappoint",
         component: hisappoint,
         meta: {
           title: 'HIS预约窗口'
         },
       },
   ]
  }
];

export default new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
