<template>
  <div>
    <va-aside-layout :title="asideTitle">
      <ig-service-group-type-show
        v-if="show"
        :item="item"
      ></ig-service-group-type-show>
      <ig-service-group-type-form
        v-else
        :id="id"
        width="800px"
        :item="item"
        @saved="onSaved"
        ref="form"
      ></ig-service-group-type-form>
    </va-aside-layout>
    <v-card :title="title">
      <va-list
        :filters="filters"
        disable-global-search
        disable-create-redirect
        ref="list"
        @action="onAction"
        :items-per-page="10"
      >
        <va-data-table
          :fields="fields"
          disableShow
          disableClone
          disable-create-redirect
          disable-edit-redirect
          disable-delete-redirect
          @item-action="onAction"
        >
        </va-data-table>
      </va-list>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["resource", "title"],
  data() {
    return {
      asideTitle: null,
      show: false,
      item: null,
      id: null,
      filters: [{
          alwaysOn: true,
          label: "名称",
          source: "name",
        },
        {
          alwaysOn: true,
          label: "编码",
          source: "code",
        },],
      fields: [
        { source: "name", label: "名称", sortable: true, align: "center" },
        { source: "code", label: "编码", sortable: true, align: "center" },
        {
          source: "parentcode",
          label: "上级编码",
          sortable: true,
          align: "center",
        },
        {
          source: "office_code",
          label: "科室编码",
          sortable: true,
          align: "center",
        },
        {
          source: "office_name",
          label: "科室名称",
          sortable: true,
          align: "center",
        },
        { source: "remark", label: "备注", sortable: true, align: "center" },
        {
          source: "create_time",
          label: "创建时间",
          sortable: true,
          align: "center",
          type: "date",
        },
      ],
    };
  },
  methods: {
    onSaved() {
      this.asideTitle = null;
      this.$refs.list.fetchData();
    },
    async onAction({ action, title, id, item }) {
      this.asideTitle = title;
      this.id = id;
      this.show = action === "show";
      this.item = item;
      if (action === "create") {
        this.id = null;
      }
    },
  },
};
</script>
