<template>
  <div>
    <va-aside-layout :title="asideTitle">
      <medical-technology-project-form
        v-if="!show"
        :id="id"
        :item="item"
        @saved="onSaved"
      ></medical-technology-project-form>
    </va-aside-layout>
    <v-card :icon="resource.icon" :title="title">
      <va-list
        ref="list"
        :filters="filters"
        disable-global-search
        disable-pagination
        disable-create-redirect
        disable-export
        @action="onAction"
        :sort-by="['id']"
        :sort-desc="[true]"
        resource="bs_hospital"
      >
        <v-data-table
          @item-action="onAction"
          :headers="headers"
          :items="desserts"
          hide-default-footer
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  class="mr-2"
                  color="blue"
                  @click="editItem(item)"
                  v-bind="attrs"
                  v-on="on"
                  v-if="$admin.can(['change_examinationdictionaries'])"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span>编辑</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  class="mr-2"
                  color="red"
                  @click="deleteItem(item)"
                  v-bind="attrs"
                  v-on="on"
                  v-if="$admin.can(['delete_examinationdictionaries'])"
                >
                  mdi-trash-can
                </v-icon>
              </template>
              <span>删除</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <div style="width: 100%; display: flex; justify-content: flex-end">
          <v-pagination
            v-model="page"
            :length="length_data"
            :total-visible="7"
          ></v-pagination>
        </div>
      </va-list>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["resource", "title"],
  data() {
    return {
      filters: [],
      page: 1,
      length_data: null,
      form: {},
      asideTitle: null,
      id: null,
      item: null,
      show: false,
      desserts: [],
      headers: [
        { text: "项目编码", value: "project_code", sortable: false },
        { text: "项目名称", value: "project_name", sortable: false },
        { text: "部位", value: "hospital_name", sortable: false },
        { text: "所属分类", value: "hospital_name", sortable: false },
        { text: "平均时长（倍数）", value: "hospital_name", sortable: false },
        { text: "操作", value: "actions", sortable: false },
      ],
      user_data: null,
    };
  },
  mounted() {
    this.user_data = JSON.parse(
      window.sessionStorage.getItem("user")
    ).is_superuser;
  },
  methods: {
    adjustment() {
      let obj_key = Object.keys(this.form);
      let src_key = "";
      obj_key.forEach((key) => {
        src_key = src_key + "&" + key + "=" + `${this.form[key]}`;
      });
      src_key = src_key.slice(1);
      if (src_key) {
        // 修改接口
        this.$admin.http.get(`base-system/exa_dic/?${src_key}`).then((res) => {
          this.length_data =
            (res.data.count / 10) % 1 === 0
              ? res.data.count / 10
              : parseInt(res.data.count / 10) + 1;
          this.desserts = res.data.results;
        });
      }
    },
    async onAction({ action, title, id, item }) {
      this.asideTitle = "新建项目管理";
      this.id = id;
      this.show = action === "show";
      this.item = item;
      if (action === "create") {
        this.id = null;
      }
    },
    onSaved() {
      this.asideTitle = null;
      this.$refs.list.fetchData();
    },
    editItem(item) {
      this.id = null;
      this.asideTitle = `编辑项目管理`;
      this.id = item.id;
      this.item = item;
      this.asideid = item.id;
      this.show = false;
    },
    async deleteItem(item) {
      this.$confirm("此操作将删除该数据，是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // 调接口
          // this.$store
          //   .dispatch("bs_examination_dictionaries/delete", {
          //     id: item.id,
          //   })
          //   .then((res) => {});
        })
        .catch(() => {});
    },
  },
  watch: {
    page: {
      deep: true,
      immediate: true,
      handler(val) {
        this.show = true;
        if (val == "" || val == null || val == undefined) {
          delete this.form.page;
          this.adjustment();
        } else {
          delete this.form.page;
          this.form.page = val;
          this.adjustment();
        }
      },
    },
  },
};
</script>
<style lang="sass" scoped>
.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined)
  background-color: #eee
  color: #14B2C5
  &:hover
    background-color: #ccc
    color: #214578
.custom_btn.v-item-group
  left: 0
  position: absolute

.v-btn.v-size--default
  margin-top: 5px !important
  font-size: 1rem
</style>
<style scoped>
::v-deep .el-input__inner {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1px #d2d2d2 solid !important;
  border-radius: 0;
  padding-bottom: 10px;
}
.select .el-icon-circle-close,
.el-input__prefix {
  display: block !important;
}

.custom_btn.v-item-group {
  left: 0;
  position: absolute;
}
</style>
