<template>
  <va-form :id="id" :item="item" @saved="$emit('saved')" ref="form">
    <v-card-text>
      <va-text-input
        label="服务组名称"
        required
        source="project_name"
        v-model="form.project_name"
      ></va-text-input>
      <va-text-input
        label="工作描述"
        required
        source="project_name"
        v-model="form.project_name"
      ></va-text-input>
      <va-text-input
        label="工作地址"
        required
        source="project_name"
        v-model="form.project_name"
      ></va-text-input>
      <va-select-input
        required
        label="执行科室"
        item-value="codenum"
        reference="bs_office"
        :items-per-page="1000"
        source="office_code"
        v-model="form.office_code"
      ></va-select-input>
      <va-text-input
        v-model="form.describe"
        label="注意事项"
        source="describe"
        multiline
      ></va-text-input>
      <v-container fluid style="display: flex">
        <!-- <p>{{ selected }}</p> -->
        <v-checkbox
          v-model="selected"
          label="门诊"
          value="1"
          style="margin-right: 50px"
        ></v-checkbox>
        <v-checkbox
          style="margin-right: 50px"
          v-model="selected"
          label="住院"
          value="2"
        ></v-checkbox>
      </v-container>
    </v-card-text>
    <div class="btn_bg">
      <v-btn @click="isSure" color="primary">保存</v-btn>
    </div>
  </va-form>
</template>

<script>
import Utils from "../../global/utils";
export default {
  props: ["id", "title", "item"],
  data() {
    return {
      selected: [],
      form: {
        project_name: null,
        created_time: null,
      },
    };
  },
  created() {},
  mounted() {},
  watch: {
    id: {
      deep: true,
      immediate: true,
      handler(val) {},
    },
  },
  computed: {},
  methods: {
    async isSure() {
      if (!this.form.project_name) {
        this.$message({
          message: "有必填项未填写",
          type: "warning",
        });
        return;
      }
      this.$store
        .dispatch("bs_examination_dictionaries/create", {
          data: this.form,
        })
        .then((res) => {
          this.$emit("saved");
        });
      setTimeout(() => {
        Utils.$emit("open", true);
      }, 200);
    },
  },
};
</script>
<style scoped>
.btn_bg {
  width: 95%;
  position: fixed;
  bottom: 0px;
  padding: 10px 0;
  text-align: center;
  background-color: white;
}
::v-deep .el-input__inner {
  background-color: #f0f0f0 !important;
  border-color: rgba(255, 255, 255, 0.5);
  color: #707070;
  height: 60px;
}
.container {
  margin-top: -20px;
}
</style>
