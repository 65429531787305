<template>
  <va-form :id="id" :item="item" @saved="$emit('saved')" ref="form">
    <v-card-text>
      <va-text-input
        source="type_code"
        multiline
        required
        v-model="form.type_code"
      ></va-text-input>
      <va-text-input
        source="type_name"
        required
        v-model="form.type_name"
      ></va-text-input>
      <va-select-input
        source="parent_id"
        v-model="form.parent_id"
        reference="inspection_type"
        label="上级类型"
        :items-per-page="1000"
      ></va-select-input>
      <va-boolean-input
        source="is_active"
        v-model="form.is_active"
      ></va-boolean-input>
    </v-card-text>
    <div class="btn_bg">
      <!-- <va-save-button></va-save-button> -->
      <v-btn @click="isSure" color="primary">保存</v-btn>
    </div>
  </va-form>
</template>

<script>
import moment from "moment";
export default {
  props: ["id", "title", "item"],
  data() {
    return {
      form: {
        type_name: null,
        type_code: null,
        parent_id: null,
        is_active: true,
      },
    };
  },
  methods: {
    isSure() {
      if (!this.id) {
        this.$store
          .dispatch("inspection_type/create", {
            data: this.form,
          })
          .then((res) => {
            this.$emit("saved");
          });
      }else{
        this.form.updated_by = moment().format("YYYY-MM-DD")
        this.$store
          .dispatch("inspection_type/update", {
            id: this.id,
            data: this.form,
          })
          .then((res) => {
            this.$emit("saved");
          });
      }
    },
  },
};
</script>
<style scoped>
.btn_bg {
  width: 95%;
  position: fixed;
  bottom: 0px;
  padding: 10px 0;
  text-align: center;
  background-color: white;
}
</style>