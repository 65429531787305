<template>
  <v-card :icon="resource.icon" :title="title">
    <va-list
      :filters="filters"
      :filter="{ reg_type: type_code }"
      disable-create
      ref="list"
      :sort-by="['id']"
      :sort-desc="[true]"
      global-search-query="patient_name@_like,id_num@_like,hospital_name@_like,office_name@_like,doctor_name@_like"
      resource="os_outpatient_registration"
    >
      <template v-slot:actions>
        <va-action-button
          label="普通挂号"
          text
          icon="mdi-plus"
          color="success"
          @click="created_data('普通挂号')"
          style="color: #4caf50"
        >
        </va-action-button>
        <va-action-button
          label="急诊挂号"
          text
          icon="mdi-plus"
          color="success"
          @click="created_data('急诊挂号')"
          style="color: #4caf50"
        >
        </va-action-button>
        <!-- <va-action-button
          icon="mdi-briefcase-download-outline"
          label="下载模版"
          :href="template_download_url"
          color="green"
          text
        >
        </va-action-button>
        <va-action-button
          icon="mdi-import"
          color="green"
          label="导入"
          @click="on_show_import"
          text
        >
        </va-action-button>
        <va-action-button
          icon="mdi-arrow-collapse-down"
          color="green"
          label="导出"
          text
          @click="on_export_excel"
        >
        </va-action-button> -->
      </template>
      <va-data-table
        disable-edit
        disable-delete
        :fields="fields"
        disable-clone
        disable-edit-redirect
        disable-show
      >
        <template v-slot:[`field.noontype_code`]="{ item }">
          <template v-if="item.noontype_code == 'am'"> 上午 </template>
          <template v-else-if="item.noontype_code == 'pm'"> 下午 </template>
          <template v-else> 晚上 </template>
        </template>
        <template v-slot:[`field.reg_category`]="{ item }">
          <template v-if="item.reg_category == '1'"> 普通 </template>
          <template v-else> 急诊 </template>
        </template>
        <template v-slot:[`field.state`]="{ item }">
          <template v-if="item.state == '1'"> 已预约 </template>
          <template v-else-if="item.state == '2'"> 已完成 </template>
          <template v-else> 爽约 </template>
        </template>
      </va-data-table>
    </va-list>
  </v-card>
</template>

<script>
export default {
  props: ["resource", "title", "type"],
  data() {
    return {
      filters: [
        // {
        //   alwaysOn: true,
        //   label: "医院姓名",
        //   source: "hospital_name",
        // },
        {
          alwaysOn: true,
          label: "科室姓名",
          source: "office_name",
        },
        {
          alwaysOn: true,
          label: "医生姓名",
          source: "doctor_name",
        },
        {
          alwaysOn: true,
          label: "挂号类别",
          source: "reg_category",
          type: "select",
          
        },
      ],
      fields: [
        { source: "id_num", label: "身份证号" },
        { source: "patient_name", label: "患者姓名" },
        { source: "visit_date", label: "挂号日期" },
        { source: "noontype_code", label: "时段" },
        { source: "hospital_name", label: "医院名称" },
        { source: "office_name", label: "科室名称" },
        { source: "doctor_name", label: "医生姓名" },
        { source: "emergency_room", label: "急诊诊室" },
        { source: "reg_category", label: "挂号类别" },
        { source: "state", label: "状态" },
      ],
      type_code: null,
      filter: {},
    };
  },
  mounted() {
    if (this.type) {
      this.type_code = this.type;
    } else {
      this.type_code = 3;
    }
  },
  created() {
    this.getSupper();
  },
  methods: {
    getSupper() {
      let user_data = JSON.parse(
        window.sessionStorage.getItem("user")
      );
      if (user_data.is_superuser) {
        this.filters.push({
          source: "hospital_name",
          label: "所属医院",
          type: "text",
          alwaysOn: true,
        });
      }else{
        this.filter = {hospital_name: user_data.hospital_name}
      }
    },
    created_data(data) {
      this.$router.push({
        path: "/os-outpatient-registration/create",
        query: { data: data, type: this.type_code },
      });
    },
  },
};
</script>
