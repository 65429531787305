<template>
    <ws-doctor-work-scheduling-main-list :resource="resource" :type_fee="2" :form_title="title"></ws-doctor-work-scheduling-main-list>
</template>

<script>

export default {
  props: ["resource"],
  data(){
    return{
      title: "电话问诊"
    }
  }
};
</script>