<template>
  <v-form ref="form" @submit.prevent="validate" class="biaodan">
    <v-text-field
      
      color="#333"
      :label="$t('auth.username')"
      prepend-icon="mdi-account"
      v-model="form.username"
      required
      :error-messages="errorMessages.username"
      style="height: 6vh;margin-bottom: 5px;"
    ></v-text-field>

    <v-text-field
     
      color="#333"
      :label="$t('auth.password')"
      prepend-icon="mdi-lock"
      type="password"
      v-model="form.password"
      required
      :error-messages="errorMessages.password"
    ></v-text-field>
    <!-- <v-text-field
     color="#333"
     label="请输入验证吗"
     prepend-icon="mdi-lock"
     required
     :error-messages="errorMessages.code"
   ></v-text-field> -->
    <!-- <div style="margin-top:4vh;text-align: right;">
      <v-btn
        class="indigo--text"
        height="40px"
        :loading="loading"
        large
        type="submit"
        style="text-align: right;background-color: #069CAE;color: white !important;"
      >
        {{ $t("auth.sign_in") }}</v-btn
      >
    </div> -->
    <div style="margin-top: 2vh;text-align: right;">
      <v-checkbox style="margin-right:140px;display:inline-block"
      v-model="checkbox"
      :label="`记住登录状态`"
    ></v-checkbox>
      <v-btn
        class="indigo--text"
        height="40px"
        :loading="loading"
        large
        type="submit"
        style="text-align: right;display: flex-end;background-color: #069CAE;color: white !important"
      >
        {{ $t("auth.sign_in") }}</v-btn
      >
    </div>
  </v-form>
</template>

<script>
import { mapActions } from "vuex";
import { enDES3, deDES3 } from "@/util/des";
export default {
  data() {
    return {
      form: {
        username: "",
        password: "",
        code:"",
        remember: false,
        key: "",
      },
      errorMessages: {
        username: "",
        password: "",
        code: "",
      },
      loading: false,
      key_data: "",
      checkbox:false
    };
  },
  methods: {
    ...mapActions({
      login: "auth/login",
    }),
    async validate() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        try {
          let username = this.form.username;
          let password = this.form.password;
          var datatransfer = {
            username: enDES3(username, this.key_data),
            password: enDES3(password, this.key_data),
            key: this.key_data,
          };
          await this.login(datatransfer);
        } catch (e) {
          if (e.errors) {
            this.errorMessages = e.errors;
            return;
          }
          this.errorMessages = {
            username: ["您输入的用户名有误"],
            password: ["您输入的密码有误"],
            code: ["您输入的验证码有误"],
         
          };
        } finally {
          this.loading = false;
        }
      }
    },
  },
  mounted() {
    this.key_data = "K3bDD6Zytur5RLCJ";
  },
};
</script>
