<!--
 * @Description: 
 * @Author: your name
 * @Date: 2023-08-09 10:40:53
 * @LastEditors: your name
 * @LastEditTime: 2023-08-31 11:05:51
-->
<template>
  <v-navigation-drawer
    :clipped="$vuetify.breakpoint.lgAndUp"
    right
    app
    disable-resize-watcher
    :width="width"
    v-model="opened"
  >
    <div class="pa-4">
      <div class="d-flex align-center">
        <h3 class="display-1">
          <portal-target name="aside-title"></portal-target>
        </h3>
        <v-btn class="close" icon @click="open">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div class="content">
        <portal-target
          name="aside-content"
          @change="handleUpdate"
        ></portal-target>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import Utils from "../global/utils";
/**
 * Customizable admin aside component where you put some contextualized additional information.
 * Use the associated `VaAsideLayout` component for content integration from anywhere on any context.
 */
export default {
  inject: ["reload"],
  props: {
    /**
     * Width of the aside
     */
    width: {
      type: Number,
      default: 400,
    },
  },
  data() {
    return {
      opened: false,
    };
  },
  created() {
    let that = this;
    Utils.$off("open")
    Utils.$on("open", function (msg) {
      if(msg==true) {
        that.open(); //这里就是调用你的方法
      }
    });
  },
  methods: {
    handleUpdate(newContent) {
      this.opened = newContent;
    },
    open() {
      // console.log(this.opened);
      this.opened = false;
      // console.log(1111,this.opened);
    },
  },
};
</script>

<style scoped>
.close {
  position: absolute;
  right: 1rem;
}
.content {
  margin-top: 3rem;
}
</style>