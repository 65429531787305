<script>
import SelectInput from "vuetify-admin/src/components/ui/inputs/SelectInput";
export default {
  extends: SelectInput,
  watch: {
    filter: async function () {
      this.items = await this.fetchChoices();
    },
    sortBy: async function () {
      this.items = await this.fetchChoices();
    },
    sortDesc: async function () {
      this.items = await this.fetchChoices();
    },
    include: async function () {
      this.items = await this.fetchChoices();
    },
    itemsPerPage: async function () {
      this.items = await this.fetchChoices();
    },
    disableItemsPerPage: async function () {
      this.items = await this.fetchChoices();
    },
  },
};
</script>