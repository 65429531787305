<template>
  <va-form :id="id" :item="item" @saved="$emit('saved')" ref="form">
    <v-card-text>
      <va-text-input
        source="project_code"
        v-model="form.project_code"
      ></va-text-input>
      <va-text-input
        source="project_name"
        v-model="form.project_name"
      ></va-text-input>
      <va-select-input
        v-if="user_data.is_superuser"
        label="所属医院"
        item-value="codenum"
        reference="bs_hospital"
        :items-per-page="1000"
        source="hospital_code"
        v-model="form.hospital_code"
      ></va-select-input>
      <SelectInput
        item-value="codenum"
        source="office_code"
        reference="bs_office"
        v-model="form.office_code"
        @office="getOffice"
        :items="items_data"
        :label="label_name"
        :entrust="entrust"
      ></SelectInput>
      <!-- <va-text-input
        source="hospital_name"
        v-model="form.hospital_name"
      ></va-text-input>
      <va-text-input
        source="office_name"
        v-model="form.office_name"
      ></va-text-input> -->
      <el-cascader
        v-model="form.code_srvtp"
        :props="{ emitPath: false, value: 'code_srvtp', label: 'name_srvtp' }"
        style="width: 100%; margin-bottom: 3vh"
        placeholder="试试搜索：'类别名称'"
        :options="options"
        filterable
      ></el-cascader>
      <va-text-input
        label="类别编码"
        source="code_srvtp"
        v-model="form.code_srvtp"
      ></va-text-input>
      <va-text-input
        source="project_fees"
        v-model="form.project_fees"
      ></va-text-input>
      <va-text-input source="remarks" v-model="form.remarks"></va-text-input>
    </v-card-text>
    <div class="btn_bg">
      <v-btn @click="isSure" color="primary">保存</v-btn>
    </div>
  </va-form>
</template>

<script>
import Utils from "../../global/utils";
import SelectInput from "@/components/FilterData.vue";
export default {
  props: ["id", "title", "item", "type"],
  components: { SelectInput },
  data() {
    return {
      form: {
        project_code: null,
        project_name: null,
        hospital_code: null,
        office_code: null,
        project_fees: null,
        remarks: null,
        name_srvtp: null,
        code_srvtp: null,
      },
      hospitalId: null,
      options: null,
      label_name: "所属科室",
      items_data: null,
      user_data: null,
      entrust: null,
    };
  },
  created() {
    this.user_data = JSON.parse(window.sessionStorage.getItem("user"));
    if (!this.user_data.is_superuser) {
      this.form.hospital_code = this.user_data.hospital_code;
    }
  },
  mounted() {
    this.$admin.http.get(`/base-system/inspectiontypes/`).then((res) => {
      this.options = res.data;
    });
  },
  watch: {
    "form.hospital_code": {
      deep: true,
      immediate: true,
      handler(val) {
        if (val !== undefined) {
          this.$store
            .dispatch("bs_hospital/getList", {
              filter: {
                codenum: val,
              },
            })
            .then((res) => {
              this.hospitalId = res.data[0].id;
              if (this.hospitalId) {
                this.$store
                  .dispatch("bs_office/getList", {
                    filter: {
                      hospital_id: this.hospitalId,
                    },
                  })
                  .then((res) => {
                    res.data.forEach((item) => {
                      item.text = item.name;
                    });
                    this.items_data = res.data;
                  });
              } else {
                this.$store.dispatch("bs_office/getList").then((res) => {
                  res.data.forEach((item) => {
                    item.text = item.name;
                  });
                  this.items_data = res.data;
                });
              }
            });
        }
      },
    },
    "form.code_srvtp": {
      deep: true,
      immediate: true,
      handler(val) {
        this.$store
          .dispatch("bs_inspection_type/getList", {
            filter: {
              code_srvtp: val,
            },
          })
          .then((res) => {
            this.form.name_srvtp = res.data[0].name_srvtp;
          });
      },
    },
    id: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val && this.item.office_code) {
          this.$store
            .dispatch("bs_office/getOne", {
              codenum: this.item.office_code,
            })
            .then((res) => {
              if (res.data.id) {
                this.entrust = res.data.id;
              }
            });
        } else {
          this.entrust = null;
        }
      },
    },
  },
  methods: {
    getOffice(msg) {
      this.form.office_code = msg.codenum;
    },
    isSure() {
      if (
        !this.form.project_code ||
        !this.form.project_name ||
        !this.form.hospital_code ||
        !this.form.office_code ||
        !this.form.name_srvtp ||
        !this.form.code_srvtp ||
        !this.form.project_fees
      ) {
        this.$message({
          message: "有必填项未填写",
          type: "warning",
        });
        return;
      }
      if (!this.id) {
        this.form.distinguish = "0";
        this.form.is_active = true;
        this.$store
          .dispatch("bs_inspection_dictionaries/create", {
            data: this.form,
          })
          .then((res) => {
            this.$emit("saved");
          });
      } else {
        this.$store
          .dispatch("bs_inspection_dictionaries/update", {
            id: this.id,
            data: this.form,
          })
          .then((res) => {
            this.$emit("saved");
          });
      }
      setTimeout(() => {
        Utils.$emit("open", true);
      }, 200);
    },
  },
};
</script>
<style scoped>
.btn_bg {
  width: 95%;
  position: fixed;
  bottom: 0px;
  padding: 10px 0;
  text-align: center;
  background-color: white;
}
::v-deep .el-input__inner {
  background-color: #f0f0f0 !important;
  border-color: rgba(255, 255, 255, 0.5);
  color: #707070;
  height: 60px;
}
</style>