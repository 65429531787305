<template>
  <v-app-bar
    :clipped-left="$vuetify.breakpoint.lgAndUp"
    :clipped-right="$vuetify.breakpoint.lgAndUp"
    app
    :dark="dark"
    :color="color"
    :dense="dense"
  >
    <v-app-bar-nav-icon @click.stop="$emit('toggle')" />
    <v-toolbar-title class="ml-0 pl-4" style="width: 200px">
      <span class="hidden-sm-and-down">{{ title || $admin.title }}</span>
    </v-toolbar-title>
    <ul
      v-if="headerMenu.length <= 8 && $vuetify.breakpoint.lgAndUp"
      style="display: flex; flex-wrap: wrap"
    >
      <li
        v-for="(item, i) in headerMenu"
        :key="i"
        class="text-center mb-sm-0 mb-5"
        cols="auto"
        style="display: inline-block"
      >
        <component
          :is="item.href ? 'a' : 'router-link'"
          href="javascript:void(0);"
          :to="item.link"
          @click="linktowhere(item)"
          class="px-3 white--text link"
          target="_self"
          v-text="item.text"
        ></component>
      </li>
    </ul>
    <ul
      v-if="headerMenu.length > 8 && $vuetify.breakpoint.lgAndUp"
      style="display: flex; flex-wrap: wrap"
    >
      <li
        v-for="(item, i) in headerMenu.slice(0, 8)"
        :key="i"
        style="display: inline-block"
      >
        <component
          :is="item.href ? 'a' : 'router-link'"
          href="javascript:void(0);"
          :to="item.link"
          @click="linktowhere(item)"
          class="px-3 white--text link"
          target="_self"
          v-text="item.text"
        ></component>
      </li>
      <li>
        <el-dropdown style="padding: 0 10px">
          <span class="el-dropdown-link">
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(item, i) in headerMenu.slice(8)"
              :key="i"
              @click.native="toPage(item)"
              >{{ item.text }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </li>
    </ul>

    <v-spacer />
    <div>
      <v-menu offset-y v-if="!disableCreate && createResourceLinks.length">
        <template v-slot:activator="{ on }">
          <v-btn icon small v-on="on" :title="$t('va.actions.create')">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>

        <v-list nav dense>
          <v-list-item
            v-for="(item, index) in createResourceLinks"
            :key="index"
            link
            :to="item.link"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn
        v-if="!disableReload"
        icon
        small
        class="ml-5"
        :loading="$store.state.api.loading"
        @click="refresh"
      >
        <v-icon>mdi-refresh</v-icon>
      </v-btn>

      <v-badge
        color="red"
        :content="length_data"
        :overlap="true"
        :value="length_data"
      >
        <v-btn icon small class="ml-5" @click="router">
          <v-icon>mdi-bell</v-icon>
        </v-btn>
      </v-badge>
      <v-menu offset-y v-if="$store.state.auth.user">
        <template v-slot:activator="{ on }">
          <v-btn icon small class="ml-5" v-on="on">
            <v-icon>mdi-account-circle</v-icon>
          </v-btn>
        </template>

        <v-list nav dense>
          <template v-if="getName">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="title">{{
                  getName
                }}</v-list-item-title>
                <v-list-item-subtitle v-if="getEmail">{{
                  getEmail
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>
          </template>

          <v-list-item
            v-for="(item, index) in profileMenu"
            :key="index"
            link
            :to="item.link"
            class="mt-2"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="logout()">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ $t("va.logout") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </v-app-bar>
</template>

<script>
/**
 * Default customizable admin VAppBar.
 * Contains main app title, header menus, direct resource creation links, global refresh action, profile menu.
 * Profile user dropdown will not appear on guest mode.
 */
export default {
  data() {
    return {
      length_data: null,
    };
  },
  props: {
    /**
     * Replace default admin app title set on VuetifyAdmin constructor.
     */
    title: String,
    /**
     * Header links visible on left side.
     */
    headerMenu: {
      type: Array,
      default: () => [],
    },
    /**
     * Profile related links, visible inside authenticated dropdown menu.
     */
    profileMenu: {
      type: Array,
      default: () => [],
    },
    /**
     * Disable create menu.
     */
    disableCreate: Boolean,
    /**
     * Disable reload state button.
     */
    disableReload: Boolean,
    /**
     * Color for the VAppBar.
     */
    color: {
      type: String,
      default: "primary",
    },
    /**
     * Reduce height of VAppBar
     */
    dense: Boolean,
    /**
     * Apply dark theme variant for VAppBar
     */
    dark: Boolean,
  },
  computed: {
    createResourceLinks() {
      return this.$admin.getResourceLinks(
        this.$admin.resources.map((r) => {
          return {
            name: r.name,
            action: "create",
          };
        })
      );
    },

    getName() {
      return this.$store.getters["auth/getName"];
    },
    getEmail() {
      return this.$store.getters["auth/getEmail"];
    },
  },
  mounted() {
    // this.getLengh();
  },
  methods: {
    toPage(item) {
      this.$router.push(item.href);
    },
    linktowhere(path) {
      if (path.href === this.flag) {
        return;
      } else {
        this.flag = path.href;
        this.$router.push({
          path: path.href,
        });
      }
    },
    router() {
      this.$router.push("/inform/");
    },
    refresh() {
      this.$store.dispatch("api/refresh", this.$route.meta.resource);
    },
    logout() {
      this.$store.dispatch("auth/logout");
    },
    // getLengh() {
    //   this.$store.dispatch("inform_inform/getList", {
    //     filter: {
    //       is_read: false,
    //       to_user_id: JSON.parse(window.sessionStorage.getItem("user")).id,
    //     },
    //   }).then((item) => {
    //     this.length_data = item.data.length;
    //   });
    // },
  },
};
</script>

<style scoped>
.link {
  font-size: 0.825rem;
  font-weight: 500;
  text-decoration: none;
  text-transform: uppercase;
}
ul > li {
  list-style: none;
  /* width: 8vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; */
}
.el-dropdown-link {
  cursor: pointer;
  color: #fff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
</style>