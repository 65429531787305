<!--
 * @Description: 
 * @Author: your name
 * @Date: 2023-05-08 14:31:12
 * @LastEditors: your name
 * @LastEditTime: 2023-05-13 17:04:20
-->
<template>
  <va-show :item="item">
    <va-field source="note" label="驳回原因"></va-field>
    <div class="btn_bg">
      <v-btn @click="goCreat(item)" color="primary">再次申请</v-btn>
    </div>
  </va-show>
</template>

<script>
export default {
  props: ["title", "item"],
  created() {
    console.log(this.item,'12324521');
  },
  methods: {
    goCreat(item) {
      console.log(item, "showshow");
      this.$router.push({
        path: `hap-bed-apt-record/${item.id}/edit`,
        query: { type: 3 },
      });
    },
  },
};
</script>
<style scoped>
.btn_bg {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0px;
  padding: 10px 10px;
  background-color: white;
}
</style>
