<template>
  <va-form :id="id" :item="item" @saved="$emit('saved')">
    <v-card-text>
      <va-text-input
        v-model="form.codenum"
        label="•职级编码"
        source="codenum"
        required
      ></va-text-input>
      <va-text-input
        v-model="form.name"
        label="•职级名称"
        source="name"
        required
      ></va-text-input>
      <!-- <va-text-input source="hospital_id" required></va-text-input> -->
      <va-select-input
        v-if="user.is_superuser"
        source="hospital_id"
        label="•所属医院"
        reference="bs_hospital"
        required
        v-model="form.hospital_id"
        :items-per-page="1000"
        :filter="{ is_active: true }"
      ></va-select-input>
    </v-card-text>
    <div class="btn_bg">
      <v-btn @click="isSure" color="primary">保存</v-btn>
    </div>
  </va-form>
</template>

<script>
import moment from 'moment'
import { mapState } from "vuex";
export default {
  props: ["id", "title", "item"],
  data() {
    return {
      form: {
        codenum: "",
        name: "",
        hospital_id: "",
      },
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  mounted() {
    if (!this.user.is_superuser) {
      this.form.hospital_id = this.user.hospital;
    }
  },

  methods: {
    async isSure() {
      if (
        !this.form.codenum ||
        !this.form.name ||
        !this.form.hospital_id
      ) {
        this.$message({
          message: "有必填项未填写",
          type: "warning",
        });
        return;
      }
      if (this.id) {
        this.form.updated_by = this.user.username;
        try {
          await this.$store
            .dispatch("bs_position_title/update", {
              id: this.id,
              data: this.form,
            })
            .then((res) => {
              this.$emit("saved");
            });
        } catch {
          this.$message({
            message: "修改失败",
            type: "warning",
          });
        }
      } else {
        this.form.created_by = this.user.username;
        this.form.created_time = moment().format('YYYY-MM-DD HH:mm:ss');

        try {
          await this.$store
            .dispatch("bs_position_title/create", {
              data: this.form,
            })
            .then((res) => {
              this.$emit("saved");
            });
        } catch {
          this.$message({
            message: "添加失败",
            type: "warning",
          });
        }
      }
    },
  },
};
</script>
<style scoped>
.btn_bg {
  width: 95%;
  position: fixed;
  bottom: 0px;
  padding: 10px 0;
  text-align: center;
  background-color: white;
}
</style>
