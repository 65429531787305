<template>
  <base-material-card :icon="resource.icon" :title="title">
    <va-list
      :filters="filters"
    >
      <va-data-table :fields="fields">
      </va-data-table>
    </va-list>
  </base-material-card>
</template>

<script>
export default {
  props: ["resource", "title"],
  data() {
    return {
      filters: [
  'group_name 所属服务组名称',
  'group_id 所属服务组ID',
  'device_code 设备编码',
  'device_name 设备名称',
  'is_active   启用'
],
      fields: [
  'group_name 所属服务组名称',
  { source: 'group_id 所属服务组ID', sortable: true },
  { source: 'device_code 设备编码', sortable: true },
  { source: 'device_name 设备名称', sortable: true },
  'device_addr 地址',
  'device_type 可用的诊疗类型',
  'create_time   创建时间',
  { source: 'is_active   启用', sortable: true },
  'remark\t备注'
],
    };
  },
};
</script>
