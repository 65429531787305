<!--
 * @Description: 
 * @Author: your name
 * @Date: 2023-04-27 14:54:55
 * @LastEditors: your name
 * @LastEditTime: 2023-08-10 16:35:03
-->
<template>
  <va-form :id="id" :item="item">
    <v-card class="v_card">
      <div style="line-height: 60px">
        <span class="dot_circle"></span><span class="title_con">患者信息</span>
      </div>
      <v-row>
        <v-col sm="4">
          <v-text-field
            label="身份证号"
            source="id_num"
            v-model="form.id_num"
            :disabled="isReadOnly"
            :rules="rules"
            filled
          ></v-text-field>
        </v-col>

        <v-col sm="4">
          <v-text-field
            label="患者姓名"
            source="patient_name"
            :rules="rules1"
            :disabled="isReadOnly"
            v-model="form.patient_name"
            filled
          ></v-text-field>
        </v-col>
        <!-- <v-col sm="4">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="form.planned_admission_time"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="form.planned_admission_time"
                label="计划入住时间"
                prepend-icon="mdi-calendar"
                readonly
                filled
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="form.planned_admission_time"
              :min="minTime"
              :max="maxTime"
              no-title
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal = false"> 取消 </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.menu.save(form.planned_admission_time)"
              >
                确定
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col> -->
      </v-row>
    </v-card>
    <v-card class="v_card" style="overflow-y: scroll; padding-bottom: 80px">
      <div style="line-height: 60px">
        <span class="dot_circle"></span
        ><span class="title_con">手术室信息</span>
      </div>
      <v-row>
        <v-col sm="4">
          <va-select-input
            label="预约医院"
            item-value="codenum"
            reference="bs_hospital"
            :items-per-page="1000"
            source="hospital_code"
            v-model="form.hospital_code"
          ></va-select-input>
        </v-col>
        <v-col sm="4">
          <SelectInput
            item-value="opr_code"
            item-text="opr_name"
            source="opr_code"
            v-model="form.opr_code"
            reference="opr_operating_room_management"
            label="手术室"
            :items-per-page="1000"
            ref="dept"
            :filter="opr_filter"
          ></SelectInput>
        </v-col>
        <!-- <v-col sm="4">
          <el-date-picker
            class="select"
            lable="时间"
            :picker-options="pickerOptions"
            size="large"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            v-model="date_value"
            type="date"
            placeholder="请选择日期"
          >
          </el-date-picker>
        </v-col> -->
        <v-col sm="4">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date_value"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date_value"
                label="预约时间"
                prepend-icon="mdi-calendar"
                readonly
                filled
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date_value"
              :min="minTime"
              :max="maxTime"
              no-title
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal = false"> 取消 </v-btn>
              <v-btn text color="primary" @click="$refs.menu.save(date_value)">
                确定
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col sm="4">
          <el-time-picker
            style="
              width: 100%;
              height: 58px;
              line-height: 58px;
              background-color: #f0f0f0;
              text-align: center;
            "
            is-range
            size="medium"
            v-model="date_value1"
            format="HH:mm"
            value-format="HH:mm"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            placeholder="选择时间范围"
          >
          </el-time-picker>
          <!-- <el-date-picker
            class="select"
            lable="时间"
            size="large"
            v-model="date_value1"
            type="datetime"
            placeholder="选择结束时间"
          >
          </el-date-picker> -->
        </v-col>
      </v-row>
      <v-row v-if="isShow">
        <v-col sm="2"></v-col>
        <v-col sm="8">
          <table>
            <tr>
              <th v-for="(item, i) in oprList" :key="i" class="boxTh">
                {{ item.opr_name }}
              </th>
            </tr>
            <tr>
              <td class="boxTdone" v-for="(item, i) in oprList" :key="i">
                <div v-if="item.apt_records.length">
                  <div
                    v-for="(items, is) in item.apt_records"
                    class="tdDivCon"
                    :key="is"
                  >
                    <p>{{ items.patient_name }}</p>
                    <p>
                      {{ items.apt_date }}
                    </p>
                    <p>{{ items.start_time + "-" + items.end_time }}</p>
                  </div>
                </div>
                <div style="text-align: center" v-else>暂无数据</div>
              </td>
            </tr>

            <!-- <tr>
              <td class="boxTdone">数据4</td>
            </tr> -->
          </table>
        </v-col>
      </v-row>
    </v-card>

    <div class="btn_bg">
      <v-btn @click="close" style="color: #42b6c4; margin-right: 50px"
        >取消</v-btn
      >
      <v-btn @click="isSure" color="primary" v-if="isShowbtn">保存</v-btn>
    </div>
  </va-form>
</template>

<script>
import moment from "moment";
import SelectInput from "@/components/VaCustomSelectInput.vue";
export default {
  props: ["id", "title", "item"],
  components: {
    SelectInput,
  },
  data() {
    return {
      itemState: this.$route.query.type,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      form: {
        hospital_code: null,
        hospital_name: "",
        id_num: "",
        patient_name: "",
        is_active: true,
        opr_code: "",
        opr_name: "",
        apt_date: "",
        start_time: "",
        end_time: "",
        noontype_code: "",
        state: "1",
      },
      advance_day: "",
      minTime: moment().format("YYYY-MM-DD"),
      date_value1: null,
      date_value: "",
      menu: null,
      bedList: [],
      oprList: [],
      rules1: [(v) => (v && v.length <= 5) || "最多只能输入 5 个字符"],
    };
  },
  beforeDestroy() {},
  methods: {
    getConfigDay() {
      this.$store.dispatch("opr_apt_config/getList").then((res) => {
        if (res.data.length > 0) {
          this.advance_day = res.data[0].advance_day;
        }
      });
    },
    isSure() {
      this.form.apt_date = this.date_value;
      this.form.start_time = this.date_value1[0];
      this.form.end_time = this.date_value1[1];
      if (
        !this.form.hospital_code ||
        !this.form.hospital_name ||
        !this.form.opr_code ||
        !this.form.opr_name ||
        !this.form.apt_date ||
        !this.form.start_time ||
        !this.form.end_time ||
        !this.form.id_num ||
        !this.form.patient_name
      ) {
        console.log(this.form);
        this.$message({
          message: "有必填项未填写",
          type: "warning",
        });
        return;
      }

      if (this.id) {
        if (this.item.state == 2) {
          this.form.state = "0";
          this.$admin.http
            .put(`operating-room/appointment/${this.id}/`, this.form)
            .then((res) => {
              this.close();
            });
        } else {
          this.$admin.http
            .put(`operating-room/appointment/${this.id}/`, this.form)
            .then((res) => {
              this.close();
            });
        }
      } else {
        let params = {};
        params.id_num = this.form.id_num;
        params.patient_name = this.form.patient_name;
        this.$admin.http
          .post("hospitalization_apt/get_patcode/", params)
          .then((res) => {
            this.form.patient_code = res.data.patient_code;
            this.$admin.http
              .post("operating-room/appointment/", this.form)
              .then((res) => {
                this.close();
              })
              .catch((err) => {
                this.$message({
                  message: "保存失败",
                  type: "error",
                });
              });
          })
          .catch((err) => {
            this.$message({
              message: "获取患者编码失败",
              type: "error",
            });
          });
      }
    },
    close() {
      this.$router.push("/opr-operating-room-apt");
    },
    bedNumAdd(item) { 
      this.form.bed_num = item.bed_num;
    },
    reduce() {
      if (this.form.planned_length_stay == 0) {
        this.form.planned_length_stay = 0;
      } else {
        this.form.planned_length_stay--;
      }
    },
    getContent() {
      let data = {};
      data.apt_date = this.date_value;
      data.hospital_code = this.form.hospital_code;
      // ?apt_date=${newValue}&hospital_code=${this.form.hospital_code}
      this.$admin.http
        .post("operating-room/appointment/appoint_record/", data)
        .then((res) => {
          console.log(res, "resres");
          this.oprList = res.data;
        });
    },
  },
  computed: {
    rules() {
      const idNumberRegex =
        /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
      return [
        (v) => (v && v.length <= 18) || "最多只能输入 18 个字符",
        (v) => idNumberRegex.test(v) || "无效的身份证号码",
      ];
    },
    maxTime() {
      console.log(this.advance_day, "this.advance_day");
      return moment()
        .add("days", this.advance_day)
        .format("YYYY-MM-DD HH:mm:ss");
    },
    isReadOnly() {
      if (this.id) {
        return true;
      } else {
        return false;
      }
    },
    opr_filter() {
      if (this.form.hospital_code) {
        return { hospital_code: this.form.hospital_code, is_active: true };
      } else {
        return {};
      }
    },
    isShowbtn() {
      if (this.form.state == 1) {
        return true;
      } else {
        return false;
      }
    },
    isShow() {
      if (this.date_value) {
        return true;
      } else {
        return false;
      }
    },
    filteredList() {
      return this.oprList.filter((item) => item.apt_records.length);
    },
  },
  watch: {
    "form.hospital_code": {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue) {
          this.getContent();
          this.$store
            .dispatch("bs_hospital/getList", {
              filter: {
                codenum: newValue,
              },
            })
            .then((res) => {
              this.form.hospital_name = res.data[0].name;
            });
        }
      },
    },
    "form.opr_code": {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue) {
          this.$store
            .dispatch("opr_operating_room_management/getList", {
              filter: {
                opr_code: newValue,
              },
            })
            .then((res) => {
              console.log(res, "resres");

              this.form.opr_name = res.data[0].opr_name;
            });
        }
      },
    },
    date_value: {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue) {
          // if (!this.form.hospital_code) {
          //   this.$message({
          //     message: "请选择医院",
          //     type: "warning",
          //   });
          //   return;
          // }
          this.getContent();
        }
      },
    },
  },
  created() {
    this.getConfigDay();
    if (this.id) {
      this.form = this.item;
      this.date_value = this.item.apt_date;
      this.date_value1 = [this.item.start_time, this.item.end_time];
    }
    this.$route.query.items
      ? (this.form.id_num = this.$route.query.items.id_num)
      : null;
  },
};
</script>
<style scoped>
.btn_bg {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0px;
  padding: 10px 10px;
  background-color: white;
}
.v_card {
  padding: 20px;
  margin-bottom: 20px;
}

.red_sp {
  color: red;
}

.dot_circle {
  display: inline-block;
  background-color: #069cae;
  width: 8px;
  height: 8px;
  margin-right: 10px;
  border-radius: 8px;
  margin-bottom: 2px;
}

.title_con {
  display: inline-block;
  font-size: 20px;
}
.inputBoxs {
  width: 100%;
  height: 50px;
  background-color: #f0f0f0;
  padding: 5px;
}
.inputBox {
  width: 100%;
  height: 50px;
  background-color: #f0f0f0;
  padding: 5px;
  box-sizing: border-box;
  margin: 0px 10px;
}
.vColBox {
  display: flex;
  justify-content: center;
  align-items: center;
}
.select {
  background-color: #f0f0f0 !important;
  width: 100% !important;
}
::v-deep .select .el-input__inner {
  padding: 28px !important;
  background-color: #f0f0f0 !important;
}
:v-deep .select .el-range-input {
  padding: 28px !important;
  background-color: #f0f0f0 !important;
}
table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}
.boxTh {
  width: 200px;
  height: 50px;
  font-size: 20px;
  font-weight: 600;
}
.boxTdone {
  font-size: 18px;
}
.tdDivCon {
  width: 100%;
  border-bottom: 1px solid #000;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
}
.tdDivCon p {
  margin-bottom: 5px;
}
.boxTdone .tdDivCon:last-child {
  border-bottom: none;
}
</style>