<template>
  <va-form :id="id" :item="item" @saved="$emit('saved')">
    <v-card style="padding: 2vw 0.5vw">
      <v-card-text style="width: 97%; margin: 0 auto">
        <!-- <v-row>
          <ul>
            <li>患者信息</li>
            <li style="list-style: none; font-size: 10px; color: #999">
              PATIENT INFORMATION
            </li>
          </ul>
        </v-row> -->
        <v-row>
          <v-col>
            <va-text-input
              v-model="form.patient_name"
              label="患者姓名"
              source="patient_name"
              required
            ></va-text-input>
          </v-col>
          <v-col>
            <va-text-input
              v-model="form.id_num"
              label="身份证号"
              source="id_num"
              required
            ></va-text-input>
          </v-col>
          <v-col>
            <va-text-input
              v-model="form.gender"
              label="患者性别"
            ></va-text-input>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <va-text-input v-model="form.age" label="患者年龄"></va-text-input>
          </v-col>
          <v-col>
            <va-date-input
              source="plan_date"
              v-model="form.plan_date"
              format="short"
              label="预约日期"
            ></va-date-input>
          </v-col>
          <v-col>
            <v-select
              :items="items"
              filled
              v-model="form.noontype_code"
              label="预约时段"
              item-text="name"
              item-value="code"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <va-select-input
              required
              label="所属医院"
              item-value="codenum"
              reference="bs_hospital"
              :items-per-page="1000"
              source="hospital_code"
              v-model="form.hospital_code"
            ></va-select-input>
          </v-col>
          <v-col>
            <SelectInput
              required
              label="所属科室"
              item-value="codenum"
              source="office_code"
              reference="bs_office"
              :items-per-page="1000"
              ref="dept"
              :filter="dept_filter"
              v-model="form.office_code"
            ></SelectInput>
          </v-col>
          <v-col>
            <el-cascader
              clearable
              v-model="form.code_srvtp"
              :props="{
                emitPath: false,
                value: 'code_srvtp',
                label: 'name_srvtp',
              }"
              style="width: 100%; margin-bottom: 3vh"
              placeholder="试试搜索：'类别名称'"
              :options="options"
              filterable
            ></el-cascader>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <!-- <va-select-input
              required
              label="项目名称"
              item-value="project_code"
              item-text="project_name"
              reference="bs_inspection_dictionaries"
              :items-per-page="1000"
              source="item_name"
              v-model="form.item_code"
            ></va-select-input> -->
            <va-text-input
              v-model="form.item_name"
              label="项目名称"
            ></va-text-input>
          </v-col>
          <v-col>
            <va-text-input
              v-model="form.item_fee"
              label="项目价格"
            ></va-text-input>
          </v-col>
          <v-col></v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <el-table
      :data="detail"
      height="300"
      style="width: 100%; margin-bottom: 80px"
      @current-change="handleCurrentChange"
    >
      <!-- <el-table-column label="时段" prop="name" align="center">
      </el-table-column>
      <el-table-column label="项目编码" prop="num_count" align="center">
      </el-table-column>
      <el-table-column label="项目名称" prop="left_num" align="center">
      </el-table-column>
      <el-table-column label="项目费用（元）" prop="doctor_fee" align="center">
      </el-table-column>
      <el-table-column label="注意事项" prop="start_time" align="center">
      </el-table-column>
      <el-table-column label="项目明细" prop="end_time" align="center">
      </el-table-column> -->
      <el-table-column label="项目编码" prop="project_code" align="center">
      </el-table-column>
      <el-table-column label="项目名称" prop="project_name" align="center">
      </el-table-column>
      <el-table-column
        label="项目费用（元）"
        prop="project_fees"
        align="center"
      >
      </el-table-column>
    </el-table>
    <div class="text-center">
      <v-btn @click="close" style="color: #42b6c4; margin-right: 50px"
        >取消</v-btn
      >
      <v-btn
        @click="isSure"
        style="background-color: #42b6c4; margin-right: 50px"
        >确认</v-btn
      >
    </div>
  </va-form>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import SelectInput from "@/components/VaCustomSelectInput.vue";
export default {
  props: ["id", "title", "item"],
  components: { SelectInput },
  data() {
    return {
      form: {
        patient_name: null,
        id_num: null,
        gender: null,
        age: null,
        plan_date: null,
        hospital_code: null,
        office_code: null,
        code_srvtp: null,
        item_name: null,
        item_fee: null,
        noontype_code: null,
      },
      hospitalId: null,
      options: null,
      detail: null,
      items: [
        { code: "am", name: "上午", value: "08:00:00-12:00:00" },
        { code: "pm", name: "下午", value: "13:00:00-18:00:00" },
        { code: "night", name: "晚上", value: "19:00:00-22:00:00" },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    dept_filter() {
      if (this.hospitalId) {
        return { hospital_id: this.hospitalId, is_active: true };
      } else {
        return {};
      }
    },
  },
  mounted() {
    this.$admin.http.get(`/base-system/inspectiontypes/`).then((res) => {
      this.options = res.data;
    });
  },
  methods: {
    handleCurrentChange(val) {
      console.log(val);
      if (val) {
        this.form.item_name = val.project_name;
        this.form.item_code = val.project_code;
        this.form.item_fee = val.project_fees + "";
      }
    },
    isSure() {
      let data = {
        patient_code: this.form.id_num,
        id_num: this.form.id_num,
        patient_name: this.form.patient_name,
        item_code: this.form.item_code,
        item_name: this.form.item_name,
        code_srvtp: this.form.code_srvtp,
        name_srvtp: this.form.name_srvtp,
        hospital_code: this.form.hospital_code,
        hospital_name: this.form.hospital_name,
        office_code: this.form.office_code,
        office_name: this.form.office_name,
        item_fee: this.form.item_fee,
        apt_type: "1",
        plan_date: this.form.plan_date,
        start_time: this.form.start_time,
        end_time: this.form.end_time,
        noontype_code: this.form.noontype_code,
        state: "1",
      };
      // console.log(data, this.form);
      if (!this.id) {
        this.$admin.http.post(`medical-technology/checkuprecords/`, data).then((res) => {
          this.$message({
            message: res.msg,
            type: "success",
          });
        });
      } else {
        this.$admin.http
          .put(`medical-technology/checkuprecords/${this.id}/`, data)
          .then((res) => {
            this.$message({
              message: "修改成功",
              type: "success",
            });
          });
      }
      this.close();
    },
    close() {
      this.$router.push("/mt-checkup-record/");
    },
  },
  watch: {
    "form.hospital_code": {
      deep: true,
      immediate: true,
      handler: function (newVal) {
        this.form.office_code = null;
        if (newVal) {
          this.$store
            .dispatch("bs_hospital/getList", {
              filter: {
                codenum: newVal,
              },
            })
            .then((res) => {
              this.form.hospital_name = res.data[0].name;
              this.hospitalId = res.data[0].id;
              this.dept_filter = {
                hospital_id: this.hospitalId,
                is_active: true,
              };
            });
        }
      },
    },
    "form.noontype_code": {
      deep: true,
      immediate: true,
      handler: function (val) {
        if (val == "am") {
          this.form.start_time = "08:00:00";
          this.form.end_time = "12:00:00";
        } else if (val == "pm") {
          this.form.start_time = "13:00:00";
          this.form.end_time = "18:00:00";
        } else {
          this.form.start_time = "19:00:00";
          this.form.end_time = "22:00:00";
        }
      },
    },
    "form.office_code": {
      deep: true,
      immediate: true,
      handler: function (newVal) {
        if (newVal) {
          this.$store
            .dispatch("bs_office/getList", {
              filter: {
                codenum: newVal,
              },
            })
            .then((res) => {
              this.form.office_name = res.data[0].name;
            });
        }
      },
    },
    "form.id_num": {
      deep: true,
      immediate: true,
      handler: function (val) {
        const reg =
          /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
        if (reg.test(val)) {
          var org_birthday = val.substring(6, 14);
          var org_gender = val.substring(16, 17);
          var sex = org_gender % 2 == 1 ? "男" : "女";
          var birthday =
            org_birthday.substring(0, 4) +
            "-" +
            org_birthday.substring(4, 6) +
            "-" +
            org_birthday.substring(6, 8);
          var birthdays = new Date(birthday.replace(/-/g, "/"));
          let d = new Date();
          let age =
            d.getFullYear() -
            birthdays.getFullYear() -
            (d.getMonth() < birthdays.getMonth() ||
            (d.getMonth() == birthdays.getMonth() &&
              d.getDate() < birthdays.getDate())
              ? 1
              : 0);
          this.form.gender = sex;
          this.form.age = age+'';
        } else {
          this.form.sex = "未填写";
          return false;
        }
      },
    },
    "form.code_srvtp": {
      deep: true,
      immediate: true,
      handler: function (val) {
        if (val !== null) {
          this.$store
          .dispatch("bs_inspection_type/getList", {
            filter: {
              code_srvtp: val,
            },
          })
          .then((res) => {
            console.log(res.data);
            this.form.name_srvtp = res.data[0].name_srvtp;
          });
          this.$store
            .dispatch(
              "bs_inspection_dictionaries/getList"
              //   {
              //   filter: {
              //     is_read: false,
              //     to_user_id: this.user.id,
              //   },
              // }
            )
            .then((res) => {
              this.detail = res.data;
            });
        } else {
          this.detail = null;
        }
      },
    },
  },
};
</script>
<style scoped>
ul li::marker {
  color: #42b6c4;
}
.row + .row {
  margin-top: 0px;
}
.row > .col {
  padding: 0 12px !important;
}
.text-center {
  width: 87%;
  background-color: #fff;
  height: 80px;
  position: fixed;
  bottom: 0px;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .el-input__inner {
  background-color: #f0f0f0 !important;
  border-color: rgba(255, 255, 255, 0.5);
  color: #707070;
  height: 60px;
}
</style>
