import { ApolloClient } from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import { from } from "apollo-link";
import { InMemoryCache } from "apollo-cache-inmemory";

const graphqlURL = process.env.VUE_APP_GRAPHQL_URL;
const httpLink = createHttpLink({
  uri: graphqlURL,
});
const removeUniq = (message) => {
  let index = message.indexOf(".");
  let indexs = message.indexOf('"');
  return message.substring(index + 1, indexs);
}
const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      graphQLErrors[0].message = removeUniq(graphQLErrors[0].message);
      for (const err of graphQLErrors) {
        switch (err.extensions.code) {
          // Apollo Server sets code to UNAUTHENTICATED
          // when an AuthenticationError is thrown in a resolver
          case "UNAUTHENTICATED":
            throw new Error("用户未登录");
          //无效jwt
          case "invalid-jwt":
            throw new Error("jwt token无效");
        }
      }
    }

    // To retry on network errors, we recommend the RetryLink
    // instead of the onError link. This just logs the error.
    if (networkError) {
      throw new Error("网络错误");
    }
  }
);

const authLink = setContext((_, { headers }) => {
  const token = sessionStorage.getItem("jwt_token");
  if (token) {
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  } else {
    return {
      headers: {
        ...headers,
      },
    };
  }
});

const client = new ApolloClient({
  link: errorLink.concat(from([authLink, httpLink])),
  cache: new InMemoryCache(),
});

export default client;
