<template>
  <va-form :id="id" :item="item">
    <v-row justify="center">
      <v-col sm="6">
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2">
              {{ title }}
            </div>
          </template>
          <v-card-text>
            <va-text-input source="item_name"></va-text-input>            
            <va-number-input source="item_type" v-model="xman.item_type" :step="1" :min="1" :max="999" ></va-number-input>
            <va-number-input source="item_operation" v-model="xman.item_operation" :step="1" :min="1" :max="999" ></va-number-input>
            <va-number-input source="item_sys" v-model="xman.item_sys" :step="1" :min="1" :max="999" ></va-number-input>
            <va-number-input source="item_regions" v-model="xman.item_type" :step="1" :min="1" :max="999" ></va-number-input>
            <va-number-input source="item_duration" v-model="xman.item_duration" :step="1" :min="1" :max="999" ></va-number-input>
			<va-boolean-input source="available" v-model="xman.available"></va-boolean-input>
            <!-- <va-number-input source="item_type"></va-number-input> -->

            <va-text-input source="remark"></va-text-input>
          </v-card-text>
          <va-save-button @click="isSure"></va-save-button>
        </base-material-card>
      </v-col>
    </v-row>
  </va-form>
</template>

<script>
export default {
  props: ["id", "title", "item"],
  data() {
    return {
      xman: {
        item_name: "",
        item_type: null,
        item_operation: null,
        item_sys: null,
        item_type: null,
        remark: "",
      },
      // ... 你的其他数据属性 ...
      menu: false,
      asideTitle: null,
      show: true,
      // ... 你的其他数据属性 ...
    };
  },
  methods: {
    async isSure() {
      this.xman.item_type = Number(this.xman.item_type);
      if (this.xman.item_type > 4 || this.xman.item_type < 1) {
        this.xman.item_type = null;
        return;
      }
      if (this.id) {
        try {
          await this.$store
            .dispatch("ig_item/update", {
              id: this.id,
              data: this.xman,
            })
            .then((res) => {
              this.$emit("saved");
            });
        } catch {
          // this.$message({
          //   message: "医院编码违反唯一性，请修改",
          //   type: "error",
          // });
        }
      } else {
        await this.$store
          .dispatch("ig_item/create", {
            data: this.xman,
          })
          .then((res) => {
            this.$emit("saved");
            // if (this.file_data && this.file_data.length >= 1) {
            //   this.httpRequest(this.imgfile, res.data.id, res.data.logo);
            // }
          })
          .catch((err) => {
            // this.$message({
            //   message: "医院编码违反唯一性，请修改",
            //   type: "error",
            // });
          });
      }
    },
    close() {},
  },
};
</script>
