<template>
  <div>
    <v-card class="v_card">
      <span class="red_sp">
        示例：可提前预约7天，预约截止时间：“预约时段开始”“前”24小时，取消预约截止时间：“预约时段开始”“前”8小时
      </span>
    </v-card>

    <v-card class="v_card" v-for="(item, index) in form_data" :key="index">
      <div style="line-height: 60px">
        <span class="dot_circle"></span
        ><span class="title_con">{{ title[index] }}</span>
      </div>
      <div>
        <v-row>
          <v-col sm="4">
            <va-text-input
              label="可提前预约天数(天)"
              source="advance_days"
              v-model="item.advance_days"
            ></va-text-input>
          </v-col>
        </v-row>

        <div style="line-height: 60px; font-size: 20px">预约截止日期</div>
        <v-row justify="center">
          <v-col sm="4">
            <va-select-input
              source="apt_deadline"
              v-model="item.apt_deadline"
              label="参考时间点"
              :items-per-page="1000"
            ></va-select-input>
          </v-col>
          <v-col sm="4">
            <va-select-input
              source="apt_time_orientation"
              v-model="item.apt_time_orientation"
              label="时间方向"
              :items-per-page="1000"
            ></va-select-input>
          </v-col>
          <v-col sm="4">
            <va-text-input
              v-model="item.apt_hours"
              label="请输入时间（小时）"
              source="apt_hours"
            ></va-text-input>
          </v-col>
        </v-row>

        <div style="line-height: 60px; font-size: 20px">取消预约截止日期</div>
        <v-row justify="center">
          <v-col sm="4">
            <va-select-input
              label="参考时间点"
              source="cancel_apt_deadline"
              v-model="item.cancel_apt_deadline"
              :items-per-page="1000"
            ></va-select-input>
          </v-col>
          <v-col sm="4">
            <va-select-input
              label="时间方向"
              source="cancel_time_orientation"
              v-model="item.cancel_time_orientation"
              :items-per-page="1000"
            ></va-select-input>
          </v-col>
          <v-col sm="4">
            <va-text-input
              label="请输入时间（小时）"
              source="cancel_apt_hours"
              v-model="item.cancel_apt_hours"
            >
            </va-text-input>
          </v-col>
        </v-row>
        <v-radio-group inline v-model="item.priority" label="优先级">
          <v-radio label="门诊" value="1"></v-radio>
          <v-radio label="住院" value="2"></v-radio>
        </v-radio-group>
        <!-- <el-radio v-model="item.priority" label="1">门诊</el-radio>
        <el-radio v-model="item.priority" label="2">住院</el-radio> -->
      </div>
    </v-card>
    <div style="margin-bottom: 80px;"></div>
    <div class="text-center">
      <v-btn @click="isSure" color="primary">保存</v-btn>
      <!-- <va-save-button></va-save-button> -->
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
export default {
  // props: ["resource", "title"],
  data() {
    return {
      title: ["检查预约参数配置", "检验预约参数配置"],
      form_data: [
        {
          advance_days: null, //可提前几天预约
          apt_deadline: null, //预约截止时间
          apt_time_orientation: null, //预约时间方向
          apt_hours: null, //可提前几个小时预约
          cancel_apt_deadline: null, //取消预约截止时间
          cancel_time_orientation: null, //取消预约时间方向
          cancel_apt_hours: null, //可提前几个小时取消预约
          apt_type: "1", //预约类型
          priority: "1", //优先事项
          created_time: moment().format("YYYY-MM-DD HH:mm:ss"), //	创建时间
        },
        {
          advance_days: null, //可提前几天预约
          apt_deadline: null, //预约截止时间
          apt_time_orientation: null, //预约时间方向
          apt_hours: null, //可提前几个小时预约
          cancel_apt_deadline: null, //取消预约截止时间
          cancel_time_orientation: null, //取消预约时间方向
          cancel_apt_hours: null, //可提前几个小时取消预约
          apt_type: "2", //预约类型
          priority: "1", //优先事项
          created_time: moment().format("YYYY-MM-DD HH:mm:ss"), //	创建时间
        },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  mounted() {
    this.form_data = []
    this.$store
      .dispatch("mt_config_main/getList",{
        sort: [{ by: "apt_type", desc: false }],
      })
      .then((res) => {
        console.log(res.data);
        res.data.forEach((item)=> {
          this.form_data.push(item)
        })
        console.log(this.form_data);
      });
  },
  watch: {},
  methods: {
    isSure() {
      console.log(this.form_data);
      this.form_data.forEach((item) => {
        if (!this.form_data[0].id) {
          this.$store.dispatch("mt_config_main/create", {
            data: item,
          });
        } else {
          this.$store.dispatch("mt_config_main/update", {
            id: item.id,
            data: item,
          });
        }
      });
    },
  },
};
</script>
<style scoped>
.v_card {
  padding: 20px;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.red_sp {
  color: red;
}

.dot_circle {
  display: inline-block;
  background-color: #069cae;
  width: 8px;
  height: 8px;
  margin-right: 10px;
  border-radius: 8px;
  margin-bottom: 2px;
}

.title_con {
  display: inline-block;
  font-size: 20px;
}

.text-center {
  width: 87%;
  background-color: #fff;
  height: 80px;
  position: fixed;
  bottom: 0px;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
</style>
