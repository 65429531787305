<template>
  <va-show-layout>
    <va-show :item="item">
      <v-row justify="center">
        <v-col sm="6">
          <base-material-card>
            <template v-slot:heading>
              <div class="display-2">
                {{ title }}
              </div>
            </template>
            <v-card-text>
              <va-field source="request_no" ></va-field>
              <va-field source="remote_addr" ></va-field>
              <va-field source="path_info" ></va-field>
              <va-field source="full_path" ></va-field>
              <va-field source="req_method" ></va-field>
              <va-field source="req_params" ></va-field>
              <va-field source="content_type" ></va-field>
              <va-field source="operation_content" ></va-field>
              <va-field source="status_code" ></va-field>
              <va-field source="content_length" ></va-field>
              <va-field source="result" ></va-field>
              <va-field source="return_message" ></va-field>
              <va-field source="start_timestamp" ></va-field>
              <va-field source="end_timestamp" ></va-field>
              <va-field source="time_use_ms" ></va-field>
              <va-field source="created_time" type="date" ></va-field>
              <va-field source="updated_time" type="date" ></va-field>
            </v-card-text>
          </base-material-card>
        </v-col>
      </v-row>
    </va-show>
  </va-show-layout>
</template>

<script>
export default {
  props: ["title", "item"],
};
</script>
