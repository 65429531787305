<template>
  <!-- <va-create-layout> -->
    <mt-checkup-record-form :title="title" :item="item"></mt-checkup-record-form>
  <!-- </va-create-layout> -->
</template>

<script>
export default {
  props: ["title", "item"],
};
</script>
