<template>
  <div>
    <va-aside-layout :title="asideTitle">
      <opr-operating-room-management-show
        v-if="show"
        :item="item"
      ></opr-operating-room-management-show>
      <opr-operating-room-management-form
        v-else
        :id="id"
        width="500px"
        :item="item"
        @saved="onSaved"
        ref="form"
      ></opr-operating-room-management-form>
    </va-aside-layout>
    <v-card :icon="resource.icon" :title="title">
      <va-list
        disable-global-search
        disable-create-redirect
        disable-export
        :filters="filters"
        :filter="filter"
        ref="list"
        @action="onAction"
        :include="['bs_hospital']"
        :items-per-page="10"
      >
        <!-- <template v-slot:actions>
          <va-action-button
            icon="mdi-briefcase-download-outline"
            label="下载模版"
            :href="template_download_url"
            color="green"
            text
          >
          </va-action-button>
          <va-action-button
            icon="mdi-import"
            color="green"
            label="导入"
            @click="on_show_import"
            text
          >
          </va-action-button>
          <va-action-button
            icon="mdi-arrow-collapse-down"
            color="green"
            label="导出"
            text
            @click="on_export_excel"
          >
          </va-action-button>
        </template> -->
        <va-data-table
          disableShow
          disableClone
          :fields="fields"
          disable-create-redirect
          disable-edit-redirect
          disable-delete-redirect
          @item-action="onAction"
        >
        </va-data-table>
      </va-list>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["resource", "title"],
  data() {
    return {
      // 模板下载地址
      // template_download_url: `${this.global.upload_url}excel/office_import.xlsx`,
      // 弹出侧边栏
      asideTitle: null,
      id: null,
      item: null,
      show: false,
      filters: [],
      filter: {},
      fields: [
        {
          source: "hospital_name",
          label: "所属医院",
          sortable: false,
          align: "center",
        },
        {
          source: "opr_code",
          label: "手术室编码",
          sortable: false,
          align: "center",
        },
        {
          source: "opr_name",
          label: "手术室名称",
          sortable: false,
          align: "center",
        },
      ],
    };
  },
  created() {
    this.getSupper();
  },
  methods: {
    getSupper() {
      let user_data = JSON.parse(window.sessionStorage.getItem("user"));
      if (user_data.is_superuser) {
        this.filters.push({
          source: "hospital_name",
          label: "所属医院",
          type: "text",
          alwaysOn: true,
          // attributes: { reference: "auth_system_user.id" },
        });
      } else {
        this.filter = { hospital_name: user_data.hospital_name };
      }
    },
    async onAction({ action, title, id, item }) {
      this.asideTitle = title;
      this.id = id;
      this.show = action === "show";
      this.item = item;
      if (action === "create") {
        this.id = null;
      }
    },
    onSaved() {
      this.asideTitle = null;
      this.$refs.list.fetchData();
    },
    // 导入
    on_show_import() {},
    // 导出
    on_export_excel() {},
  },
};
</script>
