<template>
  <base-material-card :icon="resource.icon" :title="title">
    <va-list
      :filters="filters"
    >
      <va-data-table :fields="fields">
      </va-data-table>
    </va-list>
  </base-material-card>
</template>

<script>
export default {
  props: ["resource", "title"],
  data() {
    return {
      filters: [
  'number 号源',
  'status 号源是否已被使用',
  'time_line_name\t时间线名称',
  'time_line_code\t时间线编码',
  'patient_name\t患者名称',
  'patient_id\t患者身份证',
  'patient_phone\t患者手机'
],
      fields: [
  { source: 'number 号源', sortable: true },
  { source: 'status 号源是否已被使用', sortable: true },
  { source: 'time_line_name\t时间线名称', sortable: true },
  { source: 'time_line_code\t时间线编码', sortable: true },
  { source: 'patient_name\t患者名称', sortable: true },
  { source: 'patient_id\t患者身份证', sortable: true },
  { source: 'patient_phone\t患者手机', sortable: true },
  'create_time\t创建时间',
  'remark\t备注'
],
    };
  },
};
</script>
