<template>
  <va-form :id="id" :item="item">
    <va-select-input
      label="所属医院"
      reference="bs_hospital"
      :items-per-page="1000"
      :filter="{ is_active: true }"
      source="hospital_id"
      v-model="form.hospital_id"
    ></va-select-input>
    <SelectInput
      label="所属科室"
      source="office_id"
      reference="bs_office"
      :items-per-page="1000"
      ref="dept"
      :filter="dept_filter"
      v-model="form.office_id"
    ></SelectInput>
    <!-- <va-text-input source="name" v-model="form.name"></va-text-input> -->
    <va-select-input
      label="医生姓名"
      reference="bs_doctor"
      :items-per-page="1000"
      :filter="{ is_active: true }"
      source="doctor_id"
      v-model="form.doctor_id"
    ></va-select-input>
    <va-select-input
      label="选择模板"
      reference="ws_work_scheduling_main"
      :items-per-page="1000"
      :filter="{ is_active: true }"
      v-model="form.form"
    ></va-select-input>
    <!-- <div class="btn_bg">
      <div v-if="form.form">
       <router-link :to="{ path:'/ws-doctor-work-scheduling-main/create',query:{data:JSON.stringify(this.form)}}" tag="button">查看模板</router-link>
      </div>
    </div> -->
  </va-form>
</template>

<script>
import SelectInput from "@/components/VaCustomSelectInput.vue";
export default {
  props: ["id", "title", "item","type_id"],
  components: {
    SelectInput,
  },
  data() {
    return {
      form: {
        hospital_id: null,
        office_id: null,
        doctor_id: null,
        form: null,
        is_active: true,
        type_id: this.type_id, 
      },
    };
  },
  mounted(){
  },
  computed: {
    dept_filter() {
      if (this.form.hospital_id) {
        return { hospital_id: this.form.hospital_id, is_active: true };
      } else {
        return {};
      }
    },
  },
  methods: {
  },
  watch: {
    "form.hospital_id": {
      deep: true,
      immediate: true,
      handler(val) {
        if(val) {
          this.dept_filter = {hospital_id: val, is_active: true }
        }
      }
    },
  },
};
</script>

<style scoped>
.btn_bg {
  width: 95%;
  position: fixed;
  bottom: 0px;
  padding: 10px 0;
  text-align: center;
  background-color: white;
}
</style>