<template>
	<div>
		<div class="template-options">
			<v-row class="template-options-row">
				<v-col cols="2" md="2">
					<v-select filled label="服务组" item-value="id" item-text="name" :items="servicesGroups"
						v-model="selectedServiceGroupId" @change="handleServiceGroupChange"></v-select>
				</v-col>
				<!-- 设置每个按钮为相等的宽度，调整cols和md为平均值 -->
				<v-col cols="10" md="2" class="template-options-btn" @click="toggleDialog('planSchedule')">
					<span>按计划排班</span>
				</v-col>
				<v-col cols="10" md="2" class="template-options-btn"
					@click="toggleDialog('maintainSchedulingTemplates')">
					<span>维护排班模板1</span>
				</v-col>
				<v-col cols="10" md="2" class="template-options-btn" @click="toggleDialog('planScheduleAvailableItem')">
					<span>时段可用项目2</span>
				</v-col>
				<v-col cols="10" md="2" class="template-options-btn" @click="toggleDialog('resourceAllocation')">
					<span>资源分配3</span>
				</v-col>
			</v-row>
		</div>
		<!-- 按计划排班 -->
		<v-dialog v-model="dialogs.planSchedule" max-width="880px">
			<v-card>
				<v-card-title>
					<v-row justify="space-between">
						<v-col cols="3" md="2" style="margin-top: 25px;"> <!-- 使用较多的列数来保持左侧宽度 -->
							按计划排班
						</v-col>
						<v-col cols="3" md="3">
							<v-menu ref="startMenu" v-model="menuStart" :close-on-content-click="false"
								transition="scale-transition" offset-y min-width="auto">
								<template v-slot:activator="{ on, attrs }">
									<v-text-field v-model="startDate" label="开始日期" prepend-icon="mdi-calendar" readonly
										v-bind="attrs" v-on="on" @click="showStartDate = true"></v-text-field>
								</template>
								<v-date-picker v-if="showStartDate" v-model="startDate" no-title
									@input="menuStart = false"></v-date-picker>
							</v-menu>
						</v-col>
						<v-col cols="3" md="3">
							<v-menu ref="endMenu" v-model="menuEnd" :close-on-content-click="false"
								transition="scale-transition" offset-y min-width="auto">
								<template v-slot:activator="{ on, attrs }">
									<v-text-field v-model="endDate" label="结束日期" prepend-icon="mdi-calendar" readonly
										v-bind="attrs" v-on="on" @click="showEndDate = true"></v-text-field>
								</template>
								<v-date-picker v-if="showEndDate" v-model="endDate" no-title
									@input="menuEnd = false"></v-date-picker>
							</v-menu>
						</v-col>
						<v-col cols="3" md="4" style="margin-top: 20px;"> <!-- 使用较少的列数用于按钮组，自动右对齐 -->
							<v-btn color="success" style="margin-left: 100px;" text @click="saveSchedule" normal>
								生成排班
							</v-btn>
						</v-col>
					</v-row>
				</v-card-title>
				<v-card-text>
					<v-simple-table>
						<template v-slot:default>
							<thead>
								<tr>
									<th class="text-left">预约时段</th>
									<th class="text-left">周一</th>
									<th class="text-left">周二</th>
									<th class="text-left">周三</th>
									<th class="text-left">周四</th>
									<th class="text-left">周五</th>
									<th class="text-left">周六</th>
									<th class="text-left">周日</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(schedule, index) in weekSchedule" :key="index">
									<td>{{ schedule.time_line_name }}</td>
									<td v-for="(day, dayIndex) in schedule.days_data" :key="dayIndex">
										<v-text-field v-model="day.value" type="number"
											@click:append="editDay(day, index, dayIndex)" dense outlined solo flat
											hide-details></v-text-field>
									</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
				</v-card-text>
			</v-card>
		</v-dialog>
		<!-- 维护排班模板 -->
		<v-dialog v-model="dialogs.maintainSchedulingTemplates" max-width="900px">
			<v-card>
				<v-card-title>
					<v-row justify="space-between" align="center"> <!-- 添加 align="center" 来垂直居中对齐 -->
						<v-col cols="3" md="3"> <!-- 修改列数以适应内容 -->
							维护排班模板
						</v-col>
						<v-col cols="3" md="3">
							<span class="title-info">所属科室：{{ serviceGroupOffice }}</span>
						</v-col>
						<v-col cols="3" md="3">
							<span class="title-info">服务组名称：{{ serviceGroupNmae }}</span>
						</v-col>
						<v-col cols="3" md="3">
							<span class="title-info">服务组描述：{{ serviceGroupDesc }}</span>
						</v-col>
					</v-row>
					<v-row justify="space-between" align="center">
						<v-col cols="3" md="3"> <!-- 适当调整以适应屏幕宽度 -->
							<va-number-input v-model.number="defaultQuantity" label="默认数量" type="number"
								:rules="[rules.required, rules.number]" single-line dense outlined>
							</va-number-input>
						</v-col>
						<v-col cols="3" md="3"> <!-- 增加列数以适应剩余内容 -->
							<v-btn color="success" text @click="addTodo" normal>增加行</v-btn>
							<v-btn color="success" text @click="removeTodo" normal>删除行</v-btn>
						</v-col>
						<v-col cols="3" md="3"> <!-- 增加列数以适应剩余内容 -->
							<!-- <v-btn color="warning" text @click="markTemplateAsFull" normal>生成</v-btn> -->
							<v-btn color="success" text @click="saveTemplate" normal>保存模板</v-btn>
						</v-col>
					</v-row>
				</v-card-title>
				<v-data-table :headers="headers" :items="timelines" class="elevation-1" item-key="id"
					:item-class="itemClass" @click:row="selectRow">
					<template v-slot:[`item.start_time`]="{ item }">
						<v-text-field v-model="item.start_time" :value="item.start_time"
							@input="updateTime(item, 'start_time', $event)" type="text"
							@blur="validateTime(item, 'start_time')" dense outlined solo flat hide-details
							placeholder="HH:MM"></v-text-field>
					</template>
					<template v-slot:[`item.end_time`]="{ item, index }">
						<v-text-field v-model="item.end_time" :value="item.end_time"
							@input="updateTime(item, 'end_time', $event)" type="text"
							@blur="validateTime(item, 'end_time'); updateTimeRange(item, index)" dense outlined solo
							flat hide-details placeholder="HH:MM">
						</v-text-field>
					</template>
					<template v-slot:[`item.resource_no_start`]="{ item, index }">
						<v-text-field v-model="item.resource_no_start" type="number"
							@blur="validateNumber(item, 'resource_no_start'); updateStartNo(item, index)"></v-text-field>
					</template>
					<template v-slot:[`item.resource_no_end`]="{ item, index }">
						<v-text-field v-model="item.resource_no_end" type="number"
							@blur="validateNumber(item, 'resource_no_end'); updateEndNo(item, index)"></v-text-field>
					</template>
					<template v-slot:[`item.back_up_time_zone`]="{ item }">
						<v-checkbox v-model="item.back_up_time_zone"></v-checkbox>
					</template>
				</v-data-table>
			</v-card>
		</v-dialog>
		<!-- 时段可用项目 -->
		<v-dialog v-model="dialogs.planScheduleAvailableItem" max-width="880px">
			<v-card>
				<v-card-title>
					<v-row justify="space-between" align="center"> <!-- 添加 align="center" 来垂直居中对齐 -->
						<v-col cols="3" md="3"> <!-- 修改列数以适应内容 -->
							时段可用项目
						</v-col>
						<v-col cols="3" md="3">
							<span class="title-info">所属科室：{{ serviceGroupOffice }}</span>
						</v-col>
						<v-col cols="3" md="3">
							<span class="title-info">服务组名称：{{ serviceGroupNmae }}</span>
						</v-col>
						<v-col cols="3" md="3">
							<span class="title-info">服务组描述：{{ serviceGroupDesc }} ID:{{ selectedServiceGroupId }}
							</span>
						</v-col>
					</v-row>
					<v-row justify="space-between" align="center">
						<v-col cols="0" md="0">
						</v-col>
					<!-- 	<v-col cols="2" md="3">
							<v-select filled label="选择星期" item-value="value" item-text="name" :items="weekDays"
								v-model="selectWeek"></v-select>
						</v-col> -->
						<v-col cols="2" md="3">
							<v-select filled label="选择星期" item-value="value" item-text="name" :items="weekDays" v-model="selectWeek" @change="handleWeekChange"></v-select>
						</v-col>
						<v-col cols="0" md="5">
						</v-col>
						<v-col cols="2" md="3">
							<v-btn color="success" text @click="saveTimezone" normal>保存</v-btn>
						</v-col>
					</v-row>
				</v-card-title>
				<v-data-table :headers="worklineheaders" :items="timelines" class="elevation-4" hide-default-footer>
					<template v-slot:[`item.resource_count`]="{ item }">
						<v-text-field v-model="item.resource_count" type="number" @input="updateRemaining(item)" single-line dense placeholder="Edit"></v-text-field>
					</template>
					<template v-slot:[`item.resource_quota`]="{ item }">
						<v-text-field v-model="item.resource_quota" type="number" single-line dense placeholder="Edit"></v-text-field>
					</template>
					<template v-slot:[`item.appointment_item_way`]="{ item }">
						<v-select :items="ruleOptions" v-model="item.appointment_item_way" item-text="text" item-value="value" return-object dense></v-select>
					</template>
					<template v-slot:[`item.itemselected`]="{ item }">
						<v-chip class="ma-2" color="#069CAE" @click="redirectToJoinPage(item.id, item.time_line_name, item.appointment_item_way)" text-color="white">
						配置项目
						</v-chip>
					</template>
				</v-data-table>
				<v-card-title>
					<v-row justify="space-between" align="center">
						<v-col cols="0" md="0">
						</v-col>
						<v-col cols="3" md="12">
							<v-switch label="相同时段规则复制 *启用则无需选择星期,周几都一样,关闭则必须指定星期" v-model="same_time_zone_copy"
								color="primary">
							</v-switch>
						</v-col>
					</v-row>
				</v-card-title>
			</v-card>
		</v-dialog>
		<!-- 资源分配 -->
		<v-dialog v-model="dialogs.resourceAllocation" max-width="1100px">
			<v-card>
				<v-card-title>
					<v-row justify="space-between">
						<v-col cols="2" md="2"> <!-- 使用较多的列数来保持左侧宽度 -->
							资源分配
						</v-col>
						<v-col cols="3" md="3">
							<span class="title-info">所属科室：{{ serviceGroupOffice }}</span>
						</v-col>
						<v-col cols="3" md="3">
							<span class="title-info">服务组名称：{{ serviceGroupNmae }}</span>
						</v-col>
						<v-col cols="3" md="3">
							<span class="title-info">服务组描述：{{ serviceGroupDesc }}</span>
						</v-col>
					</v-row>
					<v-row justify="space-between">
						<v-col cols="3" md="4" style="margin-top: 25px;"> <!-- 使用较多的列数来保持左侧宽度 -->
							分配列表：（-1表示无限制）
						</v-col>
						<v-col cols="3" md="3" style="margin-top: 20px;"> <!-- 使用较少的列数用于按钮组，自动右对齐 -->
							<v-btn color="success" text @click="saveResourceConfig" normal>
								保存
							</v-btn>
						</v-col>
					</v-row>
				</v-card-title>
				<v-card-text>
					<!-- <v-data-table :headers="resourceHeaders" :items="weekConfig" item-key="mType" class="elevation-1">						
						<template v-slot:[`item.pre_apply_days`]="{ item }">
							{{ getPreApplyDays(item) }}
						</template>
					</v-data-table> -->
					<v-data-table :headers="resourceHeaders" :items="weekConfig" item-key="mType" class="elevation-1">
						<template v-slot:[`item.pre_apply_days`]="{ item }">
							{{ getPreApplyDays(item) }}
						</template>
						<template v-slot:[`item.days[0].value`]="{ item }">
							<v-text-field v-model="item.days[0].value" type="number" label="周一" single-line
								dense></v-text-field>
						</template>
						<template v-slot:[`item.days[1].value`]="{ item }">
							<v-text-field v-model="item.days[1].value" type="number" label="周二" single-line
								dense></v-text-field>
						</template>
						<template v-slot:[`item.days[2].value`]="{ item }">
							<v-text-field v-model="item.days[2].value" type="number" label="周三" single-line
								dense></v-text-field>
						</template>
						<template v-slot:[`item.days[3].value`]="{ item }">
							<v-text-field v-model="item.days[3].value" type="number" label="周四" single-line
								dense></v-text-field>
						</template>
						<template v-slot:[`item.days[4].value`]="{ item }">
							<v-text-field v-model="item.days[4].value" type="number" label="周五" single-line
								dense></v-text-field>
						</template>
						<template v-slot:[`item.days[5].value`]="{ item }">
							<v-text-field v-model="item.days[5].value" type="number" label="周六" single-line
								dense></v-text-field>
						</template>
						<template v-slot:[`item.days[6].value`]="{ item }">
							<v-text-field v-model="item.days[6].value" type="number" label="周日" single-line
								dense></v-text-field>
						</template>
					</v-data-table>

				</v-card-text>
			</v-card>
		</v-dialog>

		<!-- 日历主窗口 -->
		<v-sheet height="600" style="margin: 20px">
			<div style="display: flex">
				<v-btn text small color="grey darken-2" @click="prev">
					<v-icon small> mdi-chevron-left </v-icon>
				</v-btn>
				<v-btn text small color="grey darken-2" @click="next">
					<v-icon small> mdi-chevron-right </v-icon>
				</v-btn>
				<!-- 显示月份和年 -->
				<v-toolbar-title v-if="$refs.calendar">
					{{ $refs.calendar.title }}
				</v-toolbar-title>
			</div>
			<!-- 显示月份和年容器 -->
			<v-spacer></v-spacer>
			<v-calendar ref="calendar" v-model="focus" color="primary" :events="events" type="month"
				:event-color="getEventColor">
				<template v-slot:day="{ date }">
					<div>
						<template v-if="date">
							<div @click="handleDay({ date })">
								<div class="date-text blue--text ">{{ usagePlanFormatted(date) }}</div>
							</div>
						</template>
					</div>
				</template>
			</v-calendar>
		</v-sheet>

		<!-- 维护计划数量对话框 -->
		<v-dialog v-model="dialog" max-width="800px">
			<v-card>
				<v-card-title>
					<v-row justify="space-between">
						<v-col cols="12" md="8"> <!-- 使用较多的列数来保持左侧宽度 -->
							维护计划数量 - {{ selectedDateFormatted }}
						</v-col>
						<v-col cols="12" md="4"> <!-- 使用较少的列数用于按钮组，自动右对齐 -->
							<v-btn color="warning" text @click="markAsFull" normal>
								排满
							</v-btn>
							<v-btn color="success" text @click="saveTasks" normal>
								保存
							</v-btn>
						</v-col>
					</v-row>
				</v-card-title>
				<v-card-text>
					<v-data-table :headers="workheaders" :items="weekSchedule" class="elevation-4" hide-default-footer>
						<template v-slot:[`item.resource_count`]="{ item }">
							<v-text-field v-model="item.resource_count" type="number" @input="updateRemaining(item)"
								single-line dense placeholder="Edit"></v-text-field>
						</template>
						<template v-slot:[`item.remain`]="{ item }">
							{{ item.remain }}
						</template>
						<template v-slot:[`item.resource_quota`]="{ item }">
							<v-text-field v-model="item.resource_quota" type="number" single-line dense
								placeholder="Edit"></v-text-field>
						</template>
						<template v-slot:[`item.back_up_time_zone`]="{ item }">
							<!-- 如果勾选，表示备用时段的号源仅支持分诊台工作人员预约-->
							<v-checkbox v-model="item.back_up_time_zone"></v-checkbox>
						</template>
					</v-data-table>
				</v-card-text>
				<v-card-actions>
					<v-btn color="primary" text @click="dialog = false">
						关闭
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	import moment from "moment";
	export default {
		data() {
			return {
				focus: "",
				events: [],
				dialog: false,
				menuStart: moment().format('YYYY-MM-DD'),
				menuEnd: moment().add(7, 'days').format('YYYY-MM-DD'),
				startDate: moment().format('YYYY-MM-DD'),
				endDate: moment().add(7, 'days').format('YYYY-MM-DD'),
				planQuantity: 0,
				date: null,
				showStartDate: null,
				showEndDate: null,
				accessRule: 0,
				resource_quota: 0,
				defaultQuantity: 0, // 默认数量的初始值
				same_time_zone_copy: false,
				template_id: null,
				template_name: null,
				attrs: null,
				on: null,
				weekDays: [
					{ name: '星期一', value: 'a' },
					{ name: '星期二', value: 'b' },
					{ name: '星期三', value: 'c' },
					{ name: '星期四', value: 'd' },
					{ name: '星期五', value: 'e' },
					{ name: '星期六', value: 'f' },
					{ name: '星期日', value: 'g' }
				],
				// 设置默认选择为星期一
				selectWeek: {},
				servicesGroup: 0,
				servicesGroups: [],
				selectedRows: [],
				selectedTodoIndex: "",
				selectedServiceGroupId: null,
				serviceGroupOffice: null,
				serviceGroupNmae: null,
				serviceGroupDesc: null,
				selectedRowIndex: null,
				selectedRow: null,
				resourceHeaders: [{
						text: '诊疗类型',
						value: 'mType',
						sortable: false,
						width: '110px'
					},
					{
						text: '可预约天数',
						value: 'pre_apply_days', // 这里是个虚拟字段，真正的值将由下面的 template 控制
						sortable: false,
						width: '110px'
					},
					{
						text: '周一',
						value: 'days[0].value',
						sortable: false,
						width: '90px'
					},
					{
						text: '周二',
						value: 'days[1].value',
						sortable: false,
						width: '90px'
					},
					{
						text: '周三',
						value: 'days[2].value',
						sortable: false,
						width: '90px'
					},
					{
						text: '周四',
						value: 'days[3].value',
						sortable: false,
						width: '90px'
					},
					{
						text: '周五',
						value: 'days[4].value',
						sortable: false,
						width: '90px'
					},
					{
						text: '周六',
						value: 'days[5].value',
						sortable: false,
						width: '90px'
					},
					{
						text: '周日',
						value: 'days[6].value',
						sortable: false,
						width: '90px'
					},
					// 添加其他周的定义
				],
				dialogs: {
					planSchedule: false,
					maintainSchedulingTemplates: false,
					planScheduleAvailableItem: false,
					resourceAllocation: false,
				},
				usagePlans: [],
				ruleOptions: [{
						text: '不限制',
						value: 1
					},
					{
						text: '包含则有效',
						value: 2
					},
					{
						text: '不包含则有效',
						value: 3
					},
				],
				workheaders: [{
						text: '预约时段',
						value: 'time_line_name',
						width: 110,
						sortable: false
					},
					{
						text: '计划预约',
						value: 'resource_count',
						width: 110,
						sortable: false
					},
					{
						text: '已预约',
						value: 'used_count',
						width: 100,
						sortable: false
					},
					// {
					// 	text: '剩余',
					// 	value: 'remain',
					// 	width: 70,
					// 	sortable: false
					// },
					{
						text: '住院限额(-1:不限)',
						value: 'resource_quota',
						width: 150,
						sortable: false
					},
					{
						text: '备用时段',
						value: 'back_up_time_zone',
						width: 90,
						sortable: false
					},
				],
				worklineheaders: [
					{ text: '预约时段', value: 'time_line_name', width: 100, sortable: false },
					{ text: '计划数量', value: 'resource_count', width: 100, sortable: false },
					{ text: '住院限额(-1:不限)', value: 'resource_quota', width: 160, sortable: false },
					{ text: '特殊项目访问规则', value: 'appointment_item_way', width: 170, sortable: false },
					{ text: '可做项目', value: 'itemselected', width: 200, sortable: false }
				],
				headers: [{
						text: '预约时段',
						value: 'time_line_name',
						width: 100,
						sortable: false
					},
					{
						text: '开始时间',
						value: 'start_time',
						width: 100,
						sortable: false
					},
					{
						text: '结束时间',
						value: 'end_time',
						width: 100,
						sortable: false
					},
					{
						text: '起始号码',
						value: 'resource_no_start',
						width: 100,
						sortable: false
					},
					{
						text: '结束号码',
						value: 'resource_no_end',
						width: 100,
						sortable: false
					},
					{
						text: '号源数量',
						value: 'resource_count',
						width: 100,
						sortable: false
					},
					{
						text: '备用时段',
						value: 'back_up_time_zone',
						width: 100,
						sortable: false
					},
				],
				weekConfig: [],
				weekSchedule: [
					// 	{
					// 	time_line_name: '10:00~11:00',
					// 	days: [{
					// 		value: 20
					// 	}, {
					// 		value: 20
					// 	}, {
					// 		value: 20
					// 	}, {
					// 		value: 20
					// 	}, {
					// 		value: 20
					// 	}, {
					// 		value: 20
					// 	}, {
					// 		value: 20
					// 	}]
					// },
					// {
					// 	time_line_name: '10:00~11:00',
					// 	days: [{
					// 		value: 20
					// 	}, {
					// 		value: 20
					// 	}, {
					// 		value: 20
					// 	}, {
					// 		value: 20
					// 	}, {
					// 		value: 20
					// 	}, {
					// 		value: 20
					// 	}, {
					// 		value: 20
					// 	}]
					// }
				],
				rules: {
					required: value => !!value || '必填项。',
					number: value => !isNaN(parseFloat(value)) || '必须是数字。',
				},
				timelines: [],
				works: [
					// {
					// 	time_line_name: '10:00~11:00',
					// 	resource_count: 20,
					// 	used: 20,
					// 	remain: 0,
					// 	back_up_time_zone: false,
					// 	editable: false,
					// 	resource_quota: 0
					// },
					// {
					// 	time_line_name: '11:00~12:00',
					// 	resource_count: 20,
					// 	used: 20,
					// 	remain: 0,
					// 	back_up_time_zone: false,
					// 	editable: false,
					// 	resource_quota: 0
					// },					
				], // 存储待办事项数据
			};
		},
		created() {
			this.fetchServicesGroup(); // 在组件创建时调用
		},
		mounted() {
			this.fetchItemOptions();
			this.fetchServicesGroup();
		},
		computed: {
			selectedDateFormatted() {
				// ... 已定义的计算属性 ...
				console.log('this.focus', this.focus);
				return this.focus ? moment(this.focus).format('YYYY-MM-DD') : '';
			},
			usagePlanFormatted() {
				const usagePlans = {};
				this.usagePlans.forEach(item => {
					usagePlans[item.date] = `${item.used}/${item.plan}`;
				});

				return date => usagePlans[date] || '---';
			},
		},
		watch: {
			selectWeek(newVal) {
				this.updateWeekSelection();
			}
		},
		methods: {
			handleWeekChange() {
				const weekMapping = {
					a: 'plan_count_a',
					b: 'plan_count_b',
					c: 'plan_count_c',
					d: 'plan_count_d',
					e: 'plan_count_e',
					f: 'plan_count_f',
					g: 'plan_count_g'
				};
				const resourceQuotaMapping = {
					a: 'resource_quota_in_a',
					b: 'resource_quota_in_b',
					c: 'resource_quota_in_c',
					d: 'resource_quota_in_d',
					e: 'resource_quota_in_e',
					f: 'resource_quota_in_f',
					g: 'resource_quota_in_g'
				};
				const itemRuleMapping = {
					a: 'item_rule_a',
					b: 'item_rule_b',
					c: 'item_rule_c',
					d: 'item_rule_d',
					e: 'item_rule_e',
					f: 'item_rule_f',
					g: 'item_rule_g'
				};
				const selectedWeek = this.selectWeek;
				console.log('selectedWeek ', selectedWeek);
				this.timelines.forEach(item => {
					console.log('item 1101 ', item);
					console.log('item.resource_count 1 ', item.resource_count);
					item.resource_count = item[weekMapping[selectedWeek]];
					console.log('item.resource_count 2 ', item.resource_count);
					console.log('item.resource_quota 1 ', item.resource_quota);
					item.resource_quota = item[resourceQuotaMapping[selectedWeek]];
					console.log('item.resource_quota 2 ', item.resource_quota);
					console.log('item.appointment_item_way 1 ', item.appointment_item_way);
					item.appointment_item_way = item[itemRuleMapping[selectedWeek]];
					console.log('item.appointment_item_way 2 ', item.appointment_item_way);
				});
			},
			updateWeekSelection() {
				this.timelines.forEach(item => {
				const selectedWeek = this.selectWeek;
				const itemRuleMapping = {
					a: 'item_rule_a',
					b: 'item_rule_b',
					c: 'item_rule_c',
					d: 'item_rule_d',
					e: 'item_rule_e',
					f: 'item_rule_f',
					g: 'item_rule_g'
				};
				item.appointment_item_way = item[itemRuleMapping[selectedWeek]];
				});
			},
			getPreApplyDays(item) {
				return item.mType === '门诊' ? item.pre_out_apply_days : item.pre_in_apply_days;
			},
			fetchServicesGroup() {
				this.$store.dispatch("ig_service_group/getList")
					.then((res) => {
						this.servicesGroups = res.data;
						if (this.servicesGroups.length > 0) {
							this.selectedServiceGroupId = this.servicesGroups[0].id; // 设置默认选中第一个服务组
							// 可以选择在这里调用 handleServiceGroupChange 来初始化相关数据
							this.handleServiceGroupChange();
							this.getUsagePlans();
						}
					})
					.catch(error => {
						console.error("Failed to load service groups:", error);
					});
			},
			handleServiceGroupChange() {
				if (this.selectedServiceGroupId) {
					// 在服务组数据中找到选中的服务组对象
					const group = this.servicesGroups.find(group => group.id === this.selectedServiceGroupId);
					if (group) {
						this.serviceGroupOffice = group.exec_office_name; // 执行科室名称
						this.serviceGroupNmae = group.name; // 服务组名称
						this.serviceGroupDesc = group.work_desc; // 服务组描述						
					}
				}
				this.updateCalendarData();
			},
			// 操作项目弹窗
			toggleDialog(dialogName) {
				if (this.serviceGroupNmae === null) {
					this.$message({
						message: `请先选择要配置的服务组`,
						type: "error"
					});
				} else {
					this.dialogs[dialogName] = !this.dialogs[dialogName];
					// 当打开“维护排班模板”对话框时，加载数据
					if (dialogName === 'planSchedule' && this.dialogs[dialogName]) {
						this.loadplanSchedule();
					}
					if (dialogName === 'maintainSchedulingTemplates' && this.dialogs[dialogName]) {
						this.loadSchedulingTemplates();
					}
					if (dialogName === 'planScheduleAvailableItem' && this.dialogs[dialogName]) {
						this.loadplanScheduleAvailableItem();
					}
					if (dialogName === 'resourceAllocation' && this.dialogs[dialogName]) {
						this.loadresourceAllocation();
					}
				}
			},
			loadSchedulingTemplates() {
				// 从 API 获取数据，这里假设已经有了 group_id
				const groupId = this.selectedServiceGroupId; // 或者指定的ID，如 2
				this.$admin.http.get(`/scheduling_template/schedulingtemplate/group-scheduling/?group_id=${groupId}`)
					.then(response => {
						// 假设返回的数据结构符合你的描述
						if (response.data && response.data.timelines) {
							// this.timelines = [];
							this.timelines = response.data.timelines;
							this.template_id = response.data.id;
							this.template_name = response.data.name;
						}
					})
					.catch(error => {
						console.error("Failed to fetch scheduling templates:", error);
						this.$message({
							message: "加载排班模板数据失败",
							type: "error",
						});
					});
			},
			loadplanSchedule() {
				// 从 API 获取数据，这里假设已经有了 group_id
				const groupId = this.selectedServiceGroupId; // 或者指定的ID，如 2
				this.$admin.http.get(`/scheduling_template/schedulingtemplate/group-scheduling/?group_id=${groupId}`)
					.then(response => {
						// 假设返回的数据结构符合你的描述
						if (response.data && response.data.timelines) {
							this.weekSchedule = [];
							this.weekSchedule = response.data.timelines;
							this.template_id = response.data.id;
							this.template_name = response.data.name;
						}
					})
					.catch(error => {
						console.error("Failed to fetch scheduling templates:", error);
						this.$message({
							message: "加载排班模板数据失败",
							type: "error",
						});
					});
			},
			// 时段可用项目
			loadplanScheduleAvailableItem() {
				// 从 API 获取数据，这里假设已经有了 group_id
				const groupId = this.selectedServiceGroupId; // 或者指定的ID，如 2
				this.$admin.http.get(`/scheduling_template/schedulingtemplate/group-scheduling/?group_id=${groupId}`)
					.then(response => {
						// 假设返回的数据结构符合你的描述
						if (response.data && response.data.timelines) {
							this.timelines = [];
							this.timelines = response.data.timelines;
							this.template_id = response.data.id;
							this.template_name = response.data.name;
						}
					})
					.catch(error => {
						console.error("Failed to fetch scheduling templates:", error);
						this.$message({
							message: "加载排班模板数据失败",
							type: "error",
						});
					});
			},
			loadresourceAllocation() {
				const groupId = this.selectedServiceGroupId; // 或者指定的ID，如 2
				this.$admin.http.get(`/scheduling_template/schedulingtemplate/group-scheduling/?group_id=${groupId}`)
					.then(response => {
						// 假设返回的数据结构符合你的描述
						if (response.data && response.data.timelines) {
							this.timelines = [];
							this.timelines = response.data.timelines;
							this.template_id = response.data.id;
							this.template_name = response.data.name;

							this.$admin.http.get(
								`/scheduling_template/schedulingtemplate/type-group-scheduling-timeline-config/`, {
									params: {
										template_id: this.template_id
									}
								}).then(response => {
								this.weekConfig = response.data.map(item => ({
									mType: item.mType,
									pre_in_apply_days: item.pre_in_apply_days,
									pre_out_apply_days: item.pre_out_apply_days,
									days: item.days.map(day => ({
										value: day.value
									}))
								}));
							}).catch(error => {
								console.error("Error loading resource allocation data:", error);
							});
						}
					})
					.catch(error => {
						console.error("Failed to fetch scheduling templates:", error);
						this.$message({
							message: "加载排班模板数据失败",
							type: "error",
						});
					});
			},
			updateRemaining(item) {
				const resource_count = Number(item.resource_count);
				const used = Number(item.used_count);
				item.remain = resource_count >= used ? resource_count - used : 0; // 若计划数量小于已使用数量，则标记为错误
			},
			editDay(day, rowIndex, dayIndex) {
				// 实现编辑某一天的逻辑
				console.log(`Editing ${rowIndex + 1}, Day ${dayIndex + 1}`);
			},
			saveEdit(item) {
				// 在这里处理编辑后的数据保存逻辑
				console.log('Edited item', item);
				// 例如保存到服务器或状态管理
			},
			validateTime(item, field) {
				// 校验时间格式
				const regex = /^([01]\d|2[0-3]):([0-5]\d)$/;
				if (!regex.test(item[field])) {
					this.$set(item, field, ''); // 重置不合法的时间
				} else {
					// 如果时间格式正确，则尝试更新时间范围
					this.updateTimeRange(item);
				}
			},
			fetchItemOptions() {
				this.$store.dispatch("ig_item_property/getList").then((res) => {
					// this.options2 = res.data;
					console.log(res.data);
				});
				this.$admin.http.get("item_property/itemregions").then((response) => {
					// this.$admin.http.get("ig_item_regions/getList").then((response) => {
					console.log(response.data.results);
					const formattedOptions = response.data.results.map(item => ({
						id: item.id,
						name: item.item_name
					}));
					this.timelines.forEach(item => {
						item.itemOptions = formattedOptions;
						item.loaded = true;
						console.log("Updated itemOptions for item:", formattedOptions[0].name);
					});
				}).catch(error => {
					console.error("Failed to fetch items:", error);
				});
			},
			loadOptions(item) {
				if (!item.loaded) { // 确保未加载过
					this.fetchItemOptions(item);
				};
			},
			formatTimeInput(value) {
				let clean = value.replace(/[^0-9]/g, ''); // 移除非数字字符
				if (clean.length > 4) clean = clean.slice(0, 4); // 限制长度为4个字符
				if (clean.length <= 2) return clean; // 小于等于2个字符，直接返回
				return clean.slice(0, 2) + ':' + clean.slice(2); // 添加冒号分隔符
			},
			updateTime(item, field, value) {
				item[field] = this.formatTimeInput(value); // 更新时间并格式化
				if (field === 'start_time' || field === 'end_time') {
					this.updateTimeRange(item); // 可选：如果你有时间范围更新逻辑
				}
			},
			updateTimeRange(item, index) {
				// 更新时间范围并校验结束时间是否大于开始时间
				if (item.start_time && item.end_time) {
					const startMinutes = this.getMinutesFromTime(item.start_time);
					const endMinutes = this.getMinutesFromTime(item.end_time);
					if (endMinutes > startMinutes) {
						item.time_line_name = `${item.start_time}~${item.end_time}`;
						this.$set(item, 'time_line_name', `${item.start_time}-${item.end_time}`);
						// 确保下一行的 start_time 等于当前行的 end_time
						if (index < this.timelines.length - 1) {
							this.timelines[index + 1].start_time = item.end_time;
						}
					} else {
						this.$set(item, 'end_time', ''); // 重置不合法的结束时间
					}
				}
			},
			validateNumber(item, field) {
				// 校验数字字段
				if (isNaN(item[field]) || item[field] < 0) {
					this.$set(item, field, 0); // 重置不合法的数字
				}
			},
			updateStartNo(item, index) {
				// 更新 resource_no_start 并校验它是否小于 resource_no_end
				if (item.resource_no_start >= item.resource_no_end) {
					let stepno = this.defaultQuantity > 0 ? this.defaultQuantity : 1;
					this.$set(item, 'resource_no_end', Number.parseInt(item.resource_no_start) + Number.parseInt(stepno) -
						1);
				}
				this.updateCount(item);
			},
			updateEndNo(item, index) {
				// 更新 resource_no_end 并校验它是否大于 resource_no_start
				if (item.resource_no_end <= item.resource_no_start) {
					this.$set(item, 'resource_no_start', Number.parseInt(item.resource_no_end) - 1);
				}
				this.updateCount(item);
				// 确保下一行的 resource_no_start 是当前行的 resource_no_end + 1
				if (index < this.timelines.length - 1) {
					this.timelines[index + 1].resource_no_start = Number.parseInt(item.resource_no_end) + 1;
				}
			},
			updateCount(item) {
				// 更新计数
				this.$set(item, 'resource_count', item.resource_no_end - item.resource_no_start + 1);
			},
			getMinutesFromTime(time) {
				// 从时间字符串获取分钟数
				const [hours, minutes] = time.split(':').map(Number);
				return hours * 60 + minutes;
			},
			prev() {
				this.$refs.calendar.prev();
				this.updateCalendarData();
			},
			next() {
				this.$refs.calendar.next();
				this.updateCalendarData();
			},
			// 更新日历数据
			updateCalendarData() {
				const selectedMonth = this.$refs.calendar.start;
				console.log('切换日历 ', selectedMonth);
				this.getUsagePlans(this.focus);
			},
			getUsagePlans(date) {
				// 使用 moment 检查日期是否有效
				if (!moment(date).isValid()) {
					// 如果日期无效，设置为当前月份
					date = moment().format('YYYY-MM');
				} else {
					// 格式化日期为 'YYYY-MM' 格式
					date = moment(date).format('YYYY-MM');
				}
				this.$admin.http.get(
					`scheduling_day/schedulingday/get_usage_plan/?month=${date}&group_id=${this.selectedServiceGroupId}`
				).then((
					response) => {
					// this.usagePlans = response?.data ?? []
					this.usagePlans = response.data ? response.data : [];
				}).catch(error => {
					console.error("Failed to fetch items:", error);
				});
			},
			getEventColor(event) {
				return event.color;
			},
			async saveTemplate() {
				console.log("默认数量:", this.defaultQuantity);
				this.timelines.forEach(item => {
					item.resource_quota = -1; // -1代表不限，1代表住院占用1个号源
					item.appointment_item_way = 1; // 2包含则有效、3不包含则无效、1不限制
					console.log("item.resource_quota ", item.resource_quota);
					item.group_id = this.selectedServiceGroupId;
					item.group_name = this.serviceGroupNmae;
				});
				const requestData = {
					template_id: this.template_id, // 确保已经在data中定义并适当赋值
					template_name: this.template_name, // 确保已经在data中定义并适当赋值
					serviceGroupNmae: this.serviceGroupNmae, // 确保已经在data中定义并适当赋值
					selectedServiceGroupId: this.selectedServiceGroup, // 确保已经在data中定义并适当赋值
					timelines: this.timelines // 这是一个数组
				};
				try {
					const response = await this.$admin.http.post(
						"/scheduling_template/schedulingtemplate/group-scheduling-time-line/", requestData).then(
						response => {
							// 处理响应
							console.log('Form submitted successfully');
							this.$message({
								message: "保存成功！",
								type: "success"
							});
							this.dialogs.maintainSchedulingTemplates = false;
						}).catch(error => {
						// 处理错误
						console.error('Error submitting form', error);
						this.$message({
							message: `"保存失败！"${error}`,
							type: "error"
						});
					});
				} catch (error) {
					this.$message({
						message: `"保存失败！"${error}`,
						type: "error"
					});
				}
			},
			saveTimezone() {
			    // 在这里实现保存逻辑
			    console.log('this.selectWeek ', this.selectWeek);
			
			    const weekMapping = {
			        a: 1,
			        b: 2,
			        c: 3,
			        d: 4,
			        e: 5,
			        f: 6,
			        g: 7
			    };
			
			    // 转换 this.selectWeek 的值
			    let selectWeekInt = null;
			    if (this.selectWeek && weekMapping.hasOwnProperty(this.selectWeek)) {
			        selectWeekInt = weekMapping[this.selectWeek];
			    }
			    console.log('selectWeekInt ', selectWeekInt);
			
			    if (this.same_time_zone_copy || selectWeekInt) {
			        let formTimelines = this.timelines;
			        formTimelines.forEach(item => {
			            item.resource_quota = Number(item.resource_quota); // -1代表不限，1代表住院占用1个号源
			            if (item.appointment_item_way.value > 0) {
			                item.appointment_item_way = Number(item.appointment_item_way.value); // 2包含则有效、3不包含则无效、1不限制
			            }
			        });
			        const requestData = {
			            template_id: this.template_id,
			            selectedServiceGroupId: this.selectedServiceGroupId,
			            same_time_zone_copy: this.same_time_zone_copy,
			            selectWeek: selectWeekInt,
			            timelines: formTimelines
			        };
			        this.$admin.http.post(
			                "/scheduling_template/schedulingtemplate/group-scheduling-timeline-config/", requestData)
			            .then(response => {
			                // 处理响应
			                console.log('Form submitted successfully');
			                this.$message({
			                    message: "保存成功！",
			                    type: "success"
			                });
			                this.dialogs.planScheduleAvailableItem = false;
			            }).catch(error => {
			                // 处理错误
			                console.error('Error submitting form', error);
			                this.$message({
			                    message: `"保存失败！"${error}`,
			                    type: "error"
			                });
			            });
			    } else {
			        this.$message({
			            message: "请启用“相同时段规则复制” 或者选择一个有效的星期",
			            type: "error"
			        });
			    }
			},
			redirectToJoinPage(id, time_line_name, appointment_item_way) {
				// 检查是否为对象且包含value属性
				console.log('Erase Israel ', appointment_item_way);
				if (typeof appointment_item_way === 'object' && appointment_item_way !== null) {
					console.log('appointment_item_way properties:');
					for (const key in appointment_item_way) {
						if (appointment_item_way.hasOwnProperty(key)) {
							console.log(`${key}: ${appointment_item_way[key]}`);
						}
					}
					// 如果appointment_item_way是一个对象并有value属性，则取其value属性
					if ('value' in appointment_item_way) {
						appointment_item_way = appointment_item_way.value;
					}
				}
				const url =
					`/medicaltechnician/JointimelineItem?group_id=${this.selectedServiceGroupId}&group_name=${this.serviceGroupNmae}&id=${id}&appointment_item_way=${appointment_item_way}&template_time_line_name=${time_line_name}`;
				window.open(url, '_blank'); // 使用 _blank 打开新标签页
			},
			// 处理点击日历格子事件
			handleDay({
				date
			}) {
				this.focus = date;
				this.fetchDaytimelines(date);
			},
			// 获取待办事项 -暂时无用 -
			async fetchDaytimelines(date) {
				console.log("fetching fetchDaytimelines:", date);
				try {
					const response = await this.$admin.http.get('scheduling_day/schedulingday/day-schedule/', {
						params: {
							date: moment(date).format('YYYY-MM-DD'),
							group_id: this.selectedServiceGroupId
						}
					});
					console.log('556 ', response.data);
					this.weekSchedule = response.data; // 假设接口返回的待办事项在 data 属性中
					this.dialog = true; // 打开对话框
				} catch (error) {
					console.error("Error fetching weekSchedule:", error);
				}
			},
			itemClass(item) {
				// 检查当前行是否被选中
				return this.selectedRow && this.selectedRow.id === item.id ? 'selected' : '';
			},
			removeTodo() {
				if (this.selectedRowIndex === null) {
					this.$message({
						message: "请选中一行后，进行删除",
						type: "error",
					});
				} else {
					this.timelines.splice(this.selectedRowIndex, 1);
					this.selectedRowIndex = null; // 重置选中的行索引
				}
			},
			// 保存按钮事件处理器
			saveResourceConfig() {
				// 在这里实现保存逻辑
				// 构建请求体，这里假设后端需要的数据格式是一个数组，每个元素代表一周的配置
				const resourceConfig = this.weekConfig.map(config => ({
					template_id: this.template_id,
					mType: config.mType, // 诊疗类型
					days: config.days.map(day => day.value) // 将周一到周日的值数组化
				}));

				// 执行 POST 请求
				this.$admin.http.post('/scheduling_template/schedulingtemplate/resource-config/', resourceConfig)
					.then(response => {
						// 请求成功
						console.log('保存成功', response);
						this.$message({
							message: '资源配置已成功保存！',
							type: 'success'
						});
						this.dialogs.resourceAllocation = false;
					})
					.catch(error => {
						// 请求失败
						console.error('保存失败', error);
						this.$message({
							message: `保存失败：${error.message}`,
							type: 'error'
						});
					});
				// 可能需要关闭对话框
				this.dialog = false;
			},
			saveSchedule() {
				// 构造请求体，包含开始日期、结束日期和排班数据
				const requestData = {
					startDate: this.startDate,
					endDate: this.endDate,
					group_id: this.selectedServiceGroupId,
					group_name: this.serviceGroupNmae,
					schedules: this.weekSchedule.map(schedule => ({
						template_time_line_id: schedule.id,
						timeLineName: schedule.time_line_name,
						start_time: schedule.start_time,
						end_time: schedule.end_time,
						resource_no_start: schedule.resource_no_start,
						resource_no_end: schedule.resource_no_end,
						resource_count: schedule.resource_count,
						appointment_item_way: schedule.appointment_item_way, // 特殊项目规则
						item_rule_a: schedule.item_rule_a, // 特殊项目规则
						item_rule_b: schedule.item_rule_b, // 特殊项目规则
						item_rule_c: schedule.item_rule_c, // 特殊项目规则
						item_rule_d: schedule.item_rule_d, // 特殊项目规则
						item_rule_e: schedule.item_rule_e, // 特殊项目规则
						item_rule_f: schedule.item_rule_f, // 特殊项目规则
						item_rule_g: schedule.item_rule_g, // 特殊项目规则
						back_up_time_zone: schedule.back_up_time_zone, // 备用时段
						resource_quota: schedule.resource_quota, // 住院限额
						resource_quota_in_a: schedule.resource_quota_in_a, // 住院限额
						resource_quota_in_b: schedule.resource_quota_in_b, // 住院限额
						resource_quota_in_c: schedule.resource_quota_in_c, // 住院限额
						resource_quota_in_d: schedule.resource_quota_in_d, // 住院限额
						resource_quota_in_e: schedule.resource_quota_in_e, // 住院限额
						resource_quota_in_f: schedule.resource_quota_in_f, // 住院限额
						resource_quota_in_g: schedule.resource_quota_in_g, // 住院限额
						daysData: schedule.days_data.map(day => parseInt(day.value))
					}))
				};
				// 使用 axios 或其他 HTTP 客户端发送 POST 请求
				this.$admin.http.post('/scheduling_day/schedulingday/save-scheduling/', requestData)
					.then(response => {
						// 处理响应
						this.$message({
							message: '排班数据保存成功！',
							type: 'success'
						});
						this.updateCalendarData(); // 更新日历数据
						this.dialogs.planSchedule = false;
					})
					.catch(error => {
						// 处理错误
						console.error('保存排班数据失败:', error);
						this.$message({
							message: `保存失败：${error.message}`,
							type: 'error'
						});
					});
			},
			// 保存按钮事件处理器
			saveTasks() {
				// 在这里实现保存逻辑
				console.log("保存操作日期 ", this.focus);
				console.log("保存操作服务组 ", this.selectedServiceGroupId);
				// 构造请求体，包含开始日期、结束日期和排班数据
				const requestData = {
					date: this.focus,
					group_id: this.selectedServiceGroupId,
					group_name: this.serviceGroupNmae,
					schedules: this.weekSchedule.map(schedule => ({
						template_time_line_id: schedule.id,
						timeLineName: schedule.time_line_name,
						start_time: schedule.start_time,
						end_time: schedule.end_time,
						resource_no_start: schedule.resource_no_start,
						resource_no_end: schedule.resource_no_end,
						resource_count: schedule.resource_count,
						appointment_item_way: schedule.appointment_item_way, // 特殊项目规则
						item_rule_a: schedule.item_rule_a, // 特殊项目规则
						item_rule_b: schedule.item_rule_b, // 特殊项目规则
						item_rule_c: schedule.item_rule_c, // 特殊项目规则
						item_rule_d: schedule.item_rule_d, // 特殊项目规则
						item_rule_e: schedule.item_rule_e, // 特殊项目规则
						item_rule_f: schedule.item_rule_f, // 特殊项目规则
						item_rule_g: schedule.item_rule_g, // 特殊项目规则
						back_up_time_zone: schedule.back_up_time_zone, // 备用时段
						resource_quota: schedule.resource_quota, // 住院限额
						resource_quota_in_a: schedule.resource_quota_in_a, // 住院限额
						resource_quota_in_b: schedule.resource_quota_in_b, // 住院限额
						resource_quota_in_c: schedule.resource_quota_in_c, // 住院限额
						resource_quota_in_d: schedule.resource_quota_in_d, // 住院限额
						resource_quota_in_e: schedule.resource_quota_in_e, // 住院限额
						resource_quota_in_f: schedule.resource_quota_in_f, // 住院限额
						resource_quota_in_g: schedule.resource_quota_in_g, // 住院限额
						daysData: schedule.days_data.map(day => parseInt(day.value))
					}))
				};
				// 使用 axios 或其他 HTTP 客户端发送 POST 请求
				this.$admin.http.post('/scheduling_day/schedulingday/save-plan-count-scheduling/', requestData)
					.then(response => {
						// 处理响应
						this.$message({
							message: '排班数据保存成功！',
							type: 'success'
						});
						this.updateCalendarData(); // 更新日历数据
						this.dialogs.planSchedule = false;
					})
					.catch(error => {
						// 处理错误
						console.error('保存排班数据失败:', error);
						this.$message({
							message: `保存失败：${error.message}`,
							type: 'error'
						});
					});
				// 可能需要关闭对话框
				this.dialog = false;
			},
			// 排满按钮事件处理器
			markAsFull() {
				console.log("排满操作日期 ", this.focus);
				console.log("排满操作服务组 ", this.selectedServiceGroupId);
				
				this.$admin.http.get(`/scheduling_template/schedulingtemplate/group-scheduling/?group_id=${this.selectedServiceGroupId}`)
					.then(response => {
						// 假设返回的数据结构符合你的描述
						if (response.data && response.data.timelines) {
							// this.timelines = [];
							this.weekSchedule = response.data.timelines;
						}
					})
					.catch(error => {
						console.error("Failed to fetch scheduling templates:", error);
						this.$message({
							message: "排班操作失败",
							type: "error",
						});
					});					
			},
			addTodo() {
				const maxId = this.timelines.reduce((max, item) => Math.max(max, item.id), 0);
				console.log('max id ', maxId);
				const newTodo = {
					id: maxId + 1,
					time_line_name: null,
					start_time: null,
					end_time: null,
					resource_no_start: 20,
					resource_no_end: null,
					back_up_time_zone: false,
				};
				// this.timelines.push(newTodo);
				this.$set(this.timelines, this.timelines.length, newTodo);
			},
			selectRow(item, index) {
				this.selectedRowIndex = index.index;
				this.selectedRow = item;
			},
			// 点击行时调用此方法来设置选中的记录
			selectTodo(index) {
				console.log('now selected = ', index);
				this.selectedTodoIndex = index;
			},
		}
	};
</script>

<style scoped>
	.date-text {
		font-size: 20px;
		font-weight: bold;
		width: 100%;
		text-align: center;
	}

	.theme--light /deep/ .v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
		color: #000;
	}

	.template-options {
		margin-left: 20px;
		margin-right: 20px;
		padding-left: 50px;
		padding-right: 50px;
		background-color: white;
		margin-top: 13px;
		margin-bottom: 0px;
		text-align: center;
		border-radius: 12px;
	}

	.template-options-row {
		align-items: baseline;
	}

	.template-options-btn {
		background-color: #42b6c4;
		border-radius: 12px;
		color: white;
		font-size: larger;
		font-weight: bold;
		padding-top: 8px;
		padding-bottom: 8px;
		/* 减小左右间距 */
		margin-left: 5px;
		margin-right: 5px;
		/* 保持顶部和底部间距 */
		margin-top: 20px;
		margin-bottom: 20px;
	}

	.btn-container {
		display: flex;
		align-items: center;
		/* 确保按钮垂直居中 */
		padding: 0;
		/* 移除默认的填充 */
	}

	.btn-icon {
		font-size: 40px;
		font-weight: bolder;
	}

	::v-deep .selected {
		background-color: #e0e0e0;
		/* 淡蓝色背景，您可以选择其他颜色 */
	}

	.title-info {
		font-size: small;
	}
</style>