<template>
  <!-- <va-edit-layout> -->
    <bs-doctor-form :id="id" :title="title" :item="item"></bs-doctor-form>
  <!-- </va-edit-layout> -->
</template>

<script>
export default {
  props: ["id", "title", "item"],
};
</script>
