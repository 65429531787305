<template>
  <va-form :id="id" :item="item">
    <v-card-text>
      <va-text-input source="name" v-model="xman.name"></va-text-input>
      <va-text-input source="code" v-model="xman.code"></va-text-input>
      <va-text-input
        source="parentcode"
        v-model="xman.parentcode"
      ></va-text-input>
      <va-text-input
        source="office_code"
        v-model="xman.office_code"
      ></va-text-input>
      <va-text-input
        source="office_name"
        v-model="xman.office_name"
      ></va-text-input>
      <va-text-input source="remark" v-model="xman.remark"></va-text-input>
    </v-card-text>
    <div class="btn_bg">
      <v-btn @click="isSure" color="primary">保存</v-btn>
    </div>
  </va-form>
</template>

<script>
export default {
  props: ["id", "title", "item"],
  data() {
    return {
      xman: {
        name: "",
        code: "",
        parentcode: "",
        office_code: "",
        office_name: "",
        create_time: null,
        remark: "",
      },
    };
  },
  methods: {
    async isSure() {
      if (this.id) {
        try {
          this.xman.create_time = new Date().toISOString();
          console.log(this.xman.create_time);
          await this.$store
            .dispatch("ig_service_group_type/update", {
              id: this.id,
              data: this.xman,
            })
            .then((res) => {
              this.$emit("saved");
            });
        } catch {
          // this.$message({
          //   message: "医院编码违反唯一性，请修改",
          //   type: "error",
          // });
        }
      } else {
        this.xman.create_time = new Date().toISOString();
        console.log(this.xman.create_time);
        await this.$store
          .dispatch("ig_service_group_type/create", {
            data: this.xman,
          })
          .then((res) => {
            this.$emit("saved");
            // if (this.file_data && this.file_data.length >= 1) {
            //   this.httpRequest(this.imgfile, res.data.id, res.data.logo);
            // }
          })
          .catch((err) => {
            // this.$message({
            //   message: "医院编码违反唯一性，请修改",
            //   type: "error",
            // });
          });
      }
    },
  },
};
</script>
<style scoped>
.btn_bg {
  width: 95%;
  position: fixed;
  bottom: 0px;
  padding: 10px 0;
  text-align: center;
  background-color: white;
}
</style>