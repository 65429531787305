<template>
  <va-show-layout>
    <va-show :item="item">
      <v-row justify="center">
        <v-col sm="6">
          <base-material-card>
            <template v-slot:heading>
              <div class="display-2">
                {{ title }}
              </div>
            </template>
            <v-card-text>
              <va-field source="created_time" type="date"></va-field>
              <va-field source="updated_time" type="date"></va-field>
              <va-field source="is_active" type="boolean"></va-field>
              <va-field source="pharmacy_code"></va-field>
              <va-field source="pharmacy_name"></va-field>
              <va-field source="belong_unit"></va-field>
              <va-field source="address"></va-field>
              <va-field source="pharmacy_type_id" type="select"></va-field>
            </v-card-text>
          </base-material-card>
        </v-col>
      </v-row>
    </va-show>
  </va-show-layout>
</template>

<script>
export default {
  props: ["title", "item"],
};
</script>
