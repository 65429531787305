<template>
  <base-material-card title="MARS">
    <va-list>
      <va-data-table :fields="fields"></va-data-table>
    </va-list>
  </base-material-card>
</template>

<script>
export default {
  props: ["resource", "title"],
  data() {
    return {
      asideTitle: null,
      id: null,
      item: null,
      show: false,
      filters: [],
      filter: {},
      fields: [
        {
          source: "device_name",
          label: "设备名称",
          sortable: false,
          align: "center",
        },
        {
          source: "device_model",
          label: "设备型号",
          sortable: false,
          align: "center",
        },
        {
          source: "device_status",
          label: "设备状态",
          sortable: false,
          align: "center",
          type: "boolean",
        },
        {
          source: "online_date",
          label: "上线日期",
          sortable: false,
          align: "center",
        },
        {
          source: "remark",
          label: "备注",
          sortable: false,
          align: "center",
        },
		{
		  source: "",
		  label: "操作",
		  sortable: false,
		  align: "center",
		},
      ],
    };
  },
  
  created() {
    this.getSupper();
  },
  methods: {
    getSupper() {
      let user_data = JSON.parse(window.sessionStorage.getItem("user"));
      if (user_data.is_superuser) {
        this.filters.push({
          source: "hospital_name",
          label: "医院科室位置",
          type: "text",
          alwaysOn: true,
          // attributes: { reference: "auth_system_user.id" },
        });
      } else {
        this.filter = { hospital_name: user_data.hospital_name };
      }
    },
    async onAction({ action, title, id, item }) {
      this.asideTitle = title;
      this.id = id;
      this.show = action === "show";
      this.item = item;
      if (action === "create") {
        this.id = null;
      }
    },
    onSaved() {
      this.asideTitle = null;
      this.$refs.list.fetchData();
    },
    // 导入
    on_show_import() {},
    // 导出
    on_export_excel() {},
  },
};
</script>
