<template>
  <va-create-layout>
    <bs-pharmacy-management-form
      :title="title"
      :item="item"
    ></bs-pharmacy-management-form>
  </va-create-layout>
</template>

<script>
export default {
  props: ["title", "item"],
};
</script>
