<template>
  <va-form>
    <v-card>
      <v-card-text style="width: 100%; margin: 0 auto">
        <v-row style="text-alin: center; margin-top: 15px">
          <v-col>
            <el-input
              v-model="temp_name"
              placeholder="请输入模板名称"
            ></el-input>
          </v-col>
          <v-col>
            <el-select
              style="width: 100%"
              v-model="hospital_code"
              filterable
              placeholder="请选择所属医院"
              v-show="user.is_superuser"
            >
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.name"
                :value="item.codenum"
              >
              </el-option>
            </el-select>
          </v-col>
          <v-col>
            <el-select
              style="width: 100%"
              v-model="office_code"
              filterable
              placeholder="请选择所属科室"
              v-show="user.is_superuser"
            >
              <el-option
                v-for="item in options1"
                :key="item.id"
                :label="item.name"
                :value="item.codenum"
              >
              </el-option>
            </el-select>
          </v-col>
        </v-row>
        <v-row style="text-alin: center">
          <v-col>
            <el-select
              style="width: 100%"
              v-model="temp_type"
              filterable
              placeholder="请选择模板项目"
            >
              <el-option
                v-for="item in options2"
                :key="item.name"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </v-col>
          <v-col>
            <el-select
              style="width: 100%"
              v-model="item_code"
              filterable
              placeholder="请选择检查/检验项目"
              remote
              :remote-method="remoteMethod"
              clearable
              v-scroll-loadMore="loadmore"
              infinite-scroll-distance="1"
              infinite-scroll-immediate="true"
              infinite-scroll-disabled="false"
            >
              <el-option
                v-for="item in options3"
                :key="item.id"
                :label="item.project_name"
                :value="item.project_code"
                style="height: 35px; overflow: auto"
              >
              </el-option>
            </el-select>
          </v-col>
          <v-col></v-col>
        </v-row>
      </v-card-text>
      <el-table
        style="width: 99%; overflow-x: auto; margin: 0 auto"
        :header-cell-style="{
          background: '#FFFFFF',
          textAlign: 'center',
          margin: '20px 0',
        }"
      >
        <el-table-column
          v-for="(item, index) in tableData"
          :key="index"
          :label="label_data(String(item.what_day))"
        >
          <el-table-column
            v-for="(items, index) in tableData[index].data"
            :key="index"
            :label="label_value(items.noontype_code)"
          >
            <el-table-column class="container">
              <template slot="header" slot-scope="scope">
                {{ scope.row }}
                <el-input
                  class="paperview-input-text"
                  v-model="items.num_count"
                  size="mini"
                  placeholder="号源"
                  :disabled="items.is_dayoff"
                />
              </template>
              <el-table-column>
                <template slot="header" slot-scope="scope">
                  {{ scope.row }}
                  <el-time-picker
                    format="HH:mm"
                    value-format="HH:mm:ss"
                    class="paperview-input-text"
                    size="mini"
                    v-model="items.start_time"
                    placeholder="开始时间"
                    :disabled="items.is_dayoff"
                    :picker-options="{
                      selectableRange: '07:00:00 - 12:00:00',
                    }"
                    v-if="items.noontype_code == 'am'"
                  >
                  </el-time-picker>
                  <el-time-picker
                    format="HH:mm"
                    value-format="HH:mm:ss"
                    class="paperview-input-text"
                    size="mini"
                    v-model="items.start_time"
                    placeholder="开始时间"
                    :disabled="items.is_dayoff"
                    :picker-options="{
                      selectableRange: '12:00:00 - 18:00:00',
                    }"
                    v-else-if="items.noontype_code == 'pm'"
                  >
                  </el-time-picker>
                  <el-time-picker
                    format="HH:mm"
                    value-format="HH:mm:ss"
                    class="paperview-input-text"
                    size="mini"
                    v-model="items.start_time"
                    placeholder="开始时间"
                    :disabled="items.is_dayoff"
                    :picker-options="{
                      selectableRange: '18:00:00 - 23:59:59',
                    }"
                    v-else
                  >
                  </el-time-picker>
                </template>
                <el-table-column>
                  <template slot="header" slot-scope="scope">
                    {{ scope.row }}
                    <el-time-picker
                      format="HH:mm"
                      value-format="HH:mm"
                      class="paperview-input-text"
                      size="mini"
                      v-model="items.end_time"
                      placeholder="结束时间"
                      :disabled="items.is_dayoff"
                      :picker-options="{
                        selectableRange: `${items.start_time} - 23:59:59`,
                      }"
                    >
                    </el-time-picker>
                  </template>
                  <el-table-column>
                    <template slot="header" slot-scope="scope">
                      {{ scope.row }}
                      <el-checkbox
                        @change="checkout(items)"
                        v-model="items.is_dayoff"
                        >休息</el-checkbox
                      >
                    </template>
                  </el-table-column>
                </el-table-column></el-table-column
              >
            </el-table-column>
          </el-table-column>
        </el-table-column>
        <template slot="empty">
          <div></div>
        </template>
      </el-table>
      <div class="btn_bg">
        <v-btn @click="isSure" color="primary">保存</v-btn>
        <v-btn @click="notSure" style="color: #14b2c5; margin-left: 50px"
          >取消</v-btn
        >
      </div>
    </v-card>
  </va-form>
</template>

<script>
import moment from "moment";
// import SelectInput from "@/components/VaCustomSelectInput.vue";
import { log, table } from "console";
export default {
  props: ["id"],
  // components: { SelectInput },
  data() {
    return {
      user: null,
      hospital_code: null,
      hospital_id: null,
      hospital_name: null,
      office_code: null,
      office_name: null,
      item_name: null,
      item_code: null,
      temp_type: null,
      temp_name: null,
      options: null,
      options1: null,
      options2: [
        { value: 1, name: "检查" },
        { value: 2, name: "检验" },
      ],
      options3: null,
      zd_limit: 10,
      tableData: [],
      data_list: {},
      timeMap: new Map([
        ["1", "星期一"],
        ["2", "星期二"],
        ["3", "星期三"],
        ["4", "星期四"],
        ["5", "星期五"],
        ["6", "星期六"],
        ["7", "星期日"],
      ]),
      valueMap: new Map([
        ["1am", "上午"],
        ["2pm", "下午"],
        ["3night", "晚上"],
      ]),
    };
  },
  created() {
    this.user = JSON.parse(window.sessionStorage.getItem("user"));
    this.getUser();
    this.getSelect();
  },
  mounted() {
    this.getdatas();
  },
  computed: {
    dept_filter() {
      if (this.hospital_id) {
        return {
          hospital_id: this.hospital_id,
          is_active: true,
        };
      } else {
        return {};
      }
    },
  },
  methods: {
    checkout(val) {
      if (val.is_dayoff) {
        console.log(val.is_dayoff);
        val.end_time = null;
        val.start_time = null;
        val.num_count = 0;
      }
    },
    loadmore() {
      this.zd_limit = this.zd_limit + 10;
    },
    getSelect() {
      this.$store.dispatch("bs_hospital/getList").then((res) => {
        this.options = res.data;
      });
    },
    remoteMethod(query) {
      console.log(111111111111);
      if (query !== "") {
        console.log(111111111111111, this.temp_type);
        if (this.temp_type == 1) {
          this.$admin.http
            .get(`/base-system/ins_dic/?project_name=${query}`)
            .then((res) => {
              this.options3 = res.data.results;
            });
        } else {
          this.$admin.http
            .get(`/base-system/exa_dic/?project_name=${query}`)
            .then((res) => {
              this.options3 = res.data.results;
            });
        }
      } else {
        this.options3 = [];
      }
    },
    getUser() {
      this.$store
        .dispatch("bs_hospital/getOne", {
          filter: {
            id: this.user.hospital,
          },
        })
        .then((res) => {
          this.hospital_name = res.data.name;
          this.hospital_code = res.data.codenum;
        });
      this.$store
        .dispatch("bs_office/getOne", {
          filter: {
            id: this.user.office,
          },
        })
        .then((res) => {
          this.office_name = res.data.name;
          this.office_code = res.data.codenum;
        });
    },
    label_data(t) {
      return this.timeMap.get(t);
    },
    label_value(v) {
      return this.valueMap.get(v);
    },
    getdatas() {
      if (this.id) {
        this.$admin.http
          .get(`medical-technology/templates/${this.id}/`)
          .then((res) => {
            if (res.data) {
              this.data_list = res.data;
              this.temp_name = this.data_list.temp_name;
              this.hospital_code = this.data_list.hospital_code;
              this.office_name = this.data_list.office_name;
              this.office_code = this.data_list.office_code;
              this.temp_type = Number(this.data_list.temp_type);
              this.item_code = this.data_list.item_code;
              this.change_data();
            }
          });
      } else {
        this.data_list = {
          template_details: [],
        };
        let noonData = ["am", "pm", "night"];
        for (var i = 1; i <= 7; i++) {
          noonData.forEach((item, index) => {
            this.data_list.template_details.push({
              what_day: i,
              num_count: 0,
              noontype_code: item,
              start_time: null,
              end_time: null,
              is_dayoff: false,
            });
          });
        }
        this.change_data();
      }
    },
    notSure() {
      this.$router.push(
        "/mt-template-main" //跳转
      );
    },
    async isSure() {
      this.data_list.temp_name = this.temp_name;
      this.data_list.office_code = this.office_code;
      this.data_list.office_name = this.office_name;
      this.data_list.hospital_code = this.hospital_code;
      this.data_list.hospital_name = this.hospital_name;
      this.data_list.is_active = true;
      this.data_list.item_name = this.item_name;
      this.data_list.item_code = this.item_code;
      this.data_list.temp_type = this.temp_type;
      console.log(this.data_list);
      if (this.id) {
        this.$admin.http
          .put(`/medical-technology/templates/${this.id}/`, this.data_list)
          .then((res) => {
            this.$message({
              message: "修改成功",
              type: "success",
            });
            this.notSure();
            console.log(res);
          });
      } else {
        this.$admin.http
          .post("medical-technology/templates/", this.data_list)
          .then((res) => {
            console.log(res);
            this.$message({
              message: "新建成功",
              type: "success",
            });
            this.notSure();
          });
      }
    },
    change_data() {
      let arr = this.data_list.template_details;
      var map = {};
      for (var i = 0; i < arr.length; i++) {
        var ai = arr[i];
        if (!map[ai.what_day]) {
          this.tableData.push({
            what_day: ai.what_day,
            name: ai.name,
            data: [ai],
          });
          map[ai.what_day] = ai;
        } else {
          for (var j = 0; j < this.tableData.length; j++) {
            var dj = this.tableData[j];
            if (dj.what_day == ai.what_day) {
              dj.data.push(ai);
              break;
            }
          }
        }
      }
    },
    getText(val) {
      if (val == 1) {
        this.$admin.http
          .get(
            `base-system/ins_dic/?page=1&page_size=${this.zd_limit}&hospital_name=${this.hospital_name}`
          )
          .then((res) => {
            this.options3 = res.data.results;
          });
      } else {
        this.$admin.http
          .get(
            `base-system/exa_dic/?page=1&page_size=${this.zd_limit}&hospital_name=${this.hospital_name}`
          )
          .then((res) => {
            this.options3 = res.data.results;
          });
      }
    },
  },
  watch: {
    hospital_code: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
          this.$store
            .dispatch("bs_hospital/getList", {
              filter: {
                codenum: val,
              },
            })
            .then((res) => {
              if (this.user.is_superuser) {
                this.hospital_name = res.data[0].name;
              }
              this.hospital_id = res.data[0].id;
              this.$store
                .dispatch("bs_office/getList", {
                  filter: {
                    hospital_id: this.hospital_id,
                  },
                })
                .then((res) => {
                  this.options1 = res.data;
                });
            });
        } else {
          this.$store.dispatch("bs_office/getList").then((res) => {
            this.options1 = res.data;
          });
        }
      },
    },
    office_code: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
          this.$store
            .dispatch("bs_office/getList", {
              filter: {
                codenum: val,
              },
            })
            .then((res) => {
              console.log(res.data[0].name);
              this.office_name = res.data[0].name;
            });
        }
      },
    },
    zd_limit: {
      deep: true,
      immediate: true,
      handler(val) {
        this.getText(this.temp_type);
      },
    },
    item_code: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
          if (this.temp_type == 1) {
            this.$admin.http
              .get(`base-system/ins_dic/?project_code=${val}`)
              .then((res) => {
                console.log(res.data, res.data.results[0].project_name);
                this.item_name = res.data.results[0].project_name;
              });
          } else {
            this.$admin.http
              .get(`base-system/exa_dic/?project_code=${val}`)
              .then((res) => {
                this.item_name = res.data.results[0].project_name;
              });
          }
        }
      },
    },
    temp_type: {
      deep: true,
      immediate: true,
      handler(val) {
        this.getText(val);
      },
    },
  },
};
</script>
<style scoped>
.input {
  outline: none;
}
.first_header {
  display: flex;
  width: 100%;
  margin: 0 auto;
  border: 0.5px solid #f8f8f8;
}

.first_header_cell {
  padding: 5px 10px;
  width: 13%;
  height: 40px;
  line-height: 30px;
  text-align: center;
  border: 0.5px solid #f8f8f8;
}

.sec_header_cell {
  display: flex;
  width: 13%;
  height: 40px;
  text-align: center;
}

.sec_header_title {
  width: 33.33%;
  line-height: 38px;
  border: 1px solid #f8f8f8;
}

.sec_header {
  margin-top: -2px;
}

.btn_bg {
  width: 87%;
  position: fixed;
  bottom: 0px;
  padding: 10px 0;
  text-align: center;
  background-color: white;
  z-index: 99;
}
.el-date-editor /deep/ .el-input__prefix {
  display: none !important;
}
.el-input--prefix /deep/ .el-input__inner {
  width: 100%;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.paperview-input-text /deep/ .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border: 0px;
  width: 100%;
  display: inline-block;
}
</style>
