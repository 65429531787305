<template>
  <v-card :icon="resource.icon" :title="title">
    <va-list
      :filters="filters"
      :filter="filter"
      disable-export
      :sort-by="['id']"
      :sort-desc="[true]"
    >
      <va-data-table :fields="fields" disable-clone disable-show>
        <template v-slot:[`field.noontype_code`]="{ item }">
          <template v-if="item.noontype_code == 'am'"> 上午 </template>
          <template v-else-if="item.noontype_code == 'pm'"> 下午 </template>
          <template v-else> 晚上 </template>
        </template>
        <template v-slot:[`field.state`]="{ item }">
          <template v-if="item.state == '1'"> 已预约 </template>
          <template v-else-if="item.state == '2'"> 已完成 </template>
          <template v-else> 爽约 </template>
        </template>
      </va-data-table>
    </va-list>
  </v-card>
</template>

<script>
export default {
  props: ["resource", "title"],
  data() {
    return {
      filters: [],
      filter: {},
      fields: [
        { source: "id_num", label: "身份证号", sortable: false },
        { source: "patient_name", label: "患者姓名", sortable: false },
        { source: "item_code", label: "项目编码", sortable: false },
        { source: "item_name", label: "项目名称", sortable: false },
        { source: "hospital_name", label: "所属医院", sortable: false },
        { source: "office_name", label: "所属科室", sortable: false },
        { source: "name_srvtp", label: "检查类别", sortable: false },
        { source: "item_fee", label: "项目费用", sortable: false },
        {
          source: "plan_date",
          label: "预约时间",
          sortable: false,
          type: "date",
          attributes: { format: "longTime" },
        },
        { source: "noontype_code", label: "时段", sortable: false },
        { source: "state", label: "状态", sortable: false },
      ],
    };
  },
  created() {
    this.getSupper();
  },
  methods: {
    getSupper() {
      let user_data = JSON.parse(
        window.sessionStorage.getItem("user")
      );
      if (user_data.is_superuser) {
        this.filters.push({
          source: "hospital_name",
          label: "所属医院",
          type: "text",
          alwaysOn: true,
        });
      }else{
        this.filter = {hospital_name: user_data.hospital_name}
      }
    },
  }
};
</script>
