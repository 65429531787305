<template>
  <div>
    <v-row>
      <v-col>
        <va-select-input
          required
          label="所属医院"
          reference="bs_hospital"
          :items-per-page="1000"
          :filter="{ is_active: true }"
          source="hospital_id"
          v-model="form.hospital_id"
        ></va-select-input>
        <va-select-input
          required
          label="选择模板"
          reference="ws_work_scheduling_main"
          :items-per-page="1000"
          :filter="{ is_active: true }"
          v-model="form.form"
        ></va-select-input>
      </v-col>
      <v-col>
        <SelectInput
          required
          label="所属科室"
          source="office_id"
          reference="bs_office"
          :items-per-page="1000"
          ref="dept"
          :filter="dept_filter"
          v-model="form.office_id"
        ></SelectInput>
      </v-col>
      <v-col>
        <SelectInput
          required
          label="医生姓名"
          source="doctor_id"
          reference="bs_doctor"
          :items-per-page="1000"
          ref="dept"
          :filter="dept_filters"
          v-model="form.doctor_id"
        ></SelectInput>
      </v-col>
    </v-row>

    <div style="padding: 20px; margin-top: 8px" v-if="form.form">
      <div class="first_header">
        <div class="first_header_cell" style="width: 9%">
          <span>星期</span>
        </div>
        <div
          class="first_header_cell"
          v-for="(item, index) of this.data_list.ws_doctor_schedule_days.data"
          :key="index"
        >
          <span v-if="item.week_day == 1">{{ item.work_date }} 周一</span>
          <span v-else-if="item.week_day == 2">{{ item.work_date }} 周二</span>
          <span v-else-if="item.week_day == 3">{{ item.work_date }} 周三</span>
          <span v-else-if="item.week_day == 4">{{ item.work_date }} 周四</span>
          <span v-else-if="item.week_day == 5">{{ item.work_date }} 周五</span>
          <span v-else-if="item.week_day == 6">{{ item.work_date }} 周六</span>
          <span v-else-if="item.week_day == 7">{{ item.work_date }} 周日</span>
        </div>
      </div>
      <div class="first_header sec_header">
        <div class="first_header_cell" style="width: 9%">
          <span>午别</span>
        </div>
        <div
          class="sec_header_cell"
          v-for="(mitem, m) of this.data_list.ws_doctor_schedule_days.data"
          :key="m"
        >
          <div
            class="sec_header_title"
            v-for="(nitem, n) of mitem.ws_doctor_work_shifts.data"
            :key="n"
          >
            {{ nitem.name }}
          </div>
        </div>
      </div>
      <div class="first_header sec_header" style="margin-top: -2px">
        <div class="first_header_cell" style="width: 9%">
          <span>号源量</span>
        </div>
        <div
          class="sec_header_cell"
          v-for="(mitem, m) of this.data_list.ws_doctor_schedule_days.data"
          :key="m"
        >
          <div
            class="sec_header_title"
            v-for="(nitem, n) of mitem.ws_doctor_work_shifts.data"
            :key="n"
          >
            <div v-if="nitem.is_active">
              <!-- {{
                nitem.is_active == true
                  ? (nitem.num_count = 0)
                  : (nitem.num_count = null)
              }} -->
            </div>
            <input
              v-else
              v-model="nitem.num_count"
              style="
                width: 100%;
                height: 100%;
                line-height: 38px;
                text-align: center;
                outline: none;
              "
            />
          </div>
        </div>
      </div>
      <div class="first_header sec_header" style="margin-top: -2px">
        <div class="first_header_cell" style="width: 9%">
          <span>开始时间</span>
        </div>
        <div
          class="sec_header_cell"
          v-for="(mitem, m) of this.data_list.ws_doctor_schedule_days.data"
          :key="m"
        >
          <div
            class="sec_header_title"
            v-for="(nitem, n) of mitem.ws_doctor_work_shifts.data"
            :key="n"
          >
            <el-time-picker
              :clearable="false"
              style="width: 100%"
              id="time"
              v-model="nitem.start_time"
              format="HH:mm"
                  value-format="HH:mm"
            >
            </el-time-picker>
          </div>
        </div>
      </div>
      <div class="first_header sec_header" style="margin-top: -3px">
        <div class="first_header_cell" style="width: 9%">
          <span>结束时间</span>
        </div>
        <div
          class="sec_header_cell"
          v-for="(mitem, m) of this.data_list.ws_doctor_schedule_days.data"
          :key="m"
        >
          <div
            class="sec_header_title"
            v-for="(nitem, n) of mitem.ws_doctor_work_shifts.data"
            :key="n"
          >
            <el-time-picker
              :clearable="false"
              style="width: 100%"
              id="time_0"
              v-model="nitem.end_time"
              format="HH:mm"
              value-format="HH:mm"
            >
            </el-time-picker>
          </div>
        </div>
      </div>
      <div class="first_header sec_header" style="margin-top: -3px">
        <div class="first_header_cell" style="width: 9%">
          <span>是否休息</span>
        </div>
        <div
          class="sec_header_cell"
          v-for="(mitem, m) of this.data_list.ws_doctor_schedule_days.data"
          :key="m"
        >
          <div
            class="sec_header_title"
            v-for="(nitem, n) of mitem.ws_doctor_work_shifts.data"
            :key="n"
          >
            <el-checkbox v-model="nitem.is_active">休息</el-checkbox>
          </div>
        </div>
      </div>
    </div>

    <div class="btn_bg">
      <v-btn @click="notSure(type_id)" style="color: #14b2c5; margin-right: 50px"
        >取消</v-btn
      >
      <v-btn @click="isSure(type_id)" color="primary">保存</v-btn>
    </div>
  </div>
</template>

<script>
import SelectInput from "@/components/VaCustomSelectInput.vue";
import moment from "moment";
export default {
  props: ["id", "type_fee"],
  components: {
    SelectInput,
  },
  data() {
    return {
      form: {
        hospital_id: null,
        office_id: null,
        doctor_id: null,
        form: null,
        is_active: true,
      },
      data_list: {},
      doctor_data: null,
      nextWeek: null,
      type_id: null,
    };
  },
  computed: {
    dept_filter() {
      if (this.form.hospital_id) {
        return { hospital_id: this.form.hospital_id, is_active: true };
      } else {
        return {};
      }
    },
    dept_filters() {
      if (this.form.office_id) {
        return { office_id: this.form.office_id, is_active: true };
      } else {
        return {};
      }
    },
  },
  mounted() {
    if (this.$route.query.type_id) {
      this.type_id = this.$route.query.type_id;
    } else {
      this.type_id = 1;
    }
  },
  methods: {
    getNextWeek(i) {
      let weekOfDay = parseInt(moment().format("E")); //计算今天是这周第几天
      let next_monday = moment()
        .add(7 - weekOfDay + 7 * (i - 1) + 1, "days")
        .format("YYYY-MM-DD"); //周一日期
      let next_Tuesday = moment()
        .add(7 - weekOfDay + 7 * (i - 1) + 2, "days")
        .format("YYYY-MM-DD"); //周一日期
      let next_Wednesday = moment()
        .add(7 - weekOfDay + 7 * (i - 1) + 3, "days")
        .format("YYYY-MM-DD"); //周一日期
      let next_Thursday = moment()
        .add(7 - weekOfDay + 7 * (i - 1) + 4, "days")
        .format("YYYY-MM-DD"); //周一日期
      let next_Friday = moment()
        .add(7 - weekOfDay + 7 * (i - 1) + 5, "days")
        .format("YYYY-MM-DD"); //周一日期
      let next_Saturday = moment()
        .add(7 - weekOfDay + 7 * (i - 1) + 6, "days")
        .format("YYYY-MM-DD"); //周一日期
      let next_sunday = moment()
        .add(7 - weekOfDay + 7 * i, "days")
        .format("YYYY-MM-DD"); //周日日期
      return [
        next_monday,
        next_Tuesday,
        next_Wednesday,
        next_Thursday,
        next_Friday,
        next_Saturday,
        next_sunday,
      ];
    },
    //前端排序方法
    compare(property) {
      return function (a, b) {
        var value1 = a[property];
        var value2 = b[property];
        return value1 - value2;
      };
    },
    async notSure(type_id) {
      if (type_id == 1) {
        this.$router.push(
          "/ws-doctor-work-scheduling-main" //跳转
        );
      }
      if (type_id == 3) {
        this.$router.push(
          "/ws-doctor-work-scheduling-main-online" //跳转
        );
      }
      if (type_id == 2) {
        this.$router.push(
          "/ws-doctor-work-scheduling-main-phone" //跳转
        );
      }
      if (type_id == 4) {
        this.$router.push(
          "/ws-doctor-work-scheduling-main-recheck" //跳转
        );
      }
    },
    async isSure(type_id) {
      if (
        !this.form.hospital_id ||
        !this.form.office_id ||
        !this.form.doctor_id ||
        !this.form.form
      ) {
        this.$message({
          message: "有必填项未填写",
          type: "warning",
        });
        return;
      } else {
        this.doctor_data.name = "医生";
        this.doctor_data.start_time = this.nextWeek[0];
        this.doctor_data.pb_type = type_id + "";
        this.doctor_data.created_by_id = JSON.parse(
          window.sessionStorage.getItem("user")
        ).id;
        this.doctor_data.updated_by_id = JSON.parse(
          window.sessionStorage.getItem("user")
        ).id;
        this.doctor_data.ws_doctor_schedule_days = { data: [] };
        this.doctor_data.ws_doctor_schedule_days.data =
          this.data_list.ws_doctor_schedule_days.data;
        this.doctor_data.ws_doctor_schedule_days.data.forEach((item) => {
          item.doctor_id = this.doctor_data.doctor_id;
          // item.hospital_id = this.doctor_data.hospital_id;
          // item.office_id = this.doctor_data.office_id;
          delete item.work_scheduling_main;
          delete item.id;
          item.ws_doctor_work_shifts.data.forEach((res) => {
            res.left_num = res.num_count;
            res.doctor_id = item.doctor_id;
            res.hospital_id = this.doctor_data.hospital_id;
            res.office_id = this.doctor_data.office_id;
            res.work_date = item.work_date;
            delete res.week_day;
            delete res.ws_type;
            delete res.id;
          });
        });
        this.$store
          .dispatch("ws_doctor_work_scheduling_main/create", {
            data: JSON.parse(JSON.stringify(this.doctor_data)),
          })
          .then((res) => {
            this.notSure(res.data.pb_type);
          });
      }
    },
  },
  watch: {
    "form.form": {
      deep: true,
      immediate: true,
      handler(val) {
        this.doctor_data = this.form;
        this.nextWeek = this.getNextWeek(1);
        if (val) {
          this.$admin.http
            .get("work-scheduling/worksche_doctor/" + val + "/")
            .then((res) => {
              if (res.data) {
                this.data_list = res.data;
                this.data_list.ws_doctor_schedule_days.data.forEach(
                  (item, index) => {
                    item.work_date = this.nextWeek[index];
                  }
                );
              }
            });
        }
      },
    },
    "form.hospital_id": {
      deep: true,
      immediate: true,
      handler(val) {
        if(val) {
          this.dept_filter = {office_id: val, is_active: true }
        }
      }
    },
    "form.office_id": {
      deep: true,
      immediate: true,
      handler(val) {
        if(val) {
          this.dept_filters = {hospital_id: val, is_active: true }
        }
      }
    },
  },
};
</script>
<style scoped>
.first_header {
  display: flex;
  width: 100%;
  margin: 0 auto;
  border: 0.5px solid #f8f8f8;
}

.first_header_cell {
  padding: 5px 10px;
  width: 13%;
  height: 40px;
  line-height: 30px;
  text-align: center;
  border: 0.5px solid #f8f8f8;
}

.sec_header_cell {
  display: flex;
  width: 13%;
  height: 40px;
  text-align: center;
}

.sec_header_title {
  width: 33.33%;
  line-height: 38px;
  border: 1px solid #f8f8f8;
}

.sec_header {
  margin-top: -2px;
}

.el-input--suffix .el-input__inner {
  padding-right: 0 !important;
}

.el-icon-circle-close,
.el-input__prefix {
  display: none !important;
}

.btn_bg {
  width: 87%;
  position: fixed;
  bottom: 0px;
  padding: 10px 0;
  text-align: center;
  /* background-color: ; */
  z-index: 99;
}
.el-input--suffix .el-input__inner {
  padding-left: 12% !important;
}
.el-date-editor /deep/ .el-input__prefix {
  display: none !important;
}
.el-input--prefix /deep/ .el-input__inner {
  text-align: center;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
</style>

