<template>
    <os-work-scheduling-main-list :resource="resource" :type_fee="'在线问诊'"></os-work-scheduling-main-list>
</template>

<script>

export default {
  props: ["resource"],
  data(){
    return{
      title: "在线问诊排班",
    }
  },
  moment() {
  }
};
</script>