<template>
  <div>
    <v-card style="padding: 2vw 0.5vw">
      <v-card-text style="width: 97%; margin: 0 auto">
        <v-row>
          <ul>
            <li>患者信息</li>
            <li style="list-style: none; font-size: 10px; color: #999">
              PATIENT INFORMATION
            </li>
          </ul>
        </v-row>
        <v-row style="margin: 15px 0">
          <v-col>
            <span>处方编码：{{ code_pre }}</span>
          </v-col>
          <v-col>
            <span>健康卡ID：{{ params.health_card_id }}</span>
          </v-col>
          <v-col></v-col>
        </v-row>
        <v-row style="margin: 15px 0">
          <v-col>
            <span>患者姓名：{{ params.pat_name }}</span>
          </v-col>
          <v-col>
            <span>患者年龄：{{ params.pat_age }}</span>
          </v-col>
          <v-col>
            <span v-if="params.pat_gender == 1">患者性别：男</span>
            <span v-else>患者性别：女</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <el-select
              v-model="value"
              filterable
              placeholder="请选择初步诊断"
              remote
              :remote-method="remoteMethod"
              clearable
              v-scroll-loadMore="loadmore"
              infinite-scroll-distance="1"
              infinite-scroll-immediate="true"
              infinite-scroll-disabled="false"
            >
              <el-option
                v-for="item in options"
                :key="item.code"
                :label="item.name"
                :value="item.name"
                style="height: 35px; overflow: auto"
              >
              </el-option>
            </el-select>
          </v-col>
          <v-col>
            <el-input
              type="textarea"
              placeholder="请输入处理意见"
              v-model="handle_opinion"
            ></el-input>
          </v-col>
          <v-col></v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card style="padding: 2vw 0.5vw; margin: 20px 0">
      <el-button
        style="float: right; margin: 0 0 10px"
        type="primary"
        @click="addRemind"
        icon="el-icon-plus"
        size="small"
        >添加行</el-button
      >
      <div class="tableList">
        <el-table :data="tableData" border style="width: 100%" size="small">
          <el-table-column
            type="index"
            label="序号"
            width="50"
            align="center"
            fixed="left"
          ></el-table-column>
          <el-table-column
            align="center"
            v-for="(text, i) in title"
            :key="i"
            :label="text.name"
            width="150px"
          >
            <template slot-scope="scope">
              <template v-if="text.field === 'drug_name'">
                <el-input
                  clearable
                  @input="change_data(scope)"
                  v-model="scope.row[text.field]"
                  :placeholder="'请输入' + text.name"
                  width="150px"
                ></el-input>
              </template>
              <template
                v-else-if="
                  text.field === 'amount_use' ||
                  text.field === 'frequency_use' ||
                  text.field === 'administration_mode' ||
                  text.field === 'drug_days' ||
                  text.field === 'pre_num' ||
                  text.field === 'dr_advice'
                "
              >
                <el-input
                  clearable
                  v-model="scope.row[text.field]"
                  :placeholder="'请输入' + text.name"
                ></el-input>
              </template>
              <template v-else-if="text.field === 'is_essential'">
                <div v-if="scope.row[text.field] == null"></div>
                <div v-else>
                  {{ scope.row[text.field] == true ? "是" : "否" }}
                </div>
              </template>
              <template v-else>
                <div>{{ scope.row[text.field] }}</div>
              </template>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
            width="70px"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                @click="removeRemind(scope.row)"
                width="70px"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </v-card>

    <el-table
      :data="drugsData"
      height="300"
      style="width: 100%; margin-bottom: 80px"
      @current-change="handleCurrentChange"
    >
      <el-table-column type="index" width="50"> </el-table-column>
      <el-table-column label="药品编码" prop="drug_code"> </el-table-column>
      <el-table-column label="药品名称" prop="drug_name"> </el-table-column>
      <el-table-column label="规格" prop="standards"> </el-table-column>
      <el-table-column label="药品剂型" prop="preparation_type">
      </el-table-column>
      <el-table-column label="药品类型" prop="drug_type"> </el-table-column>
      <el-table-column label="单位剂量" prop="unit_dose"> </el-table-column>
      <el-table-column label="计量单位" prop="measure_unit"> </el-table-column>
      <el-table-column label="单位" prop="units"> </el-table-column>
      <el-table-column label="库存数量" prop="stock_left"> </el-table-column>
      <el-table-column label="库存单位" prop="stock_unit"> </el-table-column>
      <el-table-column label="医保类别" prop="mic"> </el-table-column>
      <el-table-column label="农合类别" prop="rcc_category"> </el-table-column>
      <el-table-column label="生产厂家" prop="manufacturer"> </el-table-column>
      <el-table-column label="基药类型" prop="is_essential">
        <template slot-scope="scope">{{
          !!scope.row.is_essential ? "是" : "否"
        }}</template>
      </el-table-column>
      <el-table-column label="高危等级" prop="hr_level"> </el-table-column>
      <el-table-column label="国家贯标编码" prop="gb_code"> </el-table-column>
      <el-table-column label="国家贯标名称" prop="gb_name"> </el-table-column>
    </el-table>
    <div style="float: right; padding-bottom: 80px">
      <v-pagination
        v-model="page"
        :length="total"
        :total-visible="7"
      ></v-pagination>
    </div>
    <div class="text-center">
      <v-btn @click="close" style="color: #42b6c4; margin-right: 50px"
        >取消</v-btn
      >
      <v-btn
        @click="isSure"
        style="background-color: #42b6c4; margin-right: 50px"
        >提交审核</v-btn
      >
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Utils from "../global/utils";
export default {
  data() {
    return {
      total: null,
      page: 1,
      form: {},
      params: null,
      tableData: [],
      title: null,
      drugsData: [],
      code_pre: null,
      options: [],
      value: "",
      handle_opinion: null,
      table_index: null,
      doctor: null,
      zd_limit: 10,
    };
  },
  created() {
    this.query_info();
    if (sessionStorage.getItem(`tableData${this.$route.query.id}`)) {
      this.tableData = JSON.parse(
        sessionStorage.getItem(`tableData${this.$route.query.id}`)
      );
    }
    this.getDoctor();
    this.getCode();
    this.getDiagnosis();
    this.getTable();
  },
  // 页面销毁之前
  // beforeDestroy() {
  //   sessionStorage.removeItem("paramsData");
  // },
  methods: {
    loadmore() {
      this.zd_limit = this.zd_limit += 10;
      this.getDiagnosis();
    },
    // 取消
    close() {
      this.$router.push("/return-visit/");
    },
    // 提交审核
    isSure() {
      if (this.value == "") {
        this.$message({
          message: "有必填项未填写",
          type: "warning",
        });
        return;
      }
      if (this.tableData.length == 0) {
        this.$message({
          message: "有必填项未填写",
          type: "warning",
        });
        return;
      } else {
        let res_data;
        this.tableData.forEach((item) => {
          res_data =
            !item.amount_use ||
            !item.frequency_use ||
            !item.administration_mode ||
            !item.pre_num ||
            !item.dr_advice ||
            !item.drug_days ||
            !item.price;
        });
        if (res_data) {
          this.$message({
            message: "有必填项未填写",
            type: "warning",
          });
          return;
        }
      }
      this.$admin.http
        .get(`/online-recheck/diarcs/?name=${this.value}`)
        .then((res) => {
          let user = JSON.parse(window.sessionStorage.getItem("user"));
          let prescription = {
            code_pre: this.code_pre,
            code_healthcard: this.params.health_card_id,
            code_pat: this.params.health_card_id,
            diagnosis_code: res.data.results[0].code,
            diagnosis_name: res.data.results[0].name,
            handle_opinion: this.handle_opinion,
            issue_date: moment().format("YYYY-MM-DD"),
            doctor_code: this.doctor.job_number,
            doctor_name: this.doctor.name,
            office_code: this.doctor.office_code,
            office_name: this.doctor.office_name,
            hospital_code: this.doctor.hospital_code,
            hospital_name: this.doctor.hospital_name,
            // code_ent: this.params.pat_codenum,
            code_ent: this.$route.query.code_ent,
            created_by: user.name,
            updated_by: user.name,
            status: "0",
            drug_pre: this.tableData,
          };
          this.$admin.http
            .post("/online-recheck/prescriptions/", prescription)
            .then((data) => {
              Utils.$emit('demo',{type: '处方',content: data.data})
              this.$message({
                message: "提交成功",
                type: "success",
              });
              this.close();
            });
        });
    },
    // 查询患者
    query_info() {
      this.doctor_id = JSON.parse(window.sessionStorage.getItem("user")).doctor;
      this.$admin.http
        .get(
          `patient-account/online_therapy/?appoint_type=4&status=3&doctor_id=${this.doctor_id}`
        )
        .then((res) => {
          res.data.results.forEach((item) => {
            if (item.id == this.$route.query.id) {
              this.params = item;
            }
          });
        });
    },
    // 获取医生
    getDoctor() {
      this.user = JSON.parse(window.sessionStorage.getItem("user"));
      this.$admin.http
        .get(`/base-system/doctors/${this.user.doctor}/`)
        .then((doctor) => {
          this.doctor = doctor.data;
          this.form.hospital_code = this.doctor.hospital_code;
        });
    },
    // 搜索药品
    change_data(scope) {
      this.table_index = scope.$index;
      if (scope.row.name == "") {
        this.form.drug_name = "";
        this.adjustment();
      } else {
        this.form.drug_name = scope.row.drug_name;
        this.adjustment();
      }
    },
    // 处方编码
    getCode() {
      this.code_pre = new Date().getTime();
      // for (
      //   var i = 0;
      //   i < 4;
      //   i++ //4位随机数，用以加在时间戳后面。
      // ) {
      //   this.code_pre += Math.floor(Math.random() * 10);
      // }
      // this.code_pre = new Date().getTime() + this.code_pre; //时间戳，用来生成用户编号。
    },
    // 获取诊断
    getDiagnosis() {
      this.$admin.http
        .get(`/online-recheck/diarcs/?page=1&page_size=${this.zd_limit}`)
        .then((res) => {
          this.options = res.data.results;
        });
    },
    remoteMethod(query) {
      if (query !== "") {
        this.$admin.http
          .get(`/online-recheck/diarcs/?name=${query}`)
          .then((res) => {
            this.options = res.data.results;
          });
      } else {
        this.options = [];
      }
    },
    // 获取药品
    // getDrugs(item) {
    //   if (item) {
    //     this.$admin.http
    //       .get(
    //         `/base-system/drug_directories/?drug_name=${item}&hospital_code=${this.doctor.hospital_code}`
    //       )
    //       .then((res) => {
    //         if (res.data.code == 2001) {
    //           this.$message({
    //             message: res.data.message,
    //             type: "warning",
    //           });
    //           return;
    //         } else {
    //           this.drugsData = res.data.results;
    //         }
    //       });
    //   } else {
    //     this.drugsData = null;
    //   }
    // },
    adjustment() {
      let obj_key = Object.keys(this.form);
      let src_key = "";
      obj_key.forEach((key) => {
        src_key = src_key + "&" + key + "=" + `${this.form[key]}`;
      });
      src_key = src_key.slice(1);
      if (this.form.drug_name) {
        this.$admin.http
          .get(`/base-system/drug_directories/?${src_key}`)
          .then((res) => {
            this.total =
              (res.data.count / 10) % 1 === 0
                ? res.data.count / 10
                : parseInt(res.data.count / 10) + 1;
            this.drugsData = res.data.results;
          });
      }
    },
    getTable() {
      this.$admin.http
        .get(`/online-recheck/prescriptions/tabtitle/`)
        .then((res) => {
          this.title = res.data;
        });
    },
    // 选中筛选数据
    handleCurrentChange(val) {
      this.tableData[this.table_index].drug_name = val.drug_name; //  药品编码
      this.tableData[this.table_index].drug_code = val.drug_code; //  药品编码
      this.tableData[this.table_index].price = val.price; //  药品单价
      this.tableData[this.table_index].specification = val.standards; //  规格
      this.tableData[this.table_index].preparation_type = val.preparation_type; //  剂型
      this.tableData[this.table_index].drug_type = val.drug_type; //  药品类型
      this.tableData[this.table_index].unit_dose = val.unit_dose; //  单位剂量
      this.tableData[this.table_index].measure_unit = val.measure_unit; //  计量单位
      // this.tableData[this.table_index].frequency_use = val.standards     //  用药频率
      // this.tableData[this.table_index].administration_mode = val.drug_type        //  给药方式
      // this.tableData[this.table_index].drug_days = val.unit_dose     //  用药天数
      // this.tableData[this.table_index].pre_num = val.standards     //  处方数量
      this.tableData[this.table_index].total_unit = val.units; //  总量单位
      // this.tableData[this.table_index].dr_advice = val.drug_type     //  医嘱嘱托
      this.tableData[this.table_index].is_essential = val.is_essential; //  是否是基药
      this.tableData[this.table_index].hr_level = val.hr_level; //  高危等级
      this.tableData[this.table_index].gb_code = val.gb_code; //  国家贯标编码
      this.tableData[this.table_index].gb_name = val.gb_name; //  国家贯标名称
      this.tableData[this.table_index].total_unit_code = val.total_unit_code; //
      this.tableData[this.table_index].measure_unit_code =
        val.measure_unit_code; //
      this.tableData[this.table_index].amount_use = val.amount_use; //  用量
      this.tableData[this.table_index].code_medu_cur = val.code_medu_cur; //  单次用量编码
      this.tableData[this.table_index].code_ent = this.$route.query.code_ent; // 就诊编码
    },
    // 添加行
    addRemind() {
      let table_info = {};
      this.title.forEach((item) => {
        table_info[item.field] = null;
      });
      this.tableData.push(table_info);
    },
    // 删除行
    removeRemind(row) {
      let rowIndex = this.tableData.indexOf(row);
      this.tableData.splice(rowIndex, 1);
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== "prescribing") {
      sessionStorage.setItem(
        `tableData${this.$route.query.id}`,
        JSON.stringify(this.tableData)
      );
    }
    next();
  },
  watch: {
    page: {
      deep: true,
      immediate: true,
      handler(val) {
        this.form.page = val;
        this.form.page_size = 10;
        this.adjustment();
      },
    },
  },
};
</script>

<style scoped>
.text-center {
  width: 87%;
  background-color: #fff;
  height: 80px;
  position: fixed;
  bottom: 0px;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
ul li::marker {
  color: #42b6c4;
}
.row + .row {
  margin-top: 0px;
}
.row > .col {
  padding: 0 12px !important;
}
.el-select {
  display: block;
}
.el-button--primary {
  background-color: #42b6c4;
  border-color: #42b6c4;
}
</style>