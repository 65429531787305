<template>
  <va-form :id="id" :item="item" @saved="$emit('saved')">
    <v-card-text>
            <va-text-input source="code" required></va-text-input>
            <va-text-input source="name" required></va-text-input>
          </v-card-text>
    <div class="btn_bg">
      <va-save-button></va-save-button>
    </div>
  </va-form>
</template>

<script>
export default {
  props: ["id", "title", "item"],
};
</script>
<style scoped>
.btn_bg {
  width: 95%;
  position: fixed;
  bottom: 0px;
  padding: 10px 0;
  text-align: center;
  background-color: white;
}
</style>