<template>
  <va-show :item="item">
    <v-row justify="center">
      <v-col sm="6">
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2">
              {{ title }}
            </div>
          </template>
          <v-card-text>
            <va-field source="id"></va-field>
            <va-field source="user"></va-field>
            <va-field source="username"></va-field>
            <va-field source="change_time" type="longtime"></va-field>
            <va-field source="from_url"></va-field>
            <va-field source="operat_status" type="boolean"></va-field>
            <va-field source="operat_content"></va-field>
            <va-field source="log_type"></va-field>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </va-show>
</template>

<script>
export default {
  props: ["title", "item"],
};
</script>
