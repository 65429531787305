<template>
  <div>
    <va-aside-layout :title="asideTitle">
      <os-scheduling-type-show v-if="show" :id="id" :item="item"></os-scheduling-type-show>
      <os-scheduling-type-form
        v-else
        :id="id"
        width="500px"
        :item="item"
        @saved="onSaved"
        ref="form"
      ></os-scheduling-type-form>
    </va-aside-layout>
    <v-card :icon="resource.icon" :title="title">
      <va-list
        @action="onAction"
        :filters="filters"
        :filter="filter"
        disable-export
        :items-per-page="10"
        :sort-by="['id']"
        :sort-desc="[true]"
        disable-create-redirect
        ref="list"
        global-search-query="hospital_name@_like,office_name@_like,type_code@_like,type_name@_like"
      >
        <va-data-table
          @item-action="onAction"
          :items-per-page="10"
          :fields="fields"
          disable-clone
          disable-show
          disable-edit-redirect
        >
        </va-data-table>
      </va-list>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["resource", "title"],
  data() {
    return {
      asideTitle: null,
      id: null,
      item: null,
      show: false,
      create: false,
      filters: [],
      fields: [
        {
          source: "hospital_name",
          label: "所属医院",
        },
        // {
        //   source: "office_name",
        //   label: "所属科室",
        // },
        {
          source: "type_code",
          label: "类型编码",
        },
        {
          source: "type_name",
          label: "类型名称",
        },
      ],
      filter: {},
    };
  },
  created() {
    this.getSupper();
  },
  methods: {
    getSupper() {
      let user_data = JSON.parse(
        window.sessionStorage.getItem("user")
      );
      if (user_data.is_superuser) {
        this.filters.push({
          source: "hospital_name",
          label: "所属医院",
          type: "text",
          alwaysOn: true,
        });
      }else{
        this.filter = {hospital_name: user_data.hospital_name}
      }
    },
    async onAction({ action, title, id, item }) {
      this.asideTitle = title;
      this.id = id;
      this.show = action === "show";
      this.item = item;
      if (action === "create") {
        this.id = null;
      }
    },
    onSaved() {
      this.asideTitle = null;
      this.$refs.list.fetchData();
    },
  },
};
</script>
