<!--
 * @Description: 
 * @Author: your name
 * @Date: 2023-05-04 14:29:34
 * @LastEditors: your name
 * @LastEditTime: 2023-09-01 15:22:16
-->
<template>
  <div>
    <va-aside-layout ref="aside" :title="asideTitle">
      <hap-bed-apt-record-multiplex-show v-if="!show" :item="item" :id="id"
        @saved="onSaved"></hap-bed-apt-record-multiplex-show>
      <!-- <hap-bed-apt-record-multiplex-form
        v-else
        :id="id"
        width="500px"
        :item="item"
        @saved="onSaved"
        ref="form"
      ></hap-bed-apt-record-multiplex-form> -->
    </va-aside-layout>
    <v-card :icon="resource.icon" :title="title">
      <va-list disable-export disable-create disable-pagination ref="list"
        :include="['bs_hospital', 'hap_bed_apt_record']" :sort-by="['planned_admission_time']"
        resource="hap_bed_apt_record" :sort-desc="[true]"
        global-search-query="hap_bed_apt_record#hospital_name@_like,hap_bed_apt_record#ward_name@_like,hap_bed_apt_record#room_name@_like,hap_bed_apt_record#patient_name@_like,hap_bed_apt_record#bed_num@_like"
        :filter="filter">
        <template v-slot:actions>
          <v-btn-toggle class="custom_btn" v-model="toggle_release" mandatory color="#214578">
            <v-btn> 待审批</v-btn>
            <v-btn> 已同意 </v-btn>
            <v-btn> 已驳回 </v-btn>
          </v-btn-toggle>
        </template>
        <div class="divBox">
          <!-- <el-input
            style="margin: 0 20px 10px; width: 200px"
            v-model="hospital_names"
            @change="changeSearch()"
            placeholder="查询"
          ></el-input> -->
          <el-select v-if="isshow" class="select" style="margin: 0 20px 10px" :clearable="true" v-model="hospital_data"
            @change="changeSearch()" placeholder="请选择医院" filterable>
            <el-option v-for="item in options0" :key="item.id" :label="item.name" :value="item.codenum">
            </el-option>
          </el-select>
          <el-select class="select" style="margin: 0 20px 10px" :clearable="true" v-model="word_data"
            @change="changeSearch()" placeholder="请选择病区" filterable>
            <el-option v-for="item in options1" :key="item.id" :label="item.name" :value="item.ward_code">
            </el-option>
          </el-select>
          <el-select class="select" style="margin: 0 20px 10px" :clearable="true" v-model="room_data"
            @change="changeSearch()" placeholder="请选择病房">
            <el-option v-for="item in options2" :key="item.id" :label="item.name" :value="item.room_num">
            </el-option>
          </el-select>

          <el-date-picker class="select" v-model="date_value" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"
            @change="changeSearch()">
          </el-date-picker>
        </div>

        <!-- <template v-slot:actions>
          <va-action-button
            icon="mdi-briefcase-download-outline"
            label="下载模版"
            :href="template_download_url"
            color="green"
            text
          >
          </va-action-button>
          <va-action-button
            icon="mdi-import"
            color="green"
            label="导入"
            @click="on_show_import"
            text                         
          >
          </va-action-button>
          <va-action-button
            icon="mdi-arrow-collapse-down"
            color="green"
            label="导出"
            text
            @click="on_export_excel"
          >
          </va-action-button>
        </template> -->
        <template v-if="toggle_release == 0">
          <v-card>
            <v-data-table :headers="headers0" :items="table_data">
              <template v-slot:[`item.planned_admission_time`]="{ item }">
                <span>{{ item.planned_admission_time | keepTwoNum }}</span>
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip class="ma-2" color="#069CAE" @click="passList(item)" v-bind="attrs" v-on="on"
                      text-color="white">
                      同意
                    </v-chip>
                  </template>
                  <span>同意</span>
                </v-tooltip>
                <!-- v-if="$admin.can(['change_bedaptrecordmultiplex'])" -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip class="ma-2" color="red" @click="refuseList(item)" v-bind="attrs" v-on="on"
                      text-color="white">
                      驳回
                    </v-chip>
                  </template>
                  <span>驳回</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card>
        </template>
        <template v-if="toggle_release == 1">
          <v-card>
            <v-data-table :headers="headers1" :items="table_data">
              <template v-slot:[`item.planned_admission_time`]="{ item }">
                <span>{{ item.planned_admission_time | keepTwoNum }}</span>
              </template>
              <template v-slot:[`item.approved_time`]="{ item }">
                <span>{{ item.approved_time | keepTwoNum }}</span>
              </template>
            </v-data-table>
          </v-card>
        </template>
        <template v-if="toggle_release == 2">
          <v-card>
            <v-data-table :headers="headers2" :items="table_data">
              <template v-slot:[`item.planned_admission_time`]="{ item }">
                <span>{{ item.planned_admission_time | keepTwoNum }}</span>
              </template>
              <template v-slot:[`item.approved_time`]="{ item }">
                <span>{{ item.approved_time | keepTwoNum }}</span>
              </template>
            </v-data-table>
          </v-card>
        </template>
      </va-list>
    </v-card>
  </div>
</template>

<script>
import moment from "moment";
import Utils from "../../global/utils";

export default {
  props: ["resource", "title"],
  data() {
    return {
      // 模板下载地址
      // template_download_url: `${this.global.upload_url}excel/office_import.xlsx`,
      // 弹出侧边栏
      asideTitle: null,
      toggle_release: 0,
      id: null,
      item: null,
      table_data: [],
      table_datas: [],
      data: null,
      show: false,
      date_value: "",
      hospital_data: null,
      // hospital_names: "",
      word_data: null,
      room_data: null,
      options0: [],
      options1: [],
      options2: [],
      approval: "",
      headers0: [
        {
          text: "患者姓名",
          value: "patient_name",
          sortable: false,
          align: "center",
        },
        { text: "身份证号", value: "id_num", sortable: false, align: "center" },
        {
          text: "所属医院",
          value: "hospital_name",
          sortable: false,
          align: "center",
        },
        {
          text: "所属病区",
          value: "ward_name",
          sortable: false,
          align: "center",
        },
        {
          text: "所属病房",
          value: "room_name",
          sortable: false,
          align: "center",
        },
        { text: "床位号", value: "bed_num", sortable: false, align: "center" },
        // {
        //   text: "计划入住时间",
        //   value: "planned_admission_time",
        //   type: "date",
        //   align: "center",
        //   attributes: { format: "short" },
        // },
        {
          text: "计划入住时间",
          value: "planned_admission_time",
          sortable: false,
          align: "center",
        },

        {
          text: "计划入住天数",
          value: "planned_length_stay",
          sortable: false,
          align: "center",
        },
        { text: "操作", value: "actions", align: "center" },
      ],
      headers1: [
        {
          text: "患者姓名",
          value: "patient_name",
          sortable: false,
          align: "center",
        },
        { text: "身份证号", value: "id_num", sortable: false, align: "center" },
        {
          text: "所属医院",
          value: "hospital_name",
          sortable: false,
          align: "center",
        },
        {
          text: "所属病区",
          value: "ward_name",
          sortable: false,
          align: "center",
        },
        {
          text: "所属病房",
          value: "room_name",
          sortable: false,
          align: "center",
        },
        { text: "床位号", value: "bed_num", sortable: false, align: "center" },
        {
          text: "计划入住时间",
          value: "planned_admission_time",
          sortable: false,
          align: "center",
        },
        {
          text: "计划入住天数",
          value: "planned_length_stay",
          sortable: false,
          align: "center",
        },
        { text: "审批人", value: "approved_by", sortable: false },
        { text: "审批时间", value: "approved_time", sortable: false },
      ],
      headers2: [
        {
          text: "患者姓名",
          value: "patient_name",
          sortable: false,
          align: "center",
        },
        { text: "身份证号", value: "id_num", sortable: false, align: "center" },
        {
          text: "所属医院",
          value: "hospital_name",
          sortable: false,
          align: "center",
        },
        {
          text: "所属病区",
          value: "ward_name",
          sortable: false,
          align: "center",
        },
        {
          text: "所属病房",
          value: "room_name",
          sortable: false,
          align: "center",
        },
        { text: "床位号", value: "bed_num", sortable: false, align: "center" },
        {
          text: "计划入住时间",
          value: "planned_admission_time",
          sortable: false,
          align: "center",
        },
        {
          text: "计划入住天数",
          value: "planned_length_stay",
          sortable: false,
          align: "center",
        },
        { text: "驳回原因", value: "note", sortable: false },
        { text: "审批人", value: "approved_by", sortable: false },
        { text: "审批时间", value: "approved_time", sortable: false },
      ],
      filter: {},
      user_data: null,
      isshow: true,
    };
  },
  async mounted() {
    this.data = await this.$store.dispatch("hap_bed_apt_record/getList");
  },
  created() {
    this.getSupper();
    this.getSelect();
    this.changeSearch();

    this.approval = JSON.parse(window.sessionStorage.getItem("user")).name;
  },
  watch: {
    hospital_data: {
      deep: true,
      handler: function (newValue, oldValue) {
        if (newValue != oldValue) {
          this.$store
            .dispatch("hap_inpatient_ward/getList", {
              filter: {
                hospital_code: newValue,
              },
            })
            .then((res) => {
              this.options1 = res.data;
            });
        }
      },
    },
    word_data: {
      deep: true,
      handler: function (newValue, oldValue) {
        if (newValue != oldValue) {
          this.$store
            .dispatch("hap_inpatient_room/getList", {
              filter: {
                ward_code: newValue,
              },
            })
            .then((res) => {
              this.options2 = res.data;
            });
        }
      },
    },
    date_value: {
      deep: true,
      handler: function (newVal) {
        if (newVal) {
          this.table_datas = [];
          let value1 = moment(newVal[0]).format("YYYY-MM-DD");
          let value2 = moment(newVal[1]).format("YYYY-MM-DD");
          this.data.data.forEach((item) => {
            if (
              moment(item.planned_admission_time).format("YYYY-MM-DD") >=
              value1 &&
              moment(item.planned_admission_time).format("YYYY-MM-DD") <= value2
            ) {
              this.table_datas.push(item.id);
            } else {
              this.table_datas = [];
              return;
            }
            this.filter = {
              id: this.table_datas,
              hospital_name: this.user_data.hospital_name,
            };
          });
          // this.table_data = this.table_data.filter((items) =>
          //   this.table_datas.forEach((inx, item) => {
          //     items.id = item;
          //   })
          // );
        } else {
          this.table_datas = [];
        }
      },
    },
    toggle_release: {
      deep: true,
      handler: function (newVal) {
        this.changeSearch(newVal);
        this.show = true;
        // setTimeout(() => {
        //   Utils.$emit("open", true);
        // }, 200);
        // this.id = null;
        // console.log(this.$refs.aside, "123");
        // this.$refs.aside.handleUpdate(false);
      },
    },
  },
  filters: {
    keepTwoNum: function (value) {
      return moment(value).format("YYYY-MM-DD");
    },
  },
  methods: {
    getSupper() {
      // 医院名称的过滤
      this.user_data = JSON.parse(window.sessionStorage.getItem("user"));
      this.hospital_data = this.user_data.hospital_code;
      if (this.user_data.is_superuser) {
        this.isshow = true;
      } else {
        this.isshow = false;
      }
    },
    getSelect() {
      this.$store.dispatch("bs_hospital/getList").then((res) => {
        this.options0 = res.data;
      });

      // this.$store.dispatch("hap_inpatient_room/getList").then((res) => {
      //   this.options2 = res.data;
      // });
    },
    changeSearch(item) {
      // this.hospital_data = this.user_data.hospital_code
      let objF = {};
      // objF.id = this.table_datas;
      if (item) {
        // delete objF.id
        objF.state = item;
      } else {
        objF.state = this.toggle_release;
      }
      if (this.hospital_data) {
        objF.hospital_code = this.hospital_data;
      }
      if (this.word_data) {
        objF.ward_code = this.word_data;
      }
      if (this.room_data) {
        objF.room_num = this.room_data;
      }
      if (this.toggle_release == 0) {
        objF.apt_state = 1;
      }
      if (this.date_value) {
        objF.planned_admission_time = this.date_value;
      }
      console.log(objF, "objF");
      this.$store
        .dispatch("hap_bed_apt_record/getList", {
          filter: objF,
        })
        .then((res) => {
          console.log(res.data, "res.data");
          this.table_data = res.data;
        });
    },
    refuseList(item) {
      this.id = null
      this.asideTitle = "驳回原因";
      this.id = item.id;
      this.show = false;
      this.item = item;
    },

    async onAction({ action, title, id, item }) {
      this.asideTitle = title;
      this.id = id;
      this.show = action === "show";
      this.item = item;
      if (action === "create") {
        this.id = null;
      }
      this.$admin.http
        .put(`base-system/users/${this.id}/`, this.form)
        .then((res) => {
          this.close();
          if (this.imageInfo[0]) {
            this.reviseImg(
              this.imageInfo[0].id,
              this.imgfile,
              this.id,
              res.data.avatar_url
            );
          } else {
            this.httpRequest(this.imgfile, this.id, res.data.avatar_url);
          }
        });
    },
    passList(item) {
      item.state = "1";
      item.approved_by = this.approval;
      item.approved_time = moment().format("YYYY-MM-DD HH:mm:ss");
      this.$admin.http
        .post(
          `hospitalization_apt/bed_appointment_record/${item.id}/bed_reservation_approval/`,
          item
        )
        .then((res) => {
          console.log(res, "resresres");
          this.onSaved();
        });
    },

    onSaved() {
      this.asideTitle = null;
      this.$refs.list.fetchData();
      this.changeSearch();
    },
    deleteItem(item) {
      this.$confirm("此操作将取消预约，是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$store
            .dispatch("hap_bed_apt_record/delete", {
              id: item.id,
            })
            .then((res) => {
              this.onSaved();
              // this.getSelect();
            });
        })
        .catch(() => { });
    },
    // 导入
    on_show_import() { },
    // 导出
    on_export_exce() { },
  },
};
</script>
<style lang="sass" scoped>
.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined)
  background-color: #eee
  color: #586AFC
  &:hover
    background-color: #ccc
    color: #000

.navInput
  width: 12rem
  height: 2.4rem
  border-bottom: 1px solid rgba(0, 0, 0, 0.15)
  border-radius: 3px
  cursor: pointer
  background: #fff
  padding-top: 0.6rem
  color: #444
  font-size: 14px
  font-family: SourceHanSansCN-Normal, SourceHanSansCN
  font-weight: 400

::v-deep .el-input__inner
  border-top: none !important
  border-left: none !important
  border-right: none !important
  border-bottom: 1px #D2D2D2 solid !important
  border-radius: 0
  padding-bottom: 10px
</style>
<style scoped>
::v-deep .el-input__inner {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1px #d2d2d2 solid !important;
  border-radius: 0;
  padding-bottom: 10px;
}

.select .el-icon-circle-close,
.el-input__prefix {
  display: block !important;
}

.custom_btn.v-item-group {
  left: 0;
  position: absolute;
}

.divBox {
  display: flex;
  align-items: flex-start;
}
</style>
