<template>
  <div>
    <va-aside-layout :title="asideTitle">
      <ws-doctor-work-scheduling-main-show
        v-if="show"
        :item="item"
      ></ws-doctor-work-scheduling-main-show>
      <ws-doctor-work-scheduling-main-form
        v-else
        :id="id"
        :item="item"
        @saved="onSaved"
        :type_id="type_id"
      ></ws-doctor-work-scheduling-main-form>
    </va-aside-layout>
    <v-card :icon="resource.icon" :title="title">
      <va-list
        resource="ws_doctor_work_scheduling_main"
        ref="list"
        disable-create
        disable-global-search
        @action="onAction"
        disable-export
        disable-pagination
        :sort-desc="[true]"
      >
        <template v-slot:actions>
          <va-action-button
            icon="mdi-briefcase-download-outline"
            label="下载模版"
            :href="template_download_url"
            color="green"
            text
          >
          </va-action-button>
          <va-action-button
            icon="mdi-import"
            color="green"
            label="导入"
            @click="on_show_import"
            text
          >
          </va-action-button>
          <!-- <va-action-button
            icon="mdi-arrow-collapse-down"
            color="green"
            label="导出"
            text
            @click="on_export_excel"
          >
          </va-action-button> -->
          <va-action-button
            icon="mdi-plus"
            color="success"
            @click="createdrawer"
            style="color: #4caf50"
            text
            label="新建"
          ></va-action-button>
        </template>
        <div
          style="
            width: 98%;
            margin: 0 auto 20px;
            display: flex;
            justify-content: space-between;
          "
        >
          <el-radio-group v-model="week" fill="#42B6C4">
            <el-radio-button label="上周"></el-radio-button>
            <el-radio-button label="本周"></el-radio-button>
            <el-radio-button label="下周"></el-radio-button>
          </el-radio-group>
          <el-select
            class="select"
            clearable
            filterable
            v-model="hospital"
            placeholder="请选择医院"
            style="width: 15%"
          >
            <el-option
              v-for="item in options1"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-select
            class="select"
            clearable
            v-model="office"
            filterable
            placeholder="请选择科室"
            style="width: 15%"
          >
            <el-option
              v-for="item in options2"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-select
            class="select"
            clearable
            v-model="doctor"
            filterable
            placeholder="请选择医生"
            style="width: 15%"
          >
            <el-option
              v-for="item in options3"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <el-table
          :data="tableData"
          style="width: 98%; margin: 0 auto"
          v-if="tableData.length > 0"
        >
          <el-table-column type="index" label="序号"> </el-table-column>
          <el-table-column prop="hospital_name" label="医院"> </el-table-column>
          <el-table-column prop="office_name" label="科室"> </el-table-column>
          <el-table-column prop="doctor_name" label="姓名"> </el-table-column>
          <el-table-column
            :label="table_label[index]"
            v-for="(item, index) in tableData[0].ws_doctor_schedule_days.data"
            :key="index"
          >
            {{ item }}
            <el-table-column label="上午" width="50">
              <template slot-scope="scope">
                <span>{{
                  scope.row.ws_doctor_schedule_days.data[index]
                    .ws_doctor_work_shifts.data[0].num_count
                }}</span>
              </template>
            </el-table-column>
            <el-table-column label="下午" width="50">
              <template slot-scope="scope">
                <span>{{
                  scope.row.ws_doctor_schedule_days.data[index]
                    .ws_doctor_work_shifts.data[1].num_count
                }}</span>
              </template>
            </el-table-column>
            <el-table-column label="晚上" width="50">
              <template slot-scope="scope">
                <span>{{
                  scope.row.ws_doctor_schedule_days.data[index]
                    .ws_doctor_work_shifts.data[2].num_count
                }}</span>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="120">
            <template slot-scope="scope">
              <el-button
                @click="editRow(scope.$index, scope.row)"
                type="text"
                size="small"
              >
                编辑
              </el-button>
              <el-button
                @click.native.prevent="
                  deleteRow(scope.$index, scope.row, tableData)
                "
                type="text"
                size="small"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-table :data="tableData" style="width: 98%; margin: 0 auto" v-else>
          <el-table-column type="index" label="序号"> </el-table-column>
          <el-table-column prop="hospital_name" label="医院"> </el-table-column>
          <el-table-column prop="office_name" label="科室"> </el-table-column>
          <el-table-column prop="doctor_name" label="姓名"> </el-table-column>
          <el-table-column
            :label="table_label[index]"
            v-for="(item, index) in 7"
            :key="index"
          >
            <el-table-column label="上午" width="50"> </el-table-column>
            <el-table-column label="下午" width="50"> </el-table-column>
            <el-table-column label="晚上" width="50"> </el-table-column>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="120">
            <template slot-scope="scope">
              <el-button
                @click="editRow(scope.$index, scope.row)"
                type="text"
                size="small"
              >
                编辑
              </el-button>
              <el-button
                @click.native.prevent="
                  deleteRow(scope.$index, scope.row, tableData)
                "
                type="text"
                size="small"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div style="float: right; padding-bottom: 80px">
              <v-pagination
                v-model="page"
                :length="total"
                :total-visible="7"
              ></v-pagination>
            </div>
      </va-list>
      <!-- 导入框 -->
      <v-dialog v-model="dialog" width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">批量导入</span>
          </v-card-title>
          <v-card-text>
            <el-form label-width="80px">
              <el-form-item label="上传文件">
                <el-upload
                  class="upload-demo"
                  action
                  :auto-upload="false"
                  :on-change="handleChange"
                  :file-list="fileList"
                  multiple
                >
                  <el-button
                    size="small"
                    type="primary"
                    style="
                      color: white;
                      background-color: #14b2c5;
                      border: #14b2c5 1px solid;
                    "
                    >点击上传</el-button
                  >
                </el-upload>
              </el-form-item>
            </el-form>
          </v-card-text>
          <v-card-actions>
            <!-- <v-spacer></v-spacer> -->
            <v-btn color="primary" text @click="dialog = false"> 取消 </v-btn>
            <v-btn color="primary" @click="isSure"> 确认 </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import moment from "moment";
import { exportResource } from "@/util/excel_export";
const static_server_url =
  process.env.VUE_APP_API_URL || "http://localhost:28000/static";
export default {
  props: ["resource", "title", "type_fee", "form_title"],
  data() {
    return {
      total: null,
      page: 1,
      form: {},
      template_download_url: `${this.global.upload_url}excel/hospital_scheduling.xlsx`,
      fileList: [],
      dialog: false,
      filters: [],
      fields: ["hospital_id", "office_id", "name"],
      tableData: [],
      table_label: [],
      asideTitle: null,
      id: null,
      item: null,
      show: false,
      type_id: null,
      week: "本周",
      options1: [],
      hospital: undefined,
      options2: [],
      office: undefined,
      options3: [],
      doctor: undefined,
      week_date: null,
    };
  },
  mounted() {
    if (this.$route.query.doctor_id) {
      this.doctor = Number(this.$route.query.doctor_id);
    }
    this.getOptions();
    if (this.type_fee) {
      this.type_id = this.type_fee;
    } else {
      this.type_id = 1;
    }
    let week = ["周一", "周二", "周三", "周四", "周五", "周六", "周日"];
    this.week_date = this.getThisWeek(1);
    this.table_label = [];
    for (let i = 0; i < week.length; i++) {
      this.table_label.push(this.week_date[i] + week[i]);
    }
    this.showTable();
  },
  methods: {
    //导出
    on_export_excel() {
      let params = this.$router.currentRoute.query;
      exportResource(
        this.$admin.http,
        "/base-system/export_hospital",
        params,
        "排班信息.xlsx"
      );
    },
    //导入
    async isSure() {
      let excel_file = this.fileList[0].raw;
      let formData = new FormData();
      formData.append("excel_file", excel_file);
      formData.append(
        "created_user",
        JSON.parse(window.sessionStorage.getItem("user")).id
      );
      formData.append("pb_type", this.type_id);
      await this.$admin.http
        .post("/work-scheduling/import_dws/", formData)
        .then(() => {
          this.$message({
            message: "导入成功！",
            type: "success",
          });
        })
        .catch((error) => {
          this.$message({
            message: "数据有误，请检查重试！",
            type: "warning",
          });
        });
      this.dialog = false;
    },
    handleChange(file, fileList) {
      this.fileList = fileList;
    },
    on_show_import() {
      this.dialog = true;
    },
    getOptions() {
      this.$store.dispatch("bs_hospital/getList").then((res) => {
        this.options1 = res.data;
      });
      this.$store.dispatch("bs_office/getList").then((res) => {
        this.options2 = res.data;
      });
      this.$store.dispatch("bs_doctor/getList").then((res) => {
        this.options3 = res.data;
      });
    },
    deleteRow(index, rows, data) {
      this.$confirm("此操作将删除该排版信息，是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$admin.http;
          this.$admin.http
            .delete(`/work-scheduling/doctor_worksche/${rows.id}/`)
            .then((res) => {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              data.splice(index, 1);
            });
        })
        .catch(() => {});
    },
    editRow(index, rows) {
      this.$router.push({
        path: `/ws-doctor-work-scheduling-main/${rows.id}/edit`,
        query: { type_id: this.type_fee },
      });
    },
    createdrawer() {
      this.$router.push({
        path: "/ws-doctor-work-scheduling-main/create",
        query: { type_id: this.type_fee },
      });
    },
    getDay(num) {
      return "周" + ["", "一", "二", "三", "四", "五", "六", "日"][num];
    },
    onAction({ action, title, id, item }) {
      if (this.form_title) {
        this.asideTitle = this.form_title;
      } else {
        this.asideTitle = title;
      }
      this.id = id;
      this.show = action === "show";
      this.item = item;
      if (action === "create") {
        this.id = null;
      }
    },
    onSaved() {
      this.asideTitle = null;
      this.$refs.list.fetchData();
    },
    getLastWeek(i) {
      let weekOfDay = parseInt(moment().format("E")); //计算今天是这周第几天
      let last_monday = moment()
        .subtract(weekOfDay + 7 * i - 1, "days")
        .format("YYYY-MM-DD"); //周一日期
      let last_Tuesday = moment()
        .subtract(weekOfDay + 7 * i - 2, "days")
        .format("YYYY-MM-DD"); //周一日期
      let last_Wednesday = moment()
        .subtract(weekOfDay + 7 * i - 3, "days")
        .format("YYYY-MM-DD"); //周一日期
      let last_Thursday = moment()
        .subtract(weekOfDay + 7 * i - 4, "days")
        .format("YYYY-MM-DD"); //周一日期
      let last_Friday = moment()
        .subtract(weekOfDay + 7 * i - 5, "days")
        .format("YYYY-MM-DD"); //周一日期
      let last_Saturday = moment()
        .subtract(weekOfDay + 7 * i - 6, "days")
        .format("YYYY-MM-DD"); //周一日期
      let last_sunday = moment()
        .subtract(weekOfDay + 7 * (i - 1), "days")
        .format("YYYY-MM-DD"); //周日日期
      return [
        last_monday,
        last_Tuesday,
        last_Wednesday,
        last_Thursday,
        last_Friday,
        last_Saturday,
        last_sunday,
      ];
    },
    getThisWeek(i) {
      let this_monday = moment().weekday(1).format("YYYY-MM-DD"); //本周一
      let this_Tuesday = moment().weekday(2).format("YYYY-MM-DD"); //本周一
      let this_Wednesday = moment().weekday(3).format("YYYY-MM-DD"); //本周一
      let this_Thursday = moment().weekday(4).format("YYYY-MM-DD"); //本周一
      let this_Friday = moment().weekday(5).format("YYYY-MM-DD"); //本周一
      let this_Saturday = moment().weekday(6).format("YYYY-MM-DD"); //本周一
      let this_sunday = moment().weekday(7).format("YYYY-MM-DD"); //本周日
      return [
        this_monday,
        this_Tuesday,
        this_Wednesday,
        this_Thursday,
        this_Friday,
        this_Saturday,
        this_sunday,
      ];
    },
    getNextWeek(i) {
      let weekOfDay = parseInt(moment().format("E")); //计算今天是这周第几天
      let next_monday = moment()
        .add(7 - weekOfDay + 7 * (i - 1) + 1, "days")
        .format("YYYY-MM-DD"); //周一日期
      let next_Tuesday = moment()
        .add(7 - weekOfDay + 7 * (i - 1) + 2, "days")
        .format("YYYY-MM-DD"); //周一日期
      let next_Wednesday = moment()
        .add(7 - weekOfDay + 7 * (i - 1) + 3, "days")
        .format("YYYY-MM-DD"); //周一日期
      let next_Thursday = moment()
        .add(7 - weekOfDay + 7 * (i - 1) + 4, "days")
        .format("YYYY-MM-DD"); //周一日期
      let next_Friday = moment()
        .add(7 - weekOfDay + 7 * (i - 1) + 5, "days")
        .format("YYYY-MM-DD"); //周一日期
      let next_Saturday = moment()
        .add(7 - weekOfDay + 7 * (i - 1) + 6, "days")
        .format("YYYY-MM-DD"); //周一日期
      let next_sunday = moment()
        .add(7 - weekOfDay + 7 * i, "days")
        .format("YYYY-MM-DD"); //周日日期
      return [
        next_monday,
        next_Tuesday,
        next_Wednesday,
        next_Thursday,
        next_Friday,
        next_Saturday,
        next_sunday,
      ];
    },
    showTable() {
      let test_key = [];
      let test_data = [];
      let test_all = "";
      if (this.type_id == null) {
        return;
      }
      if (this.hospital !== undefined) {
        test_key.push("hospital");
        test_data.push(this.hospital);
      }
      if (this.office !== undefined) {
        test_key.push("office");
        test_data.push(this.office);
      }
      if (this.doctor !== undefined) {
        test_key.push("doctor");
        test_data.push(this.doctor);
      }
      test_key.forEach((item, index) => {
        test_all = test_all + "&" + item + "=" + test_data[index];
      });
      this.$admin.http
        .get(
          `/work-scheduling/doctor_worksche/?pb_type=${this.type_id}&start_time=${this.week_date[0]}&page=${this.page}` +
            test_all
        )
        .then((res) => {
          this.tableData = res.data.results;
          this.total = (res.data.count / 10) % 1 === 0
              ? res.data.count / 10
              : parseInt(res.data.count / 10) + 1;
          this.tableData.forEach((item) => {
            item.ws_doctor_schedule_days.data.forEach((res) => {
              // console.log(res.ws_doctor_work_shifts.data);
              if (res.ws_doctor_work_shifts.data.length == 0) {
                for (let i = 0; i <= 2; i++) {
                  res.ws_doctor_work_shifts.data.push({});
                }
                res.ws_doctor_work_shifts.data[0].num_count = 0;
                res.ws_doctor_work_shifts.data[1].num_count = 0;
                res.ws_doctor_work_shifts.data[2].num_count = 0;
              }
            });
          });
        });
    },
    adjustment() {
      let obj_key = Object.keys(this.form);
      let src_key = "";
      obj_key.forEach((key) => {
        src_key = src_key + "&" + key + "=" + `${this.form[key]}`;
      });
      src_key = src_key.slice(1);
      if (this.form.project_name) {
        this.$admin.http.get(`/base-system/exa_dic/?${src_key}`).then((res) => {
          this.total =
            (res.data.count / 10) % 1 === 0
              ? res.data.count / 10
              : parseInt(res.data.count / 10) + 1;
          this.drugsData = res.data.results;
        });
      }
    },
  },
  watch: {
    week: {
      deep: true,
      immediate: true,
      handler(week) {
        if (week === "上周") {
          let week = ["周一", "周二", "周三", "周四", "周五", "周六", "周日"];
          this.week_date = this.getLastWeek(1);
          this.table_label = [];
          for (let i = 0; i < week.length; i++) {
            this.table_label.push(this.week_date[i] + week[i]);
          }
          this.showTable();
        }
        if (week === "本周") {
          let week = ["周一", "周二", "周三", "周四", "周五", "周六", "周日"];
          this.week_date = this.getThisWeek(1);
          this.table_label = [];
          for (let i = 0; i < week.length; i++) {
            this.table_label.push(this.week_date[i] + week[i]);
          }
          this.showTable();
        }
        if (week === "下周") {
          let week = ["周一", "周二", "周三", "周四", "周五", "周六", "周日"];
          this.week_date = this.getNextWeek(1);
          this.table_label = [];
          for (let i = 0; i < week.length; i++) {
            this.table_label.push(this.week_date[i] + week[i]);
          }
          this.showTable();
        }
      },
    },
    hospital: {
      deep: true,
      immediate: true,
      handler(val) {
        this.showTable();
        if (val) {
          this.$store
            .dispatch("bs_office/getList", {
              filter: {
                hospital_id: val,
              },
            })
            .then((res) => {
              this.options2 = res.data;
            });
          this.$store
            .dispatch("bs_doctor/getList", {
              filter: {
                hospital_id: val,
              },
            })
            .then((res) => {
              this.options3 = res.data;
            });
        }
        if (val == "") {
          this.$store.dispatch("bs_office/getList").then((res) => {
            this.options2 = res.data;
          });
          this.$store.dispatch("bs_doctor/getList").then((res) => {
            this.options3 = res.data;
          });
          return;
        }
      },
    },
    office: {
      deep: true,
      immediate: true,
      handler(val) {
        this.showTable();
        if (val == "") {
          this.$store.dispatch("bs_doctor/getList").then((res) => {
            this.options3 = res.data;
          });
          return;
        }
        if (val) {
          this.$store
            .dispatch("bs_doctor/getList", {
              filter: {
                office_id: val,
              },
            })
            .then((res) => {
              this.options3 = res.data;
            });
        }
      },
    },
    doctor: {
      deep: true,
      immediate: true,
      handler(val) {
        this.showTable();
        if (val == "") {
          return;
        }
      },
    },
    page: {
      deep: true,
      immediate: true,
      handler(val) {
        this.showTable()
      },
    },
  },
};
</script>
<style scoped>
.select .el-icon-circle-close,
.el-input__prefix {
  display: block !important;
}
</style>