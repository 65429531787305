<!--
 * @Description: 
 * @Author: your name
 * @Date: 2023-05-04 14:29:34
 * @LastEditors: your name
 * @LastEditTime: 2023-08-31 10:12:04
-->
<template>
  <div>
    <v-card>
      <v-row>
        <v-col sm="2">
          <el-date-picker
            class="select"
            lable="时间"
            size="large"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            v-model="date_value"
            type="date"
            @change="changeTime"
            :clearable="false"
            placeholder="请选择日期"
          >
          </el-date-picker>
        </v-col>
        <v-col sm="2">
          <el-select
            v-if="isshow"
            class="select"
            style="margin: 0 20px 10px"
            :clearable="true"
            filterable
            v-model="hospital_code"
            placeholder="请选择医院"
          >
            <el-option
              v-for="item in options3"
              :key="item.id"
              :label="item.name"
              :value="item.codenum"
            >
            </el-option>
          </el-select>
        </v-col>
        <!--     v-if="$admin.can(['change_operatingroomarrange'])" -->
        <v-col sm="7" class="d-flex justify-end">
          <v-btn
        
            style="color: #fff"
            @click="goCreatArrange"
            color="primary"
            >安排</v-btn
          >
        </v-col>
      </v-row>
    </v-card>
    <v-card class="cardFlex" style="margin-top: 20px">
      <table>
        <tr>
          <th v-for="(item, i) in oprList" :key="i" class="boxTh">
            {{ item.opr_name }}
          </th>
        </tr>
        <tr>
          <td class="boxTdone" v-for="(item, i) in oprList" :key="i">
            <div v-if="item.apt_records.length">
              <div
                v-for="(items, is) in item.apt_records"
                class="tdDivCon"
                :key="is"
                :class="{ selected: selectedItem === items }"
                @click="updataObj(items)"
              >
                <p>{{ items.patient_name }}</p>
                <p>
                  {{ items.apt_date }}
                </p>
                <p style="margin-bottom: 0px">
                  {{ items.start_time + "-" + items.end_time }}
                </p>
              </div>
            </div>
            <div style="text-align: center" v-else>暂无数据</div>
          </td>
        </tr>

        <!-- <tr>
              <td class="boxTdone">数据4</td>
            </tr> -->
      </table>
    </v-card>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: ["resource", "title"],
  data() {
    return {
      // 模板下载地址
      // template_download_url: `${this.global.upload_url}excel/office_import.xlsx`,
      // 弹出侧边栏
      selectedItem: null,
      asideTitle: null,
      id: null,
      item: null,
      table_data: [],
      data: null,
      show: false,
      date_value: moment().format("YYYY-MM-DD"),
      hospital_code: "",
      options3: [],
      oprList: [],
      filters: [
        // {
        //   alwaysOn: true,
        //   label: "所属医院",
        //   source: "hospital_name",
        //   type: "text",
        // },
        {
          source: "apt_date@_gte",
          alwaysOn: true,
        },
      ],
      user_data: null,
      isshow: false,
    };
  },
  mounted() {
    this.user_data = JSON.parse(window.sessionStorage.getItem("user"));
    console.log(this.user_data, "user_data");
    if (this.user_data.is_superuser) {
      this.isshow = true;
    } else {
      this.isshow = false;
    }
    this.$store.dispatch("bs_hospital/getList").then((res) => {
      this.options3 = res.data;
    });

    // this.getList();
  },
  created() {
    this.getHospital();
  },
  watch: {
    hospital_code: {
      deep: true,
      handler: function (newVal) {
        if (newVal) {
          this.getList();
        } else {
        }
      },
    },
    date_value: {
      deep: true,
      handler: function (newVal) {
        if (newVal) {
          this.getList();
        } else {
        }
      },
    },
  },
  methods: {
    changeTime() {
      if (this.date_value == null) {
        this.getList();
      }
    },
    getHospital() {
      let ids = JSON.parse(window.sessionStorage.getItem("user")).hospital;
      this.$store
        .dispatch("bs_hospital/getList", { filter: { id: ids } })
        .then((res) => {
          console.log(res, "resresres");
          if (res.data.length > 0) {
            this.hospital_code = res.data[0].codenum;
          }
        });
    },
    // 点击安排
    goCreatArrange() {
      if (this.selectedItem === null) {
        this.$message({
          message: "请选择手术场次",
          type: "error",
        });
        return;
      } else {
        this.$router.push({
          path: "/arrangeApt",
          query: { selectedItem: JSON.stringify(this.selectedItem) },
        });
      }
    },
    // 点击数据
    updataObj(item) {
      this.selectedItem = item;
      console.log(this.selectedItem);
    },
    getList() {
      let data = {};
      data.apt_date = this.date_value;
      data.hospital_code = this.hospital_code;
      data.state = 1;
      this.$admin.http
        .post("operating-room/appointment/appoint_record/", data)
        .then((res) => {
          console.log(res, "resres");
          this.oprList = res.data;
        });
      // ?apt_date=${this.date_value}&hospital_code=${this.hospital_code}
      // this.$admin.http
      //   .post("operating-room/appointment/appoint_record/")
      //   .then((res) => {
      //     console.log(res, "resresres");
      //     this.oprList = res.data;
      //   });
    },
    // 导入
    on_show_import() {},
    // 导出
    on_export_exce() {},
    itemShow(item) {
      if (item.state == 1) {
        return true;
      }
    },
  },
};
</script>
<style lang="sass" scoped>
.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined)
  background-color: #eee
  color: #586AFC
  &:hover
    background-color: #ccc
    color: #000

.navInput
  width: 12rem
  height: 2.4rem
  border-bottom: 1px solid rgba(0, 0, 0, 0.15)
  border-radius: 3px
  cursor: pointer
  background: #fff
  padding-top: 0.6rem
  color: #444
  font-size: 14px
  font-family: SourceHanSansCN-Normal, SourceHanSansCN
  font-weight: 400

::v-deep .el-input__inner
  border-top: none !important
  border-left: none !important
  border-right: none !important
  border-bottom: 1px #D2D2D2 solid !important
  border-radius: 0
  padding-bottom: 10px
</style>
<style scoped>
::v-deep .el-input__inner {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1px #d2d2d2 solid !important;
  border-radius: 0;
  padding-bottom: 10px;
}
.select .el-icon-circle-close,
.el-input__prefix {
  display: block !important;
}

.custom_btn.v-item-group {
  left: 0;
  position: absolute;
}
table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}
.boxTh {
  width: 200px;
  height: 50px;
  font-size: 20px;
  font-weight: 600;
}
.boxTdone {
  font-size: 18px;
}
.tdDivCon {
  width: 100%;
  border-bottom: 1px solid #000;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
}
.tdDivCon p {
  margin-bottom: 5px;
}
.boxTdone .tdDivCon:last-child {
  border-bottom: none;
}
.cardFlex {
  display: flex;
  justify-content: center;
  overflow-y: auto;
  padding: 20px 0px;
}
.selected {
  background-color: #f9f9f9;
  color: #14b2c5;
}
</style>
