<template>
  <v-card :title="title">
    <!-- <va-list :filters="filters" resource="" disableExport disable-global-search disablePagination> -->
    <va-list
      :filters="filters"
      disableExport
      disable-global-search
      disablePagination
      :sort-desc="[true]"
    >
      <v-card
        style="margin-top: 20px"
        v-for="(fitem, f) of this.datalist"
        :key="f"
      >
        <div style="height: 60px; margin-left: 20px; line-height: 60px">
          <span class="dot_circle" style="margin-top: 15px"></span
          ><span class="title_con">{{ fitem.name }}</span>
          <span class="edit_con" @click="edit(fitem)"
            ><span>
              <v-icon small class="mr-2" color="green">
                mdi-message-text
              </v-icon> </span
            >编辑</span
          >
          <span class="edit_con" @click="delte(fitem)" style="color: red"
            ><span>
              <v-icon small class="mr-2" color="red">
                mdi-trash-can
              </v-icon> </span
            >删除</span
          >
        </div>
        <div style="padding: 20px; margin-top: 8px">
          <div class="first_header">
            <div class="first_header_cell" style="width: 9%">
              <span>星期</span>
            </div>
            <div
              class="first_header_cell"
              v-for="(item, index) of fitem.ws_schedule_days.data"
              :key="index"
            >
              <span v-if="item.week_day == 1">星期一</span>
              <span v-else-if="item.week_day == 2">星期二</span>
              <span v-else-if="item.week_day == 3">星期三</span>
              <span v-else-if="item.week_day == 4">星期四</span>
              <span v-else-if="item.week_day == 5">星期五</span>
              <span v-else-if="item.week_day == 6">星期六</span>
              <span v-else-if="item.week_day == 7">星期日</span>
            </div>
          </div>
          <div class="first_header sec_header">
            <div class="first_header_cell" style="width: 9%">
              <span>午别</span>
            </div>
            <div
              class="sec_header_cell"
              v-for="(mitem, m) of fitem.ws_schedule_days.data"
              :key="m"
            >
              <div
                class="sec_header_title"
                v-for="(nitem, n) of mitem.ws_work_shifts.data"
                :key="n"
              >
                {{ nitem.name }}
              </div>
            </div>
          </div>
          <div class="first_header sec_header" style="margin-top: -3px">
            <div class="first_header_cell" style="width: 9%">
              <span>号源量</span>
            </div>
            <div
              class="sec_header_cell"
              v-for="(mitem, p) of fitem.ws_schedule_days.data"
              :key="p"
            >
              <div
                class="sec_header_title"
                v-for="(nitem, o) of mitem.ws_work_shifts.data"
                :key="o"
              >
                <div
                  style="
                    width: 100%;
                    height: 100%;
                    line-height: 38px;
                    text-align: center;
                    outline: none;
                  "
                >
                  {{ nitem.num_count }}
                </div>
              </div>
            </div>
          </div>
          <div class="first_header sec_header" style="margin-top: -3px">
            <div class="first_header_cell" style="width: 9%">
              <span>开始时间</span>
            </div>
            <div
              class="sec_header_cell"
              v-for="(mitem, d) of fitem.ws_schedule_days.data"
              :key="d"
            >
              <div
                class="sec_header_title"
                v-for="(nitem, t) of mitem.ws_work_shifts.data"
                :key="t"
              >
                {{
                  nitem.start_time == null
                    ? (nitem.start_time = "")
                    : nitem.start_time
                }}
              </div>
            </div>
          </div>
          <div class="first_header sec_header" style="margin-top: -3px">
            <div class="first_header_cell" style="width: 9%">
              <span>结束时间</span>
            </div>
            <div
              class="sec_header_cell"
              v-for="(mitem, d) of fitem.ws_schedule_days.data"
              :key="d"
            >
              <div
                class="sec_header_title"
                v-for="(nitem, t) of mitem.ws_work_shifts.data"
                :key="t"
              >
                {{
                  nitem.end_time == null
                    ? (nitem.end_time = "")
                    : nitem.end_time
                }}
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </va-list>
  </v-card>
</template>

<script>
import moment from "moment";
export default {
  props: ["resource", "title"],
  data() {
    return {
      filters: [],
      moment,
      // data_list: {
      //   name: '第一周排班模板',
      //   time: '',
      //   day_date: [
      //     {
      //       name: '周一',
      //       banci: [
      //         {
      //           name: '上午',
      //           work: "休",
      //           start_time: '9:30',
      //           end_time: '12:00',
      //           is_active: true
      //         },
      //         {
      //           name: '下午',
      //           work: "休",
      //           start_time: '14:00',
      //           end_time: '18:00'
      //         },
      //         {
      //           name: '晚上',
      //           work: "休",
      //           start_time: '18:00',
      //           end_time: '21:00'
      //         }
      //       ]

      //     },
      //     {
      //       name: '周二',
      //       banci: [
      //         {
      //           name: '上午',
      //           work: "20",
      //           start_time: '9:00',
      //           end_time: '12:00',
      //           is_active: true
      //         },
      //         {
      //           name: '下午',
      //           start_time: '14:00',
      //           end_time: '18:00'
      //         },
      //         {
      //           name: '晚上',
      //           start_time: '18:00',
      //           end_time: '21:00'
      //         }
      //       ]

      //     },
      //     {
      //       name: '周三',
      //       banci: [
      //         {
      //           name: '上午',
      //           start_time: '9:00',
      //           end_time: '12:00',
      //           is_active: true
      //         },
      //         {
      //           name: '下午',
      //           start_time: '14:00',
      //           end_time: '18:00'
      //         },
      //         {
      //           name: '晚上',
      //           start_time: '18:00',
      //           end_time: '21:00'
      //         }
      //       ]

      //     },
      //     {
      //       name: '周四',
      //       banci: [
      //         {
      //           name: '上午',
      //           start_time: '9:00',
      //           end_time: '12:00',
      //           is_active: true
      //         },
      //         {
      //           name: '下午',
      //           start_time: '14:00',
      //           end_time: '18:00'
      //         },
      //         {
      //           name: '晚上',
      //           start_time: '18:00',
      //           end_time: '21:00'
      //         }
      //       ]

      //     },
      //     {
      //       name: '周五',
      //       banci: [
      //         {
      //           name: '上午',
      //           start_time: '9:00',
      //           end_time: '12:00',
      //           is_active: true
      //         },
      //         {
      //           name: '下午',
      //           start_time: '14:00',
      //           end_time: '18:00'
      //         },
      //         {
      //           name: '晚上',
      //           start_time: '18:00',
      //           end_time: '21:00'
      //         }
      //       ]

      //     },
      //     {
      //       name: '周六',
      //       banci: [
      //         {
      //           name: '上午',
      //           start_time: '9:00',
      //           end_time: '12:00',
      //           is_active: true
      //         },
      //         {
      //           name: '下午',
      //           start_time: '14:00',
      //           end_time: '18:00'
      //         },
      //         {
      //           name: '晚上',
      //           start_time: '18:00',
      //           end_time: '21:00'
      //         }
      //       ]

      //     },
      //     {
      //       name: '周日',
      //       banci: [
      //         {
      //           name: '上午',
      //           start_time: '9:00',
      //           end_time: '12:00',
      //           is_active: true
      //         },
      //         {
      //           name: '下午',
      //           start_time: '14:00',
      //           end_time: '18:00'
      //         },
      //         {
      //           name: '晚上',
      //           start_time: '18:00',
      //           end_time: '21:00'
      //         }
      //       ]

      //     },
      //   ]
      // },
      datalist: [],
    };
  },
  mounted() {
    this.getdata();
  },
  methods: {
    async getdata() {
      let datalist = await this.$admin.http.get("work-scheduling/worksche/");

      if (datalist.data.length > 0) {
        this.datalist = datalist.data;
      }
    },

    edit(item) {
      this.$router.push({
        path: "/ws-schedule-day/" + item.id + "/edit",
      });
    },

    async delte(item) {
      await this.$store
        .dispatch("ws_work_scheduling_main/delete", {
          id: item.id,
        })
        .then((res) => {
          this.getdata();
        });
    },
  },
};
</script>
<style scoped>
.dot_circle {
  display: inline-block;
  background-color: #069cae;
  width: 8px;
  height: 8px;
  margin-right: 10px;
  border-radius: 8px;
  margin-bottom: 2px;
}

.create_btn {
  padding: 8px 20px;
}

.create_btn:hover {
  background-color: #f1f9f1;
}

.el-table td,
.el-table th {
  text-align: center !important;
}

.edit_con {
  padding: 0px 20px;
  float: right;
  margin-right: 25px;
  color: #4caf50;
  margin-top: 15px;
}

.edit_con:hover {
  background-color: #f1f9f1;
}

.first_header {
  display: flex;
  width: 100%;
  margin: 0 auto;
  border: 1px solid #a4a4a4;
}

.first_header_cell {
  padding: 5px 10px;
  width: 13%;
  height: 40px;
  line-height: 30px;
  text-align: center;
  border: 0.5px solid #a4a4a4;
}

.sec_header_cell {
  display: flex;
  width: 13%;
  height: 40px;
  text-align: center;
}

.sec_header_title {
  width: 33.33%;
  line-height: 38px;
  border: 1px solid #a4a4a4;
}

.sec_header {
  margin-top: -2px;
}
</style>
