<template>
  <div>
    <v-row>
      <v-col>
        <form @submit.prevent="updateAccount" class="mb-5">
          <base-material-card
            color="primary"
            icon="mdi-account"
            title="个人信息"
          >
            <v-card-text>
              <v-row>
                <v-col>
                  <v-text-field
                    label="所属医院"
                    v-model="accountForm.hospital"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    label="所属科室"
                    v-model="accountForm.office"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    label="用户名"
                    v-model="accountForm.username"
                    required
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    label="姓名"
                    v-model="accountForm.name"
                    type="email"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <div class="text-center">
              <v-btn @click="close" style="color: #42b6c4; margin-right: 50px"
                >取消</v-btn
              >
              <!-- <v-btn @click="isSure" color="primary" type="submit">{{
                $t("profile.update")
              }}</v-btn> -->
            </div>
          </base-material-card>
        </form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      accountUpdating: false,
      accountForm: {
        username: null,
        name: null,
        office: null,
        hospital: null,
      },
      errorMessages: {},
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  created() {
    this.gethospital();
    this.getoffice();
    // this.getUserInfo()
  },
  watch: {
    user: {
      handler(newVal) {
        if (newVal) {
          let { name, username, hospital, office } = newVal;
          this.accountForm = {
            name,
            username,
            hospital,
            office,
          };
        }
      },
      immediate: true,
    },
  },
  methods: {
    close() {
      window.history.go(-1);
    },
    isSure() {
      this.user.hospital = this.accountForm.hospital
      this.user.office = this.accountForm.office
      this.user.username = this.accountForm.username
      this.user.name = this.accountForm.name
      this.$store.dispatch("base_system_user/update", {
        id: this.user.id,
        data: this.user
      });
    },
    // 获取公司
    async gethospital() {
      let hospital_name = await this.$store.dispatch("bs_hospital/getOne", {
        id: this.user.hospital,
      });
      this.accountForm.hospital = hospital_name.data.name;
    },
    // 获取部门
    async getoffice() {
      let office_name = await this.$store.dispatch("bs_office/getOne", {
        id: this.user.office,
      });
      this.accountForm.office = office_name.data.name;
    },
    ...mapActions({
      checkAuth: "auth/checkAuth",
    }),
    async update(method, url, data) {
      try {
        await this.$admin.http({ method, url, data });

        this.errorMessages = {};
        return true;
      } catch (e) {
        this.$admin.toast.error(e.message);

        if (e.errors) {
          this.errorMessages = e.errors;
        }
      }
      return false;
    },
    async updateAccount() {
      this.accountUpdating = true;
      try {
        if (
          await this.update(
            "put",
            "/user/profile-information",
            this.accountForm
          )
        ) {
          /**
           * Recheck auth
           */
          this.checkAuth();
          this.$admin.toast.success(this.$t("profile.account_updated"));
        }
      } finally {
        this.accountUpdating = false;
      }
    },
  },
};
</script>
