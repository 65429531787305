<template>
	<v-card :icon="resource.icon" :title="title">
		<va-aside-layout :title="asideTitle">
			<ig-application-form-show v-if="show" :item="item" :id="id"></ig-application-form-show>
			<ig-application-form-form v-else :id="id" :item="item" @saved="onSaved"
				ref="form"></ig-application-form-form>
		</va-aside-layout>
		<va-list @action="onAction" :filters="filters" disable-create disable-global-search>
			<va-data-table :fields="fields" disable-select disable-clone disable-delete disable-edit
				disable-show-redirect @item-action="onAction">
				<template v-slot:[`item.actions`]="{ item }">
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-icon small class="mr-2" color="blue" @click="changeApply(item.id)" v-bind="attrs"
								v-on="on">
								mdi-pencil
							</v-icon>
						</template>
						<span v-if="item.reqExecStatusName">变更预约</span>
						<span v-else>预约</span>
					</v-tooltip>
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-icon small class="mr-2" color="blue" @click="rePrint(item.id)" v-bind="attrs" v-on="on">
								mdi-printer
							</v-icon>
						</template>
						<span>补打申请单</span>
					</v-tooltip>
				</template>

				<template v-slot:[`field.printGuideline`]="{ item }">
					<template v-if="item.printGuideline == true"> <span
							style="color: white; background-color: #4caf50;padding: 3px;border-radius: 5px;">已打印</span>
					</template>
					<template v-else> 未打印 </template>
				</template>
			</va-data-table>
		</va-list>
	</v-card>
</template>

<script>
	export default {
		props: ["resource", "title"],
		data() {
			return {
				asideTitle: null,
				id: null,
				item: null,
				show: false,
				filters: [{
						alwaysOn: true,
						label: "申请单号",
						source: "note",
					},
					{
						alwaysOn: true,
						label: "就诊类型",
						source: "medicalTypeCode",
						type: "select"
					},
					{
						alwaysOn: true,
						label: "患者姓名",
						source: "patientName",
					},
				],
				fields: [
					// {
					// 	source: "message",
					// 	label: "提示信息",
					// 	sortable: false,
					// 	align: "center"
					// },

					{
						source: "note",
						label: "申请单号",
						sortable: false,
						align: "center",
					},
					// {
					// 	source: "medicalTypeCode",
					// 	label: "就诊类型",
					// 	sortable: false,
					// 	align: "center",
					// },
					{
						source: "medicalTypeName",
						label: "就诊类型",
						sortable: false,
						align: "center",
					},
					// {
					// 	source: "imageNo",
					// 	label: "镜像编号",
					// 	sortable: false,
					// 	align: "center"
					// },

					// {
					// 	source: "cardID",
					// 	label: "身份证",
					// 	sortable: false,
					// 	align: "center"
					// },

					// {
					// 	source: "hospitalCardNo",
					// 	label: "医院账户号",
					// 	sortable: false,
					// 	align: "center"
					// },

					// {
					// 	source: "medicalTypeCode",
					// 	label: "医保卡类型代码",
					// 	sortable: false,
					// 	align: "center"
					// },

					// {
					// 	source: "medicalSerialNo",
					// 	label: "医保卡号",
					// 	sortable: false,
					// 	align: "center"
					// },



					{
						source: "reqExecStatusName",
						label: "预约检查状态",
						sortable: false,
						align: "center",
					},

					// {
					// 	source: "reqExecStatus",
					// 	label: "预约检查状态代码",
					// 	sortable: false,
					// 	align: "center"
					// },

					{
						source: "patientName",
						label: "患者姓名",
						sortable: false,
						align: "center",
					},

					// {
					// 	source: "sexName",
					// 	label: "性别",
					// 	sortable: false,
					// 	align: "center"
					// },

					{
						source: "age",
						label: "年龄",
						sortable: false,
						align: "center",
					},

					// {
					// 	source: "phoneNo",
					// 	label: "电话",
					// 	sortable: false,
					// 	align: "center"
					// },

					// {
					// 	source: "bedNo",
					// 	label: "床号",
					// 	sortable: false,
					// 	align: "center"
					// },

					// {
					// 	source: "mailingAddress",
					// 	label: "地址",
					// 	sortable: false,
					// 	align: "center"
					// },

					// {
					// 	source: "resourceGroupID",
					// 	label: "资源组编号",
					// 	sortable: false,
					// 	align: "center"
					// },

					// {
					// 	source: "resourceGroupName",
					// 	label: "资源组名称",
					// 	sortable: false,
					// 	align: "center"
					// },

					// {
					// 	source: "idReq",
					// 	label: "检查项目编号",
					// 	sortable: false,
					// 	align: "center"
					// },

					// {
					// 	source: "reqDeptName",
					// 	label: "检查项目科室",
					// 	sortable: false,
					// 	align: "center"
					// },
					// {
					// 	source: "reqDatetime",
					// 	label: "检查申请时间",
					// 	sortable: false,
					// 	align: "center"
					// },
					{
						source: "idReqName",
						label: "检查项目",
						sortable: false,
						align: "center",
					},

					{
						source: "reqTime",
						label: "预约检查时间",
						sortable: false,
						align: "center",
						type: "date",
						attributes: {
							format: "longTime"
						},
					},
					{
						source: "printGuideline",
						label: "指引单打印状态",
						sortable: false,
						// type: "boolean",
						align: "center",
					},
					// {
					// 	source: "canAppoint",
					// 	label: "是否可预约",
					// 	sortable: false,
					// 	align: "center"
					// },

					// {
					// 	source: "needApoint",
					// 	label: "是否需要预约",
					// 	sortable: false,
					// 	align: "center"
					// },

					// {
					// 	source: "reachStudyTo",
					// 	label: "检查准备就绪",
					// 	sortable: false,
					// 	align: "center"
					// },

					// {
					// 	source: "isError",
					// 	label: "是否出错",
					// 	sortable: false,
					// 	align: "center"
					// },

					// {
					// 	source: "chargeMark",
					// 	label: "充电状态",
					// 	sortable: false,
					// 	align: "center"
					// },

					// {
					// 	source: "appointed",
					// 	label: "已预约",
					// 	sortable: false,
					// 	align: "center"
					// },

					// {
					// 	source: "appointMode",
					// 	label: "预约模式",
					// 	sortable: false,
					// 	align: "center"
					// },

					// {
					// 	source: "reqStatus",
					// 	label: "申请状态",
					// 	sortable: false,
					// 	align: "center"
					// },

					// {
					// 	source: "attention",
					// 	label: "注意事项",
					// 	sortable: false,
					// 	align: "center"
					// },

					// {
					// 	source: "mainDiagnosis",
					// 	label: "主要诊断",
					// 	sortable: false,
					// 	align: "center"
					// },

					// {
					// 	source: "reqDocName",
					// 	label: "申请预约医生",
					// 	sortable: false,
					// 	align: "center"
					// },

					// {
					// 	source: "chiefComplaint",
					// 	label: "重要诉求",
					// 	sortable: false,
					// 	align: "center"
					// },

					// {
					// 	source: "presentHistory",
					// 	label: "过往病史",
					// 	sortable: false,
					// 	align: "center"
					// },

					// {
					// 	source: "allergicHistory",
					// 	label: "过敏史",
					// 	sortable: false,
					// 	align: "center"
					// },

					// {
					// 	source: "physicalExamication",
					// 	label: "体检信息",
					// 	sortable: false,
					// 	align: "center"
					// },

					// {
					// 	source: "assistExamication",
					// 	label: "辅助检验",
					// 	sortable: false,
					// 	align: "center"
					// },

					// {
					// 	source: "needEscort",
					// 	label: "需要配送",
					// 	sortable: false,
					// 	align: "center"
					// },

					// {
					// 	source: "endMenstruationDate",
					// 	label: "末次月经时间",
					// 	sortable: false,
					// 	align: "center"
					// },

					// {
					// 	source: "remark",
					// 	label: "备注",
					// 	sortable: false,
					// 	align: "center",
					// }
				],
			};
		},
		methods: {
			async onAction({
				action,
				title,
				id,
				item
			}) {
				this.asideTitle = title;
				this.id = id;
				this.show = action === "show";
				this.item = item;
				//如果点击的是新增,请求新增的接口
				if (action === "create") {
					this.id = null;
				} else if (action === "edit") {
					this.id = item.id;
				}
			},
			onSaved() {
				this.asideTitle = null;
				this.$refs.list.fetchData();
			},
			changeApply(item) {
				this.$router.push({
					path: "/appointmentmanagement/Change",
					query: {
						items: item,
					},
				});
			},
			rePrint(item) {
				this.$router.push({
					path: "/appointmentmanagement/Reprint",
					query: {
						items: item,
					},
				});
			},
		},
	};
</script>