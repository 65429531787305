<template>
  <va-show :item="item" :id="id">
    <template v-slot:heading>
      <div class="display-2">
        {{ title }}
      </div>
    </template>
    <v-card-text>
      <va-field source="codenum"></va-field>
      <va-field source="name"></va-field>
      <va-field source="parent_id">{{ hospital }}</va-field>
      <va-field source="address"></va-field>
      <va-field source="longitude"></va-field>
      <va-field source="latitude"></va-field>
      <va-field source="introduce"></va-field>

      <va-field source="created_time" type="date" format="longTime"></va-field>
      <va-field source="created_by"></va-field>

      <va-field source="logo">
        <div class="demo-image__preview" v-if="url">
          <el-image
            style="width: 100px; height: 100px"
            :src="url"
            :preview-src-list="srcList"
          >
          </el-image>
        </div>
        <div v-else>暂无logo上传</div>
      </va-field>
    </v-card-text>
  </va-show>
</template>

<script>
export default {
  props: ["title", "item", "id"],
  data() {
    return {
      hospital: "",
      url: null,
      srcList: [],
    };
  },
  created() {
  },
  methods: {
    getdatas() {
      if(this.item.parent_id!==undefined) {
        this.$store.dispatch(
          "bs_hospital/getOne",
          { id: this.item.parent_id }
        ).then((res)=> {
          this.hospital = res.data.name;
        });
      }else{
        this.hospital = null
      }
    },
  },
  watch: {
    id: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
          this.$store
            .dispatch("bs_hospital/getList", {
              filter: {
                id: val,
              },
              include: ["logo_images"],
            })
            .then((res) => {
              let imgs = res.data[0].logo_images;
              this.srcList = [];
              this.url = null;
              imgs.forEach((img) => {
                this.url = `${this.global.url}/${img.big_image}`;
                this.srcList.push(`${this.global.url}/${img.big_image}`);
              });
            });
            this.getdatas();
        }
      },
    },
  },
};
</script>
