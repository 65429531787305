<template>
  <va-edit-layout>
    <ig-item-system-form :id="id" :title="title" :item="item"></ig-item-system-form>
  </va-edit-layout>
</template>

<script>
export default {
  props: ["id", "title", "item"],
};
</script>
