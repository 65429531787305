<template>
	<!-- <base-material-card :icon="resource.icon" :title="title"> -->
	<v-card :icon="resource.icon" :title="title">
		<va-list 
		:include="['ig_service_group_type']" 
		:filters="filters" 
		 >
			<va-data-table disable-clone disableDelete :fields="fields">
				<template v-slot:[`item.actions`]="{ item }">
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-chip class="ma-2" color="#069CAE" @click="onAddGroup(item)" v-bind="attrs" v-on="on"
								text-color="white">
								配置项目
							</v-chip>
						</template>
					</v-tooltip>
				</template>
			</va-data-table>
		</va-list>
	</v-card>
</template>

<script>
	export default {
		props: ["resource", "title"],
		data() {
			return {
				filters: [{
						alwaysOn: true,
						label: "名称",
						source: "name",
					},
					{
						alwaysOn: true,
						label: "科室名称",
						source: "office_name",
					},
					{
						alwaysOn: true,
						label: "执行科室名称",
						source: "exec_office_name",
					},
				],
				fields: [
					// {
					// 	source: "id",
					// 	label: "ID",
					// 	sortable: true,
					// },
					{
						source: "name",
						label: "名称",
						sortable: true,
					},
					{
						label: "分类",
						source: "ig_service_group_type.name",
						sortable: true,
					},
					{
						source: "office_code",
						label: "科室编码",
						sortable: true,
					},
					{
						source: "office_name",
						label: "科室名称",
						sortable: true,
					},
					{
						source: "exec_office_code",
						label: "执行科室编码",
						sortable: true,
					},
					{
						source: "exec_office_name",
						label: "执行科室名称",
						sortable: true,
					},
					{
						source: "work_desc",
						label: "工作描述",
						sortable: true,
					},
					{
						source: "work_addr",
						label: "工作地址",
						sortable: true,
					},

					// {
					//   source: "appointment_way_out",
					//   label: "门诊是否可预约",
					//   sortable: true,
					// },
					// {
					//   source: "appointment_way_out_days",
					//   label: "门诊可提前几天预约",
					//   sortable: true,
					// },
					// {
					//   source: "appointment_way_out_doctor",
					//   label: "医生是否可预约门诊",
					//   sortable: true,
					// },
					// {
					//   source: "appointment_way_in",
					//   label: "住院是否可预约",
					//   sortable: true,
					// },
					// {
					//   source: "appointment_way_in_days",
					//   label: "住院可提前几天预约",
					//   sortable: true,
					// },
					// {
					//   source: "appointment_way_in_doctor",
					//   label: "医生是否可预约住院",
					//   sortable: true,
					// },
					// {
					//   source: "automatic_segmentation",
					//   label: "是否自动分诊",
					//   sortable: true,
					// },
					// {
					//   source: "release_resource",
					//   label: "是否取消后释放资源",
					//   sortable: true,
					// },
					// {
					//   source: "guide_print_out",
					//   label: "门诊是否可打印指引单",
					//   sortable: true,
					// },
					// {
					//   source: "guide_print_in",
					//   label: "住院是否可打印指引单",
					//   sortable: true,
					// },
					{
						source: "remark",
						label: "备注",
						sortable: true,
					},
				],
			};
		},
		methods: {
			onEdit(item) {
				// this.$router.push({ path: "/ig-item", query: { items: item },
				this.$router.push({
					name: "/ig-item",
					params: {
						item
					}
				});
			},
			onAddGroup(item) {
				this.$router.push({
					path: "/medicaltechnician/Join",
					query: {
						group_id: item.id,
						group_name: item.name,
					},
				});
			},
		},
	};
</script>