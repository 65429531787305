export default [{
		name: 'bs_hospital',
		icon: 'mdi-face-agent',
		label: 'name',
		permissions: [
			[Array],
			[Array],
			[Array],
			[Array]
		]
	},
	{
		name: 'bs_office',
		icon: 'mdi-face-agent',
		label: 'name',
		permissions: [
			[Array],
			[Array],
			[Array],
			[Array]
		]
	},
	{
		name: 'bs_position_title',
		icon: 'mdi-face-agent',
		label: 'name',
		permissions: [
			[Array],
			[Array],
			[Array],
			[Array]
		]
	},
	{
		name: 'bs_expense_standard',
		icon: 'mdi-face-agent',
		label: 'name',
		permissions: [
			[Array],
			[Array],
			[Array],
			[Array]
		]
	},
	{
		name: 'bs_expense_standard_tel',
		icon: 'mdi-face-agent',
		label: 'name',
		permissions: [
			[Array],
			[Array],
			[Array],
			[Array]
		]
	},
	{
		name: 'bs_expense_standard_return',
		icon: 'mdi-face-agent',
		label: 'name',
		permissions: [
			[Array],
			[Array],
			[Array],
			[Array]
		]
	},
	{
		name: 'bs_expense_standard_online',
		icon: 'mdi-face-agent',
		label: 'name',
		permissions: [
			[Array],
			[Array],
			[Array],
			[Array]
		]
	},
	{
		name: 'base_system_user',
		icon: 'mdi-face-agent',
		label: 'name',
		permissions: [
			[Array],
			[Array],
			[Array],
			[Array]
		]
	},
	{
		name: 'bs_doctor',
		icon: 'mdi-face-agent',
		label: 'name',
		permissions: [
			[Array],
			[Array],
			[Array],
			[Array]
		]
	},
	{
		name: 'base_system_user_groups',
		icon: 'mdi-face-agent',
		label: 'name',
		permissions: [
			[Array],
			[Array],
			[Array],
			[Array]
		]
	},
	{
		name: 'bs_expense_standard_doctors',
		icon: 'mdi-face-agent',
		label: 'name',
		permissions: [
			[Array],
			[Array],
			[Array],
			[Array]
		]
	},
	{
		name: 'ws_appointment_parameter_setting',
		icon: 'mdi-face-agent',
		label: 'name',
		permissions: [
			[Array],
			[Array],
			[Array],
			[Array]
		]
	},
	{
		name: 'bs_expense_type',
		icon: 'mdi-face-agent',
		label: 'name',
		permissions: [
			[Array],
			[Array],
			[Array],
			[Array]
		]
	},
	{
		name: 'bs_extra_group',
		icon: 'mdi-face-agent',
		label: 'name',
		permissions: [
			[Array],
			[Array],
			[Array],
			[Array]
		]
	},
	{
		name: 'auth_group',
		icon: 'mdi-face-agent',
		label: 'name',
		permissions: [
			[Array],
			[Array],
			[Array],
			[Array]
		]
	},
	{
		name: 'ws_schedule_day',
		icon: 'mdi-face-agent',
		label: 'name',
		permissions: [
			[Array],
			[Array],
			[Array],
			[Array]
		]
	},
	{
		name: 'ws_work_scheduling_main',
		icon: 'mdi-face-agent',
		label: 'name',
		permissions: [
			[Array],
			[Array],
			[Array],
			[Array]
		]
	},
	{
		name: 'ws_doctor_work_scheduling_main',
		icon: 'mdi-face-agent',
		label: 'name',
		permissions: [
			[Array],
			[Array],
			[Array],
			[Array]
		]
	},
	{
		name: 'ws_doctor_work_scheduling_main_recheck',
		icon: 'mdi-face-agent',
		label: 'name',
		permissions: [
			[Array],
			[Array],
			[Array],
			[Array]
		]
	},
	{
		name: 'ws_doctor_work_scheduling_main_phone',
		icon: 'mdi-face-agent',
		label: 'name',
		permissions: [
			[Array],
			[Array],
			[Array],
			[Array]
		]
	},
	{
		name: 'ws_doctor_work_scheduling_main_online',
		icon: 'mdi-face-agent',
		label: 'name',
		permissions: [
			[Array],
			[Array],
			[Array],
			[Array]
		]
	},
	{
		name: 'bs_inspection_dictionaries',
		icon: 'mdi-face-agent',
		label: 'name',
		permissions: [
			[Array],
			[Array],
			[Array],
			[Array]
		]
	},
	{
		name: 'bs_inspection_text',
		icon: 'mdi-face-agent',
		label: 'name',
		permissions: [
			[Array],
			[Array],
			[Array],
			[Array]
		]
	},
	{
		name: 'bs_examination_dictionaries',
		icon: 'mdi-face-agent',
		label: 'name',
		permissions: [
			[Array],
			[Array],
			[Array],
			[Array]
		]
	},
	{
		name: 'cm_expert_introduction_config',
		icon: 'mdi-face-agent',
		label: 'name',
		permissions: [
			[Array],
			[Array],
			[Array],
			[Array]
		]
	},
	{
		name: 'cm_system_log_config',
		icon: 'mdi-face-agent',
		label: 'name',
		permissions: [
			[Array],
			[Array],
			[Array],
			[Array]
		]
	},
	{
		name: 'pa_appointment_record_return',
		icon: 'mdi-face-agent',
		label: 'name',
		actions: undefined
	},
	{
		name: 'bs_drug_directory',
		icon: 'mdi-face-agent',
		label: 'name',
		permissions: [
			[Array],
			[Array],
			[Array],
			[Array]
		]
	},
	{
		name: 'bs_drug_preparation_type',
		icon: 'mdi-face-agent',
		label: 'name',
		permissions: [
			[Array],
			[Array],
			[Array],
			[Array]
		]
	},
	{
		name: 'bs_drug_category',
		icon: 'mdi-face-agent',
		label: 'name',
		permissions: [
			[Array],
			[Array],
			[Array],
			[Array]
		]
	},
	{
		name: 'bs_pharmacy_management',
		icon: 'mdi-face-agent',
		label: 'name',
		permissions: [
			[Array],
			[Array],
			[Array],
			[Array]
		]
	},
	{
		name: 'bs_pharmacy_type',
		icon: 'mdi-face-agent',
		label: 'name',
		permissions: [
			[Array],
			[Array],
			[Array],
			[Array]
		]
	},
	{
		name: 'bs_pharmacy_enterprise',
		icon: 'mdi-face-agent',
		label: 'name',
		permissions: [
			[Array],
			[Array],
			[Array],
			[Array]
		]
	},
	{
		name: 'bs_pharmacy_drug',
		icon: 'mdi-face-agent',
		label: 'name',
		permissions: [
			[Array],
			[Array],
			[Array],
			[Array]
		]
	},
	{
		name: 'bs_drug_type',
		icon: 'mdi-face-agent',
		label: 'name',
		permissions: [
			[Array],
			[Array],
			[Array],
			[Array]
		]
	},
	{
		name: 'htm_type',
		icon: 'mdi-face-agent',
		label: 'name',
		permissions: [
			[Array],
			[Array],
			[Array],
			[Array]
		]
	},
	{
		name: 'sl_audit_log',
		icon: 'mdi-face-agent',
		label: 'name',
		permissions: [
			[Array],
			[Array],
			[Array],
			[Array]
		]
	},
	{
		name: 'bs_inspection_type',
		icon: 'mdi-face-agent',
		label: 'name',
		permissions: [
			[Array],
			[Array],
			[Array],
			[Array]
		]
	},
	{
		name: 'bs_hospital_profile',
		icon: 'mdi-face-agent',
		label: 'name',
		actions: undefined
	},
	{
		name: 'hap_inpatient_ward',
		icon: 'mdi-face-agent',
		label: 'name',
		actions: undefined,
		permissions: [
			[Array],
			[Array],
			[Array],
			[Array]
		]
	},
	{
		name: 'hap_inpatient_room',
		icon: 'mdi-face-agent',
		label: 'name',
		actions: undefined,
		permissions: [
			[Array],
			[Array],
			[Array],
			[Array]
		]
	},
	{
		name: 'hap_sickbed',
		icon: 'mdi-face-agent',
		label: 'name',
		actions: undefined,
		permissions: [
			[Array],
			[Array],
			[Array],
			[Array]
		]
	},
	{
		name: 'hap_bed_apt_config',
		icon: 'mdi-face-agent',
		label: 'name',
		actions: undefined,
		permissions: [
			[Array],
			[Array],
			[Array],
			[Array]
		]
	},
	{
		name: 'hap_bed_apt_record',
		icon: 'mdi-face-agent',
		label: 'name',
		actions: undefined,
		permissions: [
			[Array],
			[Array],
			[Array],
			[Array]
		]
	},
	{
		name: 'hap_bed_apt_record_multiplex',
		icon: 'mdi-face-agent',
		label: 'name',
		actions: undefined,
		permissions: [
			[Array],
			[Array],
			[Array],
			[Array]
		]
	},
	{
		name: 'hap_sickbed_search',
		icon: 'mdi-face-agent',
		label: 'name',
		actions: undefined,
		permissions: [
			[Array],
			[Array],
			[Array],
			[Array]
		]
	},
	{
		name: 'hap_hospitalization_patient',
		icon: 'mdi-face-agent',
		label: 'name',
		actions: undefined,
		permissions: [
			[Array],
			[Array],
			[Array],
			[Array]
		]
	},
	{
		name: 'opr_operating_room_management',
		icon: 'mdi-face-agent',
		label: 'name',
		actions: undefined,
		permissions: [
			[Array],
			[Array],
			[Array],
			[Array]
		]
	},
	{
		name: 'opr_apt_config',
		icon: 'mdi-face-agent',
		label: 'name',
		actions: undefined,
		permissions: [
			[Array],
			[Array],
			[Array],
			[Array]
		]
	},
	{
		name: 'opr_operating_room_apt',
		icon: 'mdi-face-agent',
		label: 'name',
		actions: undefined,
		permissions: [
			[Array],
			[Array],
			[Array],
			[Array]
		]
	},
	{
		name: 'opr_operating_room_apt_search',
		icon: 'mdi-face-agent',
		label: 'name',
		actions: undefined,
		permissions: [
			[Array],
			[Array],
			[Array],
			[Array]
		]
	},
	{
		name: 'opr_operating_room_apt_arrange',
		icon: 'mdi-face-agent',
		label: 'name',
		actions: undefined,
		permissions: [
			[Array],
			[Array],
			[Array],
			[Array]
		]
	},
	{
		name: 'apt_register',
		icon: 'mdi-face-agent',
		label: 'name',
		permissions: [
			[Array],
			[Array],
			[Array],
			[Array]
		]
	},
	{
		name: 'mt_config_main',
		icon: 'mdi-face-agent',
		label: 'name',
		permissions: [
			[Array],
			[Array],
			[Array],
			[Array]
		]
	},
	{
		name: 'mt_template_main',
		icon: 'mdi-face-agent',
		label: 'name',
		permissions: [
			[Array],
			[Array],
			[Array],
			[Array]
		]
	},
	{
		name: 'mt_scheduling_main',
		icon: 'mdi-face-agent',
		label: 'name',
		permissions: [
			[Array],
			[Array],
			[Array],
			[Array]
		]
	},
	{
		name: 'mt_scheduling_main_inspect',
		icon: 'mdi-face-agent',
		label: 'name',
		permissions: [
			[Array],
			[Array],
			[Array],
			[Array]
		]
	},
	{
		name: 'mt_checkup_record',
		icon: 'mdi-face-agent',
		label: 'name'
	},
	{
		name: 'os_scheduling_apt_config',
		icon: 'mdi-face-agent',
		label: 'name'
	},
	{
		name: 'os_scheduling_template',
		icon: 'mdi-face-agent',
		label: 'name'
	},
	{
		name: 'os_scheduling_type',
		icon: 'mdi-face-agent',
		label: 'name'
	},
	{
		name: 'os_work_scheduling_main',
		icon: 'mdi-face-agent',
		label: 'name'
	},
	{
		name: 'os_work_scheduling_main_online',
		icon: 'mdi-face-agent',
		label: 'name'
	},
	{
		name: 'os_work_scheduling_main_phone',
		icon: 'mdi-face-agent',
		label: 'name'
	},
	{
		name: 'os_work_scheduling_detail',
		icon: 'mdi-face-agent',
		label: 'name'
	},
	{
		name: 'os_outpatient_registration',
		icon: 'mdi-face-agent',
		label: 'name'
	},
	{
		name: 'os_outpatient_registration_online',
		icon: 'mdi-face-agent',
		label: 'name'
	},
	{
		name: 'os_outpatient_registration_phone',
		icon: 'mdi-face-agent',
		label: 'name'
	},
	{
		name: 'er_management_main',
		icon: 'mdi-face-agent',
		label: 'name'
	},
	{
		name: 'os_record',
		icon: 'mdi-face-agent',
		label: 'name'
	},
	{
		name: 'os_record_online',
		icon: 'mdi-face-agent',
		label: 'name'
	},
	{
		name: 'os_record_phone',
		icon: 'mdi-face-agent',
		label: 'name'
	},
	{
		name: 'os_registration_records',
		icon: 'mdi-face-agent',
		label: 'name'
	},
	{
		name: 'os_registration_records_online',
		icon: 'mdi-face-agent',
		label: 'name'
	},
	{
		name: 'os_registration_records_phone',
		icon: 'mdi-face-agent',
		label: 'name'
	},
	{
		name: 'bs_examination_type',
		icon: 'mdi-face-agent',
		label: 'name'
	},
	{
		name: 'medical_technology_classification',
		icon: 'mdi-face-agent',
		label: 'name',
		actions: undefined
	},
	{
		name: 'medical_technology_project',
		icon: 'mdi-face-agent',
		label: 'name',
		actions: undefined
	},
	{
		name: 'medical_service_group',
		icon: 'mdi-face-agent',
		label: 'name',
		actions: undefined
	},
	{
		name: 'medical_specify_project_team',
		icon: 'mdi-face-agent',
		label: 'name',
		actions: undefined
	},
	{
		name: 'medical_technology_priority',
		icon: 'mdi-face-agent',
		label: 'name',
		actions: undefined
	},
	{
		name: 'medical_technology_allocation',
		icon: 'mdi-face-agent',
		label: 'name',
		actions: undefined
	},
	{
		name: 'ss_smart_show',
		icon: 'mdi-face-agent',
		label: 'name',
		actions: undefined
	},
	{
		name: 'his_appointment',
		icon: 'mdi-face-agent',
		label: 'name',
		actions: undefined
	},
	{
		name: 'ig_item',
		icon: 'mdi-face-agent',
		label: 'name',
		actions: undefined
	},
	{
		name: 'ig_item_type',
		icon: 'mdi-face-agent',
		label: 'name',
		actions: undefined
	},
	{
		name: 'service_group',
		icon: 'mdi-face-agent',
		label: 'name',
		actions: undefined
	},
	{
		name: 'ig_service_group',
		icon: 'mdi-account',
		label: 'name',
		actions: undefined
	},
	{
		name: 'ig_application_form',
		icon: 'mdi-face-agent',
		label: 'name',
		actions: undefined
	},
	{
		name: 'ig_application_form_change',
		icon: 'mdi-face-agent',
		label: 'name',
		actions: undefined
	},
	{
		name: 'ig_item_property',
		icon: 'mdi-face-agent',
		label: 'name',
		actions: undefined
	},
	{
		name: 'ig_device_resource',
		icon: 'mdi-face-agent',
		label: 'name',
		actions: undefined
	},
	{
		name: 'ig_device_resource_type',
		icon: 'mdi-face-agent',
		label: 'name',
		actions: undefined
	},
	{
		name: 'ig_service_group_device_resource',
		icon: 'mdi-face-agent',
		label: 'name',
		actions: undefined
	},
	{
		name: 'ig_item_operate',
		icon: 'mdi-face-agent',
		label: 'name',
		actions: undefined
	},
	{
		name: 'ig_item_regions',
		icon: 'mdi-face-agent',
		label: 'name',
		actions: undefined
	},
	{
		name: 'ig_item_system',
		icon: 'mdi-face-agent',
		label: 'name',
		actions: undefined
	},
	{
		name: 'ig_service_group_type',
		icon: 'mdi-account',
		label: 'name',
		actions: undefined
	},
	{
		name: 'ig_scheduling_day',
		icon: 'mdi-face-agent',
		label: 'name',
		actions: undefined
	},
	{
		name: 'ig_scheduling_day_time_line',
		icon: 'mdi-face-agent',
		label: 'name',
		actions: undefined
	},
	{
		name: 'ig_scheduling_day_time_line_item',
		icon: 'mdi-face-agent',
		label: 'name',
		actions: undefined
	},
]