<template>
  <div>
    <va-aside-layout :title="asideTitle">
      <!-- <bs-inspection-dictionaries-show
        v-if="show"
        :item="item"
      ></bs-inspection-dictionaries-show> -->
      <bs-inspection-dictionaries-form
        :type="type"
        v-if="!show"
        :id="id"
        :item="item"
        @saved="getSelect"
      ></bs-inspection-dictionaries-form>
    </va-aside-layout>
    <v-card :icon="resource.icon" :title="title">
      <va-list
        :filters="filters"
        disable-global-search
        disable-pagination
        disable-create-redirect
        disable-export
        @action="onAction"
        resource="bs_inspection_dictionaries"
        :sort-by="['id']"
        :sort-desc="[true]"
      >
        <template v-slot:actions>
          <va-action-button
            icon="mdi-briefcase-download-outline"
            label="下载模版"
            :href="template_download_url"
            color="green"
            text
          >
          </va-action-button>
          <va-action-button
            icon="mdi-import"
            color="green"
            label="导入"
            @click="on_show_import"
            text
          >
          </va-action-button>
          <va-action-button
            icon="mdi-arrow-collapse-down"
            color="green"
            label="导出"
            text
            @click="on_export_excel"
          >
          </va-action-button>
        </template>
        <template v-slot:[`filter.project_name`]>
          <el-input
            class="select"
            :clearable="true"
            v-model="name_data"
            placeholder="请输入项目名称"
          >
          </el-input>
        </template>
        <template v-slot:[`filter.hospital_data1`]>
          <el-select
          filterable
            v-if="user_data"
            class="select"
            :clearable="true"
            v-model="hospital_data1"
            placeholder="请选择医院"
          >
            <el-option
              v-for="item in options1"
              :key="item.id"
              :label="item.name"
              :value="item.codenum"
            >
            </el-option>
          </el-select>
          <el-select
            class="select"
            style="margin: -8px 20px"
            :clearable="true"
            filterable
            v-model="office_data1"
            placeholder="请选择科室"
          >
            <el-option
              v-for="item in options2"
              :key="item.id"
              :label="item.name"
              :value="item.codenum"
            >
            </el-option>
          </el-select>
        </template>
        <v-data-table
          @item-action="onAction"
          :headers="headers0"
          :items="desserts0"
          hide-default-footer
          ref="list"
        >
          <template v-slot:[`item.project_fees`]="{ item }">
            <template v-if="item.project_fees">
              <span>￥{{ item.project_fees }}</span>
            </template>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  class="mr-2"
                  color="blue"
                  @click="edit(item)"
                  v-bind="attrs"
                  v-on="on"
                  v-if="$admin.can(['change_inspectiondictionaries'])"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span>编辑</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  class="mr-2"
                  color="red"
                  @click="deleteData(item)"
                  v-bind="attrs"
                  v-on="on"
                  v-if="$admin.can(['delete_inspectiondictionaries'])"
                >
                mdi-trash-can
                </v-icon>
              </template>
              <span>删除</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <div style="width: 100%; display: flex; justify-content: flex-end">
          <v-pagination
            v-model="page"
            :length="length_data"
            :total-visible="7"
          ></v-pagination>
        </div>
      </va-list>
      <v-dialog v-model="dialog" width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">批量导入</span>
          </v-card-title>
          <v-card-text>
            <el-form label-width="80px">
              <el-form-item label="上传文件">
                <el-upload
                  class="upload-demo"
                  action
                  :auto-upload="false"
                  :on-change="handleChange"
                  :file-list="fileList"
                  multiple
                >
                  <el-button
                    type="primary"
                    style="
                      color: white;
                      background-color: #14b2c5;
                      border: #14b2c5 1px solid;
                    "
                    size="small"
                    >点击上传</el-button
                  >
                </el-upload>
              </el-form-item>
            </el-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog = false"> 取消 </v-btn>
            <v-btn color="primary" @click="isSure" text-color="#fff">
              确认
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import { exportResource } from "@/util/excel_export";
export default {
  inject: ["reload"],
  props: ["resource", "title"],
  data() {
    return {
      template_download_url: `${this.global.upload_url}excel/ins_dic_import.xlsx`,
      fileList: [],
      dialog: false,
      page: 1,
      length_data: null,
      type: null,
      asideTitle: null,
      id: null,
      item: null,
      show: false,
      filters: [
        {
          source: "project_name",
          alwaysOn: true,
        },
        {
          source: "hospital_data1",
          alwaysOn: true,
        },
      ],
      desserts0: [],
      headers0: [
        { text: "项目编码", value: "project_code", sortable: false },
        { text: "项目名称", value: "project_name", sortable: false },
        { text: "所属医院", value: "hospital_name", sortable: false },
        { text: "所属科室", value: "office_name", sortable: false },
        { text: "项目费用", value: "project_fees" },
        { text: "类别名称", value: "name_srvtp", sortable: false },
        { text: "类别编码", value: "code_srvtp", sortable: false },
        { text: "备注", value: "remarks", sortable: false },
        { text: "操作", value: "actions", sortable: false },
      ],
      hospital_data1: undefined,
      options1: [],
      office_data1: undefined,
      options2: [],
      hospital_data2: undefined,
      form: {},
      user_data: null,
      name_data: null,
    };
  },
  mounted() {
    this.user_data = JSON.parse(
      window.sessionStorage.getItem("user")
    ).is_superuser;
    this.getSelect();
  },
  methods: {
    adjustment() {
      let obj_key = Object.keys(this.form);
      let src_key = "";
      obj_key.forEach((key) => {
        src_key = src_key + "&" + key + "=" + `${this.form[key]}`;
      });
      src_key = src_key.slice(1);
      if (src_key) {
        this.$admin.http.get(`base-system/ins_dic/?${src_key}`).then((res) => {
          this.length_data =
            (res.data.count / 10) % 1 === 0
              ? res.data.count / 10
              : parseInt(res.data.count / 10) + 1;
          this.desserts0 = res.data.results;
        });
      }
    },
    async onAction({ action, title, id, item }) {
      this.asideTitle = title;
      this.id = id;
      this.show = action === "show";
      this.item = item;
      if (action === "create") {
        this.id = null;
      }
    },
    // onSaved() {
    //   this.asideTitle = null;
    //   this.$refs.list.fetchData();
    // },
    getSelect() {
      let hospital_id = JSON.parse(
        window.sessionStorage.getItem("user")
      ).hospital;
      this.$store
        .dispatch("bs_hospital/getList", {
          filter: {
            id: hospital_id,
          },
        })
        .then((res) => {
          this.hospital_data1 = res.data[0].codenum;
        });
      this.$store.dispatch("bs_hospital/getList").then((res) => {
        this.options1 = res.data;
      });
      this.$store
        .dispatch("bs_office/getList", {
          filter: {
            hospital_id: hospital_id,
          },
        })
        .then((res) => {
          this.options2 = res.data;
        });
      if (this.hospital_data1 !== undefined) {
        this.adjustment();
        // debugger
      }
    },
    inspect() {
      this.getSelect();
    },
    test() {
      let hospital_id = JSON.parse(
        window.sessionStorage.getItem("user")
      ).hospital;
      this.$store
        .dispatch("bs_hospital/getList", {
          filter: {
            id: hospital_id,
          },
        })
        .then((res) => {
          this.hospital_data2 = res.data[0].codenum;
        });
      this.$store.dispatch("bs_hospital/getList").then((res) => {
        this.options3 = res.data;
      });
      this.$store
        .dispatch("bs_office/getList", {
          filter: {
            hospital_id: hospital_id,
          },
        })
        .then((res) => {
          this.options4 = res.data;
        });
      if (this.hospital_data1 !== undefined) {
        this.$admin.http
          .get(`base-system/exa_dic/?hospital_code=${this.hospital_data2}`)
          .then((res) => {
            this.desserts1 = res.data.results;
          });
      }
    },
    edit(item) {
      this.id = null;
      this.asideTitle = `编辑检查字典`;
      this.id = item.id;
      this.item = item;
      this.asideid = item.id;
      this.show = false;
    },
    editItem(item) {
      this.id = null;
      this.asideTitle = `编辑检验字典`;
      this.id = item.id;
      this.item = item;
      this.asideid = item.id;
      this.show = false;
    },
    async deleteData(item) {
      this.$confirm("此操作将删除该数据，是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$store
            .dispatch("bs_inspection_dictionaries/delete", {
              id: item.id,
            })
            .then((res) => {
              this.getSelect();
            });
        })
        .catch(() => {});
    },
    async deleteItem(item) {
      this.$confirm("此操作将删除该数据，是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$store
            .dispatch("bs_examination_dictionaries/delete", {
              id: item.id,
            })
            .then((res) => {
              this.getSelect();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            });
        })
        .catch(() => {});
    },
    on_export_excel() {
      let params = this.$router.currentRoute.query;
      exportResource(
        this.$admin.http,
        "/base-system/export_ins_dic",
        params,
        "检查字典.xlsx"
      );
    },
    //导入
    async isSure() {
      let excel_file = this.fileList[0].raw;
      let formData = new FormData();
      formData.append("excel_file", excel_file);
      await this.$admin.http.post("/base-system/import_ins_dic/", formData);
      this.dialog = false;
    },
    handleChange(file, fileList) {
      this.fileList = fileList;
    },
    on_show_import() {
      this.fileList = [];
      this.dialog = true;
    },
  },
  watch: {
    hospital_data1: {
      deep: true,
      immediate: true,
      handler(val) {
        this.show = true;
        if (val) {
          delete this.form.hospital_code;
          this.form.hospital_code = val;
          this.$store
            .dispatch("bs_hospital/getList", {
              filter: {
                codenum: val,
              },
            })
            .then((res) => {
              this.$store
                .dispatch("bs_office/getList", {
                  filter: {
                    hospital_id: res.data[0].id,
                  },
                })
                .then((data) => {
                  this.options2 = data.data;
                });
            });
          this.adjustment();
        }
        if (val == "" || val == null || val == undefined) {
          delete this.form.hospital_code;
          this.adjustment();
        }
      },
    },
    office_data1: {
      deep: true,
      immediate: true,
      handler(val) {
        this.show = true;
        if (val == "" || val == null || val == undefined) {
          delete this.form.office_code;
          this.adjustment();
        } else {
          delete this.form.office_code;
          this.form.office_code = val;
          this.adjustment();
        }
      },
    },
    name_data: {
      deep: true,
      immediate: true,
      handler(val) {
        this.show = true;
        console.log(111111111,val);
        if (val == "" || val == null || val == undefined) {
          delete this.form.project_name;
          this.adjustment();
        } else {
          delete this.form.project_name;
          this.form.project_name = val;
          this.adjustment();
        }
      },
    },
    page: {
      deep: true,
      immediate: true,
      handler(val) {
        this.show = true;
        if (val == "" || val == null || val == undefined) {
          delete this.form.page;
          this.adjustment();
        } else {
          delete this.form.page;
          this.form.page = val;
          this.adjustment();
        }
      },
    },
  },
};
</script>
<style lang="sass" scoped>
.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined)
  background-color: #eee
  color: #14B2C5
  &:hover
    background-color: #ccc
    color: #214578
.custom_btn.v-item-group
  left: 0
  position: absolute

.v-btn.v-size--default
  margin-top: 5px !important
  font-size: 1rem
</style>
<style scoped>
::v-deep .el-input__inner {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1px #d2d2d2 solid !important;
  border-radius: 0;
  padding-bottom: 10px;
}
.select .el-icon-circle-close,
.el-input__prefix {
  display: block !important;
}

.custom_btn.v-item-group {
  left: 0;
  position: absolute;
}
</style>