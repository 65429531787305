<template>
  <va-show-layout>
    <va-show :item="item">
      <v-row justify="center">
        <v-col sm="6">
          <base-material-card>
            <template v-slot:heading>
              <div class="display-2">
                {{ title }}
              </div>
            </template>
            <v-card-text>
              <va-field source="hospital_id" ></va-field>
              <va-field source="office_id" ></va-field>
              <va-field source="user_rank_id" ></va-field>
              <va-field source="username" ></va-field>
              <va-field source="first_name" ></va-field>
              <va-field source="last_name" ></va-field>
              <va-field source="email" ></va-field>
              <va-field source="is_staff" type="boolean" ></va-field>
              <va-field source="is_active" type="boolean" ></va-field>
              <va-field source="date_joined" type="date" ></va-field>
              <va-field source="name" ></va-field>
              <va-field source="password" type="password" ></va-field>
              <va-field source="last_login" type="date" ></va-field>
            </v-card-text>
          </base-material-card>
        </v-col>
      </v-row>
    </va-show>
  </va-show-layout>
</template>

<script>
export default {
  props: ["title", "item"],
};
</script>
