<template>
  <va-form :id="id" :item="item">
    <v-row justify="center">
      <v-col sm="12">
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2">
              {{ title }}
            </div>
          </template>

          <v-card-text>
            <va-text-input source="device_name" v-model="xman.device_name"></va-text-input>
            <va-text-input source="device_model" v-model="xman.device_model"></va-text-input>
            <va-date-input
              source="online_date"
              v-model="xman.online_date"
              format="short"
              label="预约日期"
            ></va-date-input>
            <va-boolean-input
              label="设备状态"
              source="device_status"
              v-model="xman.device_status"
            ></va-boolean-input>
            <va-text-input source="remark" v-model="xman.remark"></va-text-input>
          </v-card-text>
          <!-- <va-save-button></va-save-button> -->
        </base-material-card>
      </v-col>
    </v-row>    
    <div class="btn_bg">
      <v-btn @click="isSure" color="primary">保存</v-btn>
    </div>
  </va-form>
</template>

<script>
export default {
  props: ["id", "title", "item"],
  data() {
    return {
      xman: {
        device_name:"",
        device_model:"",
        device_status:false,
        online_date:"",
        remark:""
      },

      // ... 你的其他数据属性 ...
      menu: false,
      date: null, // 用于v-date-picker的模型
      time: null, // 用于v-time-picker的模型
      // ... 你的其他数据属性 ...
    };
  },
  methods: {
    async isSure() {
      if (this.id) {
        try {
          await this.$store
            .dispatch("ss_smart_show/update", {
              id: this.id,
              data: this.xman,
            })
            .then((res) => {
              this.$emit("saved");
            });
        } catch {
          // this.$message({
          //   message: "医院编码违反唯一性，请修改",
          //   type: "error",
          // });
        }
      } else {
        await this.$store
          .dispatch("ss_smart_show/create", {
            data: this.xman,
          })
          .then((res) => {
            this.$emit("saved");
            // if (this.file_data && this.file_data.length >= 1) {
            //   this.httpRequest(this.imgfile, res.data.id, res.data.logo);
            // }
          })
          .catch((err) => {
            // this.$message({
            //   message: "医院编码违反唯一性，请修改",
            //   type: "error",
            // });
          });
      }
    },
    close() {
      
    }
  }
};
</script>

<style scoped>
.v-application--is-ltr .v-input__prepend-outer {
  display: none;
}
.btn_bg {
  width: 95%;
  position: fixed;
  bottom: 0px;
  padding: 10px 0;
  text-align: center;
  background-color: white;
}
</style>