<template>
	<v-container>
		<div class="image-container" id="print-area" ref="printArea">
			<img :src="generatedImage" alt="Generated Image" v-if="generatedImage" />
		</div>
		<v-row>
			<v-col cols="12">
				<v-btn @click="printImage" :disabled="!canPrint" color="success">打印图片</v-btn> <span style="color: gray;">* 预约后才能打印</span>
			</v-col>
			<v-col cols="12" class="d-none">
				<canvas ref="canvas" width="800" height="700" style="border:1px solid #000000;"></canvas>
			</v-col>
		</v-row>
		<v-tabs style="margin-top: 10px;">
			<v-tab v-for="(item, index) in dates" :key="index" @click="fetchSchedule(item.text)">{{ `${item.text} (${item.day})` }}</v-tab>
		</v-tabs>
		<h2 class="pa-2">上午</h2>
		<div class="d-flex flex-nowrap date-scroll pa-2">
			<div class="date-card" :class="active == 'am' + index ? 'active' : ''"
				v-for="(schedule, index) in amSchedule" :key="index" @click="selectSchedule(schedule, 'am', index)">
				<p>{{ schedule.group_name }}</p>
				<p>{{ schedule.time_line_name }}</p>
				{{ schedule.used_count }}/{{ schedule.resource_count }}
			</div>
		</div>
		<br />
		<h2 class="pa-2">下午</h2>
		<div class="d-flex flex-nowrap date-scroll pa-2">
			<div class="date-card" :class="active == 'pm' + index ? 'active' : ''"
				v-for="(schedule, index) in pmSchedule" :key="index" @click="selectSchedule(schedule, 'pm', index)">
				<p>{{ schedule.group_name }}</p>
				<p>{{ schedule.time_line_name }}</p>
				{{ schedule.used_count }}/{{ schedule.resource_count }}
			</div>
		</div>
		<v-card-text>
			<v-row>
				<v-col sm="3">
					<v-radio-group row v-model="needEscort" label="是否陪送" :disabled="isSaved">
						<v-radio label="轮椅" value="1"></v-radio>
						<v-radio label="平车" value="2"></v-radio>
					</v-radio-group>
				</v-col>
				<v-col sm="3">
					<el-date-picker class="select" label="末次月经时间" :picker-options="pickerOptions" size="large"
						v-model="xman.endMenstruationDate" type="date" placeholder="末次月经时间" :disabled="isSaved">
					</el-date-picker>
				</v-col>
				<v-col sm="3">
					<v-btn v-if="!isSaved" class="print-btn" text @click="isSure" :disabled="!canSave" :style="{ backgroundColor: canSave ? '#4caf50' : '' }">
						保 存
					</v-btn>
					<v-btn v-if="isSaved" disabled>已预约</v-btn>
				</v-col>
				<v-col sm="3">
				</v-col>
			</v-row>
		</v-card-text>
	</v-container>
</template>

<script>
import JsBarcode from 'jsbarcode';
import moment from "moment";
import 'moment/locale/zh-cn'; // 引入中文语言包

function getNextSevenDays() {
	const dates = [];
	let currentDate = moment(); // 获取当前日期

	for (let i = 0; i < 7; i++) {
		const dateString = currentDate.format('YYYY-MM-DD');
		const dayOfWeek = currentDate.format('dddd');
		dates.push({
			text: dateString,
			day: dayOfWeek
		});
		currentDate = currentDate.add(1, 'days'); // 增加一天
	}

	return dates;
}

export default {
	data() {
		return {
			userInput: '',
			apiData: '',
			generatedImage: '',
			dates: getNextSevenDays(),
			amSchedule: [],
			pmSchedule: [],
			canPrint: false, // 控制打印按钮的可用状态
			canSave: false, // 控制保存按钮的可用状态
			isSaved: false, // 控制是否显示已预约按钮
			// 初始化数据模型
			patientName: '',
			sexName: '',
			idReq: '',
			idReqName: '',
			reqQr: 0, // 指引单号
			reqNo: 0, // 排号
			reqDocDepartName: '',
			reqDocName: '',
			reqDatetime: '',
			needEscort: '',
			wattingLocation: "",
			wattingDatetime: "", // 待检时间
			wattingDept: "",

			active: 'am0',
			items: [],
			pickerOptions: {
				disabledDate(time) {
					return time.getTime() < Date.now() - 8.64e7;
				},
			},
			xman: {
				patientName: null,
				patientLid: null,
				visitTime: null,
				visitNo: null,
				visitOrdNo: null,
				age: null,
				sexName: null,
				needEscort: null,
				phoneNo: null,
				mailingAddress: null,
				bedNo: null,
				reqExecStatusName: null,
				reqExecStatus: null,
				reqNo: null,
				reqQr: null,
				visitTypeCode: null,
				visitTypeName: null,
				visitOrdNo: null,
				resourceGroupName: null,
				reqDocCode: null,
				reqDocName: null,
				reqDatetime: null,
				reqTime: null,
				requestNo: null,
				orderLid: null,
				deptCode: null,
				deptName: null,
				exeDeptCode: null,
				exeDeptName: null,
				orgCode: null,
				orgName: null,
				orderLid: null,
				cardID: null,
				medicalSerialNo: null,
				item_code: null,
				item_name: null,
				mainDiagnosis: null,
				chiefComplaint: null,
				presentHistory: null,
				allergicHistory: null,
			},
		};
	},
	mounted() {
		this.fetchQuery();
	},
	watch: {
		userInput: 'generateImage',
		apiData: 'generateImage',
		needEscort: 'generateImage', // 监听 needEscort 变化
		active() {
			this.updateCanSave();
		},
		needEscort() {
			this.updateCanSave();
		}
	},
	methods: {
		updateCanSave() {
			this.canSave = this.active && this.needEscort;
		},
		async fetchReqQr() {
			try {
				const response = await this.$admin.http.get(
					`/application_form/applicationform/generate_unique_reqQr/?visitTypeCode=${this.xman.visitTypeCode}`
				);
				this.reqQr = response.data.reqQr;
				await this.fetchSchedule(this.dates[0].text)
				this.generateImage(); // 获取到 reqQr 后生成图片
			} catch (error) {
				console.error('Error fetching reqQr:', error);
			}
		},
		async fetchSchedule(date) {
			try {
				const itemCode = this.xman.item_code;
				const response = await this.$admin.http.get(`/item/itembindinggroup/item-schedule-day?date=${date}&item_code=${itemCode}`);
				const data = response.data;
				this.amSchedule = data.am;
				if (this.amSchedule?.length > 0) {
					this.selectSchedule(this.amSchedule[0])
				}

				this.pmSchedule = data.pm;
			} catch (error) {
				console.error('Error fetching schedule:', error);
			}
		},
		selectSchedule(schedule, time, index) {
			this.active = time + index;
			this.reqNo = schedule.reqNo;
			this.wattingLocation = schedule.work_addr;
			this.reqTime = schedule.req_datetime;
			this.wattingDatetime = moment(schedule.req_datetime).format('YYYY-MM-DD') + ' ' + schedule.time_line_name + ' (北京时间)';
			this.wattingDept = schedule.exec_office_name;
			this.items = [schedule.items];
			this.generateImage();
		},
		fetchApiData() {
			// 模拟从接口获取数据
			return new Promise((resolve) => {
				setTimeout(() => {
					resolve('接口数据');
				}, 1000);
			});
		},
		async generateImage() {
			console.log('in image generate.');
			 // 获取接口数据
			this.apiData = await this.fetchApiData();

			// 获取 canvas 上下文
			const canvas = this.$refs.canvas;
			const context = canvas.getContext('2d');

			// 清除之前的内容
			context.clearRect(0, 0, canvas.width, canvas.height);

			// 绘制矩形背景
			context.fillStyle = "#FFFFFF";
			context.fillRect(0, 0, canvas.width, canvas.height);

			// 设置通用文本样式
			context.font = "16px Arial";
			context.fillStyle = "#000000";

			// 根据visitTypeCode决定生成哪种指引单
			const isInpatient = ["03", "05"].includes(this.xman.visitTypeCode);
			console.log('666 isInpatient ', isInpatient);
			isInpatient ? this.generateInpatientGuide(context) : this.generateOutpatientGuide(context);

			// 生成图片
			this.generatedImage = canvas.toDataURL("image/png");
		},

		// 门诊
		generateOutpatientGuide(context) {
			this.drawTitle(context, "检查预约指引单（门诊）");
			this.drawBarcode(context, this.reqQr, 50, 60);

			const escortWay = this.getEscortWay(); // 获取当前的陪送方式

			const textItems = [
				{ text: `姓名: ${this.xman.patientName}`, x: 50, y: 180 },
				{ text: `性别: ${this.xman.sexName}`, x: 400, y: 180 },
				{ text: `年龄: ${this.xman.age}岁`, x: 500, y: 180 },
				{ text: `科室: ${this.xman.deptName}`, x: 50, y: 205 },
				{ text: `床号: ${this.xman.bedNo}`, x: 400, y: 205 },
				{ text: `预约号: `, x: 50, y: 230 },
				{ text: `${this.reqNo}`, x: 120, y: 230, font: "bold 20px Arial" },
				{ text: `候检时间: `, x: 50, y: 255 },
				{ text: `${this.wattingDatetime}`, x: 120, y: 255, font: "bold 20px Arial" },
				{ text: `候检地点: ${this.wattingLocation}`, x: 50, y: 280 },
				{ text: `检查诊室: ${this.wattingDept}`, x: 50, y: 305 },
				{ text: `申请医生: ${this.xman.reqDocName}`, x: 50, y: 380 },
				{ text: `诊断: ${this.xman.mainDiagnosis}`, x: 50, y: 405 },
				{ text: `病史: ${this.xman.presentHistory}`, x: 50, y: 430 },
				{ text: `陪送方式: ${escortWay}`, x: 50, y: 455 },
				{ text: "***过号请重新预约***", x: 50, y: 480 },
			];

			this.drawTextItems(context, textItems);
			this.drawExamItems(context, 330);
		},
		// 住院
		generateInpatientGuide(context) {
			this.drawTitle(context, "检查预约指引单（住院）");
			this.drawBarcode(context, this.reqQr, 50, 60);

			const escortWay = this.getEscortWay(); // 获取当前的陪送方式
			console.log('in 2022 ', escortWay);

			const textItems = [
				{ text: `住院号：`, x: 500, y: 93 },
				{ text: `ZY0856230005487`, x: 500, y: 117 },
				{ text: `姓名: ${this.xman.patientName}`, x: 50, y: 180 },
				{ text: `性别: ${this.xman.sexName}`, x: 400, y: 180 },
				{ text: `年龄: ${this.xman.age}岁`, x: 500, y: 180 },
				{ text: `科室: ${this.xman.deptName}`, x: 50, y: 205 },
				{ text: `床号: ${this.xman.bedNo}`, x: 400, y: 205 },
				{ text: `预约号: `, x: 50, y: 230 },
				{ text: `${this.reqNo}`, x: 120, y: 230, font: "bold 20px Arial" },
				{ text: `候检时间: `, x: 50, y: 255 },
				{ text: `${this.wattingDatetime}`, x: 120, y: 255, font: "bold 20px Arial" },
				{ text: `候检地点: ${this.wattingLocation}`, x: 50, y: 280 },
				{ text: `检查诊室: ${this.wattingDept}`, x: 50, y: 330 },
				{ text: `申请医生: ${this.xman.reqDocName}`, x: 50, y: 380 },
				{ text: `诊断: ${this.xman.mainDiagnosis}`, x: 50, y: 405 },
				{ text: `病史: ${this.xman.presentHistory}`, x: 50, y: 430 },
				{ text: `陪送方式: ${escortWay}`, x: 50, y: 455 },
				{ text: "***过号请重新预约***", x: 50, y: 480 },
				{ text: this.userInput, x: 50, font: "16px Arial" }
			];

			this.drawTextItems(context, textItems);
			this.drawExamItems(context, 305);
		},

		drawTitle(context, title) {
			context.font = "bold 24px Arial";
			context.fillText(title, 250, 50);
		},

		drawBarcode(context, data, x, y) {
			const barcodeCanvas = document.createElement('canvas');
			JsBarcode(barcodeCanvas, data, {
				format: 'CODE128',
				displayValue: true,
				height: 50,
				fontSize: 20
			});
			context.drawImage(barcodeCanvas, x, y);
		},

		drawTextItems(context, textItems) {
			textItems.forEach(({ text, x, y, font }) => {
				if (font) context.font = font;
				context.fillText(text, x, y);
				context.font = "16px Arial"; // reset font
			});
		},

		drawExamItems(context, startY) {
			this.items.forEach((item, index) => {
				const y = startY + index * 25;
				context.fillText(index === 0 ? `检查项目: ${item.item_name}` : item.item_name, index === 0 ? 50 : 122, y);
			});
		},

		getEscortWay() {
			console.log('this.needEscort ', this.needEscort);
			switch (this.needEscort) {
				case "1":
					return '轮椅';
				case "2":
					return '平车';
				default:
					return '-';
			}
		},
		async fetchQuery() {
			try {
				const query = this.$route.query;
				this.xman.patientName = query.patientName;
				this.xman.age = query.age;
				this.xman.visitNo = query.visitNo;
				this.xman.visitOrdNo = query.visitOrdNo;
				this.xman.visitTypeCode = query.visitTypeCode;
				this.xman.visitTypeName = query.visitTypeName;
				this.xman.sexName = query.sexName;
				this.xman.phoneNo = query.phoneNo;
				this.xman.mailingAddress = query.mailingAddress;
				this.xman.bedNo = query.bedNo;
				this.xman.deptName = query.deptName;
				this.xman.deptCode = query.deptCode;
				this.xman.medicalTypeName = query.medicalTypeName;
				this.xman.medicalListSerialNo = query.medicalListSerialNo;
				this.xman.resourceGroupName = query.resourceGroupName;
				this.xman.requestNo = query.requestNo;
				this.xman.reqDocName = query.reqDocName;
				this.xman.reqDocCode = query.reqDocCode;
				this.xman.reqDatetime = query.reqDatetime;
				this.xman.reqDocDepartName = query.reqDocDepartName;
				this.xman.cardID = query.cardID;
				this.xman.medicalSerialNo = query.medicalSerialNo;
				this.xman.hospitalCardNo = query.hospitalCardNo;
				this.xman.item_code = query.item_code;
				this.xman.item_name = query.item_name;
				
				this.xman.orderLid = query.orderLid;				
				this.xman.orgCode = query.orgCode;
				this.xman.orgName = query.orgName;
				this.xman.patientLid = query.patientLid;
				
				this.xman.mainDiagnosis = query.mainDiagnosis;
				this.xman.chiefComplaint = query.chiefComplaint;
				this.xman.presentHistory = query.presentHistory;
				this.xman.allergicHistory = query.allergicHistory;
				// 为 xman 赋值后，获取 reqQr
				this.fetchReqQr();
			} catch (error) {
				console.error('Error fetching items:', error);
			}
		},
		notSure() {
			this.$router.push(
				"/" //跳转
			);
		},
		async isSure() {
			if (!this.needEscort) {
				this.$message.error('请选择是否陪送')
				return false
			}

			this.xman.needEscort = this.needEscort;
			this.xman.reqExecStatusName = "已预约";
			this.xman.reqExecStatus = 1;
			this.xman.reqNo = this.reqNo;
			this.xman.reqQr = this.reqQr;
			this.xman.reqTime = this.reqTime;
			await this.$admin.http.post("/application_form/applicationform/", this.xman);
			this.isSaved = true; // 设置为已保存状态
			this.canPrint = true; // 激活打印按钮
			this.$message({
				message: "预约成功！",
				type: "success"
			});
		},
		printImage() {
			if (this.generatedImage) {
				const printArea = document.getElementById('print-area').innerHTML;
				const originalContents = document.body.innerHTML;
				document.body.innerHTML = printArea;
				window.print();
				setTimeout(() => {
					document.body.innerHTML = originalContents;
				}, 1000);
			} else {
				alert('请先生成图片');
			}
		}
	},
}
</script>

<style scoped>
.image-guide {
	display: flex;
	justify-items: center;
	width: 100%;
	background: #fff;
	overflow-y: auto;
	height: 200px;
	align-items: flex-start;
}

.image-guide-img {
	max-width: 80%;
	height: auto;
}

.image-container {
	max-height: 300px;
	/* 可调整高度 */
	overflow-y: auto;
	/* 设置垂直滚动条 */
}

img {
	width: 100%;
	/* 图片宽度充满容器 */
}

.print-btn {
	color: white;
	border-radius: 5px;
	background-color: #4caf50;
}

.date-scroll {
	overflow-x: auto
}

.date-card {
	flex-shrink: 0;
	padding: 2rem 0;
	cursor: pointer;

	width: 12rem;
	margin-right: 20px;
	background-color: rgb(180, 230, 236);
	align-items: center;
	text-align: center;
	border-radius: 10px;
}

.date-card.active {
	background-color: rgb(100, 230, 236);
	box-shadow: 0 20px 13px -6px rgba(180, 230, 236, .5)
}

.date-card p {
	margin-bottom: 5px !important
}

.v-container {
	width: 100%;
	/* 确保容器宽度为100% */
	max-width: 100%;
	/* 防止超过浏览器宽度 */
}

.date-scroll {
	display: flex;
	flex-wrap: nowrap;
	overflow-x: auto;
	width: 100%;
	/* 确保滚动视图宽度为100% */
}

.date-card {
	flex: 0 0 auto;
	/* 确保卡片不会被拉伸或压缩 */
	padding: 2rem;
	width: 12rem;
	/* 卡片宽度固定，但可以根据实际需要调整 */
	margin-right: 20px;
	/* 间隔 */
	background-color: rgb(180, 230, 236);
	text-align: center;
	border-radius: 10px;
}
</style>
