<template>
  <v-card>
    <v-card-text style="width: 97%; margin: 0 auto">
      <v-row>
        <ul style="padding: 2vw 0.5vw 0.5vw 0.5vw; color: #069cae">
          <li>编辑排班</li>
          <li style="list-style: none; font-size: 12px; color: #999">
            SCHEDULING TEMPLATE
          </li>
        </ul>
      </v-row>
      <v-row>
        <div style="margin: 1vw 0.5vw">
          <div class="first_header">
            <div class="first_header_cell" style="width: 9%">
              <span>星期</span>
            </div>
            <div
              class="first_header_cell"
              v-for="(item, index) of this.data_list.ws_doctor_schedule_days
                .data"
              :key="index"
            >
              <span v-if="item.week_day == 1"
                >{{ moment(item.work_date).format("YYYY-MM-DD") }} 周一</span
              >
              <span v-else-if="item.week_day == 2"
                >{{ moment(item.work_date).format("YYYY-MM-DD") }} 周二</span
              >
              <span v-else-if="item.week_day == 3"
                >{{ moment(item.work_date).format("YYYY-MM-DD") }} 周三</span
              >
              <span v-else-if="item.week_day == 4"
                >{{ moment(item.work_date).format("YYYY-MM-DD") }} 周四</span
              >
              <span v-else-if="item.week_day == 5"
                >{{ moment(item.work_date).format("YYYY-MM-DD") }} 周五</span
              >
              <span v-else-if="item.week_day == 6"
                >{{ moment(item.work_date).format("YYYY-MM-DD") }} 周六</span
              >
              <span v-else-if="item.week_day == 7"
                >{{ moment(item.work_date).format("YYYY-MM-DD") }} 周日</span
              >
            </div>
          </div>
          <div class="first_header sec_header">
            <div class="first_header_cell" style="width: 9%">
              <span>午别</span>
            </div>
            <div
              class="sec_header_cell"
              v-for="(mitem, m) of this.data_list.ws_doctor_schedule_days.data"
              :key="m"
            >
              <div
                class="sec_header_title"
                v-for="(nitem, n) of mitem.ws_doctor_work_shifts.data"
                :key="n"
              >
                {{ nitem.name }}
              </div>
            </div>
          </div>
          <div class="first_header sec_header" style="margin-top: -2px">
            <div class="first_header_cell" style="width: 9%">
              <span>号源量</span>
            </div>
            <div
              class="sec_header_cell"
              v-for="(mitem, m) of this.data_list.ws_doctor_schedule_days.data"
              :key="m"
            >
              <div
                class="sec_header_title"
                v-for="(nitem, n) of mitem.ws_doctor_work_shifts.data"
                :key="n"
              >
                <div v-if="nitem.is_active">
                  {{
                    nitem.is_active == true
                      ? (nitem.num_count = 0)
                      : nitem.num_count
                  }}
                </div>
                <input
                  v-else
                  v-model="nitem.num_count"
                  style="
                    width: 100%;
                    height: 100%;
                    line-height: 38px;
                    text-align: center;
                    outline: none;
                  "
                />
              </div>
            </div>
          </div>
          <div class="first_header sec_header" style="margin-top: -2px">
            <div class="first_header_cell" style="width: 9%">
              <span>开始时间</span>
            </div>
            <div
              class="sec_header_cell"
              v-for="(mitem, m) of this.data_list.ws_doctor_schedule_days.data"
              :key="m"
            >
              <div
                class="sec_header_title"
                v-for="(nitem, n) of mitem.ws_doctor_work_shifts.data"
                :key="n"
              >
                <el-time-picker
                  :clearable="false"
                  style="width: 100%"
                  class="date_picker"
                  id="time"
                  v-model="nitem.start_time"
                  format="HH:mm"
                  value-format="HH:mm"
                >
                </el-time-picker>
              </div>
            </div>
          </div>
          <div class="first_header sec_header" style="margin-top: -3px">
            <div class="first_header_cell" style="width: 9%">
              <span>结束时间</span>
            </div>
            <div
              class="sec_header_cell"
              v-for="(mitem, m) of this.data_list.ws_doctor_schedule_days.data"
              :key="m"
            >
              <div
                class="sec_header_title"
                v-for="(nitem, n) of mitem.ws_doctor_work_shifts.data"
                :key="n"
              >
                <el-time-picker
                  :clearable="false"
                  style="width: 100%"
                  id="time_0"
                  v-model="nitem.end_time"
                  format="HH:mm"
                  value-format="HH:mm"
                >
                </el-time-picker>
              </div>
            </div>
          </div>
          <div class="first_header sec_header" style="margin-top: -3px">
            <div class="first_header_cell" style="width: 9%">
              <span>是否休息</span>
            </div>
            <div
              class="sec_header_cell"
              v-for="(mitem, m) of this.data_list.ws_doctor_schedule_days.data"
              :key="m"
            >
              <div
                class="sec_header_title"
                v-for="(nitem, n) of mitem.ws_doctor_work_shifts.data"
                :key="n"
              >
                <el-checkbox v-model="nitem.is_active">休息</el-checkbox>
              </div>
            </div>
          </div>
        </div>
      </v-row>
      <div class="text-center">
        <v-btn @click="notSure(type_id)" style="color: #42b6c4; margin-right: 50px"
          >取消</v-btn
        >
        <v-btn
          @click="isSure"
          style="background-color: #42b6c4; margin-right: 50px"
          >保存</v-btn
        >
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      data_list: null,
      type_id: null,
      moment,
    };
  },
  mounted() {
    if (this.$route.query.type_id) {
      this.type_id = this.$route.query.type_id;
    } else {
      this.type_id = 1;
    }
    this.$admin.http
      .get("work-scheduling/doctor_worksche/" + this.$route.params.id + "/")
      .then((res) => {
        this.data_list = res.data;
      });
  },
  methods: {
    notSure(type) {
      if (type == 1) {
        this.$router.push(
          "/ws-doctor-work-scheduling-main" //跳转
        );
      }
      if (type == 3) {
        this.$router.push(
          "/ws-doctor-work-scheduling-main-online" //跳转
        );
      }
      if (type == 2) {
        this.$router.push(
          "/ws-doctor-work-scheduling-main-phone" //跳转
        );
      }
      if (type == 4) {
        this.$router.push(
          "/ws-doctor-work-scheduling-main-recheck" //跳转
        );
      }
    },
    async isSure() {
      this.data_list.ws_doctor_schedule_days.data.forEach(item => {
        item.ws_doctor_work_shifts.data.forEach(res=> {
          res.left_num = res.num_count
        })
      });
      this.$admin.http
        .put(
          "work-scheduling/doctor_worksche/" + this.$route.params.id + "/",
          this.data_list
        )
        .then((res) => {
          this.notSure(res.data.pb_type);
        });
    },
  },
  watch: {
    date_value: {
      deep: true,
      immediate: true,
      handler(val) {
      },
    },
  },
};
</script>
<style scoped>
.left {
  width: 29%;
  height: 76vh;
  background-color: #fff;
}
.right {
  width: 70%;
  height: 76vh;
  background-color: #fff;
}
.text-center {
  width: 87%;
  background-color: #fff;
  height: 80px;
  position: fixed;
  bottom: 0px;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
.first_header {
  display: flex;
  width: 100%;
  margin: 0 auto;
  border: 0.5px solid #f8f8f8;
}

.first_header_cell {
  padding: 5px 10px;
  width: 13%;
  height: 40px;
  line-height: 30px;
  text-align: center;
  border: 0.5px solid #f8f8f8;
}

.sec_header_cell {
  display: flex;
  width: 13%;
  height: 40px;
  text-align: center;
}

.sec_header_title {
  width: 33.33%;
  line-height: 38px;
  border: 1px solid #f8f8f8;
}

.sec_header {
  margin-top: -2px;
}

.el-input--suffix .el-input__inner {
  padding-right: 0 !important;
}

.el-icon-circle-close,
.el-input__prefix {
  display: none !important;
}

.btn_bg {
  width: 87%;
  position: fixed;
  bottom: 0px;
  padding: 10px 0;
  text-align: center;
  background-color: white;
  z-index: 99;
}
.el-input--suffix .el-input__inner {
  padding-left: 12% !important;
}
.el-picker-panel__footer .el-button--text.el-picker-panel__link-btn {
  display: none;
}
.el-date-editor /deep/ .el-input__prefix {
  display: none !important;
}
.el-input--prefix /deep/ .el-input__inner {
  text-align: center;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
</style>