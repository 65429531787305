<template>
	<v-container>
		<v-row>
			<v-col cols="12">
				<v-data-table :headers="headers" :items="patients" class="elevation-1">
					<template v-slot:top>
						<v-toolbar flat>
							<v-toolbar-title>医嘱签署列表</v-toolbar-title>
						</v-toolbar>
					</template>
					<template v-slot:[`item.action`]="{ item }">
						<v-chip class="ma-2" color="#069CAE" @click="openDialog(item)" text-color="white">
							预约
						</v-chip>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
		<v-dialog v-model="dialog" max-width="800px">
			<v-card>
				<v-card-title>
					<span class="headline">预约</span>
				</v-card-title>
				<v-card-text>
					<v-form ref="form">
						<v-row>
							<v-col v-for="field in displayFields" :key="field.key" cols="3">
								<v-text-field :label="field.label" v-model="editedItem[field.key]"
									:disabled="!field.editable"
									:type="field.key === 'reqTime' ? 'text' : 'text'"></v-text-field>
							</v-col>
						</v-row>
						<v-tabs style="margin-top: 10px;">
							<v-tab v-for="(item, index) in dates" :key="index"
								@click="fetchSchedule(item.text)">{{ `${item.text} (${item.day})` }}</v-tab>
						</v-tabs>
						<h2 class="pa-2">上午</h2>
						<div class="d-flex flex-nowrap date-scroll pa-2">
							<div class="date-card" :class="active == 'am' + index ? 'active' : ''"
								v-for="(schedule, index) in amSchedule" :key="index"
								@click="selectSchedule(schedule, 'am', index)">
								<p>{{ schedule.group_name }}</p>
								<p>{{ schedule.time_line_name }}</p>
								{{ schedule.used_count }}/{{ schedule.resource_count }}
							</div>
						</div>
						<br />
						<h2 class="pa-2">下午</h2>
						<div class="d-flex flex-nowrap date-scroll pa-2">
							<div class="date-card" :class="active == 'pm' + index ? 'active' : ''"
								v-for="(schedule, index) in pmSchedule" :key="index"
								@click="selectSchedule(schedule, 'pm', index)">
								<p>{{ schedule.group_name }}</p>
								<p>{{ schedule.time_line_name }}</p>
								{{ schedule.used_count }}/{{ schedule.resource_count }}
							</div>
						</div>
						<v-row>
							<v-col sm="6">
								<v-radio-group row v-model="needEscort" label="是否陪送" >
									<v-radio label="轮椅" value="1"></v-radio>
									<v-radio label="平车" value="2"></v-radio>
								</v-radio-group>
							</v-col>
							<v-col sm="3" v-if="sexName !== '男'">
								<el-date-picker class="select" label="末次月经时间" :picker-options="pickerOptions" size="large"
									v-model="endMenstruationDate" type="date" placeholder="末次月经时间">
								</el-date-picker>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click="closeDialog">取消</v-btn>
					<v-btn color="blue darken-1" text @click="save">保存</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>
	import moment from "moment";
	import 'moment/locale/zh-cn'; // 引入中文语言包

	function getNextSevenDays() {
		const dates = [];
		let currentDate = moment(); // 获取当前日期

		for (let i = 0; i < 7; i++) {
			const dateString = currentDate.format('YYYY-MM-DD');
			const dayOfWeek = currentDate.format('dddd');
			dates.push({
				text: dateString,
				day: dayOfWeek
			});
			currentDate = currentDate.add(1, 'days'); // 增加一天
		}

		return dates;
	}

	export default {
		data() {
			return {
				dialog: false,
				patients: [],
				needEscort: null,
				sexName: null,
				endMenstruationDate: null,
				isSaved: false, // 控制是否显示已预约按钮
				pickerOptions: {
					disabledDate(time) {
						return time.getTime() > Date.now() - 8.64e7;
					},
				},
				headers: [{
						text: '患者',
						value: 'patientName'
					},
					{
						text: '申请单',
						value: 'requestNo'
					},
					{
						text: '就诊号',
						value: 'visitNo'
					},
					{
						text: '预约状态',
						value: 'reqExecStatusName'
					},
					{
						text: '检查项目',
						value: 'item_name'
					},
					// {
					// 	text: '预约编码',
					// 	value: 'id',
					// },
					{
						text: '操作',
						value: 'action',
						sortable: false
					}
				],
				displayFields: [{
						label: '患者名称',
						key: 'patientName',
						editable: false
					},
					{
						label: '年龄',
						key: 'age',
						editable: false
					},
					{
						label: '性别',
						key: 'sexName',
						editable: false
					},
					{
						label: '电话',
						key: 'phoneNo',
						editable: false
					},
					{
						label: '就诊类别名称',
						key: 'visitTypeName',
						editable: false
					},
					{
						label: '就诊流水号',
						key: 'visitOrdNo',
						editable: false
					},
					{
						label: '床号',
						key: 'bedNo',
						editable: false
					},
					{
						label: '就诊号',
						key: 'visitNo',
						editable: false
					},
					{
						label: '开立医生',
						key: 'reqDocName',
						editable: false
					},
					{
						label: '开立时间',
						key: 'reqDatetime',
						editable: false
					},
					{
						label: '开立科室',
						key: 'deptName',
						editable: false
					},
					{
						label: '申请单编号',
						key: 'requestNo',
						editable: false
					},
					{
						label: '预约排号',
						key: 'reqNo',
						editable: true
					},
					{
						label: '预约检查时间',
						key: 'reqTime',
						editable: true
					}
				],
				editedItem: {},
				dates: getNextSevenDays(),
				amSchedule: [],
				pmSchedule: [],
				active: 'am0',
				selectedSchedule: null,
			};
		},
		mounted() {
			this.fetchData();
		},
		methods: {
			async fetchData() {
				try {
					const response = await this.$admin.http.get(
						'/application_form/applicationform/filter_by_req_status/?reqExecStatus=0');
					this.patients = response.data;
				} catch (error) {
					console.error('Error fetching data:', error);
				}
			},
			openDialog(item) {
				console.log('item.sexName ', item.sexName);
				this.sexName = item.sexName;
				console.log('this.sexName ', this.sexName);
				this.editedItem = {
					...item
				};
				if (this.editedItem.reqDatetime) {
					this.editedItem.reqDatetime = this.formatDateTime(this.editedItem.reqDatetime);
				}
				this.dialog = true;
				this.fetchSchedule(this.dates[0].text, true); // 加载第一个时间选项卡的数据并默认选择第一个时间块
			},
			closeDialog() {
				this.dialog = false;
				this.$refs.form.reset();
			},
			async save() {
				// 将选择的时间块赋值给 reqTime
				if (this.selectedSchedule) {
					this.editedItem.reqTime = this.selectedSchedule.start_time;
				}
				console.log('Saving item:', this.editedItem);
				try {
					await this.$admin.http.post('/application_form/applicationform/update_req_fields/', {
						reqNo: this.editedItem.reqNo,
						reqTime: this.editedItem.reqTime,
						needEscort: this.needEscort,
						endMenstruationDate: this.endMenstruationDate,
						exec_office_name: this.exec_office_name,
						work_addr: this.work_addr,
						id: this.editedItem.id
					});
					this.$message({
						message: '保存成功',
						type: 'success'
					});
					this.fetchData(); // 重新获取数据以刷新列表
				} catch (error) {
					console.error('Error saving data:', error);
					this.$message({
						message: '保存失败',
						type: 'error'
					});
				}
				this.closeDialog();
			},
			formatDateTime(dateTime) {
				const date = new Date(dateTime);
				const year = date.getFullYear();
				const month = String(date.getMonth() + 1).padStart(2, '0');
				const day = String(date.getDate()).padStart(2, '0');
				const hours = String(date.getHours()).padStart(2, '0');
				const minutes = String(date.getMinutes()).padStart(2, '0');
				const seconds = String(date.getSeconds()).padStart(2, '0');
				return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
			},
			async fetchSchedule(date, selectFirst = false) {
				try {
					const itemCode = this.editedItem.item_code;
					const isInpatient = ["03", "05"].includes(this.xman.visitTypeCode);
					console.log('999 fetchSchedule ', isInpatient);
					// 就诊类型treatment_type【0门诊、1住院】、
					// 预约人类型appointment_maker【0医生、1分诊台】
					const response = await this.$admin.http.get(`/item/itembindinggroup/item-schedule-day?date=${date}&item_code=${itemCode}&treatment_type=${isInpatient ? 1 : 0}&appointment_maker=0`);
					const data = response.data;
					this.amSchedule = data.am;
					this.pmSchedule = data.pm;
					if (selectFirst && this.amSchedule.length > 0) {
						this.selectSchedule(this.amSchedule[0], 'am', 0);
					} else if (selectFirst && this.pmSchedule.length > 0) {
						this.selectSchedule(this.pmSchedule[0], 'pm', 0);
					}
				} catch (error) {
					console.error('Error fetching schedule:', error);
				}
			},
			selectSchedule(schedule, time, index) {
				this.active = time + index;
				this.selectedSchedule = schedule;
				this.editedItem.reqNo = schedule.reqNo;
				this.exec_office_name = schedule.exec_office_name;
				this.work_addr = schedule.work_addr;
				this.editedItem.reqTime = this.formatDateTime(schedule.start_time); //`${this.formatDateTime(schedule.start_time)} ${schedule.time_line_name}`;
			}
		}
	};
</script>

<style scoped>
	.v-container {
		padding-top: 20px;
	}

	.date-scroll {
		overflow-x: auto;
	}

	.date-card {
		flex-shrink: 0;
		padding: 2rem 0;
		cursor: pointer;
		width: 12rem;
		margin-right: 20px;
		background-color: rgb(180, 230, 236);
		align-items: center;
		text-align: center;
		border-radius: 10px;
	}

	.date-card.active {
		background-color: rgb(100, 230, 236);
		box-shadow: 0 20px 13px -6px rgba(180, 230, 236, .5);
	}

	.date-card p {
		margin-bottom: 5px !important;
	}
</style>