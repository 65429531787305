<template>
  <div>
    <va-aside-layout :title="asideTitle">
      <bs-office-show v-if="show" :item="item" :id="id"></bs-office-show>
      <bs-office-form
        v-else
        :id="id"
        width="500px"
        :item="item"
        @saved="onSaved"
        ref="form"
      ></bs-office-form>
    </va-aside-layout>
    <v-card :icon="resource.icon" :title="title">
      <va-list
        :filters="filters"
        :filter="filter"
        disableExport
        :include="['bs_hospital', 'bs_office']"
        disable-global-search
        disable-create-redirect
        @action="onAction"
        ref="list"
        :sort-by="['created_time']"
        :sort-desc="[true]"
        @update:filter="update_data"
      >
        <template v-slot:actions>
          <va-action-button
            icon="mdi-briefcase-download-outline"
            label="下载模版"
            :href="template_download_url"
            color="green"
            text
          >
          </va-action-button>
          <va-action-button
            icon="mdi-import"
            color="green"
            label="导入"
            @click="on_show_import"
            text
          >
          </va-action-button>
          <va-action-button
            icon="mdi-arrow-collapse-down"
            color="green"
            label="导出"
            text
            @click="on_export_excel"
          >
          </va-action-button>
        </template>
        <va-data-table
          :fields="fields"
          disable-show-redirect
          disable-clone
          disable-edit-redirect
          @item-action="onAction"
        >
          <template v-slot:[`field.introduce`]="{ item }">
            <div
              style="
                width: 11vw;
                word-break: break-all;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
              "
            >
              {{ item.introduce }}
            </div>
          </template>
        </va-data-table>
      </va-list>
      <v-dialog v-model="dialog" width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">批量导入</span>
          </v-card-title>
          <v-card-text>
            <el-form label-width="80px">
              <el-form-item label="上传文件">
                <el-upload
                  class="upload-demo"
                  action
                  :auto-upload="false"
                  :on-change="handleChange"
                  :file-list="fileList"
                  multiple
                >
                  <el-button
                    type="primary"
                    style="
                      color: white;
                      background-color: #14b2c5;
                      border: #14b2c5 1px solid;
                    "
                    size="small"
                    >点击上传</el-button
                  >
                </el-upload>
              </el-form-item>
            </el-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog = false"> 取消 </v-btn>
            <v-btn color="primary" @click="isSure"> 确认 </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import merge from "webpack-merge";
import { exportResource } from "@/util/excel_export";
const static_server_url =
  process.env.VUE_APP_API_URL || "http://localhost:28000/static";
export default {
  inject: ["reload"], // 注入 reload 方法
  props: ["resource", "title"],
  data() {
    return {
      template_download_url: `${this.global.upload_url}excel/office_import.xlsx`,
      fileList: [],
      dialog: false,
      filters: [
        {
          source: "name",
          type: "text",
          alwaysOn: true,
        },
      ],
      fields: [
        { source: "codenum", sortable: false },
        "name",
        "phone",
        { source: "bs_hospital.name", label: "所属医院", sortable: false },
        { source: "bs_office.name", label: "上级科室", sortable: false },
        "address",
        "introduce",
        { source: "is_active", type: "boolean" },
        // "created_by",
        {
          source: "created_time",
          sortable: false,
          type: "date",
          attributes: { format: "longTime" },
        },
      ],
      asideTitle: null,
      id: null,
      item: null,
      show: false,
      filter: {},
    };
  },
  created() {
    this.getSupper();
  },
  methods: {
    getSupper() {
      let user_data = JSON.parse(
        window.sessionStorage.getItem("user")
      );
      if (user_data.is_superuser) {
        this.filters.push({
          source: "hospital_id",
          label: "所属医院",
          type: "select",
          alwaysOn: true,
          attributes: { reference: "bs_hospital" },
          // attributes: { reference: "auth_system_user.id" },
        });
      }else{
        this.filter = {hospital_id: user_data.hospital}
      }
    },
    update_data() {
      // this.$router.push({
      //   query: merge(this.$route.query,{'page': '1'})
      // })
      // this.$route.query.page = 1
      // this.$refs.list.fetchData();
      // this.$refs.list.initFiltersFromQuery()
      const query = JSON.parse(JSON.stringify(this.$route.query));
      query.page = 1; //state 修改的参数
      this.$router.push({ path: this.$route.path, query });
    },
    //导出
    on_export_excel() {
      let params = this.$router.currentRoute.query;
      exportResource(
        this.$admin.http,
        "base-system/export_office/",
        params,
        "科室信息.xlsx"
      );
    },
    //导入
    async isSure() {
      let excel_file = this.fileList[0].raw;
      let formData = new FormData();
      formData.append("excel_file", excel_file);
      await this.$admin.http
        .post("/base-system/import_office/", formData)
        .then((res) => {
          if (res.status == 200) {
            this.$message({
              message: "导入成功",
              type: "warning",
            });
          }
        })
        .catch((err) => {
          this.$message({
            message: "导入失败",
            type: "warning",
          });
        });
      this.dialog = false;
    },
    handleChange(file, fileList) {
      this.fileList = fileList;
    },
    on_show_import() {
      this.fileList = [];
      this.dialog = true;
    },
    async onAction({ action, title, id, item }) {
      this.asideTitle = title;
      this.id = id;
      this.show = action === "show";
      this.item = item;
      //如果点击的是新增,请求新增的接口
      if (action === "create") {
        this.id = null;
      } else if (action === "edit") {
        this.id = item.id;
      }
    },
    onSaved() {
      this.asideTitle = null;
      this.$refs.list.fetchData();
    },
  },
};
</script>
