<!--
 * @Description: 
 * @Author: your name
 * @Date: 2023-04-27 14:54:55
 * @LastEditors: your name
 * @LastEditTime: 2023-08-08 10:16:32
-->
<template>
  <va-form :id="id" :item="item" @saved="$emit('saved')" ref="form">
    <template v-slot:heading>
      <div class="display-2">
        {{ title }}
      </div>
    </template>
    <v-card-text style="padding-bottom: 80px">
      <va-select-input
        label="所属医院"
        item-value="codenum"
        reference="bs_hospital"
        :items-per-page="1000"
        source="hospital_code"
        v-model="form.hospital_code"
      ></va-select-input>
      <SelectInput
        item-value="ward_code"
        source="ward_code"
        v-model="form.ward_code"
        reference="hap_inpatient_ward"
        label="所属病区"
        :items-per-page="1000"
        ref="dept"
        :filter="ward_filter"
      ></SelectInput>
      <va-text-input
        label="病房编码"
        source="room_num"
        v-model="form.room_num"
      ></va-text-input>
      <va-text-input
        label="病房名称"
        source="name"
        v-model="form.name"
      ></va-text-input>
    </v-card-text>
    <div class="btn_bg">
      <v-btn @click="isSure" color="primary">保存</v-btn>
    </div>
  </va-form>
</template>

<script>
import SelectInput from "@/components/VaCustomSelectInput.vue";
export default {
  props: ["id", "title", "item"],
  components: {
    SelectInput,
  },
  data() {
    return {
      form: {
        hospital_code: "",
        hospital_name: "",
        ward_code: "",
        ward_name: "",
        name: "",
        room_num: "",
        is_active: true,
      },
    };
  },
  methods: {
    isSure() {
      if (
        !this.form.hospital_code ||
        !this.form.hospital_name ||
        !this.form.ward_code ||
        !this.form.ward_name ||
        !this.form.name ||
        !this.form.room_num
      ) {
        this.$message({
          message: "有必填项未填写",
          type: "warning",
        });
        return;
      }
      if (this.id) {
        this.$store
          .dispatch("hap_inpatient_room/update", {
            id: this.id,
            data: this.form,
          })
          .then((res) => {
            this.$emit("saved");
          })
          .catch(() => {
            this.$message({
              type: "error",
              message: "病房编码违反唯一性，请修改",
            });
          });
      } else {
        this.$store
          .dispatch("hap_inpatient_room/create", {
            data: this.form,
          })
          .then((res) => {
            this.$emit("saved");
          })
          .catch(() => {
            this.$message({
              type: "error",
              message: "病房编码违反唯一性，请修改",
            });
          });
      }
      console.log(this.form);
      // this.$store.dispatch("hap_sickbed/create",this.form)
    },
  },
  created() {
    // console.log(this.item, "item");
    this.form.hospital_code = "";
    this.form.hospital_code = this.item.hospital_code;
  },

  computed: {
    ward_filter() {
      if (this.form.hospital_code) {
        return { hospital_code: this.form.hospital_code, is_active: true };
      } else {
        return {};
      }
    },
  },
  watch: {
    "form.hospital_code": {
      deep: true,
      handler(newValue, oldValue) {
        this.$store
          .dispatch("bs_hospital/getList", {
            filter: {
              codenum: newValue,
            },
          })
          .then((res) => {
            this.form.hospital_name = res.data[0].name;
          });
      },
    },
    "form.ward_code": {
      deep: true,
      handler(newValue, oldValue) {
        this.$store
          .dispatch("hap_inpatient_ward/getList", {
            filter: {
              ward_code: newValue,
            },
          })
          .then((res) => {
            console.log(res, "1321232");
            this.form.ward_name = res.data[0].name;
          });
      },
    },
  },
};
</script>
<style scoped>
.btn_bg {
  width: 95%;
  position: fixed;
  bottom: 0px;
  padding: 10px 0;
  text-align: center;
  background-color: white;
}
</style>
