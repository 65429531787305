<template>
  <va-form :id="id" :item="item" @saved="$emit('saved')" ref="form">
    <template v-slot:heading>
      <div class="display-2">
        {{ title }}
      </div>
    </template>
    <v-card-text id="forms_data">
      <va-text-input
        v-model="form.codenum"
        label="•医院编码"
        source="codenum"
        required
      ></va-text-input>
      <va-text-input
        v-model="form.name"
        label="•医院名称"
        source="name"
        required
      ></va-text-input>
      <va-select-input
        v-model="form.parent_id"
        source="parent_id"
        reference="bs_hospital"
        label="上级医院"
        :items-per-page="1000"
        :filter="{ is_active: true }"
      ></va-select-input>
      <va-text-input
        v-model="form.address"
        label="•医院地址"
        source="address"
        required
      ></va-text-input>
      <va-text-input
        v-model="form.phone"
        label="联系方式"
        source="phone"
      ></va-text-input>
      <va-text-input
        v-model="form.longitude"
        label="•医院经度"
        source="longitude"
        required
      ></va-text-input>
      <va-text-input
        v-model="form.latitude"
        label="•医院纬度"
        source="latitude"
        required
      ></va-text-input>
      <va-text-input
        v-model="form.introduce"
        label="医院介绍"
        source="introduce"
        multiline
      ></va-text-input>
      <!-- <el-upload
        action=" https://management.e3rong.com/tools-collection/images_upload/"
        list-type="picture-card"
        :on-change="onchange"
        :file-list="fileList"
      >
        <i class="el-icon-plus"></i>
      </el-upload> -->
      <!-- <div style="margin: 10px 0 30px">上传医院LOGO</div> -->
      <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt="" />
      </el-dialog>
    </v-card-text>
    <div class="btn_bg">
      <v-btn @click="isSure" color="primary">保存</v-btn>
      <!-- <va-save-button @click="isSure"></va-save-button> -->
    </div>
  </va-form>
</template>

<script>
import moment from "moment";
import { throwServerError } from "apollo-link-http-common";
import { mapState } from "vuex";
export default {
  // inject: ["reload"],
  props: ["id", "title", "item"],
  data() {
    return {
      file_data: null,
      imgfile: null,
      fileList: [],
      imgUrl: "",
      dialogImageUrl: "",
      dialogVisible: false,
      fileinfo: null,
      uploadFormValid: null,
      textInfo: "123123",
      form: {
        codenum: "",
        name: "",
        parent_id: "",
        address: "",
        longitude: "",
        latitude: "",
        introduce: "",
        phone: "",
        updated_time: moment().format("YYYY-MM-DD HH:mm:ss"),
      },
      imageInfo: "",
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  watch: {
    id: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
          this.$store
            .dispatch("bs_hospital/getList", {
              filter: {
                id: val,
              },
              include: ["logo_images"],
            })
            .then((res) => {
              this.imageInfo = res.data[0].logo_images;
              this.fileList = [];
              this.imageInfo.forEach((img) => {
                this.fileList.push({
                  url: `${this.global.url}/${img.big_image}`,
                });
              });
            });
        }
      },
    },
  },
  methods: {
    error(res) {},
    uploadSuccess(res) {},
    // onchange(file, fileList) {
    //   this.file_data = fileList;
    //   const imgType =
    //     file.raw.type === "image/png" || file.raw.type === "image/jpeg";
    //   const imgSize = file.raw.size / 1024 / 1024 <= 2;
    //   if (!imgType) {
    //     this.$message.error("只能上传png、jpg、jpeg的图片格式！");
    //     return false;
    //   }
    //   if (!imgSize) {
    //     this.$message.error("图片上传最大限制2MB！");
    //     return false;
    //   }
    //   //如果第二次上传文件，将前一个文件删除
    //   //这样fileList就一直保持一个文件
    //   if (fileList.length > 1) {
    //     fileList.splice(0, 1);
    //   }
    //   //获取当前文件的一个内存URL
    //   //给图片src赋值
    //   this.imgfile = file;
    //   this.imgUrl = URL.createObjectURL(file.raw);
    // },

    // // Element 上传
    // httpRequest(file, id, model) {
    //   var formData = new FormData();
    //   formData.append("belong_id", id);
    //   formData.append("belong_model", model);
    //   formData.append("big_image", file.raw);
    //   this.$admin.http
    //     .post("/tools-collection/images_upload/", formData)
    //     .then((img) => {});
    // },
    // // 修改图片
    // reviseImg(imgid, file, id, model) {
    //   var formData = new FormData();
    //   formData.append("belong_id", id);
    //   formData.append("belong_model", model);
    //   formData.append("big_image", file.raw);
    //   this.$admin.http
    //     .put(`/tools-collection/images_upload/${imgid}/`, formData)
    //     .then((img) => {});
    // },

    async isSure() {
      if (
        !this.form.codenum ||
        !this.form.name ||
        !this.form.address ||
        !this.form.longitude ||
        !this.form.latitude
      ) {
        this.$message({
          message: "有必填项未填写",
          type: "warning",
        });
        return;
      }
      if (this.id) {
        this.form.updated_by = this.user.username;
        this.form.created_time = this.item.created_time;
        try {
          await this.$store
            .dispatch("bs_hospital/update", {
              id: this.id,
              data: this.form,
            })
            .then((res) => {
              this.$emit("saved");
              // if (this.file_data && this.file_data.length >= 1) {
              //   if (this.imageInfo[0]) {
              //     this.reviseImg(
              //       this.imageInfo[0].id,
              //       this.imgfile,
              //       this.id,
              //       res.data.logo
              //     );
              //   } else {
              //     this.httpRequest(this.imgfile, this.id, res.data.logo);
              //   }
              // }
            });
        } catch {
          // this.$message({
          //   message: "医院编码违反唯一性，请修改",
          //   type: "error",
          // });
        }
      } else {
        this.form.created_by = this.user.username;
        this.form.created_time = moment().format("YYYY-MM-DD HH:mm:ss");
        await this.$store
          .dispatch("bs_hospital/create", {
            data: this.form,
          })
          .then((res) => {
            this.$emit("saved");
            // if (this.file_data && this.file_data.length >= 1) {
            //   this.httpRequest(this.imgfile, res.data.id, res.data.logo);
            // }
          })
          .catch((err) => {
            // this.$message({
            //   message: "医院编码违反唯一性，请修改",
            //   type: "error",
            // });
          });
      }
    },
    resetForm() {
      this.form = {
        codenum: "",
        name: "",
        parent_id: "",
        address: "",
        longitude: "",
        latitude: "",
        introduce: "",
      };
      // this.$emit('saved')
    },
    uploadFile() {
      let formData = new FormData();
      formData.append("file", this.fileinfo);
    },
  },
};
</script>
<style scoped>
.v-application--is-ltr .v-input__prepend-outer {
  display: none;
}
.btn_bg {
  width: 95%;
  position: fixed;
  bottom: 0px;
  padding: 10px 0;
  text-align: center;
  background-color: white;
}
</style>