<template>
    <os-record-list :resource="resource" :type_fee="'在线问诊'"></os-record-list>
</template>

<script>

export default {
  props: ["resource"],
  data(){
    return{
      title: "在线问诊记录",
    }
  },
  moment() {
  }
};
</script>