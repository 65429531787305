<template>
  <va-form :id="id" :item="item">
    <v-card style="padding-bottom: 100px">
      <v-card-text style="width: 97%; margin: 0 auto">
        <v-row>
          <ul style="padding: 1.5vw 0.5vw">
            <li>资讯清单</li>
            <li style="list-style: none; font-size: 10px; color: #999">
              LISTING INFORMATION
            </li>
          </ul>
        </v-row>
        <v-row>
          <v-col>
            <va-select-input
              source="htm_type_id"
              label="资讯类型"
              reference="htm_type"
              v-model="form.htm_type_id"
            ></va-select-input>
          </v-col>
          <v-col>
            <va-select-input
              source="hospital_id"
              label="所属医院"
              reference="bs_hospital"
              :items-per-page="1000"
              :filter="{ is_active: true }"
              v-model="form.hospital_id"
            ></va-select-input>
          </v-col>
          <v-col>
            <va-text-input source="title" v-model="form.title"></va-text-input>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <va-text-input
              source="author"
              label="作者"
              v-model="form.author"
            ></va-text-input>
          </v-col>
          <v-col>
            <va-text-input
              source="source"
              label="来源"
              v-model="form.source"
            ></va-text-input>
          </v-col>
          <v-col>
            <va-select-input
              source="created_by_id"
              v-model="form.created_by_id"
              label="发布人"
              reference="base_system_user"
              :items-per-page="1000"
              :filter="{ is_active: true }"
            ></va-select-input>
          </v-col>
        </v-row>
        上传缩略图：
        <v-row style="margin: 10px 0">
          <uploadImg
            ref="uploadImg"
            @imgInfo="imgInfo"
            :imageInfo="imageInfo"
          ></uploadImg>
        </v-row>
        <v-row>
          <v-col>
            <complie @input="input" :value="value" :id="id"></complie>
          </v-col>
        </v-row>
      </v-card-text>
      <div class="text-center">
        <v-btn @click="close" style="color: #42b6c4; margin-right: 50px"
          >取消</v-btn
        >
        <v-btn
          @click="isSure"
          style="background-color: #42b6c4; margin-right: 50px"
          >保存</v-btn
        >
      </div>
    </v-card>
  </va-form>
</template>

<script>
import uploadImg from "@/components/UploadImg.vue";
import complie from "../../components/compile.vue";
export default {
  components: {
    complie,
    uploadImg,
  },
  props: ["id", "title", "item"],
  data() {
    return {
      form: {
        title: null,
        created_by_id: null,
        hospital_id: null,
        content: null,
        author: null,
        htm_type_id: null,
        author: null,
        source: null,
      },
      rich_data: null,
      value: null,
      imgfile: null,
      imageInfo: "",
    };
  },
  created() {
    if (this.id) {
      this.value = this.item.content;
      this.rich_data = this.item.content;
    }
  },
  mounted() {
    if (!this.id) {
      this.form.created_by_id = JSON.parse(
        window.sessionStorage.getItem("user")
      ).id;
    }
  },
  methods: {
    imgInfo(val) {
      this.imgfile = val;
    },
    close() {
      this.$router.push("/health-information");
    },
    input(val) {
      console.log(val);
      this.rich_data = val;
    },
    async isSure() {
      this.form.content = this.rich_data;
      console.log(this.form);
      if (this.id) {
        try {
          await this.$store
            .dispatch("health_information/update", {
              id: this.id,
              data: this.form,
            })
            .then((res) => {
              this.close();
                this.$refs.uploadImg.uploadImg(
                  this.imgfile,
                  res.data.id,
                  res.data.litimg
                );
              this.$emit("saved");
            });
        } catch {}
      } else {
        try {
          await this.$store
            .dispatch("health_information/create", {
              data: this.form,
            })
            .then((res) => {
              this.close();
              this.$refs.uploadImg.uploadImg(
                this.imgfile,
                res.data.id,
                res.data.litimg
              );
              this.$emit("saved");
            });
        } catch {}
      }
    },
  },
  watch: {
    id: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
          this.$store
            .dispatch("health_information/getList", {
              filter: {
                id: val,
              },
              include: ["health_images"],
            })
            .then((res) => {
              console.log(res.data);
              this.imageInfo = res.data[0].health_images;
            });
        }
      },
    },
  },
};
</script>
<style scoped>
ul li::marker {
  color: #42b6c4;
}
.row + .row {
  margin-top: 0px;
}
.row > .col {
  padding: 0 12px !important;
}
.text-center {
  width: 87%;
  background-color: #fff;
  height: 80px;
  position: fixed;
  bottom: 0px;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>