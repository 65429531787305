<template>
	<div>
		<div class="template-options">
			<v-row class="template-options-row">
				<v-col cols="3" md="2">
					<v-select filled label="服务组" item-value="id" item-text="name" :items="servicesGroups"
						v-model="selectedServiceGroupId" @change="handleServiceGroupChange"></v-select>
				</v-col>
				<v-col cols="3" md="2" class="template-options-btn" @click="toggleDialog('planSchedule')">
					<span>按计划排班</span>
				</v-col>
				<v-col cols="3" md="2" class="template-options-btn"
					@click="toggleDialog('maintainSchedulingTemplates')">
					<span>分类汇总</span>
				</v-col>
			</v-row>
		</div>
		<v-sheet height="600" style="margin: 20px">
			<div style="display: flex">
				<v-btn text small color="grey darken-2" @click="prev">
					<v-icon small> mdi-chevron-left </v-icon>
				</v-btn>
				<v-btn text small color="grey darken-2" @click="next">
					<v-icon small> mdi-chevron-right </v-icon>
				</v-btn>
				<v-toolbar-title v-if="$refs.calendar">
					{{ $refs.calendar.title }}
				</v-toolbar-title>
				<v-toolbar-title v-else>
					{{ currentMonthYear }}
				</v-toolbar-title>
			</div>
			<v-spacer></v-spacer>
			<v-calendar ref="calendar" v-model="focus" color="primary" :events="events" type="month"
				:event-color="getEventColor">
				<template v-slot:day="{ date }">
					<div>
						<template v-if="date">
							<div @click="handleDay({ date })">
								<div class="date-text blue--text ">{{ usagePlanFormatted(date) }}</div>
								<div class="date-text2" v-show="showType == 'maintainSchedulingTemplates'">{{
										usageSubtotalFormatted(date) }}</div>
							</div>
						</template>
					</div>
				</template>
			</v-calendar>
		</v-sheet>

		<!-- 操作配置弹窗 -->
		<v-dialog v-model="dialogs.planSchedule" max-width="880px">
			<v-card>
				<v-card-title>
					<v-row justify="space-between">
						<v-col cols="3" md="2" style="margin-top: 25px;"> <!-- 使用较多的列数来保持左侧宽度 -->
							按计划排班
						</v-col>
						<v-col cols="3" md="2"> <!-- 使用较多的列数来保持左侧宽度 -->
							<v-menu ref="startMenu" v-model="menuStart" :close-on-content-click="false"
								transition="scale-transition" offset-y min-width="auto">
								<template v-slot:activator="{ on, attrs }">
									<v-text-field v-model="startDate" label="开始日期" prepend-icon="mdi-calendar" readonly
										v-bind="attrs" v-on="on"  @click="showStartDate = true"></v-text-field>
								</template>
								<v-date-picker  v-if="showStartDate"  v-model="startDate" no-title @input="menuStart = false"></v-date-picker>
							</v-menu>
						</v-col>
						<v-col cols="3" md="2"> <!-- 使用较多的列数来保持左侧宽度 -->
							<v-menu ref="endMenu" v-model="menuEnd" :close-on-content-click="false"
								transition="scale-transition" offset-y min-width="auto">
								<template v-slot:activator="{ on, attrs }">
									<v-text-field v-model="endDate" label="结束日期" prepend-icon="mdi-calendar" readonly
										v-bind="attrs" v-on="on" @click="showEndDate = true"></v-text-field>
								</template>
								<v-date-picker  v-if="showEndDate"  v-model="endDate" no-title @input="menuEnd = false"></v-date-picker>
							</v-menu>
						</v-col>
						<v-col cols="3" md="4" style="margin-top: 20px;"> <!-- 使用较少的列数用于按钮组，自动右对齐 -->
							<v-btn color="success" style="margin-left: 100px;" text @click="saveSchedule" normal>
								生成排班
							</v-btn>
						</v-col>
					</v-row>
				</v-card-title>
				<v-card-text>
					<v-simple-table>
						<template v-slot:default>
							<thead>
								<tr>
									<th class="text-left">预约时段</th>
									<th class="text-left">周一</th>
									<th class="text-left">周二</th>
									<th class="text-left">周三</th>
									<th class="text-left">周四</th>
									<th class="text-left">周五</th>
									<th class="text-left">周六</th>
									<th class="text-left">周日</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(schedule, index) in weekSchedule" :key="index">
									<td>{{ schedule.time_line_name }}</td>
									<td v-for="(day, dayIndex) in schedule.days_data" :key="dayIndex">
										<v-text-field v-model="day.value" type="number"
											@click:append="editDay(day, index, dayIndex)" dense outlined solo flat
											hide-details></v-text-field>
									</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
				</v-card-text>
			</v-card>
		</v-dialog>
		<!-- 待办事项对话框 -->
		<v-dialog v-model="dialog" max-width="600px">
			<v-card>
				<v-card-title>
					<v-row justify="space-between">
						<v-col cols="12" md="8"> <!-- 使用较多的列数来保持左侧宽度 -->
							维护计划数量 - {{ selectedDateFormatted }}
						</v-col>
						<v-col cols="12" md="4"> <!-- 使用较少的列数用于按钮组，自动右对齐 -->
							<v-btn color="warning" text @click="markAsFull" normal>
								排满
							</v-btn>
							<v-btn color="success" text @click="saveTasks" normal>
								保存
							</v-btn>
						</v-col>
					</v-row>
				</v-card-title>
				<v-card-text>
					<v-data-table :headers="headers" :items="weekSchedule" class="elevation-4" hide-default-footer>
						<template v-slot:[`item.resource_count`]="{ item }">
							<v-text-field v-model="item.resource_count" type="number" @input="updateRemaining(item)" single-line
								dense placeholder="Edit"></v-text-field>
						</template>
						<template v-slot:[`item.remain`]="{ item }">
							{{ item.remain }}
						</template>
						<template v-slot:[`item.resource_quota`]="{ item }">
							<v-text-field v-model="item.resource_quota" type="number" single-line dense
								placeholder="Edit"></v-text-field>
						</template>
					</v-data-table>
				</v-card-text>
				<v-card-actions>
					<v-btn color="primary" text @click="dialog = false">
						关闭
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	import moment from "moment";
	export default {
		data() {
			return {
				focus: "",
				dialog: false,
				menuStart: moment().format('YYYY-MM-DD'),
				menuEnd: moment().add(7, 'days').format('YYYY-MM-DD'),
				startDate: moment().format('YYYY-MM-DD'),
				endDate: moment().add(7, 'days').format('YYYY-MM-DD'),
				planQuantity: 0,
				date: null,
				showStartDate: null,
				showEndDate: null,
				accessRule: 0,
				resource_quota: 0,
				defaultQuantity: 0, // 默认数量的初始值
				usagePlans: [],
				servicesGroup: 0,
				servicesGroups: [],
				selectedServiceGroupId: null,
				serviceGroupOffice: null,
				serviceGroupNmae: null,
				serviceGroupDesc: null,
				events: [
					// {
					// 	date: new Date('2024-05-01').toISOString().substr(0, 10),
					// 	used: 7,
					// 	available: 11,
					// 	start: new Date('2024-05-01'),
					// 	end: new Date('2024-05-01')
					// },
					// {
					// 	date: new Date('2024-05-02').toISOString().substr(0, 10),
					// 	used: 6,
					// 	available: 10,
					// 	start: new Date('2024-05-02'),
					// 	end: new Date('2024-05-02')
					// },
					// {
					//     "date": "1714492800",
					//     "used": 7,
					//     "available": 11
					// },
					// {
					//     "date": "1714579200",
					//     "used": 6,
					//     "available": 10
					// },
					// {
					//     "date": "2024-05-03",
					//     "used": 2,
					//     "available": 10
					// },
					// {
					//     "date": "2024-05-04",
					//     "used": 5,
					//     "available": 10
					// },
					// {
					//     "date": "2024-05-05",
					//     "used": 8,
					//     "available": 10
					// },
				],
				dialogs: {
					planSchedule: false,
					maintainSchedulingTemplates: false,
					planScheduleAvailableItem: false,
					resourceAllocation: false,
				},
				weekSchedule: [
					// {
					// 	timeRange: '10:00~11:00',
					// 	days: [{
					// 		value: 20
					// 	}, {
					// 		value: 20
					// 	}, {
					// 		value: 20
					// 	}, {
					// 		value: 20
					// 	}, {
					// 		value: 20
					// 	}, {
					// 		value: 20
					// 	}, {
					// 		value: 20
					// 	}]
					// },
					// {
					// 	timeRange: '10:00~11:00',
					// 	days: [{
					// 		value: 20
					// 	}, {
					// 		value: 20
					// 	}, {
					// 		value: 20
					// 	}, {
					// 		value: 20
					// 	}, {
					// 		value: 20
					// 	}, {
					// 		value: 20
					// 	}, {
					// 		value: 20
					// 	}]
					// }					
				],
				currentMonthYear: this.getCurrentMonthYear(),
				headers: [{
						text: '预约时段',
						value: 'time_line_name',
						width: 110,
						sortable: false
					},
					{
						text: '计划预约',
						value: 'resource_count',
						width: 110,
						sortable: false
					},
					{
						text: '已预约',
						value: 'used_count',
						width: 100,
						sortable: false
					},
					// {
					// 	text: '剩余',
					// 	value: 'remain',
					// 	width: 70,
					// 	sortable:false
					// },
					{
						text: '住院限额(-1:不限)',
						value: 'resource_quota',
						sortable: false
					},
					// {
					// 	text: '备用时段',
					// 	value: 'backup',
					// 	width: 100,
					// 	sortable: false
					// },
				],
				rules: {
					required: value => !!value || 'Required.',
				},
				showType: 'planSchedule',
				
			};
		},
		created() {
			this.fetchServicesGroup(); // 在组件创建时调用
		},
		mounted() {
			this.fetchServicesGroup();
		},
		computed: {
			selectedDateFormatted() {
				// ... 已定义的计算属性 ...
				console.log('this.focus', this.focus);
				return this.focus ? moment(this.focus).format('YYYY-MM-DD') : '';
			},
			usagePlanFormatted() {
				const usagePlans = {};
				this.usagePlans.forEach(item => {
					usagePlans[item.date] = `${item.used}/${item.plan}`;
				});

				return date => usagePlans[date] || '---';
			},
			usageSubtotalFormatted() {
				const usagePlans = {};
				this.usagePlans.forEach(item => {
					usagePlans[item.date] = {
						in: item.in,
						out: item.out
					};
				});
				// in  out
				return date => {
					if (!usagePlans[date]) {
						return '暂无分类数据';
					}
					// this.$message({
					// 	message: `获取到分类数据...`,
					// 	type: "success",
					// });
					const {
						in: inCount = 0,
						out = 0
					} = usagePlans[date];
					const inText = inCount > 0 ? `住:${inCount}` : '';
					const outText = out > 0 ? `门:${out}` : '';
					return inCount === 0 && out === 0 ? '暂无分类数据' : `${inText} ${outText}`;
				}
			}
		},
		methods: {
			handleServiceGroupChange() {
				if (this.selectedServiceGroupId) {
					// 在服务组数据中找到选中的服务组对象
					const group = this.servicesGroups.find(group => group.id === this.selectedServiceGroupId);
					if (group) {
						this.serviceGroupOffice = group.exec_office_name; // 执行科室名称
						this.serviceGroupNmae = group.name; // 服务组名称
						this.serviceGroupDesc = group.work_desc; // 服务组描述
					}
				}
				this.updateCalendarData();
			},
			editDay(day, rowIndex, dayIndex) {
				// 实现编辑某一天的逻辑
				console.log(`Editing ${rowIndex + 1}, Day ${dayIndex + 1} `);
			},
			fetchServicesGroup() {
				this.$store.dispatch("ig_service_group/getList")
					.then((res) => {
						this.servicesGroups = res.data;
						if (this.servicesGroups.length > 0) {
							this.selectedServiceGroupId = this.servicesGroups[0].id; // 设置默认选中第一个服务组
							// 可以选择在这里调用 handleServiceGroupChange 来初始化相关数据
							this.handleServiceGroupChange();
						}
					})
					.catch(error => {
						console.error("Failed to load service groups:", error);
					});
			},
			// 操作项目弹窗
			toggleDialog(dialogName) {
				if (this.serviceGroupNmae === null && dialogName == "planSchedule") {
					this.$message({
						message: `请先选择要配置的服务组`,
						type: "error",
					});
				} else {
					this.dialogs[dialogName] = !this.dialogs[dialogName];
					this.showType = this.showType ? "" : dialogName;
					if (dialogName === 'planSchedule' && this.dialogs[dialogName]) {
						this.loadplanSchedule();
					}
					if (dialogName === 'maintainSchedulingTemplates' && this.dialogs[dialogName]) {
						this.$message({
							message: `加载分类数据...`,
							type: "success",
						});
					}
				}
			},
			loadplanSchedule() {
				// 从 API 获取数据，这里假设已经有了 group_id
				const groupId = this.selectedServiceGroupId; // 或者指定的ID，如 2
				this.$admin.http.get(`/scheduling_template/schedulingtemplate/group-scheduling/?group_id=${groupId}`)
					.then(response => {
						// 假设返回的数据结构符合你的描述
						if (response.data && response.data.timelines) {
							this.weekSchedule = [];
							this.weekSchedule = response.data.timelines;
							this.template_id = response.data.id;
							this.template_name = response.data.name;
						}
					})
					.catch(error => {
						console.error("Failed to fetch scheduling templates:", error);
						this.$message({
							message: "加载排班模板数据失败",
							type: "error",
						});
					});
			},
			updateRemaining(item) {
				const total = Number(item.total);
				const used = Number(item.used);
				item.remain = total >= used ? total - used : 0; // 若计划数量小于已使用数量，则标记为错误
			},
			saveEdit(item) {
				// 在这里处理编辑后的数据保存逻辑
				console.log('Edited item', item);
				// 例如保存到服务器或状态管理
			},
			prev() {
				this.$refs.calendar.prev();
				this.updateCalendarData();
				console.log('Clicked prev');
			},
			next() {
				this.$refs.calendar.next();
				this.updateCalendarData();
				console.log('Clicked next');
			},
			// 更新日历数据
			updateCalendarData() {
				const selectedMonth = this.$refs.calendar.start;
				console.log('切换日历 ', selectedMonth);
				this.getUsagePlans(this.focus);
			},
			getUsagePlans(date) {
				// 使用 moment 检查日期是否有效
				if (!moment(date).isValid()) {
					// 如果日期无效，设置为当前月份
					date = moment().format('YYYY-MM');
				} else {
					// 格式化日期为 'YYYY-MM' 格式
					date = moment(date).format('YYYY-MM');
				}
				this.$admin.http.get(
					`scheduling_day/schedulingday/get_usage_plan/?month=${date}&group_id=${this.selectedServiceGroupId}`
				).then((
					response) => {
					// this.usagePlans = response?.data ?? []
					this.usagePlans = response.data ? response.data : [];
				}).catch(error => {
					console.error("Failed to fetch items:", error);
				});
			},
			getEventColor(event) {
				return event.color;
			},
			saveSchedule() {
				// 构造请求体，包含开始日期、结束日期和排班数据
				const requestData = {
					startDate: this.startDate,
					endDate: this.endDate,
					group_id: this.selectedServiceGroupId,
					group_name: this.serviceGroupNmae,
					schedules: this.weekSchedule.map(schedule => ({
						template_time_line_id: schedule.id,
						timeLineName: schedule.time_line_name,
						start_time: schedule.start_time,
						end_time: schedule.end_time,
						resource_no_start: schedule.resource_no_start,
						resource_no_end: schedule.resource_no_end,
						resource_count: schedule.resource_count,
						appointment_item_way: schedule.appointment_item_way, // 特殊项目规则
						item_rule_a: schedule.item_rule_a, // 特殊项目规则
						item_rule_b: schedule.item_rule_b, // 特殊项目规则
						item_rule_c: schedule.item_rule_c, // 特殊项目规则
						item_rule_d: schedule.item_rule_d, // 特殊项目规则
						item_rule_e: schedule.item_rule_e, // 特殊项目规则
						item_rule_f: schedule.item_rule_f, // 特殊项目规则
						item_rule_g: schedule.item_rule_g, // 特殊项目规则
						back_up_time_zone: schedule.back_up_time_zone, // 备用时段
						resource_quota: schedule.resource_quota, // 住院限额
						resource_quota_in_a: schedule.resource_quota_in_a, // 住院限额
						resource_quota_in_b: schedule.resource_quota_in_b, // 住院限额
						resource_quota_in_c: schedule.resource_quota_in_c, // 住院限额
						resource_quota_in_d: schedule.resource_quota_in_d, // 住院限额
						resource_quota_in_e: schedule.resource_quota_in_e, // 住院限额
						resource_quota_in_f: schedule.resource_quota_in_f, // 住院限额
						resource_quota_in_g: schedule.resource_quota_in_g, // 住院限额
						daysData: schedule.days_data.map(day => parseInt(day.value))
					}))
				};
				// 使用 axios 或其他 HTTP 客户端发送 POST 请求
				this.$admin.http.post('/scheduling_day/schedulingday/save-scheduling/', requestData)
					.then(response => {
						// 处理响应
						this.$message({
							message: '排班数据保存成功！',
							type: 'success'
						});
						this.updateCalendarData(); // 更新日历数据
						this.dialogs.planSchedule = false;
					})
					.catch(error => {
						// 处理错误
						console.error('保存排班数据失败:', error);
						this.$message({
							message: `保存失败：${error.message}`,
							type: 'error'
						});
					});
			},
			// 保存按钮事件处理器
			saveTasks() {
				// 在这里实现保存逻辑
				console.log("保存操作日期 ", this.focus);
				console.log("保存操作服务组 ", this.selectedServiceGroupId);
				// 构造请求体，包含开始日期、结束日期和排班数据
				const requestData = {
					date: this.focus,
					group_id: this.selectedServiceGroupId,
					group_name: this.serviceGroupNmae,
					schedules: this.weekSchedule.map(schedule => ({
						template_time_line_id: schedule.id,
						timeLineName: schedule.time_line_name,
						start_time: schedule.start_time,
						end_time: schedule.end_time,
						resource_no_start: schedule.resource_no_start,
						resource_no_end: schedule.resource_no_end,
						resource_count: schedule.resource_count,
						appointment_item_way: schedule.appointment_item_way, // 特殊项目规则
						item_rule_a: schedule.item_rule_a, // 特殊项目规则
						item_rule_b: schedule.item_rule_b, // 特殊项目规则
						item_rule_c: schedule.item_rule_c, // 特殊项目规则
						item_rule_d: schedule.item_rule_d, // 特殊项目规则
						item_rule_e: schedule.item_rule_e, // 特殊项目规则
						item_rule_f: schedule.item_rule_f, // 特殊项目规则
						item_rule_g: schedule.item_rule_g, // 特殊项目规则
						back_up_time_zone: schedule.back_up_time_zone, // 备用时段
						resource_quota: schedule.resource_quota, // 住院限额
						resource_quota_in_a: schedule.resource_quota_in_a, // 住院限额
						resource_quota_in_b: schedule.resource_quota_in_b, // 住院限额
						resource_quota_in_c: schedule.resource_quota_in_c, // 住院限额
						resource_quota_in_d: schedule.resource_quota_in_d, // 住院限额
						resource_quota_in_e: schedule.resource_quota_in_e, // 住院限额
						resource_quota_in_f: schedule.resource_quota_in_f, // 住院限额
						resource_quota_in_g: schedule.resource_quota_in_g, // 住院限额
						daysData: schedule.days_data.map(day => parseInt(day.value))
					}))
				};
				// 使用 axios 或其他 HTTP 客户端发送 POST 请求
				this.$admin.http.post('/scheduling_day/schedulingday/save-plan-count-scheduling/', requestData)
					.then(response => {
						// 处理响应
						this.$message({
							message: '排班数据保存成功！',
							type: 'success'
						});
						this.updateCalendarData(); // 更新日历数据
						this.dialogs.planSchedule = false;
					})
					.catch(error => {
						// 处理错误
						console.error('保存排班数据失败:', error);
						this.$message({
							message: `保存失败：${error.message}`,
							type: 'error'
						});
					});
				// 可能需要关闭对话框
				this.dialog = false;
			},
			// 排满按钮事件处理器
			markAsFull() {
				console.log("排满操作日期 ", this.focus);
				console.log("排满操作服务组 ", this.selectedServiceGroupId);
				
				this.$admin.http.get(`/scheduling_template/schedulingtemplate/group-scheduling/?group_id=${this.selectedServiceGroupId}`)
					.then(response => {
						// 假设返回的数据结构符合你的描述
						if (response.data && response.data.timelines) {
							// this.timelines = [];
							this.weekSchedule = response.data.timelines;
						}
					})
					.catch(error => {
						console.error("Failed to fetch scheduling templates:", error);
						this.$message({
							message: "排班操作失败",
							type: "error",
						});
					});					
			},
			// 处理点击日历格子事件
			handleDay({ date }) {
				this.focus = date;
				this.fetchDaytimelines(date);
			},
			// 获取待办事项 -暂时无用 -
			async fetchDaytimelines(date) {
				console.log("fetching fetchDaytimelines:", date);
				try {
					const response = await this.$admin.http.get('scheduling_day/schedulingday/day-schedule/', {
						params: {
							date: moment(date).format('YYYY-MM-DD'),
							group_id: this.selectedServiceGroupId
						}
					});
					console.log('556 ', response.data);
					this.weekSchedule = response.data; // 假设接口返回的待办事项在 data 属性中
					this.dialog = true; // 打开对话框
				} catch (error) {
					console.error("Error fetching weekSchedule:", error);
				}
			},
			getEventContent(date) {
				const dateString = date.toISOString().substr(0, 10); // 转换为 YYYY-MM-DD 格式
				const event = this.events.find(e => e.date === dateString);
				return event ? `${event.used} /${event.available}` : '0/0';
			},
			async fetchEventsForMonth(month) {
				try {
					const response = await this.$axios.get('/path/to/api', {
						params: {
							month: month
						}
					});
					this.events = response.data.map(event => ({
						...event,
						start: event.date,
						end: event.date,
						content: `${event.used}/${event.available}`
					}));
				} catch (error) {
					console.error("Error fetching events:", error);
				}
			},
			getCurrentMonthYear() {
				const months = ["一月", "二月", "三月", "四月", "五月", "六月", "七月", "八月", "九月", "十月", "十一月", "十二月"];
				const now = new Date();
				const month = months[now.getMonth()]; // 从0开始计数，所以一月是0
				const year = now.getFullYear();
				return `${month} ${year}`;
			},
		}
	};
</script>

<style scoped>
	.date-text {
		font-size: 18px;
		font-weight: bold;
		width: 100%;
		text-align: center;
	}

	.date-text2 {
		font-size: 15px;
		font-weight: bold;
		width: 100%;
		text-align: center;
		color: #000
	}

	.template-options {
		margin-left: 20px;
		margin-right: 20px;
		padding-left: 50px;
		padding-right: 50px;
		background-color: white;
		margin-top: 13px;
		margin-bottom: 0px;
		text-align: center;
		border-radius: 12px;
	}

	.template-options-row {
		align-items: baseline;
	}

	.template-options-btn {
		background-color: #42b6c4;
		border-radius: 12px;
		color: white;
		font-size: larger;
		font-weight: bold;
		padding-top: 8px;
		padding-bottom: 8px;
		padding-left: 0px;
		margin-left: 70px;
		margin-right: 70px;
		margin-top: 20px;
		/* 可以减少这个值来减少按钮栏顶部的空间 */
		margin-bottom: 20px;
		/* 可以减少这个值来减少按钮栏底部的空间 */
	}

	.theme--light /deep/ .v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
		color: #000;
	}
</style>