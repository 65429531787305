export const fileDownload = (blobData, fileName) => {
  const downloadUrl = window.URL.createObjectURL(new Blob([blobData]));
  const link = document.createElement("a");
  link.href = downloadUrl;
  link.setAttribute("download", fileName); //any other extension
  document.body.appendChild(link);
  link.click();
  link.remove();
};

//导出单个资源
export const export_single_resource = (httpClient, url, id, fileName) => {
  httpClient
    .get(url, {
      responseType: "blob",
      params: { id },
    })
    .then(({ data }) => {
      fileDownload(data, fileName);
    });
};
export const exportResource = (httpClient, url, params, fileName) => {
  const { page, perPage, sortBy, sortDesc, filter } = params;

  //getList	{ pagination: { page: Number , perPage: Number },
  //  sort: [{ by: String, desc: Boolean }], filter: Object },
  //  include: String[], fields: { [resource]: String[] }
  // }
  //处理排序
  let ordering = [];
  if (sortBy && sortDesc) {
    let sortByArray = sortBy.split(",");
    let sortDescArray = sortDesc.split(",");
    for (const [i, sortField] of sortByArray.entries()) {
      let order = `${sortDescArray[i] === "true" ? "-" : ""}${sortField}`;
      ordering.push(order);
    }
    ordering = ordering.join(",");
  }

  // const { field, order } = params.sort;
  const filterObj = filter ? JSON.parse(filter) : {};

  //处理查询字段
  let filters = {};
  for (const [key, value] of Object.entries(filterObj)) {
    //处理字符串
    let newKey = key;
    if (typeof value === "string") {
      newKey = `${key}__icontains`;
    }
    //处理数组
    if (Array.isArray(value)) {
      if (value.length > 1) {
        newKey = `${key}__in`;
        value = value.join(",");
      } else {
        value = value[0];
      }
    }
    filters[newKey] = value;
  }
  const query = {
    ordering,
    ...filters,
  };

  httpClient
    .get(url, {
      responseType: "blob",
      params: query,
    })
    .then(({ data }) => {
      fileDownload(data, fileName);
    });
};
