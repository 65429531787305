import {
  LOGIN,
  LOGOUT,
  CHECK_AUTH,
  CHECK_ERROR,
  GET_NAME,
  GET_EMAIL,
  GET_PERMISSIONS,
} from "./actions";

export default (httpClient, params = {}) => {
  params = {
    routes: {
      login: "/base-system/login/",
      logout: "/dj-rest-auth/logout/",
      verify: "/base-system/verify/",
      // refresh: "/dj-rest-auth/token/refresh/",
      // user: "/dj-rest-auth/user/",
      get_permissions: "/base-system/permissions/",
    },
    storageKey: "jwt_token",
    refreshKey: "refresh_token",
    userKey: "user",
    getToken: (r) => r.jwt_token,
    getRefreshToken: (r) => r.jwt_token,
    getCredentials: ({
      username,
      password
    }) => {
      return {
        username,
        password,
      };
    },
    getUser: () => {
      if (sessionStorage.getItem(userKey)) {
        return JSON.parse(sessionStorage.getItem(userKey));
      }
      return null;
    },
    getUsername: (u) => u.username,
    getName: (u) => u.name,
    getEmail: (u) => u.email,
    getPermissions: (u) => u.permissions,
    ...params,
  };

  let {
    routes,
    storageKey,
    refreshKey,
    userKey,
    getCredentials,
    getName,
    getUsername,
    getEmail,
    getPermissions,
    getToken,
    getRefreshToken,
    getUser,
  } = params;

  return {
    [LOGIN]: async ({
      username,
      password
    }) => {
      let response = await httpClient.post(
        routes.login,
        getCredentials({
          username,
          password
        })
      );
      if (response.status === 200) {
        // throw new Error(response.statusText);
        if (response.data.msg) {
          alert(response.data.msg)
        }
      }
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }

      let user = response.data.user;
      sessionStorage.setItem(storageKey, getToken(response.data));
      sessionStorage.setItem(refreshKey, getRefreshToken(response.data));
      // 给用户添加权限
      let resp = await httpClient.get(routes.get_permissions);
      user.permissions = resp.data.map((o) => o.codename);
      sessionStorage.setItem(userKey, JSON.stringify(user));
      if (response.status === 200) {
        if (response.data.msg) {
          alert(response.data.msg)
        }
        let user = JSON.parse(window.sessionStorage.getItem("user"));
        httpClient.get(`config-manage/logout_log/?user_id=${user.id}&operat_content=登录成功&log_type=登录`)
      }
      return Promise.resolve(user);
    },
    [LOGOUT]: async () => {
      let user = JSON.parse(window.sessionStorage.getItem("user"));
      // debugger
      httpClient.get(`config-manage/logout_log/?user_id=${user.id}&operat_content=退出登录成功&log_type=退出登录`)
      if (routes.logout) {
        sessionStorage.removeItem(storageKey);
        sessionStorage.removeItem(refreshKey);
        sessionStorage.removeItem(userKey);
        sessionStorage.removeItem("show")
        localStorage.clear()
      }
      return Promise.resolve();
    },
    [CHECK_AUTH]: async () => {
      /**
       * Refresh token
       */
      if (routes.refresh) {
        const refresh = sessionStorage.getItem(refreshKey);
        let response = await httpClient.post(routes.refresh, {
          refresh
        });
        sessionStorage.setItem(storageKey, response.data.access);
      }
      if (routes.verify) {
        const token = sessionStorage.getItem(refreshKey);
        let response = await httpClient.post(routes.verify, {
          token
        });
        // sessionStorage.setItem(storageKey, response.data.access);
      }

      /**
       * Get user infos
       */
      if (routes.user) {
        let response = await httpClient.get(routes.user);

        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }

        //获取权限
        let data = (user = response.data);
        let resp = await httpClient.get(routes.get_permissions);
        user.permissions = resp.data.map((o) => o.code);
        if (resp.status < 200 || resp.status >= 300) {
          throw new Error(resp.statusText);
        }
        return Promise.resolve({
          data
        });
      }

      let user = sessionStorage.getItem(userKey);
      return user ?
        Promise.resolve({
          data: JSON.parse(user)
        }) :
        Promise.reject();
    },
    [CHECK_ERROR]: ({
      status
    }) => {
      if (status === 401 || status === 403) {
        sessionStorage.removeItem(storageKey);
        sessionStorage.removeItem(refreshKey);
        sessionStorage.removeItem(userKey);
        return Promise.reject();
      }
      return Promise.resolve();
    },
    [GET_NAME]: (user) => getName(user),
    // [GET_EMAIL]: (user) => getUsername(user),
    // [GET_EMAIL]: (user) => getEmail(user),
    [GET_PERMISSIONS]: (user) => getPermissions(user),
  };
};