<template>
	<va-form :id="id" :item="item">
		<v-card class="pa-6">

			<div class="image-guide">
				<img class="image-guide-img " src="../../assets/guide.png" />
			</div>
			<v-btn class="print-btn" text @click="saveTasks" normal>
				打 印
			</v-btn>
			<div class="h-20"></div>

			<v-tabs>
				<v-tab v-for="(item, index) in dates" :key="index">{{ `${item.text} (${item.day})` }}</v-tab>
			</v-tabs>
			<h2 class="pa-2">上午</h2>
			<div class="d-flex flex-nowrap date-scroll pa-2">
				<div class="date-card" v-for="(item, index) in 20" :key="index">
					<p>
						CT5号
					</p>
					<p>
						9：00--10：00
					</p>
					1/10
				</div>
			</div>
			<br />
			<h2 class="pa-2">下午</h2>
			<div class="d-flex flex-nowrap date-scroll pa-2">
				<div class="date-card" v-for="(item, index) in 20" :key="index">
					<p>
						CT5号
					</p>
					<p>
						9：00--10：00
					</p>
					1/10
				</div>
			</div>
			<v-card-text>
				<v-radio-group row v-model="xman.needEscort" label="是否配送">
					<v-radio label="轮椅" value="1"></v-radio>
					<v-radio label="平车" value="2"></v-radio>
				</v-radio-group>
				<va-date-input source="endMenstruationDate" v-model="xman.endMenstruationDate" format="short"
					label="预约日期"></va-date-input>
			</v-card-text>
			<va-save-button></va-save-button>
		</v-card>
	</va-form>
</template>

<script>
import moment from "moment";
import 'moment/locale/zh-cn'; // 引入中文语言包
function getNextSevenDays() {
	const dates = [];
	let currentDate = moment(); // 获取当前日期

	for (let i = 0; i < 7; i++) {
		const dateString = currentDate.format('YYYY-MM-DD');
		const dayOfWeek = currentDate.format('dddd');
		dates.push({
			text: dateString,
			day: dayOfWeek
		});
		currentDate = currentDate.add(1, 'days'); // 增加一天
	}

	return dates;
}

export default {
	props: ["id", "title", "item"],
	data() {
		return {
			dates: getNextSevenDays(),
			xman: {
				needEscort: 0,
				endMenstruationDate: "",
				appointment_way_out_doctor: false,
				appointment_way_out_days: 0,
				appointment_way_in: false,
				appointment_way_in_doctor: false,
				appointment_way_in_days: 0,
				automatic_segmentation: false,
				release_resource: false,
				guide_print_out: false,
				guide_print_in: false,
				remark: "",
			},

			// ... 你的其他数据属性 ...
			menu: false,
			date: null, // 用于v-date-picker的模型
			time: null, // 用于v-time-picker的模型
			// ... 你的其他数据属性 ...
		};
	},
	methods: {
		async isSure() {
			if (this.id) {
				try {
					await this.$store
						.dispatch("ig_application_form/update", {
							id: this.id,
							data: this.xman,
						})
						.then((res) => {
							this.$emit("saved");
						});
				} catch {
					// this.$message({
					//   message: "医院编码违反唯一性，请修改",
					//   type: "error",
					// });
				}
			} else {
				await this.$store
					.dispatch("ig_application_form/create", {
						data: this.xman,
					})
					.then((res) => {
						this.$emit("saved");
						// if (this.file_data && this.file_data.length >= 1) {
						//   this.httpRequest(this.imgfile, res.data.id, res.data.logo);
						// }
					})
					.catch((err) => {
						// this.$message({
						//   message: "医院编码违反唯一性，请修改",
						//   type: "error",
						// });
					});
			}
		},
		close() {

		}
	}
};
</script>
<style scoped>
.image-guide {
	display: flex;
	justify-items: center;
	width: 100%;
	background: #fff;
	overflow-y: auto;
	height: 200px;
	align-items: flex-start;
}

.image-guide-img {
	/* max-width: 100%; */
	height: auto;
}
.print-btn{
	color: white;
	border-radius: 5px;
	background-color: #4caf50;
}
.date-scroll {
	overflow-x: auto
}

.date-card {
	flex-shrink: 0;
	padding: 2rem 0;

	width: 12rem;
	margin-right: 20px;
	background-color: rgb(180, 230, 236);
	align-items: center;
	text-align: center;
	border-radius: 10px;
}

.date-card p {
	margin-bottom: 5px !important
}
</style>