<template>
  <va-form :id="id" :item="item" @saved="$emit('saved')" ref="form">
    <v-card-text>
      <va-text-input
        label="分类编码"
        source="code_srvtp"
        required
      ></va-text-input>
      <va-text-input
        label="分类名称"
        source="name_srvtp"
        required
      ></va-text-input>
      <SelectInput
        source="parent_id"
        itemText="name_srvtp"
        reference="bs_inspection_type"
        label="上级分类"
        :items-per-page="1000"
        ref="dept"
      ></SelectInput>
      <va-boolean-input
        source="is_active"
        v-model="is_active"
        label="是否启用"
      ></va-boolean-input>
    </v-card-text>
    <div class="btn_bg">
      <v-btn @click="isSure" color="primary">保存</v-btn>
    </div>
  </va-form>
</template>

<script>
import SelectInput from "@/components/VaCustomSelectInput.vue";
import moment from "moment";
export default {
  props: ["id", "title", "item"],
  components: {
    SelectInput,
  },
  data() {
    return {
      is_active: true,
    };
  },
  methods: {
    isSure() {},
  },
};
</script>
<style scoped>
.btn_bg {
  width: 95%;
  position: fixed;
  bottom: 0px;
  padding: 10px 0;
  text-align: center;
  background-color: white;
}
</style>
