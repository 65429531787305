<template>
  <va-form :id="id" :item="item" @saved="$emit('saved')">
    <v-card-text style="padding-bottom: 80px;">
      <va-text-input
        source="drug_code"
        required
        v-model="form.drug_code"
      ></va-text-input>
      <va-text-input
        source="drug_name"
        required
        v-model="form.drug_name"
      ></va-text-input>
      <va-text-input
        source="standards"
        required
        v-model="form.standards"
      ></va-text-input>
      <va-text-input
        source="units"
        v-model="form.units"
        required
      ></va-text-input>
      <va-select-input
        required
        source="preparation_type_id"
        item-text="type_name"
        reference="bs_drug_preparation_type"
        :items-per-page="1000"
        :filter="{ is_active: true }"
        v-model="form.preparation_type_id"
      ></va-select-input>
      <va-select-input
        required
        source="drug_type_id"
        label="药品类型"
        item-text="name"
        reference="bs_drug_type"
        :items-per-page="1000"
        :filter="{ is_active: true }"
        v-model="form.drug_type_id"
      ></va-select-input>
      <va-select-input
        v-model="form.category_id"
        required
        source="category_id"
        reference="bs_drug_category"
        item-text="category_name"
        :items-per-page="1000"
        :filter="{ is_active: true }"
      ></va-select-input>
      <va-text-input
        required
        source="origin_place"
        v-model="form.origin_place"
      ></va-text-input>
      <va-text-input
        required
        source="manufacturer"
        v-model="form.manufacturer"
      ></va-text-input>
      <!-- <va-select-input source="category_id"></va-select-input>
      <va-select-input source="preparation_type_id"></va-select-input> -->
      <va-text-input
        required
        source="unit_dose"
        v-model="form.unit_dose"
      ></va-text-input>
      <va-text-input
        required
        source="measure_unit"
        v-model="form.measure_unit"
      ></va-text-input>
      <va-text-input
        required
        source="stock_left"
        v-model="form.stock_left"
      ></va-text-input>
      <va-text-input
        required
        source="stock_unit"
        v-model="form.stock_unit"
      ></va-text-input>
      <va-text-input required source="mic" v-model="form.mic"></va-text-input>
      <va-text-input
        required
        source="rcc_category"
        v-model="form.rcc_category"
      ></va-text-input>
      <va-text-input
        required
        source="hr_level"
        v-model="form.hr_level"
      ></va-text-input>
      <va-text-input
        required
        source="gb_code"
        v-model="form.gb_code"
      ></va-text-input>
      <va-text-input
        required
        source="gb_name"
        v-model="form.gb_name"
      ></va-text-input>
      <va-boolean-input
        source="is_essential"
        v-model="form.is_essential"
      ></va-boolean-input>
    </v-card-text>
    <div class="btn_bg">
      <!-- <va-save-button></va-save-button> -->
      <v-btn @click="isSure" color="primary">保存</v-btn>
    </div>
  </va-form>
</template>

<script>
export default {
  props: ["id", "title", "item"],
  data() {
    return {
      hospital: undefined,
      form: {
        drug_code: null,
        drug_name: null,
        standards: null,
        units: null,
        preparation_type_id: null,
        drug_type_id: null,
        category_id: null,
        origin_place: null,
        manufacturer: null,
        unit_dose: null,
        measure_unit: null,
        stock_left: null,
        stock_unit: null,
        mic: null,
        rcc_category: null,
        is_essential: true,
        hr_level: null,
        gb_code: null,
        gb_name: null,
        // price: null,
        // hospital_id: null,
      },
    };
  },
  methods: {
    async isSure() {
      if (
        !this.form.drug_code ||
        !this.form.drug_name ||
        !this.form.standards ||
        !this.form.units ||
        !this.form.preparation_type_id ||
        !this.form.drug_type_id ||
        !this.form.origin_place ||
        !this.form.manufacturer ||
        !this.form.unit_dose ||
        !this.form.measure_unit ||
        !this.form.stock_left ||
        !this.form.stock_unit ||
        !this.form.mic ||
        !this.form.rcc_category ||
        !this.form.hr_level ||
        !this.form.gb_code ||
        !this.form.gb_name ||
        // !this.form.price ||
        !this.form.category_id
      ) {
        this.$message({
          message: "有必填项未填写",
          type: "warning",
        });
        return;
      } else {
        if (!this.id) {
          try {
            await this.$store
              .dispatch("bs_drug_directory/create", {
                data: this.form,
              })
              .then((res) => {
                this.$emit("saved");
              });
          } catch {
            // this.$message({
            //   message: "药品编码违反唯一性，请修改",
            //   type: "error",
            // });
          }
        } else {
          try {
            await this.$store
              .dispatch("bs_drug_directory/update", {
                id: this.id,
                data: this.form,
              })
              .then((res) => {
                this.$emit("saved");
              });
          } catch {
            this.$message({
              message: "更新失败",
              type: "warning",
            });
          }
        }
      }
    },
  },
  mounted() {
    this.form.hospital_id = JSON.parse(
      window.sessionStorage.getItem("user")
    ).hospital;
  },
};
</script>
<style scoped>
.v-application--is-ltr .v-input__prepend-outer {
  display: none;
}
.btn_bg {
  width: 94% !important;
  position: fixed;
  bottom: 0px;
  padding: 10px 0;
  text-align: center;
  background-color: white;
}
</style>