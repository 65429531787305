<template>
		<va-show :item="item">
			<v-row>
				<v-col>
					<v-card>
						<v-card-text>

							<v-row>
								<v-col>
									<va-field source="name"></va-field>
								</v-col>
								<v-col>
									<va-field source="remark"></va-field>
								</v-col>
							</v-row>

							<v-row>
								<v-col>
									<va-field source="appointment_way_in" type="boolean"></va-field>
								</v-col>
								<v-col>
									<va-field source="appointment_way_in_days"></va-field>
								</v-col>
								<v-col>
									<va-field source="appointment_way_in_doctor" type="boolean"></va-field>
								</v-col>
							</v-row>

							<v-row>
								<v-col>
									<va-field source="appointment_way_out" type="boolean"></va-field>
								</v-col>
								<v-col>
									<va-field source="appointment_way_out_days"></va-field>
								</v-col>
								<v-col>
									<va-field source="appointment_way_out_doctor" type="boolean"></va-field>
								</v-col>
							</v-row>

							<v-row>
								<v-col>
									<va-field source="office_code"></va-field>
								</v-col>
								<v-col>
									<va-field source="office_name"></va-field>
								</v-col>
							</v-row>

							<v-row>
								<v-col>
									<va-field source="exec_office_code"></va-field>
								</v-col>
								<v-col>
									<va-field source="exec_office_name"></va-field>
								</v-col>
							</v-row>

							<v-row>
								<v-col>
									<va-field source="work_desc"></va-field>
								</v-col>
								<v-col>
									<va-field source="work_addr"></va-field>
								</v-col>
							</v-row>

							<v-row>
								<v-col>
									<va-field source="automatic_segmentation" type="boolean"></va-field>
								</v-col>
								<v-col>
									<va-field source="release_resource" type="boolean"></va-field>
								</v-col>
								<v-col>
									<va-field source="guide_print_out" type="boolean"></va-field>
								</v-col>
								<v-col>
									<va-field source="guide_print_in" type="boolean"></va-field>
								</v-col>
							</v-row>

						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
			<div class="text-center">
      <v-btn @click="notSure" style="color: #42b6c4; margin-right: 50px"
        >返回</v-btn
      >
    </div>
		</va-show>
</template>

<script>
	export default {
		props: ["title", "item"],
		methods: {
			notSure() {
			this.$router.push('/ig-service-group')
		},
		}
	};
</script>
<style scoped>
.text-center {
  width: 87%;
  background-color: #fff;
  height: 80px;
  position: fixed;
  bottom: 0px;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}</style>