<!--
 * @Description: 
 * @Author: your name
 * @Date: 2023-04-27 14:54:55
 * @LastEditors: your name
 * @LastEditTime: 2023-06-05 16:42:16
-->
<template>
  <va-form :id="id" :item="item" @saved="$emit('saved')" ref="form">
    <template v-slot:heading>
      <div class="display-2">
        {{ title }}
      </div>
    </template>
    <v-card-text style="padding-bottom: 80px">
      <va-select-input
        label="所属医院"
        item-value="codenum"
        reference="bs_hospital"
        :items-per-page="1000"
        source="hospital_code"
        v-model="form.hospital_code"
      ></va-select-input>
      <va-text-input
        label="病区编码"
        source="ward_code"
        v-model="form.ward_code"
      ></va-text-input>
      <va-text-input
        label="病区名称"
        source="name"
        v-model="form.name"
      ></va-text-input>
    </v-card-text>
    <div class="btn_bg">
      <v-btn @click="isSure" color="primary">保存</v-btn>
    </div>
  </va-form>
</template>

<script>
import moment from "moment";
export default {
  props: ["id", "title", "item"],
  data() {
    return {
      form: {
        hospital_code: "",
        hospital_name: "",
        ward_code: "",
        name: "",
        is_active: true,
      },
    };
  },
  methods: {
    isSure() {
      if (
        !this.form.hospital_code ||
        !this.form.hospital_name ||
        !this.form.ward_code ||
        !this.form.name
      ) {
        this.$message({
          message: "有必填项未填写",
          type: "warning",
        });
        return;
      }
      if (this.id) {
        this.$store
          .dispatch("hap_inpatient_ward/update", {
            id: this.id,
            data: this.form,
          })
          .then((res) => {
            this.$emit("saved");
          })
          .catch(() => {
            this.$message({
              type: "error",
              message: "病区编码违反唯一性，请修改",
            });
          });
      } else {
        this.$store
          .dispatch("hap_inpatient_ward/create", {
            data: this.form,
          })
          .then((res) => {
            this.$emit("saved");
          })
          .catch(() => {
            this.$message({
              type: "error",
              message: "病区编码违反唯一性，请修改",
            });
          });
      }
    },
  },
  watch: {
    "form.hospital_code": {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue) {
          this.$store
            .dispatch("bs_hospital/getList", {
              filter: {
                codenum: newValue,
              },
            })
            .then((res) => {
              this.form.hospital_name = res.data[0].name;
            });
        }
      },
    },
  },
};
</script>
<style scoped>
.btn_bg {
  width: 95%;
  position: fixed;
  bottom: 0px;
  padding: 10px 0;
  text-align: center;
  background-color: white;
}
</style>
