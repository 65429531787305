<template>
  <va-form :id="id" :item="item">
    <v-row justify="center">
      <v-col sm="6">
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2">
              {{ title }}
            </div>
          </template>
          <v-card-text>
            <va-text-input source="template_time_line_name"></va-text-input>
            <va-text-input source="template_time_line_code"></va-text-input>
            <va-text-input source="template_code"></va-text-input>
            <va-text-input source="template_name"></va-text-input>
            <va-text-input source="office_code"></va-text-input>
            <va-text-input source="office_name"></va-text-input>
            <va-text-input source="group_name"></va-text-input>
            <va-text-input source="group_id"></va-text-input>
            <va-text-input source="start_time"></va-text-input>
            <va-text-input source="end_time"></va-text-input>
            <va-text-input source="resource_no_start"></va-text-input>
            <va-text-input source="resource_no_end"></va-text-input>
            <va-text-input source="resource_count"></va-text-input>
            <va-text-input source="back_up_time_zone"></va-text-input>
            <va-text-input source="resource_quota"></va-text-input>
            <va-text-input source="appointment_item_way"></va-text-input>
            <va-text-input source="pre_apply_days"></va-text-input>
            <va-text-input source="create_time"></va-text-input>
            <va-text-input source="remark"></va-text-input>
          </v-card-text>
          <va-save-button></va-save-button>
        </base-material-card>
      </v-col>
    </v-row>
  </va-form>
</template>

<script>
export default {
  props: ["id", "title", "item"],
};
</script>
