<template>
  <div class="box">
    <v-card-text style="width: 97%; margin: 0 auto">
      <v-row>
        <ul style="padding: 0.5vw">
          <li>问诊详情</li>
          <li style="list-style: none; font-size: 10px; color: #999">
            BASIC INFORMATION
          </li>
        </ul>
      </v-row>
      <v-row>
        <div class="chat-massage-list">
          <message-list ref="message_list" base-size="5px"></message-list>
        </div>
        <div class="chat-massage-editor">
          <message-editor
            ref="messageEditor"
            base-size="5px"
            style="position: relative"
          >
            <div style="position: absolute; right: 0; z-index: 1">
              <i
                @mousedown.stop.prevent="showDeleteButton()"
                @mouseup.stop.prevent="clearLoop()"
                class="el-icon-mic"
                style="
                  margin: 1.2rem 1.2rem 0 0;
                  color: #777777;
                  font-size: 1.15em;
                "
              ></i>
              <i
                @click="call(1)"
                class="el-icon-phone-outline"
                style="
                  margin: 1.2rem 1.2rem 0 0;
                  color: #777777;
                  font-size: 1.15em;
                "
              ></i>
              <i
                @click="call(2)"
                class="el-icon-video-camera"
                style="
                  margin: 1.2rem 1.2rem 0 0;
                  color: #777777;
                  font-size: 1.15em;
                "
              ></i>
            </div>
          </message-editor>
        </div>
        <viewer
          ref="viewer"
          :images="imgArr"
          style="display: none"
          @inited="inited"
        >
          <img v-for="(src, index) in imgArr" :key="index" :src="src" />
        </viewer>
        <el-dialog :visible.sync="dialogVideo" width="50%">
          <video ref="videoPlayer" class="video-js"></video>
        </el-dialog>
      </v-row>
      <div
        class="callbox"
        v-show="isCallbox"
        style="display: flex; flex-direction: column; align-items: center"
      >
        <!-- 语音通话 -->
        <div v-show="!isVideo">
          <img
            style="
              width: 10rem;
              height: 10rem;
              border-radius: 50%;
              margin: 10rem 0 5rem;
            "
            :src="sender.portrait_uri"
          />
          <div style="margin: 5rem 0 8rem">
            <span v-if="showNumbers == 1"
              >正在呼叫{{ sender.user_name }}...</span
            >
            <span v-else-if="showNumbers == 2"
              >正在接听{{ sender.user_name }}...</span
            >
            <span v-else-if="showNumbers == 3"
              >{{ sender.user_name }}邀请通话...</span
            >
          </div>
        </div>
        <!-- 视频通话 -->
        <div v-show="isVideo">
          <div id="videoView"></div>
          <v-btn
            style="position: absolute; bottom: 5vh; left: 45%; z-index: 99"
            class="mx-2"
            fab
            dark
            color="red"
            @click="hangUp"
          >
            <v-icon dark>mdi-phone-off</v-icon>
          </v-btn>
        </div>
        <!-- 通话按钮 -->
        <div style="display: flex; width: 100%; justify-content: space-evenly">
          <v-btn
            class="mx-2"
            fab
            dark
            color="green"
            @click="accept"
            v-show="isAccept"
          >
            <v-icon dark>mdi-phone</v-icon>
          </v-btn>
          <v-btn class="mx-2" fab dark color="red" @click="hangUp">
            <v-icon dark>mdi-phone-off</v-icon>
          </v-btn>
        </div>
      </div>
    </v-card-text>
  </div>
</template>

<script>
import * as RongIMLib from "@rongcloud/imlib-next";
import { defineCustomElements, imkit } from "@rongcloud/imkit";
import { CoreEvent } from "@rongcloud/imkit";
import { ConversationType } from "@rongcloud/engine";
import { mapState, mapMutations } from "vuex";
import Utils from "../../global/utils";
// 视频播放页面
import videojs from "video.js";
// import videoPlayer from "@/components/videoPlay";
import Recorder from "js-audio-recorder";
const recorder = new Recorder({
  sampleBits: 16,
  sampleRate: 16000,
  numChannels: 1,
});
recorder.onprogress = function (params) {
  console.log("--------------START---------------");
  console.log("录音时长(秒)", params.duration);
  console.log("录音大小(字节)", params.fileSize);
  console.log("录音音量百分比(%)", params.vol);
  console.log("当前录音的总数据([DataView, DataView...])", params.data);

  // console.log('--------------END---------------')
};
export default {
  props: ["id", "health_card_id"],
  data() {
    return {
      userToken: null,
      targetId: null,
      sender: null,
      isCallbox: false,
      dialogVideo: false,
      session: null,
      dialog: false,
      imgSrc: null,
      imgArr: [],
      player: null,
      showNumbers: 1,
      isVideo: false,
      isAccept: true,
      callClient: null,
      videoType: "",
      // showWord: true,
      // errorDefaultImg: require('')
    };
  },
  beforeUnmount() {
    // 注意：需要 removeEventListener 防止多次绑定造成异常
    let messageList = this.$refs.message_list;
    messageList.removeEventListener("tapMessage", this.handleTapMessage);
  },
  mounted() {
    this.getToken();
    let that = this;
    Utils.$off("demo")
    Utils.$on("demo", function (msg) {
      that.getrichContent(msg.type, msg.content); //这里就是调用你的方法
    });
  },
  updated() {
    let messageList = this.$refs.message_list;
    //添加点击消息触发监听
    messageList.addEventListener("tapMessage", this.handleTapMessage);
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  methods: {
    ...mapMutations({
      //读取最新消息列表，并设置----------------------------重要
      getAnswer: "getAnswer",
      updataAnswer: "updataAnswer",
    }),
    // 点击开始录音
    showDeleteButton() {
      recorder.start().then(
        () => {
          this.$message({
            message: "录音中...",
            type: "",
          });
        },
        (error) => {
          // 出错了
          console.log(`${error.name} : ${error.message}`);
        }
      );
    },
    //  松开关闭录音
    clearLoop() {
      console.log("关闭");
      this.$message({
        message: "录音结束",
        type: "",
      });
      recorder.stop();
      // 获取录音的详细信息
      this.getRecorder();
    },
    getrichContent(type, content) {
      const richContent = imkit.registerMessageType(
        "kit:richContent",
        true,
        true
      );
      const message = new richContent({ content: content, title: type });
      const option = null;
      const conversation = {
        conversationType: ConversationType.PRIVATE,
        targetId: this.targetId,
      };
      imkit.sendMessage(conversation, message, option).then((res) => {
        console.info("发送消息结果：", res);
      });
    },
    getRecorder() {
      let that = this;
      let toltime = recorder.duration; //录音总时长
      // console.log("录音时长", toltime);
      let fileSize = recorder.fileSize; //录音总大小
      // console.log("录音总大小", fileSize);
      //录音结束，获取取录音数据
      let blob = recorder.getWAVBlob(); //获取 WAV 数据
      console.log(blob, "blob");
      let newbolb = new Blob([blob], { type: "audio/wav" });
      let fileOfBlob = new File([newbolb], new Date().getTime() + ".wav");
      const conversation = {
        conversationType: RongIMLib.ConversationType.PRIVATE,
        targetId: this.targetId,
      };
      const msgBody = {
        file: fileOfBlob, // 待上传文件
      };
      // const message = new PersonMessage({ key1: 'value1', key2: 'value2' })
      const hooks = {
        onProgress(progress) {}, // 上传进度监听，可选
        onComplete(fileInfo) {
          // 上传完成时的回调钩子，可选
          console.log(fileInfo.url); // 文件存储地址
          // 如果需要构建自定义消息，return new ABCMesssage('')
          // ABCMesssage 定义通过自定义消息实现 `const ABCMesssage = RongIMLib.registerMessageType(...)`
          // 无 return 返回值的情况下，SDK 默认发送 FileMessage
        },
      };
      const options = {
        contentDisposition: "attachment", // 文件链接在浏览器中展示形式（仅 aws、stc 上传有效） 'inline': 在浏览器中预览， 'attachment': 直接下载。如果不传，html 类型文件会预览，其他类型为直接下载
        // ... 其他配置项，可选
      };
      // const option = {
      //   contentDisposition: "attachment", // 文件链接在浏览器中展示形式（仅 aws、stc 上传有效） 'inline': 在浏览器中预览， 'attachment': 直接下载。如果不传，html 类型文件会预览，其他类型为直接下载
      //   // ... 其他配置项，可选
      // };
      RongIMLib.sendHQVoiceMessage(conversation, msgBody, hooks, options).then(
        ({ code, data: message }) => {
          if (code === 0) {
            // 发送成功
            this.$message({
              message: "发送语音成功",
              type: "",
            });
            // this.$parent.rowClicked(this.sender)
            // this.getAnswer(message);
            // this.$refs.messageList
            // console.log("发送语音成功");
          }
        }
      );
      //       imkit.sendMessage(conversation, message, option).then(res => {
      //   console.info('发送消息结果：',res);
      // })
    },
    // 初始化语音、视频聊天
    initCall() {
      let mediaType;
      let rtcClient = RongIMLib.installPlugin(RCRTC.installer);
      let callClient = RongIMLib.installPlugin(RCCall.installer, {
        rtcClient: rtcClient,
        onSession: (session) => {
          mediaType = session.getMediaType();
          this.registerCallSessionEvent(session);
          this.session = session;
          this.isCallbox = true;
          this.isAccept = true;
          this.showNumbers = 3;
          this.dialog = true;
          this.$message(`收到 ${session.getCallerId()} 的通话邀请`);
        },
        onSessionClose: (session, summary) => {
          this.hangUp();
          this.$message("通话已结束");
          document.getElementById("videoView").innerHTML = "";
          this.isVideo = false;
        },
      });
      this.callClient = callClient;
    },
    //初始化预览组件
    inited(viewer) {
      this.$viewer = viewer;
    },
    // 获取接收人
    getSender() {
      this.$admin.http
        .post(`tools-collection/get_rc_token/`, {
          rc_id: this.targetId,
        })
        .then((res) => {
          this.sender = res.data;
        });
    },
    // 实例化播放器
    createVideo(url) {
      let options = {
        autoplay: false,
        controls: true,
        sources: [
          {
            src: url,
          },
        ],
      };
      this.player = videojs(this.$refs.videoPlayer, options);
    },
    // 点击消息事件
    handleTapMessage(e) {
      console.log(e);
      const data = e.detail;
      // 处理点击查看大图或文件消息下载等功能
      if (data.type == "image") {
        this.imgSrc = data.url;
        this.imgArr = [];
        this.imgArr.push(data.url);
        this.$viewer.show();
      } else if (data.type === "file") {
        window.open(data.url);
        return;
      } else if (data.type === "sight") {
        // window.open(data.url);
        this.dialogVideo = true;
        this.createVideo(data.url);
      } else if (data.type === "hqvoice") {
        let audio_data = new Audio(data.url);
        // console.log(data.url);
        audio_data.play();
      } else if (data.type == "richContent") {
        console.log(1111111111111);
        console.log(data.url);
      }
    },
    // 获取im聊天token并连接
    getToken() {
      RongIMLib.disconnect().then(() => {
        console.log("成功断开");
      });
      this.$admin.http
        .post(`tools-collection/get_rc_token/`, {
          user_type: "doctor",
          user_id: this.user.doctor,
          name: this.user.name,
          portrait_uri: this.user.avatar_url,
        })
        .then((res) => {
          this.userToken = res.data;
          // 请替换真实 Token
          RongIMLib.connect(res.data.token).then((res) => {
            // 加载会话列表 CoreEvent 可通过 import { CoreEvent } from '@rongcloud/imkit' 获取
            imkit.emit(CoreEvent.CONVERSATION, true);
          });
          RongIMLib.connect(res.data.token).then((res) => {
            if (res.code === 0) {
              this.initCall();
            } else {
              console.warn("链接失败, code:", res.code);
            }
          });
        });
    },
    // 获取会话事件
    getCallSessionEvent() {
      return {
        onRinging: (sender) => {
          this.$message(` ${sender.userId} 正在振铃`);
        },
        onAccept: (sender) => {
          this.showNumbers = 2;
          this.$message(`${sender.userId} 已接听`);
        },
        onHungup: (sender) => {
          this.$message(`${sender.userId} 已挂断`);
          this.hangUp();
        },
        onTrackReady: (track) => {
          this.videoType = "AUDIO";
          if (track.isAudioTrack() && !track.isLocalTrack()) {
            track.play();
          }
          this.appendVideoEl(track);
          // 视频在对应的容器里播放
          if (track.isVideoTrack()) {
            this.videoType = "AUDIO_VIDEO";
            if (this.videoType == "AUDIO_VIDEO") {
              // 关闭框
              this.isVideo = true;
            }
            // Toast.success("通话已建立a");
          }
          // if (track.isAudioTrack() && !track.isLocalTrack()) {
          //   track.play();
          // }
          // this.appendVideoEl(track);
        },
      };
    },
    registerCallSessionEvent(session) {
      const events = this.getCallSessionEvent();
      session.registerSessionListener(events);
    },
    // 发起呼叫
    call(type) {
      this.isAccept = false;
      const { RCCallMediaType, RCCallErrorCode } = RCCall;
      const events = this.getCallSessionEvent();
      const isPrivateCall = ConversationType.PRIVATE;
      let mediaType;
      if (type == 1) {
        mediaType = RCCallMediaType.AUDIO;
        this.videoType = "AUDIO";
      } else {
        mediaType = RCCallMediaType.AUDIO_VIDEO;
        this.videoType = "AUDIO_VIDEO";
      }
      const params = {
        targetId: this.sender.rc_id,
        mediaType: mediaType,
        listener: events,
      };
      this.callClient.call(params).then(({ code, session }) => {
        if (code === RCCallErrorCode.SUCCESS) {
          this.registerCallSessionEvent(session);
          this.session = session;
          this.isCallbox = true;
          this.isVideo = false;
        } else {
          this.$message(`呼叫失败，错误原因：${code}`);
        }
      });
    },
    // 挂断
    hangUp() {
      this.session.hungup();
      this.showNumbers = 1;
      this.isCallbox = false;
      this.dialog = false;
      this.showNumbers = 1;
    },
    // 接听
    async accept() {
      const { RCCallMediaType, RCCallErrorCode } = RCCall;
      const { code } = await this.session.accept();
      if (code === RCCallErrorCode.SUCCESS) {
        this.isAccept = false;
        this.showNumbers = 2;
      }
    },
    appendVideoEl(track) {
      let container = document.getElementById("videoView");
      if (track.isAudioTrack()) {
        let uid = track.getUserId();
        let node = document.createElement("div");
        node.setAttribute("id", `video-${uid}`);
        let videoTpl = `
        <span class="video-user-id" style="position: absolute;left: 2px;font-size:16px;font-wight:500">ID: ${uid}</span>
        <video style="width:100%; height: 30vh;" id="${uid}"></video>`;
        node.innerHTML = videoTpl;
        node.classList = "video-item";
        container.appendChild(node);
        track.play();
      } else {
        const videoEl = document.getElementById(track.getUserId());
        track.play(videoEl);
      }
    },
  },
  watch: {
    health_card_id: {
      deep: true,
      immediate: true,
      handler(val) {
        console.log(val);
          imkit.selectConversation({
            conversationType: ConversationType.PRIVATE, // 会话类型
            targetId: `patient_${val}`,
          });
          this.targetId = `patient_${val}`;
          this.getSender();
      },
    },
  },
};
</script>

<style scoped>
.box {
  position: relative;
}
#videoView {
  width: 100%;
  height: 100%;
  position: absolute;
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 10px;
  top: 0;
  left: 0;
  z-index: 2;
}
.callbox {
  width: 100%;
  height: 100%;
  position: absolute;
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 10px;
  top: 0;
  left: 0;
  z-index: 2;
}
ul li::marker {
  color: #42b6c4;
}
.row + .row {
  margin-top: 0px;
}
.row > .col {
  padding: 0 12px !important;
}
.chat-conversation {
  float: left;
  width: 100%;
  height: 100%;
}
.chat-massage-list {
  width: 100%;
  height: 40vh;
  overflow-y: auto;
  margin-top: 15px;
}
.chat-massage-editor {
  width: 100%;
  height: 20vh;
  min-height: 150px;
  box-sizing: content-box;
}
.video-js /deep/ .vjs-big-play-button {
  top: 50% !important;
  left: 50%;
  margin-left: -1vw;
}
.video-js {
  width: 100%;
}
</style>