<template>
    <va-show :item="item">
      
            <template v-slot:heading>
              <div class="display-2">
                {{ title }}
              </div>
            </template>
            <v-card-text>
              <va-field source="name" ></va-field>
              <va-field source="is_active" type="boolean"></va-field>
              <va-field source="created_time" ></va-field>
            </v-card-text>
      
          
    </va-show>
</template>
<script>
export default {
  props: ["title", "item"],
};
</script>
