<template>
    <v-container>
        <div class="image-container" id="print-area" ref="printArea">
            <img :src="generatedImage" alt="Generated Image" v-if="generatedImage" />
        </div>
        <v-row>
            <v-col cols="12">
                <v-btn @click="printImage" :disabled="!canPrint" :class="{ 'active-print-btn': canPrint }" color="success">打印图片</v-btn>
                <span style="color: gray;">* 预约后才能打印</span>
            </v-col>
            <v-col cols="12" style="display: none;">
                <canvas ref="canvas" width="800" height="1000" style="border:1px solid #000000;"></canvas>
            </v-col>
        </v-row>
        <v-tabs style="margin-top: 10px;">
            <v-tab v-for="(item, index) in dates" :key="index" @click="fetchSchedule(item.text)">{{ `${item.text} (${item.day})` }}</v-tab>
        </v-tabs>
        <h2 class="pa-2">上午</h2>
        <div class="d-flex flex-nowrap date-scroll pa-2">
            <div class="date-card" :class="active == 'am' + index ? 'active' : ''"
                v-for="(schedule, index) in amSchedule" :key="index" @click="selectSchedule(schedule, 'am', index)">
                <p>{{ schedule.group_name }}</p>
                <p>{{ schedule.time_line_name }}</p>
                {{ schedule.used_count }}/{{ schedule.resource_count }}
            </div>
        </div>
        <br />
        <h2 class="pa-2">下午</h2>
        <div class="d-flex flex-nowrap date-scroll pa-2">
            <div class="date-card" :class="active == 'pm' + index ? 'active' : ''"
                v-for="(schedule, index) in pmSchedule" :key="index" @click="selectSchedule(schedule, 'pm', index)">
                <p>{{ schedule.group_name }}</p>
                <p>{{ schedule.time_line_name }}</p>
                {{ schedule.used_count }}/{{ schedule.resource_count }}
            </div>
        </div>
        <v-card-text>
            <v-row>
                <v-col sm="5">
                    <v-radio-group row v-model="needEscort" label="是否陪送" :disabled="isSaved">
                        <v-radio label="轮椅" value="1"></v-radio>
                        <v-radio label="平车" value="2"></v-radio>
                    </v-radio-group>
                </v-col>
                <v-col sm="4" v-if="xman.sexName !== '男'">
                    <el-date-picker class="select" label="末次月经时间" :picker-options="pickerOptions" size="large"
                        v-model="xman.endMenstruationDate" type="date" placeholder="末次月经时间" :disabled="isSaved">
                    </el-date-picker>
                </v-col>
                <v-col sm="3">
                    <v-btn v-if="!isSaved" class="print-btn" text @click="isSure" :disabled="!canSave" :style="{ backgroundColor: canSave ? '#4caf50' : '#e0e0e0' }">
                        保存
                    </v-btn>
                    <v-btn v-if="isSaved" disabled>已预约</v-btn>
                </v-col>
            </v-row>
        </v-card-text>
    </v-container>
</template>

<script>
import JsBarcode from 'jsbarcode';
import moment from "moment";
import 'moment/locale/zh-cn'; // 引入中文语言包

function getNextSevenDays() {
    const dates = [];
    let currentDate = moment(); // 获取当前日期

    for (let i = 0; i < 7; i++) {
        const dateString = currentDate.format('YYYY-MM-DD');
        const dayOfWeek = currentDate.format('dddd');
        dates.push({
            text: dateString,
            day: dayOfWeek
        });
        currentDate = currentDate.add(1, 'days'); // 增加一天
    }

    return dates;
}

export default {
    data() {
        return {
            userInput: '',
            apiData: '',
            generatedImage: '',
            dates: getNextSevenDays(),
            day_time_line_id: null,
            amSchedule: [],
            pmSchedule: [],
            canPrint: false, // 控制打印按钮的可用状态
            canSave: false, // 控制保存按钮的可用状态
            isSaved: false, // 控制是否显示已预约按钮
            // 初始化数据模型
            patientName: '',
            sexName: '',
            idReq: '',
            idReqName: '',
            reqQr: 0, // 指引单号
            reqNo: 0, // 排号
            reqDocDepartName: '',
            reqDocName: '',
            reqDatetime: '',
            needEscort: '',
            wattingDatetime: "", // 待检时间
            time_line_name: "",
            exec_office_name: "",
            work_addr: "",

            active: 'am0',
            items: [],
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now() - 8.64e7;
                },
            },
            xman: {
                patientName: null,
                patientLid: null,
                visitTime: null,
                visitNo: null,
                visitOrdNo: null,
                age: null,
                sexName: null,
                needEscort: null,
                phoneNo: null,
                mailingAddress: null,
                bedNo: null,
                reqExecStatusName: null,
                reqExecStatus: null,
                reqNo: null,
                reqQr: null,
                visitTypeCode: null,
                visitTypeName: null,
                visitOrdNo: null,
                resourceGroupName: null,
                reqDocCode: null,
                reqDocName: null,
                reqDatetime: null,
                reqTime: null,
                requestNo: null,
                orderLid: null,
                deptCode: null,
                deptName: null,
                day_time_line_id: null,
                exeDeptCode: null,
                exeDeptName: null,
                orgCode: null,
                orgName: null,
                orderLid: null,
                cardID: null,
                medicalSerialNo: null,
                item_code: null,
                item_name: null,
                time_line_name: null,
                mainDiagnosis: null,
                chiefComplaint: null,
                presentHistory: null,
                allergicHistory: null,
            },
        };
    },
    mounted() {
        this.fetchQuery();
    },
    watch: {
        active() {
            this.updateCanSave();
        },
        needEscort() {
            this.updateCanSave();
        },
        userInput: 'generateImage',
        apiData: 'generateImage',
        needEscort: 'generateImage', // 监听 needEscort 变化

    },
    methods: {
        updateCanSave() {
            this.canSave = !!this.active; // 只要选择了上午或下午的卡片就激活
        },
        async fetchReqQr() {
            try {
                const response = await this.$admin.http.get(
                    `/application_form/applicationform/generate_unique_reqQr/?visitTypeCode=${this.xman.visitTypeCode}`
                );
                this.reqQr = response.data.reqQr;
                await this.fetchSchedule(this.dates[0].text)
                this.generateImage(); // 获取到 reqQr 后生成图片
            } catch (error) {
                console.error('Error fetching reqQr:', error);
            }
        },
        async fetchSchedule(date) {
            try {
                const itemCode = this.xman.item_code;
				const isInpatient = ["03", "05"].includes(this.xman.visitTypeCode);
				console.log('999 fetchSchedule ', isInpatient);
				// 就诊类型treatment_type【0门诊、1住院】、
				// 预约人类型appointment_maker【0医生、1分诊台】
                const response = await this.$admin.http.get(`/item/itembindinggroup/item-schedule-day?date=${date}&item_code=${itemCode}&treatment_type=${isInpatient ? 1 : 0}&appointment_maker=0`);
                const data = response.data;
                this.amSchedule = data.am;
                if (this.amSchedule?.length > 0) {
                    this.selectSchedule(this.amSchedule[0])
                }

                this.pmSchedule = data.pm;
            } catch (error) {
                console.error('Error fetching schedule:', error);
            }
        },
        selectSchedule(schedule, time, index) {
            this.active = time + index;
            this.reqNo = schedule.reqNo;
            this.reqTime = schedule.req_datetime;
            this.wattingDatetime = moment(schedule.req_datetime).format('YYYY-MM-DD') + ' ' + schedule.time_line_name + ' (北京时间)';
            this.day_time_line_id = schedule.day_time_line_id;
            this.time_line_name = schedule.time_line_name;
            this.exec_office_name = schedule.exec_office_name;
            this.work_addr = schedule.work_addr;
            this.items = [schedule.items];
            this.generateImage();
        },
        fetchApiData() {
            // 模拟从接口获取数据
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve('接口数据');
                }, 1000);
            });
        },
        async generateImage() {
            console.log('in image generate.');
            // 获取接口数据
            this.apiData = await this.fetchApiData();

            // 获取 canvas 上下文
            const canvas = this.$refs.canvas;
            const context = canvas.getContext('2d');

            // 清除之前的内容
            context.clearRect(0, 0, canvas.width, canvas.height);

            // 绘制矩形背景
            context.fillStyle = "#FFFFFF";
            context.fillRect(0, 0, canvas.width, canvas.height);

            // 设置通用文本样式
            context.font = "16px Arial";
            context.fillStyle = "#000000";

            // 根据visitTypeCode决定生成哪种指引单
            const isInpatient = ["03", "05"].includes(this.xman.visitTypeCode);
            console.log('666 isInpatient ', isInpatient);
            isInpatient ? this.generateInpatientGuide(context) : this.generateOutpatientGuide(context);

            // 生成图片
            this.generatedImage = canvas.toDataURL("image/png");
        },

        // 门诊
        generateOutpatientGuide(context) {
            this.drawTitle(context, "检查预约指引单（门诊）");
            this.drawBarcode(context, this.reqQr, 70, 60);

            const escortWay = this.getEscortWay(); // 获取当前的陪送方式

            const textItems = [
                { text: `姓名: ${this.xman.patientName}`, x: 20, y: 180, font: "16px Arial" },
                { text: `性别: ${this.xman.sexName}`, x: 20, y: 205 },
                { text: `年龄: ${this.xman.age}岁`, x: 20, y: 230 },
                { text: `科室: ${this.xman.deptName}`, x: 20, y: 255 },
                { text: `预约号: `, x: 20, y: 280 },
                { text: this.reqNo, x: 100, y: 280, font: "bold 18px Arial" },
                { text: `候检时间: `, x: 20, y: 305 },
                { text: this.wattingDatetime, x: 100, y: 305, font: "bold 18px Arial" },
                { text: `候检地点: ${this.work_addr}`, x: 20, y: 330 },
                { text: `检查诊室: ${this.exec_office_name}`, x: 20, y: 355 },
                { text: `检查项目: ${this.items[0]?.item_name || '-'}`, x: 20, y: 380 },
                { text: `申请医生: ${this.xman.reqDocName}`, x: 20, y: 405 },
                { text: `诊断: ${this.xman.mainDiagnosis}`, x: 20, y: 430 },
                { text: `病史: ${this.xman.presentHistory}`, x: 20, y: 455 },
                { text: `陪送方式: ${escortWay}`, x: 20, y: 480 },
                { text: "***过号请重新预约***", x: 20, y: 505 },
                { text: "申请单及撤费单到超声医学科", x: 20, y: 530 },
                { text: " 分诊台登记报道。", x: 20, y: 555 },
                { text: "如未按时报道者(迟到30分钟)视为", x: 20, y: 580 },
                { text: "自动弃号，请重新改约时间!", x: 20, y: 605 },
                { text: "看清楚预约时间按时检查，谢谢配合!!", x: 20, y: 630 },
                { text: "诊患者2小时内未缴费，自动取消预约。", x: 20, y: 655 },
                { text: "(1)肝、胆、脾、胰、需空腹检查、", x: 20, y: 680 },
                { text: "   前一日晚餐忌食油腻，当日晨禁食水。", x: 20, y: 705 },
                { text: "(2)膀胱、前列腺、子宫、附件、早孕应憋尿后", x: 20, y: 730 },
                { text: "  检查，如无尿，应大量饮水待憋尿后再检查。", x: 20, y: 755 },
                { text: "(3)其他脏器以及心脏彩超、颅脑彩超及小器官", x: 20, y: 780 },
                { text: "  检查无需特殊准备。", x: 20, y: 805 },
                { text: "(4)阴道超声检查:无需膀胱充盈，患者本人有", x: 20, y: 830 },
                { text: "  性生活史并且自愿检查。", x: 20, y: 855 },
                // { text: "  地址:1号楼3楼超声医学科", x: 20, y: 880 },
            ];

            this.drawTextItems(context, textItems);
        },

        // 住院
        generateInpatientGuide(context) {
            this.drawInTitle(context, "检查预约指引单（住院）");
            this.drawBarcode(context, this.reqQr, 50, 70);

            const escortWay = this.getEscortWay(); // 获取当前的陪送方式

            const textItems = [
                { text: `住院号: `, x: 550, y: 100, font: "16px Arial" },
                { text: `${this.reqQr}`, x: 550, y: 125 },
                { text: `姓名: ${this.xman.patientName}`, x: 50, y: 205, font: "16px Arial" },
                { text: `性别: ${this.xman.sexName}`, x: 450, y: 205 },
                { text: `年龄: ${this.xman.age}岁`, x: 560, y: 205 },
                { text: `科室: ${this.xman.deptName}`, x: 50, y: 230 },
                { text: `预约号: `, x: 50, y: 255, font: "16px Arial" },
                { text: this.reqNo, x: 120, y: 255, font: "bold 18px Arial" },
                { text: `候检时间: `, x: 50, y: 280, font: "16px Arial" },
                { text: this.wattingDatetime, x: 120, y: 280, font: "bold 18px Arial" },
                { text: `候检地点: ${this.work_addr}`, x: 50, y: 305 },
                { text: `检查诊室: ${this.exec_office_name}`, x: 50, y: 330 },
                { text: `检查项目: ${this.items[0]?.item_name || '-'}`, x: 50, y: 355 },
                { text: `申请医生: ${this.xman.reqDocName}`, x: 50, y: 380 },
                { text: `诊断: ${this.xman.mainDiagnosis}`, x: 50, y: 405 },
                { text: `病史: ${this.xman.presentHistory}`, x: 50, y: 430 },
                { text: `陪送方式: ${escortWay}`, x: 50, y: 455 },
                { text: "***过号请重新预约***", x: 50, y: 480 },
                // { text: "*注意事项:请于【促检时间】提前15分钟携带申请单及撤费单到超声医学科分诊台登记报道。", x: 50, y: 555 },
                // { text: "如未按时报道者(迟到30分钟)视为自动弃号，请重新改约时间!因疫情防控要求请各位患者看清楚预约时间按时检查，谢谢配合!!", x: 50, y: 580 },
                // { text: "诊患者2小时内未缴费，自动取消预约。", x: 50, y: 605 },
                // { text: "(1)肝、胆、脾、胰、需空腹检查、前一日晚餐忌食油腻，当日晨禁食水。", x: 50, y: 630 },
                // { text: "(2)膀胱、前列腺、子宫、附件、早孕应憋尿后检查，如无尿，应大量饮水待憋尿后再检查。", x: 50, y: 655 },
                // { text: "(3)其他脏器以及心脏彩超、颅脑彩超及小器官检查无需特殊准备。", x: 50, y: 680 },
                // { text: "(4)阴道超声检查:无需膀胱充盈，患者本人有性生活史并且自愿检查。地址:1号楼3楼超声医学科", x: 50, y: 705 },
            ];

            this.drawTextItems(context, textItems);
        },

        drawTitle(context, title) {
            context.font = "bold 24px Arial";
            context.fillText(title, 80, 50); // 将标题向左移动60像素
        },

        drawInTitle(context, title) {
            context.font = "bold 24px Arial";
            context.fillText(title, 280, 50); // 将标题向左移动60像素
        },

        drawBarcode(context, data, x, y) {
            const barcodeCanvas = document.createElement('canvas');
            JsBarcode(barcodeCanvas, data, {
                format: 'CODE128',
                displayValue: true,
                height: 50,
                fontSize: 20
            });
            context.drawImage(barcodeCanvas, x, y);
        },

        drawTextItems(context, textItems) {
            textItems.forEach(({ text, x, y, font }) => {
                if (font) context.font = font;
                context.fillText(text, x, y);
                context.font = "16px Arial"; // reset font
            });
        },

        getEscortWay() {
            switch (this.needEscort) {
                case "1":
                    return '轮椅';
                case "2":
                    return '平车';
                default:
                    return '-';
            }
        },

        async fetchQuery() {
            try {
                const query = this.$route.query;
                this.xman.patientName = query.patientName;
                this.xman.age = query.age;
                this.xman.visitNo = query.visitNo;
                this.xman.visitOrdNo = query.visitOrdNo;
                this.xman.visitTypeCode = query.visitTypeCode;
                this.xman.visitTypeName = query.visitTypeName;
                this.xman.sexName = query.sexName;
                this.xman.phoneNo = query.phoneNo;
                this.xman.mailingAddress = query.mailingAddress;
                this.xman.bedNo = query.bedNo;
                this.xman.deptName = query.deptName;
                this.xman.deptCode = query.deptCode;
                this.xman.medicalTypeName = query.medicalTypeName;
                this.xman.medicalListSerialNo = query.medicalListSerialNo;
                this.xman.resourceGroupName = query.resourceGroupName;
                this.xman.requestNo = query.requestNo;
                this.xman.reqDocName = query.reqDocName;
                this.xman.reqDocCode = query.reqDocCode;
                this.xman.reqDatetime = query.reqDatetime;
                this.xman.reqDocDepartName = query.reqDocDepartName;
                this.xman.cardID = query.cardID;
                this.xman.medicalSerialNo = query.medicalSerialNo;
                this.xman.hospitalCardNo = query.hospitalCardNo;
                this.xman.item_code = query.item_code;
                this.xman.item_name = query.item_name;

                this.xman.orderLid = query.orderLid;
                this.xman.orgCode = query.orgCode;
                this.xman.orgName = query.orgName;
                this.xman.patientLid = query.patientLid;

                this.xman.mainDiagnosis = query.mainDiagnosis;
                this.xman.chiefComplaint = query.chiefComplaint;
                this.xman.presentHistory = query.presentHistory;
                this.xman.allergicHistory = query.allergicHistory;
                // 为 xman 赋值后，获取 reqQr
                this.fetchReqQr();
            } catch (error) {
                console.error('Error fetching items:', error);
            }
        },

        notSure() {
            this.$router.push(
                "/" //跳转
            );
        },

        async isSure() {
            if (!this.needEscort) {
                this.$message.error('请选择是否陪送')
                return false
            }

            this.xman.needEscort = this.needEscort;
            this.xman.reqExecStatusName = "已预约";
            this.xman.reqExecStatus = 1;
            this.xman.reqNo = this.reqNo;
            this.xman.reqQr = this.reqQr;
            this.xman.reqTime = this.reqTime;
            this.xman.day_time_line_id = this.day_time_line_id;
            this.xman.time_line_name = this.time_line_name;
            this.xman.exec_office_name = this.exec_office_name;
            this.xman.work_addr = this.work_addr;

            await this.$admin.http.post("/application_form/applicationform/", this.xman);
            this.isSaved = true; // 设置为已保存状态
            this.canPrint = true; // 激活打印按钮
            this.$message({
                message: "预约成功！",
                type: "success"
            });
        },

        printImage() {
            if (this.generatedImage) {
                const printArea = document.getElementById('print-area').innerHTML;
                const originalContents = document.body.innerHTML;
                document.body.innerHTML = printArea;
                window.print();
                setTimeout(() => {
                    document.body.innerHTML = originalContents;
                }, 1000);
            } else {
                alert('请先生成图片');
            }
        }
    },
}
</script>

<style scoped>
.image-guide {
    display: flex;
    justify-items: center;
    width: 100%;
    background: #fff;
    overflow-y: auto;
    height: 200px;
    align-items: flex-start;
}

.image-guide-img {
    max-width: 80%;
    height: auto;
}

.image-container {
    max-height: 300px;
    /* 可调整高度 */
    overflow-y: auto;
    /* 设置垂直滚动条 */
}

img {
    width: 100%;
    /* 图片宽度充满容器 */
}

.print-btn {
    color: white;
    border-radius: 5px;
    background-color: #4caf50;
}

.active-print-btn {
    background-color: #4caf50 !important;
}

.date-scroll {
    overflow-x: auto
}

.date-card {
    flex-shrink: 0;
    padding: 2rem 0;
    cursor: pointer;

    width: 12rem;
    margin-right: 20px;
    background-color: rgb(180, 230, 236);
    align-items: center;
    text-align: center;
    border-radius: 10px;
}

.date-card.active {
   	background-color: rgb(100, 230, 236);
   	box-shadow: 0 20px 13px -6px rgba(180, 230, 236, .5)
}

.date-card p {
    margin-bottom: 5px !important
}

.v-container {
    width: 100%;
    /* 确保容器宽度为100% */
    max-width: 100%;
    /* 防止超过浏览器宽度 */
}

.date-scroll {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    width: 100%;
    /* 确保滚动视图宽度为100% */
}

.date-card {
    flex: 0 0 auto;
    /* 确保卡片不会被拉伸或压缩 */
    padding: 2rem;
    width: 12rem;
    /* 卡片宽度固定，但可以根据实际需要调整 */
    margin-right: 20px;
    /* 间隔 */
    background-color: rgb(180, 230, 236);
    text-align: center;
    border-radius: 10px;
}
</style>
