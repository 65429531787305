<template>
  <div>
    <v-card>
      <v-card-text style="width: 97%; margin: 0 auto">
        <v-row>
          <ul style="padding: 0.5vw">
            <li>新建{{ title }}</li>
            <li style="list-style: none; font-size: 10px; color: #999">
              OUTPATIENT REGISTRATION
            </li>
          </ul>
        </v-row>
        <v-row style="margin: 15px 0">
          <v-col>
            <el-input v-model="input" placeholder="请输入患者姓名"></el-input>
          </v-col>
          <v-col>
            <el-input
              v-model="idNumber"
              placeholder="请输入身份证号"
            ></el-input>
          </v-col>
          <v-col>
            <el-input v-model="gender" placeholder="请选择性别"></el-input>
          </v-col>
          <v-col>
            <el-input v-model="age" placeholder="请输入患者年龄"></el-input>
          </v-col>
        </v-row>
        <v-row style="margin: 15px 0">
          <v-col>
            <el-date-picker
              v-model="dateOfBirth"
              placeholder="请选择出生日期"
            ></el-date-picker>
          </v-col>
          <v-col>
            <el-select v-model="input" placeholder="病员类型"></el-select>
          </v-col>
          <v-col>
            <el-input v-model="input" placeholder="请输入职业"></el-input>
          </v-col>
          <v-col>
            <el-input v-model="input" placeholder="请输入联系电话"></el-input>
          </v-col>
        </v-row>
        <v-row style="margin: 15px 0">
          <v-col>
            <el-input v-model="input" placeholder="请输入联系人"></el-input>
          </v-col>
          <v-col>
            <el-input v-model="input" placeholder="请输入联系人关系"></el-input>
          </v-col>
          <v-col cols="6">
            <el-input v-model="input" placeholder="请输入联系地址"></el-input>
          </v-col>
        </v-row>
        <template v-if="title == '普通挂号'">
          <v-row style="margin: 15px 0">
            <v-col>
              <el-date-picker
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                v-model="work_date"
                type="date"
                placeholder="选择预约日期"
                style="width: 100%"
                clearable
              >
              </el-date-picker>
            </v-col>
            <v-col>
              <el-select
                v-model="hospital"
                filterable
                placeholder="请选择医院"
                clearable
              >
                <el-option
                  v-for="item in hospital_data"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </v-col>
            <v-col>
              <el-select
                v-model="office"
                filterable
                placeholder="请选择科室"
                clearable
                :disabled="hospital && work_date ? false : true"
              >
                <el-option
                  v-for="item in office_data"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </v-col>
            <v-col>
              <el-select
                clearable
                v-model="doctor"
                filterable
                placeholder="请选择医生"
                :disabled="hospital && work_date && office ? false : true"
              >
                <el-option
                  v-for="item in doctor_data"
                  :key="item.id"
                  :label="`${item.name}--${item.position_name}`"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </v-col>
          </v-row>
          <v-row style="margin: 15px 0">
            <v-col>
              <el-input
                v-model="cost"
                placeholder="请填写挂号费用"
                disabled
              ></el-input>
            </v-col>
            <v-col>
              <el-input
                v-model="time_data"
                placeholder="请选择时段"
                disabled
              ></el-input>
            </v-col>
            <v-col></v-col>
            <v-col></v-col> </v-row
        ></template>
        <template v-else>
          <v-row style="margin: 15px 0">
            <v-col>
              <el-select
                v-model="hospital"
                filterable
                placeholder="请选择医院"
                clearable
              >
                <el-option
                  v-for="item in hospital_data"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </v-col>
            <v-col>
              <el-select
                v-model="office"
                filterable
                placeholder="请选择科室"
                clearable
                :disabled="hospital && work_date ? false : true"
              >
                <el-option
                  v-for="item in office_data"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </v-col>
            <v-col>
              <el-select
                clearable
                v-model="doctor"
                filterable
                placeholder="请选择医生"
                :disabled="hospital && work_date && office ? false : true"
              >
                <el-option
                  v-for="item in doctor_data"
                  :key="item.id"
                  :label="`${item.name}--${item.position_name}`"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </v-col>
            <v-col>
              <el-input
                v-model="cost"
                placeholder="请填写挂号费用"
                disabled
              ></el-input>
            </v-col>
          </v-row>
        </template>
      </v-card-text>
    </v-card>
    <el-table
      :data="detail"
      height="300"
      style="width: 100%; margin-bottom: 80px"
      @current-change="handleCurrentChange"
      v-if="title == '普通挂号'"
    >
      <el-table-column label="医生姓名" prop="name" align="center">
      </el-table-column>
      <el-table-column label="职级" prop="name" align="center">
      </el-table-column>
      <el-table-column label="限号" prop="num_count" align="center">
      </el-table-column>
      <el-table-column label="余号" prop="left_num" align="center">
      </el-table-column>
      <el-table-column label="挂号费用（元）" prop="doctor_fee" align="center">
      </el-table-column>
      <el-table-column label="时段开始时间" prop="start_time" align="center">
      </el-table-column>
      <el-table-column label="时段结束时间" prop="end_time" align="center">
      </el-table-column>
    </el-table>
    <el-table
      :data="detail"
      height="300"
      style="width: 100%; margin-bottom: 80px"
      @current-change="handleCurrentChange"
      v-else
    >
      <el-table-column label="医院名称" prop="name" align="center">
      </el-table-column>
      <el-table-column label="科室名称" prop="name" align="center">
      </el-table-column>
      <el-table-column label="诊室名称" prop="num_count" align="center">
      </el-table-column>
      <el-table-column label="挂号费用（元）" prop="doctor_fee" align="center">
      </el-table-column>
    </el-table>
    <el-dialog
      title="交费详情"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form :model="form">
        <v-row style="margin: 0px 0 15px">
          <v-col>
            <el-input
              v-model="form.name"
              placeholder="请填写挂号费用"
            ></el-input>
          </v-col>
          <v-col>
            <el-input
              v-model="form.name"
              placeholder="请填写账户余额"
            ></el-input>
          </v-col>
        </v-row>
        <hr />
        <v-row style="margin: 15px 0">
          <v-col>
            <el-select v-model="form.region" placeholder="请选择病员费别">
              <el-option
                v-for="(item, index) in fee"
                :key="index"
                :label="fee.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </v-col>
          <v-col>
            <el-input
              v-model="form.name"
              placeholder="请填写现金支付金额"
            ></el-input>
          </v-col>
          <v-col>
            <el-input
              v-model="form.name"
              placeholder="请填写医保支付金额"
            ></el-input>
          </v-col>
        </v-row>
        <v-row style="margin: 15px 0">
          <v-col>
            <el-input
              v-model="form.name"
              placeholder="请填写健康卡支付金额"
            ></el-input>
          </v-col>
          <v-col>
            <el-input
              v-model="form.name"
              placeholder="请填写银联支付金额"
            ></el-input>
          </v-col>
          <v-col>
            <el-select v-model="form.region" placeholder="请选择移动支付">
              <el-option label="微信" value="1"></el-option>
              <el-option label="支付宝" value="2"></el-option>
            </el-select>
          </v-col>
        </v-row>
        <hr />
        <v-row style="margin: 15px 0">
          <v-col>
            <el-input
              v-model="form.name"
              placeholder="请填写应收金额"
            ></el-input>
          </v-col>
          <v-col>
            <el-input
              v-model="form.name"
              placeholder="请填写实收金额"
            ></el-input>
          </v-col>
          <v-col>
            <el-input
              v-model="form.name"
              placeholder="请填写找零金额"
            ></el-input>
          </v-col>
        </v-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-checkbox v-model="checked" style="margin-right: 50px"
          >打印发票</el-checkbox
        >
        <el-button @click="dialogFormVisible = false">退出支付</el-button>
        <el-button type="primary" @click="payment">确认支付</el-button>
      </div>
    </el-dialog>

    <div class="text-center">
      <v-btn @click="close" style="color: #42b6c4; margin-right: 50px"
        >取消</v-btn
      >
      <v-btn
        @click="settlement"
        style="background-color: #42b6c4; margin-right: 50px"
        >结算</v-btn
      >
      <v-btn
        @click="isSure"
        style="background-color: #42b6c4; margin-right: 50px"
        >确认</v-btn
      >
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: ["data"],
  data() {
    return {
      dialogFormVisible: false,
      input: null,
      work_date: moment().format("YYYY-MM-DD"),
      title: null,
      form: {},
      doctor: null,
      office: null,
      hospital: null,
      hospital_data: null,
      office_data: null,
      doctor_data: null,
      time_data: null,
      detail: null,
      cost: null,
      idNumber: null,
      age: null,
      dateOfBirth: null,
      gender: null,
    };
  },
  methods: {
    handleCurrentChange(val) {
      this.time_data = val.name;
      this.dw_id = val.id;
    },
    getClass() {
      this.$admin.http.get(`base-system/hospitals/`).then((data) => {
        this.hospital_data = data.data.results;
      });
    },
    // 取消
    close() {
      this.$router.push("/apt-register/");
    },
    isSure() {},
    settlement() {
      this.dialogFormVisible = true;
    },
    payment() {
      this.$prompt("请输入支付密码", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^[1-9]\d{5}$/,
        inputType: "password",
        inputErrorMessage: "支付密码格式不正确",
        closeOnClickModal: false,
        closeOnPressEscape: false,
        closeOnHashChange: false,
      })
        .then(({ value }) => {
          this.$message({
            type: "success",
            message: "你的密码是: " + value,
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },
  },
  mounted() {
    this.title = this.$route.query.data;
  },
  created() {
    this.user = JSON.parse(window.sessionStorage.getItem("user"));
    this.getClass();
  },
  watch: {
    idNumber: {
      deep: true,
      immediate: true,
      handler(val) {
        const reg =
          /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
        if (reg.test(val)) {
          var org_birthday = val.substring(6, 14);
          var org_gender = val.substring(16, 17);
          var sex = org_gender % 2 == 1 ? "男" : "女";
          var birthday =
            org_birthday.substring(0, 4) +
            "-" +
            org_birthday.substring(4, 6) +
            "-" +
            org_birthday.substring(6, 8);
          var birthdays = new Date(birthday.replace(/-/g, "/"));
          let d = new Date();
          let age =
            d.getFullYear() -
            birthdays.getFullYear() -
            (d.getMonth() < birthdays.getMonth() ||
            (d.getMonth() == birthdays.getMonth() &&
              d.getDate() < birthdays.getDate())
              ? 1
              : 0);
          this.gender = sex;
          this.dateOfBirth = birthdays;
          this.age = age;
        } else {
          this.form.sex = "未填写";
          return false;
        }
      },
    },
    work_date: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
          this.hospital = null;
          this.office = null;
          this.doctor = null;
          this.position = null;
          this.detail = null;
          this.cost = null;
        }
      },
    },
    hospital: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val !== null && this.work_date) {
          this.office = null;
          this.doctor = null;
          this.time_data = null;
          this.position = null;
          this.detail = null;
          this.cost = null;
          if (this.params) {
            this.$store
              .dispatch("bs_hospital/getOne", {
                id: val,
              })
              .then((res) => {
                this.getjzk(this.params.ehc_id, res.data.codenum);
              });
          }
          this.$admin.http
            .get(
              `work-scheduling/apn/offices/?hospital=${val}&work_date=${moment(
                this.work_date
              ).format("YYYY-MM-DD")}`
            )
            .then((data) => {
              this.office_data = data.data.results;
            });
        } else {
          this.office_data = null;
        }
      },
    },
    office: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val !== null && this.work_date && this.hospital) {
          this.doctor = null;
          this.time_data = null;
          this.position = null;
          this.detail = null;
          this.cost = null;
          this.$admin.http
            .get(
              `work-scheduling/apn/doctors/?hospital=${
                this.hospital
              }&office=${val}&work_date=${moment(this.work_date).format(
                "YYYY-MM-DD"
              )}&fee_type=1&pb_type=1`
            )
            .then((data) => {
              this.doctor_data = data.data.results;
            });
        } else {
          this.doctor_data = null;
        }
      },
    },
    doctor: {
      deep: true,
      immediate: true,
      handler(val) {
        this.time_data = null;
        this.position = null;
        this.detail = null;
        this.cost = null;
        if (val !== null) {
          this.doctor_data.forEach((item) => {
            if (item.id == val) {
              this.cost = item.doctor_fee;
              this.position = item.position_name;
            }
          });
          this.$admin.http
            .get(
              `work-scheduling/apn/?doctor=${val}&work_date=${moment(
                this.work_date
              ).format("YYYY-MM-DD")}&offie_code=${this.office}&pb_type=1`
            )
            .then((data) => {
              this.detail = data.data.results;
            });
        }
      },
    },
  },
};
</script>
<style scoped>
.text-center {
  width: 87%;
  background-color: #fff;
  height: 80px;
  position: fixed;
  bottom: 0px;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
ul li::marker {
  color: #42b6c4;
}
.row + .row {
  margin-top: 0px;
}
.row > .col {
  padding: 0 12px !important;
}
.el-select {
  display: block;
}
.el-button--primary {
  background-color: #42b6c4;
  border-color: #42b6c4;
}
.el-date-editor.el-input {
  width: 100%;
}
</style>