<template>
  <va-show-layout>
    <va-show :item="item">
      <v-row justify="center">
        <v-col sm="6">
          <base-material-card>
            <template v-slot:heading>
              <div class="display-2">
                {{ title }}
              </div>
            </template>
            <v-card-text>
              <va-field source="day" ></va-field>
              <va-field source="time_line_name" ></va-field>
              <va-field source="time_line_code" ></va-field>
              <va-field source="office_code" ></va-field>
              <va-field source="office_name" ></va-field>
              <va-field source="group_name" ></va-field>
              <va-field source="group_id" ></va-field>
              <va-field source="start_time" ></va-field>
              <va-field source="end_time" ></va-field>
              <va-field source="resource_no_start" ></va-field>
              <va-field source="resource_no_end" ></va-field>
              <va-field source="resource_count" ></va-field>
              <va-field source="back_up_time_zone" ></va-field>
              <va-field source="resource_quota" ></va-field>
              <va-field source="appointment_item_way" ></va-field>
              <va-field source="pre_apply_days" ></va-field>
              <va-field source="create_time" ></va-field>
              <va-field source="remark" ></va-field>
            </v-card-text>
          </base-material-card>
        </v-col>
      </v-row>
    </va-show>
  </va-show-layout>
</template>

<script>
export default {
  props: ["title", "item"],
};
</script>
