<template>
    <v-card :icon="resource.icon" :title="title">
      <va-list
        :filters="filters"
        disable-create
        ref="list"
        :sort-by="['id']"
        :sort-desc="[true]"
        global-search-query="type_name@_like,type_code@_like"
      >
        <template v-slot:actions>
          <va-action-button
            label="普通挂号"
            text
            icon="mdi-plus"
            color="success"
            @click="created_data('普通挂号')"
            style="color: #4caf50"
          >
          </va-action-button>
          <va-action-button
            label="急诊挂号"
            text
            icon="mdi-plus"
            color="success"
            @click="created_data('急诊挂号')"
            style="color: #4caf50"
          >
          </va-action-button>
          <!-- <va-action-button
          icon="mdi-briefcase-download-outline"
          label="下载模版"
          :href="template_download_url"
          color="green"
          text
        >
        </va-action-button>
        <va-action-button
          icon="mdi-import"
          color="green"
          label="导入"
          @click="on_show_import"
          text
        >
        </va-action-button>
        <va-action-button
          icon="mdi-arrow-collapse-down"
          color="green"
          label="导出"
          text
          @click="on_export_excel"
        >
        </va-action-button> -->
        </template>
        <va-data-table
          :fields="fields"
          disable-clone
          disable-edit-redirect
          disable-show
        >
        </va-data-table>
      </va-list>
    </v-card>
</template>

<script>
export default {
  props: ["resource", "title"],
  data() {
    return {
      filters: [
        // {
        //   alwaysOn: true,
        //   label: "是否启用",
        //   source: "is_active",
        //   type: "select",
        // },
      ],
      fields: [
        { source: "healthcard_num", label: "健康卡ID" },
        { source: "patient_name", label: "患者姓名" },
        { source: "visit_date", label: "就诊日期" },
        { source: "noon_type", label: "时段" },
        { source: "hospital_name", label: "医院名称" },
        { source: "office_name", label: "科室名称" },
        { source: "doctor_name", label: "医生姓名" },
        { source: "emergency_room", label: "急诊诊室" },
        { source: "apt_type", label: "挂号类别" },
        { source: "status", label: "状态" },
      ],
    };
  },
  methods: {
    created_data(data) {
      this.$router.push({
        path: "/apt-register/create",
        query: { data: data },
      });
    },
  },
};
</script>
