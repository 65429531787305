<template>
  <v-card>
    <v-form ref="form" v-model="valid">
      <v-card-text style="width: 97%; margin: 0 auto">
        <v-row>
          <ul style="padding: 2vw 0 2vw 0.5vw">
            <li>预约</li>
            <li style="list-style: none; font-size: 10px; color: #999">
              MAKE AN APPOINTMENT
            </li>
          </ul>
        </v-row>
        <v-row>
          <v-col sm="3">
            <v-text-field
              label="患者名称"
              v-model="project.patientName"
              :disabled="true"
            ></v-text-field>
          </v-col>
          <v-col sm="3">
            <v-text-field
              label="性别"
              v-model="project.sexName"
              :disabled="true"
            ></v-text-field>
          </v-col>
          <v-col sm="3">
            <v-text-field
              label="电话"
              v-model="project.phoneNo"
              :disabled="true"
            ></v-text-field>
          </v-col>
          <v-col sm="3">
            <v-text-field
              label="开立医生"
              v-model="project.reqDocName"
              :disabled="true"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="3">
            <v-text-field
              label="床号"
              v-model="project.bedNo"
              :disabled="true"
            ></v-text-field>
          </v-col>
          <v-col sm="3">
            <v-text-field
              label="诊疗类型"
              v-model="project.medicalTypeName"
              :disabled="true"
            ></v-text-field>
          </v-col>
          <v-col sm="3">
            <v-text-field
              label="诊疗流水号"
              v-model="project.medicalListSerialNo"
              type="bool"
              :disabled="true"
            ></v-text-field>
          </v-col>
          <v-col sm="3">
            <v-text-field
              label="资源组名称"
              v-model="project.resourceGroupName"
              :disabled="true"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="3">
            <v-text-field
              label="开立时间"
              v-model="project.reqDatetime"
              :disabled="true"
            ></v-text-field>
          </v-col>
          <v-col sm="3">
            <v-text-field
              label="年龄"
              v-model="project.age"
              :disabled="true"
            ></v-text-field>
          </v-col>
          <v-col sm="3">
            <v-text-field
              label="开立科室"
              v-model="project.reqDocDepartName"
              :disabled="true"
            ></v-text-field>
          </v-col>
          <v-col sm="3">
            <v-text-field
              label="申请单状态"
              v-model="project.reqExecStatusName"
              :disabled="true"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="3">
            <v-text-field
              label="申请单号"
              v-model="project.note"
              :disabled="true"
            ></v-text-field>
          </v-col>
          <v-col sm="3">
            <v-text-field
              label="身份证"
              v-model="project.cardID"
              :disabled="true"
            ></v-text-field>
          </v-col>
          <v-col sm="3">
            <v-text-field
              label="医保卡号"
              v-model="project.medicalSerialNo"
              :disabled="true"
            ></v-text-field>
          </v-col>
          <v-col sm="3">
            <v-text-field
              label="医院账户号"
              v-model="project.hospitalCardNo"
              :disabled="true"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="3">
            <v-select
              disabled
              label="检查项目"
              :items="items"
              item-text="item_name"
              item-value="item_code"
              v-model="selectedItemId"
              @change="updateProject"
            ></v-select>
          </v-col>
          <v-col sm="3">
            <va-date-input
							label="变更预约时间"
              v-model="project.reqTime"
              format="longTime"
            ></va-date-input>
            <!-- <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="project.reqTime"
                  label="变更预约时间"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="project.reqTime"
                no-title
                @input="menu = false"
              ></v-date-picker>
            </v-menu> -->
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
    <div class="text-center">
      <v-btn @click="notSure" style="color: #42b6c4; margin-right: 50px"
        >返回</v-btn
      >
      <v-btn
        @click="submitReview"
        style="background-color: #42b6c4; margin-right: 50px"
        >保存</v-btn
      >
    </div>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      valid: true,
      menu: false,
      id: "",
      selectedItemId: null,
      items: [],
      project: {
        note: "",
        name: "",
        cardID: "",
        patientName: "",
        printGuideline: null,
        reqExecStatusName: null,
        medicalTypeName: null,
        reqTime: "",
        idReq: 0,
        idReqName: "",
        idReqCode: "",
        sexName: "",
      },
      statusOptions: [
        { text: "男", value: "男" },
        { text: "女", value: "女" },
      ],
    };
  },
  created() {
    this.id = this.$route.query.items;
    this.fetchProject();
    this.fetchItems();
  },
  methods: {
    notSure() {
      this.$router.push(
        "/ig-application-form" //跳转
      );
    },
    fetchItems() {
      this.$store
        .dispatch("ig_item/getList")
        .then((response) => {
          this.items = response.data; // Assuming the response data is the array of items
        })
        .catch((error) => {
          console.error("Error fetching items:", error);
        });
    },
    updateProject() {
      const selectedItem = this.items.find(
        (item) => item.item_code === this.selectedItemId
      );
      if (selectedItem) {
        this.project.idReq = selectedItem.id;
        this.project.idReqCode = selectedItem.item_code;
        this.project.idReqName = selectedItem.item_name;
      }
    },
    async fetchProject() {
      try {
        const response = await this.$store.dispatch(
          "ig_application_form/getOne",
          { id: this.id }
        );
        this.project = response.data;
        console.log("366 ", this.project);
      } catch (error) {
        console.error("Failed to fetch project", error);
      }
    },
    async submitReview() {
      if (this.$refs.form.validate()) {
        try {
          await this.$store.dispatch("ig_application_form/update", {
            id: this.id,
            data: this.project,
          });
          this.$router.push("/ig-application-form"); // 假设有一个成功页面
        } catch (error) {
          console.error("Failed to submit review", error);
        }
      }
    },
  },
};
</script>
<style scoped>
ul li::marker {
  color: #42b6c4;
}

.row + .row {
  margin-top: 0px;
}

.row > .col {
  padding: 0 12px !important;
}

.text-center {
  width: 87%;
  background-color: #fff;
  height: 80px;
  position: fixed;
  bottom: 0px;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
