<template>
  <va-form :id="id" :item="item">
    <v-card>
      <v-card-text style="width: 97%; margin: 0 auto">
        <v-row>
          <ul style="padding: 2vw 0.5vw 0.5vw">
            <li>用户信息</li>
            <li style="list-style: none; font-size: 10px; color: #999">
              USER INFORMATION
            </li>
          </ul>
        </v-row>
        <v-row style="text-alin: center; margin-top: 15px">
          <v-col v-if="is_superuser">
            <va-select-input
              reference="bs_hospital"
              :items-per-page="1000"
              :filter="{ is_active: true }"
              source="hospital_id"
              v-model="form.hospital"
            ></va-select-input>
          </v-col>
          <v-col>
            <SelectInput
              @office="getOffice"
              :items="items_data"
              :label="label_name"
              :entrust="entrust"
              source="office_id"
              reference="bs_office"
              v-model="form.office"
            ></SelectInput>
          </v-col>
          <v-col>
            <va-text-input
              required
              label="账号"
              source="username"
              v-model="form.username"
            ></va-text-input>
          </v-col>
          <v-col v-if="!is_superuser"></v-col>
        </v-row>
        <v-row style="text-alin: center; margin-top: 15px">
          <v-col>
            <va-text-input
              required
              label="姓名"
              source="name"
              v-model="form.name"
            ></va-text-input>
          </v-col>
          <v-col>
            <v-autocomplete
              v-model="user_roles"
              :items="auth_roles"
              filled
              color="blue-grey lighten-2"
              label="角色"
              item-text="role_name"
              item-value="group_id"
              multiple
            >
              <template v-slot:selection="data">
                <v-chip
                  v-model="data.attrs"
                  :input-value="data.selected"
                  close
                  @click="data.select"
                  @click:close="remove(data.item)"
                >
                  {{ data.item.role_name }}
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col>
            <div style="margin: 10px" v-if="is_superuser">
              <v-btn @click="password(id, item)" style="color: #42b6c4"
                >重置密码</v-btn
              >
              <span style="color: red; font-size: 12px; padding-left: 80px"
                >提示：重置后的密码为admin@1234</span
              >
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card style="margin-top: 1vw; margin-bottom: 90px">
      <v-card-text style="width: 97%; margin: 0 auto">
        <v-row>
          <ul style="padding: 2vw 0.5vw 0.5vw">
            <li>医生信息</li>
            <li style="list-style: none; font-size: 10px; color: #999">
              DOCTOR INFORMATION
            </li>
          </ul>
        </v-row>
        <v-row style="text-alin: center; margin-top: 15px">
          <v-col>
            <SelectInput
              source="doctor_id"
              reference="bs_doctor"
              v-model="form.doctor"
              @office="getDoctor"
              :items="items_datas"
              :label="label_names"
              :entrusts="entrusts"
            ></SelectInput>
          </v-col>
          <v-col></v-col>
          <v-col></v-col>
        </v-row>
      </v-card-text>
      <div class="text-center">
        <va-save-button></va-save-button>
      </div>
    </v-card>
    <div class="text-center">
      <v-btn @click="close" style="color: #42b6c4; margin-right: 50px"
        >取消</v-btn
      >
      <v-btn
        @click="isSure"
        style="background-color: #42b6c4; margin-right: 50px"
        >保存</v-btn
      >
    </div>
  </va-form>
</template>

<script>
import moment from "moment";
import SelectInput from "@/components/FilterData.vue";
export default {
  props: ["id", "title", "item"],
  components: { SelectInput },
  data() {
    return {
      label_name: "所属科室",
      items_data: null,
      label_names: "绑定医生",
      items_datas: null,
      imgfile: null,
      fileList: [],
      imageInfo: "",
      disabled: true,
      form: {
        doctor: null,
        hospital: null,
        office: null,
        user_rank: null,
        username: null,
        // password: null,
        name: null,
        // avatar_url: null,
        email: "111@qq.com",
        date_joined: moment().format("YYYY-MM-DD HH:mm:ss"),
        default_group_id: this.user_roles,
      },
      is_online_consult: "true",
      user_roles: null,
      auth_roles: null,
      this_user: null,
      is_superuser: null,
      entrust: null,
      entrusts: null,
    };
  },
  mounted() {
    this.is_superuser = JSON.parse(
      window.sessionStorage.getItem("user")
    ).is_superuser;
    if (!this.is_superuser) {
      this.form.hospital = JSON.parse(
        window.sessionStorage.getItem("user")
      ).hospital;
    }
    if(this.id) {
      this.entrust = this.item.office_id
      this.entrusts = this.item.doctor_id
    }
    this.get_roles();
    this.get_user_roles();
  },
  methods: {
    getOffice(msg) {
      this.form.office = msg.id;
    },
    getDoctor(msg) {
      this.form.doctor = msg.id;
    },
    password(id, item) {
      let passwordForm = {
        origin_password: "admin",
        password: "admin@1234",
        password2: "admin@1234",
      };
      this.$admin.http
        .put(`/base-system/users/${id}/change_password/`, passwordForm)
        .then((res) => {
          this.$message({
            message: "重置密码成功！",
            type: "success",
          });
        });
    },
    onchange(file, fileList) {
      const imgType =
        file.raw.type === "image/png" || file.raw.type === "image/jpeg";
      const imgSize = file.raw.size / 1024 / 1024 <= 2;
      if (!imgType) {
        this.$message.error("只能上传png、jpg、jpeg的图片格式！");
        return false;
      }
      if (!imgSize) {
        this.$message.error("图片上传最大限制2MB！");
        return false;
      }
      //如果第二次上传文件，将前一个文件删除
      //这样fileList就一直保持一个文件
      if (fileList.length > 1) {
        fileList.splice(0, 1);
      }
      //获取当前文件的一个内存URL
      //给图片src赋值
      this.imgfile = file;
      this.imgUrl = URL.createObjectURL(file.raw);
    },

    // Element 上传
    httpRequest(file, id, model) {
      var formData = new FormData();
      formData.append("belong_id", id);
      formData.append("belong_model", model);
      formData.append("big_image", file.raw);
      this.$admin.http
        .post("/tools-collection/images_upload/", formData)
        .then((img) => {});
    },
    // 修改图片
    reviseImg(imgid, file, id, model) {
      var formData = new FormData();
      formData.append("belong_id", id);
      formData.append("belong_model", model);
      formData.append("big_image", file.raw);
      this.$admin.http
        .put(`/tools-collection/images_upload/${imgid}/`, formData)
        .then((img) => {});
    },
    close() {
      this.$router.push("/base-system-user");
    },
    async isSure() {
      if (
        !this.form.username ||
        !this.form.name ||
        !this.form.hospital ||
        !this.form.office ||
        !this.user_roles
      ) {
        this.$message({
          message: "有必填项未填写",
          type: "warning",
        });
        return;
      }
      if (this.id) {
        this.this_user = this.id;
        this.$admin.http
          .put(`base-system/users/${this.id}/`, this.form)
          .then((res) => {
            this.close();
            // if (this.imageInfo[0]) {
            //   this.reviseImg(
            //     this.imageInfo[0].id,
            //     this.imgfile,
            //     this.id,
            //     res.data.avatar_url
            //   );
            // } else {
            //   this.httpRequest(this.imgfile, this.id, res.data.avatar_url);
            // }
          });
      } else {
        await this.$admin.http
          .post(`base-system/users/`, this.form)
          .then((res) => {
            this.this_user = res.data.id;
            this.close();
            // this.httpRequest(this.imgfile, res.data.id, res.data.avatar_url);
          });
      }
      //更新角色
      let { data: roles } = await this.$store.dispatch(
        "base_system_user_groups/getList",
        {
          filter: {
            user_id: this.this_user,
          },
        }
      );
      //先删除
      await this.$store.dispatch("base_system_user_groups/deleteMany", {
        ids: roles.map((o) => o.id),
      });

      //再插入
      await this.$store.dispatch("base_system_user_groups/create", {
        data: this.user_roles.map((group_id) => {
          return {
            user_id: this.this_user,
            group_id: group_id,
          };
        }),
      });

      // this.$router.push("/base-system-user");
    },
    // 获取所有角色列表
    async get_roles() {},
    // 获取当前用户拥有角色
    async get_user_roles() {
      if (!this.item) {
        return;
      }
      let { data: uroles } = await this.$store.dispatch(
        "base_system_user_groups/getList",
        {
          filter: {
            user_id: this.item.id,
          },
          include: ["auth_group"],
        }
      );
      this.user_roles = uroles.map((o) => o.auth_group.id);
    },
    // 下拉框删除已选中
    async remove(item) {
      const index = this.user_roles.indexOf(item.group_id);
      if (index >= 0) this.user_roles.splice(index, 1);
    },
  },
  computed: {
  },
  watch: {
    id: {
      deep: true,
      handler: function (newVal) {
        // this.form.default_group_id = newVal;
      },
    },
    "form.doctor": {
      deep: true,
      handler: function (newVal) {
        if (newVal) {
          this.$store
            .dispatch("bs_doctor/getOne", { id: newVal })
            .then((res) => {
              this.form.hospital = res.data.hospital_id;
              this.form.office = res.data.office_id;
              this.form.user_rank = res.data.doc_rank_id;
            });
        }
      },
    },
    "form.office": {
      deep: true,
      handler: function (newVal) {
        if (newVal) {
          this.$store
            .dispatch("bs_doctor/getList", {
              filter: {
                office_id: newVal,
              },
            })
            .then((res) => {
              res.data.forEach((item) => {
                item.text = item.name;
              });
              this.items_datas = res.data;
            });
        }else{
          this.$store
            .dispatch("bs_doctor/getList")
            .then((res) => {
              res.data.forEach((item) => {
                item.text = item.name;
              });
              this.items_datas = res.data;
            });
        }
      }
    },
    "form.hospital": {
      deep: true,
      handler: function (newVal) {
        if (newVal) {
          this.$store
            .dispatch("bs_office/getList", {
              filter: {
                hospital_id: newVal,
              },
            })
            .then((res) => {
              res.data.forEach((item) => {
                item.text = item.name;
              });
              this.items_data = res.data;
            });
        }else{
          this.$store
            .dispatch("bs_office/getList")
            .then((res) => {
              res.data.forEach((item) => {
                item.text = item.name;
              });
              this.items_data = res.data;
            });
            this.form.office = undefined
        }
        this.$store
          .dispatch("bs_extra_group/getList", {
            filter: {
              hospital_id: newVal,
            },
          })
          .then((res) => {
            this.auth_roles = res.data;
          });
      },
    },
    id: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
          this.$store
            .dispatch("base_system_user/getList", {
              filter: {
                id: val,
              },
              include: ["user_avatar"],
            })
            .then((res) => {
              this.imageInfo = res.data[0].user_avatar;
              this.fileList = [];
              this.imageInfo.forEach((img) => {
                this.fileList.push({
                  url: `${this.global.url}/${img.big_image}`,
                });
              });
            });
        }
      },
    },
  },
};
</script>

<style scoped>
ul li::marker {
  color: #42b6c4;
}
.row + .row {
  margin-top: 0px;
}
.row > .col {
  padding: 0 12px !important;
}
.text-center {
  width: 87%;
  background-color: #fff;
  height: 80px;
  position: fixed;
  bottom: 0px;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>