<!--
 * @Description: 
 * @Author: your name
 * @Date: 2023-05-04 14:29:34
 * @LastEditors: your name
 * @LastEditTime: 2023-06-05 17:36:53
-->
<template>
  <div>
    <v-card :icon="resource.icon" :title="title">
      <va-list
        disable-export
        :filters="filters"
        ref="list"
        @action="onAction"
        :items-per-page="10"
        global-search-query="hospital_name@_like,ward_name@_like,room_name@_like,patient_name@_like,bed_num@_like"
        :include="['bs_hospital']"
        :sort-by="['planned_admission_time']"
        :sort-desc="[true]"
        disable-create
        :filter="filter"
      >
        <template v-slot:[`filter.planned_admission_time@_gte`]="props">
          <el-date-picker
            class="select"
            size="small"
            v-bind="props"
            v-model="date_value"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </template>
        <template v-slot:actions> </template>
        <!-- <template v-slot:actions>
          <va-action-button
            icon="mdi-briefcase-download-outline"
            label="下载模版"
            :href="template_download_url"
            color="green"
            text
          >
          </va-action-button>
          <va-action-button
            icon="mdi-import"
            color="green"
            label="导入"
            @click="on_show_import"
            text
          >
          </va-action-button>
          <va-action-button
            icon="mdi-arrow-collapse-down"
            color="green"
            label="导出"
            text
            @click="on_export_excel"
          >
          </va-action-button>
        </template> -->
        <va-data-table
          disableShow
          disableClone
          :fields="fields"
          disable-create-redirect
          disable-show-redirect
          disable-delete-redirect
          @item-action="onAction"
          disable-delete
          disable-edit
          disable-select
        >
          <template v-slot:[`field.real_length_stay`]="{ item }">
            {{ real_length_stay(item) }}
          </template>
          <template v-slot:[`field.state`]="{ item }">
            <template v-if="item.state == 0">
              <v-chip class="ma-2" color="green" text-color="white">
                已预约
              </v-chip>
            </template>
            <template v-else-if="item.state == 1">
              <v-chip class="ma-2" text-color="#42b6c4">
                已入住
              </v-chip></template
            ><template v-else>
              <v-chip class="ma-2" text-color="#42b6c4"> 已搬出 </v-chip>
            </template>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip
              bottom
              v-if="
                item.state == 0 && $admin.can(['change_hospitalizationpatient'])
              "
            >
            </v-tooltip>
            <v-tooltip
              bottom
              v-else-if="
                item.state == 1 && $admin.can(['change_hospitalizationpatient'])
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  class="mr-2"
                  color="red"
                  @click="removeRoom(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-home-minus
                </v-icon>
              </template>
              <span>搬出</span>
            </v-tooltip>
          </template>
        </va-data-table>
      </va-list>
    </v-card>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: ["resource", "title"],
  data() {
    return {
      // 模板下载地址
      // template_download_url: `${this.global.upload_url}excel/office_import.xlsx`,
      // 弹出侧边栏
      asideTitle: null,
      id: null,
      item: null,
      table_data: [],
      data: null,
      show: false,
      date_value: "",
      filters: [
        // {
        //   alwaysOn: true,
        //   label: "所属医院",
        //   source: "hospital_name",
        //   type: "text",
        // },
        {
          alwaysOn: true,
          label: "病区名称",
          source: "ward_name",
          type: "text",
        },
        {
          alwaysOn: true,
          label: "病房名称",
          source: "room_name",
          type: "text",
        },
        {
          alwaysOn: true,
          label: "状态",
          source: "state",
          type: "select",
          // attributes: {
          //   reference: "bs_drug_preparation_type",
          //   itemText: "type_name",
          // },
        },
        {
          source: "planned_admission_time@_gte",
          alwaysOn: true,
        },
      ],
      fields: [
        {
          source: "patient_name",
          label: "患者姓名",
          sortable: false,
          align: "center",
        },
        {
          source: "id_num",
          label: "身份证号",
          sortable: false,
          align: "center",
        },
        {
          source: "hospital_name",
          label: "所属医院",
          sortable: false,
          align: "center",
        },
        {
          source: "ward_name",
          label: "所属病区",
          sortable: false,
          align: "center",
        },
        {
          source: "room_name",
          label: "所属病房",
          sortable: false,
          align: "center",
        },
        {
          source: "bed_num",
          label: "床位号",
          sortable: false,
          align: "center",
        },

        {
          source: "planned_admission_time",
          type: "date",
          label: "计划入住时间",
          align: "center",
          attributes: { format: "short" },
        },
        {
          source: "planned_length_stay",
          label: "计划入住天数",
          sortable: false,
          align: "center",
        },
        {
          source: "real_admission_time",
          label: "入住时间",
          type: "date",
          align: "center",
          attributes: { format: "short" },
        },
        {
          source: "real_discharge_time",
          label: "搬出时间",
          type: "date",
          align: "center",
          attributes: { format: "short" },
        },
        {
          source: "real_length_stay",
          label: "入住天数(天)",
          sortable: false,
          align: "center",
        },
        {
          source: "state",
          label: "状态",
          sortable: false,
          align: "center",
        },
      ],
      filter: {},
      user_data: null,
    };
  },
  computed: {},
  mounted() {
    this.$store.dispatch("hap_hospitalization_patient/getList").then((res) => {
      this.data = res;
    });
    // this.$admin.http.get("hospitalization_apt/in_patient/").then((res) => {
    //   this.data = res.data.results;
    //   console.log(res, "resers");
    // });
  },
  watch: {
    date_value: {
      deep: true,
      handler: function (newVal) {
        if (newVal) {
          this.table_data = [];
          let value1 = moment(newVal[0]).format("YYYY-MM-DD");
          let value2 = moment(newVal[1]).format("YYYY-MM-DD");
          // this.data.data.forEach((item) => {
          //   if (
          //     moment(item.planned_admission_time).format("YYYY-MM-DD") >=
          //       value1 &&
          //     moment(item.planned_admission_time).format("YYYY-MM-DD") <= value2
          //   ) {
          //     this.table_data.push(item.id);
          //   } else {
          //     this.table_data = [0];
          //     return;
          //   }
          // });
          this.data.data.forEach((item) => {
            if (
              moment(item.planned_admission_time).format("YYYY-MM-DD") ==
              moment(newVal).format("YYYY-MM-DD")
            ) {
              this.table_data.push(item.id);
            }
          });
        } else {
          this.table_data = [];
        }
        this.filter = {
          id: this.table_data,
          hospital_name: this.user_data.hospital_name,
        };
      },
    },
  },
  created() {
    this.getSupper();
  },
  methods: {
    getSupper() {
      // 医院名称的过滤
      this.user_data = JSON.parse(window.sessionStorage.getItem("user"));
      if (this.user_data.is_superuser) {
        this.filters.push({
          source: "hospital_name",
          label: "所属医院",
          alwaysOn: true,
          type: "text",
        });
      }
      this.filter = { hospital_name: this.user_data.hospital_name };
    },
    real_length_stay(item) {
      if (item.real_admission_time && item.real_discharge_time) {
        const checkInDate = new Date(item.real_admission_time);
        const checkOutDate = new Date(item.real_discharge_time);
        const timeDiff = checkOutDate.getTime() - checkInDate.getTime();
        const daysDiff = Math.round(timeDiff / (1000 * 3600 * 24));
        return daysDiff + 1;
      } else {
        return null;
      }
    },
    goShow(item) {
      this.asideTitle = "驳回原因";
      this.id = item.id;
      this.show = true;
      this.item = item;
    },
    async onAction({ action, title, id, item }) {
      console.log(1111111111);
      this.asideTitle = title;
      this.id = id;
      this.show = action === "show";
      this.item = item;
      if (action === "create") {
        this.id = null;
      }
    },
    onSaved() {
      this.asideTitle = null;
      this.$refs.list.fetchData();
    },
    goRomm(item) {
      this.$confirm("患者入住，是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let forms = {};

          forms.state = "1";
          this.$admin.http
            .post(
              `hospitalization_apt/in_patient/${item.id}/manipulating_patient/`,
              forms
            )
            .then((res) => {
              this.onSaved();
            });
          // this.$store
          //   .dispatch("hap_hospitalization_patient/update", {
          //     id: item.id,
          //     data: {
          //       state: "1",
          //       real_admission_time: moment().format("YYYY-MM-DD HH:mm:ss"),
          //     },
          //   })
          //   .then((res) => {
          //     // this.onSaved();
          //     this.getSelect(item, 1);
          //     this.getSelects(item, 1);
          //   });
        })
        .catch(() => {});
    },
    removeRoom(item) {
      // this.getSelect(item, 2);
      this.$confirm("患者搬出，是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let forms = {};

          forms.state = "2";
          this.$admin.http
            .post(
              `hospitalization_apt/in_patient/${item.id}/manipulating_patient/`,
              forms
            )
            .then((res) => {
              this.onSaved();
            });
          // this.$store
          //   .dispatch("hap_hospitalization_patient/update", {
          //     id: item.id,
          //     data: {
          //       state: "2",
          //       real_discharge_time: moment().format("YYYY-MM-DD HH:mm:ss"),
          //     },
          //   })
          //   .then((res) => {
          //     // this.onSaved();
          //     this.getSelect(item, 2);
          //     this.getSelects(item, 2);
          //   });
        })
        .catch(() => {});
    },
    // getSelect(item, type) {
    //   console.log(item,'item123212');
    //   this.$store
    //     .dispatch("hap_sickbed/getList", {
    //       filter: {
    //         hospital_code: item.hospital_code,
    //         ward_code: item.ward_code,
    //         room_num: item.room_num,
    //         bed_num: item.bed_num,
    //       },
    //     })
    //     .then((res) => {
    //       let forms = res.data[res.data.length - 1];
    //       console.log(res.data, "formsz");

    //       console.log(forms, "formsz");
    //       if (type == 1) {
    //         forms.state = "2";
    //       } else {
    //         forms.state = "0";
    //       }
    //       this.updataS(forms);
    //     });
    // },
    // updataS(forms) {
    //   this.$store
    //     .dispatch("hap_sickbed/update", {
    //       id: forms.id,
    //       data: forms,
    //     })
    //     .then((res) => {
    //       this.onSaved();
    //     });
    // },
    // getSelects(item, type) {
    //   this.$store
    //     .dispatch("hap_bed_apt_record/getList", {
    //       filter: {
    //         hospital_code: item.hospital_code,
    //         ward_code: item.ward_code,
    //         room_num: item.room_num,
    //         bed_num: item.bed_num,
    //       },
    //     })
    //     .then((res) => {
    //       let forms = res.data[res.data.length - 1];
    //       console.log(res.data, "forms");

    //       console.log(forms, "forms");
    //       if (type == 1) {
    //         forms.bed_state = "1";
    //       } else {
    //         forms.bed_state = "2";
    //       }
    //       this.updataZ(forms);
    //     });
    // },
    // updataZ(forms) {
    //   this.$store
    //     .dispatch("hap_bed_apt_record/update", {
    //       id: forms.id,
    //       data: forms,
    //     })
    //     .then((res) => {
    //       console.log(res, "resres");
    //     });
    // },

    // 导入
    on_show_import() {},
    // 导出
    on_export_exce() {},
  },
};
</script>
<style lang="sass" scoped>
.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined)
  background-color: #eee
  color: #586AFC
  &:hover
    background-color: #ccc
    color: #000

.navInput
  width: 12rem
  height: 2.4rem
  border-bottom: 1px solid rgba(0, 0, 0, 0.15)
  border-radius: 3px
  cursor: pointer
  background: #fff
  padding-top: 0.6rem
  color: #444
  font-size: 14px
  font-family: SourceHanSansCN-Normal, SourceHanSansCN
  font-weight: 400

::v-deep .el-input__inner
  border-top: none !important
  border-left: none !important
  border-right: none !important
  border-bottom: 1px #D2D2D2 solid !important
  border-radius: 0
  padding-bottom: 10px
</style>
<style scoped>
::v-deep .el-input__inner {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1px #d2d2d2 solid !important;
  border-radius: 0;
  padding-bottom: 10px;
}
.select .el-icon-circle-close,
.el-input__prefix {
  display: block !important;
}

.custom_btn.v-item-group {
  left: 0;
  position: absolute;
}
</style>
