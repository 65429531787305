<template>
  <v-card :icon="resource.icon" :title="title">
    <va-list
      :filters="filters"
      :filter="filter"
      :include="['bs_hospital', 'bs_office', 'bs_position_title']"
      global-search-query="bs_office#name@_like,bs_hospital#name@_like,codenum@_like,name@_like,bs_position_title#name@_like"
      disable-export
      ref="list"
      :sort-desc="[true]"
    >
      <template v-slot:actions>
        <va-action-button
          icon="mdi-briefcase-download-outline"
          label="下载模版"
          :href="template_download_url"
          color="green"
          text
        >
        </va-action-button>
        <va-action-button
          icon="mdi-import"
          color="green"
          label="导入"
          @click="on_show_import"
          text
        >
        </va-action-button>
        <va-action-button
          icon="mdi-arrow-collapse-down"
          color="green"
          label="导出"
          text
          @click="on_export_excel"
        >
        </va-action-button>
      </template>
      <va-data-table :fields="fields" disable-clone disable-show>
        <template v-slot:[`field.describe`]="{ item }">
          <div
            style="
              width: 15vw;
              word-break: break-all;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
            "
          >
            {{ item.describe }}
          </div>
        </template>
      </va-data-table>
    </va-list>
    <v-dialog v-model="dialog" width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">批量导入</span>
        </v-card-title>
        <v-card-text>
          <el-form label-width="80px">
            <el-form-item label="上传文件">
              <el-upload
                class="upload-demo"
                action
                :auto-upload="false"
                :on-change="handleChange"
                :file-list="fileList"
                multiple
              >
                <el-button
                  type="primary"
                  style="
                    color: white;
                    background-color: #14b2c5;
                    border: #14b2c5 1px solid;
                  "
                  size="small"
                  >点击上传</el-button
                >
              </el-upload>
            </el-form-item>
          </el-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> 取消 </v-btn>
          <v-btn color="primary" @click="isSure"> 确认 </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { exportResource } from "@/util/excel_export";
const static_server_url =
  process.env.VUE_APP_API_URL || "http://localhost:28000/static";
export default {
  props: ["resource", "title"],
  data() {
    return {
      template_download_url: `${this.global.upload_url}excel/doctor_import.xlsx`,
      fileList: [],
      dialog: false,
      filter: {},
      filters: [
        // {
        //   alwaysOn: true,
        //   label: "所属医院",
        //   source: "hospital_id",
        //   type: "select",
        //   attributes: {
        //     reference: "bs_hospital",
        //     itemText: "name",
        //     itemsPerPage: "1000"
        //   },
        // },
        // {
        //   alwaysOn: true,
        //   label: "所属科室",
        //   source: "office_id",
        //   type: "select",
        //   attributes: {
        //     reference: "bs_office",
        //     itemText: "name",
        //   },
        // },
        {
          alwaysOn: true,
          label: "医生姓名",
          source: "name",
        },
      ],
      fields: [
        { source: "bs_hospital.name", label: "所属医院" },
        { source: "bs_office.name", label: "所属科室" },
        "codenum",
        // "photo",
        "name",
        { source: "bs_position_title.name", label: "医生职称" },
        // "doc_rank_id",
        "describe",
        // { source: "bs-expense-standard.fees", label: "挂号费用标准", filter: {expense_type: 1} },
        // { source: "bs-expense-standard.fees", label: "电话问诊费用标准", filter: {expense_type: 2} },
        // { source: "bs-expense-standard.fees", label: "在线问诊费用标准", filter: {expense_type: 3} },
        {
          source: "is_online_consult",
          label: "是否互联网接诊",
          type: "boolean",
        },
      ],
    };
  },
  created() {
    this.getSupper();
  },
  methods: {
    getSupper() {
      let user_data = JSON.parse(
        window.sessionStorage.getItem("user")
      );
      if (user_data.is_superuser) {
        this.filters.push({
          source: "hospital_id",
          label: "所属医院",
          type: "select",
          alwaysOn: true,
          attributes: { reference: "bs_hospital" },
          // attributes: { reference: "auth_system_user.id" },
        });
      }else{
        this.filter = {hospital_id: user_data.hospital}
      }
    },
    //导出
    on_export_excel() {
      let params = this.$router.currentRoute.query;
      exportResource(
        this.$admin.http,
        "/base-system/export_doctor",
        params,
        "医生信息.xlsx"
      );
    },
    //导入
    async isSure() {
      let excel_file = this.fileList[0].raw;
      let formData = new FormData();
      formData.append("excel_file", excel_file);
      await this.$admin.http.post("/base-system/import_doctor/", formData);
      this.dialog = false;
    },
    handleChange(file, fileList) {
      this.fileList = fileList;
    },
    on_show_import() {
      this.fileList = []
      this.dialog = true;
    },
  },
};
</script>
