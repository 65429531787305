<!--
 * @Description: 
 * @Author: your name
 * @Date: 2023-05-04 14:29:34
 * @LastEditors: your name
 * @LastEditTime: 2023-05-18 14:33:33
-->
<template>
  <div>
    <va-aside-layout :title="asideTitle">
      <er-management-main-show
        v-if="show"
        :item="item"
      ></er-management-main-show>
      <er-management-main-form
        v-else
        :id="id"
        width="500px"
        :item="item"
        @saved="onSaved"
        ref="form"
      ></er-management-main-form>
    </va-aside-layout>
    <v-card :icon="resource.icon" :title="title">
      <va-list
        disable-global-search
        disable-create-redirect
        disable-export
        :filters="filters"
        :filter="filter"
        ref="list"
        @action="onAction"
        :items-per-page="10"
        :include="['bs_hospital']"
      >
        <!-- <template v-slot:actions>
          <va-action-button
            icon="mdi-briefcase-download-outline"
            label="下载模版"
            :href="template_download_url"
            color="green"
            text
          >
          </va-action-button>
          <va-action-button
            icon="mdi-import"
            color="green"
            label="导入"
            @click="on_show_import"
            text
          >
          </va-action-button>
          <va-action-button
            icon="mdi-arrow-collapse-down"
            color="green"
            label="导出"
            text
            @click="on_export_excel"
          >
          </va-action-button>
        </template> -->
        <va-data-table
          disableShow
          disableClone
          :fields="fields"
          disable-create-redirect
          disable-edit-redirect
          disable-delete-redirect
          @item-action="onAction"
        >
        </va-data-table>
      </va-list>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["resource", "title"],
  data() {
    return {
      // 模板下载地址
      // template_download_url: `${this.global.upload_url}excel/office_import.xlsx`,
      // 弹出侧边栏
      asideTitle: null,
      id: null,
      item: null,
      show: false,
      filter: {},
      filters: [
        // {
        //   alwaysOn: true,
        //   label: "所属医院",
        //   source: "hospital_name",
        //   type: "text",
        // },
        {
          alwaysOn: true,
          label: "科室名称",
          source: "office_name",
          type: "text",
        },
        {
          alwaysOn: true,
          label: "诊室名称",
          source: "name",
          type: "text",
        },
      ],
      fields: [
        {
          source: "hospital_name",
          label: "所属医院",
          sortable: false,
          align: "center",
        },
        {
          source: "office_name",
          label: "所属科室",
          sortable: false,
          align: "center",
        },
        {
          source: "code",
          label: "诊室编码",
          sortable: false,
          align: "center",
        },
        {
          source: "name",
          label: "诊室名称",
          sortable: false,
          align: "center",
        },
        {
          source: "fee",
          label: "挂号费用标准",
          sortable: false,
          align: "center",
        },
      ],
    };
  },
  created() {
    this.getSupper();
  },
  methods: {
    getSupper() {
      // 医院名称的过滤
      let user_data = JSON.parse(
        window.sessionStorage.getItem("user")
      );
      if (user_data.is_superuser) {
        this.filters.push({
          source: "hospital_name",
          label: "所属医院",
          alwaysOn: true,
          type: "text",
        });
      }else{
        this.filter = {hospital_name: user_data.hospital_name}
      }
    },
    async onAction({ action, title, id, item }) {
      this.asideTitle = title;
      this.id = id;
      this.show = action === "show";
      this.item = item;
      if (action === "create") {
        this.id = null;
      }
    },
    onSaved() {
      this.asideTitle = null;
      this.$refs.list.fetchData();
    },
    // 导入
    on_show_import() {},
    // 导出
    on_export_exce() {},
  },
};
</script>
