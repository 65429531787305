<template>
  <!-- <va-edit-layout> -->
    <bs-pharmacy-drug-form
      :id="id"
      :title="title"
      :item="item"
    ></bs-pharmacy-drug-form>
  <!-- </va-edit-layout> -->
</template>

<script>
export default {
  props: ["id", "title", "item"],
};
</script>
