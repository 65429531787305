<!--
 * @Description: 
 * @Author: your name
 * @Date: 2023-05-06 14:38:14
 * @LastEditors: your name
 * @LastEditTime: 2023-05-08 16:34:52
-->
<template>
  <!-- <va-edit-layout> -->
    <hap-bed-apt-record-multiplex-form :id="id" :title="title" :item="item"></hap-bed-apt-record-multiplex-form>
  <!-- </va-edit-layout> -->
</template>

<script>
export default {
  props: ["id", "title", "item"],
};
</script>
