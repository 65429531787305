<template>
  <base-material-card :icon="resource.icon" :title="title">
    <va-list :filters="filters" disable-global-search>
      <va-data-table :fields="fields">
      </va-data-table>
    </va-list>
  </base-material-card>
</template>

<script>
export default {
  props: ["resource", "title"],
  data() {
    return {		
		item: null,
		show: false,
        filters: [
			{ alwaysOn: true, label: "设备编码", source: "device_code" },
			{ alwaysOn: true, label: "设备名称", source: "device_name" },
			{ alwaysOn: true, label: "启用", source: "is_active" },
		],
        fields: [
			{ source: 'device_code', label: '设备编码', sortable: true, align: "center"},
			{ source: 'device_name', label: '设备名称', sortable: true, align: "center"},
			{ source: 'device_addr', label: '地址', sortable: true, align: "center"},
			// { source: 'device_type', label: '设备类型', sortable: true, align: "center"},
			{ source: 'is_active', label: '启用', sortable: true, sortable: true, type: "boolean", align: "center"},
			{ source: 'remark', label: '备注', sortable: true, align: "center"},
	  	],
    };
  },
};
</script>
