<template>
  <div>
    <v-card style="padding: 2vw 0.5vw; margin: 20px 0">
      <el-button
        style="float: right; margin: 0 0 10px"
        type="primary"
        @click="addRemind"
        icon="el-icon-plus"
        size="small"
        >添加行</el-button
      >
      <div class="tableList">
        <el-table
          :data="tableData"
          border
          style="width: 100%"
          size="small"
          :header-cell-style="tableHeaderCellStyle"
        >
          <el-table-column
            type="index"
            label="序号"
            width="50"
            align="center"
            fixed="left"
          ></el-table-column>
          <el-table-column
            align="center"
            v-for="(text, i) in title"
            :key="i"
            :label="text.name"
          >
            <template slot-scope="scope">
              <template v-if="text.field === 'drug_name'">
                <el-input
                  clearable
                  @input="change_data(scope)"
                  v-model="scope.row[text.field]"
                  :placeholder="'请输入' + text.name"
                  width="150px"
                ></el-input>
              </template>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
            width="70px"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                @click="removeRemind(scope.row)"
                width="70px"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </v-card>

    <el-table
      :data="drugsData"
      height="400"
      style="width: 100%"
      @current-change="handleCurrentChange"
      :header-cell-style="tableHeaderCellStyle"
    >
      <el-table-column type="index" width="50"> </el-table-column>
      <el-table-column label="分类" prop="drug_code"> </el-table-column>
      <el-table-column label="项目编码" prop="drug_code"> </el-table-column>
      <el-table-column label="项目名称" prop="drug_name"> </el-table-column>
    </el-table>
    <div style="float: right; padding-bottom: 80px">
      <v-pagination
        v-model="page"
        :length="total"
        :total-visible="7"
      ></v-pagination>
    </div>
    <div class="text-center">
      <v-btn @click="close" style="color: #42b6c4; margin-right: 50px"
        >取消</v-btn
      >
      <v-btn
        @click="isSure"
        :disabled="disabled"
        style="background-color: #42b6c4; margin-right: 50px"
        >确定</v-btn
      >
    </div>
  </div>
</template>

<script>
import moment from "moment";
// import Utils from "../global/utils";
export default {
  data() {
    return {
      total: null,
      page: 1,
      form: {},
      params: null,
      tableData: [],
      title: [
        {
          field: "drug_name",
          name: "分类",
        },
        {
          field: "drug_code",
          name: "项目编码",
        },
        {
          field: "drug_name",
          name: "项目名称",
        },
      ],
      drugsData: [],
      code_pre: null,
      options: [],
      value: "",
      handle_opinion: null,
      table_index: null,
      doctor: null,
      zd_limit: 10,
      query_name: null,
      disabled: false,
      diagnosis_name: null,
    };
  },
  created() {
    this.options = JSON.parse(this.$route.query.diagnosis);
    this.query_info();
    if (sessionStorage.getItem(`tableData${this.$route.query.id}`)) {
      this.tableData = JSON.parse(
        sessionStorage.getItem(`tableData${this.$route.query.id}`)
      );
    }
    this.getCode();
    this.getTable();
  },
  methods: {
    tableHeaderCellStyle() {
      let cellStyle = "color: #14b2c5;background: #F6F9FD";
      return cellStyle;
    },
    // 取消
    close() {
      this.$router.push("/medical-technology-allocation");
    },
    // 提交审核
    isSure() {
      if (this.tableData && this.tableData.length == 0) {
        this.$message({
          message: "有必填项未填写",
          type: "warning",
        });
        return;
      }
      this.disabled = true;
      let user = JSON.parse(window.sessionStorage.getItem("user"));
      let prescription = {
        code_pre: this.code_pre,
        code_healthcard: this.params.health_card_id,
        code_pat: this.params.health_card_id,
        diagnosis_code: this.value,
        diagnosis_name: this.diagnosis_name,
        handle_opinion: this.handle_opinion,
        issue_date: moment().format("YYYY-MM-DD"),
        doctor_code: this.doctor.job_number,
        doctor_name: this.doctor.name,
        office_code: this.doctor.office_code,
        office_name: this.doctor.office_name,
        hospital_code: this.doctor.hospital_code,
        hospital_name: this.doctor.hospital_name,
        // code_ent: this.params.pat_codenum,
        code_ent: this.$route.query.code_ent,
        created_by: user.name,
        updated_by: user.name,
        status: "0",
        drug_pre: this.tableData,
      };
      this.$admin.http
        .post("/online-recheck/prescriptions/", prescription)
        .then((data) => {
          this.$message({
            message: "提交成功",
            type: "success",
          });
          this.close();
        });
    },
    // 查询患者
    query_info() {
      this.doctor_id = JSON.parse(window.sessionStorage.getItem("user")).doctor;
      this.$admin.http
        .get(
          `patient-account/online_therapy/?appoint_type=4&status=3&doctor_id=${this.doctor_id}`
        )
        .then((res) => {
          res.data.results.forEach((item) => {
            if (item.id == this.$route.query.id) {
              this.params = item;
            }
          });
        });
    },
    // 搜索药品
    change_data(scope) {
      this.table_index = scope.$index;
      // scope.row.drug_name = null; //  药品编码
      // scope.row.drug_code = null; //  药品编码
      // scope.row.price = null; //  药品单价
      // scope.row.frequency_use = null; //  用药频率
      // scope.row.administration_mode = null; //  给药方式
      // scope.row.drug_days = null; //  用药天数
      // scope.row.pre_num = null;
      // scope.row.dr_advice = null;
      // scope.row.specification = null; //  规格
      // scope.row.preparation_type = null; //  剂型
      // scope.row.drug_type = null; //  药品类型
      // scope.row.unit_dose = null; //  单位剂量
      // scope.row.measure_unit = null; //  计量单位
      // scope.row.total_unit = null; //  总量单位
      // scope.row.hr_level = null; //  高危等级
      // scope.row.gb_code = null; //  国家贯标编码
      // scope.row.gb_name = null; //  国家贯标名称
      // scope.row.total_unit_code = null; //
      // scope.row.measure_unit_code = null;
      // scope.row.amount_use = null; //  用量
      // scope.row.code_medu_cur = null;
      // if (scope.row.drug_name == "") {
      //   this.form.drug_name = null;
      // }
      this.form.drug_name = scope.row.drug_name;
      this.adjustment();
    },
    remoteMethod(query) {
      if (query !== "") {
        this.query_name = query;
        this.$admin.http
          .get(`/online-recheck/diarcs/?name=${query}`)
          .then((res) => {
            this.options = res.data.results;
          });
      } else {
        this.options = [];
      }
    },
    adjustment() {
      let obj_key = Object.keys(this.form);
      let src_key = "";
      obj_key.forEach((key) => {
        src_key = src_key + "&" + key + "=" + `${this.form[key]}`;
      });
      src_key = src_key.slice(1);
      if (this.form.drug_name) {
        this.$admin.http
          .get(`/base-system/drug_directories/?${src_key}`)
          .then((res) => {
            if (res.data.results && res.data.results.length > 0) {
              this.total =
                (res.data.count / 10) % 1 === 0
                  ? res.data.count / 10
                  : parseInt(res.data.count / 10) + 1;
              this.drugsData = res.data.results;
            }
          });
      }
    },
    getTable() {
      this.$admin.http
        .get(`/online-recheck/prescriptions/tabtitle/`)
        .then((res) => {
          this.title = res.data;
        });
    },
    // 选中筛选数据
    handleCurrentChange(val) {
      if (val) {
        this.tableData[this.table_index].drug_name = val.drug_name; //  药品编码
        this.tableData[this.table_index].drug_code = val.drug_code; //  药品编码
        this.tableData[this.table_index].price = val.price; //  药品单价
        this.tableData[this.table_index].specification = val.standards; //  规格
        this.tableData[this.table_index].preparation_type =
          val.preparation_type; //  剂型
        this.tableData[this.table_index].drug_type = val.drug_type; //  药品类型
        this.tableData[this.table_index].unit_dose = val.unit_dose; //  单位剂量
        this.tableData[this.table_index].measure_unit = val.measure_unit; //  计量单位
        // this.tableData[this.table_index].frequency_use = val.standards     //  用药频率
        // this.tableData[this.table_index].administration_mode = val.drug_type        //  给药方式
        // this.tableData[this.table_index].drug_days = val.unit_dose     //  用药天数
        // this.tableData[this.table_index].pre_num = val.standards     //  处方数量
        this.tableData[this.table_index].total_unit = val.total_unit; //  总量单位
        // this.tableData[this.table_index].dr_advice = val.drug_type     //  医嘱嘱托
        // this.tableData[this.table_index].is_essential = val.is_essential; //  是否是基药
        this.tableData[this.table_index].hr_level = val.hr_level; //  高危等级
        this.tableData[this.table_index].gb_code = val.gb_code; //  国家贯标编码
        this.tableData[this.table_index].gb_name = val.gb_name; //  国家贯标名称
        this.tableData[this.table_index].total_unit_code = val.total_unit_code; //
        this.tableData[this.table_index].measure_unit_code =
          val.measure_unit_code; //
        this.tableData[this.table_index].amount_use = val.amount_use; //  用量
        this.tableData[this.table_index].code_medu_cur = val.code_medu_cur; //  单次用量编码
        this.tableData[this.table_index].code_ent = this.$route.query.code_ent; // 就诊编码
        this.total = null;
        this.drugsData = null;
      }
    },
    // 添加行
    addRemind() {
      let table_info = {};
      this.title.forEach((item) => {
        table_info[item.field] = null;
      });
      this.tableData.push(table_info);
    },
    // 删除行
    removeRemind(row) {
      let rowIndex = this.tableData.indexOf(row);
      this.tableData.splice(rowIndex, 1);
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== "prescribing") {
      sessionStorage.setItem(
        `tableData${this.$route.query.id}`,
        JSON.stringify(this.tableData)
      );
    }
    next();
  },
  watch: {
    page: {
      deep: true,
      immediate: true,
      handler(val) {
        this.form.page = val;
        this.form.page_size = 10;
        this.adjustment();
      },
    },
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
          let data = this.options.filter(function (obj) {
            return obj.diagnosis_code === val; // 这里根据需要修改条件判断语句
          });
          this.diagnosis_name = data[0].diagnosis_name;
        } else {
          this.diagnosis_name = null;
        }
      },
    },
  },
};
</script>

<style scoped>
.text-center {
  width: 87%;
  background-color: #fff;
  height: 80px;
  position: fixed;
  bottom: 0px;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
ul li::marker {
  color: #42b6c4;
}
.row + .row {
  margin-top: 0px;
}
.row > .col {
  padding: 0 12px !important;
}
.el-select {
  display: block;
}
.el-button--primary {
  background-color: #42b6c4;
  border-color: #42b6c4;
}
.el-input--suffix /deep/ .el-input__inner {
  border: none;
}
</style>
