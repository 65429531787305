<template>
  <div>
    <va-aside-layout :title="asideTitle">
      <bs-pharmacy-drug-show v-if="show" :item="item"></bs-pharmacy-drug-show>
      <bs-pharmacy-drug-form
        v-else
        :id="id"
        :item="item"
        @saved="onSaved"
      ></bs-pharmacy-drug-form>
    </va-aside-layout>
    <v-card :icon="resource.icon" :title="title">
      <va-list
        ref="list"
        :filters="filters"
        :filter="filter"
        disableExport
        global-search-query="bs_pharmacy_management#pharmacy_name@_like,drug_code@_like,bs_drug_type#name@_like,bs_drug_preparation_type#type_name@_like,manufacturer@_like,origin_place@_like"
        disable-create-redirect
        @action="onAction"
        :include="[
          'bs_pharmacy_management',
          'bs_hospital',
          'bs_drug_preparation_type',
          'bs_drug_category',
          'bs_drug_type',
        ]"
        :sort-by="['id']"
        :sort-desc="[true]"
      >
      <template v-slot:actions>
          <va-action-button
          icon="mdi-briefcase-download-outline"
          label="下载模版"
          :href="template_download_url"
          color="green"
          text
        >
        </va-action-button>
        <va-action-button
          icon="mdi-import"
          color="green"
          label="导入"
          @click="on_show_import"
          text
        >
        </va-action-button>
          <va-action-button
            icon="mdi-arrow-collapse-down"
            color="green"
            label="导出"
            text
            @click="on_export_excel"
          >
          </va-action-button>
        </template>
        <va-data-table
          disable-show
          :fields="fields"
          disable-show-redirect
          disable-clone
          disable-edit-redirect
          @item-action="onAction"
        >
        </va-data-table>
      </va-list>
      <v-dialog v-model="dialog" width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">批量导入</span>
        </v-card-title>
        <v-card-text>
          <el-form label-width="80px">
            <el-form-item label="上传文件">
              <el-upload
                class="upload-demo"
                action
                :auto-upload="false"
                :on-change="handleChange"
                :file-list="fileList"
                multiple
              >
                <el-button
                  type="primary"
                  style="
                    color: white;
                    background-color: #14b2c5;
                    border: #14b2c5 1px solid;
                  "
                  size="small"
                  >点击上传</el-button
                >
              </el-upload>
            </el-form-item>
          </el-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> 取消 </v-btn>
          <v-btn color="primary" @click="isSure" text-color="#fff"> 确认 </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </v-card>
  </div>
</template>

<script>
import { exportResource } from "@/util/excel_export";
export default {
  props: ["resource", "title"],
  data() {
    return {
      template_download_url: `${this.global.upload_url}excel/import_pharmacy_drug.xlsx`,
      fileList: [],
      dialog: false,
      asideTitle: null,
      id: null,
      item: null,
      show: false,
      create: false,
      filters: [
        { alwaysOn: true, label: "药品名称", source: "drug_name" },
        {
          alwaysOn: true,
          label: "分类名称",
          source: "category_id",
          type: "select",
          attributes: {
            reference: "bs_drug_category",
            itemText: "category_name",
          },
        },
      ],
      filter: {},
      fields: [
        { source: "bs_pharmacy_management.pharmacy_name", label: "药房名称" },
        "drug_code",
        "drug_name",
        "standards",
        { source: "bs_drug_type.name", label: "药品类型" },
        { source: "bs_drug_preparation_type.type_name", label: "制剂类型" },
        { source: "bs_drug_category.category_name", label: "分类" },
        "origin_place",
        "manufacturer",
        { source: "valid_date", type: "date" },
        "inventory_quantity",
        "measurement_unit",
        "cost_unit_price",
        "cost_amount",
        "retail_unit_price",
        "retail_amount",
        { source: "is_active", type: "boolean" },
      ],
    };
  },
  methods: {
    on_export_excel() {
      let params = this.$router.currentRoute.query;
      exportResource(
        this.$admin.http,
        "/base-system/export_pharmacy_drug",
        params,
        "药品目录.xlsx"
      );
    },
    //导入
    async isSure() {
      let excel_file = this.fileList[0].raw;
      let formData = new FormData();
      formData.append("excel_file", excel_file);
      await this.$admin.http.post("/base-system/import_pharmacy_drug/", formData);
      this.dialog = false;
    },
    handleChange(file, fileList) {
      this.fileList = fileList;
    },
    on_show_import() {
      this.dialog = true;
    },
    async onAction({ action, title, id, item }) {
      this.asideTitle = title;
      this.id = id;
      this.show = action === "show";
      this.item = item;
      console.log(this.item);
      if (action === "create") {
        this.id = null;
      }
    },
    onSaved() {
      this.asideTitle = null;
      this.$refs.list.fetchData();
    },
  },
};
</script>
