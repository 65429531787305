<template>
  <v-card :icon="resource.icon" :title="title">
    <v-btn-toggle
      class="custom_btn"
      v-model="toggle_release"
      mandatory
      color="#214578"
    >
      <v-btn @click="infoList"> 资讯清单 </v-btn>
      <v-btn @click="postedList"> 我发布的 </v-btn>
    </v-btn-toggle>
    <va-list
      :filters="filters"
      :filter="filter"
      disable-export
      :include="['bs_hospital', 'base_system_user', 'htm_type']"
      :sort-by="['created_time']"
      :sort-desc="[true]"
      global-search-query="bs_hospital#name@_like,htm_type#name@_like,title@_like,base_system_user#name@_like"
    >
      <va-data-table :fields="fields" disable-clone> </va-data-table>
    </va-list>
  </v-card>
</template>

<script>
export default {
  props: ["resource", "title"],
  data() {
    return {
      toggle_release: 0,
      filter: {},
      filters: [
        {
          source: "hospital_id",
          label: "医院名称",
          type: "select",
          alwaysOn: true,
          attributes: { reference: "bs_hospital" },
        },
        {
          source: "htm_type_id",
          label: "资讯类型",
          type: "select",
          alwaysOn: true,
          attributes: { reference: "htm_type" },
        },
        {
          alwaysOn: true,
          label: "标题",
          source: "title",
        },
      ],
      fields: [
        { source: "htm_type.name", label: "资讯类型" },
        "title",
        { source: "bs_hospital.name", label: "医院名称" },
        { source: "base_system_user.name", label: "发布人" },
        {
          source: "created_time",
          type: "date",
          attributes: { format: "longTime" },
        },
        // "updated_time",
      ],
    };
  },
  methods: {
    infoList() {
      this.filter = {};
    },
    postedList() {
      this.filter = {
        created_by_id: JSON.parse(window.sessionStorage.getItem("user")).id,
      };
    },
  },
};
</script>
<style lang="sass" scoped>
.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined)
  background-color: #eee
  color: #14B2C5
  &:hover
    background-color: #ccc
    color: #214578
.custom_btn.v-item-group

.v-btn.v-size--default
  margin-top: 5px !important
  font-size: 1rem
</style>