<template>
  <va-form :id="id" :item="item" @saved="$emit('saved')">
    <v-card-text style="padding-bottom: 80px;">
      <va-text-input
        source="pharmacy_code"
        v-model="form.pharmacy_code"
      ></va-text-input>
      <va-text-input
        source="pharmacy_name"
        v-model="form.pharmacy_name"
      ></va-text-input>
      <va-select-input
        required
        label="药房分类"
        source="pharmacy_type_id"
        item-text="name"
        reference="bs_pharmacy_type"
        :items-per-page="1000"
        :filter="{ is_active: true }"
        v-model="form.pharmacy_type_id"
      ></va-select-input>

      <va-select-input
        v-show="show"
        label="所属医院"
        reference="bs_hospital"
        :items-per-page="1000"
        :filter="{ is_active: true }"
        source="hospital_id"
        v-model="form.hospital_id"
      ></va-select-input>
      <va-select-input
        v-show="!show"
        label="所属药企"
        reference="bs_pharmacy_enterprise"
        :items-per-page="1000"
        :filter="{ is_active: true }"
        source="enterprise_id"
        v-model="form.enterprise_id"
      ></va-select-input>

      <va-text-input source="address" v-model="form.address"></va-text-input>

      <va-boolean-input
        source="is_active"
        v-model="form.is_active"
      ></va-boolean-input>
    </v-card-text>
    <div class="btn_bg">
      <v-btn @click="isSure" color="primary">保存</v-btn>
    </div>
  </va-form>
</template>

<script>
export default {
  props: ["id", "title", "item"],
  data() {
    return {
      show: true,
      form: {
        pharmacy_code: null,
        pharmacy_name: null,
        pharmacy_type_id: null,
        address: null,
        enterprise_id: undefined,
        hospital_id: undefined,
        is_active: true,
      },
    };
  },
  methods: {
    async isSure() {
      if (
        !this.form.pharmacy_code ||
        !this.form.pharmacy_name ||
        !this.form.pharmacy_type_id ||
        !this.form.address
      ) {
        this.$message({
          message: "有必填项未填写",
          type: "warning",
        });
        return;
      }
      if (!this.id) {
        try {
          await this.$store
            .dispatch("bs_pharmacy_management/create", {
              data: this.form,
            })
            .then((res) => {
              this.$emit("saved");
            });
        } catch {
          // this.$message({
          //   message: "药房编码违反唯一性，请修改",
          //   type: "warning",
          // });
        }
      } else {
        try {
          await this.$store
            .dispatch("bs_pharmacy_management/update", {
              id: this.id,
              data: this.form,
            })
            .then((res) => {
              this.$emit("saved");
            });
        } catch {
          this.$message({
            message: "添加失败",
            type: "warning",
          });
        }
      }
    },
  },
  mounted() {},
  watch: {
    "form.pharmacy_type_id": {
      deep: true,
      immediate: true,
      handler(val) {
        if (val == 1) {
          this.show = true;
          this.form.hospital_id = JSON.parse(
            window.sessionStorage.getItem("user")
          ).hospital;
        } else {
          this.show = false;
        }
      },
    },
  },
};
</script>
<style scoped>
.v-application--is-ltr .v-input__prepend-outer {
  display: none;
}
.btn_bg {
  width: 95%;
  position: fixed;
  bottom: 0px;
  padding: 10px 0;
  text-align: center;
  background-color: white;
}
</style>