<!--
 * @Description: 
 * @Author: your name
 * @Date: 2023-05-05 17:16:25
 * @LastEditors: your name
 * @LastEditTime: 2023-08-31 10:00:27
-->
<template>
  <div>
    <div>
      <v-card class="v_card">
        <div style="line-height: 60px">
          <span class="dot_circle"></span
          ><span class="title_con">床位预约参数配置</span>
        </div>
        <div>
          <v-row style="display: flex; align-items: center">
            <v-col sm="2" class="vColBox" style="display: flex">
              <!-- <va-number-input
                label="可提前预约天数(天)"
                v-model="form.advance_day"
                source="advance_day"
              ></va-number-input> -->
              <v-btn
                small
                v-if="$admin.can(['change_bedappointmentconfig'])"
                @click="reduce"
                style="color: #42b6c4"
                ><v-icon>mdi-minus</v-icon></v-btn
              >

              <input
                class="inputBox"
                type="number"
                v-model="form.advance_day"
                placeholder="可提前预约天数(天)"
                source="advance_day"
                disabled
                max="100"
                min="0"
              />
              <v-btn
                small
                @click="add"
                v-if="$admin.can(['change_bedappointmentconfig'])"
                color="primary"
              >
                <v-icon>mdi-plus</v-icon></v-btn
              >
            </v-col>
            <div style="margin-left: 10px; color: rgb(168, 152, 150)">
              可提前预约天数(天)
            </div>
          </v-row>
        </div>
      </v-card>
      <div class="btn_bg">
        <v-btn
          @click="isSure"
          color="primary"
          v-if="$admin.can(['change_bedappointmentconfig'])"
          >保存</v-btn
        >
        <!-- <va-save-button></va-save-button> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["resource", "title"],
  data() {
    return {
      form: {
        is_active: true,
        advance_day: "",
      },
      id: "",
    };
  },
  computed: {},
  created() {
    this.getConfigDay();
  },
  watch: {
    // "form.advance_day": {
    //   deep: true,
    //   handler: function (newVal) {
    //     debugger
    //     var reg = /^(0|[1-9][0-9]*)$/;
    //     if (reg.test(newVal)) {
    //       return newVal;
    //     } else {
    //       return 0;
    //     }
    //   },
    // },
  },
  methods: {
    add() {
      this.form.advance_day++;
    },
    reduce() {
      if (this.form.advance_day == 0) {
        this.form.advance_day = 0;
      } else {
        this.form.advance_day--;
      }
    },
    getConfigDay() {
      this.$store.dispatch("hap_bed_apt_config/getList").then((res) => {
        if (res.data.length > 0) {
          this.form.advance_day = res.data[0].advance_day;
          this.id = res.data[0].id;
        }
      });
    },
    async isSure() {
      this.form.advance_day = this.form.advance_day.toString();
      if (this.id) {
        this.$store
          .dispatch("hap_bed_apt_config/update", {
            id: this.id,
            data: this.form,
          })
          .then((res) => {
            console.log(res, "resersres");
          });
      } else {
        this.$store
          .dispatch("hap_bed_apt_config/create", { data: this.form })
          .then((res) => {
            console.log(res, "resersres");
          });
      }
    },
  },
};
</script>
<style scoped>
.v_card {
  padding: 20px;
  margin-bottom: 20px;
}

.red_sp {
  color: red;
}

.dot_circle {
  display: inline-block;
  background-color: #069cae;
  width: 8px;
  height: 8px;
  margin-right: 10px;
  border-radius: 8px;
  margin-bottom: 2px;
}

.title_con {
  display: inline-block;
  font-size: 20px;
}

.btn_bg {
  width: 80%;
  position: fixed;
  bottom: 0px;
  padding: 10px 0;
  text-align: center;
  background-color: white;
}
.inputBox {
  width: 100%;
  height: 50px;
  background-color: #f0f0f0;
  padding: 5px;
  margin: 0px 10px;
}
.vColBox {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
