<template>
  <va-layout>
    <AppBar
      slot="app-bar"
      @getpath="getpath"
      :header-menu="headerMenu"
      :profile-menu="profileMenu"
      color="#182B5C"
      dense
      disableCreate
      dark
      @toggle="
        $vuetify.breakpoint.lgAndUp ? (mini = !mini) : (drawer = !drawer)
      "
    ></AppBar>

    <Sidebar
      @menuChange="menuChange"
      slot="sidebar"
      :pathnow="pathnow"
      v-model="drawer"
      :mini-variant="mini"
      color="#182B5C"
    ></Sidebar>
    <template slot="header">
      <!-- <breadcrumbs></breadcrumbs> -->
      <div class="breadcrumbs">
        <div class="breadcrumbs_son" v-for="(item, i) in breadList" :key="i">
          <div
            v-if="i == 0"
            style="margin-left: 12px"
            :class="{ active: ctnum == i }"
            @click="ctnum = i"
          >
            <router-link :to="item.path" style="text-decoration: none">
              {{ item.name }}
            </router-link>
          </div>
          <div v-else :class="{ active: ctnum == i }" @click="ctnum = i">
            <!-- <router-link  @click.stop="get_icon_class(item)" :to="item.path" style="text-decoration:none;">
              {{item.name}}
            </router-link> -->
            <span
              @click="get_icon_class(item)"
              style="text-decoration: none; display: inline-block"
            >
              {{ item.name }}
            </span>
            <div class="icon_sty" @click.stop="getme(item)">×</div>
          </div>
        </div>
        <!-- <div class="breadcrumbs_son">
           首页你好
        </div> -->
      </div>
      <!-- <el-breadcrumb separator="|" style="height:57px;line-height:57px;padding-left: 20px;background-color: white;">
        <el-breadcrumb-item
          v-for="(item,index) in breadList"
          :key="index"
          :to="{ path: item.path }"
          ><span @click="getme(this)">{{item.name}}</span></el-breadcrumb-item>
      </el-breadcrumb> -->
      <impersonate-message></impersonate-message>
    </template>
    <Aside slot="aside" :width="width"></Aside>
  </va-layout>
</template>

<script>
import ImpersonateMessage from "@/components/ImpersonateMessage";
import Sidebar from "@/components/Sidebar";
// import breadcrumbs from "@/components/breadcrumbs";

import Aside from "@/components/Aside.vue";
import AppBar from "@/components/AppBar";

export default {
  props: {
    /**
     * Width of the aside
     */
    width: {
      type: Number,
      default: 500,
    },
  },
  name: "App",
  components: {
    ImpersonateMessage,
    Sidebar,
    Aside,
    AppBar,
    
    // breadcrumbs
  },
  data() {
    return {
      ctnum: 0,
      drawer: null,
      mini: false,
      headerMenu: [
        // ...this.$admin.getResourceLinks([
        //   // "/dashboard",
        //   // "authors",
        //   // "categories",
        //   // "books",
        //   // "reviews",
        // ]),
        {
          href: "/dashboard",
          text: "首页",
        },
        // {
        //   href: "/month-plans",
        //   text: "月度计划报表",
        // },
      ],
      footerMenu: [],
      profileMenu: [
        {
          icon: "mdi-account",
          text: this.$t("menu.profile"),
          link: "/profile",
        },
        {
          icon: "mdi-pencil",
          text: this.$t("menu.password"),
          link: "/password",
        },
      ],
      // sidebarMenu: [],
      breadList: [
        {
          name: "首页",
          path: "/dashboard",
        },
      ],
      pathnow: "",
    };
  },
  mounted() {
    this.arrowS();
  },
  watch: {
    ctnum: {
      handler(val) {
        this.pathnow = this.breadList[val].path;
      },
    },
  },
  methods: {
    get_icon_class(data) {
      this.$router.push(data.path);
    },
    getpath(data) {
      this.pathnow = data;
      let index = this.breadList.findIndex(function (item) {
        return item.path === data;
      });
      if (index > 0 || index == 0) {
        this.ctnum = index;
      }
    },
    getme(data) {
      let index = this.breadList.findIndex(function (item) {
        return item.path === data.path;
      });
      //删除路由
      this.breadList.splice(index, 1);
      //跳转当前路由的上一个路由
      this.$router.push(this.breadList[index - 1].path);
      this.ctnum = index - 1;
      this.pathnow = this.$router.path;
    },
    menuChange(data) {
      let path = data.path;
      let flags = true;
      this.breadList.forEach((item) => {
        if (item.path === path) {
          flags = false;
        }
      });
      if (flags) {
        this.breadList.push(data);
        this.ctnum = this.breadList.length - 1;
        setTimeout(() => {
          $(".el-breadcrumb__inner").css("color", "white");
        }, 10);
      } else {
        let index = this.breadList.findIndex(function (item) {
          return item.path === path;
        });
        this.ctnum = index;
      }

      // if (icon_class != null) {
      //   sessionStorage.setItem("icon_class", icon_class)
      //   this.breadList.forEach((item) => {
      //     if (item.path === path && item.icon_class === icon_class) {
      //       flags = false;
      //     }
      //   });
      //   if (flags) {
      //     this.breadList.push(data);
      //     this.ctnum = this.breadList.length - 1;
      //     setTimeout(() => {
      //       $(".el-breadcrumb__inner").css("color", "white");
      //     }, 10);

      //   } else {
      //     let index = this.breadList.findIndex(function (item) {
      //       return item.path === path;
      //     })
      //     this.ctnum = index
      //   }

      // } else {
      //   window.sessionStorage.removeItem("icon_class")
      //   this.breadList.forEach((item) => {
      //     if (item.path === path) {
      //       flags = false;
      //     }
      //   });
      //   if (flags) {
      //     this.breadList.push(data);
      //     this.ctnum = this.breadList.length - 1;
      //     setTimeout(() => {
      //       $(".el-breadcrumb__inner").css("color", "white");
      //     }, 10);

      //   } else {
      //     let index = this.breadList.findIndex(function (item) {
      //       return item.path === path;
      //     })
      //     this.ctnum = index
      //   }
      // }
    },
    arrowS() {
      $(".el-breadcrumb__inner").css("color", "#14b2c5");
    },
  },
};
</script>
<style scoped>
.breadcrumbs {
  display: flex;
  height: 57px;
  line-height: 57px;
  background-color: white;
  overflow-x: auto;
  cursor:pointer;
}

.breadcrumbs_son1 {
  height: 16px;
  line-height: 18px;
  padding: 0px 16px;
  margin-top: 20px;
  color: #a3ebf3;
  border-right: 1px solid #14b2c5;
  position: relative;
}

.breadcrumbs_son {
  background-color: #f6f9fd;
  line-height: 0px;
  margin: 15px 0px 15px 8px;
  color: #14b2c5;
  padding: 15px 15px 15px 10px;
  border-left: 1px solid #14b2c5;
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
}

.active {
  font-weight: 600;
}

.icon_sty {
  font-size: 12px;
  width: 12px;
  height: 12px;
  line-height: 10px;
  text-align: center;
  border-radius: 10px;
  position: absolute;
  right: -4px;
  top: -2px;
  color: #f5f1f1;
  z-index: 2;
  background-color: #b5afaf;
}
::-webkit-scrollbar {
  background-color: #ffffff !important;
  border-radius: 8px;
  width: 0px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  height: 3px;
}
.v-application .v-breadcrumbs a {
  color: #069cae !important;
}
</style>
