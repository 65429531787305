<template>
  <div>
    <va-aside-layout :title="asideTitle">
      <bs-drug-directory-show v-if="show" :item="item"></bs-drug-directory-show>
      <bs-drug-directory-form
        v-else
        :id="id"
        :item="item"
        @saved="onSaved"
      ></bs-drug-directory-form>
    </va-aside-layout>
    <v-card :icon="resource.icon" :title="title">
      <va-list
        ref="list"
        :filters="filters"
        :filter="filter"
        disableExport
        disable-global-search
        disable-create-redirect
        @action="onAction"
        :include="[
          'bs_hospital',
          'bs_drug_preparation_type',
          'bs_drug_category',
          'bs_drug_type',
        ]"
        :sort-by="['id']"
        :sort-desc="[true]"
      >
      <template v-slot:actions>
          <va-action-button
          icon="mdi-briefcase-download-outline"
          label="下载模版"
          :href="template_download_url"
          color="green"
          text
        >
        </va-action-button>
        <va-action-button
          icon="mdi-import"
          color="green"
          label="导入"
          @click="on_show_import"
          text
        >
        </va-action-button>
          <va-action-button
            icon="mdi-arrow-collapse-down"
            color="green"
            label="导出"
            text
            @click="on_export_excel"
          >
          </va-action-button>
        </template>
        <va-data-table
          disable-show
          :fields="fields"
          disable-show-redirect
          disable-clone
          disable-edit-redirect
          @item-action="onAction"
        >
          <template v-slot:[`field.is_essential`]="{ item }">
            <template v-if="item.is_essential==true">是</template>
            <template v-else>否</template>
          </template>
        </va-data-table>
      </va-list>
      <v-dialog v-model="dialog" width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">批量导入</span>
        </v-card-title>
        <v-card-text>
          <el-form label-width="80px">
            <el-form-item label="上传文件">
              <el-upload
                class="upload-demo"
                action
                :auto-upload="false"
                :on-change="handleChange"
                :file-list="fileList"
                multiple
              >
                <el-button
                  type="primary"
                  style="
                    color: white;
                    background-color: #14b2c5;
                    border: #14b2c5 1px solid;
                  "
                  size="small"
                  >点击上传</el-button
                >
              </el-upload>
            </el-form-item>
          </el-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> 取消 </v-btn>
          <v-btn color="primary" @click="isSure" text-color="#fff"> 确认 </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </v-card>
  </div>
</template>

<script>
import { exportResource } from "@/util/excel_export";
export default {
  props: ["resource", "title"],
  data() {
    return {
      template_download_url: `${this.global.upload_url}excel/import_drug_directory.xlsx`,
      fileList: [],
      dialog: false,
      asideTitle: null,
      id: null,
      item: null,
      show: false,
      create: false,
      filters: [
        {
          alwaysOn: true,
          label: "制剂类型",
          source: "preparation_type_id",
          type: "select",
          attributes: {
            reference: "bs_drug_preparation_type",
            itemText: "type_name",
          },
        },
        {
          alwaysOn: true,
          label: "分类",
          source: "category_id",
          type: "select",
          attributes: {
            reference: "bs_drug_category",
            itemText: "category_name",
          },
        },
      ],
      filter: {},
      fields: [
        // { source: "bs_hospital.name", label: "所属医院" },
        "drug_code",
        "drug_name",
        "standards",
        "units",
        { source: "bs_drug_preparation_type.type_name", label: "制剂类型" },
        { source: "bs_drug_type.name", label: "药品类型" },
        { source: "bs_drug_category.category_name", label: "分类" },
        "origin_place",
        "manufacturer",
        "unit_dose",
        "measure_unit",
        "stock_left",
        "stock_unit",
        "mic",
        "rcc_category",
        "is_essential",
        "hr_level",
        "gb_code",
        "gb_name",
        // "price",
      ],
    };
  },
  mounted() {
    if (JSON.parse(window.sessionStorage.getItem("user")).is_superuser) {
      this.filter = {};
    } else {
      // let hospital_id = JSON.parse(
      //   window.sessionStorage.getItem("user")
      // ).hospital;
      // this.filter = {
      //   hospital_id: hospital_id,
      // };
    }
  },
  methods: {
    on_export_excel() {
      let params = this.$router.currentRoute.query;
      exportResource(
        this.$admin.http,
        "/base-system/export_drug_directory",
        params,
        "药品目录.xlsx"
      );
    },
    //导入
    async isSure() {
      let excel_file = this.fileList[0].raw;
      let formData = new FormData();
      formData.append("excel_file", excel_file);
      await this.$admin.http.post("/base-system/import_drug_dir/", formData);
      this.dialog = false;
    },
    handleChange(file, fileList) {
      this.fileList = fileList;
    },
    on_show_import() {
      this.dialog = true;
    },
    async onAction({ action, title, id, item }) {
      this.asideTitle = title;
      this.id = id;
      this.show = action === "show";
      this.item = item;
      if (action === "create") {
        this.id = null;
      }
    },
    onSaved() {
      this.asideTitle = null;
      this.$refs.list.fetchData();
    },
  },
};
</script>
