<template>
  <v-container fluid fill-height id="login-page">
    <v-layout align-center id="card">
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </v-layout>
  </v-container>
</template>
 
 <script>
export default {
  data() {
    return {};
  },
  mounted() {},

  methods: {},
};
</script>
 <style scoped>
</style>
 