<template>
  <va-create-layout>
    <er-management-main-form :title="title" :item="item"></er-management-main-form>
  </va-create-layout>
</template>

<script>
export default {
  props: ["title", "item"],
};
</script>
