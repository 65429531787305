<template>
	<va-form :id="id" :item="item">
		<v-row justify="center">
			<v-col sm="6">
				<base-material-card>
					<template v-slot:heading>
						<div class="display-2">
							{{ title }}
						</div>
					</template>
					<v-card-text>
						<!--             
            <v-select
              v-model="xman.item_type"
              :items="choices"
              menu-props="auto"
              label="Select"
              hide-details
              prepend-icon="mdi-map"
              single-line
            ></v-select> -->

						<v-select v-model="xman.item_type" :items="choices" menu-props="auto" label="Select"
							hide-details single-line></v-select>

						<va-text-input source="item_name" v-model="xman.item_name"></va-text-input>

						<!-- <va-select-field source="item_type" v-model="xman.item_type" chip :choices="choices"></va-select-field> -->

						<va-text-input source="remark" v-model="xman.remark"></va-text-input>

					</v-card-text>
					<v-btn @click="isSure" color="primary">保存</v-btn>
				</base-material-card>
			</v-col>
		</v-row>
	</va-form>
</template>

<script>
	export default {
		props: ["id", "title", "item"],
		data() {
			return {
				choices: [{
						value: "1",
						text: "检查类型"
					},
					{
						value: "2",
						text: "基本操作"
					},
					{
						value: "3",
						text: "系统"
					},
					{
						value: "4",
						text: "部位"
					}
				],
				xman: {
					item_name: "",
					item_type: 1,
					remark: "",
				},
				// ... 你的其他数据属性 ...
				menu: false,
				date: null, // 用于v-date-picker的模型
				time: null, // 用于v-time-picker的模型
				asideTitle: null,
				show: false,
				// ... 你的其他数据属性 ...
			};
		},
		methods: {
			async isSure() {
				this.xman.item_type = Number(this.xman.item_type);
				if (this.xman.item_type > 5 || this.xman.item_type < 1) {
					this.xman.item_type = null;
					this.$message({
						message: "类型仅限输入数字，且大于0，小于4，请修改",
						type: "error",
					});
					return;
				}
				if (this.id) {
					try {
						await this.$store
							.dispatch("ig_item_type/update", {
								id: this.id,
								data: this.xman,
							})
							.then((res) => {
								this.$emit("saved");
								this.$router.push({
									path: "/ig-item-type/"
								});
							});
					} catch {
						// this.$message({
						//   message: "医院编码违反唯一性，请修改",
						//   type: "error",
						// });
					}
				} else {
					await this.$store
						.dispatch("ig_item_type/create", {
							data: this.xman,
						})
						.then((res) => {
							this.$emit("saved");
							this.$router.push({
								path: "/ig-item-type/"
							});
							// if (this.file_data && this.file_data.length >= 1) {
							//   this.httpRequest(this.imgfile, res.data.id, res.data.logo);
							// }
						})
						.catch((err) => {
							// this.$message({
							//   message: "医院编码违反唯一性，请修改",
							//   type: "error",
							// });
						});
				}
			},
			close() {},
		},
	};
</script>