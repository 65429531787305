<template>
  <v-navigation-drawer
    :clipped="$vuetify.breakpoint.lgAndUp"
    right
    app
    disable-resize-watcher
    :width="width"
    v-model="opened"
  >
    <div class="pa-4">
      <div class="d-flex align-center">
        <h3 class="display-1">
          <portal-target name="aside-title"></portal-target>
        </h3>
        <v-btn class="close" icon @click="open">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div class="content">
        <portal-target name="aside-content" @change="handleUpdate">
        </portal-target>
        <template>
          <va-form :id="id">
            <v-row>
              <v-col>
                <span>患者姓名：{{ patient.pat_name }}</span>
              </v-col>
              <v-col>
                <span>健康卡ID：{{ patient.health_card_id }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span v-if="patient.pat_gender == 1">患者性别：男</span>
                <span v-else>患者性别：女</span>
              </v-col>
              <v-col>
                <span>患者年龄：{{ patient.pat_age }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span>医院名称：{{ doctor.hospital_name }}</span>
              </v-col>
              <v-col>
                <span>科室名称：{{ doctor.office_name }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span>就诊日期：{{ treatment_date }}</span>
              </v-col>
              <v-col>
                <span>接诊医生：{{ doctor.name }}</span>
              </v-col>
            </v-row>
            <v-row style="margin-bottom: 15px">
              <v-col>
                <span>就诊类型：复诊</span>
              </v-col>
              <v-col> </v-col>
            </v-row>
            <va-text-input
              label="主诉"
              multiline
              v-model="chief_complaint"
            ></va-text-input>
            <va-text-input
              label="现病史"
              multiline
              v-model="present_illness_history"
            ></va-text-input>
            <va-text-input
              label="既往史"
              multiline
              v-model="past_illness_history"
            ></va-text-input>
            <va-text-input
              label="过敏史"
              multiline
              v-model="allergic_history"
            ></va-text-input>
            <va-text-input
              label="体格检查"
              multiline
              v-model="health_checkup"
            ></va-text-input>
            <va-text-input
              label="辅助检查"
              multiline
              v-model="auxiliary_checkup"
            ></va-text-input>
            <va-text-input
              label="处理意见"
              multiline
              v-model="handle_opinion"
            ></va-text-input>
            <v-row>
              <v-col>
                <va-text-input
                  v-model="diagnosis"
                  label="初步诊断"
                  required
                ></va-text-input>
              </v-col>
            </v-row>
            <v-row style="padding-bottom: 100px">
              <v-col>
                <v-data-table
                  :headers="headers"
                  :items="desserts"
                  :items-per-page="5"
                  class="elevation-1"
                  @click:row="rowClicked"
                  hide-default-footer
                ></v-data-table>
                <div
                  style="width: 100%; display: flex; justify-content: flex-end"
                >
                  <v-pagination
                    v-model="page"
                    :length="length_data"
                    :total-visible="7"
                  ></v-pagination>
                </div>
              </v-col>
            </v-row>
            <div class="btn_bg">
              <v-btn @click="isSure" color="primary">保存</v-btn>
              <!-- <va-save-button></va-save-button> -->
            </div>
          </va-form>
        </template>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import moment from "moment";
import Utils from "../global/utils";
export default {
  props: {
    width: {
      type: Number,
      default: 520,
    },
  },
  data() {
    return {
      page: 1,
      length_data: null,
      chief_complaint: null,
      present_illness_history: null,
      past_illness_history: null,
      allergic_history: null,
      health_checkup: null,
      handle_opinion: null,
      auxiliary_checkup: null,
      user: null,
      treatment_date: moment().format("YYYY-MM-DD"),
      id: null,
      opened: false,
      patient: null,
      doctor: null,
      diagnosis: null,
      diagnosis_name: null,
      diagnosis_code: null,
      headers: [
        {
          text: "诊断编码",
          align: "center",
          sortable: false,
          value: "code",
        },
        {
          text: "诊断名称",
          align: "center",
          sortable: false,
          value: "name",
        },
      ],
      desserts: [],
      selected: [],
      code_ent: null,
      recorde: null,
    };
  },
  created() {},
  methods: {
    getData() {
      this.$admin.http
        .get(
          `online-recheck/patcase/?code_pat=${this.patient.pat_codenum}&code_ent=${this.code_ent}`
        )
        .then((data) => {
          this.recorde = data.data.results[0];
          if (data.data.results.length > 0) {
            this.chief_complaint = this.recorde.chief_complaint;
            this.present_illness_history = this.recorde.present_illness_history;
            this.past_illness_history = this.recorde.past_illness_history;
            this.allergic_history = this.recorde.allergic_history;
            this.health_checkup = this.recorde.health_checkup;
            this.auxiliary_checkup = this.recorde.auxiliary_checkup;
            this.handle_opinion = this.recorde.handle_opinion;
            this.diagnosis = this.recorde.diagnosis;
          }
        });
    },
    // 获取诊断
    getDiagnosis(val,page) {
      this.$admin.http
        .get(`/online-recheck/diarcs/?name=${val}&page=${page}&page_size=5`)
        .then((res) => {
          this.desserts = res.data.results;
          this.length_data = (res.data.count / 5) % 1 === 0
              ? res.data.count / 5
              : parseInt(res.data.count / 5) + 1;
        });
    },
    query_info() {
      this.user = JSON.parse(window.sessionStorage.getItem("user"));
      this.$admin.http
        .get(
          `patient-account/online_therapy/?appoint_type=4&doctor_id=${this.user.doctor}`
        )
        .then((res) => {
          res.data.results.forEach((item) => {
            if (item.id == this.id) {
              this.patient = item;
            }
          });
        });
      this.$admin.http
        .get(`/base-system/doctors/${this.user.doctor}/`)
        .then((doctor) => {
          this.doctor = doctor.data;
        });
    },
    handleUpdate(val, code_ent, opened) {
      this.code_ent = code_ent;
      this.id = val;
      this.opened = opened;
      this.query_info();
      this.getData();
    },
    open() {
      this.opened = false;
    },
    rowClicked(row) {
      // console.log(row);
      this.selected = [];
      this.selected.push(row);
      this.diagnosis = row.name;
      this.diagnosis_name = row.name;
      this.diagnosis_code = row.code;
    },
    isSure() {
      let data = {
        code_healthcard: this.patient.health_card_id,
        code_pat: this.patient.health_card_id,
        ent_type: "复诊",
        diagnosis_code: this.diagnosis_code,
        diagnosis_name: this.diagnosis_name,
        handle_opinion: this.handle_opinion,
        treatment_date: this.treatment_date,
        is_active: false,
        doctor_code: this.doctor.job_number,
        doctor_name: this.doctor.name,
        office_code: this.doctor.office_code,
        office_name: this.doctor.office_name,
        hospital_code: this.doctor.hospital_code,
        hospital_name: this.doctor.hospital_name,
        code_ent: this.patient.code_apt,
        chief_complaint: this.chief_complaint,
        present_illness_history: this.present_illness_history,
        past_illness_history: this.past_illness_history,
        allergic_history: this.allergic_history,
        health_checkup: this.health_checkup,
        auxiliary_checkup: this.auxiliary_checkup,
        diagnosis: this.diagnosis_name,
        created_time: moment().format("YYYY-MM-DD HH:mm:ss"),
        updated_time: moment().format("YYYY-MM-DD HH:mm:ss"),
        created_by: this.user.name,
        updated_by: this.user.name,
        code_ent: this.code_ent,
      };
      if (!this.recorde) {
        // console.log("新建", data);
        this.$admin.http.post(`online-recheck/patcase/`, data).then((res) => {
          Utils.$emit("demo", { type: "病历", content: data.data });
          this.$message({
            message: "填写成功",
            type: "success",
          });
        });
      } else {
        // console.log("修改", data);
        this.$admin.http
          .put(`online-recheck/patcase/${this.recorde.id}/`, data)
          .then((res) => {
            Utils.$emit("demo", { type: "病历", content: data.data });
            this.$message({
              message: "修改成功",
              type: "success",
            });
          });
      }
      this.open();
    },
  },
  watch: {
    diagnosis: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val !== "") {
          this.getDiagnosis(val,this.page);
        }
      },
    },
    page: {
      deep: true,
      immediate: true,
      handler(val) {
        this.getDiagnosis(this.diagnosis,val);
      },
    },
    id: {
      deep: true,
      immediate: true,
      handler(val) {
        this.chief_complaint = null;
        this.present_illness_history = null;
        this.past_illness_history = null;
        this.allergic_history = null;
        this.health_checkup = null;
        this.auxiliary_checkup = null;
        this.handle_opinion = null;
        this.diagnosis = null;
        if (val) {
          if (this.patient) {
            this.getData();
          }
        }
      },
    },
  },
};
</script>

<style scoped>
.close {
  position: absolute;
  right: 1rem;
}
.content {
  margin-top: 3rem;
}
.btn_bg {
  width: 95%;
  position: fixed;
  bottom: 0px;
  padding: 10px 0;
  text-align: center;
  background-color: white;
}
</style>