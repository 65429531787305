<template>
  <va-show :item="item">
          <template v-slot:heading>
            <div class="display-2">
              {{ title }}
            </div>
          </template>
          <v-card-text>
            <va-field source="codenum"></va-field>
            <va-field source="name"></va-field>
            <va-field source="hospital_id">{{hospital}}</va-field>
            <!-- <va-field source="is_active" type="boolean"></va-field> -->
            <va-field source="created_time"  type="date" format= "longTime"></va-field>
            <va-field source="created_by"></va-field>
          </v-card-text>
  </va-show>
</template>

<script>
export default {
props: ["title", "item"],
data(){
return{
   hospital:''
}
},
mounted() {
 this.getdatas()
 
},
methods:{
  async getdatas(){
      let bs_hospital_getOne = await this.$store.dispatch(
        "bs_hospital/getOne",
        { id: this.item.hospital_id }
      );
      this.hospital=bs_hospital_getOne.data.name;
  },
}
};
</script>
