<template>
  <v-container>
	<v-card>
      <v-card-title>
        补打指引单
      </v-card-title>
      <v-card-text ref="printableContent">
        <v-form ref="form" v-model="valid">
			<v-row>
				<v-col sm="3">				
					<v-text-field
					  label="患者名称"
					  v-model="project.patientName"
					  :disabled="true"
					></v-text-field>
				</v-col>
				<v-col sm="3">				
					<v-text-field
					  label="性别"
					  v-model="project.sexName"
					  :disabled="true"
					></v-text-field>
				</v-col>
				<v-col sm="3">				
					<v-text-field
					  label="电话"
					  v-model="project.phoneNo"
					  :disabled="true"
					></v-text-field>
				</v-col>
				<v-col sm="3">				
					<v-text-field
					  label="开立医生"
					  v-model="project.reqDocName"
					  :disabled="true"
					></v-text-field>
				</v-col>
			</v-row>
			<v-row>
				<v-col sm="3">				
					<v-text-field
					  label="床号"
					  v-model="project.bedNo"
					  :disabled="true"
					></v-text-field>
				</v-col>
				<v-col sm="3">				
					<v-text-field
					  label="诊疗类型"
					  v-model="project.medicalTypeName"
					  :disabled="true"
					></v-text-field>
				</v-col>
				<v-col sm="3">				
					<v-text-field
					  label="诊疗流水号"
					  v-model="project.medicalListSerialNo"
					  type="bool"
					  :disabled="true"
					></v-text-field>
				</v-col>
				<v-col sm="3">				
					<v-text-field
					  label="资源组名称"
					  v-model="project.resourceGroupName"
					  :disabled="true"
					></v-text-field>
				</v-col>
			</v-row>
			<v-row>
				<v-col sm="3">				
					<v-text-field
					  label="开立时间"
					  v-model="project.reqDatetime"
					  :disabled="true"
					></v-text-field>
				</v-col>
				<v-col sm="3">				
					<v-text-field
					  label="年龄"
					  v-model="project.age"
					  :disabled="true"
					></v-text-field>
				</v-col>
				<v-col sm="3">				
					<v-text-field
					  label="开立科室"
					  v-model="project.reqDocDepartName"
					  :disabled="true"
					></v-text-field>
				</v-col>
				<v-col sm="3">				
					<v-text-field
					  label="申请单状态"
					  v-model="project.reqExecStatusName"
					  :disabled="true"
					></v-text-field>
				</v-col>
			</v-row>
			<v-row>
				<v-col sm="3">				
					<v-text-field
					  label="申请单号"
					  v-model="project.note"
					  :disabled="true"
					></v-text-field>
				</v-col>
				<v-col sm="3">				
					<v-text-field
					  label="身份证"
					  v-model="project.cardID"
					  :disabled="true"
					></v-text-field>
				</v-col>
				<v-col sm="3">				
					<v-text-field
					  label="医保卡号"
					  v-model="project.medicalSerialNo"
					  :disabled="true"
					></v-text-field>
				</v-col>
				<v-col sm="3">				
					<v-text-field
					  label="医院账户号"
					  v-model="project.hospitalCardNo"
					  :disabled="true"
					></v-text-field>
				</v-col>
			</v-row>
			<v-row>
				<v-col sm="6">				
					<v-text-field
					  label="检查项目"
					  v-model="project.idReqName"
					  :disabled="true"
					></v-text-field>
				</v-col>
				<v-col sm="3">				
					<v-text-field
					  label="预约检查时间"
					  v-model="project.reqTime"
					  :disabled="true"
					></v-text-field>
				</v-col>
			</v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
		<div class="btn_bg">
		  <v-btn @click="callPrint" color="primary">打  印</v-btn>
		  <v-btn @click="notSure" style="color: #14b2c5; margin-left: 10px">返回</v-btn>
		</div>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      valid: true,
      menu: false,
	  id: '',	
      selectedItemId: null,	  
      items: [],
      project: {
		  note: '',		  
          name: '',
          cardID: '',
          patientName: '',
		  printGuideline: null,
		  reqExecStatusName: null,
		  medicalTypeName: null,
          reqTime: '',
          idReq: 0,
          idReqName: '',
          idReqCode: '',
		  sexName: '',
      },
      statusOptions: [
        { text: '男', value: '男' },
        { text: '女', value: '女' },
      ]
    };
  },
  created() {
    this.id = this.$route.query.items;
    this.fetchProject();
  },
  methods: {
	notSure() {
		this.$router.push(
			"/ig-application-form" //跳转
		);
	},	
	callPrint() {
		
		  // 获取要转换的 DOM 元素
		  const element = this.$refs.printableContent;
		
		  // 使用 html2canvas 将 DOM 元素转换为画布
		  html2canvas(element).then(canvas => {
		    // 从画布创建图片
		    const image = canvas.toDataURL("image/png");
		
		    // 创建并打开一个新窗口
		    const printWindow = window.open('', '_blank');
		
		    // 设置新窗口的文档内容，显示图片
		    printWindow.document.write(`
		      <html>
		        <head><title>打印预览</title></head>
		        <body style="margin: 0; padding: 0; display: flex; justify-content: center; align-items: center; height: 100vh;">
		          <img src="${image}" style="max-width: 100%; max-height: 100vh;" onload="window.print(); window.close();">
		        </body>
		      </html>
		    `);
		
		    // 关闭文档的写入流
		    printWindow.document.close();
		  }).catch(error => {
		    console.error("Error in html2canvas: ", error);
		  });
	},
	
    async fetchProject() {
      try {
        const response = await this.$store.dispatch("ig_application_form/getOne", { id: this.id });
			this.project = response.data;
			console.log('366 ', this.project);
      } catch (error) {
        console.error("Failed to fetch project", error);
      }
    },
    async changePrintState() {
      if (this.$refs.form.validate()) {
        try {
          await this.$store.dispatch('ig_application_form/update', { id: this.id, data: this.project });
			this.$router.push('/ig-application-form'); // 假设有一个成功页面
        } catch (error) {
          console.error("Failed to submit review", error);
        }
      }
    }
  }
};
</script>


<style scoped>
@media print {
  body * {
    visibility: hidden;
  }
  .printable, .printable * {
    visibility: visible;
  }
  .printable {
    position: absolute;
    left: 0;
    top: 0;
  }
}
</style>

