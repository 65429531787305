<template>
  <div>
    <v-card :icon="resource.icon" :title="title">
      <va-list
        ref="list"
        :filters="filters"
        disable-global-search
        disable-pagination
        disable-create-redirect
        disable-export
        disable-create
        @action="onAction"
        resource="os_outpatient_registration"
        :sort-by="['id']"
        :sort-desc="[true]"
      >
        <!-- <template v-slot:actions>
            <va-action-button
            icon="mdi-briefcase-download-outline"
            label="下载模版"
            :href="template_download_url"
            color="green"
            text
          >
          </va-action-button>
          <va-action-button
            icon="mdi-import"
            color="green"
            label="导入"
            @click="on_show_import"
            text
          >
          </va-action-button>
            <va-action-button
              icon="mdi-arrow-collapse-down"
              color="green"
              label="导出"
              text
              @click="on_export_excel"
            >
            </va-action-button>
          </template> -->
        <template v-slot:[`filter.hospital_data1`]>
          <el-select
            class="select"
            style="margin: 0 20px 10px"
            :clearable="true"
            filterable
            v-model="hospital_data1"
            placeholder="请选择医院"
            :disabled="disabled"
          >
            <el-option
              v-for="item in options1"
              :key="item.id"
              :label="item.name"
              :value="item.codenum"
            >
            </el-option>
          </el-select>
          <el-select
            class="select"
            style="margin: 0 20px 10px"
            :clearable="true"
            filterable
            v-model="office_data1"
            placeholder="请选择科室"
          >
            <el-option
              v-for="item in options2"
              :key="item.id"
              :label="item.name"
              :value="item.codenum"
            >
            </el-option>
          </el-select>
        </template>
        <v-data-table
          @item-action="onAction"
          :headers="headers0"
          :items="desserts0"
          hide-default-footer
        >
          <template v-slot:[`item.created_time`]="{ item }">
            {{ moment(item.created_time).format("YYYY-MM-DD HH:mm:ss") }}
          </template>
          <template v-slot:[`item.state`]="{ item }">
            <template v-if="item.state == 1">
              <span>已预约</span>
            </template>
            <template v-else-if="item.state == '2'">
              <span>已完成</span>
            </template>
            <template v-else>
              <span>爽约</span>
            </template>
          </template>
          <!-- <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  class="mr-2"
                  color="blue"
                  @click="edit(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span>编辑</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  class="mr-2"
                  color="red"
                  @click="deleteData(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-trash-can
                </v-icon>
              </template>
              <span>删除</span>
            </v-tooltip>
          </template> -->
        </v-data-table>
        <div style="width: 100%; display: flex; justify-content: flex-end">
          <v-pagination
            v-model="page"
            :length="length_data"
            :total-visible="7"
          ></v-pagination>
        </div>
      </va-list>
      <v-dialog v-model="dialog" width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">批量导入</span>
          </v-card-title>
          <v-card-text>
            <el-form label-width="80px">
              <el-form-item label="上传文件">
                <el-upload
                  class="upload-demo"
                  action
                  :auto-upload="false"
                  :on-change="handleChange"
                  :file-list="fileList"
                  multiple
                >
                  <el-button
                    type="primary"
                    style="
                      color: white;
                      background-color: #14b2c5;
                      border: #14b2c5 1px solid;
                    "
                    size="small"
                    >点击上传</el-button
                  >
                </el-upload>
              </el-form-item>
            </el-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog = false"> 取消 </v-btn>
            <v-btn color="primary" @click="isSure" text-color="#fff">
              确认
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>
  
  <script>
import moment from "moment";
import { exportResource } from "@/util/excel_export";
export default {
  inject: ["reload"],
  props: ["resource", "title", "type"],
  data() {
    return {
      moment,
      template_download_url: `${this.global.upload_url}excel/ins_dic_import.xlsx`,
      fileList: [],
      dialog: false,
      page: 1,
      length_data: null,
      filters: [
        {
          source: "hospital_data1",
          alwaysOn: true,
        },
      ],
      desserts0: [],
      headers0: [
        { text: "挂号编码", value: "reg_code", sortable: false },
        { text: "挂号渠道", value: "", sortable: false },
        { text: "挂号医院", value: "hospital_name", sortable: false },
        { text: "挂号科室", value: "office_name", sortable: false },
        { text: "挂号医生", value: "doctor_name", sortable: false },
        { text: "患者姓名", value: "patient_name", sortable: false },
        { text: "身份证号", value: "id_num", sortable: false },
        { text: "就诊状态", value: "state", sortable: false },
        { text: "挂号时间", value: "created_time", sortable: false },
        { text: "就诊日期", value: "visit_date", sortable: false },
      ],
      hospital_data1: undefined,
      options1: [],
      office_data1: undefined,
      options2: [],
      hospital_data2: undefined,
      form: {},
      disabled: false,
    };
  },
  created() {
    this.user_data = JSON.parse(window.sessionStorage.getItem("user"));
    if (this.user_data.is_superuser) {
      this.disabled = false;
    } else {
      this.disabled = true;
    }
    if (this.type) {
      this.form.reg_type = this.type;
    } else {
      this.form.reg_type = 3;
    }
  },
  mounted() {
    this.getSelect();
  },
  methods: {
    adjustment() {
      let obj_key = Object.keys(this.form);
      let src_key = "";
      obj_key.forEach((key) => {
        src_key = src_key + "&" + key + "=" + `${this.form[key]}`;
      });
      src_key = src_key.slice(1);
      if (src_key) {
        this.$admin.http
          .get(`outpatient-scheduling/registrations/?${src_key}`)
          .then((res) => {
            this.length_data =
              (res.data.count / 10) % 1 === 0
                ? res.data.count / 10
                : parseInt(res.data.count / 10) + 1;
            this.desserts0 = res.data.results;
          });
      }
    },
    async onAction({ action, title, id, item }) {
      this.asideTitle = title;
      this.id = id;
      this.show = action === "show";
      this.item = item;
      if (action === "create") {
        this.id = null;
      }
    },
    onSaved() {
      this.asideTitle = null;
      this.$refs.list.fetchData();
    },
    getSelect() {
      let hospital_id = JSON.parse(
        window.sessionStorage.getItem("user")
      ).hospital;
      this.$store
        .dispatch("bs_hospital/getList", {
          filter: {
            id: hospital_id,
          },
        })
        .then((res) => {
          this.hospital_data1 = res.data[0].codenum;
        });
      this.$store.dispatch("bs_hospital/getList").then((res) => {
        this.options1 = res.data;
      });
      this.$store
        .dispatch("bs_office/getList", {
          filter: {
            hospital_id: hospital_id,
          },
        })
        .then((res) => {
          this.options2 = res.data;
        });
      if (this.hospital_data1 !== undefined) {
        this.adjustment();
      }
    },
    on_export_excel() {
      let params = this.$router.currentRoute.query;
      exportResource(
        this.$admin.http,
        "/base-system/export_ins_dic",
        params,
        "检查字典.xlsx"
      );
    },
    //导入
    async isSure() {
      let excel_file = this.fileList[0].raw;
      let formData = new FormData();
      formData.append("excel_file", excel_file);
      await this.$admin.http.post("/base-system/import_ins_dic/", formData);
      this.dialog = false;
    },
    handleChange(file, fileList) {
      this.fileList = fileList;
    },
    on_show_import() {
      this.dialog = true;
    },
  },
  watch: {
    hospital_data1: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
          delete this.form.hospital_code;
          this.form.hospital_code = val;
          this.$store
            .dispatch("bs_hospital/getList", {
              filter: {
                codenum: val,
              },
            })
            .then((res) => {
              this.$store
                .dispatch("bs_office/getList", {
                  filter: {
                    hospital_id: res.data[0].id,
                  },
                })
                .then((data) => {
                  this.options2 = data.data;
                });
            });
          this.adjustment();
        }
        if (val == "" || val == null || val == undefined) {
          delete this.form.hospital_code;
          this.adjustment();
        }
      },
    },
    office_data1: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val == "" || val == null || val == undefined) {
          delete this.form.office_code;
          this.adjustment();
        } else {
          delete this.form.office_code;
          this.form.office_code = val;
          this.adjustment();
        }
      },
    },
    page: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val == "" || val == null || val == undefined) {
          delete this.form.page;
          this.adjustment();
        } else {
          delete this.form.page;
          this.form.page = val;
          this.adjustment();
        }
      },
    },
  },
};
</script>
  <style lang="sass" scoped>
.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined)
  background-color: #eee
  color: #14B2C5
  &:hover
    background-color: #ccc
    color: #214578
.custom_btn.v-item-group
  left: 0
  position: absolute

.v-btn.v-size--default
  margin-top: 5px !important
  font-size: 1rem
</style>
  <style scoped>
::v-deep .el-input__inner {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1px #d2d2d2 solid !important;
  border-radius: 0;
  padding-bottom: 10px;
}
.select .el-icon-circle-close,
.el-input__prefix {
  display: block !important;
}

.custom_btn.v-item-group {
  left: 0;
  position: absolute;
}
</style>