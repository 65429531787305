<template>
  <base-material-card title="项目属性">
    <va-list :filters="filters" disable-global-search>
      <va-data-table :fields="fields">
        <template v-slot:[`field.item_type`]="{ item }">
          <span>{{ item.item_type | itemTypeText }}</span>
        </template>
      </va-data-table>
    </va-list>
  </base-material-card>
</template>

<script>
export default {
  props: ["resource", "title"],
  data() {
    return {
      filters: ["item_name", "item_type"],
      fields: [
        { source: "item_name", label: "名称", sortable: true },
        { source: "item_type", label: "类别", sortable: true },
        { source: "remark", label: "备注", sortable: true },
      ],
    };
  },
  filters: {
    itemTypeText: function (value) {
      let list = [
        { value: "1", text: "检查类型" },
        { value: "2", text: "基本操作" },
        { value: "3", text: "系统" },
        { value: "4", text: "部位" },
      ];
      return list.find((item) => item.value == value).text;
    },
  },
};
</script>
