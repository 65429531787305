<template>
  <os-registration-records-list
    :resource="resource"
    :type="2"
  ></os-registration-records-list>
</template>

<script>
export default {
  props: ["resource"],
  data() {
    return {
      title: "电话问诊记录",
    };
  },
};
</script>