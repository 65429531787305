<!--
 * @Description: 
 * @Author: your name
 * @Date: 2023-04-27 14:54:55
 * @LastEditors: your name
 * @LastEditTime: 2023-06-06 15:10:14
-->
<template>
  <va-form :id="id" :item="item">
    <v-card class="v_card">
      <div style="line-height: 60px">
        <span class="dot_circle"></span><span class="title_con">患者信息</span>
      </div>
      <v-row>
        <v-col sm="4">
          <v-text-field
            label="身份证号"
            source="id_num"
            v-model="form.id_num"
            :disabled="isReadOnly"
            :rules="rules"
            filled
          ></v-text-field>
        </v-col>

        <v-col sm="4">
          <v-text-field
            label="患者姓名"
            source="patient_name"
            :rules="rules1"
            :disabled="isReadOnly"
            v-model="form.patient_name"
            filled
          ></v-text-field>
        </v-col>
        <v-col sm="4">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="form.planned_admission_time"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="form.planned_admission_time"
                label="计划入住时间"
                prepend-icon="mdi-calendar"
                readonly
                filled
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="form.planned_admission_time"
              :min="minTime"
              :max="maxTime"
              no-title
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false"> 取消 </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.menu.save(form.planned_admission_time)"
              >
                确定
              </v-btn>
            </v-date-picker>
          </v-menu>
          <!-- <va-date-input

            source="planned_admission_time"
            v-model="form.planned_admission_time"
            label="入住时间"
            format="short"
            min="2023-05-16"
            max="2023-05-18"
          ></va-date-input> -->
        </v-col>
      </v-row>
    </v-card>
    <v-card class="v_card">
      <div style="line-height: 60px">
        <span class="dot_circle"></span><span class="title_con">信息</span>
      </div>
      <v-row>
        <v-col sm="4">
          <va-select-input
            label="预约医院"
            item-value="codenum"
            reference="bs_hospital"
            :items-per-page="1000"
            source="hospital_code"
            v-model="form.hospital_code"
          ></va-select-input>
        </v-col>
        <v-col sm="4">
          <SelectInput
            item-value="ward_code"
            source="ward_code"
            v-model="form.ward_code"
            reference="hap_inpatient_ward"
            label="预约病区"
            :items-per-page="1000"
            ref="dept"
            :filter="ward_filter"
          ></SelectInput>
        </v-col>
        <v-col sm="4">
          <SelectInput
            item-value="room_num"
            source="room_num"
            v-model="form.room_num"
            reference="hap_inpatient_room"
            label="预约病房"
            :items-per-page="1000"
            ref="dept"
            :filter="room_filter"
          ></SelectInput>
        </v-col>
        <v-col sm="4" @click="handleClick(event)">
          <input
            class="inputBoxs"
            type="text"
            placeholder="床位号(请在下放床位选择)"
            readonly
            source="bed_num"
            v-model="form.bed_num"
          />
          <!-- <va-text-input
            label="预约床位"
            source="bed_num"
            v-model="form.bed_num"
            @keydown="$event.target.blur()"
            @keypress="$event.target.blur()"
            @keyup="$event.target.blur()"
          ></va-text-input> -->
        </v-col>

        <v-col sm="4" class="vColBox" style="display: flex">
          <!-- <va-number-input
                label="可提前预约天数(天)"
                v-model="form.advance_day"
                source="advance_day"
              ></va-number-input> -->
          <v-btn @click="reduce" small style="color: #42b6c4"
            ><v-icon>mdi-minus</v-icon></v-btn
          >

          <input
            class="inputBox"
            type="number"
            v-model="form.planned_length_stay"
            placeholder="计划入住天数"
            source="planned_length_stay"
            disabled
            max="100"
            min="0"
          />
          <v-btn @click="add" small color="primary">
            <v-icon>mdi-plus</v-icon></v-btn
          >
          <div
            style="margin-left: 10px; width: 350px; color: rgb(168, 152, 150)"
          >
            计划入住天数(天)
          </div>
        </v-col>
      </v-row>
      <v-row v-if="isShow">
        <span v-for="(item, index) in bedList" :key="index">
          <v-col sm="1" v-if="item.state == 0">
            <v-btn @click="bedNumAdd(item)" color="primary">{{
              item.bed_num + isState(item)
            }}</v-btn></v-col
          >
          <v-col sm="1" v-if="item.state != 0">
            <v-btn disabled color="primary">{{
              item.bed_num + isState(item)
            }}</v-btn></v-col
          >
        </span>
      </v-row>
    </v-card>

    <div class="btn_bg">
      <v-btn @click="close" style="color: #42b6c4; margin-right: 50px"
        >取消</v-btn
      >
      <v-btn @click="isSure" color="primary" v-if="isShowB">保存</v-btn>
    </div>
  </va-form>
</template>

<script>
import moment from "moment";
import SelectInput from "@/components/VaCustomSelectInput.vue";
export default {
  props: ["id", "title", "item"],
  components: {
    SelectInput,
  },
  data() {
    return {
      itemState: this.$route.query.type,
      form: {
        hospital_code: null,
        hospital_name: "",
        ward_code: null,
        ward_name: "",
        room_num: null,
        room_name: "",
        is_active: true,
        state: "0",
        bed_num: "",
        id_num: "",
        patient_name: "",
        planned_length_stay: 1,
        planned_admission_time: "",
        patient_code: "",
        // maxTime: moment().add('days',7).format('YYYY年MM月DD日'),
      },
      advance_day: null,
      minTime: moment().format("YYYY-MM-DD HH:mm:ss"),
      menu: null,
      bedList: [],

      rules1: [(v) => (v && v.length <= 5) || "最多只能输入 5 个字符"],
    };
  },
  beforeDestroy() {
    this.itemState = null;
  },
  methods: {
    getConfigDay() {
      this.$store.dispatch("hap_bed_apt_config/getList").then((res) => {
        if (res.data.length > 0) {
          this.advance_day = res.data[0].advance_day;
        }
      });
    },
    isSure() {
      if (this.form.planned_admission_time) {
        this.form.planned_admission_time = moment(
          this.form.planned_admission_time
        ).format("YYYY-MM-DD HH:mm:ss");
      }

      if (
        !this.form.hospital_code ||
        !this.form.hospital_name ||
        !this.form.ward_code ||
        !this.form.ward_name ||
        !this.form.room_num ||
        !this.form.room_name ||
        !this.form.bed_num ||
        !this.form.id_num ||
        !this.form.patient_name ||
        !this.form.planned_length_stay ||
        !this.form.planned_admission_time
      ) {
        this.$message({
          message: "有必填项未填写",
          type: "warning",
        });
        return;
      }
      //

      if (this.itemState == 3) {
        this.form.state = "0";
        this.$admin.http
          .put(
            `hospitalization_apt/bed_appointment_record/${this.id}/`,
            this.form
          )
          .then((res) => {
            this.getSelect(this.form);
          });
      } else {
        if (this.id) {
          if (this.item.state == 2) {
            this.form.state = "0";
            this.$admin.http
              .put(
                `hospitalization_apt/bed_appointment_record/${this.id}/`,
                this.form
              )
              .then((res) => {
                this.close();
              });
          } else {
            this.$admin.http
              .put(
                `hospitalization_apt/bed_appointment_record/${this.id}/`,
                this.form
              )
              .then((res) => {
                this.close();
              });
          }
        } else {
          let params = {};
          params.id_num = this.form.id_num;
          params.patient_name = this.form.patient_name;
          this.$admin.http
            .post("hospitalization_apt/get_patcode/", params)
            .then((res) => {
              this.form.patient_code = res.data.patient_code;
              this.$admin.http
                .post(
                  "hospitalization_apt/bed_appointment_record/apt_sick_bed/",
                  this.form
                )
                .then((res) => {
                  this.close();
                })
                .catch((err) => {
                  this.$message({
                    message: "保存失败",
                    type: "error",
                  });
                });
            })
            .catch((err) => {
              this.$message({
                message: "获取患者编码失败",
                type: "error",
              });
            });

          // this.$store
          //   .dispatch("hap_bed_apt_record/create", {
          //     data: this.form,
          //   })
          //   .then((res) => {
          //     this.close();
          //   });
        }
      }

      // this.$store.dispatch("hap_sickbed/create",this.form)
    },
    close() {
      this.$router.push("/hap-bed-apt-record");
    },
    getSelect(item) {
      this.$store
        .dispatch("hap_sickbed/getList", {
          filter: {
            hospital_code: item.hospital_code,
            ward_code: item.ward_code,
            room_num: item.room_num,
            bed_num: item.bed_num,
          },
        })
        .then((res) => {
          let forms = res.data[0];
          this.updataS(forms);
        });
    },
    updataS(forms) {
      forms.state = "1";
      this.$store
        .dispatch("hap_sickbed/update", {
          id: forms.id,
          data: forms,
        })
        .then((res) => {
          this.close();
        });
    },
    isState(item) {
      console.log(item.state);
      if (item.state == 0) {
        return "空位";
      } else if (item.state == 1) {
        return "已预约";
      } else if (item.state == 2) {
        return "已入住";
      }
    },
    bedNumAdd(item) {
      this.form.bed_num = item.bed_num;
    },
    handleClick(event) {
      event.preventDefault();
    },
    add() {
      this.form.planned_length_stay++;
    },
    reduce() {
      if (this.form.planned_length_stay == 0) {
        this.form.planned_length_stay = 0;
      } else {
        this.form.planned_length_stay--;
      }
    },
  },
  computed: {
    rules() {
      const idNumberRegex =
        /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
      return [
        (v) => (v && v.length <= 18) || "最多只能输入 18 个字符",
        (v) => idNumberRegex.test(v) || "无效的身份证号码",
      ];
    },

    isReadOnly() {
      if (this.id) {
        return true;
      } else {
        return false;
      }
    },
    maxTime() {
      console.log(this.advance_day, "this.advance_day");
      return moment()
        .add("days", this.advance_day)
        .format("YYYY-MM-DD HH:mm:ss");
    },
    ward_filter() {
      if (this.form.hospital_code) {
        return { hospital_code: this.form.hospital_code, is_active: true };
      } else {
        return {};
      }
    },
    isShowB() {
      if (
        (this.item && this.item.state == 1) ||
        (this.item && this.item.apt_state == 2)
      ) {
        return false;
      } else if (
        (this.item && this.item.state == 0) ||
        (this.itemState && this.itemState == 3) ||
        !this.item
      ) {
        return true;
      } else {
        return false;
      }
    },
    room_filter() {
      if (this.form.ward_code) {
        return {
          hospital_code: this.form.hospital_code,
          ward_code: this.form.ward_code,
          is_active: true,
        };
      } else {
        return {};
      }
    },
    isShow() {
      if (this.form.room_num) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    "form.hospital_code": {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue) {
          this.$store
            .dispatch("bs_hospital/getList", {
              filter: {
                codenum: newValue,
              },
            })
            .then((res) => {
              this.form.hospital_name = res.data[0].name;
            });
        }
      },
    },
    "form.ward_code": {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue) {
          this.$store
            .dispatch("hap_inpatient_ward/getList", {
              filter: {
                ward_code: newValue,
              },
            })
            .then((res) => {
              this.form.ward_name = res.data[0].name;
            });
        }
      },
    },
    "form.room_num": {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue) {
          this.$store
            .dispatch("hap_inpatient_room/getList", {
              filter: {
                room_num: newValue,
              },
            })
            .then((res) => {
              this.form.room_name = res.data[0].name;
            });

          this.$store
            .dispatch("hap_sickbed/getList", {
              filter: {
                room_num: newValue,
              },
            })
            .then((res) => {
              console.log(res, "res1212121");
              this.bedList = res.data;
              // this.form.room_name = res.data[0].name;
            });
        }
      },
    },
  },
  created() {
    if (this.id) {
      this.form = this.item;

      this.form.planned_admission_time = moment(
        this.item.planned_admission_time
      ).format("YYYY-MM-DD");
    }
    this.getConfigDay();
  },
};
</script>
<style scoped>
.btn_bg {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0px;
  padding: 10px 10px;
  background-color: white;
}
.v_card {
  padding: 20px;
  margin-bottom: 20px;
}

.red_sp {
  color: red;
}

.dot_circle {
  display: inline-block;
  background-color: #069cae;
  width: 8px;
  height: 8px;
  margin-right: 10px;
  border-radius: 8px;
  margin-bottom: 2px;
}

.title_con {
  display: inline-block;
  font-size: 20px;
}
.inputBoxs {
  width: 100%;
  height: 50px;
  background-color: #f0f0f0;
  padding: 5px;
}
.inputBox {
  width: 100%;
  height: 50px;
  background-color: #f0f0f0;
  padding: 5px;
  box-sizing: border-box;
  margin: 0px 10px;
}
.vColBox {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>