<template>
  <va-form :id="id" :item="item">
    <v-row justify="center">
      <v-col sm="6">
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2">
              {{ title }}
            </div>
          </template>
          <v-card-text>
            <va-text-input source="request_no"></va-text-input>
            <va-text-input source="remote_addr"></va-text-input>
            <va-text-input source="path_info"></va-text-input>
            <va-text-input source="full_path"></va-text-input>
            <va-text-input source="req_method"></va-text-input>
            <va-text-input source="req_params"></va-text-input>
            <va-text-input source="content_type" required></va-text-input>
            <va-text-input source="operation_content"></va-text-input>
            <va-text-input source="status_code"></va-text-input>
            <va-text-input source="content_length"></va-text-input>
            <va-text-input source="result"></va-text-input>
            <va-text-input source="return_message"></va-text-input>
            <va-text-input source="start_timestamp"></va-text-input>
            <va-text-input source="end_timestamp"></va-text-input>
            <va-text-input source="time_use_ms"></va-text-input>
            <va-date-input source="created_time"></va-date-input>
            <va-date-input source="updated_time"></va-date-input>
          </v-card-text>
          <va-save-button></va-save-button>
        </base-material-card>
      </v-col>
    </v-row>
  </va-form>
</template>

<script>
export default {
  props: ["id", "title", "item"],
};
</script>
