<template>
  <div class="box">
    <v-card>
      <v-card-text style="width: 97%; margin: 0 auto">
        <v-row style="margin: 15px 0">
          <ul>
            <li>就诊信息</li>
            <li style="list-style: none; font-size: 10px; color: #999">
              VISIT INFORMATION
            </li>
          </ul>
        </v-row>
        <v-row style="margin: 15px 0">
          <v-col>
            <span>患者姓名：{{ item.pat_name }}</span>
          </v-col>
          <v-col>
            <span>健康卡ID： {{ item.code_healthcard }}</span>
          </v-col>
          <v-col>
            <span>患者性别：{{ item.pat_gender == 0 ? "女" : "男" }}</span>
          </v-col>
          <v-col>
            <span>患者年龄：{{ item.pat_age }}</span>
          </v-col>
          <!-- <v-col>
            <span>处方编码：{{ item.code_pre }}</span>
          </v-col> -->
        </v-row>
        <v-row style="margin: 15px 0">
          <v-col>
            <span>开方医院：{{ item.hospital_name }}</span>
          </v-col>
          <v-col>
            <span>开方科室：{{ item.office_name }}</span>
          </v-col>
          <v-col>
            <span>开方医生：{{ item.doctor_name }}</span>
          </v-col>
          <v-col>
            <span>开方时间：{{ item.issue_date }}</span>
          </v-col>
        </v-row>
        <v-row style="margin: 15px 0">
          <v-col>
            <span>初步诊断：{{ item.diagnosis_name }}</span>
          </v-col>
          <v-col>
            <span>处理意见：{{ item.handle_opinion }}</span>
          </v-col>
          <v-col> </v-col>
          <v-col> </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card style="margin: 15px 0">
      <el-table :data="item.drug_pre" style="width: 100%">
        <el-table-column type="index" width="50"> </el-table-column>
        <el-table-column prop="drug_name" label="药品名称"> </el-table-column>
        <!-- <el-table-column
          prop="administration_mode,amount_use,measure_unit,frequency_use,drug_days,specification,total_unit"
          label="用法用量"
        >
          <template slot-scope="scope"
            >{{ scope.row.administration_mode }}
            {{ scope.row.frequency_use }}
            {{ scope.row.amount_use }}
            {{ scope.row.drug_days }}
          </template>
        </el-table-column> -->
        <el-table-column prop="price" label="药品费用"> </el-table-column>
        <el-table-column prop="pre_num" label="处方数量"> </el-table-column>
      </el-table>
    </v-card>
    <v-card>
      <v-card-text style="width: 97%; margin: 0 auto">
        <v-row style="margin: 15px 0">
          <v-col>
            <span>开方药师：{{ item.doctor_name }}</span>
          </v-col>
          <v-col>
            <span>审核药师：{{ item.created_by }}</span>
          </v-col>
          <v-col>
            <span>配药药师：{{ item.updated_by }}</span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <hr />
    <div style="display: flex; margin: 20px; justify-content: flex-end">
      <h5>共计：{{ item.total_price }}元</h5>
    </div>
    <div class="text-center">
      <v-btn @click="close" style="color: #42b6c4; margin-right: 50px"
        >取消</v-btn
      >
      <v-btn
        v-if="item.status == 4"
        @click="isSure(item)"
        style="background-color: #42b6c4; margin-right: 50px"
        >确定</v-btn
      >
      <v-btn
        v-else-if="item.status == 5"
        @click="medicine(item)"
        style="background-color: #42b6c4; margin-right: 50px"
        >已取药</v-btn
      >
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      item: null,
    };
  },
  created() {
    this.item = JSON.parse(this.$route.query.item);
    this.item.updated_by = JSON.parse(
      window.sessionStorage.getItem("user")
    ).name;
  },
  methods: {
    close() {
      this.$router.push("/circulation");
    },
    // 提交审核
    isSure() {
      this.item.handle_opinion = this.handle_opinion;
      this.item.status = 5;
      this.$admin.http
        .put(`/online-recheck/prescriptions/${this.item.id}/`, this.item)
        .then((data) => {
          this.$message({
            message: "保存成功",
            type: "success",
          });
        });
      this.close();
    },
    medicine() {
      this.item.handle_opinion = this.handle_opinion;
      this.item.status = 6;
      this.$admin.http
        .put(`/online-recheck/prescriptions/${this.item.id}/`, this.item)
        .then((data) => {
          this.$message({
            message: "保存成功",
            type: "success",
          });
        });
      this.close();
    },
  },
};
</script>
<style scoped>
ul li::marker {
  color: #42b6c4;
}
.row + .row {
  margin-top: 0px;
}

.text-center {
  width: 87%;
  background-color: #fff;
  height: 80px;
  position: fixed;
  bottom: 0px;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.box {
  width: 100%;
}
.box-show {
  display: flex;
}
.box-show dd {
  margin: 15px 0;
}
.btn_bg {
  width: 95%;
  position: fixed;
  bottom: 0px;
  padding: 10px 0;
  text-align: center;
  background-color: white;
}
</style>