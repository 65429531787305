<template>
  <v-combobox
    filled
    v-model="model"
    :filter="filter"
    :items="items"
    :label="label"
  >
  </v-combobox>
</template>
<script>
export default {
  props: ["hospital", "label", "items", "entrust","entrusts"],
  data: () => ({
    // items: [
    //   {
    //     id:1,
    //     text: "Foo",
    //   },
    //   {
    //     id:2,
    //     text: "Bar",
    //   },
    // ],
    model: [],
    // search: null,
  }),
  created() {
  },
  watch: {
    model: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val.length !== 0) {
          this.$emit("office", val);
        }else{
          this.$emit("office", null);
        }
      },
    },
    entrust: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
          this.$store
            .dispatch("bs_office/getOne", {
              id: val,
            })
            .then((res) => {
              res.data.text = res.data.name;
              this.model = res.data;
            });
        }else{
          this.model = null
        }
      },
    },
    entrusts: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
          this.$store
            .dispatch("bs_doctor/getOne", {
              id: val,
            })
            .then((res) => {
              res.data.text = res.data.name;
              this.model=res.data;
            });
        }
      },
    },
  },
  methods: {
    edit(index, item) {
      if (!this.editing) {
        this.editing = item;
        this.editingIndex = index;
      } else {
        this.editing = null;
        this.editingIndex = -1;
      }
    },
    filter(item, queryText, itemText) {
      if (item.header) return false;
      const hasValue = (val) => (val != null ? val : "");
      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return (
        text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) >
        -1
      );
    },
  },
};
</script>