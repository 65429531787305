<template>
  <va-show-layout>
    <va-show :item="item">
      <v-row justify="center">
        <v-col sm="6">
          <base-material-card>
            <template v-slot:heading>
              <div class="display-2">
                {{ title }}
              </div>
            </template>
            <v-card-text>
              <va-field source="created_time"></va-field>
              <va-field source="updated_time"></va-field>
              <va-field source="is_active"></va-field>
              <va-field source="drug_code"></va-field>
              <va-field source="drug_name"></va-field>
              <va-field source="standards"></va-field>
              <va-field source="units"></va-field>
              <va-field source="origin_place"></va-field>
              <va-field source="manufacturer"></va-field>
              <va-field source="valid_date" type="date"></va-field>
              <va-field source="inventory_quantity"></va-field>
              <va-field source="measurement_unit"></va-field>
              <va-field source="cost_unit_price"></va-field>
              <va-field source="cost_amount"></va-field>
              <va-field source="retail_unit_price"></va-field>
              <va-field source="retail_amount"></va-field>
              <va-field source="pharmacy_id"></va-field>
              <va-field source="hospital_id" type="select"></va-field>
              <va-field source="category_id"></va-field>
              <va-field source="preparation_type_id"></va-field>
            </v-card-text>
          </base-material-card>
        </v-col>
      </v-row>
    </va-show>
  </va-show-layout>
</template>

<script>
export default {
  props: ["title", "item"],
};
</script>
