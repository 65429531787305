<template>
  <va-form :id="id" :item="item" @saved="$emit('saved')">
    <template v-slot:heading>
      <div class="display-2">
        {{ title }}
      </div>
    </template>
    <v-card-text>
      <va-text-input
        v-model="form.role_code"
        source="role_code"
        label="•角色编码"
        required
      ></va-text-input>
      <va-text-input
        v-model="form.role_name"
        source="role_name"
        label="•角色名称"
        required
      ></va-text-input>
      <va-select-input
        v-if="user.is_superuser"
        source="hospital_id"
        label="•所属医院"
        reference="bs_hospital"
        required
        v-model="form.hospital_id"
        :items-per-page="1000"
        :filter="{ is_active: true }"
      ></va-select-input>
      <va-boolean-input
        label="是否启用"
        v-model="form.is_active"
        source="is_active"
      ></va-boolean-input>
      <h4>权限管理：</h4>
      <v-treeview
        selectable
        selected-color="blue"
        :items="items"
        v-model="selection"
        :selection-type="selectionType"
        return-object
        :active.sync="active"
        activatable
        color="#586AFC"
        transition
        style="padding-bottom: 60px"
      ></v-treeview>
    </v-card-text>
    <div class="btn_bg">
      <v-btn @click="isSure" color="primary">保存</v-btn>
      <!-- <va-save-button></va-save-button> -->
    </div>
  </va-form>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: ["id", "title", "item"],
  data() {
    return {
      form: {
        role_code: "",
        role_name: "",
        hospital_id: "",
        is_active: true,
        created_user:null,
      },
      active: [],
      sure_data: [],
      items: [],
      selection: [],
      selectionType: "leaf",
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  watch: {
    selection: {
      deep: true,
      handler: function (newVal) {
        this.sure_data = newVal.map((o) => o.id);
      },
    },
    id: {
      deep: true,
      handler(newValue, oldValue) {
        this.getTree();
      },
    },
  },
  mounted() {
    if (!this.user.is_superuser) {
      this.form.hospital_id = this.user.hospital;
    }
    this.getTree();
  },
  methods: {
    // async get_auth_group() {
    //   if (this.id) {
    //     let inform = await this.$store.dispatch("auth_group/getOne", {
    //       id: this.item.group_id,
    //     });
    //     if (inform.data) {
    //       this.form.role_name = inform.data.role_name;
    //     }
    //   }
    // },
    async getTree() {
      let arr = [];
      let data = await this.$admin.http.get("/base-system/menu_permissions/");
      this.items = data.data.data;
      if (this.id) {
        let dataArr = await this.$admin.http.get(
          "/base-system/groups/" + this.item.group_id + "/"
        );
        dataArr.data.permissions.forEach((item) => {
          let obj = {};
          obj.id = item;
          arr.push(obj);
        });
      }
      this.selection = arr;
      // this.get_auth_group();
    },
    async isSure() {
      if (
        !this.form.role_code ||
        !this.form.role_name ||
        !this.form.hospital_id
      ) {
        this.$message({
          message: "有必填项未填写",
          type: "warning",
        });
        return;
      }
      this.form.name = this.form.role_name
      this.form.permission_ids = this.sure_data;
      if (this.id) {
        if(this.item.created_user) {
          this.form.created_user = this.item.created_user
        }else{
          this.form.created_user = this.user.username
        }
        try {
          let data = await this.$admin.http.put(
            "/base-system/groups/" + this.item.group_id + "/",
            this.form
          );
          if (data.status === 205) {
            this.$message({
              type: "success",
              message: "更新成功",
            });
            this.$emit("saved");
          }
        } catch {
          this.$message({
            message: "角色编码违反唯一性，请修改",
            type: "error",
          });
        }
      } else {
        this.form.created_user = this.user.username;
        try {
          let data = await this.$admin.http.post(
            "/base-system/groups/",
            this.form
          );
          if (data.status === 210) {
            this.$message({
              type: "warning",
              message: data.data.msg,
            });
          } else if (data.status === 201) {
            this.$message({
              type: "success",
              message: "添加成功",
            });
            this.$emit("saved");
          }
        } catch (e) {
          this.$message({
            message: "角色编码违反唯一性，请修改",
            type: "error",
          });
          // var tips = "";
          // var errors_data = e.response.data.name;
          // errors_data.forEach((item) => {
          //   if (errors_data.lenght > 1) {
          //     tips += item + ",";
          //   } else {
          //     tips += item;
          //   }
          // });
          // this.$message({
          //   type: "error",
          //   message: tips,
          // });
        }
      }
    },
  },
};
</script>
<style scoped>
.btn_bg {
  width: 95%;
  position: fixed;
  bottom: 0px;
  padding: 10px 0;
  text-align: center;
  background-color: white;
}
</style>
