<template>
  <div>
    <v-card style="padding: 2vw 0.5vw">
      <v-card-text style="width: 97%; margin: 0 auto">
        <v-row>
          <ul>
            <li>患者信息</li>
            <li style="list-style: none; font-size: 10px; color: #999">
              PATIENT INFORMATION
            </li>
          </ul>
        </v-row>
        <v-row style="margin: 15px 0">
          <v-col>
            <span>健康卡ID：{{ params.health_card_id }}</span>
          </v-col>
          <v-col>
            <span>患者姓名：{{ params.pat_name }}</span>
          </v-col>
          <v-col>
            <span v-if="params.pat_gender == 1">患者性别：男</span>
            <span v-else>患者性别：女</span>
          </v-col>
        </v-row>
        <v-row style="margin: 15px 0">
          <v-col>
            <span>患者年龄：{{ params.pat_age }}</span>
          </v-col>
          <v-col>
            <el-date-picker style="width: 100%" v-model="treatment_date" type="date" placeholder="选择预约日期">
            </el-date-picker>
          </v-col>
          <v-col>
            <el-select v-model="time_interval" placeholder="请选择时段" clearable>
              <el-option v-for="item in timeInterval" :key="item.code" :label="item.name" :value="item.code">
              </el-option>
            </el-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <el-select v-model="value" filterable placeholder="请选择初步诊断" remote :remote-method="remoteMethod" clearable
              v-scroll-loadMore="loadmore" infinite-scroll-distance="1" infinite-scroll-immediate="true"
              infinite-scroll-disabled="false">
              <el-option v-for="item in options1" :key="item.code" :label="item.name" :value="item.name"
                style="height: 35px; overflow: auto">
              </el-option>
            </el-select>
          </v-col>
          <v-col> </v-col>
          <v-col></v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card style="padding: 2vw 0.5vw; margin: 20px 0">
      <el-button style="float: right; margin: 0 0 10px" type="primary" @click="addRemind" icon="el-icon-plus"
        size="small">添加行</el-button>
      <div class="tableList">
        <el-table :data="tableData" border style="width: 100%" size="small">
          <el-table-column type="index" label="序号" width="50" align="center" fixed="left"></el-table-column>
          <el-table-column align="center" v-for="(text, i) in title" :key="i" :label="text.name"
            :width="i < 2 ? '250px' : ''">
            <template slot-scope="scope">
              <template v-if="text.field === 'name'">
                <el-input clearable @input="change_data(scope)" v-model="scope.row[text.field]"
                  :placeholder="'请输入' + text.name" width="150px"></el-input>
              </template>
              <template v-else>
                <div>{{ scope.row[text.field] }}</div>
              </template>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="70px" fixed="right">
            <template slot-scope="scope">
              <el-button type="text" @click="removeRemind(scope.row)" width="70px">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </v-card>

    <el-table :data="drugsData" height="300" style="width: 100%" @current-change="handleCurrentChange">
      <el-table-column type="index" width="50"> </el-table-column>
      <el-table-column label="类别" prop="name_srvtp"> </el-table-column>
      <el-table-column label="项目名称" prop="project_name"> </el-table-column>
      <el-table-column label="所属医院" prop="hospital_name"> </el-table-column>
      <el-table-column label="所属科室" prop="office_name"> </el-table-column>
      <el-table-column label="项目费用" prop="project_fees"> </el-table-column>
      <el-table-column label="项目明细" prop="drug_type"> </el-table-column>
      <el-table-column label="医保代码" prop="unit_dose"> </el-table-column>
      <el-table-column label="医保名称" prop="units"> </el-table-column>
      <el-table-column label="注意事项" prop="remarks"> </el-table-column>
    </el-table>
    <div style="float: right; padding-bottom: 80px">
      <v-pagination v-model="page" :length="total" :total-visible="7"></v-pagination>
    </div>
    <div class="text-center">
      <v-btn @click="close" style="color: #42b6c4; margin-right: 50px">取消</v-btn>
      <v-btn @click="isSure" style="background-color: #42b6c4; margin-right: 50px">确认</v-btn>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Utils from "../global/utils";
export default {
  data() {
    return {
      total: null,
      page: 1,
      params: null,
      tableData: [],
      title: [
        {
          field: "name",
          name: "项目名称",
        },
        {
          field: "code",
          name: "项目编码",
        },
        {
          field: "code_srvtp",
          name: "检查类型编码",
        },
        {
          field: "name_srvtp",
          name: "检查类型名称",
        },
        {
          field: "unit_price",
          name: "单价",
        },
        {
          field: "notices",
          name: "注意事项",
        },
      ],
      drugsData: [],
      code_pre: null,
      options: [],
      timeInterval: [
        { name: "上午", code: "morning" },
        { name: "下午", code: "afternoon" },
        { name: "晚上", code: "evening" },
      ],
      time_interval: "",
      value: "",
      handle_opinion: null,
      table_index: null,
      treatment_date: moment().format("YYYY-MM-DD"),
      doctor: null,
      user: null,
      options1: [],
      form: {},
      zd_limit: 10,
    };
  },
  created() {
    this.query_info();
    this.getDoctor();
    this.getDiagnosis();
    // if (sessionStorage.getItem(`tableData${this.$route.query.id}`)) {
    //   this.tableData = JSON.parse(
    //     sessionStorage.getItem(`tableData${this.$route.query.id}`)
    //   );
    // }
    // this.getCode();
    // this.getDiagnosis();
    // this.getTable();
  },
  methods: {
    loadmore() {
      this.zd_limit = this.zd_limit + 10;
      this.getDiagnosis();
    },
    // 获取诊断
    getDiagnosis() {
      this.$admin.http
        .get(`/online-recheck/diarcs/?page=1&page_size=${this.zd_limit}`)
        .then((res) => {
          this.options1 = res.data.results;
        });
    },
    remoteMethod(query) {
      if (query !== "") {
        this.$admin.http
          .get(`/online-recheck/diarcs/?name=${query}`)
          .then((res) => {
            this.options1 = res.data.results;
          });
      } else {
        this.options = [];
      }
    },
    // 获取医生
    getDoctor() {
      this.user = JSON.parse(window.sessionStorage.getItem("user"));
      this.$admin.http
        .get(`/base-system/doctors/${this.user.doctor}/`)
        .then((doctor) => {
          this.doctor = doctor.data;
          this.form.hospital_code = this.doctor.hospital_code;
        });
    },
    // 取消
    close() {
      this.$router.push("/return-visit/");
    },
    // 提交审核
    isSure() {
      if (this.value == "") {
        this.$message({
          message: "有必填项未填写",
          type: "warning",
        });
        return;
      }
      if (this.tableData.length == 0) {
        this.$message({
          message: "有必填项未填写",
          type: "warning",
        });
        return;
      } else {
        this.tableData.forEach((item) => {
          if (
            !item.name ||
            !item.code ||
            !item.unit_price ||
            !item.name_srvtp ||
            !item.code_ent
          ) {
            this.$message({
              message: "有必填项未填写",
              type: "warning",
            });
            return;
          }
        });
      }
      this.$admin.http
        .get(`/online-recheck/diarcs/?name=${this.value}`)
        .then((res) => {
          let inspect = {
            checkup_item: this.tableData,
            code_healthcard: this.params.health_card_id,
            code_pat: this.params.health_card_id,
            diagnosis_code: res.data.results[0].code,
            diagnosis_name: res.data.results[0].name,
            handle_opinion: "多喝热水，多休息",
            doctor_code: this.doctor.job_number,
            doctor_name: this.doctor.name,
            office_code: this.doctor.office_code,
            office_name: this.doctor.office_name,
            hospital_code: this.doctor.hospital_code,
            hospital_name: this.doctor.hospital_name,
            // code_ent: this.params.pat_codenum,
            created_by: this.user.username,
            updated_by: this.user.username,
            time_interval: this.time_interval,
            date_plan: this.treatment_date,
            code_ent: this.$route.query.code_ent,
          };
          // debugger
          this.$admin.http
            .post("/online-recheck/checkups/", inspect)
            .then((data) => {
              Utils.$emit("demo", { type: "检查", content: data.data });
              this.$message({
                message: "提交成功",
                type: "success",
              });
              this.close();
            });
        });
    },
    // 查询患者
    query_info() {
      this.doctor_id = JSON.parse(window.sessionStorage.getItem("user")).doctor;
      this.$admin.http
        .get(
          `patient-account/online_therapy/?appoint_type=4&status=3&doctor_id=${this.doctor_id}`
        )
        .then((res) => {
          res.data.results.forEach((item) => {
            if (item.id == this.$route.query.id) {
              this.params = item;
            }
          });
        });
    },
    // 搜索药
    change_data(scope) {
      this.table_index = scope.$index;
      if (scope.row.name == "") {
        this.form.project_name = scope.row.name;
        this.adjustment();
      } else {
        this.form.project_name = scope.row.name;
        this.adjustment();
      }
    },
    // 处方编码
    getCode() {
      for (
        var i = 0;
        i < 4;
        i++ //4位随机数，用以加在时间戳后面。
      ) {
        this.code_pre += Math.floor(Math.random() * 10);
      }
      this.code_pre = new Date().getTime() + this.code_pre; //时间戳，用来生成用户编号。
    },
    getTable() {
      this.$admin.http
        .get(`/online-recheck/prescriptions/tabtitle/`)
        .then((res) => {
          this.title = res.data;
        });
    },
    // 选中筛选数据
    handleCurrentChange(val) {
      this.tableData[this.table_index].name = val.project_name; //  项目名称
      this.tableData[this.table_index].code = val.project_code; //  项目名称
      this.tableData[this.table_index].unit_price = val.project_fees; //  项目费用
      this.tableData[this.table_index].notices = val.remarks; //  注意事项
      this.tableData[this.table_index].name_srvtp = val.name_srvtp; //  类别名称
      this.tableData[this.table_index].code_srvtp = val.code_srvtp; //  类别编码
      this.tableData[this.table_index].code_ent = this.$route.query.code_ent; //  就诊编码
    },
    // 添加行
    addRemind() {
      let table_info = {};
      this.title.forEach((item) => {
        table_info[item.field] = null;
      });
      this.tableData.push(table_info);
    },
    // 删除行
    removeRemind(row) {
      let rowIndex = this.tableData.indexOf(row);
      this.tableData.splice(rowIndex, 1);
    },
    adjustment() {
      let obj_key = Object.keys(this.form);
      let src_key = "";
      obj_key.forEach((key) => {
        src_key = src_key + "&" + key + "=" + `${this.form[key]}`;
      });
      src_key = src_key.slice(1);
      if (this.form.project_name) {
        this.$admin.http.get(`/base-system/ins_dic/?${src_key}`).then((res) => {
          this.total =
            (res.data.count / 10) % 1 === 0
              ? res.data.count / 10
              : parseInt(res.data.count / 10) + 1;
          this.drugsData = res.data.results;
        });
      }
    },
  },
  watch: {
    page: {
      deep: true,
      immediate: true,
      handler(val) {
        this.form.page = val;
        this.adjustment();
      },
    },
  },
};
</script>

<style scoped>
.text-center {
  width: 87%;
  background-color: #fff;
  height: 80px;
  position: fixed;
  bottom: 0px;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

ul li::marker {
  color: #42b6c4;
}

.row+.row {
  margin-top: 0px;
}

.row>.col {
  padding: 0 12px !important;
}

.el-select {
  display: block;
}

.el-button--primary {
  background-color: #42b6c4;
  border-color: #42b6c4;
}
</style>