<!--
 * @Description: 
 * @Author: your name
 * @Date: 2023-04-27 14:54:55
 * @LastEditors: your name
 * @LastEditTime: 2023-05-04 18:01:32
-->
<template>
  <va-create-layout>
    <opr-operating-room-management-form :title="title" :item="item"></opr-operating-room-management-form>
  </va-create-layout>
</template>

<script>
export default {
  props: ["title", "item"],
};
</script>
