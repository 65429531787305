<template>
  <v-card :icon="resource.icon" :title="title">
    <va-list
      :include="[
        'bs_hospital',
        'bs_office',
        'bs_expense_standard',
        'bs_doctor',
        'bs_extra_group',
        'base_system_user_groups',
      ]"
      global-search-query="bs_office#name@_like,bs_hospital#name@_like,bs_doctor#name@_like,username@_like,name@_like"
      disable-export
      :filters="filters"
      :filter="filter"
      ref="list"
      :sort-by="['id']"
      :sort-desc="[true]"
    >
      <va-data-table :fields="fields" disable-clone disable-show>
      </va-data-table>
    </va-list>
  </v-card>
</template>

<script>
export default {
  props: ["resource", "title"],
  data() {
    return {
      filter: {},
      filters: [
        // {
        //   alwaysOn: true,
        //   label: "所属医院",
        //   source: "hospital_id",
        //   type: "select",
        //   attributes: {
        //     reference: "bs_hospital",
        //     itemText: "name",
        //   },
        // },
        // {
        //   alwaysOn: true,
        //   label: "所属科室",
        //   source: "office_id",
        //   type: "select",
        //   attributes: {
        //     reference: "bs_office",
        //     itemText: "name",
        //     // filter: {
        //     //   hospital_id: hospital_id
        //     // }
        //   },
        // },
        // {
        //   alwaysOn: true,
        //   label: "绑定医生",
        //   source: "doctor_id",
        //   type: "select",
        //   attributes: {
        //     reference: "bs_doctor",
        //     itemText: "name",
        //   },
        // },
      ],
      fields: [
        { source: "bs_hospital.name", label: "所属医院" },
        { source: "bs_office.name", label: "所属科室" },
        { source: "bs_doctor.name", label: "绑定医生" },
        // { source: "bs_position_title.name", label: "职级" },
        "username",
        { source: "name", label: "姓名" },
        // {
        //   source: "default_group_id",
        //   label: "启用角色",
        //   type: "array",
        //   attributes: {
        //     select: true,
        //     color: "yellow",
        //     small: true,
        //     column: true,
        //   },
        // },
        {
          source: "bs_doctor.is_online_consult",
          label: "是否互联网接诊",
          type: "boolean",
        },
        // {
        //   source: "bs-expense-standard.standard_name",
        //   label: "挂号费用配置",
        //   filter: { expense_type: 1 },
        // },
        // {
        //   source: "bs-expense-standard.standard_name",
        //   label: "电话问诊费用配置",
        //   filter: { expense_type: 2 },
        // },
        // {
        //   source: "bs-expense-standard.standard_name",
        //   label: "在线问诊费用配置",
        //   filter: { expense_type: 3 },
        // },
        // {
        //   source: "base_system_user",
        //   label: "用户头像",
        //   type: "image",
        //   attributes: {
        //     src: "avatar_url",
        //   },
        // },
      ],
    };
  },
  created() {
    this.getSupper();
  },
  methods: {
    getSupper() {
      let user_data = JSON.parse(
        window.sessionStorage.getItem("user")
      );
      if (user_data.is_superuser) {
        this.filters.push({
          source: "hospital_id",
          label: "所属医院",
          type: "select",
          alwaysOn: true,
          attributes: { reference: "bs_hospital" },
          // attributes: { reference: "auth_system_user.id" },
        });
      }else{
        this.filter = {hospital_id: user_data.hospital}
      }
    },
  }
};
</script>
