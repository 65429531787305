<template>
	<div>
		<v-container>
			<v-data-table :headers="headers" :items="filteredItems" item-key="id" v-model="selected"
				item-value="item_name" class="elevation-1" :items-per-page="2000">
				<template v-slot:top>
					<v-toolbar flat>
						<v-toolbar-title>服务组项目列表</v-toolbar-title>
						<v-spacer></v-spacer>
						<v-select v-model="filterType" :items="uniqueTypes" item-text="item_type_name"
							item-value="item_type" label="检查类型" return-object single-line clearable></v-select>
						<v-select v-model="filterOperation" :items="uniqueOperations" item-text="item_operation_name"
							item-value="item_operation" label="基本操作" return-object single-line clearable></v-select>
						<v-select v-model="filterSystem" :items="uniqueSystems" item-text="item_sys_name"
							item-value="item_sys" label="系统" return-object single-line clearable></v-select>
						<v-select v-model="filterRegion" :items="uniqueRegions" item-text="item_regions_name"
							item-value="item_regions" label="部位" return-object single-line clearable></v-select>
						<v-spacer></v-spacer>
						<v-spacer></v-spacer>
						<v-btn @click="notSure"
							style="color: #14b2c5; margin-left: 50px; margin-right: 15px;">返回</v-btn>
						<v-btn color="primary" @click="joinSelected"> 加入服务组 </v-btn>
					</v-toolbar>
				</template>
				<template v-slot:[`item.is_binding`]="{ item }">
					<v-checkbox v-model="item.is_binding" color="primary" hide-details></v-checkbox>
				</template>
			</v-data-table><v-dialog v-model="dialog" persistent max-width="300">
				<v-card>
					<v-card-title class="text-h5">正在处理</v-card-title>
					<v-card-text>
						<v-progress-linear :value="loadingProgress" color="primary" indeterminate></v-progress-linear>
						请稍等，正在加入服务组...
					</v-card-text>
				</v-card>
			</v-dialog>
		</v-container>
		<!-- 右下角弹窗提示
		<v-snackbar v-model="snackbar" :color="snackbarColor" bottom right>
			{{ snackbarText }}
			<v-btn color="white" text @click="snackbar = false">关闭</v-btn>
		</v-snackbar> -->
	</div>
</template>

<script>
	export default {
		data() {
			return {
				group_id: null,
				group_name: null,
				filterType: null,
				filterOperation: null,
				filterSystem: null,
				filterRegion: null,
				dialog: false,
				loadingProgress: 0,
				snackbar: false,
				snackbarText: '',
				snackbarColor: '',
				headers: [{
						text: "是否绑定",
						value: "is_binding"
					},
					{
						text: "项目ID",
						value: "item_id"
					},
					{
						text: "代码",
						value: "item_code"
					},
					{
						text: "项目名称",
						value: "item_name"
					},
					{
						text: "检查类型名称",
						value: "item_type_name"
					},
					// {
					// 	text: "基本操作",
					// 	value: "item_operation"
					// },
					{
						text: "基本操作名称",
						value: "item_operation_name"
					},
					// {
					// 	text: "系统",
					// 	value: "item_sys"
					// },
					{
						text: "系统名称",
						value: "item_sys_name"
					},
					// {
					// 	text: "部位",
					// 	value: "item_regions"
					// },
					{
						text: "部位名称",
						value: "item_regions_name"
					},
					// { text: '组名', value: 'group_name' }
				],
				items: [],
				selected: [],
			};
		},
		methods: {
			async fetchItems() {
				try {
					this.group_id = this.$route.query.group_id; // 获取路由参数
					this.group_name = this.$route.query.group_name; // 获取路由参数
					console.log('886 ', this.group_id, this.group_name);
					const response = await this.$admin.http.get(
						`/item/itembindinggroup/${this.group_id}/GetItemByGroupId/`
					);
					this.items = response.data;
					// 初始化筛选项
					this.filterType = null;
					this.filterOperation = null;
					this.filterSystem = null;
					this.filterRegion = null;
				} catch (error) {
					console.error("Error fetching items:", error);
				}
			},
			updateSelected() {
				this.selected = this.items
					.filter((item) => item.is_binding)
					.map((item) => item.id);
			},
			notSure() {
				this.$router.push(
					"/ig-service-group" //跳转
				);
			},
			joinSelected() {
				// this.$message({
				// 	message: `正在写入绑定，请稍等。。。`,
				// 	type: "error",
				// });
				// 直接从items数组中筛选出所有勾选状态的项目
				const selectedItems = this.items
					.filter(item => item.is_binding) // 筛选出is_binding为true的项目
					.map(({
						item_id,
						item_name,
						item_code,
						item_type,
						item_type_name,
						item_operation,
						item_operation_name,
						item_sys,
						item_sys_name,
						item_regions,
						item_regions_name
					}) => ({
						item_id,
						item_name,
						item_code,
						item_type,
						item_type_name,
						item_operation,
						item_operation_name,
						item_sys,
						item_sys_name,
						item_regions,
						item_regions_name
					}));

				const dataToSend = {
					group_id: this.group_id,
					group_name: this.group_name,
					items: selectedItems,
				};

				this.dialog = true;
				this.loadingProgress = 10;
				console.log('9908 ', this.dialog);

				// this.snackbarText = `正在添加项目，请勿关闭此页面。`;
				// this.snackbarColor = 'success';
				// this.snackbar = true;
				this.$admin.http.post('/item/itembindinggroup/join/', dataToSend)
					.then(response => {
						this.dialog = true;
						this.loadingProgress = 10;
						console.log('1235 ', this.dialog);
						console.log(response);
						this.$router.push({
							path: "/ig-service-group/"
						});
					})
					.catch(error => {
						console.error("Error joining service groups:", error);
						this.dialog = false; // 也关闭对话框
						// this.$message({
						// 	message: `出现错误：${error}`,
						// 	type: "error",
						// });

						this.snackbarText = `出现错误：${error.message}`;
						this.snackbarColor = 'error';
						this.snackbar = true;
					});
			},
		},
		mounted() {
			this.fetchItems();
		},
		computed: {
			filteredItems() {
				return this.items.filter((item) => {
					return (!this.filterType || item.item_type === this.filterType.item_type) &&
						(!this.filterOperation || item.item_operation === this.filterOperation.item_operation) &&
						(!this.filterSystem || item.item_sys === this.filterSystem.item_sys) &&
						(!this.filterRegion || item.item_regions === this.filterRegion.item_regions);
				});
			},
			uniqueTypes() {
				const types = this.items.map(item => ({
					item_type_name: item.item_type_name,
					item_type: item.item_type
				})).filter(item => item.item_type_name && item.item_type); // 确保名称和类型非空
				return [...new Map(types.map(item => [item.item_type, item])).values()];
			},
			uniqueOperations() {
				const operations = this.items.map(item => ({
					item_operation_name: item.item_operation_name,
					item_operation: item.item_operation
				})).filter(item => item.item_operation_name && item.item_operation);
				return [...new Map(operations.map(item => [item.item_operation, item])).values()];
			},
			uniqueSystems() {
				const systems = this.items.map(item => ({
					item_sys_name: item.item_sys_name,
					item_sys: item.item_sys
				})).filter(item => item.item_sys_name && item.item_sys);
				return [...new Map(systems.map(item => [item.item_sys, item])).values()];
			},
			uniqueRegions() {
				const regions = this.items.map(item => ({
					item_regions_name: item.item_regions_name,
					item_regions: item.item_regions
				})).filter(item => item.item_regions_name && item.item_regions);
				return [...new Map(regions.map(item => [item.item_regions, item])).values()];
			},
		}

	};
</script>

<style scoped>
	.v-container {
		max-width: 1200px;
	}
</style>