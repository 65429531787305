<template>
  <va-form :id="id" :item="item" @saved="$emit('saved')" ref="form">
    <v-card-text>
      <!-- <el-cascader
        v-model="form.code_srvtp"
        :props="{ emitPath: false, value: 'code_srvtp', label: 'name_srvtp' }"
        style="width: 100%; margin-bottom: 3vh"
        placeholder="试试搜索：'分类名称'"
        :options="options"
        filterable
      ></el-cascader> -->
      <v-select filled label="项目名称" :items="item_data"></v-select>
      <v-select filled label="服务组名称" :items="items"></v-select>
    </v-card-text>
    <div class="btn_bg">
      <v-btn @click="isSure" color="primary">保存</v-btn>
    </div>
  </va-form>
</template>

<script>
import Utils from "../../global/utils";
export default {
  props: ["id", "title", "item"],
  data() {
    return {
      options: null,
      item_data: null,
      items: null,
      form: {
        code_srvtp: null,
        project_name: null,
        created_time: null,
      },
    };
  },
  created() {},
  mounted() {
    this.getData();
  },
  watch: {
    id: {
      deep: true,
      immediate: true,
      handler(val) {},
    },
  },
  computed: {},
  methods: {
    getData() {
      this.$admin.http.get(`/base-system/inspectiontypes/`).then((res) => {
        this.options = res.data;
      });
      this.items = ["DR1"];
      // this.$admin.http.get(`/base-system/inspectiontypes/`).then((res) => {
      //   this.items = res.data;
      // });
    },
    async isSure() {
      if (!this.form.project_name) {
        this.$message({
          message: "有必填项未填写",
          type: "warning",
        });
        return;
      }
      this.$store
        .dispatch("bs_examination_dictionaries/create", {
          data: this.form,
        })
        .then((res) => {
          this.$emit("saved");
        });
      setTimeout(() => {
        Utils.$emit("open", true);
      }, 200);
    },
  },
};
</script>
<style scoped>
.btn_bg {
  width: 95%;
  position: fixed;
  bottom: 0px;
  padding: 10px 0;
  text-align: center;
  background-color: white;
}
::v-deep .el-input__inner {
  background-color: #f0f0f0 !important;
  border-color: rgba(255, 255, 255, 0.5);
  color: #707070;
  height: 60px;
}
</style>
