<template>
  <div>
    <v-row>
      <v-col>
        <form @submit.prevent="changePassword">
          <base-material-card color="primary" icon="mdi-lock" title="修改密码">
            <v-card-text>
              <v-row>
                <v-col>
                  <v-text-field
                    label="当前密码"
                    type="origin_password"
                    v-model="passwordForm.origin_password"
                    required
                    :rules="[(v) => !!v || '请输入当前密码']"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    label="新密码"
                    type="password"
                    v-model="passwordForm.password"
                    required
                    :rules="[(v) => !!v || '请输入新密码']"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    label="确认密码"
                    type="password"
                    v-model="passwordForm.password2"
                    required
                    :rules="[(v) => !!v || '请确认新密码']"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>

            <v-dialog v-model="dialog" width="300">
              <template v-slot:activator="{ on, attrs }">
                <div style="display: flex; justify-content: center">
                  <v-btn
                    @click="close"
                    style="color: #42b6c4; margin-right: 50px"
                    >取消</v-btn
                  >
                  <v-btn color="primary" dark v-bind="attrs" v-on="on">
                    保存
                  </v-btn>
                </div>
              </template>
              <v-card>
                <v-card-text> 确认修改为该密码吗？ </v-card-text>
                <!-- <v-divider></v-divider> -->
                <v-card-actions>
                  <!-- <v-spacer></v-spacer> -->
                  <v-btn text color="primary" @click="dialog = false">
                    取消
                  </v-btn>
                  <v-btn color="primary" @click="changePassword">确认</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </base-material-card>
        </form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { checkStrong } from "@/components/checkpassStrong/checkpassStrong";
export default {
  inject: ["reload"],
  data() {
    return {
      dialog: false,
      passwordChanging: false,
      passwordForm: {
        origin_password: null,
        password: null,
        password2: null,
      },
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  mounted() {},
  methods: {
    close() {
      window.history.go(-1);
    },
    async changePassword() {
      let passwordLevel = checkStrong(this.passwordForm.password);
      if (this.passwordForm.password != this.passwordForm.password2) {
        this.$message({
          message: "两次密码不一致",
          type: "warning",
        });
        return;
      } else if (this.passwordForm.password.length < 8) {
        this.$message({
          message: "请输入8位以上密码",
          type: "warning",
        });
        return;
      } else if (passwordLevel < 3) {
        this.$message({
          message: "密码至少8位且包含大小写字母、数字、字符任意三种组合",
          type: "warning",
        });
        return;
      }

      let data = await this.$admin.http.put(
        `/base-system/users/${this.user.id}/change_password/`,
        this.passwordForm
      );
      this.dialog = false;
      this.$message({
        message: "修改成功",
        type: "success",
      });
      if (data.status === 205) {
        sessionStorage.clear();
        this.$admin.http.get(
          `config-manage/logout_log/?user_id=${this.user.id}&operat_content=修改密码退出成功&log_type=退出`
        );
        this.$router.push(`/login?redirect=${this.$route.fullPath}`); //跳回登录地址
      }
    },
  },
};
</script>

