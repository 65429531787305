<template>
  <va-create-layout>
    <ig-scheduling_day_time_line-form :title="title" :item="item"></ig-scheduling_day_time_line-form>
  </va-create-layout>
</template>

<script>
export default {
  props: ["title", "item"],
};
</script>
