<template>
  <div>
    <div
      style="display: flex; justify-content: space-between; align-items: center"
    >
      <v-btn-toggle
        class="custom_btn"
        v-model="toggle_release"
        mandatory
        color="#214578"
      >
        <v-btn @click="toBeReceived"> 待接诊 </v-btn>
        <v-btn @click="toBeInquired"> 待问诊 </v-btn>
        <v-btn @click="checked"> 已问诊 </v-btn>
      </v-btn-toggle>
      <va-action-button
        icon="mdi-minus"
        color="#14B2C5"
        @click="stopInquiries"
        style="color: #14b2c5"
        label="停诊"
      ></va-action-button>
    </div>
    <template>
      <div style="display: flex; justify-content: space-between">
        <div
          class="left"
          :style="{
            width: toggle_release == 1 || toggle_release == 2 ? '30%' : '49%',
          }"
        >
          <v-card-text style="width: 97%; margin: 0 auto">
            <v-row>
              <ul style="padding: 0.5vw">
                <li>在线问诊</li>
                <li style="list-style: none; font-size: 10px; color: #999">
                  ONLINE CONSULTATION
                </li>
              </ul>
            </v-row>
            <v-data-table
              style="margin: 15px 0"
              @click:row="rowClicked"
              :headers="headers"
              :items="desserts"
              v-model="selected"
            >
              <template v-slot:[`item.code_dayslottp`]="{ item }">
                <span v-if="item.code_dayslottp == 'morning'"
                  >{{ moment(item.appoint_date).format("MM/DD") }} 上午</span
                >
                <span v-else-if="item.code_dayslottp == 'afternoon'"
                  >{{ moment(item.appoint_date).format("MM/DD") }} 下午</span
                >
                <span v-else-if="item.code_dayslottp == 'night'"
                  >{{ moment(item.appoint_date).format("MM/DD") }} 晚上</span
                >
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <span v-if="item.status == '2'">未接诊</span>
                <span v-if="item.status == '3'">问诊中</span>
                <span v-if="item.status == '4'">已问诊</span>
              </template>
            </v-data-table>
          </v-card-text>
        </div>
        <div class="center" v-show="toggle_release == 1 || toggle_release == 2">
          <dialogue
            :id="list_id"
            :health_card_id="health_card_id"
            ref="dialogue"
          ></dialogue>
        </div>
        <div
          class="right"
          :style="{
            width: toggle_release == 1 || toggle_release == 2 ? '34%' : '50%',
          }"
        >
          <describe
            :id="list_id"
            ref="describe"
            :type_data="type"
          ></describe>
        </div>
        <div class="text-center">
          <v-btn
          v-show="toggle_release == 0"
            style="background-color: #42b6c4; color: #fff; margin-right: 50px"
            @click="receive(list_id)"
            >接诊</v-btn
          >
          <v-btn
            style="background-color: #42b6c4; color: #fff; margin-right: 50px"
            v-show="toggle_release == 1"
            @click="terminate(list_id)"
            >结束问诊</v-btn
          >
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import moment from "moment";
import describe from "@/components/online/describe.vue";
import dialogue from "@/components/online/dialogue.vue";
export default {
  components: {
    describe,
    dialogue,
  },
  data() {
    return {
      type: null,
      moment,
      toggle_release: 1,
      desserts: [],
      selected: [],
      headers: [
        { text: "患者姓名", value: "pat_name", sortable: false },
        { text: "年龄", value: "pat_age", sortable: false },
        { text: "预约时间", value: "code_dayslottp", sortable: false },
        { text: "状态", value: "status", sortable: false },
      ],
      list_id: null,
      health_card_id: null,
      row_info: null,
      doctor_id: null,
    };
  },
  created() {
    this.type = "在线问诊";
  },
  mounted() {
    this.doctor_id = JSON.parse(window.sessionStorage.getItem("user")).doctor;
    this.$admin.http
      .get(
        `patient-account/online_therapy/?appoint_type=3&status=3&doctor_id=${this.doctor_id}`
      )
      .then((res) => {
        this.desserts = res.data.results;
      });
  },
  methods: {
    receive(id) {
      this.$admin.http.put(`patient-account/online_therapy/${id}/`, {
        status: 3,
      });
      this.toBeReceived();
    },
    terminate(id) {
      this.$admin.http.put(`patient-account/online_therapy/${id}/`, {
        status: 4,
      });
      this.toBeInquired();
    },
    stopInquiries() {
      this.$router.push({
        path: "/ws-doctor-work-scheduling-main-online",
        query: { doctor_id: this.doctor_id },
      });
    },
    rowClicked(row) {
      this.selected = [];
      this.selected.push(row);
      this.health_card_id = row.ehc_id;
      this.list_id = row.id;
      this.row_info = row;
    },
    toBeReceived() {
      this.$admin.http
        .get(
          `patient-account/online_therapy/?appoint_type=3&status=2&doctor_id=${this.doctor_id}`
        )
        .then((res) => {
          this.desserts = res.data.results;
        });
    },
    toBeInquired() {
      this.$admin.http
        .get(
          `patient-account/online_therapy/?appoint_type=3&status=3&doctor_id=${this.doctor_id}`
        )
        .then((res) => {
          this.desserts = res.data.results;
        });
    },
    checked() {
      this.$admin.http
        .get(
          `patient-account/online_therapy/?appoint_type=3&status=4&doctor_id=${this.doctor_id}`
        )
        .then((res) => {
          this.desserts = res.data.results;
        });
    },
  },
  watch: {},
};
</script>

<style scoped>
.text-center {
  width: 87%;
  background-color: #fff;
  height: 80px;
  position: fixed;
  bottom: 0px;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
ul li::marker {
  color: #42b6c4;
}
.row + .row {
  margin-top: 0px;
}
.row > .col {
  padding: 0 12px !important;
}
.left {
  width: 30%;
  /* border: 2px solid #ccc; */
  background-color: #fff;
  border-radius: 10px;
}
.center {
  width: 34%;
  /* border: 2px solid #ccc; */
  background-color: #fff;
  border-radius: 10px;
}
.right {
  width: 34%;
  /* border: 2px solid #ccc; */
  background-color: #fff;
  border-radius: 10px;
}
</style>
<style lang="sass" scoped>
.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined)
  background-color: #eee
  color: #14B2C5
  &:hover
    background-color: #ccc
    color: #214578
.custom_btn.v-item-group
  left: -15px

.v-btn.v-size--default
  margin: 10px 0 !important
  font-size: 1rem
</style>