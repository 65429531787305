<template>
  <div style="overflow-y: auto; height: 70vh">
    <v-card-text style="width: 97%; margin: 0 auto">
      <v-row>
        <ul style="padding: 0.5vw">
          <li>患者信息</li>
          <li style="list-style: none; font-size: 10px; color: #999">
            PATIENT INFORMATION
          </li>
        </ul>
      </v-row>
      <div v-if="patient_info" class="box-show">
        <dl>
          <dd>健康卡ID： {{ patient_info.code_pat }}</dd>
          <dd>患者姓名：{{ patient_info.pat_name }}</dd>
          <!-- <dd>患者年龄：{{ patient_info.pat_age }}</dd> -->
          <dd>就诊医院：{{ patient_info.hospital_name }}</dd>
          <dd>就诊科室：{{ patient_info.office_name }}</dd>
          <dd>就诊医生：{{ patient_info.doctor_name }}</dd>
          <!-- <dd>患者性别：{{ patient_info.pat_gender == 0 ? "女" : "男" }}</dd> -->
        </dl>
        <!-- <dl style="margin-left: 50px">
          
        </dl> -->
      </div>
      <v-row
        v-else
        style="
          height: 20vh;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        暂未选择患者</v-row
      >
    </v-card-text>
    <v-card-text style="width: 97%; margin: 0 auto">
      <v-row>
        <ul style="padding: 0.5vw">
          <li>病情描述</li>
          <li style="list-style: none; font-size: 10px; color: #999">
            DESCRIPTION OF ILLNESS
          </li>
        </ul>
      </v-row>
      <template v-if="id">
        <v-row v-if="describe">
          <div class="describe">
            {{ describe }}
          </div>
        </v-row>
        <div v-else class="describe">暂未病情描述</div>
      </template>
      <v-row
        v-else
        style="
          height: 20vh;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        暂未选择患者</v-row
      >
    </v-card-text>
    <v-card-text style="width: 97%; margin: 0 auto">
      <v-row>
        <ul style="padding: 0.5vw">
          <li>上传资料</li>
          <li style="list-style: none; font-size: 10px; color: #999">
            UPLOAD DATA
          </li>
        </ul>
      </v-row>
      <template v-if="id">
        <v-row v-if="file.length > 0">
          <div class="describe" style="display: flex">
            <div v-for="item in file" :key="item.id" style="margin: 5px">
              <template>
                <el-image
                  style="width: 100px; height: 100px"
                  :src="item.path"
                  :preview-src-list="srcList"
                ></el-image>
              </template>
            </div>
          </div>
        </v-row>
        <template v-else>
          <div class="describe">暂未上传资料</div>
        </template>
      </template>
      <template v-else>
        <v-row
          style="
            height: 20vh;
            display: flex;
            justify-content: center;
            align-items: center;
          "
        >
          暂未选择患者</v-row
        >
      </template>
    </v-card-text>
    <template v-if="toggle_release == '3'">
      <v-card-text style="width: 97%; margin: 0 auto">
        <v-row>
          <ul style="padding: 0.5vw">
            <li>退诊原因</li>
            <li style="list-style: none; font-size: 10px; color: #999">
              PATIENT MEDICAL RECORD
            </li>
          </ul>
        </v-row>
        <template v-if="id">
          <v-row class="box-show">
            <div class="describe">
              {{ withdrawal_reason }}
            </div>
          </v-row>
        </template>
        <template v-else>
          <v-row
            style="
              height: 20vh;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            暂未选择患者</v-row
          >
        </template>
      </v-card-text>
    </template>
    <template v-else>
      <v-card-text style="width: 97%; margin: 0 auto" v-if="type!=='在线问诊'">
        <v-row>
          <ul style="padding: 0.5vw">
            <li>患者病历</li>
            <li style="list-style: none; font-size: 10px; color: #999">
              PATIENT MEDICAL RECORD
            </li>
          </ul>
        </v-row>
        <template v-if="id">
          <v-row v-if="recorde" class="box-show">
            <v-col>
              <dl>
                <dd>主诉：{{ recorde.chief_complaint }}</dd>
                <dd>现病史：{{ recorde.present_illness_history }}</dd>
                <dd>既往史：{{ recorde.past_illness_history }}</dd>
                <dd>过敏史：{{ recorde.allergic_history }}</dd>
                <dd>体格检查：{{ recorde.health_checkup }}</dd>
                <dd>辅助检查：{{ recorde.auxiliary_checkup }}</dd>
                <dd>处理意见：{{ recorde.handle_opinion }}</dd>
                <dd>初步诊断：{{ recorde.diagnosis_name }}</dd>
              </dl>
            </v-col>
          </v-row>
          <v-row v-else>
            <div class="describe">暂未填写病历</div>
          </v-row>
        </template>
        <template v-else>
          <v-row
            style="
              height: 20vh;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            暂未选择患者</v-row
          >
        </template>
      </v-card-text>
      <v-card-text style="width: 97%; margin: 0 auto" v-if="type!=='在线问诊'">
        <v-row>
          <ul style="padding: 0.5vw">
            <li>查看处方</li>
            <li style="list-style: none; font-size: 10px; color: #999">
              PRESCRIPTION INFORMATION
            </li>
          </ul>
        </v-row>
        <template v-if="id">
          <v-row v-if="prescription" class="box-show">
            <el-table :data="prescription" style="width: 100%" border>
              <el-table-column prop="drug_name" label="药品名称"> </el-table-column>
              <el-table-column prop="price" label="药品单价">
              </el-table-column>
              <el-table-column prop="frequency_use" label="使用方式">
              </el-table-column>
              <!-- <el-table-column prop="address" label="地址"> </el-table-column> -->
            </el-table>
          </v-row>
          <v-row v-else>
            <div class="describe">暂未开处方</div>
          </v-row>
        </template>
        <template v-else>
          <v-row
            style="
              height: 20vh;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            暂未选择患者</v-row
          >
        </template>
      </v-card-text>
      <v-card-text style="width: 97%; margin: 0 auto" v-if="type!=='在线问诊'">
        <v-row>
          <ul style="padding: 0.5vw">
            <li>查看检查</li>
            <li style="list-style: none; font-size: 10px; color: #999">
              CHECK INFORMATION
            </li>
          </ul>
        </v-row>
        <template v-if="id">
          <v-row v-if="inspect" class="box-show">
            <el-table :data="inspect" style="width: 100%" border>
              <el-table-column prop="name" label="项目名称"> </el-table-column>
              <el-table-column prop="unit_price" label="项目单价">
              </el-table-column>
              <el-table-column prop="notices" label="注意事项">
              </el-table-column>
              <!-- <el-table-column prop="address" label="地址"> </el-table-column> -->
            </el-table>
          </v-row>
          <v-row v-else>
            <div class="describe">暂未开检查</div>
          </v-row>
        </template>
        <template v-else>
          <v-row
            style="
              height: 20vh;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            暂未选择患者</v-row
          >
        </template>
      </v-card-text>
      <v-card-text style="width: 97%; margin: 0 auto" v-if="type!=='在线问诊'">
        <v-row>
          <ul style="padding: 0.5vw">
            <li>查看检验</li>
            <li style="list-style: none; font-size: 10px; color: #999">
              IMSPECTION INFORMATION
            </li>
          </ul>
        </v-row>
        <template v-if="id">
          <v-row v-if="examine" class="box-show">
            <el-table :data="examine" style="width: 100%" border>
              <el-table-column prop="name" label="项目名称"> </el-table-column>
              <el-table-column prop="name_sampt" label="标本">
              </el-table-column>
              <el-table-column prop="unit_price" label="项目单价">
              </el-table-column>
              <el-table-column prop="notices" label="注意事项">
              </el-table-column>
              <!-- <el-table-column prop="address" label="地址"> </el-table-column> -->
            </el-table>
          </v-row>
          <v-row v-else>
            <div class="describe">暂未开检验</div>
          </v-row>
        </template>
        <template v-else>
          <v-row
            style="
              height: 20vh;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            暂未选择患者</v-row
          >
        </template>
      </v-card-text>
    </template>
  </div>
</template>

<script>
export default {
  props: ["id", "code_ent", "toggle_release", "type_data"],
  data() {
    return {
      describe: null,
      patient_info: null,
      doctor_info: null,
      file: null,
      recorde: null,
      srcList: [],
      inspect: null,
      prescription: [],
      examine: null,
      withdrawal_reason: null,
      type: null,
    };
  },
  created() {
    if(this.type_data) {
      this.type = this.type_data
    }
  },
  watch: {
    code_ent: {
      deep: true,
      immediate: true,
      handler(val) {
        if(val) {
          this.query_info(this.id, val);
        }
      },
    },
    id: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val == null) {
          this.describe = null;
          this.patient_info = null;
          this.doctor_info = null;
          return;
        } else {
          this.query_info(val);
        }
      },
    },
  },
  methods: {
    query_info(id, code_ent) {
      this.$admin.http.get(`patient-account/aptrecords/${id}/`).then((res) => {
        this.patient_info = res.data;
        this.describe = res.data.describe.describe;
        this.withdrawal_reason = res.data.withdrawal_reason;
        this.file = res.data.images;
        this.file.forEach((img) => {
          this.srcList.push(img.path);
        });
        if (code_ent !== null) {
          this.$admin.http
            .get(
              `online-recheck/patcase/?code_pat=${this.patient_info.code_pat}&code_ent=${code_ent}`
            )
            .then((data) => {
              this.recorde = data.data.results[0];
            });
          this.$admin.http
            .get(
              `online-recheck/prescriptions/?code_pat=${this.patient_info.code_pat}&code_ent=${code_ent}`
            )
            .then((data) => {
              data.data.results.forEach(item=> {
                item.drug_pre.forEach(res=> {
                  this.prescription.push(res)
                })
              })
            });
          this.$admin.http
            .get(
              `online-recheck/checkupitems/?code_pat=${this.patient_info.code_pat}&code_ent=${code_ent}`
            )
            .then((data) => {
              this.inspect = data.data.results;
            });
          this.$admin.http
            .get(
              `online-recheck/inspectionitems/?code_pat=${this.patient_info.code_pat}&code_ent=${code_ent}`
            )
            .then((data) => {
              this.examine = data.data.results;
            });
        }else{
          this.recorde = null;
          this.examine = null;
          this.inspect = null;
        }
      });
    },
  },
};
</script>

<style scoped>
ul li::marker {
  color: #42b6c4;
}
.row + .row {
  margin-top: 0px;
}
.row > .col {
  padding: 0 12px !important;
}
.text-center {
  width: 87%;
  background-color: #fff;
  height: 80px;
  position: fixed;
  bottom: 0px;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.describe {
  width: 100%;
  margin: 0 auto;
  padding: 10px;
  line-height: 30px;
  font-size: 13px;
  /* border-bottom: 2px solid #ccc; */
}
.box-show {
  display: flex;
  font-size: 13px;
}
.box-show dd {
  margin: 15px 0;
}
</style>