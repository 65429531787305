<template>
  <div>
    <v-card :title="title">
      <va-list
        :filters="filters"
        disable-create
        disable-global-search
        disable-pagination
        disable-export
        resource="mr_to_home"
        :sort-desc="[true]"
      >
        <template v-slot:actions>
          <v-btn-toggle
            class="custom_btn"
            v-model="toggle_release"
            mandatory
            color="#214578"
          >
            <v-btn> 待配药 </v-btn>
            <v-btn> 已发出 </v-btn>
            <v-btn> 已完成 </v-btn>
          </v-btn-toggle>
        </template>
        <el-select
          class="select"
          style="margin: 0 20px 10px"
          :clearable="true"
          v-model="hospital"
          filterable
          placeholder="请选择医院"
        >
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.name"
            :value="item.name"
          >
          </el-option>
        </el-select>
        <v-data-table :headers="headers" :items="desserts">
          <template v-slot:[`item.actions`]="{ item }" v-if="toggle_release == 0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  class="mr-2"
                  color="primary"
                  @click="show_data(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-email-edit
                </v-icon>
              </template>
              <span>处理</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.actions`]="{ item }" v-else>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  class="mr-2"
                  color="primary"
                  @click="show_data(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-eye
                </v-icon>
              </template>
              <span>详情</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.acquisition_method`]="{ item }">
            <span v-if="item.acquisition_method == 1">邮寄到家</span>
            <span v-if="item.acquisition_method == 2">到院自取</span>
            <span v-if="item.acquisition_method == 3">PDF下载</span>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <span v-if="item.status == 10">待审核</span>
            <span v-if="item.status == 20">已驳回</span>
            <span v-if="item.status == 30">待付款</span>
            <span v-if="item.status == 40">已付款</span>
            <span v-if="item.status == 50">已寄出</span>
            <span v-if="item.status == 60">已完成</span>
            <span v-if="item.status == 70">已取消</span>
          </template>
          <template> </template>
        </v-data-table>
      </va-list>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["resource", "title"],
  data() {
    return {
      asideTitle: null,
      toggle_release: 0,
      id: null,
      item: null,
      show: false,
      filters: [{ source: "hospital_id", alwaysOn: true }],
      headers: [
        {
          text: "开方医院",
          value: "hospital_name",
          align: "center",
          sortable: false,
        },
        { text: "开方科室", value: "office_name", sortable: false },
        { text: "开方时间", value: "issue_date", sortable: false },
        { text: "处方编码", value: "code_pre", sortable: false },
        { text: "患者姓名", value: "pat_name", sortable: false },
        {
          text: "健康卡ID",
          value: "code_healthcard",
          sortable: false,
        },
        {
          text: "开方医生",
          value: "doctor_name",
          sortable: false,
        },
        {
          text: "审核药师",
          value: "created_by",
          sortable: false,
        },
        // {
        //   text: "取药方式",
        //   value: "acquisition_method",
        //   sortable: false,
        // },
        {
          text: "操作",
          value: "actions",
          // type: "date",
          sortable: false,
        },
      ],
      desserts: [],
      options: [],
      hospital: undefined,
      form: {},
    };
  },
  created() {
    this.form.status = 4;
    // this.$store
    //   .dispatch("bs_hospital/getOne", {
    //     id: JSON.parse(window.sessionStorage.getItem("user")).hospital,
    //   })
    //   .then((res) => {
    //     this.hospital = res.data.name;
    //   });
    this.getOptions();
  },
  methods: {
    getOptions() {
      this.$store.dispatch("bs_hospital/getList").then((res) => {
        this.options = res.data;
      });
    },
    show_data(item) {
      console.log(item);
      this.$router.push(`mr-to-home/${item.id}/edit`);
      this.$router.push({
        path: "/particulars",
        query: { item: JSON.stringify(item) },
      });
    },
    adjustment() {
      console.log(this.form);
      let obj_key = Object.keys(this.form);
      let src_key = "";
      obj_key.forEach((key) => {
        src_key = src_key + "&" + key + "=" + `${this.form[key]}`;
      });
      src_key = src_key.slice(1);
      if(src_key){
        this.$admin.http
        .get(`online-recheck/prescriptions/?${src_key}`)
        .then((res) => {
          this.desserts = res.data.results;
        });
      }
    },
  },
  watch: {
    hospital: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
          this.form.hospital_name = this.hospital;
          this.adjustment();
        } else {
          delete this.form.hospital_name;
          this.adjustment();
        }
      },
    },
    toggle_release: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val == 0) {
          this.form.status = 4;
          this.adjustment();
        }else if (val == 1) {
          this.form.status = 5;
          this.adjustment();
        }else if(val ==2) {
          this.form.status = 6;
          this.adjustment();
        }
      },
    },
  },
};
</script>
<style scoped>
::v-deep .el-input__inner {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1px #d2d2d2 solid !important;
  border-radius: 0;
  padding-bottom: 10px;
}
.select .el-icon-circle-close,
.el-input__prefix {
  display: block !important;
}

.custom_btn.v-item-group {
  left: 0;
  position: absolute;
}
</style>
<style lang="sass" scoped>
.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined)
  background-color: #eee
  color: #14B2C5
  &:hover
    background-color: #ccc
    color: #214578
</style>