<template>
  <va-show :item="item">
    <v-card>
      <v-card-text style="width: 97%; margin: 0 auto">
        <v-row>
          <ul style="padding: 1.5vw 0.5vw">
            <li>查看资讯清单</li>
            <li style="list-style: none; font-size: 10px; color: #999">
              MEDICAL DYNAMIC
            </li>
          </ul>
        </v-row>
        <div>
          <h2 style="text-align: center">{{ item.title }}</h2>
          <div
            style="
              display: flex;
              justify-content: space-evenly;
              color: #999;
              margin: 30px;
            "
          >
            <span> 作者：{{ item.author }} </span>
            <span> 发布时间：{{ created_time }} </span>
          </div>
          <div v-html="item.content"></div>
        </div>
        <!-- <va-field source="publisher"></va-field>
        <va-field source="title"></va-field>
        <va-field source="content"></va-field>
        <va-field source="created_time"></va-field>
        <va-field source="hospital_id"></va-field> -->
      </v-card-text>
      <div class="text-center">
        <v-btn @click="close" style="color: #42b6c4; margin-right: 50px"
          >取消</v-btn
        >
        <!-- <v-btn
        @click="isSure"
        style="background-color: #42b6c4; margin-right: 50px"
        >保存</v-btn
      > -->
      </div>
    </v-card>
  </va-show>
</template>

<script>
import moment from "moment";
export default {
  props: ["title", "item"],
  data() {
    return {
      created_time: moment(this.item.created_time).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
    };
  },
  created() {
    console.log(this.item);
  },
  methods: {
    close() {
      this.$router.push("/health-information");
    },
  },
};
</script>
<style scoped>
ul li::marker {
  color: #42b6c4;
}
.row + .row {
  margin-top: 0px;
}
.row > .col {
  padding: 0 12px !important;
}
.text-center {
  width: 87%;
  background-color: #fff;
  height: 80px;
  position: fixed;
  bottom: 0px;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>