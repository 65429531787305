<template>
  <div>
    <va-aside-layout :title="asideTitle">
      <medical-technology-priority-form
        :id="id"
        :item="item"
        @saved="onSaved"
      ></medical-technology-priority-form>
    </va-aside-layout>
    <div style="display: flex; width: 100%; height: 100%">
      <v-card class="tree" :title="title">
        <v-text-field
          class="search"
          v-model="search"
          label="查询"
          light
          clearable
          single-line
          hide-details
          append-icon="mdi-magnify"
          clear-icon="mdi-close"
        ></v-text-field>
        <v-treeview
          :active.sync="active"
          :items="items"
          :search="search"
          open-all
          activatable
          color="#069cae"
        >
        </v-treeview>
      </v-card>

      <v-card :icon="resource.icon" class="list">
        <v-data-table
          @item-action="onAction"
          :headers="headers"
          :items="desserts"
          hide-default-footer
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  class="mr-2"
                  color="blue"
                  @click="editItem(item)"
                  v-bind="attrs"
                  v-on="on"
                  v-if="$admin.can(['change_examinationdictionaries'])"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span>编辑</span>
            </v-tooltip>
            <!-- <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  class="mr-2"
                  color="red"
                  @click="deleteItem(item)"
                  v-bind="attrs"
                  v-on="on"
                  v-if="$admin.can(['delete_examinationdictionaries'])"
                >
                  mdi-trash-can
                </v-icon>
              </template>
              <span>删除</span>
            </v-tooltip> -->
          </template>
        </v-data-table>
        <div style="width: 100%; display: flex; justify-content: flex-end">
          <v-pagination
            v-model="page"
            :length="length_data"
            :total-visible="7"
          ></v-pagination>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  props: ["resource", "title"],
  data() {
    return {
      filters: [],
      headers: [
        { text: "服务组编码", value: "project_code", sortable: false },
        { text: "服务组名称", value: "project_name", sortable: false },
        { text: "服务优先级", value: "hospital_name", sortable: false },
        { text: "操作", value: "actions", sortable: false },
      ],
      form: {},
      page: 1,
      length_data: null,
      desserts: [],
      asideTitle: null,
      id: null,
      item: null,
      show: false,
      search: null,
      caseSensitive: false,
      items: [
        { name: "CT", id: 1 },
        { name: "DR", id: 2 },
      ],
      active: [],
    };
  },
  methods: {
    adjustment() {
      let obj_key = Object.keys(this.form);
      let src_key = "";
      obj_key.forEach((key) => {
        src_key = src_key + "&" + key + "=" + `${this.form[key]}`;
      });
      src_key = src_key.slice(1);
      if (src_key) {
        // 修改接口
        this.$admin.http.get(`base-system/exa_dic/?${src_key}`).then((res) => {
          this.length_data =
            (res.data.count / 10) % 1 === 0
              ? res.data.count / 10
              : parseInt(res.data.count / 10) + 1;
          this.desserts = res.data.results;
        });
      }
    },
    editItem(item) {
      this.id = null;
      this.asideTitle = `编辑优先级`;
      this.id = item.id;
      this.item = item;
      this.asideid = item.id;
      this.show = false;
    },
    async onAction({ action, title, id, item }) {
      this.asideTitle = title;
      this.id = id;
      this.show = action === "show";
      this.item = item;
    },
    onSaved() {
      this.asideTitle = null;
      this.$refs.list.fetchData();
    },
  },
  computed: {},
  watch: {
    active: {
      deep: true,
      immediate: true,
      handler(val) {
        console.log(val);
      },
    },
    page: {
      deep: true,
      immediate: true,
      handler(val) {
        this.show = true;
        if (val == "" || val == null || val == undefined) {
          delete this.form.page;
          this.adjustment();
        } else {
          delete this.form.page;
          this.form.page = val;
          this.adjustment();
        }
      },
    },
  },
};
</script>

<style>
.tree {
  width: 20%;
  background-color: #fff;
  margin-right: 10px;
}

.list {
  width: 80%;
  min-height: 735px;
  height: 100%;
  background: #fff;
}

.search {
  padding: 1vw;
}
</style>
