<template>
  <div>
    <AsideFu ref="child"> </AsideFu>
    <div
      style="display: flex; justify-content: space-between; align-items: center"
    >
      <v-btn-toggle
        class="custom_btn"
        v-model="toggle_release"
        mandatory
        color="#214578"
      >
        <v-btn @click="toBeReceived"> 待接诊 </v-btn>
        <v-btn @click="toBeInquired"> 待问诊 </v-btn>
        <v-btn @click="checked"> 已问诊 </v-btn>
        <v-btn @click="revert"> 已退诊 </v-btn>
      </v-btn-toggle>
      <va-action-button
        icon="mdi-minus"
        color="#14B2C5"
        @click="stopInquiries"
        style="color: #14b2c5"
        label="停诊"
      ></va-action-button>
    </div>
    <template>
      <div style="display: flex; justify-content: space-between">
        <div class="left" :style="{width:toggle_release== 1||toggle_release== 2 ? '30%':'49%'}">
          <v-card-text style="width: 97%; margin: 0 auto">
            <v-row>
              <ul style="padding: 0.5vw">
                <li>在线复诊</li>
                <li style="list-style: none; font-size: 10px; color: #999">
                  ONLINE RETURN VISIT
                </li>
              </ul>
            </v-row>
            <v-data-table
              style="margin: 15px 0"
              @click:row="rowClicked"
              :headers="headers"
              :items="desserts"
              v-model="selected"
            >
              <template v-slot:[`item.code_dayslottp`]="{ item }">
                <span v-if="item.code_dayslottp == 'morning'"
                  >{{ moment(item.appoint_date).format("MM/DD") }} 上午</span
                >
                <span v-else-if="item.code_dayslottp == 'afternoon'"
                  >{{ moment(item.appoint_date).format("MM/DD") }} 下午</span
                >
                <span v-else-if="item.code_dayslottp == 'night'"
                  >{{ moment(item.appoint_date).format("MM/DD") }} 晚上</span
                >
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <span v-if="item.status == '2'">未接诊</span>
                <span v-if="item.status == '3'">问诊中</span>
                <span v-if="item.status == '4'">已问诊</span>
                <span v-if="item.status == '8'">已退诊</span>
              </template>
            </v-data-table>
          </v-card-text>
        </div>
        <div class="center" v-show="toggle_release == 1 || toggle_release == 2">
          <dialogue
            :id="list_id"
            :health_card_id="health_card_id"
            ref="dialogue"
          ></dialogue>
        </div>
        <div class="right" :style="{width:toggle_release== 1||toggle_release== 2 ? '34%':'50%'}">
          <describe
            :id="list_id"
            ref="describe"
            :code_ent="code_ent"
            :toggle_release="toggle_release"
          ></describe>
        </div>
        <div class="text-center">
          <v-btn
            style="background-color: #42b6c4; color: #fff; margin-right: 50px"
            v-show="toggle_release == 1 || toggle_release == 2"
            @click="appointment(list_id, code_ent)"
            >预约加号</v-btn
          >
          <div v-show="code_ent !== null">
            <v-btn
              style="background-color: #42b6c4; color: #fff; margin-right: 50px"
              v-show="toggle_release == 1 || toggle_release == 2"
              @click="completedCases(list_id)"
              >填写病历</v-btn
            >
            <v-btn
              style="background-color: #42b6c4; color: #fff; margin-right: 50px"
              v-show="toggle_release == 1"
              @click="prescribing(list_id, code_ent)"
              >开方</v-btn
            >
            <v-btn
              style="background-color: #42b6c4; color: #fff; margin-right: 50px"
              v-show="toggle_release == 1"
              @click="inspect(list_id, code_ent)"
              >检查</v-btn
            >
            <v-btn
              style="background-color: #42b6c4; color: #fff; margin-right: 50px"
              v-show="toggle_release == 1"
              @click="examine(list_id, code_ent)"
              >检验</v-btn
            >
          </div>
          <div class="text-center" v-show="toggle_release == 0">
          <v-btn
            style="background-color: #42b6c4; color: #fff; margin-right: 50px"
            @click="receive(list_id)"
            >接诊</v-btn
          >
          <v-btn
            style="background-color: #42b6c4; color: #fff; margin-right: 50px"
            @click="is_withdrawal(list_id)"
            >退诊</v-btn
          >
        </div>
          <v-btn
            style="background-color: #42b6c4; color: #fff; margin-right: 50px"
            v-show="toggle_release == 1"
            @click="terminate(list_id)"
            >结束问诊</v-btn
          >
        </div>
      </div>
    </template>
    <el-dialog
      title="退诊信息"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
    >
      <el-form>
        <el-form-item label="退诊原因:">
          <el-input
            v-model="withdrawal"
            type="textarea"
            :rows="2"
            placeholder="请输入退诊原因"
          ></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="isSure(list_id)">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";
import describe from "@/components/online/describe.vue";
import dialogue from "@/components/online/dialogue.vue";
import AsideFu from "@/components/asidefu.vue";
export default {
  components: {
    describe,
    dialogue,
    AsideFu,
  },
  data() {
    return {
      withdrawal: null,
      centerDialogVisible: false,
      opened: false,
      moment,
      toggle_release: 1,
      desserts: [],
      selected: [],
      headers: [
        { text: "患者姓名", value: "pat_name", sortable: false },
        { text: "年龄", value: "pat_age", sortable: false },
        { text: "预约时间", value: "code_dayslottp", sortable: false },
        { text: "状态", value: "status", sortable: false },
      ],
      list_id: null,
      row_info: null,
      health_card_id: null,
      doctor_id: null,
      code_ent: null,
      form: {},
    };
  },
  created() {
    this.doctor_id = JSON.parse(window.sessionStorage.getItem("user")).doctor;
    this.form.doctor_id = this.doctor_id;
    this.form.appoint_type = 4;
    this.form.status = 3;
    this.adjustment();
    // this.$admin.http
    //   .get(
    //     `patient-account/online_therapy/?appoint_type=4&status=3&doctor_id=${this.doctor_id}`
    //   )
    //   .then((res) => {
    //     this.desserts = res.data.results;
    //   });
  },
  methods: {
    // 开方
    prescribing(id, code_ent) {
      if (id == null) {
        this.$message({
          message: "暂未选择患者，不能开处方！",
          type: "warning",
        });
        return;
      } else {
        this.$router.push({
          name: "Prescribing",
          query: {
            id: id,
            code_ent: code_ent,
          },
        });
      }
    },
    // 预约加号
    appointment(id, code_ent) {
      if (id == null) {
        this.$message({
          message: "暂未选择患者，不能加号！",
          type: "warning",
        });
        return;
      } else {
        this.$router.push({
          name: "appointment",
          query: {
            id: id,
            code_ent: code_ent,
          },
        });
      }
    },
    // 检查
    inspect(id, code_ent) {
      if (id == null) {
        this.$message({
          message: "暂未选择患者，不能开检查！",
          type: "warning",
        });
        return;
      } else {
        this.$router.push({
          name: "inspect",
          query: {
            id: id,
            code_ent: code_ent,
          },
        });
      }
    },
    // 检验
    examine(id, code_ent) {
      if (id == null) {
        this.$message({
          message: "暂未选择患者，不能开检验！",
          type: "warning",
        });
        return;
      } else {
        this.$router.push({
          name: "examine",
          query: {
            id: id,
            code_ent: code_ent,
          },
        });
      }
    },
    // 填写病历
    completedCases(id) {
      if (id == null) {
        this.$message({
          message: "暂未选择患者，不能开方！",
          type: "warning",
        });
        return;
      } else {
        this.$refs.child.handleUpdate(this.list_id, this.code_ent, true);
      }
    },
    receive(id) {
      this.$admin.http.put(`patient-account/online_therapy/${id}/`, {
        status: 3,
      });
      this.toBeReceived();
    },
    is_withdrawal(id) {
      if (id) {
        this.centerDialogVisible = true;
      } else {
        this.$message({
          message: "请选择患者，再退诊",
          type: "warning",
        });
      }
    },
    isSure(id) {
      if(!this.withdrawal) {
        this.$message({
          message: "退诊原因未填写，请填写后重试",
          type: "warning",
        });
        return
      }
      this.$admin.http.get(`patient-account/aptrecords/${id}/`).then((res) => {
        let data = res.data;
        data.status = 8;
        data.withdrawal_reason = this.withdrawal;
        // data.is_payment = false
        this.$admin.http
          .put(`patient-account/appointrecords/${id}/`, data)
          .then((res) => {
            let order_data = {
              original_order: res.data.id,
              total_fee: res.data.regfee,
              refund_status: 10,
              refund_type: "复诊",
              refunder_name: res.data.doctor_name,
              refunder_code: res.data.doctor_code,
              // refund_channel: 1,
              refund_time: moment().format("YYYY-MM-DD HH:mm:ss"),
              refund_reason: res.data.withdrawal_reason,
            };
            this.$admin.http
              .post(
                `expense-management/refund_management/appointment_refund/`,
                order_data
              )
              .then((order) => {
                  this.$message({
                  message: "退诊成功",
                  type: "success",
                });
              });
          });
        this.toBeReceived();
      });
      this.centerDialogVisible = false;
    },
    terminate(id) {
      this.$admin.http.put(`patient-account/online_therapy/${id}/`, {
        status: 4,
      });
      this.toBeInquired();
    },
    stopInquiries() {
      this.$router.push({
        path: "/ws-doctor-work-scheduling-main-recheck",
        query: { doctor_id: this.doctor_id },
      });
    },
    rowClicked(row) {
      this.health_card_id = null
      this.selected = [];
      this.selected.push(row);
      this.health_card_id = row.ehc_id;
      this.list_id = row.id;
      this.row_info = row;
      this.$admin.http
        .get(
          `/patient-account/aptrecords/?appoint_type=4&code_pat=${row.health_card_id}&is_payment=true&doctor_id=${this.doctor_id}`
        )
        .then((res) => {
          res.data.results.forEach((item) => {
            if (item.id == row.id) {
              this.code_ent = item.code_ent;
              this.$refs.child.handleUpdate(this.list_id, this.code_ent, false);
            }
          });
        });
    },
    adjustment() {
      let obj_key = Object.keys(this.form);
      let src_key = "";
      obj_key.forEach((key) => {
        src_key = src_key + "&" + key + "=" + `${this.form[key]}`;
      });
      src_key = src_key.slice(1);
      if (this.form.status) {
        this.$admin.http
          .get(`/patient-account/online_therapy/?${src_key}`)
          .then((res) => {
            this.desserts = res.data.results;
          });
      }
    },
    toBeReceived() {
      this.form.status = 2;
      this.adjustment();
      // this.$admin.http
      //   .get(
      //     `patient-account/online_therapy/?appoint_type=4&status=2&doctor_id=${this.doctor_id}`
      //   )
      //   .then((res) => {
      //     this.desserts = res.data.results;
      //   });
    },
    toBeInquired() {
      this.form.status = 3;
      this.adjustment();
    },
    checked() {
      this.form.status = 4;
      this.adjustment();
    },
    revert() {
      this.form.status = 8;
      this.adjustment();
    },
  },
  watch: {
    toggle_release: {
      deep: true,
      immediate: true,
      handler(val) {
        this.list_id = null;
        this.selected = [];
      },
    },
  },
};
</script>

<style scoped>
.text-center {
  width: 87%;
  background-color: #fff;
  height: 80px;
  position: fixed;
  bottom: 0px;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
ul li::marker {
  color: #42b6c4;
}
.row + .row {
  margin-top: 0px;
}
.row > .col {
  padding: 0 12px !important;
}
.left {
  width: 30%;
  /* border: 2px solid #ccc; */
  background-color: #fff;
  border-radius: 10px;
}
.center {
  width: 34%;
  /* border: 2px solid #ccc; */
  background-color: #fff;
  border-radius: 10px;
}
.right {
  /* border: 2px solid #ccc; */
  background-color: #fff;
  border-radius: 10px;
}
</style>
<style lang="sass" scoped>
.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined)
  background-color: #eee
  color: #14B2C5
  &:hover
    background-color: #ccc
    color: #214578
.custom_btn.v-item-group
  left: -15px
</style>