<template>
    <va-show :item="item">
            <template v-slot:heading>
              <div class="display-2">
                {{ title }}
              </div>
            </template>
            <v-card-text>
              <va-field source="role_code" ></va-field>
              <va-field source="group_id" label="角色名称">{{role_name}}</va-field>
              <va-field source="hospital_id" >{{hospital}}</va-field>
              <va-field source="is_active" type="boolean"></va-field>
              <va-field source="created_user"  label="创建人"></va-field>
              <va-field source="created_at"  label="创建时间" type="date" format= "longTime"></va-field>
              <h4>权限管理：</h4>
                  <v-treeview
                    selectable
                    selected-color="blue"
                    :items="items"
                    v-model="selection"
                    :selection-type="selectionType"
                    return-object
                    :active.sync="active"
                    activatable
                    color="#586AFC"
                    transition
                  ></v-treeview>
            </v-card-text>
    </va-show>
</template>

<script>
export default {
  props: ["title", "item"],
  data(){
  return{
     hospital:'',
     role_name:"",
     active: [],
     items:[],
     selection: [],
     selectionType: "leaf",
   
  }
},
  mounted() {
   this.getdatas();
   this.getTree();   
  },
  watch: {
   "item.id": {
      deep: true,
      handler(newValue, oldValue) {
        this.getTree();
        this.getdatas();
      },
    },
  },
  methods:{
    async getTree() {
      let arr = [];
      let data = await this.$admin.http.get("/base-system/menu_permissions/");
      this.items = data.data.data;
      if (this.item.id) {
        let dataArr = await this.$admin.http.get(
          "/base-system/groups/" + this.item.group_id + "/"
        );
        dataArr.data.permissions.forEach((item) => {
          let obj = {};
          obj.id = item;
          arr.push(obj);
        });
      }
      this.selection = arr;
    },
    async getdatas(){
        let bs_hospital_getOne = await this.$store.dispatch(
          "bs_hospital/getOne",
          { id: this.item.hospital_id }
        );
        this.hospital=bs_hospital_getOne.data.name;

        let auth_group_getOne = await this.$store.dispatch(
          "auth_group/getOne",
          { id: this.item.group_id }
        );
        let A=auth_group_getOne.data.name.split('_')
        let B= A.splice(0,1)
        this.role_name = A[0]
    },
  }
};
</script>
