<template>
  <div>
    <div>不登录也可访问页面</div>
  </div>
</template>
<script>
	export default {
		data() {
			return {};
		},
		created() {

		},
		methods: {}
	};
</script>

<style scoped>
</style>