<template>
    <os-record-list :resource="resource" :type_fee="'电话问诊'"></os-record-list>
</template>

<script>

export default {
  props: ["resource"],
  data(){
    return{
      title: "电话问诊记录",
    }
  },
  moment() {
  }
};
</script>