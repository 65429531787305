<template>
  <div>
    <v-card class="v_card">
      <span class="red_sp">
        示例：可提前预约7天，预约截止时间：“预约时段开始”“前”24小时，取消预约截止时间：“预约时段开始”“前”8小时
      </span>
    </v-card>

    <v-card class="v_card" v-for="(item, index) in title" :key="index">
      <div style="line-height: 60px">
        <span class="dot_circle"></span
        ><span class="title_con">{{ item.type_name }}参数配置</span>
      </div>
      <div>
        <v-row>
          <v-col sm="4">
            <va-text-input
              label="可提前预约天数(天)"
              source="advance_days"
              v-model="form_data[index].advance_days"
            ></va-text-input>
          </v-col>
        </v-row>

        <div style="line-height: 60px; font-size: 20px">预约截止日期</div>
        <v-row justify="center">
          <v-col sm="4">
            <va-select-input
              source="apt_deadline"
              v-model="form_data[index].apt_deadline"
              label="参考时间点"
              :items-per-page="1000"
            ></va-select-input>
          </v-col>
          <v-col sm="4">
            <va-select-input
              source="apt_time_orientation"
              v-model="form_data[index].apt_time_orientation"
              label="时间方向"
              :items-per-page="1000"
            ></va-select-input>
          </v-col>
          <v-col sm="4">
            <va-text-input
              v-model="form_data[index].apt_hours"
              label="请输入时间（小时）"
              source="apt_hours"
            ></va-text-input>
          </v-col>
        </v-row>

        <div style="line-height: 60px; font-size: 20px">取消预约截止日期</div>
        <v-row justify="center">
          <v-col sm="4">
            <va-select-input
              label="参考时间点"
              source="cancel_apt_deadline"
              v-model="form_data[index].cancel_apt_deadline"
              :items-per-page="1000"
            ></va-select-input>
          </v-col>
          <v-col sm="4">
            <va-select-input
              label="时间方向"
              source="cancel_time_orientation"
              v-model="form_data[index].cancel_time_orientation"
              :items-per-page="1000"
            ></va-select-input>
          </v-col>
          <v-col sm="4">
            <va-text-input
              label="请输入时间（小时）"
              source="cancel_apt_hours"
              v-model="form_data[index].cancel_apt_hours"
            >
            </va-text-input>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <div style="margin-bottom: 80px"></div>
    <div class="text-center">
      <v-btn @click="isSure" color="primary">保存</v-btn>
      <!-- <va-save-button></va-save-button> -->
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
export default {
  // props: ["resource", "title"],
  data() {
    return {
      title: null,
      form_data: [],
      hospital: null,
      hospital_code: null,
      hospital_name: null,
      office: null,
      office_code: null,
      office_name: null,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  created() {
    this.hospital = JSON.parse(window.sessionStorage.getItem("user")).hospital;
    this.office = JSON.parse(window.sessionStorage.getItem("user")).office;
    this.$store.dispatch("os_scheduling_type/getList",{
      filter: {
          is_active: true
        },
    }).then((res)=> {
      this.title = res.data
    })
  },
  mounted() {
      this.$store
        .dispatch("os_scheduling_apt_config/getList", {
          sort: [{ by: "apt_type", desc: false }],
        })
        .then((res) => {
          this.form_data = [];
          if(res.data) {
            res.data.forEach((item) => {
              this.form_data.push(item);
            });
          }
        });
  },
  watch: {
    hospital: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
          this.$store
            .dispatch("bs_hospital/getOne", { id: val })
            .then((res) => {
              this.hospital_code = res.data.codenum;
              this.hospital_name = res.data.name;
              this.getdata();
            });
        }
      },
    },
    office: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
          this.$store.dispatch("bs_office/getOne", { id: val }).then((res) => {
            this.office_code = res.data.codenum;
            this.office_name = res.data.name;
          });
        }
      },
    },
  },
  methods: {
    getdata() {
      this.title.forEach((item, index) => {
        this.form_data.push({
          hospital_code: this.hospital_code,
          hospital_name: this.hospital_name,
          office_code: this.office_code,
          office_name: this.office_name,
          advance_days: null, //可提前几天预约
          apt_deadline: null, //预约截止时间
          apt_time_orientation: null, //预约时间方向
          apt_hours: null, //可提前几个小时预约
          cancel_apt_deadline: null, //取消预约截止时间
          cancel_time_orientation: null, //取消预约时间方向
          cancel_apt_hours: null, //可提前几个小时取消预约
          apt_type: index + "", //预约类型
          created_time: moment().format("YYYY-MM-DD HH:mm:ss"), //	创建时间
        });
      });
    },
    isSure() {
      this.form_data.forEach((item) => {
        if (!this.form_data[0].id) {
          this.$store.dispatch("os_scheduling_apt_config/create", {
            data: item,
          });
        } else {
          this.$store.dispatch("os_scheduling_apt_config/update", {
            id: item.id,
            data: item,
          });
        }
      });
    },
  },
};
</script>
<style scoped>
.v_card {
  padding: 20px;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.red_sp {
  color: red;
}

.dot_circle {
  display: inline-block;
  background-color: #069cae;
  width: 8px;
  height: 8px;
  margin-right: 10px;
  border-radius: 8px;
  margin-bottom: 2px;
}

.title_con {
  display: inline-block;
  font-size: 20px;
}

.text-center {
  width: 87%;
  background-color: #fff;
  height: 80px;
  position: fixed;
  bottom: 0px;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
</style>
