<template>
  <base-material-card :icon="resource.icon" title="服务组可选项目">
    <va-list :filters="filters" disable-global-search>

      <div class="divBox">
        <!-- <el-input
            style="margin: 0 20px 10px; width: 200px"
            v-model="hospital_names"
            @change="changeSearch()"
            placeholder="查询"
          ></el-input> -->
        <el-input style="width: 200px" placeholder="项目名称"></el-input>
        <el-select class="select" style="margin: 0 20px 10px" :clearable="true" @change="changeSearch()"
          placeholder="检查类型" filterable>
          <el-option v-for="item in propertys" :key="item.id" :label="item.item_name" :value="item.codenum">
          </el-option>
        </el-select>
        <el-select class="select" style="margin: 0 20px 10px" :clearable="true" @change="changeSearch()"
          placeholder="基本操作" filterable>
          <el-option v-for="item in operates" :key="item.id" :label="item.item_name" :value="item.codenum">
          </el-option>
        </el-select>
        <el-select class="select" style="margin: 0 20px 10px" :clearable="true" @change="changeSearch()"
          placeholder="系统" filterable>
          <el-option v-for="item in systems" :key="item.id" :label="item.item_name" :value="item.codenum">
          </el-option>
        </el-select>
        <el-select class="select" style="margin: 0 20px 10px" :clearable="true" @change="changeSearch()"
          placeholder="部位" filterable>
          <el-option v-for="item in regions" :key="item.id" :label="item.item_name" :value="item.codenum">
          </el-option>
        </el-select>
      </div>
      <va-data-table :fields="fields">

      </va-data-table>
    </va-list>
  </base-material-card>
</template>

<script>
export default {
  props: ["resource", "title"],
  data() {
    return {
      filters: [
        // { alwaysOn: true, label: "项目名称", source: "item_name" },
        // { alwaysOn: true, label: "检查类型", source: "item_type" },
        // { alwaysOn: true, label: "基本操作", source: "item_operation" },
        // { alwaysOn: true, label: "系统", source: "item_sys" },
        // { alwaysOn: true, label: "部位", source: "item_regions" },
      ],
      propertys: [],
      regions: [], systems: [], operates: [],
      fields: [
        {
          source: "item_name",
          label: "项目名称",
          sortable: false,
          align: "center",
        },
        {
          source: "item_type_name",
          label: "检查类型",
          sortable: false,
          align: "center",
        },
        {
          source: "item_operation_name",
          label: "基本操作",
          sortable: false,
          align: "center",
        },
        {
          source: "item_sys_name",
          label: "系统",
          sortable: false,
          align: "center",
        },
        {
          source: "item_regions_name",
          label: "部位",
          sortable: false,
          align: "center",
        },
        {
          source: "remark",
          label: "备注",
          sortable: false,
          align: "center",
        },
        { source: 'available', label: '启用', sortable: true, sortable: true, type: "boolean", align: "center" },
      ],
    };
  },
  mounted() {
    this.$store.dispatch("ig_item_property/getList").then(res => {
      this.propertys = res.data ?? [];
    })
    //基本操作：
    this.$store.dispatch("ig_item_operate/getList").then(res => {
      this.operates = res.data ?? [];
    })
    //系统：
    this.$store.dispatch("ig_item_system/getList").then(res => {
      this.systems = res.data ?? [];
    })
    //部位：
    this.$store.dispatch("ig_item_regions/getList").then(res => {
      this.regions = res.data ?? [];
    })
  },
  methods: {

  }
};
</script>
<style scoped>
::v-deep .el-input__inner {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1px #d2d2d2 solid !important;
  border-radius: 0;
  padding-bottom: 10px;
}

.select .el-icon-circle-close,
.el-input__prefix {
  display: block !important;
}

.custom_btn.v-item-group {
  left: 0;
  position: absolute;
}

.divBox {
  display: flex;
  align-items: flex-start;
}
</style>