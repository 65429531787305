<template>
    <mt-scheduling-main-list :resource="resource" :type_fee="2"></mt-scheduling-main-list>
</template>

<script>

export default {
  props: ["resource"],
  data(){
    return{
      title: "检验排班"
    }
  }
};
</script>