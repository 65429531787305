import Vue from "vue";
import buildHasuraProvider from "va-data-hasura";
import apolloClient from "@/providers/apollo_client";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import buildAdmin from "./plugins/admin";
import App from "./App.vue";
// import axios from "axios";
import "./plugins/i18n";
import "./plugins/base";
import "./plugins/chartist";
import "./sass/overrides.sass";
// import "../css/materialdesignicons.min.css";
{
  /* <link href="css/materialdesignicons.min.css" rel="stylesheet"> */
}

// 组件
// elementui
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI);
// vuetify
import vuetify from "./plugins/vuetify";
// Vue.prototype.$axios=axios;
Vue.config.productionTip = false;

// 引入echarts
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts

// 时间全局过滤器
import moment from 'moment'
Vue.filter('dateFmt', (input, formatString = "YYYY-MM-DD") => {
  return moment(input).format(formatString)
})

import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor);

// 下拉加载
import elTableInfiniteScroll from 'el-table-infinite-scroll';
Vue.use(elTableInfiniteScroll);

// 地址+端口
import global from "@/global/global"
Vue.prototype.global = global
Vue.prototype.$statusColor = (s) => {
  const colors = {
    published: "success",
    pending: "warning",
    denied: "error",
  };
  return colors[s];
};

import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer)
Viewer.setDefaults({
  Options: {
    'inline': true,
    'button': true,
    'navbar': true,
    'title': false,
    'toolbar': true,
    'tooltip': true,
    'movable': true,
    'zoomable': true,
    'rotatable': true,
    'scalable': true,
    'transition': true,
    'fullscreen': true,
    'keyboard': true,
    'url': 'data-source'
  }
})

// main.js中引入样式
import 'video.js/dist/video-js.min.css'


// 融云集成
// imkit 为核心模块
// import {
//   defineCustomElements,
//   imkit
// } from '@rongcloud/imkit'
// 引入自定义组件
// defineCustomElements()
// 接入时需要将 '请更换您应用的 appkey' 替换为您的应用的 appkey
import custom_service from "@/global/custom_service";
import custom_conversation from "@/global/custom_conversation";
import custom_message from "@/global/custom_message";
//导入 IMLib 5.X
// import * as RongIMLib from "@rongcloud/imlib-next";

Vue.directive('scroll-loadMore', {
  bind(el, binding) {
    const SELECTWRAP_DOM = el.querySelector(
      '.el-select-dropdown .el-select-dropdown__wrap'
    )
    SELECTWRAP_DOM.addEventListener('scroll', function () {
      const condition =
        this.scrollHeight - this.scrollTop <= this.clientHeight
      if (condition) {
        binding.value()
      }
    })
  }
})

buildAdmin().then(admin => {
  new Vue({
    // axios,
    router,
    store,
    vuetify,
    i18n,
    admin,
    render: (h) => h(App),
    // async beforeCreate() {
    //   // 初始化imkit
    //   let libOption = {
    //     appkey: 'pvxdm17jpuyjr',
    //     logLevel: 0
    //   }
    //   imkit.init({
    //     service: custom_service,
    //     libOption: libOption,
    //     customIntercept: custom_conversation,
    //     customMessage: custom_message,             '//koooooooouuuuu
    //     // customDisplayMessage: customDisplayMessage
    //   });
    //   RongIMLib.init({
    //     appkey: 'pvxdm17jpuyjr',
    //   });
    //   const PersonMessage = imkit.registerMessageType('kit:person', true, true, [], false)
    //   const GroupMessage = imkit.registerMessageType('kit:GrpNtf', true, true, [], false)
    //   window.PersonMessage = PersonMessage
    //   window.GroupMessage = GroupMessage
    // }
  }).$mount("#app");
})