<template>
  <div>
    <v-card style="padding: 2vw 0.5vw">
      <v-card-text style="width: 97%; margin: 0 auto">
        <v-row>
          <ul>
            <li>患者信息</li>
            <li style="list-style: none; font-size: 10px; color: #999">
              PATIENT INFORMATION
            </li>
          </ul>
        </v-row>
        <v-row style="margin: 15px 0">
          <v-col>
            <span>健康卡ID：{{ params.health_card_id }}</span>
          </v-col>
          <v-col>
            <span>患者姓名：{{ params.pat_name }}</span>
          </v-col>
        </v-row>
        <v-row style="margin: 15px 0">
          <v-col>
            <span v-if="params.pat_gender == 1">患者性别：男</span>
            <span v-else>患者性别：女</span>
          </v-col>
          <v-col>
            <span>患者年龄：{{ params.pat_age }}</span>
          </v-col>
        </v-row>
        <v-row style="margin: 15px 0">
          <v-col>
            <span>联系方式：{{ params.pat_phone1 }}</span>
          </v-col>
          <v-col> </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card style="padding: 2vw 0.5vw; margin: 20px 0">
      <v-card-text style="width: 97%; margin: 0 auto">
        <v-row>
          <ul>
            <li>预约信息</li>
            <li style="list-style: none; font-size: 10px; color: #999">
              APPOINTMENT INFORMATION
            </li>
          </ul>
        </v-row>
        <v-row style="margin: 15px 0">
          <v-col>
            <el-date-picker
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              v-model="work_date"
              type="date"
              placeholder="选择预约日期"
              style="width: 100%"
              clearable
            >
            </el-date-picker>
          </v-col>
          <v-col>
            <el-select
              v-model="hospital"
              filterable
              placeholder="请选择医院"
              clearable
            >
              <el-option
                v-for="item in hospital_data"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </v-col>
          <v-col>
            <el-select
              v-model="office"
              filterable
              placeholder="请选择科室"
              clearable
              :disabled="hospital && work_date ? false : true"
            >
              <el-option
                v-for="item in office_data"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </v-col>
        </v-row>
        <v-row style="margin: 15px 0">
          <v-col>
            <el-select
              clearable
              v-model="doctor"
              filterable
              placeholder="请选择医生"
              :disabled="hospital && work_date && office ? false : true"
            >
              <el-option
                v-for="item in doctor_data"
                :key="item.id"
                :label="`${item.name}--${item.position_name}`"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </v-col>
          <!-- <v-col>
            <el-input
              v-model="position"
              placeholder="请选择职级"
              disabled
            ></el-input>
          </v-col> -->
          <v-col>
            <el-input
              v-model="cost"
              placeholder="请填写挂号费用"
              disabled
            ></el-input>
          </v-col>
          <v-col>
            <el-input
              v-model="time_data"
              placeholder="请选择时段"
              disabled
            ></el-input>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <el-table
      :data="detail"
      height="300"
      style="width: 100%; margin-bottom: 80px"
      @current-change="handleCurrentChange"
    >
      <el-table-column label="时段" prop="name" align="center">
      </el-table-column>
      <el-table-column label="限号" prop="num_count" align="center">
      </el-table-column>
      <el-table-column label="余号" prop="left_num" align="center">
      </el-table-column>
      <el-table-column label="挂号费用（元）" prop="doctor_fee" align="center">
      </el-table-column>
      <el-table-column label="时段开始时间" prop="start_time" align="center">
      </el-table-column>
      <el-table-column label="时段结束时间" prop="end_time" align="center">
      </el-table-column>
    </el-table>
    <div class="text-center">
      <v-btn @click="close" style="color: #42b6c4; margin-right: 50px"
        >取消</v-btn
      >
      <v-btn
        @click="isSure"
        style="background-color: #42b6c4; margin-right: 50px"
        >确认</v-btn
      >
    </div>
  </div>
</template>

<script>
import Utils from '../global/utils';
import moment from "moment";
export default {
  data() {
    return {
      work_date: moment().format("YYYY-MM-DD"),
      params: null,
      user: null,
      doctor: null,
      office: null,
      hospital: null,
      hospital_data: null,
      office_data: null,
      doctor_data: null,
      position: null,
      time_data: null,
      detail: null,
      cost: null,
      dw_id: null,
      jzk_id: null,
    };
  },
  created() {
    this.user = JSON.parse(window.sessionStorage.getItem("user"));
    this.query_info();
    this.getClass();
  },
  methods: {
    getjzk(id, code) {
      this.$admin.http
        .get(
          `/patient-account/patient_card/?electronic_health_card=${id}&hospital_code=${code}`
        )
        .then((res) => {
          console.log(11111111111111, res);
          if (res.data.results.length > 0) {
            this.jzk_id = res.data.results[0].patient_card_code;
          } else {
            this.jzk_id = "";
          }
        });
    },
    handleCurrentChange(val) {
      this.time_data = val.name;
      this.dw_id = val.id;
    },
    // 取消
    close() {
      this.$router.push("/return-visit/");
    },
    // 确认
    isSure() {
      let data = {
        code_pat: this.params.pat_codenum,
        code_entp: 1,
        appoint_type: 1,
        appoint_date: this.work_date,
        status: 1,
        regfee: this.cost,
        office_id: this.office,
        doctor_id: this.doctor,
        hospital_id: this.hospital,
        is_payment: false,
        dw_id: this.dw_id,
        describe: "",
      };
      if (this.detail.name == "上午") {
        data.code_dayslottp = "morning";
      } else if (this.detail.name == "下午") {
        data.code_dayslottp = "afternoon";
      } else {
        data.code_dayslottp = "night";
      }
      this.$admin.http
        .post("/patient-account/aptrecords/", data)
        .then((res) => {
          console.log(res);
          let order = {
            out_trade_no: res.data.data.code_exapt,
            // pay_mode: 2,//支付宝支付
            trade_type: "applet_payment", //common.js中有定义
            order_type: 1, //1.预约挂号
            order_status: 1, //1,下单成功
            trade_state: 0, //支付状态值:0,等待支付
            doctor_name: res.data.data.doctor_name, //医生姓名
            doctor: res.data.data.doctor_code, //医生标识
            patient_name: res.data.data.pat_name, //患者名称
            patient_code: this.jzk_id, //患者标识
            hospital_name: res.data.data.hospital_name, //医院名称
            hospital_code: res.data.data.hospital_code, //医院标识
            office_name: res.data.data.office_name, //科室名称
            office_code: res.data.data.office_code, //医院编码
            total_fee: res.data.data.regfee, //费用
            detail: "预约挂号",
            name: "预约挂号",
          };
          this.$admin.http
            .post("/expense-management/order_management/", order)
            .then((item) => {
              if (item.data.code == 0) {
                item.data.data.work_date = this.work_date
                item.data.data.name_t = this.detail.name
                Utils.$emit('demo',{type: '加号',content: item.data.data})
                this.$message({
                  message: item.data.msg,
                  type: "success",
                });
              }
              this.close();
            });
        });
    },
    // 查询患者
    query_info() {
      this.$admin.http
        .get(
          `patient-account/online_therapy/?appoint_type=4&status=3&doctor_id=${this.user.doctor}`
        )
        .then((res) => {
          res.data.results.forEach((item) => {
            if (item.id == this.$route.query.id) {
              this.params = item;
            }
          });
        });
    },
    // 获取排班信息
    getClass() {
      this.$admin.http.get(`base-system/hospitals/`).then((data) => {
        this.hospital_data = data.data.results;
      });
    },
  },
  watch: {
    work_date: {
      deep: true,
      immediate: true,
      handler(val) {
        console.log(val);
        if (val) {
          this.hospital = null;
          this.office = null;
          this.doctor = null;
          this.position = null;
          this.detail = null;
          this.cost = null;
        }
      },
    },
    hospital: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val !== null && this.work_date) {
          this.office = null;
          this.doctor = null;
          this.time_data = null;
          this.position = null;
          this.detail = null;
          this.cost = null;
          if (this.params) {
            console.log(this.params);
            this.$store
              .dispatch("bs_hospital/getOne", {
                id: val,
              })
              .then((res) => {
                this.getjzk(this.params.ehc_id, res.data.codenum);
              });
          }
          this.$admin.http
            .get(
              `work-scheduling/apn/offices/?hospital=${val}&work_date=${moment(
                this.work_date
              ).format("YYYY-MM-DD")}`
            )
            .then((data) => {
              this.office_data = data.data.results;
            });
        } else {
          this.office_data = null;
        }
      },
    },
    office: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val !== null && this.work_date && this.hospital) {
          this.doctor = null;
          this.time_data = null;
          this.position = null;
          this.detail = null;
          this.cost = null;
          this.$admin.http
            .get(
              `work-scheduling/apn/doctors/?hospital=${
                this.hospital
              }&office=${val}&work_date=${moment(this.work_date).format(
                "YYYY-MM-DD"
              )}&fee_type=1&pb_type=1`
            )
            .then((data) => {
              this.doctor_data = data.data.results;
            });
        } else {
          this.doctor_data = null;
        }
      },
    },
    doctor: {
      deep: true,
      immediate: true,
      handler(val) {
        this.time_data = null;
        this.position = null;
        this.detail = null;
        this.cost = null;
        if (val !== null) {
          this.doctor_data.forEach((item) => {
            if (item.id == val) {
              this.cost = item.doctor_fee;
              this.position = item.position_name;
            }
          });
          this.$admin.http
            .get(
              `work-scheduling/apn/?doctor=${val}&work_date=${moment(
                this.work_date
              ).format("YYYY-MM-DD")}&offie_code=${this.office}&pb_type=1`
            )
            .then((data) => {
              this.detail = data.data.results;
            });
        }
      },
    },
  },
};
</script>

<style scoped>
.text-center {
  width: 87%;
  background-color: #fff;
  height: 80px;
  position: fixed;
  bottom: 0px;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
ul li::marker {
  color: #42b6c4;
}
.row + .row {
  margin-top: 0px;
}
.row > .col {
  padding: 0 12px !important;
}
.el-select {
  display: block;
}
.el-button--primary {
  background-color: #42b6c4;
  border-color: #42b6c4;
}
</style>