import CryptoJS from 'crypto-js';
// import global from '../global/global';
// import { DES3_KEY } from '@/production';

// const KEY = this.global.key; // 此key需与后端一致

function enDES3(str, key) {
  const encrypt = CryptoJS.TripleDES.encrypt(str, CryptoJS.enc.Utf8.parse(key), {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypt.toString();
}

function deDES3(str, key = KEY) {
  const decrypt = CryptoJS.TripleDES.decrypt(str, CryptoJS.enc.Utf8.parse(key), {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  return decrypt.toString(CryptoJS.enc.Utf8);
}

export { enDES3, deDES3 };