<template>
  <div>检验详情</div>
</template>
  
  <script>
export default {
  data() {
    return {};
  },
  mounted() {
    // this.$admin.http.get(
    //   `/online-recheck/checkupitems/`
    // ).then((res)=> {
    //     console.log(res.data);
    // })
    // this.$admin.http.get(
    //   `/labreport_detail/?code_or=${}&hospital_code=${}`
    // ).then((res)=> {
    //     console.log(res.data);
    // })
  },
};
</script>
  
  <style>
</style>