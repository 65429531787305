<template>
    <!-- <va-edit-layout> -->
      <mt-template-main-form :id="id" :title="title" :item="item"></mt-template-main-form>
    <!-- </va-edit-layout> -->
  </template>
  
  <script>
  export default {
    props: ["id", "title", "item"],
  };
  </script>
  