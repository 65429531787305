<template>
  <va-form :id="id" :item="item" @saved="$emit('saved')" ref="form">
    <v-card>
      <v-card-text style="width: 97%; margin: 0 auto">
        <v-row>
          <ul style="padding: 0.5vw">
            <li>新建{{ title }}</li>
            <li style="list-style: none; font-size: 10px; color: #999">
              OUTPATIENT REGISTRATION
            </li>
          </ul>
        </v-row>
        <v-row style="margin: 15px 0">
          <v-col>
            <el-input
              v-model="patient_name"
              placeholder="请输入患者姓名"
            ></el-input>
          </v-col>
          <v-col>
            <el-input v-model="id_num" placeholder="请输入身份证号"></el-input>
          </v-col>
          <v-col>
            <el-input v-model="gender" placeholder="请选择性别"></el-input>
          </v-col>
          <v-col>
            <el-input v-model="age" placeholder="请输入患者年龄"></el-input>
          </v-col>
        </v-row>
        <v-row style="margin: 15px 0">
          <v-col>
            <el-date-picker
              v-model="dateOfBirth"
              placeholder="请选择出生日期"
            ></el-date-picker>
          </v-col>
          <v-col>
            <el-input v-model="pat_type" placeholder="病员类型"></el-input>
          </v-col>
          <v-col>
            <el-input
              v-model="career"
              placeholder="请输入职业"
              v-if="title == '普通挂号'"
            ></el-input>
          </v-col>
          <v-col>
            <el-input
              v-model="phone"
              placeholder="请输入联系电话"
              v-if="title == '普通挂号'"
            ></el-input>
          </v-col>
        </v-row>
        <v-row style="margin: 15px 0" v-if="title == '普通挂号'">
          <v-col>
            <el-input v-model="contacts" placeholder="请输入联系人"></el-input>
          </v-col>
          <v-col>
            <el-input
              v-model="contact_relationship"
              placeholder="请输入联系人关系"
            ></el-input>
          </v-col>
          <v-col cols="6">
            <el-input v-model="address" placeholder="请输入联系地址"></el-input>
          </v-col>
        </v-row>
        <template v-if="title == '普通挂号'">
          <v-row style="margin: 15px 0">
            <v-col>
              <el-date-picker
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                v-model="work_date"
                type="date"
                placeholder="选择预约日期"
                style="width: 100%"
                clearable
              >
              </el-date-picker>
            </v-col>
            <v-col>
              <el-select
                v-model="hospital"
                filterable
                placeholder="请选择医院"
                clearable
              >
                <el-option
                  v-for="item in hospital_data"
                  :key="item.id"
                  :label="item.hospital_name"
                  :value="item.hospital_code"
                >
                </el-option>
              </el-select>
            </v-col>
            <v-col>
              <el-select
                v-model="office"
                filterable
                placeholder="请选择科室"
                clearable
                :disabled="hospital && work_date ? false : true"
              >
                <el-option
                  v-for="item in office_data"
                  :key="item.id"
                  :label="item.office_name"
                  :value="item.office_code"
                >
                </el-option>
              </el-select>
            </v-col>
            <v-col>
              <el-select
                clearable
                v-model="doctor"
                filterable
                placeholder="请选择医生"
                :disabled="hospital && work_date && office ? false : true"
              >
                <el-option
                  v-for="item in doctor_data"
                  :key="item.id"
                  :label="`${item.doctor_name}`"
                  :value="item.doctor_code"
                >
                </el-option>
              </el-select>
            </v-col>
          </v-row>
          <v-row style="margin: 15px 0">
            <v-col>
              <el-input
                v-model="noontype_code"
                placeholder="请选择时段"
                disabled
              ></el-input>
            </v-col>
            <v-col>
              <el-input
                v-model="cost"
                placeholder="请填写挂号费用"
                disabled
              ></el-input>
            </v-col>
            <v-col></v-col>
            <v-col></v-col> </v-row
        ></template>
        <template v-else>
          <v-row style="margin: 15px 0">
            <v-col>
              <el-select
                v-model="hospital"
                filterable
                placeholder="请选择医院"
                clearable
              >
                <el-option
                  v-for="item in hospital_data"
                  :key="item.id"
                  :label="item.name"
                  :value="item.codenum"
                >
                </el-option>
              </el-select>
            </v-col>
            <v-col>
              <el-select
                v-model="office"
                filterable
                placeholder="请选择科室"
                clearable
                :disabled="hospital && work_date ? false : true"
              >
                <el-option
                  v-for="item in office_data"
                  :key="item.id"
                  :label="item.name"
                  :value="item.codenum"
                >
                </el-option>
              </el-select>
            </v-col>
            <v-col>
              <el-input
                v-model="room"
                placeholder="请选择诊室"
                disabled
              ></el-input>
            </v-col>
            <v-col>
              <el-input
                v-model="cost"
                placeholder="请填写挂号费用"
                disabled
              ></el-input>
            </v-col>
          </v-row>
        </template>
      </v-card-text>
    </v-card>
    <el-table
      v-if="title == '普通挂号'"
      :data="detail"
      height="300"
      style="width: 100%; margin-bottom: 80px"
      @current-change="handleCurrentChange"
    >
      <el-table-column label="医院名称" prop="hospital_name" align="center">
      </el-table-column>
      <el-table-column label="科室名称" prop="office_name" align="center">
      </el-table-column>
      <el-table-column label="医生姓名" prop="doctor_name" align="center">
      </el-table-column>
      <el-table-column label="诊室名称" prop="num_count" align="center">
      </el-table-column>
      <el-table-column label="限号" prop="num_count" align="center">
      </el-table-column>
      <el-table-column label="余号" prop="left_num" align="center">
      </el-table-column>
      <el-table-column label="时段" prop="noontype_code" align="center">
        <template slot-scope="scope">
          <template v-if="scope.row.noontype_code == 'am'">上午</template>
          <template v-else-if="scope.row.noontype_code == 'pm'">下午</template>
          <template v-else>晚上</template>
        </template>
      </el-table-column>
    </el-table>
    <el-table
      v-else
      :data="detail1"
      height="300"
      style="width: 100%; margin-bottom: 80px"
      @current-change="handleCurrentChange"
    >
      <el-table-column label="医院名称" prop="hospital_name" align="center">
      </el-table-column>
      <el-table-column label="科室名称" prop="office_name" align="center">
      </el-table-column>
      <el-table-column label="诊室名称" prop="name" align="center">
      </el-table-column>
      <el-table-column label="诊室费用" prop="fee" align="center">
      </el-table-column>
    </el-table>

    <el-dialog
      title="交费详情"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form :model="form">
        <v-row style="margin: 0px 0 15px">
          <v-col>
            <el-input
              v-model="form.name"
              placeholder="请填写挂号费用"
            ></el-input>
          </v-col>
          <v-col>
            <el-input
              v-model="form.name"
              placeholder="请填写账户余额"
            ></el-input>
          </v-col>
        </v-row>
        <hr />
        <v-row style="margin: 15px 0">
          <v-col>
            <el-select v-model="form.region" placeholder="请选择病员费别">
              <el-option
                v-for="(item, index) in fee"
                :key="index"
                :label="fee.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </v-col>
          <v-col>
            <el-input
              v-model="form.name"
              placeholder="请填写现金支付金额"
            ></el-input>
          </v-col>
          <v-col>
            <el-input
              v-model="form.name"
              placeholder="请填写医保支付金额"
            ></el-input>
          </v-col>
        </v-row>
        <v-row style="margin: 15px 0">
          <v-col>
            <el-input
              v-model="form.name"
              placeholder="请填写健康卡支付金额"
            ></el-input>
          </v-col>
          <v-col>
            <el-input
              v-model="form.name"
              placeholder="请填写银联支付金额"
            ></el-input>
          </v-col>
          <v-col>
            <el-select v-model="form.region" placeholder="请选择移动支付">
              <el-option label="微信" value="1"></el-option>
              <el-option label="支付宝" value="2"></el-option>
            </el-select>
          </v-col>
        </v-row>
        <hr />
        <v-row style="margin: 15px 0">
          <v-col>
            <el-input
              v-model="form.name"
              placeholder="请填写应收金额"
            ></el-input>
          </v-col>
          <v-col>
            <el-input
              v-model="form.name"
              placeholder="请填写实收金额"
            ></el-input>
          </v-col>
          <v-col>
            <el-input
              v-model="form.name"
              placeholder="请填写找零金额"
            ></el-input>
          </v-col>
        </v-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-checkbox v-model="checked" style="margin-right: 50px"
          >打印发票</el-checkbox
        >
        <el-button @click="dialogFormVisible = false">退出支付</el-button>
        <el-button type="primary" @click="payment">确认支付</el-button>
      </div>
    </el-dialog>

    <div class="text-center">
      <v-btn @click="close" style="color: #42b6c4; margin-right: 50px"
        >取消</v-btn
      >
      <v-btn
        @click="settlement"
        style="background-color: #42b6c4; margin-right: 50px"
        >结算</v-btn
      >
      <v-btn
        @click="isSure"
        style="background-color: #42b6c4; margin-right: 50px"
        >确认</v-btn
      >
    </div>
  </va-form>
</template>

<script>
import moment from "moment";
export default {
  props: ["data", "id", "item"],
  data() {
    return {
      dialogFormVisible: false,
      input: null,
      work_date: null,
      title: null,
      form: {},
      doctor: null,
      office: null,
      hospital: null,
      doctor_name: null,
      office_name: null,
      hospital_name: null,
      hospital_data: null,
      office_data: null,
      doctor_data: null,
      noontype_code: null,
      detail: null,
      detail1: null,
      cost: null,
      checked: true,
      start_time: null,
      end_time: null,
      patient_name: null,
      id_num: null,
      age: null,
      dateOfBirth: null,
      gender: null,
      fee: null,
      contact_relationship: null,
      address: null,
      contacts: null,
      phone: null,
      career: null,
      reg_type: null,
      pat_type: null,
      room: null,
      room_code: null,
      room_id: null,
    };
  },
  methods: {
    handleCurrentChange(val) {
      if (this.title == "普通挂号") {
        if (val.noontype_code == "am") {
          this.noontype_code = "上午";
        } else if (val.noontype_code == "pm") {
          this.noontype_code = "下午";
        } else {
          this.noontype_code = "晚上";
        }
        // this.noontype_code = val.noontype_code
        this.start_time = val.start_time;
        this.end_time = val.end_time;
      } else {
        if (val) {
          this.room = val.name;
          this.room_code = val.code;
          this.room_id = val.id;
          this.cost = val.fee;
          this.office = val.office_code;
        }
      }

      // this.dw_id = val.id;
    },
    // 取消
    close() {
      this.$router.push("/os-outpatient-registration/");
    },
    isSure() {
      if (this.title == "普通挂号") {
        this.common();
      } else {
        this.emergency();
      }
    },
    emergency() {
      console.log(this.work_date);
      let patient_data = {
        patient_name: this.patient_name,
        id_num: this.id_num,
        age: this.age,
        dateOfBirth: this.dateOfBirth,
        gender: this.gender,
        pat_type: this.pat_type,
      };
      if (
        !this.office_name ||
        !this.hospital_name ||
        !this.id_num ||
        !this.work_date ||
        !this.cost ||
        !this.patient_name
      ) {
        this.$message({
          message: "有必填项未填写",
          type: "warning",
        });
        return;
      }
      let data = {
        office_code: this.office,
        office_name: this.office_name,
        hospital_code: this.hospital,
        hospital_name: this.hospital_name,
        created_by: JSON.parse(window.sessionStorage.getItem("user")).id,
        updated_by: JSON.parse(window.sessionStorage.getItem("user")).id,
        reg_type: this.reg_type,
        patient_code: this.id_num,
        id_num: this.id_num,
        patient_name: this.patient_name,
        visit_date: this.work_date,
        start_time: this.start_time,
        end_time: this.end_time,
        fee: this.cost,
        emergency_room: this.room_id,
      };
      this.$admin.http
        .post(`hospitalization_apt/get_patcode/`, patient_data)
        .then((res) => {
          data.patient_code = res;
        });
      if (!this.id) {
        this.$admin.http
          .post(`outpatient-scheduling/registrations/`, data)
          .then((res) => {
            this.$message({
              message: "预约成功！",
              type: "success",
            });
          });
      }
      this.close();
    },
    common() {
      let patient_data = {
        patient_name: this.patient_name,
        id_num: this.id_num,
        age: this.age,
        dateOfBirth: this.dateOfBirth,
        gender: this.gender,
        contact_relationship: this.contact_relationship,
        address: this.address,
        contacts: this.contacts,
        phone: this.phone,
        career: this.career,
        pat_type: this.pat_type,
      };
      if (
        !this.office_name ||
        !this.hospital_name ||
        !this.id_num ||
        !this.reg_type ||
        !this.doctor_name ||
        !this.work_date ||
        !this.cost ||
        !this.noontype_code ||
        !this.patient_name
      ) {
        this.$message({
          message: "有必填项未填写",
          type: "warning",
        });
        return;
      }
      let data = {
        office_code: this.office,
        office_name: this.office_name,
        hospital_code: this.hospital,
        hospital_name: this.hospital_name,
        created_by: JSON.parse(window.sessionStorage.getItem("user")).id,
        updated_by: JSON.parse(window.sessionStorage.getItem("user")).id,
        reg_type: this.reg_type,
        patient_code: this.id_num,
        id_num: this.id_num,
        patient_name: this.patient_name,
        doctor_code: this.doctor,
        doctor_name: this.doctor_name,
        visit_date: this.work_date,
        start_time: this.start_time,
        end_time: this.end_time,
        fee: this.cost,
        // emergency_room: this.room_id,
      };
      if (this.noontype_code == "上午") {
        data.noontype_code = "am";
      } else if (this.noontype_code == "下午") {
        data.noontype_code = "pm";
      } else {
        data.noontype_code = "night";
      }
      this.$admin.http
        .post(`hospitalization_apt/get_patcode/`, patient_data)
        .then((res) => {
          data.patient_code = res;
        });
      if (!this.id) {
        this.$admin.http
          .post(`outpatient-scheduling/registrations/`, data)
          .then((res) => {
            this.$message({
              message: "预约成功！",
              type: "success",
            });
          });
      }
      this.close();
    },
    settlement() {
      this.dialogFormVisible = true;
    },
    payment() {
      this.$prompt("请输入支付密码", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^[1-9]\d{5}$/,
        inputType: "password",
        inputErrorMessage: "支付密码格式不正确",
        closeOnClickModal: false,
        closeOnPressEscape: false,
        closeOnHashChange: false,
      })
        .then(({ value }) => {
          this.$message({
            type: "success",
            message: "你的密码是: " + value,
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },
  },
  mounted() {
    this.work_date = moment().format("YYYY-MM-DD");
    this.title = this.$route.query.data;
    this.reg_type = this.$route.query.type;
  },
  created() {
    this.user = JSON.parse(window.sessionStorage.getItem("user"));
    if (this.$route.query.data == "急诊挂号") {
      this.$store.dispatch("bs_hospital/getList").then((res) => {
        this.hospital_data = res.data;
      });
    }
    // this.getClass();
  },
  watch: {
    id_num: {
      deep: true,
      immediate: true,
      handler(val) {
        const reg =
          /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
        if (reg.test(val)) {
          var org_birthday = val.substring(6, 14);
          var org_gender = val.substring(16, 17);
          var sex = org_gender % 2 == 1 ? "男" : "女";
          var birthday =
            org_birthday.substring(0, 4) +
            "-" +
            org_birthday.substring(4, 6) +
            "-" +
            org_birthday.substring(6, 8);
          var birthdays = new Date(birthday.replace(/-/g, "/"));
          let d = new Date();
          let age =
            d.getFullYear() -
            birthdays.getFullYear() -
            (d.getMonth() < birthdays.getMonth() ||
            (d.getMonth() == birthdays.getMonth() &&
              d.getDate() < birthdays.getDate())
              ? 1
              : 0);
          this.gender = sex;
          this.dateOfBirth = birthdays;
          this.age = age;
        } else {
          this.form.sex = "未填写";
          return false;
        }
      },
    },
    work_date: {
      deep: true,
      immediate: true,
      handler(val) {
        if (this.title == "普通挂号") {
          if (val) {
            this.hospital = null;
            this.office = null;
            this.doctor = null;
            this.position = null;
            this.detail = null;
            this.cost = null;
            this.$admin.http
              .get(
                `outpatient-scheduling/workschedetails/?work_date=${moment(
                  this.work_date
                ).format("YYYY-MM-DD")}`
              )
              .then((data) => {
                let obj = {};
                this.hospital_data = data.data.results.reduce((item, next) => {
                  obj[next.hospital_code]
                    ? ""
                    : (obj[next.hospital_code] = true && item.push(next));
                  return item;
                }, []);
              });
          }
        }
      },
    },
    hospital: {
      deep: true,
      immediate: true,
      handler(val) {
        if (this.title == "普通挂号") {
          if (val !== null && this.work_date) {
            this.office = null;
            this.doctor = null;
            this.noontype_code = null;
            this.position = null;
            this.detail = null;
            this.cost = null;
            this.$admin.http
              .get(
                `outpatient-scheduling/workschedetails/?hospital_code=${val}&work_date=${moment(
                  this.work_date
                ).format("YYYY-MM-DD")}`
              )
              .then((data) => {
                let obj = {};
                this.hospital_name = data.data.results[0].hospital_name;
                this.office_data = data.data.results.reduce((item, next) => {
                  obj[next.office_code]
                    ? ""
                    : (obj[next.office_code] = true && item.push(next));
                  return item;
                }, []);
              });
          } else {
            this.office_data = null;
          }
        } else {
          this.$store
            .dispatch("bs_hospital/getList", {
              filter: {
                codenum: val,
              },
            })
            .then((res) => {
              this.hospital_name = res.data[0].name;
              this.$store
                .dispatch("bs_office/getList", {
                  filter: {
                    hospital_id: res.data[0].id,
                  },
                })
                .then((data) => {
                  this.office_data = data.data;
                });
            });
          this.$store
            .dispatch("er_management_main/getList", {
              filter: {
                hospital_code: val,
              },
            })
            .then((data) => {
              this.detail1 = data.data;
            });
        }
      },
    },
    office: {
      deep: true,
      immediate: true,
      handler(val) {
        if (this.title == "普通挂号") {
          if (val !== null && this.work_date && this.hospital) {
            this.doctor = null;
            this.noontype_code = null;
            this.position = null;
            this.detail = null;
            this.cost = null;
            this.$admin.http
              .get(
                `outpatient-scheduling/workschedetails/?office_code=${val}&work_date=${moment(
                  this.work_date
                ).format("YYYY-MM-DD")}`
              )
              .then((data) => {
                this.office_name = data.data.results[0].office_name;
                let obj = {};
                this.doctor_data = data.data.results.reduce((item, next) => {
                  obj[next.doctor_code]
                    ? ""
                    : (obj[next.doctor_code] = true && item.push(next));
                  return item;
                }, []);
              });
          } else {
            this.doctor_data = null;
          }
        } else {
          this.$store
            .dispatch("bs_office/getList", {
              filter: {
                codenum: val,
              },
            })
            .then((res) => {
              this.office_name = res.data[0].name;
              this.$store
                .dispatch("bs_doctor/getList", {
                  filter: {
                    office_id: res.data[0].id,
                  },
                })
                .then((data) => {
                  this.doctor_data = data.data;
                });
            });
          this.$store
            .dispatch("er_management_main/getList", {
              filter: {
                office_code: val,
              },
            })
            .then((data) => {
              console.log(data);
              this.detail1 = data.data;
            });
        }
      },
    },
    doctor: {
      deep: true,
      immediate: true,
      handler(val) {
        if (this.title == "普通挂号") {
          this.noontype_code = null;
          this.position = null;
          this.detail = null;
          this.cost = null;
          if (val !== null) {
            this.$admin.http
              .get(
                `outpatient-scheduling/workschedetails/?doctor_code=${val}&work_date=${moment(
                  this.work_date
                ).format("YYYY-MM-DD")}`
              )
              .then((data) => {
                this.doctor_name = data.data.results[0].doctor_name;
                this.detail = [];
                data.data.results.forEach((item) => {
                  if (item.left_num !== 0) {
                    this.detail.push(item);
                  }
                });
                // this.detail = data.data.results;
              });
            console.log(val);
            this.$store
              .dispatch("bs_doctor/getList", {
                filter: {
                  codenum: val,
                },
              })
              .then((res) => {
                debugger;
                console.log(res.data);
                this.$store
                  .dispatch("bs_expense_standard_doctors/getList", {
                    filter: {
                      doctor_id: res.data.id,
                    },
                  })
                  .then((data) => {
                    debugger;
                    console.log(data.data);
                    this.$store
                      .dispatch("bs_expense_standard/getOne", {
                        id: data.data[data.data.length - 1].expensestandard_id,
                      })
                      .then((item) => {
                        this.cost = item.data.fees;
                      });
                  });
              });
          }
        } else {
          this.$store
            .dispatch("bs_doctor/getList", {
              filter: {
                codenum: val,
              },
            })
            .then((res) => {
              this.doctor_name = res.data[0].name;
            });
        }
      },
    },
  },
};
</script>
<style scoped>
.text-center {
  width: 87%;
  background-color: #fff;
  height: 80px;
  position: fixed;
  bottom: 0px;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
ul li::marker {
  color: #42b6c4;
}
.row + .row {
  margin-top: 0px;
}
.row > .col {
  padding: 0 12px !important;
}
.el-select {
  display: block;
}
.el-button--primary {
  background-color: #42b6c4;
  border-color: #42b6c4;
}
.el-date-editor.el-input {
  width: 100%;
}
</style>