
<template>
  <div>
    <va-aside-layout :title="asideTitle">
      <bs-expense-standard-tel-show
        v-if="show"
        :item="item"
      ></bs-expense-standard-tel-show>
      <bs-expense-standard-tel-form
        v-else
        :id="id"
        width="500px"
        :item="item"
        @saved="onSaved"
      ></bs-expense-standard-tel-form>
    </va-aside-layout>
    <v-card :icon="resource.icon" :title="title">
      <va-list
        ref="list"
        :filters="filters"
        :filter="filter"
        resource="bs_expense_standard"
        disableExport
        disable-global-search
        disable-create-redirect
        @action="onAction"
        :include="['bs_hospital']"
        :sort-by="['id']"
        :sort-desc="[true]"
      >
        <va-data-table
          :fields="fields"
          disable-show-redirect
          disable-clone
          disable-edit-redirect
          @item-action="onAction"
        >
        </va-data-table>
      </va-list>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["resource", "title"],
  data() {
    return {
      filters: [
        {
          source: "standard_name",
          type: "text",
          alwaysOn: true,
          // attributes: { reference: "auth_system_user.id" },
        },
        // {
        //   source: "hospital_id",
        //   label: "所属医院",
        //   type: "select",
        //   alwaysOn: true,
        //   attributes: { reference: "bs_hospital" },
        //   // attributes: { reference: "auth_system_user.id" },
        // },
      ],
      fields: [
        { source: "standard_code", sortable: true },
        "standard_name",
        { source: "bs_hospital.name", label: "所属医院", sortable: true },
        "fees",
        // 'expense_type',
        {
          source: "created_time",
          sortable: true,
          type: "date",
          attributes: { format: "longTime" },
        },
        "created_by",
      ],
      asideTitle: null,
      id: null,
      item: null,
      show: false,
      filter: {
        expense_type: "2",
      },
    };
  },
  created() {
    this.getSupper();
  },
  mounted() {},
  watch: {},
  methods: {
    getSupper() {
      let user_data = JSON.parse(window.sessionStorage.getItem("user"));
      if (user_data.is_superuser) {
        this.filters.push({
          source: "hospital_id",
          label: "所属医院",
          type: "select",
          alwaysOn: true,
          attributes: { reference: "bs_hospital" },
          // attributes: { reference: "auth_system_user.id" },
        });
      } else {
        this.filter.hospital_id = user_data.hospital;
      }
    },
    async onAction({ action, title, id, item }) {
      // this.asideTitle = title;
      this.id = id;
      this.show = action === "show";
      this.item = item;
      //如果点击的是新增,请求新增的接口
      if (action === "create") {
        this.id = null;
        this.asideTitle = "新建电话问诊配置";
      } else if (action === "edit") {
        this.id = item.id;
        this.asideTitle = "修改电话问诊配置";
      } else {
        this.asideTitle = "查看电话问诊配置";
      }
    },
    onSaved() {
      this.asideTitle = null;
      this.$refs.list.fetchData();
    },
  },
};
</script>

