<template>
  <os-registration-records-list
    :resource="resource"
    :type="1"
  ></os-registration-records-list>
</template>

<script>
export default {
  props: ["resource"],
  data() {
    return {
      title: "在线问诊记录",
    };
  },
};
</script>