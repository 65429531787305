<template>
  <base-material-card :icon="resource.icon" :title="title">
    <va-list
      :filters="filters"
      disable-create
      disable-export
      :sort-by="['id']"
      :sort-desc="[true]"
      global-search-query="remote_addr@_like,path_info@_like,req_method@_like,status_code@_like"
    >
      <va-data-table
        :fields="fields"
        disable-clone
        disable-create
        disable-edit
        disable-delete
      >
      </va-data-table>
    </va-list>
  </base-material-card>
</template>

<script>
export default {
  props: ["resource", "title"],
  data() {
    return {
      filters: [],
      fields: [
        "id",
        "request_no",
        "remote_addr",
        "path_info",
        // "full_path",
        "req_method",
        // "req_params",
        "content_type",
        "operation_content",
        "status_code",
        "content_length",
        "result",
        // "return_message",
        // "start_timestamp",
        // "end_timestamp",
        "time_use_ms",
        // { source: "created_time", type: "date" },
        // { source: "updated_time", type: "date" },
      ],
    };
  },
};
</script>
