<template>
  <base-material-card :icon="resource.icon" :title="title">
    <va-list
      :filters="filters"
    >
      <va-data-table :fields="fields">
      </va-data-table>
    </va-list>
  </base-material-card>
</template>

<script>
export default {
  props: ["resource", "title"],
  data() {
    return {
      filters: [
  'template_time_line_name\t时间线名称',
  'template_time_line_code\t时间线编码',
  'template_code\t模板编码',
  'template_name\t模板名称',
  'office_code\t科室编码',
  'office_name\t科室名称',
  'group_name 所属服务组名称',
  'group_id 所属服务组ID'
],
      fields: [
  { source: 'template_time_line_name\t时间线名称', sortable: true },
  { source: 'template_time_line_code\t时间线编码', sortable: true },
  { source: 'template_code\t模板编码', sortable: true },
  { source: 'template_name\t模板名称', sortable: true },
  { source: 'office_code\t科室编码', sortable: true },
  { source: 'office_name\t科室名称', sortable: true },
  { source: 'group_name 所属服务组名称', sortable: true },
  { source: 'group_id 所属服务组ID', sortable: true },
  'start_time 起始时间',
  'end_time 结束时间',
  'resource_no_start 起始号',
  'resource_no_end 尾号',
  'resource_count 号源总数',
  'back_up_time_zone 备用时段',
  'resource_quota 住院限额',
  'appointment_item_way   可做项目添加规则',
  'pre_apply_days   可预约天数',
  'create_time\t创建时间',
  'remark\t备注'
],
    };
  },
};
</script>
