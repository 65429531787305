<template>
  <div>
    <va-list
      ref="list"
      disable-create
      disable-global-search
      disable-export
      disable-pagination
      :sort-desc="[true]"
      :include="['mt_template_detail']"
    >
      <template v-slot:actions>
        <va-action-button
          icon="mdi-plus"
          color="success"
          @click="createdrawer"
          style="color: #4caf50"
          text
          label="新建"
        ></va-action-button>
      </template>
      <v-card v-for="(items, index) in table" :key="index">
        <div
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <h3 style="height: 60px; margin: 20px; padding-top: 15px">
            模板名称：{{ items.temp_name }}
          </h3>
          <div>
            <va-action-button
              icon="mdi-pencil"
              color="blue"
              @click="editData(items.id)"
              text
              label="修改"
            ></va-action-button>
            <va-action-button
              icon="mdi-trash-can"
              color="red"
              @click="deleteData(items.id)"
              text
              label="删除"
            ></va-action-button>
          </div>
        </div>

        <el-table
          :header-cell-style="{
            background: '#FFFFFF',
            textAlign: 'center',
          }"
          style="width: 100%; margin: 20px auto"
          height="300"
          :data="tableData"
          is-horizontal
        >
          <el-table-column
            v-for="(item, inn) in tableData[index]"
            :key="inn"
            :label="label_data(String(item.what_day))"
          >
            <el-table-column
              v-for="(items, idd) in item.data"
              :key="idd"
              :label="label_value(items.noontype_code)"
            >
              <el-table-column :label="String(items.num_count)">
                <el-table-column :label="items.start_time">
                  <el-table-column :label="items.end_time">
                    <el-table-column>
                      <template slot="header">
                        <template v-if="items.is_dayoff">休息</template>
                        <template v-else>不休息</template>
                      </template>
                    </el-table-column>
                  </el-table-column></el-table-column
                >
              </el-table-column>
            </el-table-column>
          </el-table-column>
        </el-table>
      </v-card>
    </va-list>
  </div>
</template>

<script>
import moment from "moment";
import { table } from "console";
export default {
  props: ["id"],
  data() {
    return {
      table: null,
      data_value: [],
      tableData: [],
      data_list: {},
      timeMap: new Map([
        ["1", "星期一"],
        ["2", "星期二"],
        ["3", "星期三"],
        ["4", "星期四"],
        ["5", "星期五"],
        ["6", "星期六"],
        ["7", "星期日"],
      ]),
      valueMap: new Map([
        ["1am", "上午"],
        ["2pm", "下午"],
        ["3night", "晚上"],
      ]),
    };
  },
  created() {
    this.getdatas();
  },
  methods: {
    editData(id) {
      this.$router.push({
        path: `/mt-template-main/${id}/edit`,
      });
    },
    deleteData(id) {
      this.$confirm("此操作将删除该数据，是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$admin.http
            .delete(`/medical-technology/templates/${id}/`)
            .then((res) => {
              this.getdatas();
              this.$message({
                message: "删除成功",
                type: "success",
              });
            });
        })
        .catch(() => {});
    },
    createdrawer() {
      this.$router.push({
        path: "/mt-template-main/create",
      });
    },
    label_data(t) {
      return this.timeMap.get(t);
    },
    label_value(v) {
      return this.valueMap.get(v);
    },
    getdatas() {
      this.$admin.http.get("medical-technology/templates/").then((res) => {
        this.table = res.data.results;
        this.change_data();
      });
    },
    change_data() {
      this.table.forEach((item, index) => {
        this.tableData.push([]);
        let arr = item.template_details;
        var map = {};
        for (var i = 0; i < arr.length; i++) {
          var ai = arr[i];
          if (!map[ai.what_day]) {
            this.tableData[index].push({
              what_day: ai.what_day,
              data: [ai],
            });
            map[ai.what_day] = ai;
          } else {
            for (var j = 0; j < this.tableData[index].length; j++) {
              var dj = this.tableData[index][j];
              if (dj.what_day == ai.what_day) {
                dj.data.push(ai);
                break;
              }
            }
          }
        }
      });
    },
  },
};
</script>
<style scoped>
.input {
  outline: none;
}
.first_header {
  display: flex;
  width: 100%;
  margin: 0 auto;
  border: 0.5px solid #f8f8f8;
}

.first_header_cell {
  padding: 5px 10px;
  width: 13%;
  height: 40px;
  line-height: 30px;
  text-align: center;
  border: 0.5px solid #f8f8f8;
}

.sec_header_cell {
  display: flex;
  width: 13%;
  height: 40px;
  text-align: center;
}

.sec_header_title {
  width: 33.33%;
  line-height: 38px;
  border: 1px solid #f8f8f8;
}

.sec_header {
  margin-top: -2px;
}

.el-input--suffix .el-input__inner {
  padding-right: 0 !important;
}

.el-icon-circle-close,
.el-input__prefix {
  display: none !important;
}

.btn_bg {
  width: 87%;
  position: fixed;
  bottom: 0px;
  padding: 10px 0;
  text-align: center;
  background-color: white;
  z-index: 99;
}
.el-input--suffix .el-input__inner {
  padding-left: 12% !important;
}
.el-date-editor /deep/ .el-input__prefix {
  display: none !important;
}
.el-input--prefix /deep/ .el-input__inner {
  text-align: center;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
</style>
