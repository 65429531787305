/*
 * @Description: 
 * @Author: your name
 * @Date: 2023-04-27 14:42:33
 * @LastEditors: your name
 * @LastEditTime: 2023-05-10 16:52:03
 */
import Vue from "vue";
import VuetifyAdmin from "vuetify-admin";

import "vuetify-admin/src/loader";

import buildHasuraProvider from "va-data-hasura";
import { laravelDataProvider } from "vuetify-admin/src/providers";
import djJwtAuthProvider from "@/providers/auth/dj_rest_auth";
import { zh } from "@/locales";
import { en } from "vuetify-admin/src/locales";

import router from "@/router";
import routes from "@/router/admin";
import store from "@/store";
import i18n from "@/i18n";
import resources from "@/resources";
import axios from "axios";
import trimEnd from "lodash/trimEnd";
import createHttpClient from "@/providers/http_client";
import apolloClient from "@/providers/apollo_client";

/**
 * Load Admin UI components
 */
Vue.use(VuetifyAdmin);

/**
 * Axios instance
 */
const baseURL = process.env.VUE_APP_API_URL+"back-stage/" || "http://localhost:18000/";
const graphqlURL = process.env.VUE_APP_GRAPHQL_URL+"back-stage/";

const http = createHttpClient({
  baseURL,
  // withCredentials: true,
  headers: { "X-Requested-With": "XMLHttpRequest" },
});

const buildAdmin = async () => {
  const dataProvider = await buildHasuraProvider({
    client: apolloClient,
  });
  const hasuraDataProvider = dataProvider
  let vuetifyAdmin = new VuetifyAdmin({
    router,
    store,
    i18n,
    title: "统一预约平台",
    routes,
    locales: { zh },
    // translations: ["zh"],
    authProvider: djJwtAuthProvider(http),
    dataProvider: hasuraDataProvider,
    resources,
    http,
    options: {
      dateFormat: "short",
      tinyMCE: {
        language: navigator.language.replace("-", "_"),
        imageUploadUrl: "/cons/upload_img/",
        fileBrowserUrl: `${trimEnd(baseURL, "/")}/elfinder/tinymce5`,
        
      },
    },
  });
  return vuetifyAdmin
};
export default buildAdmin