<template>
  <va-create-layout>
    <ig-device-resource-form :title="title" :item="item"></ig-device-resource-form>
  </va-create-layout>
</template>

<script>
export default {
  props: ["title", "item"],
};
</script>
