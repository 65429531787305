/*
 * @Description: 
 * @Author: your name
 * @Date: 2023-04-27 14:42:33
 * @LastEditors: your name
 * @LastEditTime: 2023-08-09 11:20:42
 */
// 全局变量
// let url = "http://192.168.0.74:8000"
// let url = "http://192.168.0.224:8000"
// 生产环境
// let url = "https://management.e3rong.com"
// let upload_url = "https://management.e3rong.com/"
// 外网测试
// let url = "https://wy.e3rong.com:18091/"
// let upload_url = "https://wy.e3rong.com:18091/"
// 测试环境
let url = "http://192.168.4.39:8000/"
let upload_url = "http://192.168.4.39:8000/"
// 暴露出去
export default {
  url,
  upload_url
}