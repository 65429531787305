<template>
  <div>
    <va-aside-layout :title="asideTitle">
      <hap-inpatient-ward-show
        v-if="show"
        :item="item"
      ></hap-inpatient-ward-show>
      <hap-inpatient-ward-form
        v-else
        :id="id"
        width="500px"
        :item="item"
        @saved="onSaved"
        ref="form"
      ></hap-inpatient-ward-form>
    </va-aside-layout>
    <v-card :icon="resource.icon" :title="title">
      <va-list
        disable-global-search
        disable-create-redirect
        disable-export
        :filters="filters"
        :filter="filter"
        ref="list"
        @action="onAction"
        :include="['bs_hospital']"
        :items-per-page="10"
      >
        <template v-slot:actions>
          <va-action-button
            icon="mdi-briefcase-download-outline"
            label="下载模版"
            :href="template_download_url"
            color="green"
            text
          >
          </va-action-button>
          <va-action-button
            icon="mdi-import"
            color="green"
            label="导入"
            @click="on_show_import"
            text
          >
          </va-action-button>
          <va-action-button
            icon="mdi-arrow-collapse-down"
            color="green"
            label="导出"
            text
            @click="on_export_excel"
          >
          </va-action-button>
        </template>

        <va-data-table
          disableShow
          disableClone
          :fields="fields"
          disable-create-redirect
          disable-edit-redirect
          disable-delete-redirect
          @item-action="onAction"
        >
        </va-data-table>
      </va-list>
      <v-dialog v-model="dialog" width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">批量导入</span>
          </v-card-title>
          <v-card-text>
            <el-form label-width="80px">
              <el-form-item label="上传文件">
                <el-upload
                  class="upload-demo"
                  action
                  :auto-upload="false"
                  :on-change="handleChange"
                  :file-list="fileList"
                  multiple
                >
                  <el-button
                    type="primary"
                    style="
                      color: white;
                      background-color: #14b2c5;
                      border: #14b2c5 1px solid;
                    "
                    size="small"
                    >点击上传</el-button
                  >
                </el-upload>
              </el-form-item>
            </el-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog = false"> 取消 </v-btn>
            <v-btn color="primary" @click="isSure"> 确认 </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import { exportResource } from "@/util/excel_export";
export default {
  props: ["resource", "title"],
  data() {
    return {
      // 模板下载地址
      template_download_url: `${this.global.upload_url}excel/office_import.xlsx`,
      // 弹出侧边栏
      asideTitle: null,
      id: null,
      dialog: false,
      fileList: [],
      item: null,
      show: false,
      filter: {},
      filters: [
        // {
        //   alwaysOn: true,
        //   label: "所属医院",
        //   source: "hospital_name",
        //   type: "text",
        // },
      ],
      fields: [
        {
          source: "hospital_name",
          label: "所属医院",
          sortable: false,
          align: "center",
        },
        {
          source: "ward_code",
          label: "病区编码",
          sortable: false,
          align: "center",
        },
        {
          source: "name",
          label: "病区名称",
          sortable: false,
          align: "center",
        },
      ],
    };
  },
  created() {
    this.getSupper();
  },
  methods: {
    getSupper() {
      // 医院名称的过滤
      let user_data = JSON.parse(
        window.sessionStorage.getItem("user")
      );
      if (user_data.is_superuser) {
        this.filters.push({
          source: "hospital_name",
          label: "所属医院",
          alwaysOn: true,
          type: "text",
        });
      }else{
        this.filter = {hospital_name: user_data.hospital_name}
      }
    },
    async onAction({ action, title, id, item }) {
      this.asideTitle = title;
      this.id = id;
      this.show = action === "show";
      this.item = item;
      if (action === "create") {
        this.id = null;
      }
    },
    onSaved() {
      this.asideTitle = null;
      this.$refs.list.fetchData();
    },
    handleChange(file, fileList) {
      this.fileList = fileList;
    },
    // 导入
    on_show_import() {
      this.dialog = true;
    },
    async isSure() {
      let excel_file = this.fileList[0].raw;
      let formData = new FormData();
      formData.append("excel_file", excel_file);
      await this.$admin.http.post("/base-system/import_doctor/", formData);
      this.dialog = false;
    },
    // 导出
    on_export_excel() {
      let params = this.$router.currentRoute.query;
      exportResource(
        this.$admin.http,
        "/base-system/export_doctor",
        params,
        "医生信息.xlsx"
      );
    },
  },
};
</script>
