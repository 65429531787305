<template>
  <div>
    <va-aside-layout :title="asideTitle">
      <bs-pharmacy-management-show
        v-if="show"
        :item="item"
      ></bs-pharmacy-management-show>
      <bs-pharmacy-management-form
        v-else
        :id="id"
        :item="item"
        @saved="onSaved"
      ></bs-pharmacy-management-form>
    </va-aside-layout>
    <v-card :icon="resource.icon" :title="title">
      <va-list
        ref="list"
        :filters="filters"
        :filter="filter"
        disableExport
        disable-global-search
        disable-create-redirect
        @action="onAction"
        :include="['bs_hospital', 'bs_pharmacy_type','bs_pharmacy_enterprise']"
        :sort-by="['id']"
        :sort-desc="[true]"
      >
        <template v-slot:actions>
          <v-btn-toggle
            class="custom_btn"
            v-model="toggle_release"
            mandatory
            color="#214578"
          >
            <v-btn @click="hospital"> 医院药房 </v-btn>
            <v-btn @click="cooperation"> 合作药企 </v-btn>
          </v-btn-toggle>
        </template>
        <template v-if="toggle_release == 0">
          <va-data-table
            disable-show
            :fields="fields"
            disable-show-redirect
            disable-clone
            disable-edit-redirect
            @item-action="onAction"
          >
          </va-data-table>
        </template>
        <template v-else>
          <va-data-table
            disable-show
            :fields="field"
            disable-show-redirect
            disable-clone
            disable-edit-redirect
            @item-action="onAction"
          >
          </va-data-table>
        </template>
      </va-list>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["resource", "title"],
  data() {
    return {
      asideTitle: null,
      id: null,
      item: null,
      show: false,
      create: false,
      filter: {},
      toggle_release: 0,
      filters: [
        // {
        //   alwaysOn: true,
        //   label: "药房分类",
        //   source: "pharmacy_type_id",
        //   type: "select",
        //   attributes: {
        //     reference: "bs_pharmacy_type",
        //     itemText: "name",
        //   },
        // },
      ],
      fields: [
        "pharmacy_code",
        "pharmacy_name",
        { source: "bs_pharmacy_type.name", label: "药房分类" },
        { source: "bs_hospital.name", label: "所属药企" },
        "address",
        { source: "is_active", type: "boolean" },
      ],
      field: [
        "pharmacy_code",
        "pharmacy_name",
        { source: "bs_pharmacy_type.name", label: "药房分类" },
        { source: "bs_pharmacy_enterprise.name", label: "所属药企" },
        "address",
        { source: "is_active", type: "boolean" },
      ],
    };
  },
  mounted() {
    this.hospital()
  },
  methods: {
    cooperation() {
      let hospital_id = JSON.parse(
        window.sessionStorage.getItem("user")
      ).hospital;
      this.filter = {
        // hospital_id: hospital_id,
        pharmacy_type_id: 2,
      };
    },
    hospital() {
      let hospital_id = JSON.parse(
        window.sessionStorage.getItem("user")
      ).hospital;
      this.filter = {
        // hospital_id: hospital_id,
        pharmacy_type_id: 1,
      };
    },
    async onAction({ action, title, id, item }) {
      this.asideTitle = title;
      this.id = id;
      this.show = action === "show";
      this.item = item;
      if (action === "create") {
        this.id = null;
      }
    },
    onSaved() {
      this.asideTitle = null;
      this.$refs.list.fetchData();
    },
  },
};
</script>
<style lang="sass" scoped>
.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined)
  background-color: #eee
  color: #14B2C5
  &:hover
    background-color: #ccc
    color: #214578
.custom_btn.v-item-group
  left: 0
  position: absolute

.v-btn.v-size--default
  margin-top: 5px !important
  font-size: 1rem
</style>