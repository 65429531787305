
<template>
  <va-form :id="id" :item="item" @saved="$emit('saved')">
    <template v-slot:heading>
      <div class="display-2">
        {{ title }}
      </div>
    </template>
    <v-card-text>
      <va-text-input
        v-model="form.standard_code"
        label="•标准编码"
        source="standard_code"
        required
      ></va-text-input>
      <va-text-input
        v-model="form.standard_name"
        label="•标准名称"
        source="standard_name"
        required
      ></va-text-input>
      <!-- <va-text-input source="hospital_id" required></va-text-input> -->
      <va-select-input
        v-if="user.is_superuser"
        source="hospital_id"
        label="•所属医院"
        reference="bs_hospital"
        required
        v-model="form.hospital_id"
        :items-per-page="1000"
        :filter="{ is_active: true }"
      ></va-select-input>
      <el-input
        class="inputBox"
        v-model="form.fees"
        placeholder="收费金额"
        source="fees"
        oninput="this.value=this.value.match(/\d+\.?\d{0,2}/)"
      ></el-input>
    </v-card-text>
    <div class="btn_bg">
      <v-btn @click="isSure" color="primary">保存</v-btn>
      <!-- <va-save-button></va-save-button> -->
    </div>
  </va-form>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: ["id", "title", "item"],
  data() {
    return {
      form: {
        standard_code: "",
        standard_name: "",
        parent_id: "",
        fees: null,
      },
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  mounted() {
    if (!this.user.is_superuser) {
      this.form.hospital_id = this.user.hospital;
    }
  },
  watch: {
    item: {
      deep: true,
      immediate: true,
      handler(val) {
        this.form.fees = val.fees;
      },
    },
  },

  methods: {
    async isSure() {
      if (
        !this.form.standard_code ||
        !this.form.standard_name ||
        !this.form.fees
      ) {
        this.$message({
          message: "有必填项未填写",
          type: "warning",
        });
        return;
      }
      if (this.id) {
        this.form.updated_by = this.user.username;
        // this.form.fees=Number(this.form.fees)
        try {
          await this.$store
            .dispatch("bs_expense_standard/update", {
              id: this.id,
              data: this.form,
            })
            .then((res) => {
              this.$emit("saved");
            });
          // this.$message({
          //   message: "修改成功",
          //   type: "success",
          // });
        } catch {
          this.$message({
            message: "修改失败",
            type: "warning",
          });
        }
      } else {
        this.form.created_by = this.user.username;
        this.form.expense_type = "2";
        try {
          await this.$store
            .dispatch("bs_expense_standard/create", {
              data: this.form,
            })
            .then((res) => {
              this.$emit("saved");
            });
          // this.$message({
          //   message: "添加成功",
          //   type: "success",
          // });
        } catch {
          this.$message({
            message: "添加失败",
            type: "warning",
          });
        }
      }
    },
  },
};
</script>
<style scoped>
.btn_bg {
  width: 95%;
  position: fixed;
  bottom: 0px;
  padding: 10px 0;
  text-align: center;
  background-color: white;
}
::v-deep .el-input__inner {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1px #d2d2d2 solid !important;
  border-radius: 0;
  padding: 10px;
  background-color: #f0f0f0;
  height: 55px;
  font-size: 16px;
}
</style>
