
<template>
  <v-card :icon="resource.icon" :title="title">
    <va-list
      :filters="filters"
      :filter="{
        id: table_data,
      }"
      disable-create
      disable-export
      :items-per-page="10"
      :include="['bs_hospital']"
      :sort-by="['created_time']"
      :sort-desc="[true]"
      global-search-query="username@_like,operat_content@_like,user_id@_like"
    >
      <template v-slot:actions>
        <va-action-button
          icon="mdi-arrow-collapse-down"
          color="green"
          label="导出"
          text
          @click="on_export_excel"
        >
        </va-action-button>
      </template>
      <template v-slot:[`filter.created_time@_gte`]="props" >
        <el-date-picker
          class="select"
          size="small"
          v-bind="props"
          v-model="date_value"
          type="daterange"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
        >
        </el-date-picker>
      </template>
      <va-data-table
        :items-per-page="10"
        :fields="fields"
        disable-clone
        disable-delete
        disable-edit
        disable-show
      >
      </va-data-table>
    </va-list>
  </v-card>
</template>

<script>
import { exportResource } from "@/util/excel_export";
const static_server_url =
  process.env.VUE_APP_API_URL || "http://localhost:28000/static";
import moment from "moment";
export default {
  props: ["resource", "title"],
  data() {
    return {
      template_download_url: `${this.global.upload_url}static/indications.xls`,
      table_data: [],
      data: "",
      date_value: "",
      filters: [
        {
          source: "hospital_id",
          label: "所属医院",
          type: "select",
          alwaysOn: true,
          attributes: { reference: "bs_hospital" },
        },
        {
          alwaysOn: true,
          label: "操作类型",
          source: "operat_type",
        },
        {
          source: "created_time@_gte",
          alwaysOn: true,
        },
      ],
      fields: [
        "username",
        "user_id",
        { source: "bs_hospital.name", label: "所属医院" },
        "operat_type",
        "operat_content",
        "login_ip",
        {
          source: "created_time",
          type: "date",
          attributes: { format: "longTime" },
        },
      ],
    };
  },
  methods: {
    on_export_excel() {
      // let export_data = {};
      // let filter_data = {};
      // if (this.date_value) {
      //   let key = "start_time";
      //   let key2 = "end_time";
      //   export_data[key] = moment(this.date_value[0]).format("YYYY-MM-DD");
      //   export_data[key2] = moment(this.date_value[1]).format("YYYY-MM-DD");
      // }
      // filter_data["page"] = this.$route.query.page;
      // filter_data["perPage"] = this.$route.query.perPage;
      // if (this.$route.query.filter) {
      //   filter_data = JSON.parse(this.$route.query.filter);
      // }
      // let assign = Object.assign(export_data, filter_data);
      // let src_key = "";
      // let obj_key = Object.keys(assign);
      // obj_key.forEach((objkey) => {
      //   src_key = src_key + "&" + objkey + "=" + assign[objkey];
      // });
      // let parameter = src_key.slice(1);
      // let params = this.$router.currentRoute.query;
      // exportResource(
      //   this.$admin.http,
      //   "/auth-system/export_logintolog/?" + parameter,
      //   params,
      //   "登录日志信息.xlsx"
      // );
    },
  },
  async mounted() {
    this.data = await this.$store.dispatch("cm_system_log_config/getList");
  },
  watch: {
    date_value: {
      deep: true,
      handler: function (newVal) {
        if (newVal) {
          this.table_data = [];
          let value1 = moment(newVal[0]).format("YYYY-MM-DD");
          let value2 = moment(newVal[1]).format("YYYY-MM-DD");
          this.data.data.forEach((item) => {
            if (
              moment(item.created_time).format("YYYY-MM-DD") >= value1 &&
              moment(item.created_time).format("YYYY-MM-DD") <= value2
            ) {
              this.table_data.push(item.id);
            } else {
              this.table_data = [0];
              return;
            }
          });
        } else {
          this.table_data = [];
        }
      },
    },
  },
};
</script>
<style lang="sass" scoped>
.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined)
  background-color: #eee
  color: #586AFC
  &:hover
    background-color: #ccc
    color: #000

.navInput
  width: 12rem
  height: 2.4rem
  border-bottom: 1px solid rgba(0, 0, 0, 0.15)
  border-radius: 3px
  cursor: pointer
  background: #fff
  padding-top: 0.6rem
  color: #444
  font-size: 14px
  font-family: SourceHanSansCN-Normal, SourceHanSansCN
  font-weight: 400

::v-deep .el-input__inner
  border-top: none !important
  border-left: none !important
  border-right: none !important
  border-bottom: 1px #D2D2D2 solid !important
  border-radius: 0
  padding-bottom: 10px
</style>
<style scoped>
::v-deep .el-input__inner {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1px #d2d2d2 solid !important;
  border-radius: 0;
  padding-bottom: 10px;
}
.select .el-icon-circle-close,
.el-input__prefix {
  display: block !important;
}

.custom_btn.v-item-group {
  left: 0;
  position: absolute;
}
</style>