var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('va-aside-layout',{attrs:{"title":_vm.asideTitle}},[(_vm.show)?_c('bs-pharmacy-drug-show',{attrs:{"item":_vm.item}}):_c('bs-pharmacy-drug-form',{attrs:{"id":_vm.id,"item":_vm.item},on:{"saved":_vm.onSaved}})],1),_c('v-card',{attrs:{"icon":_vm.resource.icon,"title":_vm.title}},[_c('va-list',{ref:"list",attrs:{"filters":_vm.filters,"filter":_vm.filter,"disableExport":"","global-search-query":"bs_pharmacy_management#pharmacy_name@_like,drug_code@_like,bs_drug_type#name@_like,bs_drug_preparation_type#type_name@_like,manufacturer@_like,origin_place@_like","disable-create-redirect":"","include":[
        'bs_pharmacy_management',
        'bs_hospital',
        'bs_drug_preparation_type',
        'bs_drug_category',
        'bs_drug_type',
      ],"sort-by":['id'],"sort-desc":[true]},on:{"action":_vm.onAction},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('va-action-button',{attrs:{"icon":"mdi-briefcase-download-outline","label":"下载模版","href":_vm.template_download_url,"color":"green","text":""}}),_c('va-action-button',{attrs:{"icon":"mdi-import","color":"green","label":"导入","text":""},on:{"click":_vm.on_show_import}}),_c('va-action-button',{attrs:{"icon":"mdi-arrow-collapse-down","color":"green","label":"导出","text":""},on:{"click":_vm.on_export_excel}})]},proxy:true}])},[_c('va-data-table',{attrs:{"disable-show":"","fields":_vm.fields,"disable-show-redirect":"","disable-clone":"","disable-edit-redirect":""},on:{"item-action":_vm.onAction}})],1),_c('v-dialog',{attrs:{"width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("批量导入")])]),_c('v-card-text',[_c('el-form',{attrs:{"label-width":"80px"}},[_c('el-form-item',{attrs:{"label":"上传文件"}},[_c('el-upload',{staticClass:"upload-demo",attrs:{"action":"","auto-upload":false,"on-change":_vm.handleChange,"file-list":_vm.fileList,"multiple":""}},[_c('el-button',{staticStyle:{"color":"white","background-color":"#14b2c5","border":"#14b2c5 1px solid"},attrs:{"type":"primary","size":"small"}},[_vm._v("点击上传")])],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" 取消 ")]),_c('v-btn',{attrs:{"color":"primary","text-color":"#fff"},on:{"click":_vm.isSure}},[_vm._v(" 确认 ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }