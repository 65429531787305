/**
 * Supported auth actions
 */
 export const LOGIN = "login";
 export const LOGOUT = "logout";
 export const CHECK_AUTH = "checkAuth";
 export const CHECK_ERROR = "checkError";
 export const GET_NAME = "getName";
 export const GET_EMAIL = "getEmail";
 export const GET_PERMISSIONS = "getPermissions";
