<template>
  <va-show :item="item" :id="id">
    <template v-slot:heading>
      <div class="display-2">
        {{ title }}
      </div>
    </template>
    <v-card-text>
      <va-field source="codenum"></va-field>
      <va-field source="name"></va-field>
      <va-field source="phone"></va-field>
      <va-field source="parent_id">{{ office }}</va-field>
      <va-field source="hospital_id">{{ hospital }}</va-field>

      <va-field source="address"></va-field>
      <!-- <va-text-input  label="科室地址" source="address"></va-text-input> -->
      <va-field source="is_active" type="boolean"></va-field>
      <va-field source="introduce"></va-field>
      <va-field source="created_time" type="date" format="longTime"></va-field>
      <!-- <va-field source="created_by"></va-field> -->
    </v-card-text>
  </va-show>
</template>

<script>
export default {
  props: ["title", "item","id"],
  data() {
    return {
      office: "",
      hospital: "",
    };
  },
  mounted() {
    this.getdatas();
  },
  methods: {
    getdatas() {
      // debugger
      if (this.item.parent_id !== undefined) {
        this.$store.dispatch("bs_office/getOne", {
          id: this.item.parent_id,
        }).then((res) => {
          this.office = res.data.name;
        })
      }
      if (this.item.hospital_id !== undefined) {
        this.$store.dispatch(
          "bs_hospital/getOne",
          { id: this.item.hospital_id }
        ).then((res) => {
          this.hospital = res.data.name;
        })
      }
    },
  },
  watch: {
    id: {
      deep: true,
      immediate: true,
      handler(val) {
        if(val) {
          this.getdatas();
        }
      },
    },
  },
};
</script>
