<template>
  <div>
    <va-aside-layout :title="asideTitle">
      <bs-hospital-show v-if="show" :id="id" :item="item"></bs-hospital-show>
      <bs-hospital-form
        v-else
        :id="id"
        width="500px"
        :item="item"
        @saved="onSaved"
        ref="form"
      ></bs-hospital-form>
    </va-aside-layout>
    <v-card :icon="resource.icon" :title="title">
      <va-list
        ref="list"
        :filters="filters"
        disableExport
        disable-global-search
        disable-create-redirect
        @action="onAction"
        :include="['bs_hospital', 'logo_images']"
        :sort-by="['created_time']"
        :sort-desc="[true]"
        @update:filter="update_data"
      >
        <template v-slot:actions>
          <!-- <va-action-button
            icon="mdi-briefcase-download-outline"
            label="下载模版"
            :href="template_download_url"
            color="green"
            text
          >
          </va-action-button>
          <va-action-button
            icon="mdi-import"
            color="green"
            label="导入"
            @click="on_show_import"
            text
          >
          </va-action-button> -->
          <va-action-button
            icon="mdi-arrow-collapse-down"
            color="green"
            label="导出"
            text
            @click="on_export_excel"
          >
          </va-action-button>
        </template>
        <va-data-table
          :fields="fields"
          disable-show-redirect
          disable-clone
          disable-edit-redirect
          @item-action="onAction"
        >
          <template v-slot:[`field.introduce`]="{ item }">
            <div
              style="
                width: 11vw;
                word-break: break-all;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
              "
            >
              {{ item.introduce }}
            </div>
          </template>
        </va-data-table>
      </va-list>
      <!-- 导入框 -->
      <v-dialog v-model="dialog" width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">批量导入</span>
          </v-card-title>
          <v-card-text>
            <el-form label-width="80px">
              <el-form-item label="上传文件">
                <el-upload
                  class="upload-demo"
                  action
                  :auto-upload="false"
                  :on-change="handleChange"
                  :file-list="fileList"
                  multiple
                >
                  <el-button
                    type="primary"
                    style="
                      color: white;
                      background-color: #14b2c5;
                      border: #14b2c5 1px solid;
                    "
                    size="small"
                    >点击上传</el-button
                  >
                </el-upload>
              </el-form-item>
            </el-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog = false"> 取消 </v-btn>
            <v-btn color="primary" @click="isSure"> 确认 </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import { exportResource } from "@/util/excel_export";
import BsHospitalForm from "./Form";
const static_server_url =
  process.env.VUE_APP_API_URL || "http://localhost:28000/static";
export default {
  props: ["resource", "title"],
  components: {
    "bs-hospital-form": BsHospitalForm,
  },
  data() {
    return {
      template_download_url: `${this.global.upload_url}static/indications.xls`,
      // ${static_server_url}static/indications.xls
      fileList: [],
      dialog: false,
      filters: [
        {
          source: "name",
          type: "text",
          alwaysOn: true
        },
        {
          source: "codenum",
          type: "text",
          alwaysOn: true
        },
      ],
      fields: [
        { source: "codenum" },
        "name",
        { source: "bs_hospital.name", label: "上级医院" },
        "address",
        { source: "phone", label: "联系方式" },
        "longitude",
        "latitude",
        "introduce",
        {
          source: "created_time",
          type: "date",
          attributes: { format: "longTime" },
        },
        "created_by",
      ],
      asideTitle: null,
      id: null,
      item: null,
      show: false,
      create: false,
    };
  },
  methods: {
    update_data() {
      this.$route.query.page = 1
    },
    //导出
    on_export_excel() {
      let params = this.$router.currentRoute.query;
      exportResource(
        this.$admin.http,
        "/base-system/export_hospital",
        params,
        "医院信息.xlsx"
      );
    },
    //导入
    async isSure() {
      let excel_file = this.fileList[0].raw;
      let formData = new FormData();
      formData.append("excel_file", excel_file);
      await this.$admin.http.post("/indications/upload/", formData);
      this.dialog = false;
    },
    handleChange(file, fileList) {
      this.fileList = fileList;
    },
    on_show_import() {
      this.dialog = true;
    },
    //导入
    async onAction({ action, title, id, item }) {
      this.asideTitle = title;
      this.id = id;
      this.show = action === "show";
      // this.create = action === "create";

      this.item = item;
      //如果点击的是新增,请求新增的接口
      if (action === "create") {
        this.id = null;
        if (this.$refs.bsform) {
          this.$refs.bsform.resetForm();
        }
      } else if (action === "edit") {
        this.id = item.id;
      }
    },
    onSaved() {
      this.asideTitle = null;
      this.$refs.list.fetchData();
    },
  },
};
</script>
