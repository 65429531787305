<template>
  <div>
    <v-card class="v_card">
      <span class="red_sp">
        示例：可提前预约7天，预约截止时间：“预约时段开始”“前”24小时，取消预约截止时间：“预约时段开始”“前”8小时
      </span>
      <v-row v-if="this.userflag" style="margin-top:20px">
        <v-col sm="4">
          <el-select style="width:100%" v-model="hospital_id" placeholder="•所属医院">
            <el-option v-for="item in hospitalList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
          <!-- <va-select-input
              v-model="hospital_id"
              source="hospital_id"
              label="•所属医院"
              reference="bs_hospital"
              required
              :items-per-page="1000"
              :filter="{ is_active: true }"
            ></va-select-input> -->
        </v-col>
      </v-row>
    </v-card>

    <div>
      <v-card class="v_card" v-for="(item,index) of data_wz" :key="index">
        <div style="line-height:60px;">
          <span class="dot_circle"></span><span class="title_con">{{item.name}}</span>
        </div>
        <div>

          <v-row>
            <v-col sm="4">
              <va-text-input v-model="item.appoint_advance" label="可提前预约天数(天)" source="appoint_advance"></va-text-input>
            </v-col>

          </v-row>

          <div style="line-height:60px;font-size:20px">预约截止日期</div>
          <v-row justify="center">
            <v-col sm="4">
              <va-select-input source="appoint_over_time_refer" v-model="item.appoint_over_time_refer" label="参考时间点"
                :items-per-page="1000"></va-select-input>
            </v-col>
            <v-col sm="4">
              <va-select-input v-model="item.appoint_over_time_type" source="appoint_over_time_type" label="时间方向"
                :items-per-page="1000"></va-select-input>
            </v-col>
            <v-col sm="4">
              <va-text-input v-model="item.appoint_over_time" label="请输入时间（小时）" source="appoint_over_time"
              ></va-text-input>
            </v-col>
          </v-row>

          <div style="line-height:60px;font-size:20px">取消预约截止日期</div>
          <v-row justify="center">
            <v-col sm="4">
              <va-select-input label="参考时间点" v-model="item.cancel_time_refer" source="cancel_time_refer"
                :items-per-page="1000"></va-select-input>
            </v-col>
            <v-col sm="4">
              <va-select-input label="时间方向" v-model="item.cancel_time_type" source="cancel_time_type"
                :items-per-page="1000"></va-select-input>
            </v-col>
            <v-col sm="4">
              <va-text-input v-model="item.cancel_time" label="请输入时间（小时）" source="cancel_time" >
              </va-text-input>
            </v-col>
          </v-row>
        </div>
      </v-card>
      <div class="btn_bg">
        <v-btn @click="isSure" color="primary">保存</v-btn>
        <!-- <va-save-button></va-save-button> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  // props: ["resource", "title"],
  data() {
    return {
      data_wz: [
        // {name:"",appointment_type_id:"2",hospital_id:"",appoint_advance:"",appoint_over_time_refer:"",appoint_over_time_type:"",appoint_over_time:"",cancel_time_refer:"",cancel_time_type:"",cancel_time:""},

      ],
      hospital_id: "",
      userflag: false,
      hospitalList: [],
      appointment_type_data: [],
      transferdata: [],
      transferhospital_id:""
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },

  async mounted() {
    this.gethospital();
    // this.get_sed_type();
  },
  watch: {
    hospital_id: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
          this.getdata(val)
          this.transferhospital_id=val
        }
      },
    },
  },
  methods: {
    getdata(data) {
      this.$store.dispatch("ws_appointment_type_name/getList", {
        filter: {
          is_active: true,
        },
      }).then((res) => {
        let appointment_data = res.data;
        if (res.data.length > 0) {
          this.transferdata = [];
          let p = [];
          for (let i = 0; i < appointment_data.length; i++) {
            p.push(this.$store.dispatch("ws_appointment_parameter_setting/getList", {
              filter: {
                is_active: true,
                appointment_type_id: appointment_data[i].id,
                hospital_id: data,

              },
              sort: [{ by: "updated_time", desc: true }],
            }).then((res) => {
              if (res.data.length > 0) {
                return res.data[0]
              } else {
                let obj = { name: "", appointment_type_id: "", hospital_id: "", appoint_advance: 7, appoint_over_time_refer: '', appoint_over_time_type: '', appoint_over_time:"", cancel_time_refer: "", cancel_time_type: "", cancel_time: "" };
                obj.name = appointment_data[i].name + "参数配置";
                obj.appointment_type_id = appointment_data[i].id;
                obj.hospital_id = data;
                return obj
              }
            })
            )
            // this.get_appointment_parameter_setting(appointment_data[i].id,data);

          }
          let self = this;
          Promise.all(p).then((posts) => {
            self.transferdata = posts;
            self.data_wz = self.transferdata;
          })
        }
      })
        .catch(() => { });
    },

    //获取用户
    gethospital() {
      let username = this.user.username;
      if (username == "admin") {
        this.userflag = true;
        this.gethospitallist()
      } else {
        this.hospital_id = this.user.hospital;
      }
    },
    //获取医院
    async gethospitallist() {
      let com = await this.$store.dispatch("bs_hospital/getList", {
        filter: {
          is_active: true,
        },
      });

      this.hospitalList = com.data;
      this.hospital_id = com.data[0].id;
    },
    async isSure() {
      let s = [];
      this.data_wz.forEach(element => {
        if(element.id){
          s.push(this.$store.dispatch("ws_appointment_parameter_setting/update", {
            id:element.id,
            data: element,
        })
        )
         
       }else{
        s.push(
        this.$store.dispatch("ws_appointment_parameter_setting/create", { 
          data: element,
        })
        )
       }
      });  
          let selfme = this;
          Promise.all(s).then((posts) => {
            // debugger
            selfme.getdata(selfme.transferhospital_id)
          })
     
    }
  }
};
</script>
<style scoped>
.v_card {
  padding: 20px;
  margin-bottom: 20px;
}

.red_sp {
  color: red;
}

.dot_circle {
  display: inline-block;
  background-color: #069CAE;
  width: 8px;
  height: 8px;
  margin-right: 10px;
  border-radius: 8px;
  margin-bottom: 2px;
}

.title_con {
  display: inline-block;
  font-size: 20px;
}

.btn_bg {
  width: 95%;
  position: fixed;
  bottom: 0px;
  padding: 10px 0;
  text-align: center;
  background-color: white;

}
</style>
