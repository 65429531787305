<template>
  <va-form :id="id" :item="item" @saved="$emit('saved')" ref="form">
    <template v-slot:heading>
      <div class="display-2">
        {{ title }}
      </div>
    </template>
    <v-card-text style="padding-bottom: 80px">
      <va-text-input
        label="•科室编码"
        source="codenum"
        v-model="form.codenum"
        required
      ></va-text-input>
      <va-text-input
        label="•科室名称"
        source="name"
        required
        v-model="form.name"
      ></va-text-input>
      <va-select-input
        v-if="user.is_superuser"
        v-model="form.hospital_id"
        source="hospital_id"
        label="•所属医院"
        reference="bs_hospital"
        required
        :items-per-page="1000"
        :filter="{ is_active: true }"
      ></va-select-input>
      <SelectInput
        @office="getOffice"
        :items="items_data"
        :label="label_name"
        :entrust="entrust"
        source="parent_id"
        v-model="form.parent_id"
        reference="bs_office"
      ></SelectInput>
      <va-text-input
        v-model="form.address"
        label="科室地址"
        source="address"
      ></va-text-input>
      <va-text-input
        v-model="form.phone"
        label="联系方式"
        source="phone"
      ></va-text-input>
      <va-text-input
        label="科室简介"
        source="introduce"
        v-model="form.introduce"
        multiline
      ></va-text-input>
      <va-boolean-input
        label="是否启用"
        source="is_active"
        v-model="form.is_active"
      ></va-boolean-input>
      <va-text-input
        label="创建人"
        v-model="form.created_by"
        source="created_by"
        v-show="false"
      ></va-text-input>
    </v-card-text>
    <div class="btn_bg">
      <v-btn @click="isSure" color="primary">保存</v-btn>
    </div>
  </va-form>
</template>

<script>
import SelectInput from "@/components/FilterData.vue";
import { mapState } from "vuex";
export default {
  inject: ["reload"],
  props: ["id", "title", "item"],
  components: { SelectInput },
  data() {
    return {
      label_name: "上级科室",
      items_data: null,
      // form.hospital_id: null,
      // created_by: "",
      form: {
        codenum: "",
        name: "",
        hospital_id: "",
        parent_id: "",
        address: "",
        phone: "",
        introduce: "",
        is_active: true,
        created_by: null,
      },
      entrust: null,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  mounted() {
    if (!this.user.is_superuser) {
      this.form.hospital_id = this.user.hospital;
    }
    this.form.created_by = this.user.name;
  },
  methods: {
    getOffice(msg) {
      if(msg==null){
        this.form.parent_id = null;
      }else{
        this.form.parent_id = msg.id;
      }
    },
    async isSure() {
      if (!this.form.codenum || !this.form.name || !this.form.hospital_id) {
        this.$message({
          message: "有必填项未填写",
          type: "warning",
        });
        return;
      }
      if (this.id) {
        try {
          await this.$store
            .dispatch("bs_office/update", {
              id: this.id,
              data: this.form,
            })
            .then((res) => {
              this.$emit("saved");
            });
        } catch {}
      } else {
        this.form.created_by = this.user.username;
        try {
          await this.$store
            .dispatch("bs_office/create", {
              data: this.form,
            })
            .then((res) => {
              this.$emit("saved");
            });
        } catch {
          // this.$message({
          //   message: "科室编码违反唯一性，请修改",
          //   type: "error",
          // });
          return;
        }
      }
    },
  },
  watch: {
    id: {
      deep: true,
      immediate: true,
      handler(val) {
        if(val) {
          this.entrust = this.item.parent_id
        }
      }
    },
    "form.hospital_id": {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
          this.$store
            .dispatch("bs_office/getList", {
              filter: {
                hospital_id: val,
              },
            })
            .then((res) => {
              res.data.forEach((item) => {
                item.text = item.name;
              });
              this.items_data = res.data;
            });
        }else{
          this.$store
            .dispatch("bs_office/getList")
            .then((res) => {
              res.data.forEach((item) => {
                item.text = item.name;
              });
              this.items_data = res.data;
            });
        }
      },
    },
  },
};
</script>
<style scoped>
.btn_bg {
  width: 95%;
  position: fixed;
  bottom: 0px;
  padding: 10px 0;
  text-align: center;
  background-color: white;
}
</style>