<template>
    <va-show :item="item">
            <template v-slot:heading>
              <div class="display-2">
                {{ title }}
              </div>
            </template>
            <v-card-text>
              <va-field source="standard_code"  label="标准编码"></va-field>
              <va-field source="standard_name" label="标准名称"></va-field>
              <va-field source="hospital_id" label="所属医院">{{hospital}}</va-field>
              <va-field source="fees" label="收费金额"></va-field>
              <va-field source="created_time" label="创建时间" type="date" format= "longTime"></va-field>
              <va-field source="created_by" label="创建人"></va-field>
            </v-card-text>
    </va-show>
</template>

<script>
export default {
  props: ["title", "item"],
  data(){
  return{
     hospital:''
  }
},
  mounted() {
   this.getdatas()
   
  },
  methods:{
    async getdatas(){
  
        let bs_hospital_getOne = await this.$store.dispatch(
          "bs_hospital/getOne",
          { id: this.item.hospital_id }
        );
        this.hospital=bs_hospital_getOne.data.name;
    },
  }
};
</script>
