<template>
	<el-upload action=" https://management.e3rong.com/tools-collection/images_upload/" list-type="picture-card"
		:on-change="onchange" :file-list="fileList" :on-remove="handleRemove">
		<i class="el-icon-plus"></i>
	</el-upload>
</template>

<script>
	export default {
		props: ["imageInfo"],
		data() {
			return {
				imgfile: null,
				fileList: [],
			};
		},
		methods: {
			handleRemove(file, fileList) {
				this.$admin.http.delete(`/tools-collection/images_upload/${file.id}/`);
			},
			onchange(file, fileList) {
				const imgType =
					file.raw.type === "image/png" || file.raw.type === "image/jpeg";
				const imgSize = file.raw.size / 1024 / 1024 <= 2;
				if (!imgType) {
					this.$message.error("只能上传png、jpg、jpeg的图片格式！");
					return false;
				}
				if (!imgSize) {
					this.$message.error("图片上传最大限制2MB！");
					return false;
				}
				//如果第二次上传文件，将前一个文件删除
				//这样fileList就一直保持一个文件
				if (fileList.length > 1) {
					fileList.splice(0, 1);
				}
				//获取当前文件的一个内存URL
				//给图片src赋值
				this.imgfile = file;
				this.imgUrl = URL.createObjectURL(file.raw);
				// this.$emit("imgInfo", this.imgfile); // 启动报错，因此修改 李鑫鑫
				this.$emit('img-info', this.imgfile);
			},

			// Element 上传
			uploadImg(file, id, model) {
				var formData = new FormData();
				formData.append("belong_id", id);
				formData.append("belong_model", model);
				formData.append("big_image", file.raw);
				this.$admin.http
					.post("/tools-collection/images_upload/", formData)
					.then((img) => {});
			},
			// 修改图片
			reviseImg(imgid, file, id, model) {
				var formData = new FormData();
				formData.append("belong_id", id);
				formData.append("belong_model", model);
				formData.append("big_image", file.raw);
				this.$admin.http
					.put(`/tools-collection/images_upload/${imgid}/`, formData)
					.then((img) => {});
			},
		},
		watch: {
			imageInfo: {
				deep: true,
				immediate: true,
				handler: function(newVal) {
					this.fileList = [];
					if (newVal.length >= 1) {
						newVal.forEach((img) => {
							this.fileList.push({
								id: img.id,
								url: `${this.global.url}/${img.big_image}`,
							});
						});
					}
				},
			},
		},
	};
</script>

<style>
</style>