<template>
  <div>
    <iframe
      :src="src"
      style="border: none"
      :width="searchTableWidth"
      :height="searchTableHeight"
    ></iframe>
  </div>
</template>

<script>
export default {
  data() {
    return {
      src: "",
      searchTableHeight: 0,
      searchTableWidth: 0,
    };
  },
  mounted(){
      window.onresize = () => {
      this.widthHeight(); // 自适应高宽度
    };
    this.$nextTick(function () {
      this.widthHeight();
    });
  },

  methods:{
      widthHeight() {
      this.searchTableHeight = window.innerHeight - 180;
      this.searchTableWidth = window.innerWidth - 230;
    },
  },
  created() {
    let item = JSON.parse(this.$route.query.str);
    let str = item.completestate;
    let splic = str.substring(2,str.indexOf('.'))
      let yrl = encodeURIComponent(splic)
    this.src =
        "/webroot/decision/view/report?reportlet="+yrl+".cpt&op=write&__cutpage__=null&__processtaskid__="+
      item.id + "&__allprocesstaskid__=" + item.taskid + "";
  },
};
</script>

<style>
</style>