<template>
  <!-- <va-show-layout> -->
    <va-show :item="item">
            <v-card-text>
              <va-field source="needEscort" ></va-field>
              <va-field source="endMenstruationDate" ></va-field>
              <va-field source="message" ></va-field>
              <va-field source="note" ></va-field>
              <va-field source="imageNo" ></va-field>
              <va-field source="cardID" ></va-field>
              <va-field source="hospitalCardNo" ></va-field>
              <va-field source="medicalTypeCode" ></va-field>
              <va-field source="medicalSerialNo" ></va-field>
              <va-field source="medicalTypeName" ></va-field>
              <va-field source="reqExecStatusName" ></va-field>
              <va-field source="reqExecStatus" ></va-field>
              <va-field source="patientName" ></va-field>
              <va-field source="sexName" ></va-field>
              <va-field source="age" ></va-field>
              <va-field source="phoneNo" ></va-field>
              <va-field source="bedNo" ></va-field>
              <va-field source="mailingAddress" ></va-field>
              <va-field source="resourceGroupID" ></va-field>
              <va-field source="resourceGroupName" ></va-field>
              <va-field source="idReq" ></va-field>
              <va-field source="reqDeptName" ></va-field>
              <va-field source="reqDatetime" ></va-field>
              <va-field source="printGuideline" ></va-field>
              <va-field source="canAppoint" ></va-field>
              <va-field source="needApoint" ></va-field>
              <va-field source="reachStudyTo" ></va-field>
              <va-field source="isError" ></va-field>
              <va-field source="chargeMark" ></va-field>
              <va-field source="appointed" ></va-field>
              <va-field source="appointMode" ></va-field>
              <va-field source="reqStatus" ></va-field>
              <va-field source="attention" ></va-field>
              <va-field source="mainDiagnosis" ></va-field>
              <va-field source="reqDocName" ></va-field>
              <va-field source="chiefComplaint" ></va-field>
              <va-field source="presentHistory" ></va-field>
              <va-field source="allergicHistory" ></va-field>
              <va-field source="physicalExamication" ></va-field>
              <va-field source="assistExamication" ></va-field>
              <va-field source="remark" ></va-field>
            </v-card-text>
    </va-show>
  <!-- </va-show-layout> -->
</template>

<script>
export default {
  props: ["title", "item"],
};
</script>
