<!--
 * @Description: 
 * @Author: your name
 * @Date: 2023-04-27 14:54:55
 * @LastEditors: your name
 * @LastEditTime: 2023-05-18 15:32:05
-->
<template>
  <va-form :id="id" :item="item" @saved="$emit('saved')" ref="form">
    <template v-slot:heading>
      <div class="display-2">
        {{ title }}
      </div>
    </template>
    <v-card-text style="padding-bottom: 80px">
      <va-select-input
        v-if="user.is_superuser"
        label="所属医院"
        item-value="codenum"
        reference="bs_hospital"
        :items-per-page="1000"
        source="hospital_code"
        v-model="form.hospital_code"
      ></va-select-input>
      <SelectInput
        item-value="codenum"
        source="office_code"
        v-model="form.office_code"
        reference="bs_office"
        @office="getOffice"
        :items="items_data"
        :label="label_name"
        :entrust="entrust"
      ></SelectInput>
      <va-text-input
        label="诊室编码"
        source="code"
        v-model="form.code"
      ></va-text-input>
      <va-text-input
        label="诊室名称"
        source="name"
        v-model="form.name"
      ></va-text-input>
      <el-input
        class="inputBox"
        v-model="form.fee"
        placeholder="收费金额"
        source="fees"
        oninput="this.value=this.value.match(/\d+\.?\d{0,2}/)"
      ></el-input>
      <!-- <va-select-input
        label="挂号费用标准"
        reference="bs_expense_standard"
        :items-per-page="1000"
        source="fee"
        v-model="form.fee"
      ></va-select-input> -->
    </v-card-text>
    <div class="btn_bg">
      <v-btn @click="isSure" color="primary">保存</v-btn>
    </div>
  </va-form>
</template>

<script>
import SelectInput from "@/components/FilterData.vue";
import { mapState } from "vuex";
export default {
  props: ["id", "title", "item"],
  components: {
    SelectInput,
  },
  data() {
    return {
      form: {
        hospital_code: "",
        hospital_name: "",
        office_code: "",
        office_name: "",
        code: "",
        name: "",
        is_active: true,
        fee: "",
      },
      hospital_id: "",
      label_name: "所属科室",
      items_data: null,
      entrust: null,
    };
  },
  mounted() {
    if (!this.user.is_superuser) {
      this.form.hospital_code = this.user.hospital_code;
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  methods: {
    getOffice(msg) {
      this.form.office_code = msg.codenum;
    },
    isSure() {
      if (
        !this.form.hospital_code ||
        !this.form.hospital_name ||
        !this.form.office_code ||
        !this.form.office_name ||
        !this.form.code ||
        !this.form.name ||
        !this.form.fee
      ) {
        this.$message({
          message: "有必填项未填写",
          type: "warning",
        });
        return;
      }
      if (this.id) {
        this.$store
          .dispatch("er_management_main/update", {
            id: this.id,
            data: this.form,
          })
          .then((res) => {
            this.$emit("saved");
          });
      } else {
        this.$store
          .dispatch("er_management_main/create", {
            data: this.form,
          })
          .then((res) => {
            this.$emit("saved");
          });
      }
      // this.$store.dispatch("hap_sickbed/create",this.form)
    },
  },
  watch: {
    "form.hospital_code": {
      deep: true,
      immediate: false,
      handler(val) {
        if (val !== undefined) {
          this.$store
            .dispatch("bs_hospital/getList", {
              filter: {
                codenum: val,
              },
            })
            .then((res) => {
              this.hospital_id = res.data[0].id;
              this.form.hospital_name = res.data[0].name;
              if (this.hospital_id) {
                this.$store
                  .dispatch("bs_office/getList", {
                    filter: {
                      hospital_id: this.hospital_id,
                    },
                  })
                  .then((res) => {
                    res.data.forEach((item) => {
                      item.text = item.name;
                    });
                    this.items_data = res.data;
                  });
              } else {
                this.$store.dispatch("bs_office/getList").then((res) => {
                  res.data.forEach((item) => {
                    item.text = item.name;
                  });
                  this.items_data = res.data;
                });
              }
            });
        }
      },
    },
    "form.office_code": {
      deep: true,
      immediate: false,
      handler(newValue, oldValue) {
        this.$store
          .dispatch("bs_office/getList", {
            filter: {
              codenum: newValue,
            },
          })
          .then((res) => {
            this.form.office_name = res.data[0].name;
          });
      },
    },
    "form.fee": {
      deep: true,
      handler(newValue, oldValue) {
        if (Number(newValue) < 0) {
          this.$message({
            message: "请填写规范的收费标准",
            type: "warning",
          });
        }
      },
    },
    item: {
      deep: true,
      immediate: true,
      handler(val) {
        console.log(val.fee);
        this.form.fee = val.fee;
      },
    },
    id: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val && this.item.office_code) {
          this.$store
            .dispatch("bs_office/getOne", {
              codenum: this.item.office_code,
            })
            .then((res) => {
              if (res.data.id) {
                this.entrust = res.data.id;
              }
            });
        } else {
          this.entrust = null;
        }
      },
    },
  },
  created() {
    if (this.item) {
      this.form.fee = Number(this.item.fee);
    }
  },
};
</script>
<style scoped>
.btn_bg {
  width: 95%;
  position: fixed;
  bottom: 0px;
  padding: 10px 0;
  text-align: center;
  background-color: white;
}
.inputBoxs {
  width: 100%;
  height: 50px;
  background-color: #f0f0f0;
  padding: 5px;
  outline: none;
}
::v-deep .el-input__inner {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1px #d2d2d2 solid !important;
  border-radius: 0;
  padding: 10px;
  background-color: #f0f0f0;
  height: 55px;
  font-size: 16px;
}
</style>