<!--
 * @Description: 
 * @Author: your name
 * @Date: 2023-05-04 14:29:34
 * @LastEditors: your name
 * @LastEditTime: 2023-07-31 17:48:56
-->
<template>
  <div>
    <va-aside-layout :title="asideTitle">
      <hap-bed-apt-record-show
        v-if="show"
        :id="id"
        :item="item"
      ></hap-bed-apt-record-show>
      <hap-bed-apt-record-form
        v-else
        :id="id"
        width="500px"
        :item="item"
        @saved="onSaved"
        ref="form"
      ></hap-bed-apt-record-form>
    </va-aside-layout>
    <v-card :icon="resource.icon" :title="title">
      <va-list
        disable-export
        :filters="filters"
        :filter="filter"
        ref="list"
        @action="onAction"
        :items-per-page="10"
        :include="['bs_hospital']"
        :sort-by="['planned_admission_time']"
        global-search-query="hospital_name@_like,ward_name@_like,room_name@_like,patient_name@_like,bed_num@_like"
        :sort-desc="[true]"
      >
        <template v-slot:[`filter.planned_admission_time@_gte`]="props">
          <el-date-picker
            class="select"
            size="small"
            v-bind="props"
            v-model="date_value"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </template>
        <template v-slot:actions> </template>
        <!-- <template v-slot:actions>
          <va-action-button
            icon="mdi-briefcase-download-outline"
            label="下载模版"
            :href="template_download_url"
            color="green"
            text
          >
          </va-action-button>
          <va-action-button
            icon="mdi-import"
            color="green"
            label="导入"
            @click="on_show_import"
            text
          >
          </va-action-button>
          <va-action-button
            icon="mdi-arrow-collapse-down"
            color="green"
            label="导出"
            text
            @click="on_export_excel"
          >
          </va-action-button>
        </template> -->
        <va-data-table
          disableShow
          disableClone
          :fields="fields"
          disable-create-redirect
          disable-show-redirect
          disable-delete-redirect
          @item-action="onAction"
          disable-delete
          disable-select
          disable-edit
        >
          <template v-slot:[`field.apt_state`]="{ item }">
            <template v-if="item.apt_state == 1">
              <span> 已预约 </span>
            </template>
            <template v-else-if="item.apt_state == 2">
              <span> 取消预约 </span></template
            >
          </template>
          <template v-slot:[`field.bed_state`]="{ item }">
            <template v-if="item.bed_state == 0">
              <span> 未入住 </span>
            </template>
            <template v-else-if="item.bed_state == 1">
              <span> 已入住 </span></template
            >
            <template v-else-if="item.bed_state == 2">
              <span> 已搬出 </span></template
            >
          </template>
          <template v-slot:[`field.state`]="{ item }">
            <template v-if="item.state == 0">
              <v-chip class="ma-2" color="green" text-color="white">
                未审批
              </v-chip>
            </template>
            <template v-else-if="item.state == 1">
              <v-chip class="ma-2" text-color="#42b6c4">
                已同意
              </v-chip></template
            ><template v-else-if="item.state == 2">
              <v-chip
                class="ma-2"
                color="red"
                text-color="white"
                @click="goShow(item)"
              >
                已驳回
              </v-chip>
            </template>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom v-if="itemShowEdit(item)">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-if="$admin.can(['change_bedappointmentrecord'])"
                  small
                  class="mdi-pencil"
                  color="#2196F3"
                  style="margin-right: 10px"
                  @click="showEdit(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-archive-remove-outline
                </v-icon>
              </template>
              <span>编辑</span>
            </v-tooltip>
            <v-tooltip bottom v-if="itemShow(item)">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  class="mr-2"
                  color="red"
                  v-if="$admin.can(['delete_bedappointmentrecord'])"
                  @click="deleteItem(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-archive-remove-outline
                </v-icon>
              </template>
              <span>取消预约</span>
            </v-tooltip>
          </template>
        </va-data-table>
      </va-list>
    </v-card>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: ["resource", "title"],
  data() {
    return {
      // 模板下载地址
      // template_download_url: `${this.global.upload_url}excel/office_import.xlsx`,
      // 弹出侧边栏
      asideTitle: null,
      id: null,
      item: null,
      table_data: [],
      data: null,
      show: false,
      date_value: "",
      filter: {},
      user_data: null,
      filters: [
        // {
        //   alwaysOn: true,
        //   label: "所属医院",
        //   source: "hospital_name",
        //   type: "text",
        // },
        {
          alwaysOn: true,
          label: "病区名称",
          source: "ward_name",
          type: "text",
        },
        {
          alwaysOn: true,
          label: "病房名称",
          source: "room_name",
          type: "text",
        },
        {
          alwaysOn: true,
          label: "状态",
          source: "state",
          type: "select",
          // attributes: {
          //   reference: "bs_drug_preparation_type",
          //   itemText: "type_name",
          // },
        },
        {
          source: "planned_admission_time@_gte",
          alwaysOn: true,
        },
      ],
      fields: [
        {
          source: "patient_name",
          label: "患者姓名",
          sortable: false,
          align: "center",
        },
        {
          source: "id_num",
          label: "身份证号",
          sortable: false,
          align: "center",
        },
        {
          source: "hospital_name",
          label: "所属医院",
          sortable: false,
          align: "center",
        },
        {
          source: "ward_name",
          label: "所属病区",
          sortable: false,
          align: "center",
        },
        {
          source: "room_name",
          label: "所属病房",
          sortable: false,
          align: "center",
        },
        {
          source: "bed_num",
          label: "床位号",
          sortable: false,
          align: "center",
        },

        {
          source: "planned_admission_time",
          type: "date",
          label: "计划入住时间",
          align: "center",
          attributes: { format: "short" },
        },
        {
          source: "planned_length_stay",
          label: "计划入住天数",
          sortable: false,
          align: "center",
        },
        {
          source: "apt_state",
          label: "预约状态",
          sortable: false,
          align: "center",
        },
        {
          source: "bed_state",
          label: "床位状态",
          sortable: false,
          align: "center",
        },
        {
          source: "state",
          label: "状态",
          sortable: false,
          align: "center",
        },
      ],
    };
  },
  mounted() {
    this.$store.dispatch("hap_bed_apt_record/getList").then((res) => {
      this.data = res;
    });
  },
  watch: {
    date_value: {
      deep: true,
      handler: function (newVal) {
        if (newVal) {
          this.table_data = [0];
          this.data.data.forEach((item) => {
            if (
              moment(item.planned_admission_time).format("YYYY-MM-DD") ==
              moment(newVal).format("YYYY-MM-DD")
            ) {
              this.table_data = [];
              this.table_data.push(item.id);
            }
          });
        } else {
          this.table_data = [];
        }
        this.filter = {
          id: this.table_data,
          hospital_name: this.user_data.hospital_name,
        };
      },
    },
  },
  created() {
    this.getSupper();
  },
  methods: {
    getSupper() {
      console.log("zoulejici");
      // 医院名称的过滤
      this.user_data = JSON.parse(window.sessionStorage.getItem("user"));
      if (this.user_data.is_superuser) {
        this.filters.push({
          source: "hospital_name",
          label: "所属医院",
          alwaysOn: true,
          type: "text",
        });
      } else {
        this.filter = { hospital_name: this.user_data.hospital_name };
      }
    },
    goShow(item) {
      this.show = false;
      this.asideTitle = "驳回原因";
      this.id = item.id;

      this.show = true;
      this.item = item;
    },
    async onAction({ action, title, id, item }) {
      console.log(1111111111);
      this.asideTitle = title;
      this.id = id;
      this.show = action === "show";
      this.item = item;
      if (action === "create") {
        this.id = null;
      }
    },
    onSaved() {
      this.asideTitle = null;
      this.$refs.list.fetchData();
    },
    deleteItem(item) {
      console.log(item.id, "irtmemajn");
      this.$confirm("此操作将取消预约，是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$admin.http
          .post(
            `hospitalization_apt/bed_appointment_record/${item.id}/cancel_apt_sick_bed/`,
            this.form
          )
          .then((res) => {
            this.onSaved();
          });
      });
      //   this.$store
      //     .dispatch("hap_bed_apt_record/delete", {
      //       id: item.id,
      //     })
      //     .then((res) => {
      //       this.onSaved()
      //       // this.getSelect();
      //     });
      // })
    },
    itemShowEdit(item) {
      if (item.state == 1 || item.state == 2 || item.apt_state == 2) {
        return false;
      } else {
        return true;
      }
    },
    showEdit(item) {
      this.$router.push({ path: `/hap-bed-apt-record/${item.id}/edit` });
    },
    // 导入
    on_show_import() {},
    // 导出
    on_export_exce() {},
    itemShow(item) {
      // item.apt_state != 2 || item.bed_state  == 0
      if (item.apt_state == 2) {
        return false;
      } else if (item.bed_state == 1) {
        return false;
      } else if (item.bed_state == 2) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>
<style lang="sass" scoped>
.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined)
  background-color: #eee
  color: #586AFC
  &:hover
    background-color: #ccc
    color: #000

.navInput
  width: 12rem
  height: 2.4rem
  border-bottom: 1px solid rgba(0, 0, 0, 0.15)
  border-radius: 3px
  cursor: pointer
  background: #fff
  padding-top: 0.6rem
  color: #444
  font-size: 14px
  font-family: SourceHanSansCN-Normal, SourceHanSansCN
  font-weight: 400

::v-deep .el-input__inner
  border-top: none !important
  border-left: none !important
  border-right: none !important
  border-bottom: 1px #D2D2D2 solid !important
  border-radius: 0
  padding-bottom: 10px
</style>
<style scoped>
::v-deep .el-input__inner {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1px #d2d2d2 solid !important;
  border-radius: 0;
  padding-bottom: 10px;
}
.select .el-icon-circle-close,
.el-input__prefix {
  display: block !important;
}
::v-deep .container header .v-toolbar__content form .row {
  display: flex !important;
  flex-wrap: nowrap !important;
}
.custom_btn.v-item-group {
  left: 0;
  position: absolute;
}
</style>
