<template>
  <base-material-card :icon="resource.icon" :title="title">
    <va-list
      :filters="filters"
    >
      <va-data-table :fields="fields">
      </va-data-table>
    </va-list>
  </base-material-card>
</template>

<script>
export default {
  props: ["resource", "title"],
  data() {
    return {
      filters: [
  'day 排班日期',
  'office_code\t科室编码',
  'office_name\t科室名称',
  'group_name 所属服务组名称',
  'group_id 所属服务组ID'
],
      fields: [
  { source: 'day 排班日期', sortable: true },
  { source: 'office_code\t科室编码', sortable: true },
  { source: 'office_name\t科室名称', sortable: true },
  { source: 'group_name 所属服务组名称', sortable: true },
  { source: 'group_id 所属服务组ID', sortable: true },
  'used_count 已用总数',
  'resource_count 模板号源总数',
  'start_time 起始时间',
  'end_time 结束时间',
  'resource_distribution_way   资源分配模式',
  'create_time\t创建时间',
  'remark\t备注'
],
    };
  },
};
</script>
