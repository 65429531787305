<!--
 * @Description: 
 * @Author: your name
 * @Date: 2023-05-04 14:29:34
 * @LastEditors: your name
 * @LastEditTime: 2023-05-18 09:42:20
-->
<template>
  <div>
    <va-aside-layout :title="asideTitle">
      <hap-sickbed-search-show
        v-if="show"
        :item="item"
      ></hap-sickbed-search-show>
      <hap-sickbed-search-form
        v-else
        :id="id"
        width="500px"
        :item="item"
        @saved="onSaved"
        ref="form"
      ></hap-sickbed-search-form>
    </va-aside-layout>
    <v-card :icon="resource.icon" :title="title">
      <va-list
        disable-global-search
        disable-create-redirect
        disable-export
        disable-create
        :filters="filters"
        :filter="filter"
        ref="list"
        @action="onAction"
        :items-per-page="10"
        :include="['bs_hospital']"
        resource="hap_sickbed"
      >
        <!-- <template v-slot:actions>
          <va-action-button
            icon="mdi-briefcase-download-outline"
            label="下载模版"
            :href="template_download_url"
            color="green"
            text
          >
          </va-action-button>
          <va-action-button
            icon="mdi-import"
            color="green"
            label="导入"
            @click="on_show_import"
            text
          >
          </va-action-button>
          <va-action-button
            icon="mdi-arrow-collapse-down"
            color="green"
            label="导出"
            text
            @click="on_export_excel"
          >
          </va-action-button>
        </template> -->
        <va-data-table
          disableShow
          disableClone
          :fields="fields"
          disable-create-redirect
          disable-edit-redirect
          disable-delete-redirect
          disable-edit
          disable-delete
          @item-action="onAction"
          disable-select
        >
          <template v-slot:[`field.state`]="{ item }">
            <template v-if="item.state == 0">
              <span>空位</span>
              <!-- <v-chip class="ma-2" color="green" text-color="white">
                通过
              </v-chip> -->
            </template>
            <template v-else-if="item.state == 1">
              <span>已预约</span> </template
            ><template v-else>
              <span>已入住</span>
            </template>
          </template>
        </va-data-table>
      </va-list>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["resource", "title"],
  data() {
    return {
      // 模板下载地址
      // template_download_url: `${this.global.upload_url}excel/office_import.xlsx`,
      // 弹出侧边栏
      asideTitle: null,
      id: null,
      item: null,
      show: false,
      filters: [
        // {
        //   alwaysOn: true,
        //   label: "所属医院",
        //   source: "hospital_name",
        //   type: "text",
        // },
        {
          alwaysOn: true,
          label: "病区名称",
          source: "ward_name",
          type: "text",
        },
        {
          alwaysOn: true,
          label: "病房名称",
          source: "room_name",
          type: "text",
        },
        {
          alwaysOn: true,
          label: "状态",
          source: "state",
          type: "select",
          // attributes: {
          //   reference: "bs_drug_preparation_type",
          //   itemText: "type_name",
          // },
        },
      ],
      fields: [
        {
          source: "hospital_name",
          label: "所属医院",
          sortable: false,
          align: "center",
        },
        {
          source: "ward_name",
          label: "所属病区",
          sortable: false,
          align: "center",
        },
        {
          source: "room_name",
          label: "所属病房",
          sortable: false,
          align: "center",
        },
        {
          source: "bed_num",
          label: "床位号",
          sortable: false,
          align: "center",
        },
        {
          source: "state",
          label: "状态",
          sortable: false,
          align: "center",
        },
      ],
      filter: {},
      user_data: null,
    };
  },
  created() {
    this.getSupper();
  },
  methods: {
    getSupper() {
      // 医院名称的过滤
      this.user_data = JSON.parse(window.sessionStorage.getItem("user"));
      if (this.user_data.is_superuser) {
        this.filters.push({
          source: "hospital_name",
          label: "所属医院",
          alwaysOn: true,
          type: "text",
        });
      } else {
        this.filter = { hospital_name: this.user_data.hospital_name };
      }
    },
    async onAction({ action, title, id, item }) {
      console.log(1111111111);
      this.asideTitle = title;
      this.id = id;
      this.show = action === "show";
      this.item = item;
      if (action === "create") {
        this.id = null;
      }
    },
    onSaved() {
      this.asideTitle = null;
      this.$refs.list.fetchData();
    },
    // 导入
    on_show_import() {},
    // 导出
    on_export_exce() {},
  },
};
</script>
