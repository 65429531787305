<template>
  <div>
    <va-list
      ref="list"
      disable-create
      disable-global-search
      disable-export
      disable-pagination
      :sort-desc="[true]"
    >
      <template v-slot:actions>
        <va-action-button
          icon="mdi-plus"
          color="success"
          @click="createdrawer"
          style="color: #4caf50"
          text
          label="新建"
        ></va-action-button>
      </template>
      <v-card>
        <v-card-text style="width: 97%; margin: 0 auto">
          <v-row>
            <ul style="padding: 2vw 0.5vw 0.5vw">
              <li><h4>基本信息</h4></li>
            </ul>
          </v-row>
          <v-row>
            <el-table
              :data="tableData"
              style="width: 98%; margin: 0 auto"
              :cell-style="{ textAlign: 'center' }"
              :header-cell-style="{
                background: '#FFFFFF',
                textAlign: 'center',
              }"
            >
              <el-table-column
                v-for="(item, index) in 7"
                :key="index"
                :label="table_label[index]"
              >
                {{ item }}
                <el-table-column label="上午" >
                  <el-table-column label="休">
                    <el-table-column width="69">
                      <template slot-scope="scope">
                        <span>{{
                          scope.row.ws_doctor_schedule_days.data[index]
                            .ws_doctor_work_shifts.data[2].num_count
                        }}</span>
                      </template>
                    </el-table-column>
                  </el-table-column>
                </el-table-column>
                <el-table-column label="下午">
                  <el-table-column label="0/20">
                    <el-table-column label="休" width="69"> </el-table-column>
                  </el-table-column>
                </el-table-column>
                <el-table-column label="晚上">
                  <el-table-column label="休">
                    <el-table-column label="休" width="69"> </el-table-column>
                  </el-table-column>
                </el-table-column>
              </el-table-column>
            </el-table>
          </v-row>
        </v-card-text>
      </v-card>
    </va-list>
  </div>
</template>

<script>
import { BackgroundStyle } from "quill";

export default {
  data() {
    return {
      tableData: [],
      table_label: ["周一", "周二", "周三", "周四", "周五", "周六", "周日"],
      value1: [new Date(2016, 9, 10, 8, 40), new Date(2016, 9, 10, 9, 40)],
    };
  },
  methods: {
    createdrawer() {
      this.$router.push({
        path: "/mt-template-main/create",
        query: { type_id: this.type_fee },
      });
    },
  },
};
</script>

<style scoped>
ul li::marker {
  color: #42b6c4;
}
.row + .row {
  margin-top: 20px;
}
.row > .col {
  padding: 0 12px !important;
}
</style>