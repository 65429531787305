<template>
  <!-- <va-create-layout> -->
    <os-work-scheduling-main-form :title="title" :item="item"></os-work-scheduling-main-form>
  <!-- </va-create-layout> -->
</template>

<script>
export default {
  props: ["title", "item"],
};
</script>
