<template>
  <div>
    <template>
      <div style="display: flex; justify-content: space-between">
        <div class="left" :style="{
          width: '35%',
        }">
          <v-card-text style="width: 97%; margin: 0 auto">
            <v-row>
              <v-col>
                <el-date-picker v-model="date_data" type="month" placeholder="选择月" style="width: 100%" format="yyyy-MM"
                  value-format="yyyy-MM">
                </el-date-picker>
              </v-col>
              <v-col>
                <el-select class="select" clearable filterable v-model="hospital" placeholder="请选择医院"
                  style="width: 100%" :disabled="disabled">
                  <el-option v-for="item in options1" :key="item.id" :label="item.name" :value="item.codenum">
                  </el-option>
                </el-select>
              </v-col>
              <v-col>
                <el-select class="select" clearable filterable v-model="office" placeholder="请选择科室" style="width: 100%">
                  <el-option v-for="item in options2" :key="item.id" :label="item.name" :value="item.codenum">
                  </el-option>
                </el-select>
              </v-col>
            </v-row>

            <v-data-table style="margin: 15px 0" @click:row="rowClicked" :headers="headers" :items="desserts"
              v-model="selected">
            </v-data-table>
          </v-card-text>
        </div>
        <div class="right" :style="{
          width: '64%',
        }">
          <v-sheet height="600" style="margin: 20px">
            <div style="display: flex">
              <v-btn text small color="grey darken-2" @click="prev">
                <v-icon small> mdi-chevron-left </v-icon>
              </v-btn>
              <v-btn text small color="grey darken-2" @click="next">
                <v-icon small> mdi-chevron-right </v-icon>
              </v-btn>
              <v-toolbar-title v-if="$refs.calendar">
                {{ $refs.calendar.title }}
              </v-toolbar-title>
            </div>
            <v-spacer></v-spacer>
            <v-calendar ref="calendar" v-model="focus" color="primary" :events="events" type="month"
              :event-color="getEventColor"></v-calendar>
            <v-menu v-model="selectedOpen" :close-on-content-click="false" :activator="selectedElement" offset-x>
              <v-card color="grey lighten-4" min-width="350px" flat>
                <v-toolbar :color="selectedEvent.color" dark>
                  <v-btn icon>
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon>
                    <v-icon>mdi-heart</v-icon>
                  </v-btn>
                  <v-btn icon>
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-card-text>
                  <span v-html="selectedEvent.details"></span>
                </v-card-text>
                <v-card-actions>
                  <v-btn text color="secondary" @click="selectedOpen = false">
                    Cancel
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-sheet>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: ["type_fee"],
  data() {
    return {
      date_data: null,
      options1: null,
      options2: null,
      hospital: null,
      office: null,
      year: null,
      month: null,
      type_code: null,
      form: {},
      moment,
      desserts: [],
      selected: [],
      headers: [
        { text: "医院名称", value: "hospital_name", sortable: false },
        { text: "科室名称", value: "office_name", sortable: false },
        { text: "医生姓名", value: "doctor_name", sortable: false },
        { text: "执业地点", value: "place_practice", sortable: false },
      ],
      row_info: null,
      focus: "",
      typeToLabel: {
        month: "Month",
        week: "Week",
        day: "Day",
        "4day": "4 Days",
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      colors: ["green", "orange", "blue"],
      names: ["上午", "下午", "晚上"],
      disabled: false,
      user_data: null,
    };
  },
  created() {
    this.user_data = JSON.parse(window.sessionStorage.getItem("user"));
    this.hospital = this.user_data.hospital_code;
    if (this.user_data.is_superuser) {
      this.disabled = false;
    } else {
      this.disabled = true;
    }
  },
  mounted() {
    this.getSelect();
    this.getUser();
    if (this.type_fee) {
      this.$store
        .dispatch("os_scheduling_type/getList", {
          filter: {
            hospital_code: this.user_data.hospital_code,
            type_name: this.type_fee,
          },
        })
        .then((res) => {
          this.type_code = res.data[0].type_code;
          this.form.type_code = this.type_code;
        });
    } else {
      this.$store
        .dispatch("os_scheduling_type/getList", {
          filter: {
            hospital_code: this.user_data.hospital_code,
            type_name: "预约挂号",
          },
        })
        .then((res) => {
          this.type_code = res.data[0].type_code;
          this.form.type_code = this.type_code;
        });
    }
    this.adjustment();
  },
  methods: {
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    getEventColor(event) {
      return event.color;
    },
    getUser() {
      let user = JSON.parse(window.sessionStorage.getItem("user"));
      this.$store
        .dispatch("bs_hospital/getOne", {
          id: user.hospital,
        })
        .then((res) => {
          this.hospital = res.data.codenum;
        });
      this.$store
        .dispatch("bs_office/getOne", {
          id: user.office,
        })
        .then((res) => {
          this.office = res.data.codenum;
        });
      this.date_data = moment().format("YYYY-MM");
    },
    adjustment() {
      console.log(this.form);
      let obj_key = Object.keys(this.form);
      let src_key = "";
      obj_key.forEach((key) => {
        src_key = src_key + "&" + key + "=" + `${this.form[key]}`;
      });
      src_key = src_key.slice(1);
      if (src_key) {
        this.$admin.http
          .get(`outpatient-scheduling/records/?${src_key}`)
          .then((res) => {
            // this.length_data =
            //   (res.data.count / 10) % 1 === 0
            //     ? res.data.count / 10
            //     : parseInt(res.data.count / 10) + 1;
            this.desserts = res.data.results;
          });
      }
    },
    getSelect() {
      this.$store.dispatch("bs_hospital/getList").then((res) => {
        this.options1 = res.data;
      });
      this.$store.dispatch("bs_office/getList").then((res) => {
        this.options2 = res.data;
      });
    },
    rowClicked(row) {
      this.selected = [];
      this.selected.push(row);
      this.$admin.http
        .get(
          `outpatient-scheduling/workschedetails/?hospital_code=${row.hospital_code}&office_code=${row.office_code}&doctor_code=${row.doctor_code}&type_code=${row.type_code}&year_month=${this.date_data}`
        )
        .then((res) => {
          this.events = res.data.results;
          // console.log(this.events);
          this.events.forEach((item) => {
            item.start = item.work_date;
            item.end = item.work_date;
            let noontype = null;
            if (item.noontype_code == "am") {
              item.color = this.colors[0];
              noontype = "上午";
            } else if (item.noontype_code == "pm") {
              item.color = this.colors[1];
              noontype = "下午";
            } else {
              item.color = this.colors[2];
              noontype = "晚上";
            }
            item.name = `${noontype}: ${item.left_num}/${item.num_count}`;
          });
          this.events.sort((a, b) => {
            return a.noontype > b.noontype ? -1 : 1;
          });
          console.log(this.events);
        });
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
  },
  watch: {
    date_data: {
      deep: true,
      handler: function (val) {
        this.form.year_month = val;
        this.adjustment();
        // this.year = val.split("-")[0];
        // this.month = val.split("-")[1];
        // this.form.year = this.year;
        // this.form.month = this.month;
      },
    },
    type_code: {
      deep: true,
      handler: function (val) {
        console.log(1111111111, val);
        this.adjustment();
      },
    },
    office: {
      deep: true,
      handler: function (val) {
        this.form.office_code = val;
        this.adjustment();
      },
    },
    hospital: {
      deep: true,
      handler: function (newVal) {
        if (newVal) {
          this.form.hospital_code = newVal;
          this.adjustment();
          this.$store
            .dispatch("bs_hospital/getList", {
              filter: {
                codenum: newVal,
              },
            })
            .then((res) => {
              this.$store
                .dispatch("bs_office/getList", {
                  filter: {
                    hospital_id: res.data[0].id,
                  },
                })
                .then((data) => {
                  this.options2 = data.data;
                });
            });
        }
      },
    },
  },
};
</script>

<style scoped>
.text-center {
  width: 87%;
  background-color: #fff;
  height: 80px;
  position: fixed;
  bottom: 0px;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

ul li::marker {
  color: #42b6c4;
}

.row+.row {
  margin-top: 0px;
}

.row>.col {
  padding: 0 12px !important;
}

.left {
  width: 30%;
  /* border: 2px solid #ccc; */
  background-color: #fff;
  border-radius: 10px;
}

.center {
  width: 34%;
  /* border: 2px solid #ccc; */
  background-color: #fff;
  border-radius: 10px;
}

.right {
  width: 34%;
  /* border: 2px solid #ccc; */
  background-color: #fff;
  border-radius: 10px;
}

.theme--light /deep/ .v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  color: #000;
}
</style>
<style lang="sass" scoped>
.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined)
  background-color: #eee
  color: #14B2C5
  &:hover
    background-color: #ccc
    color: #214578
.custom_btn.v-item-group
  left: -15px

.v-btn.v-size--default
  margin: 10px 0 !important
  font-size: 1rem
</style>