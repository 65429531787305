<template>
  <va-form :id="id" :item="item">
    <v-card>
      <v-card-text style="width: 97%; margin: 0 auto">
        <v-row>
          <ul style="padding: 2vw 0.5vw 0.5vw">
            <li>基本信息</li>
            <li style="list-style: none; font-size: 10px; color: #999">
              BASIC INFORMATION
            </li>
          </ul>
        </v-row>
        <v-row style="text-alin: center; margin-top: 15px">
          <v-col v-if="user_data.is_superuser">
            <va-select-input label="所属医院" reference="bs_hospital" :items-per-page="1000" :filter="{ is_active: true }"
              source="hospital_id" v-model="form.hospital_id" required></va-select-input>
          </v-col>
          <v-col>
            <FilterInput @office="getOffice" :items="items_data" :label="label_name" :entrust="entrust"
              source="office_id" v-model="form.office_id" reference="bs_office"></FilterInput>
          </v-col>
          <v-col>
            <SelectInput required reference="bs_position_title" :items-per-page="1000" :filter="deep_filter"
              source="doc_rank_id" v-model="form.doc_rank_id" label="医生职称"></SelectInput>
          </v-col>
          <v-col v-if="!user_data.is_superuser"></v-col>
        </v-row>
        <v-row style="text-alin: center; margin-top: 15px">
          <v-col>
            <va-text-input label="医生姓名" source="name" v-model="form.name" required></va-text-input>
            <va-text-input v-model="form.describe" label="医生介绍" source="describe" multiline></va-text-input>
          </v-col>
          <v-col>
            <va-text-input label="医生编号" required source="job_number" v-model="form.job_number"></va-text-input>
            <!-- <div style="margin: 10px 0 30px">上传医生照片</div> -->
            <el-upload :action="action" list-type="picture-card" :on-change="onchange" :file-list="fileList">
              <i class="el-icon-plus"></i>
            </el-upload>
          </v-col>
          <v-col> </v-col>
        </v-row>
      </v-card-text>
      <div class="text-center">
        <va-save-button></va-save-button>
      </div>
    </v-card>

    <v-card style="margin-top: 1vw; margin-bottom: 90px" v-if="this.id">
      <v-card-text style="width: 97%; margin: 0 auto">
        <v-row>
          <ul style="padding: 2vw 0.5vw 0.5vw">
            <li>费用信息</li>
            <li style="list-style: none; font-size: 10px; color: #999">
              CHARGE STANDARD
            </li>
          </ul>
        </v-row>
        <v-row>
          <div style="display: flex; align-items: center; padding-left: 10px">
            <span>是否互联网接诊:</span>
            <v-radio-group v-model="form.is_online_consult" row style="padding-left: 10px">
              <v-radio label="是" value="true"></v-radio>
              <v-radio label="否" value="false"></v-radio>
            </v-radio-group>
          </div>
        </v-row>
        <v-row style="text-alin: center">
          <v-col sm="4" v-for="(item, index) in expense_data" :key="index">
            <v-select filled v-model="select[index]" :items="filteredData(item.code)" :label="item.name"
              item-text="standard_name" item-value="id"></v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <div class="text-center">
        <va-save-button></va-save-button>
      </div>
    </v-card>
    <v-card style="margin-top: 1vw" v-else>
      <v-card-text style="width: 97%; margin: 0 auto">
        <v-row>
          <ul style="padding: 2vw 0.5vw 0.5vw">
            <li>费用信息</li>
            <li style="list-style: none; font-size: 10px; color: #999">
              CHARGE STANDARD
            </li>
          </ul>
        </v-row>
        <v-row>
          <div style="display: flex; align-items: center; padding-left: 10px">
            <span>是否互联网接诊:</span>
            <v-radio-group v-model="form.is_online_consult" row style="padding-left: 10px">
              <v-radio label="是" value="true"></v-radio>
              <v-radio label="否" value="false"></v-radio>
            </v-radio-group>
          </div>
        </v-row>
        <v-row style="text-alin: center">
          <v-col sm="4" v-for="(item, index) in expense_data" :key="index">
            <v-select filled v-model="select[index]" :items="filteredData(item.code)" :label="item.name"
              item-text="standard_name" item-value="id"></v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <div class="text-center">
        <va-save-button></va-save-button>
      </div>
    </v-card>

    <v-card style="margin-top: 1vw; margin-bottom: 90px" v-if="!this.id">
      <v-card-text style="width: 97%; margin: 0 auto">
        <v-row>
          <ul style="padding: 2vw 0.5vw 0.5vw">
            <li>其他</li>
            <li style="list-style: none; font-size: 10px; color: #999">
              OTHER INFORMATION
            </li>
          </ul>
        </v-row>
        <v-row>
          <div style="display: flex; align-items: center; padding-left: 10px">
            <span>是否为医生创建用户:</span>
            <v-radio-group v-model="is_created" row style="padding-left: 10px">
              <v-radio label="是" value="true"></v-radio>
              <v-radio label="否" value="false"></v-radio>
            </v-radio-group>
          </div>
        </v-row>
        <v-row style="text-alin: center" v-if="is_show">
          <v-col>
            <va-text-input label="用户名" v-model="user_form.username"></va-text-input>
          </v-col>
          <v-col>
            <va-text-input label="姓名" v-model="user_form.name"></va-text-input>
          </v-col>
          <v-col>
            <v-autocomplete v-model="user_roles" :items="auth_roles" filled color="blue-grey lighten-2" label="启用角色"
              item-text="role_name" item-value="group_id" multiple>
              <template v-slot:selection="data">
                <v-chip v-model="data.attrs" :input-value="data.selected" close @click="data.select"
                  @click:close="remove(data.item)">
                  {{ data.item.role_name }}
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <div class="text-center">
      <v-btn @click="close" style="color: #42b6c4; margin-right: 50px">取消</v-btn>
      <v-btn @click="isSure" style="background-color: #42b6c4; margin-right: 50px">保存</v-btn>
    </div>
  </va-form>
</template>

<script>
import moment from "moment";
import SelectInput from "@/components/VaCustomSelectInput.vue";
import FilterInput from "@/components/FilterData.vue";
export default {
  props: ["id", "title", "item"],
  components: { SelectInput, FilterInput },
  data() {
    return {
      select: [],
      items: null,
      auth_roles: null,
      form: {
        hospital_id: null,
        office_id: null,
        doc_rank_id: null,
        name: null,
        job_number: null,
        describe: null,
        is_online_consult: "true",
      },
      user_roles: [],
      user_form: {
        username: null,
        name: null,
      },
      is_created: "true",
      is_show: true,
      expense_data: null,
      imgfile: null,
      fileList: [],
      imageInfo: "",
      file_data: null,
      action: null,
      user_data: null,
      items_data: null,
      label_name: "所属科室",
      entrust: null,
    };
  },
  created() {
    this.user_data = JSON.parse(window.sessionStorage.getItem("user"));
    if (!this.user_data.is_superuser) {
      this.form.hospital_id = this.user_data.hospital;
    }
  },
  async mounted() {
    this.action = `${this.global.url}/tools-collection/images_upload/`;
    if (this.id) {
      this.form.is_online_consult = this.item.is_online_consult + "";
    }
    if (this.id) {
      this.entrust = this.item.office_id
    }
    this.$store
      .dispatch("ws_appointment_type_name/getList", {
        sort: [{ by: "id", desc: true }],
      })
      .then((data) => {
        this.expense_data = data.data;
        this.getExpense();
      });

    this.get_roles();
  },
  methods: {
    getOffice(msg) {
      this.form.office_id = msg.id;
    },
    async getExpense() {
      if (this.id) {
        for (let i = 0; i < this.expense_data.length; i++) {
          this.select.push(null);
        }
        let select_data = [];
        let { data: expense } = await this.$store.dispatch(
          "bs_expense_standard_doctors/getList",
          {
            filter: {
              doctor_id: this.id,
            },
          }
        );
        expense.forEach((res) => {
          select_data.push(res.expensestandard_id);
        });
        this.$store
          .dispatch("bs_expense_standard/getList", {
            filter: {
              id: select_data,
            },
            sort: [{ by: "expense_type", desc: true }],
          })
          .then((res) => {
            res.data.forEach((data) => {
              let index = this.expense_data.findIndex((item) => {
                return item.code == data.expense_type;
              });
              this.select.splice(index, 1, data.id);
            });
          });
      } else {
        return;
      }
    },
    filteredData(data) {
      if (this.items) {
        return this.items.filter((item) => item.expense_type == data);
      }
    },
    close() {
      this.$router.push("/bs-doctor");
    },
    onchange(file, fileList) {
      this.file_data = fileList;
      const imgType =
        file.raw.type === "image/png" || file.raw.type === "image/jpeg";
      const imgSize = file.raw.size / 1024 / 1024 <= 2;
      if (!imgType) {
        this.$message.error("只能上传png、jpg、jpeg的图片格式！");
        return false;
      }
      if (!imgSize) {
        this.$message.error("图片上传最大限制2MB！");
        return false;
      }
      //如果第二次上传文件，将前一个文件删除
      //这样fileList就一直保持一个文件
      if (fileList.length > 1) {
        fileList.splice(0, 1);
      }
      //获取当前文件的一个内存URL
      //给图片src赋值
      this.imgfile = file;
      this.imgUrl = URL.createObjectURL(file.raw);
    },

    // Element 上传
    httpRequest(file, id, model) {
      var formData = new FormData();
      formData.append("belong_id", id);
      formData.append("belong_model", model);
      formData.append("big_image", file.raw);
      this.$admin.http
        .post("/tools-collection/images_upload/", formData)
        .then((img) => { });
    },
    // 修改图片
    reviseImg(imgid, file, id, model) {
      var formData = new FormData();
      formData.append("belong_id", id);
      formData.append("belong_model", model);
      formData.append("big_image", file.raw);
      this.$admin.http
        .put(`/tools-collection/images_upload/${imgid}/`, formData)
        .then((img) => { });
    },
    async isSure() {
      if (
        !this.form.hospital_id ||
        !this.form.office_id ||
        !this.form.doc_rank_id ||
        !this.form.name ||
        !this.form.job_number
      ) {
        this.$message({
          message: "有必填项未填写",
          type: "warning",
        });
        return;
      }
      if (!this.id) {
        await this.$store
          .dispatch("bs_doctor/create", {
            data: this.form,
          })
          .then((res) => {
            if (this.is_created == "true") {
              this.user_form.doctor = res.data.id;
              this.user_form.hospital = res.data.hospital_id;
              this.user_form.office = res.data.office_id;
              this.user_form.user_rank = res.data.doc_rank_id;
              this.user_form.email = "111@qq.com";
              this.user_form.password = `${this.user_form.username}@1234`;
              this.user_form.date_joined = moment().format(
                "YYYY-MM-DD HH:mm:ss"
              );
              if (!this.user_form.username || !this.user_form.name) {
                this.$message({
                  message: "用户信息未填写,用户未创建成功,请自行创建并绑定！",
                  type: "error",
                });
                this.close();
                return;
              }
              this.$admin.http
                .post(`base-system/users/`, this.user_form)
                .then((res) => {
                  this.$store.dispatch("base_system_user_groups/create", {
                    data: this.user_roles.map((group_id) => {
                      return {
                        user_id: res.data.id,
                        group_id: group_id,
                      };
                    }),
                  });
                });
              // this.$store
              //   .dispatch("base_system_user/create", {
              //     data: this.user_form,
              //   })
              //   .then((res) => {
              //     this.$store.dispatch("base_system_user_groups/create", {
              //       data: this.user_roles.map((group_id) => {
              //         return {
              //           user_id: res.data.id,
              //           group_id: group_id,
              //         };
              //       }),
              //     });
              //   });
            }

            if (this.form.is_online_consult == "true") {
              this.select.forEach((item) => {
                this.$store.dispatch("bs_expense_standard_doctors/create", {
                  data: {
                    doctor_id: res.data.id,
                    expensestandard_id: item,
                  },
                });
              });
            }
            if (this.file_data !== null && this.file_data.length >= 1) {
              this.httpRequest(this.imgfile, res.data.id, res.data.photo);
            }
            this.close();
          })
          .catch((err) => {
            // this.$message({
            //   message: "医生编码违反唯一性，请修改",
            //   type: "error",
            // });
          });
      } else {
        let { data: doctor_id } = await this.$store.dispatch(
          "bs_expense_standard_doctors/getList",
          {
            filter: {
              doctor_id: this.id,
            },
          }
        );
        await this.$store.dispatch("bs_expense_standard_doctors/deleteMany", {
          ids: doctor_id.map((o) => o.id),
        });
        this.select.forEach((item) => {
          this.$store.dispatch("bs_expense_standard_doctors/create", {
            data: {
              doctor_id: this.id,
              expensestandard_id: item,
            },
          });
        });
        await this.$store
          .dispatch("bs_doctor/update", {
            id: this.id,
            data: this.form,
          })
          .then((res) => {
            this.close();
            if (this.file_data !== null && this.file_data.length >= 1) {
              if (this.imageInfo[0]) {
                this.reviseImg(
                  this.imageInfo[0].id,
                  this.imgfile,
                  this.id,
                  res.data.photo
                );
              } else {
                this.httpRequest(this.imgfile, this.id, res.data.photo);
              }
            }
          })
          .catch((err) => {
            // this.$message({
            //   message: "医生编码违反唯一性，请修改",
            //   type: "error",
            // });
          });
      }
    },
    // 获取所有角色列表
    async get_roles() {
      let roles = await this.$store.dispatch("bs_extra_group/getList");
      this.auth_roles = roles.data;
    },
    // 下拉框删除已选中
    remove(item) {
      this.user_roles.forEach((data, index) => {
        if (data == item.group_id) {
          this.user_roles.splice(index, 1);
        }
      });
    },
  },
  computed: {
    deep_filter() {
      if (this.form.hospital_id) {
        return { hospital_id: this.form.hospital_id, is_active: true };
      } else {
        return {};
      }
    },
  },
  watch: {
    "form.hospital_id": {
      deep: true,
      handler: function (newVal) {
        if (newVal) {
          this.$store
            .dispatch("bs_office/getList", {
              filter: {
                hospital_id: newVal,
              },
            })
            .then((res) => {
              res.data.forEach((item) => {
                item.text = item.name;
              });
              this.items_data = res.data;
            });
        } else {
          this.$store
            .dispatch("bs_office/getList")
            .then((res) => {
              res.data.forEach((item) => {
                item.text = item.name;
              });
              this.items_data = res.data;
            });
        }
        this.$store
          .dispatch("bs_expense_standard/getList", {
            filter: {
              hospital_id: newVal,
            },
          })
          .then((res) => {
            this.items = res.data;
          });
        this.$store
          .dispatch("bs_extra_group/getList", {
            filter: {
              hospital_id: newVal,
            },
          })
          .then((res) => {
            this.auth_roles = res.data;
          });
      },
    },
    is_created: {
      deep: true,
      handler: function (newVal) {
        newVal == "false" ? (this.is_show = false) : (this.is_show = true);
      },
    },
    id: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
          this.$store
            .dispatch("bs_doctor/getList", {
              filter: {
                id: val,
              },
              include: ["doctor_photo"],
            })
            .then((res) => {
              this.imageInfo = res.data[0].doctor_photo;
              this.fileList = [];
              this.imageInfo.forEach((img) => {
                this.fileList.push({
                  url: `${this.global.url}/${img.big_image}`,
                });
              });
            });
        }
      },
    },
  },
};
</script>

<style scoped>
ul li::marker {
  color: #42b6c4;
}

.row+.row {
  margin-top: 0px;
}

.row>.col {
  padding: 0 12px !important;
}

.text-center {
  width: 87%;
  background-color: #fff;
  height: 80px;
  position: fixed;
  bottom: 0px;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>