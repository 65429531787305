<template>
	<va-form :id="id" :item="item">
		<v-card class="v_card">
			<v-card-text style="width: 97%; margin: 0 auto">
				<v-row>
					<ul style="padding: 1vw 0 1vw 0.5vw">
						<li>服务组管理</li>
						<li style="list-style: none; font-size: 10px; color: #999">
							SERVICE GROUP MANAGEMENT
						</li>
						<li style="list-style: none; font-size: 10px; color: #999">
							TYPE
						</li>
					</ul>
				</v-row>
				<v-row>
					<v-col sm="3">
						<va-text-input source="name" v-model="xman.name"></va-text-input>
					</v-col>
					<v-col sm="3">
						<va-select-input source="service_group_type_id" reference="ig_service_group_type" label="分类"
							v-model="xman.service_group_type"></va-select-input>
					</v-col>
				</v-row>
				<v-row>
					<ul style="padding: 1vw 0 1vw 0.5vw">
						<li>科室信息配置</li>
						<li style="list-style: none; font-size: 10px; color: #999">
							DEPARTMENT INFORMATION CONFIGURATION
						</li>
					</ul>
				</v-row>
				<v-row justify="center">
					<v-col sm="3">
						<va-text-input source="office_code" v-model="xman.office_code"></va-text-input>
					</v-col>
					<v-col sm="3">
						<va-text-input source="office_name" v-model="xman.office_name"></va-text-input>
					</v-col>
					<v-col sm="3">
						<va-text-input source="exec_office_code" v-model="xman.exec_office_code"></va-text-input>
					</v-col>
					<v-col sm="3">
						<va-text-input source="exec_office_name" v-model="xman.exec_office_name"></va-text-input>
					</v-col>
				</v-row>
				<v-row>
					<ul style="padding: 1vw 0 1vw 0.5vw">
						<li>服务组地址与描述</li>
						<li style="list-style: none; font-size: 10px; color: #999">
							SERVICE GROUP ADDRESS AND DESCRIPTION
						</li>
					</ul>
				</v-row>
				<v-row>
					<v-col sm="3">
						<va-text-input source="work_desc" v-model="xman.work_desc"></va-text-input>
					</v-col>
					<v-col sm="3">
						<va-text-input source="work_addr" v-model="xman.work_addr"></va-text-input>
					</v-col>
				</v-row>
				<v-row>
					<ul style="padding: 1vw 0 1vw 0.5vw">
						<li>预约方式与优先级</li>
						<li style="list-style: none; font-size: 10px; color: #999">
							APPOINTMENT METHOD AND PRIORITY
						</li>
					</ul>
				</v-row>
				<v-row>
					<v-col cols="3">
						<va-number-input source="appointment_hour_line" v-model="xman.appointment_hour_line" :step="1"
							:min="1" :max="365"></va-number-input>
					</v-col>
					<v-col cols="3">
						<va-number-input source="service_priority" v-model="xman.service_priority" :step="1" :min="1"
							:max="365"></va-number-input>
					</v-col>
					<v-col cols="3">
						<v-select filled label="预约方式" :items="appointmentOptions" item-text="text" item-value="value"
							v-model="xman.appointment_way"></v-select>
					</v-col>
				</v-row>
				<v-row>
					<ul style="padding: 1vw 0 1vw 0.5vw">
						<li>可用的诊疗类型</li>
						<li style="list-style: none; font-size: 10px; color: #999">
							Available types of diagnosis and treatment
						</li>
					</ul>
				</v-row>
				<v-row>
					<v-col sm="3">
						<v-container fluid style="display: flex">
							<v-checkbox label="门诊" v-model="xman.treatment_available_type_out"></v-checkbox>
							<v-checkbox label="住院" v-model="xman.treatment_available_type_in"></v-checkbox>
						</v-container>
					</v-col>
					<!-- <v-col cols="3">
            <va-boolean-input
              label="门诊"
              v-model="xman.treatment_available_type_out"
              source="treatment_available_type_out"
            ></va-boolean-input>
          </v-col>
          <v-col cols="3">
            <va-boolean-input
              label="住院"
              v-model="xman.treatment_available_type_in"
              source="treatment_available_type_in"
            ></va-boolean-input>
          </v-col> -->
				</v-row>
				<v-row>
					<ul style="padding: 1vw 0 1vw 0.5vw">
						<li>门诊可预约配置</li>
						<li style="list-style: none; font-size: 10px; color: #999">
							Outpatient appointment configuration
						</li>
					</ul>
				</v-row>
				<v-row>
					<v-col sm="3">
						<v-container fluid style="display: flex">
							<v-checkbox label="门诊可预约" v-model="xman.appointment_way_out"></v-checkbox>
							<v-checkbox label="医生可预约" v-model="xman.appointment_way_out_doctor"></v-checkbox>
						</v-container>
					</v-col>
					<!-- <v-col sm="3">
            <va-boolean-input
              label="门诊可预约"
              v-model="xman.appointment_way_out"
              source="appointment_way_out"
            ></va-boolean-input>
          </v-col>
          <v-col sm="3">
            <va-boolean-input
              label="医生可预约"
              v-model="xman.appointment_way_out_doctor"
              source="appointment_way_out_doctor"
            ></va-boolean-input>
          </v-col> -->
					<v-col sm="3">
						<va-number-input source="appointment_way_out_days" v-model="xman.appointment_way_out_days"
							:step="1" :min="1" :max="365"></va-number-input>
					</v-col>
				</v-row>
				<v-row>
					<ul style="padding: 1vw 0 1vw 0.5vw">
						<li>住院可预约配置</li>
						<li style="list-style: none; font-size: 10px; color: #999">
							Hospitalization reservation configuration
						</li>
					</ul>
				</v-row>
				<v-row>
					<v-col sm="3">
						<v-container fluid style="display: flex">
							<v-checkbox label="住院可预约" v-model="xman.appointment_way_in"></v-checkbox>
							<v-checkbox label="医生可预约" v-model="xman.appointment_way_in_doctor"></v-checkbox>
						</v-container>
					</v-col>
					<!-- <v-col sm="3">
            <va-boolean-input
              label="住院可预约"
              v-model="xman.appointment_way_in"
              source="appointment_way_in"
            ></va-boolean-input>
          </v-col>
          <v-col sm="3">
            <va-boolean-input
              label="医生可预约"
              v-model="xman.appointment_way_in_doctor"
              source="appointment_way_in_doctor"
            ></va-boolean-input>
          </v-col> -->
					<v-col sm="3">
						<va-number-input source="appointment_way_in_days" v-model="xman.appointment_way_in_days"
							:step="1" :min="1" :max="365"></va-number-input>
					</v-col>
				</v-row>
				<v-row>
					<ul style="padding: 1vw 0 1vw 0.5vw">
						<li>其他配置</li>
						<li style="list-style: none; font-size: 10px; color: #999">
							Other configurations
						</li>
					</ul>
				</v-row>
				<v-row style="display: flex; align-items: center">
					<!-- <v-col sm="3">
            <va-boolean-input
              label="是否自动分诊"
              source="automatic_segmentation"
              v-model="xman.automatic_segmentation"
            ></va-boolean-input>
          </v-col>
          <v-col sm="3">
            <va-boolean-input
              label="是否取消后释放资源"
              source="release_resource"
              v-model="xman.release_resource"
            ></va-boolean-input>
          </v-col>
          <v-col sm="3">
            <va-boolean-input
              label="门诊是否可打印指引单"
              v-model="xman.guide_print_out"
              source="guide_print_out"
            ></va-boolean-input>
          </v-col>
          <v-col sm="3">
            <va-boolean-input
              label="住院是否可打印指引单"
              v-model="xman.guide_print_in"
              source="guide_print_in"
            ></va-boolean-input>
          </v-col> -->
					<v-col sm="6">
						<v-container fluid style="display: flex">
							<v-checkbox label="是否自动分诊" source="automatic_segmentation"
								v-model="xman.automatic_segmentation"></v-checkbox>
							<v-checkbox label="是否取消后释放资源" source="release_resource"
								v-model="xman.release_resource"></v-checkbox>
							<v-checkbox label="门诊是否可打印指引单" v-model="xman.guide_print_out"
								source="guide_print_out"></v-checkbox>
							<v-checkbox label="住院是否可打印指引单" v-model="xman.guide_print_in"
								source="guide_print_in"></v-checkbox>
						</v-container>
					</v-col>
					<v-col sm="3">
						<va-text-input source="remark" v-model="xman.remark"></va-text-input>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
		<div class="text-center">
			<v-btn @click="notSure" style="color: #42b6c4; margin-right: 50px">返回</v-btn>
			<v-btn @click="isSure" style="background-color: #42b6c4; margin-right: 50px">保存</v-btn>
		</div>
	</va-form>
</template>

<script>
	export default {
		props: ["id", "title", "item"],
		data() {
			return {
				xman: {
					name: "",
					appointment_way: 0,
					appointment_way_out: false,
					appointment_way_out_doctor: false,
					appointment_way_out_days: 0,
					appointment_way_in: false,
					appointment_way_in_doctor: false,
					appointment_way_in_days: 0,
					automatic_segmentation: false,
					release_resource: false,
					guide_print_out: false,
					guide_print_in: false,
					appointment_hour_line: 0,
					service_priority: 0,
					service_group_type: null,
					treatment_available_type_in: false,
					treatment_available_type_out: false,
					is_active: true,
					template_id: 0,
					template_name: "",
					remark: "",
				},
				appointmentOptions: [{
						text: "单时间段/次",
						value: 0
					},
					{
						text: "部位时间点/次",
						value: 1
					},
					{
						text: "单时间点/次",
						value: 2
					},
				],
				// ... 你的其他数据属性 ...
				menu: false,
				date: null, // 用于v-date-picker的模型
				time: null, // 用于v-time-picker的模型
				// ... 你的其他数据属性 ...
			};
		},
		mounted() {
			// this.xman = this.item
			// console.log(this.item);
		},
		methods: {
			notSure() {
				this.$router.push("/ig-service-group");
			},
			async isSure() {
				console.log('23455');
				this.xman.appointment_way_out_days = Number(
					this.xman.appointment_way_out_days
				);
				this.xman.service_priority = Number(this.xman.service_priority);
				this.xman.appointment_hour_line = Number(this.xman.appointment_hour_line);
				this.xman.template_id = Number(this.xman.template_id);
				this.xman.appointment_way_out_days = Number(
					this.xman.appointment_way_out_days
				);
				this.xman.appointment_way_in_days = Number(
					this.xman.appointment_way_in_days
				);
				if (this.xman.appointment_way_out_days < 0) {
					this.xman.appointment_way_out_days = null;
					this.$message({
						message: "门诊可预约天数仅限输入数字，且大于等于0，请修改",
						type: "error",
					});
					return;
				}
				this.xman.appointment_way_in_days = Number(
					this.xman.appointment_way_in_days
				);
				if (this.xman.appointment_way_in_days < 0) {
					this.xman.appointment_way_in_days = null;
					this.$message({
						message: "住院可预约天数仅限输入数字，且大于等于0，请修改",
						type: "error",
					});
					return;
				}
				if (this.id) {
					try {
						await this.$store
							.dispatch("ig_service_group/update", {
								id: this.id,
								data: this.xman,
							})
							.then((res) => {
								this.$emit("saved");
								this.$router.push({
									path: "/ig-service-group/",
								});
							});
					} catch {
						// this.$message({
						//   message: "医院编码违反唯一性，请修改",
						//   type: "error",
						// });
					}
				} else {
					this.xman.create_time = new Date().toISOString();
					await this.$store
						.dispatch("ig_service_group/create", {
							data: this.xman,
						})
						.then((res) => {
							this.$emit("saved");
							this.$router.push({
								path: "/ig-service-group/",
							});
							// if (this.file_data && this.file_data.length >= 1) {
							//   this.httpRequest(this.imgfile, res.data.id, res.data.logo);
							// }
						})
						.catch((err) => {
							// this.$message({
							//   message: "医院编码违反唯一性，请修改",
							//   type: "error",
							// });
						});
				}
			},
			close() {},
		},
	};
</script>

<style scoped>
	/deep/ .v-input--selection-controls {
		margin-right: 25px;
	}

	.v_card {
		padding: 10px;
		margin-bottom: 100px;
	}

	.red_sp {
		color: red;
	}

	.dot_circle {
		display: inline-block;
		background-color: #069cae;
		width: 8px;
		height: 8px;
		margin-right: 10px;
		border-radius: 8px;
		margin-bottom: 2px;
	}

	.title_con {
		display: inline-block;
		font-size: 20px;
	}

	.text-center {
		width: 87%;
		background-color: #fff;
		height: 80px;
		position: fixed;
		bottom: 0px;
		right: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 2;
	}

	ul li::marker {
		color: #42b6c4;
	}

	.row+.row {
		margin-top: 0px;
	}

	.row>.col {
		padding: 0 12px !important;
	}

	.text-center {
		width: 87%;
		background-color: #fff;
		height: 80px;
		position: fixed;
		bottom: 0px;
		right: 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}
</style>