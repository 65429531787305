<template>
  <va-form :id="id" :item="item" @saved="$emit('saved')">
    <v-card-text style="padding-bottom: 50px">
      <va-select-input
        required
        source="pharmacy_id"
        item-text="pharmacy_name"
        reference="bs_pharmacy_management"
        :items-per-page="1000"
        :filter="{ is_active: true }"
      ></va-select-input>
      <va-text-input source="drug_name" required></va-text-input>
      <va-text-input source="drug_code" required></va-text-input>
      <va-text-input source="standards" required></va-text-input>
      <va-select-input
        required
        label="药品类型"
        source="drug_type_id"
        item-text="name"
        reference="bs_drug_type"
        :items-per-page="1000"
        :filter="{ is_active: true }"
      ></va-select-input>
      <va-select-input
        required
        source="preparation_type_id"
        item-text="type_name"
        reference="bs_drug_preparation_type"
        :items-per-page="1000"
        :filter="{ is_active: true }"
      ></va-select-input>
      <va-select-input
        required
        source="category_id"
        reference="bs_drug_category"
        item-text="category_name"
        :items-per-page="1000"
        :filter="{ is_active: true }"
      ></va-select-input>
      <va-text-input source="origin_place" required></va-text-input>
      <va-text-input source="manufacturer" required></va-text-input>
      <va-date-input
      source="valid_date"
      v-model="valid_date"
      format="short"
    ></va-date-input>
      <va-text-input source="inventory_quantity" required></va-text-input>
      <va-text-input source="measurement_unit" required></va-text-input>
      <va-text-input source="cost_unit_price" required></va-text-input>
      <va-text-input source="cost_amount" required></va-text-input>
      <va-text-input source="retail_unit_price" required></va-text-input>
      <va-text-input source="retail_amount"></va-text-input>
      <!-- <va-select-input
        reference="bs_hospital"
        :items-per-page="1000"
        :filter="{ is_active: true }"
        source="hospital_id"
        v-model="hospital"
      ></va-select-input> -->
      <va-boolean-input source="is_active"></va-boolean-input>
    </v-card-text>
    <div class="btn_bg">
      <va-save-button></va-save-button>
    </div>
  </va-form>
</template>

<script>
export default {
  props: ["id", "title", "item"],
  data() {
    return {
      valid_date: null,
    }
  },
  mounted() {
    console.log(this.item);
  }

};
</script>
<style scoped>
.v-application--is-ltr .v-input__prepend-outer {
  display: none;
}
.btn_bg {
  width: 95%;
  position: fixed;
  bottom: 0px;
  padding: 10px 0;
  text-align: center;
  background-color: white;
}
</style>