<template>
	<v-navigation-drawer :clipped="$vuetify.breakpoint.lgAndUp" app :color="color" :mini-variant="miniVariant"
		:value="value" @input="(v) => $emit('input', v)" :width="width" class="menuWidth">
		<el-menu class="sidebar-el-menu" :default-active="this.$router.path" :collapse="collapse"
			background-color="#42b6c4" text-color="#fff" active-text-color="#bfcbd9" unique-opened router>
			<!-- 第一层 -->
			<template v-for="item in items">
				<!-- 如果第一层有子菜单，则继续循环 -->
				<template v-if="item.children.length">
					<el-submenu :index="item.name" :key="item.id">
						<template slot="title">
							<i :class="item.icon_class" style="color:white"></i>
							<span v-if="miniVariant==false" slot="title">{{ item.name }}</span>
						</template>
						<!-- 第二层 -->
						<template v-for="subItem in item.children">
							<!-- 如果第二层有子菜单，则继续循环 -->
							<template v-if="subItem.children.length">
								<el-submenu :index="subItem.name" :key="subItem.id">
									<template slot="title">
										<i :class="subItem.icon_class" style="color:white"></i>
										<span slot="title">{{ subItem.name }}</span>
									</template>
									<!-- <el-menu-item v-for="(threeItem,i) in subItem.children" :key="i" :index="threeItem.index">{{ threeItem.name }}</el-menu-item> -->
									<!-- 第三层 -->
									<template v-for="subItem2 in subItem.children">
										<!-- 如果第三层有子菜单，则继续循环 -->
										<template v-if="subItem2.children.length">
											<el-submenu :index="subItem2.name" :key="subItem2.id">
												<template slot="title">
													<i :class="subItem2.icon_class" style="color:white"></i>
													<span slot="title">{{ subItem2.name }}</span>
												</template>
												<!-- <el-menu-item v-for="(fourItem,i) in subItem2.children" :key="i" :index="fourItem.index">{{ fourItem.name }}</el-menu-item> -->
												<!-- 第四层 -->
												<template v-for="subItem3 in subItem2.children">
													<!-- 如果第四层有子菜单，则继续循环 -->
													<template v-if="subItem3.children.length">
														<el-submenu :index="subItem3.path" :key="subItem3.id"
															style="padding-left:100px">
															<template slot="title">
																<i :class="subItem3.icon_class" style="color:white"></i>
																<!-- <span slot="title">{{ subItem2.name }}</span> -->
																{{ subItem3.name }}
															</template>
															<el-menu-item v-for="(fiveItem, i) in subItem3.children"
																:key="i" :index="fiveItem.path">
																<i :class="fiveItem.icon_class" style="color:white"></i>
																{{ fiveItem.name }}</el-menu-item>
														</el-submenu>
													</template>
													<!-- 如果第四层没有子菜单 -->
													<el-menu-item v-else :index="subItem3.path" :key="subItem3.id"
														style="padding-left:100px"
														@click.native.stop="getmeatus(subItem3)">
														<i :class="subItem3.icon_class" style="color:white"></i>
														{{ subItem3.name }}</el-menu-item>
												</template>
											</el-submenu>
										</template>
										<!-- 如果第三层没有子菜单 -->
										<el-menu-item v-else :index="subItem2.path" :key="subItem2.id"
											@click.native.stop="getmeatus(subItem2)">
											<i :class="subItem2.icon_class" style="color:white"></i>
											{{ subItem2.name }}</el-menu-item>
									</template>
								</el-submenu>
							</template>
							<!-- 如果第二层没有子菜单 -->
							<el-menu-item v-else :index="subItem.path" :key="subItem.path"
								@click.native.stop="getmeatus(subItem)">
								<i :class="subItem.icon_class" style="color:white"></i>
								{{ subItem.name }}</el-menu-item>
						</template>
					</el-submenu>
				</template>
				<!-- 如果第一层没有子菜单 -->
				<template v-else>
					<el-menu-item :index="item.path" :key="item.path" @click.native.stop="getmeatus(item)">
						<i :class="item.icon_class" style="color:white"></i>
						<span v-if="miniVariant==false" slot="title">{{ item.name }}</span>
					</el-menu-item>
				</template>
			</template>
		</el-menu>
	</v-navigation-drawer>
</template>
<script>
	/**
	 * Default customizable admin VNavigationDrawer with full hierarchical menu and minimize variant.
	 */
	import stringToArray from "lodash/_stringToArray";
	import {
		mapState
	} from "vuex";
	export default {

		props: {
			pathnow: {
				type: String,
			},
			menu: {
				type: Array,
				default: () => [],
			},
			miniVariant: Boolean,
			color: {
				type: String,
				default: "white",
			},
			dark: Boolean,
			value: null,
			width: {
				type: Number,
				default: 260,
			},
		},
		data() {
			return {
				collapse: false,
				items: [],

			};
		},
		computed: {
			...mapState({
				user: (state) => state.auth.user,
			}),
		},
		methods: {
			getmeatus(data) {
				// this.$emit('menuChange', data) // 启动报错，因此替换 李鑫鑫
				this.$emit('menu-change', data);
			},
			async getNav() {
				let {
					data
				} = await this.$admin.http.get(
					"/base-system/own-menu/?user_id=" + this.user.id
				);
				this.items = data.data;
			},
		},
		mounted() {
			this.$router.path = this.$route.path;
			this.getNav();
		},
		watch: {
			pathnow: {
				// deep: true,
				// immediate: true,
				handler(val) {
					this.$router.path = val;
					this.getNav();
				},
			},
			miniVariant: {
				// deep: true,
				// immediate: true,
				handler(val) {
					this.collapse = val;
				},
			},
		},
		beforeCreate() {},
	};
</script>



<style scoped>
	.sidebar {
		display: block;
		position: absolute;
		left: 0;
		top: 70px;
		bottom: 0;
		/* overflow: scroll; */
	}

	.sidebar::-webkit-scrollbar {
		width: 0;
	}

	.sidebar-el-menu:not(.el-menu--collapse) {
		height: 100%;
		width: 260px;
		overflow: hidden;
		overflow: auto;
	}

	.sidebar>ul {
		height: 100%;
	}

	.el-menu-item.is-active {
		background-color: #2f858e !important;
	}

	.el-menu-item:hover {
		background-color: #2f858e !important;
	}

	.v-navigation-drawer--open,
	.theme--dark.v-navigation-drawer {
		background-color: #42b6c4 !important;
	}
</style>