<!--
 * @Description: 
 * @Author: your name
 * @Date: 2023-05-08 14:31:12
 * @LastEditors: your name
 * @LastEditTime: 2023-05-24 17:25:42
-->
<template>
  <va-show :item="item" @saved="$emit('saved')">
    <va-text-input
      label="驳回原因"
      source="reason"
      v-model="reason"
      multiline
    ></va-text-input>

    <div class="btn_bg">
      <v-btn @click="isSure" color="primary">提交</v-btn>
    </div>
  </va-show>
</template>

<script>
import moment from "moment";

export default {
  props: ["title", "item"],
  data() {
    return {
      reason: "",
      approval: "",
    };
  },
  created() {
    this.approval = JSON.parse(window.sessionStorage.getItem("user")).name;
  },
  methods: {
    isSure() {
      if (!this.reason) {
        this.$message({
          message: "有必填项未填写",
          type: "warning",
        });
        return;
      }
      let items = this.item;
      items.state = "2";
      items.note = this.reason;
      items.approved_by = this.approval;
      items.approval = JSON.parse(window.sessionStorage.getItem("user")).name;
      items.approved_time = moment().format("YYYY-MM-DD HH:mm:ss");
      this.$admin.http
        .post(
          `hospitalization_apt/bed_appointment_record/${this.item.id}/bed_reservation_approval/`,
          items
        )
        .then((res) => {
          this.$emit("saved");
        });
      // this.$store
      //   .dispatch("hap_bed_apt_record/update", {
      //     id: this.item.id,
      //     data: { state: "2", note: this.reason },
      //   })
      //   .then((res) => {
      //     console.log(res);

      //   });
    },
    // goCreat(item) {
    //   console.log(item, "showshow");
    //   this.$router.push({
    //     path: `hap-bed-apt-record/${item.id}/edit`,
    //     query: { type: 3 },
    //   });
    // },
  },
};
</script>
<style scoped>
.btn_bg {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0px;
  padding: 10px 10px;
  background-color: white;
}
</style>
