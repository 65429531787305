<template>
  <va-form :id="id" :item="item" @saved="$emit('saved')" ref="form">
    <v-card-text>
      <va-select-input
        required
        label="所属医院"
        item-value="codenum"
        reference="bs_hospital"
        :items-per-page="1000"
        source="hospital_code"
        v-model="form.hospital_code"
      ></va-select-input>
      <!-- <SelectInput
        required
        label="所属科室"
        item-value="codenum"
        reference="bs_office"
        :items-per-page="1000"
        ref="dept"
        source="office_code"
        :filter="dept_filter"
        v-model="form.office_code"
      ></SelectInput> -->
      <va-text-input
        required
        label="类型编码"
        source="type_code"
        v-model="form.type_code"
      ></va-text-input>
      <va-text-input
        label="类型名称"
        required
        source="type_name"
        v-model="form.type_name"
      ></va-text-input>
    </v-card-text>
    <div class="btn_bg">
      <v-btn @click="isSure" color="primary">保存</v-btn>
    </div>
  </va-form>
</template>

<script>
import moment from "moment";
// import SelectInput from "@/components/VaCustomSelectInput.vue";
export default {
  props: ["id", "title", "item", "type"],
  // components: { SelectInput },
  data() {
    return {
      form: {
        type_code: null,
        type_name: null,
        hospital_code: null,
        // office_code: null,
      },
      hospitalId: null,
      options: null,
    };
  },
  mounted() {
    console.log(this.id,this.item);
    if (!this.id) {
      this.form.created_time = moment().format("YYYY-MM-DD HH:mm:ss");
      this.form.updated_time = moment().format("YYYY-MM-DD HH:mm:ss");
    } else {
      if(this.item){
        console.log(1111111);
        // this.form.office_code = this.item.office_code
        this.form.created_time = this.item.created_time;
        this.form.updated_time = moment().format("YYYY-MM-DD HH:mm:ss");
      }
    }
  },
  watch: {
    "form.hospital_code": {
      deep: true,
      immediate: true,
      handler: function (newVal) {
        // this.form.office_code = null;
        if (newVal) {
          this.$store
            .dispatch("bs_hospital/getList", {
              filter: {
                codenum: newVal,
              },
            })
            .then((res) => {
              this.hospitalId = res.data[0].id;
              this.form.hospital_name = res.data[0].name;
            });
        }
      },
    },
    // "form.office_code": {
    //   deep: true,
    //   immediate: true,
    //   handler: function (newVal) {
    //     if (newVal) {
    //       this.$store
    //         .dispatch("bs_office/getList", {
    //           filter: {
    //             codenum: newVal,
    //           },
    //         })
    //         .then((res) => {
    //           this.form.office_name = res.data[0].name;
    //         });
    //     }
    //   },
    // },
  },
  computed: {
    dept_filter() {
      if (this.hospitalId) {
        return { hospital_id: this.hospitalId, is_active: true };
      } else {
        return {};
      }
    },
  },
  methods: {
    async isSure() {
      if(this.id) {
        // this.form.office_code = this.item.office_code
      }
      if (
          !this.form.type_code ||
          !this.form.type_name ||
          !this.form.hospital_code
        ) {
          this.$message({
            message: "有必填项未填写",
            type: "warning",
          });
          return;
        }
      if (!this.id) {
        this.$store
          .dispatch("os_scheduling_type/create", {
            data: this.form,
          })
          .then((res) => {
            this.$emit("saved");
          });
      } else {
        this.$store
          .dispatch("os_scheduling_type/update", {
            id: this.id,
            data: this.form,
          })
          .then((res) => {
            this.$emit("saved");
          });
      }
    },
  },
};
</script>
<style scoped>
.btn_bg {
  width: 95%;
  position: fixed;
  bottom: 0px;
  padding: 10px 0;
  text-align: center;
  background-color: white;
}
::v-deep .el-input__inner {
  background-color: #f0f0f0 !important;
  border-color: rgba(255, 255, 255, 0.5);
  color: #707070;
  height: 60px;
}
</style>