<template>
  <base-material-card :icon="resource.icon" title="检查类型">
    <va-list :filters="filters" disable-global-search>
      <va-data-table :fields="fields">
      </va-data-table>
    </va-list>
  </base-material-card>
</template>

<script>
export default {
  props: ["resource", "title"],
  data() {
    return {
		filters: [ 'item_name' ],
		fields: [ 
			{ source: 'item_name', label: "名称", sortable: true, align: "center" },  
			{ source: 'remark', label: "备注", sortable: true, align: "center" },
			{ source: 'available', label: "是否启用", sortable: true, align: "center", type: "boolean" },
		],
    };
  },
};
</script>
