<template>
	<va-form :id="id" :item="item">
		<v-row justify="center">
			<v-col sm="6">
				<base-material-card>
					<template v-slot:heading>
						<div class="display-2">
							{{ title }}
						</div>
					</template>
					<v-card-text>
						<va-text-input source="device_code" v-model="form.device_code"></va-text-input>
						<va-text-input source="device_name" v-model="form.device_name"></va-text-input>
						<va-text-input source="device_addr" v-model="form.device_addr"></va-text-input>
						<v-select filled label="设备类型" item-value="value" item-text="text" :items="form.deviceTypes"
							v-model="form.device_type"></v-select>
						<!-- <v-text-field label="Enter number" type="number" v-model="form.device_type" @blur="validateNumber"></v-text-field> -->
						<va-boolean-input source="is_active" v-model="form.is_active"></va-boolean-input>
						<va-text-input source="remark" v-model="form.remark"></va-text-input>
					</v-card-text>
					<div class="btn_bg">
						<v-btn @click="isSure" color="primary">保存</v-btn>
						<v-btn @click="notSure" style="color: #14b2c5; margin-left: 50px">返回</v-btn>
					</div>
				</base-material-card>
			</v-col>
		</v-row>
	</va-form>
</template>

<script>
import { Parser } from 'graphql/language/parser';
export default {
	props: ["id", "title", "item"],
	data() {
		return {
			form: {
				device_code: "",
				device_name: "",
				device_addr: "",
				device_type: 1,
				deviceTypes: [],
				is_active: true,
				remark: "",
			}
		}
	},
	created() {
		this.fetchDeviceTypes();  // 在组件创建时调用
	},
	watch: {
		// "form.device_type": {
		// 	deep: true,
		// 	immediate: true,
		// 	handler: function (newVal) {
		// 		if (newVal) {
		// 		this.$store
		// 			.dispatch("ig_device_resource_type/getList", {
		// 				filter: {
		// 					typelist: newVal,
		// 				},
		// 			})
		// 			.then((res) => {
		// 				// this.hospitalId = res.data[0].id;
		// 				this.form.device_type = res.data[0].name;
		// 			});
		// 		}
		// 	},
		// },
	},
	methods: {
		fetchDeviceTypes() {
			this.$store.dispatch("ig_device_resource_type/getList", { /* appropriate params */ })
				.then((res) => {
					console.log('22', res);
					this.form.deviceTypes = res.data.map(item => ({
						text: item.name,  // 显示在下拉列表中的文字
						value: item.id    // 实际提交的值
					}));
					// 如果需要设置默认值
					if (res.data.length > 0) {
						this.form.device_type = res.data[0].value;
					}
				})
				.catch(error => {
					console.error("Failed to load device types:", error);
				});
		},
		async isSure() {
			console.log('022', this.form.device_type);
			// let formdata = Object.assign({}, this.xman);
			// formdata.device_type = parseInt(this.form.device_type);
			// console.log('023', formdata.device_type);
			if (this.form.device_type > 500 || this.form.device_type < 1) {
				this.form.device_type = '0';
				this.$message({
					message: "类型仅限输入数字，且大于0，小于500，请修改",
					type: "error",
				});
				return;
			}
			if (this.id) {
				try {
					await this.$store
						.dispatch("ig_device_resource/update", {
							id: this.id,
							data: this.form,
						})
						.then((res) => {
							this.$emit("saved");
							this.$router.push({
								path: "/ig-device-resource/"
							});
						});
				} catch {
					// this.$message({
					//   message: "医院编码违反唯一性，请修改",
					//   type: "error",
					// });
				}
			} else {
				await this.$store
					.dispatch("ig_device_resource/create", {
						data: this.form,
					})
					.then((res) => {
						this.$emit("saved");
						this.$router.push({
							path: "/ig-device-resource/"
						});
						// if (this.file_data && this.file_data.length >= 1) {
						//   this.httpRequest(this.imgfile, res.data.id, res.data.logo);
						// }
					})
					.catch((err) => {
						// this.$message({
						//   message: "医院编码违反唯一性，请修改",
						//   type: "error",
						// });
					});
			}
		},
		notSure() {
			this.$router.push(
				"/ig-device-resource" //跳转
			);
		},
		validateNumber() {
			// 确保输入的是整数
			if (this.numberInput !== null) {
				this.numberInput = parseInt(this.numberInput, 10);
			}
		}
	}
};
</script>
