import Vue from "vue";
import AdminLayout from "@/layouts/Admin";
import Dashboard from "@/views/Dashboard";
import Profile from "@/views/Profile";
import hisappoint from "@/views/appointmentmanagement/hisappoint";
import Appointmentmanagement from "@/views/appointmentmanagement/Index";
import AppointmentmanagementList from "@/views/appointmentmanagement/List";
import Change from "@/views/appointmentmanagement/Change";
import ApplicationFormChange from "@/views/applicationform/applychange";
import Reprint from "@/views/appointmentmanagement/Reprint";
import Print from "@/views/appointmentmanagement/print";
import Appoint from "@/views/medicaltechnician/appoint";
import Servicegroup from "@/views/medicaltechnician/servicegroup";
import Template from "@/views/medicaltechnician/template";
import Join from "@/views/medicaltechnician/Join";
import Joinitem from "@/views/medicaltechnician/JointimelineItem";
import Workingschedule from "@/views/medicaltechnician/workingschedule";
import Error from "@/views/Error";
import password from "@/views/password";
// import Settings from "@/views/Settings";
import Help from "@/views/Help";
import arrangeApt from "@/views/arrange_apt";

import Online from "@/views/online";
import inspect from "@/views/inspect";
import examine from "@/views/examine";
import review from "@/views/review";
import appointment from "@/views/appointment";
import detailed from "@/views/detailed";
import returnVisit from "@/views/returnVisit";
import phone from "@/views/phone";
import PushCopy from "@/views/PushCopy";
import Prescribing from "@/views/Prescribing";
import Reporting from "@/views/Reporting";
import circulation from "@/views/circulation";
import particulars from "@/views/particulars";
import inform from "@/views/inform";
import inspectionDetails from "@/views/inspectionDetails";
import examineDetails from "@/views/examineDetails";
// import ItemByGroupId from "@/views/examineDetails";
// import Expstandlist from "@/resources/bs-expense-standard/Expstandlist";
import i18n from "@/i18n";

/**
 * Error component
 */
Vue.component("Error", Error);

export default {
  path: "",
  component: AdminLayout,
  meta: {
    title: i18n.t("routes.home"),
  },
  children: [
    {
      path: "/dashboard",
      name: "dashboard",
      component: Dashboard,
      meta: {
        title: i18n.t("routes.dashboard"),
      },
    },
    {
      path: "/appointmentmanagement/hisappoint",
      name: "hisappoint",
      component: hisappoint,
      meta: {
        title: i18n.t("routes.appointmentmanagement.hisappoint"),
      },
    },
    {
      path: "/applicationform/applychange",
      name: "applychange",
      component: ApplicationFormChange,
      meta: {
        title: i18n.t("routes.applicationform.applychange"),
      },
    },
    {
      path: "/appointmentmanagement/Change",
      name: "change",
      component: Change,
      meta: {
        title: i18n.t("routes.appointmentmanagement.change"),
      },
    },
    {
      path: "/appointmentmanagement/Reprint",
      name: "reprint",
      component: Reprint,
      meta: {
        title: i18n.t("routes.appointmentmanagement.reprint"),
      },
    },
    {
      path: "/appointmentmanagement/print",
      name: "print",
      component: Print,
      meta: {
        title: i18n.t("routes.appointmentmanagement.print"),
      },
    },
    {
      path: "/appointmentmanagement/List",
      name: "appointmentmanagementlist",
      component: AppointmentmanagementList,
      meta: {
        title: i18n.t("routes.appointmentmanagement.appointmentmanagementlist"),
      },
    },
    {
      path: "/appointmentmanagement/Index",
      name: "appointmentmanagementindex",
      component: Appointmentmanagement,
      meta: {
        title: i18n.t("routes.appointmentmanagement.appointmentmanagementindex"),
      },
    },
    {
      path: "/medicaltechnician/appoint",
      name: "appoint",
      component: Appoint,
      meta: {
        title: i18n.t("routes.medicaltechnician.appoint"),
      },
    },
    {
      path: "/medicaltechnician/Join",
	  // path: '/medicaltechnician/Join/:group_id',
      name: "join",
      component: Join,
      meta: {
        title: i18n.t("routes.medicaltechnician.join"),
      },
    },
    {
      path: "/medicaltechnician/JointimelineItem",
      name: "joinitem",
      component: Joinitem,
      meta: {
        title: i18n.t("routes.medicaltechnician.join"),
      },
    },
    {
      path: "/medicaltechnician/servicegroup",
      name: "servicegroup",
      component: Servicegroup,
      meta: {
        title: i18n.t("routes.medicaltechnician.servicegroup"),
      },
    },
    {
      path: "/medicaltechnician/template",
      name: "template",
      component: Template,
      meta: {
        title: i18n.t("routes.medicaltechnician.template"),
      },
    },
    {
      path: "/medicaltechnician/workingschedule",
      name: "workingschedule",
      component: Workingschedule,
      meta: {
        title: i18n.t("routes.medicaltechnician.workingschedule"),
      },
    },
    {
      path: "/Profile",
      name: "Profile",
      component: Profile,
      meta: {
        title: i18n.t("routes.Profile"),
      },
    },
    {
      path: "/help",
      name: "help",
      component: Help,
      meta: {
        title: i18n.t("routes.help"),
      },
    },
    {
      path: "/arrangeApt",
      name: "arrangeApt",
      component: arrangeApt,
      meta: {
        title: i18n.t("routes.arrange-apt"),
      },
    },
    {
      path: "/Online",
      name: "Online",
      component: Online,
      meta: {
        title: i18n.t("routes.Online"),
      },
    },
    {
      path: "/inspect",
      name: "inspect",
      component: inspect,
      meta: {
        title: i18n.t("routes.inspect"),
      },
    },
    {
      path: "/examine",
      name: "examine",
      component: examine,
      meta: {
        title: i18n.t("routes.examine"),
      },
    },
    {
      path: "/review",
      name: "review",
      component: review,
      meta: {
        title: i18n.t("routes.review"),
      },
    },
    {
      path: "/detailed",
      name: "detailed",
      component: detailed,
      meta: {
        title: i18n.t("routes.detailed"),
      },
    },

    {
      path: "/return-visit",
      name: "returnVisit",
      component: returnVisit,
      meta: {
        title: i18n.t("routes.returnVisit"),
      },
    },
    {
      path: "/phone",
      name: "phone",
      component: phone,
      meta: {
        title: i18n.t("routes.phone"),
      },
    },
    {
      path: "/prescribing",
      name: "Prescribing",
      component: Prescribing,
      meta: {
        title: i18n.t("routes.Prescribing"),
      },
    },
    {
      path: "/PushCopy",
      name: "PushCopy",
      component: PushCopy,
      meta: {
        title: i18n.t("routes.PushCopy"),
      },
    },
    {
      path: "/password",
      name: "password",
      component: password,
      meta: {
        title: i18n.t("routes.password"),
      },
    },
    {
      path: "/appointment",
      name: "appointment",
      component: appointment,
      meta: {
        title: i18n.t("routes.appointment"),
      },
    },
    {
      path: "/circulation",
      name: "circulation",
      component: circulation,
      meta: {
        title: i18n.t("routes.circulation"),
      },
    },
    {
      path: "/particulars",
      name: "particulars",
      component: particulars,
      meta: {
        title: i18n.t("routes.particulars"),
      },
    },
    {
      path: "/inform",
      name: "inform",
      component: inform,
      meta: {
        title: i18n.t("routes.inform"),
      },
    },
    {
      path: "/inspection-details",
      name: "inspectionDetails",
      component: inspectionDetails,
      meta: {
        title: i18n.t("routes.inspectionDetails"),
      },
    },
    {
      path: "/examine-details",
      name: "examineDetails",
      component: examineDetails,
      meta: {
        title: i18n.t("routes.examineDetails"),
      },
    },
	// {
	//     path: '/items/:group_id',  // `:group_id` 是路由参数
	//     component: ItemByGroupId,
	//     name: 'itemDetails'
	// },
    // {
    //   path: "/Expstandlist",
    //   name: "Expstandlist",
    //   component: Expstandlist,
    //   meta: {
    //     title: i18n.t("routes.Expstandlist"),
    //   },
    // },
    // {
    //   path: "/addNew",
    //   name: "addNew",
    //   component:()=>import('../../src/resources/ws-schedule-day/addNew.vue') ,
    //   meta: {
    //     // title: i18n.t("routes.Expstandlist"),
    //   },
    // },

    {
      path: "*",
      component: Error,
      meta: {
        title: i18n.t("routes.not_found"),
      },
    },
  ],
};
