<template>
	<div>
		<va-aside-layout :title="asideTitle">
			<opr-operating-room-apt-show v-if="show" :item="item">

			</opr-operating-room-apt-show>
			<opr-operating-room-apt-form v-else :id="id" width="500px" :item="item" @saved="onSaved" ref="form">

			</opr-operating-room-apt-form>
		</va-aside-layout>
		<v-card :icon="resource.icon" :title="title">
			<va-list disable-global-search disable-export ref="list" disable-pagination @action="onAction"
				:items-per-page="10" disable-create :include="['bs_hospital']" :sort-by="['created_time']"
				:sort-desc="[true]">
				<template v-slot:actions>
					<v-btn-toggle class="custom_btn" v-model="toggle_release" mandatory color="#214578">
						<v-btn> 待预约</v-btn>
						<v-btn> 已预约 </v-btn>
					</v-btn-toggle>
					<va-action-button color="green" icon="mdi-plus" v-if="toggle_release == 1 ? false : true"
						label="新建预约" @click="addRommapt" text>
					</va-action-button>
				</template>
				<div class="divBox">
					<el-select class="select" style="margin: 0 20px 10px" :clearable="true" filterable
						v-model="hospital_data" @change="changeSearch()" placeholder="请选择医院">
						<el-option v-for="item in options0" :key="item.id" :label="item.name" :value="item.codenum">
						</el-option>
					</el-select>

					<el-date-picker class="select" v-model="date_value" type="daterange" start-placeholder="开始日期"
						@change="changeSearch()" end-placeholder="结束日期" v-if="toggle_release == 1"
						:default-time="['00:00:00', '23:59:59']">
					</el-date-picker>
				</div>

				<!-- <template v-slot:[`filter.apt_date@_gte`]="props">
          <el-date-picker
            class="select"
            size="small"
            v-bind="props"
            v-model="date_value"
            type="daterange"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </template> -->
				<!-- <template v-slot:actions>
          <va-action-button
            icon="mdi-briefcase-download-outline"
            label="下载模版"
            :href="template_download_url"
            color="green"
            text
          >
          </va-action-button>
          <va-action-button
            icon="mdi-import"
            color="green"
            label="导入"
            @click="on_show_import"
            text
          >
          </va-action-button>
          <va-action-button
            icon="mdi-arrow-collapse-down"
            color="green"
            label="导出"
            text
            @click="on_export_excel"
          >
          </va-action-button>
        </template> -->
				<template v-if="toggle_release == 0">
					<v-card>
						<v-data-table :headers="headers0" :items="table_data">
							<template v-slot:[`item.actions`]="{ item }">
								<v-tooltip v-if="$admin.can(['change_operatingroomappointment'])" bottom>
									<template v-slot:activator="{ on, attrs }">
										<v-chip class="ma-2" color="#069CAE" @click="passList(item)" v-bind="attrs"
											v-on="on" text-color="white">
											预约
										</v-chip>
									</template>
									<span>预约</span>
								</v-tooltip>
							</template>
						</v-data-table>
					</v-card>
				</template>
				<template v-if="toggle_release == 1">
					<v-card>
						<v-data-table :headers="headers1" :items="table_data">
							<template v-slot:[`item.apt_date`]="{ item }">
								<template>
									<span>{{
										item.apt_date + "-" + item.start_time + "-" + item.end_time
									}}</span>
								</template>
							</template>

							<template v-slot:[`item.state`]="{ item }">
								<template v-if="item.state == 1">
									<v-chip class="ma-2" color="green" text-color="white">
										已预约
									</v-chip>
								</template>
								<template v-else-if="item.state == 2">
									<v-chip class="ma-2" color="green" text-color="white">
										已开始
									</v-chip></template><template v-else-if="item.state == 3">
									<v-chip class="ma-2" color="red" text-color="white">
										已取消
									</v-chip>
								</template>
								<template v-else-if="item.state == 4">
									<v-chip class="ma-2" color="red" text-color="white">
										已完成
									</v-chip>
								</template>
							</template>
							<template v-slot:[`item.actions`]="{ item }">
								<!-- <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      class="mr-2"
                      color="blue"
                      @click="CreatItem(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-pencil
                    </v-icon>
                  </template>
<span>编辑</span>
</v-tooltip> -->
								<v-tooltip bottom v-if="
									itemShow(item) &&
									$admin.can(['change_operatingroomappointment'])
								">
									<template v-slot:activator="{ on, attrs }">
										<v-icon small class="mr-2" color="red" @click="deleteItem(item)" v-bind="attrs"
											v-on="on">
											mdi-archive-remove-outline
										</v-icon>
									</template>
									<span>取消预约</span>
								</v-tooltip>
							</template>
						</v-data-table>
					</v-card>
				</template>
				<!-- <va-data-table
          disableShow
          disableClone
          :fields="fields"
          disable-create-redirect
          disable-show-redirect
          disable-delete-redirect
          @item-action="onAction"
          disable-delete
          disable-select
        >
      
        </va-data-table> -->
			</va-list>
		</v-card>
	</div>
</template>

<script>
import moment from "moment";

export default {
	props: ["resource", "title"],
	data() {
		return {
			// 模板下载地址
			// template_download_url: `${this.global.upload_url}excel/office_import.xlsx`,
			// 弹出侧边栏
			toggle_release: 0,
			asideTitle: null,
			id: null,
			item: null,
			table_data: [{
				id_num: "123",
				patient_name: "张三",
				opr_content: "急性阑尾炎",
				hospital_name: "郑州市第五人民医院",
			},
			{
				id_num: "123",
				patient_name: "张三",
				opr_content: "急性阑尾炎",
				hospital_name: "郑州市第五人民医院",
			},
			{
				id_num: "123",
				patient_name: "张三",
				opr_content: "急性阑尾炎",
				hospital_name: "郑州市第五人民医院",
			},
			],
			data: null,
			show: false,
			hospital_data: null,
			date_value: "",
			options0: [],
			// filters: [
			//   {
			//     alwaysOn: true,
			//     label: "所属医院",
			//     source: "hospital_name",
			//     type: "text",
			//   },
			//   {
			//     source: "apt_date@_gte",
			//     alwaysOn: true,
			//   },
			// ],
			headers0: [{
				value: "patient_name",
				text: "患者姓名",
				sortable: false,
				align: "center",
			},
			{
				value: "id_num",
				text: "身份证号",
				sortable: false,
				align: "center",
			},
			{
				value: "opr_content",
				text: "手术名称",
				sortable: false,
				align: "center",
			},
			{
				value: "hospital_name",
				text: "预约医院",
				sortable: false,
				align: "center",
			},
			{
				text: "操作",
				value: "actions",
				sortable: false,
				align: "center"
			},
			],
			headers1: [{
				value: "patient_name",
				text: "患者姓名",
				sortable: false,
				align: "center",
			},
			{
				value: "id_num",
				text: "身份证号",
				sortable: false,
				align: "center",
			},
			{
				value: "hospital_name",
				text: "预约医院",
				sortable: false,
				align: "center",
			},
			{
				value: "opr_name",
				text: "手术室",
				sortable: false,
				align: "center",
			},
			// planned_admission_time": "2023-05-18T00:00:00+08:00"
			{
				value: "apt_date",
				text: "预约时间",

				align: "center",
			},

			{
				value: "state",
				text: "状态",
				sortable: false,
				align: "center",
			},
			{
				text: "操作",
				value: "actions",
				align: "center"
			},
			],
			user_data: null,
			table_data_id: [],
			objF: {},
		};
	},
	mounted() {
		this.$store.dispatch("opr_operating_room_apt/getList").then((res) => {
			this.data = res;
		});
	},
	created() {
		this.user_data = JSON.parse(window.sessionStorage.getItem("user"));
		this.hospital_data = this.user_data.hospital_code;
		this.getSelect();
		// this.changeSearch();
	},
	watch: {
		date_value: {
			deep: true,
			handler: function (newVal) {
				if (newVal) {
					this.table_data_id = [];
					let value1 = moment(newVal[0]).format("YYYY-MM-DD");
					let value2 = moment(newVal[1]).format("YYYY-MM-DD");
					this.data.data.forEach((item) => {
						if (
							moment(item.apt_date).format("YYYY-MM-DD") >= value1 &&
							moment(item.apt_date).format("YYYY-MM-DD") <= value2
						) {
							this.table_data_id.push(item.id);
						}

						if (this.table_data_id.length == 0) {
							this.table_data_id = [0];
						}
					});
				} else {
					delete this.objF.id;
				}
				console.log(this.table_data_id);
			},
		},
		toggle_release: {
			deep: true,
			handler: function (newVal) {
				this.table_data = [];
				if (newVal == 1) {
					this.changeSearch(newVal);
				} else {
					this.table_data = [{
						id_num: "123",
						patient_name: "张三",
						opr_content: "急性阑尾炎",
						hospital_name: "郑州市第五人民医院",
					},
					{
						id_num: "123",
						patient_name: "张三",
						opr_content: "急性阑尾炎",
						hospital_name: "郑州市第五人民医院",
					},
					{
						id_num: "123",
						patient_name: "张三",
						opr_content: "急性阑尾炎",
						hospital_name: "郑州市第五人民医院",
					},
					];
				}
			},
		},
	},

	methods: {
		async onAction({
			action,
			title,
			id,
			item
		}) {
			this.asideTitle = title;
			this.id = id;
			this.show = action === "show";
			this.item = item;
			if (action === "create") {
				this.id = null;
			}
		},
		addRommapt() {
			this.$router.push("/opr-operating-room-apt/create");
		},
		onSaved() {
			this.asideTitle = null;
			this.$refs.list.fetchData();
			this.changeSearch();
		},
		getSelect() {
			this.$store.dispatch("bs_hospital/getList").then((res) => {
				this.options0 = res.data;
			});
		},
		changeSearch() {
			this.objF = {};
			// if (item) {
			//   objF.state = item;
			// } else {
			//   objF.state = this.toggle_release;
			// }
			if (this.table_data_id.length) {
				this.objF.id = this.table_data_id;
			}
			if (this.toggle_release) {
				if (this.hospital_data) {
					this.objF.hospital_code = this.hospital_data;
				}
				this.$store
					.dispatch("opr_operating_room_apt/getList", {
						filter: this.objF,
					})
					.then((res) => {
						this.table_data = res.data;
					});
			} else {
				// 待预约筛选功能
				// this.table_data.map((item,index) => {
				//   console.log(item,index);
				//   return  == item.hospital_name;
				// });
			}
		},
		deleteItem(item) {
			this.$confirm("此操作将取消预约，是否继续?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			}).then(() => {
				item.state = "3";
				this.$admin.http
					.put(`operating-room/appointment/${item.id}/`, item)
					.then((res) => {
						this.onSaved();
					});
				// .then((res) => {
				//
				// });
			});
			//   this.$store
			//     .dispatch("opr_operating_room_apt/delete", {
			//       id: item.id,
			//     })
			//     .then((res) => {
			//       this.onSaved()
			//       // this.getSelect();
			//     });
			// })
		},
		CreatItem(item) {
			this.$router.push(`/opr-operating-room-apt/${item.id}/edit`);
		},
		passList(items) {
			// {
			//   path: "/ws-doctor-work-scheduling-main-recheck",
			//   query: { doctor_id: this.doctor_id },
			// }
			this.$router.push({
				path: "/opr-operating-room-apt/create",
				query: {
					items: items
				},
			});
		},
		// 导入
		on_show_import() { },
		// 导出
		on_export_exce() { },
		itemShow(item) {
			console.log(item, "item");

			// debugger;
			const timeDate = `${item.apt_date} ${item.start_time}`;
			let timestamp = parseInt(+new Date(timeDate) / 1000);
			let dateTime = parseInt(+new Date() / 1000);
			// const aptDate = moment(item.apt_date, "yyyy-MM-dd");
			console.log(timestamp, "item");
			console.log(dateTime, "item");

			return item.state == 1 && dateTime < timestamp;
		},
	},
};
</script>
<!-- <style lang="sass" scoped>
	.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) background-color: #eee color: #586AFC &:hover background-color: #ccc color: #000 .navInput width: 12rem height: 2.4rem border-bottom: 1px solid rgba(0, 0, 0, 0.15) border-radius: 3px cursor: pointer background: #fff padding-top: 0.6rem color: #444 font-size: 14px font-family: SourceHanSansCN-Normal,
	SourceHanSansCN font-weight: 400::v-deep .el-input__inner border-top: none !important border-left: none !important border-right: none !important border-bottom: 1px #D2D2D2 solid !important border-radius: 0 padding-bottom: 10px
</style> -->
<style scoped>
::v-deep .el-input__inner {
	border-top: none !important;
	border-left: none !important;
	border-right: none !important;
	border-bottom: 1px #d2d2d2 solid !important;
	border-radius: 0;
	padding-bottom: 10px;
}

.select .el-icon-circle-close,
.el-input__prefix {
	display: block !important;
}

.custom_btn.v-item-group {
	left: 0;
	position: absolute;
}

.divBox {
	display: flex;
	align-items: flex-start;
}
</style>