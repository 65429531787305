<template>
  <os-outpatient-registration-list
    :resource="resource"
    :type="2"
  ></os-outpatient-registration-list>
</template>

<script>
export default {
  props: ["resource"],
  data() {
    return {
      title: "检验排班",
    };
  },
};
</script>