<!--
 * @Description: 
 * @Author: your name
 * @Date: 2023-04-27 14:54:55
 * @LastEditors: your name
 * @LastEditTime: 2023-06-01 16:26:40
-->
<template>
  <va-form>
    <v-card class="v_card">
      <div style="line-height: 60px">
        <span class="dot_circle"></span><span class="title_con">患者信息</span>
      </div>
      <v-row>
        <v-col sm="4">
          <v-text-field label="身份证号" source="id_num" v-model="form.id_num" :disabled="isReadOnly" :rules="rules"
            filled></v-text-field>
        </v-col>

        <v-col sm="4">
          <v-text-field label="患者姓名" source="patient_name" :rules="rules1" :disabled="isReadOnly"
            v-model="form.patient_name" filled></v-text-field>
        </v-col>
      </v-row>
    </v-card>
    <v-card class="v_card" style="overflow-y: scroll; padding-bottom: 80px">
      <div style="line-height: 60px">
        <span class="dot_circle"></span><span class="title_con">手术室信息</span>
      </div>
      <v-row>
        <v-col sm="4">
          <va-select-input label="预约医院" item-value="codenum" reference="bs_hospital" :items-per-page="1000"
            v-model="form.hospital_code"></va-select-input>
        </v-col>
        <v-col sm="4">
          <SelectInput item-value="opr_code" item-text="opr_name" v-model="form.opr_code"
            reference="opr_operating_room_management" label="手术室" :items-per-page="1000" ref="dept"
            :filter="opr_filter"></SelectInput>
        </v-col>
        <v-col sm="4">
          <el-date-picker class="select" lable="时间" :picker-options="pickerOptions" size="large" format="yyyy-MM-dd"
            value-format="yyyy-MM-dd" v-model="date_value" type="date" placeholder="请选择日期">
          </el-date-picker>
        </v-col>
        <v-col sm="4">
          <el-time-picker style="
              width: 100%;
              height: 58px;
              line-height: 58px;
              background-color: #f0f0f0;
              text-align: center;
            " is-range size="medium" v-model="date_value1" format="HH:mm" value-format="HH:mm" start-placeholder="开始时间"
            end-placeholder="结束时间" placeholder="选择时间范围">
          </el-time-picker>
          <!-- <el-date-picker
            class="select"
            lable="时间"
            size="large"
            v-model="date_value1"
            type="datetime"
            placeholder="选择结束时间"
          >
          </el-date-picker> -->
        </v-col>
      </v-row>
      <v-row v-if="isShow">
        <v-col sm="2"></v-col>
        <v-col sm="8">
          <table>
            <tr>
              <th v-for="(item, i) in oprList" :key="i" class="boxTh">
                {{ item.opr_name }}
              </th>
            </tr>
            <tr>
              <td class="boxTdone" v-for="(item, i) in oprList" :key="i">
                <div v-if="item.apt_records.length">
                  <div v-for="(items, is) in item.apt_records" class="tdDivCon" :key="is" @click="updataObj(items)"
                    :class="{ select: items.id === form.id }">
                    <p>{{ items.patient_name }}</p>
                    <p>
                      {{ items.apt_date }}
                    </p>
                    <p>{{ items.start_time + "-" + items.end_time }}</p>
                  </div>
                </div>
                <div style="text-align: center" v-else>暂无数据</div>
              </td>
            </tr>

            <!-- <tr>
              <td class="boxTdone">数据4</td>
            </tr> -->
          </table>
        </v-col>
      </v-row>
    </v-card>

    <div class="btn_bg">
      <v-btn @click="close" style="color: #42b6c4; margin-right: 50px">取消</v-btn>
      <v-btn @click="isSure(1)" style="margin-right: 50px" color="primary" v-if="isShowbtn">保存</v-btn>
      <v-btn @click="isSure(2)" color="primary" v-if="isShowbtn">保存并返回</v-btn>
    </div>
  </va-form>
</template>

<script>
import moment from "moment";
import SelectInput from "@/components/VaCustomSelectInput.vue";
export default {
  components: {
    SelectInput,
  },
  data() {
    return {
      selectedItem: this.$route.query.selectedItem,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      form: {
        hospital_code: "",
        hospital_name: "",
        id_num: "",
        patient_name: "",
        is_active: true,
        opr_code: "",
        opr_name: "",
        apt_date: "",
        start_time: "",
        end_time: "",
        noontype_code: "",
        state: "1",
      },
      minTime: moment().format("YYYY-MM-DD"),
      date_value1: [
        moment().format("HH:mm"),
        moment().add(1, "hours").format("HH:mm"),
      ],
      date_value: "",
      bedList: [],
      oprList: [],
      rules: [(v) => (v && v.length <= 18) || "最多只能输入 18 个字符"],
      rules1: [(v) => (v && v.length <= 5) || "最多只能输入 5 个字符"],
    };
  },
  beforeDestroy() { },
  methods: {
    isSure(number) {
      this.form.apt_date = this.date_value;
      this.form.start_time = this.date_value1[0];
      this.form.end_time = this.date_value1[1];
      if (
        !this.form.hospital_code ||
        !this.form.hospital_name ||
        !this.form.opr_code ||
        !this.form.opr_name ||
        !this.form.apt_date ||
        !this.form.start_time ||
        !this.form.end_time ||
        !this.form.id_num ||
        !this.form.patient_name
      ) {
        this.$message({
          message: "有必填项未填写",
          type: "warning",
        });
        return;
      }
      this.$admin.http
        .put(`operating-room/appointment/${this.form.id}/`, this.form)
        .then((res) => {
          if (number == 1) {
            this.getContent();
          } else {
            this.close();
          }
        })
        // this.$admin.http
        //   .post("operating-room/appointment/", this.form)
        //   .then((res) => {
        //      if(number == 1){
        //        this.getContent()
        //      }else{
        //       this.close()
        //      }
        //   })
        .catch((err) => {
          this.$message({
            message: "保存失败",
            type: "error",
          });
        });
    },
    close() {
      this.$router.push("/opr-operating-room-apt-arrange");
    },
    getContent() {
      let data = {};
      data.apt_date = this.date_value;
      data.hospital_code = this.form.hospital_code;
      data.state = 1;
      // ?apt_date=${newValue}&hospital_code=${this.form.hospital_code}
      this.$admin.http
        .post("operating-room/appointment/appoint_record/", data)
        .then((res) => {
          this.oprList = res.data;
          this.oprList.filter((item) => item.apt_records.length);
        });
    },
    updataObj(item) {
      this.form = item;
      this.date_value = this.form.apt_date;
      this.date_value1 = [this.form.start_time, this.form.end_time];
    },
  },
  computed: {
    isReadOnly() {
      if (this.form.hospital_name) {
        return true;
      } else {
        return false;
      }
    },
    opr_filter() {
      if (this.form.hospital_code) {
        return { hospital_code: this.form.hospital_code, is_active: true };
      } else {
        return {};
      }
    },
    isShowbtn() {
      if (this.form.state == 1) {
        return true;
      } else {
        return false;
      }
    },
    isShow() {
      if (this.date_value) {
        return true;
      } else {
        return false;
      }
    },
    // filteredList() {
    //   return this.oprList.filter((item) => item.apt_records.length);
    // },
  },
  watch: {
    "form.hospital_code": {
      deep: true,
      immediate: false,
      handler(newValue, oldValue) {
        if (newValue) {
          this.getContent();
          this.$store
            .dispatch("bs_hospital/getList", {
              filter: {
                codenum: newValue,
              },
            })
            .then((res) => {
              this.form.hospital_name = res.data[0].name;
            });
        }
      },
    },
    "form.opr_code": {
      deep: true,
      immediate: false,
      handler(newValue, oldValue) {
        if (newValue) {
          this.$store
            .dispatch("opr_operating_room_management/getList", {
              filter: {
                opr_code: newValue,
              },
            })
            .then((res) => {
              this.form.opr_name = res.data[0].opr_name;
            });
        }
      },
    },
    date_value: {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue) {
          // if (!this.form.hospital_code) {
          //   this.$message({
          //     message: "请选择医院",
          //     type: "warning",
          //   });
          //   return;
          // }
          this.getContent();
        }
      },
    },
  },
  created() { },
  mounted() {
    if (this.$route.query.selectedItem) {
      this.form = JSON.parse(this.$route.query.selectedItem);
      this.date_value = this.form.apt_date;
      this.date_value1 = [this.form.start_time, this.form.end_time];
    }
  },
};
</script>
<style scoped>
.btn_bg {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0px;
  padding: 10px 10px;
  background-color: white;
}

.v_card {
  padding: 20px;
  margin-bottom: 20px;
}

.red_sp {
  color: red;
}

.dot_circle {
  display: inline-block;
  background-color: #069cae;
  width: 8px;
  height: 8px;
  margin-right: 10px;
  border-radius: 8px;
  margin-bottom: 2px;
}

.title_con {
  display: inline-block;
  font-size: 20px;
}

.inputBoxs {
  width: 100%;
  height: 50px;
  background-color: #f0f0f0;
  padding: 5px;
}

.inputBox {
  width: 100%;
  height: 50px;
  background-color: #f0f0f0;
  padding: 5px;
  box-sizing: border-box;
  margin: 0px 10px;
}

.vColBox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.select {
  background-color: #f0f0f0 !important;
  width: 100% !important;
}

::v-deep .select .el-input__inner {
  padding: 28px !important;
  background-color: #f0f0f0 !important;
}

:v-deep .select .el-range-input {
  padding: 28px !important;
  background-color: #f0f0f0 !important;
}

table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}

.boxTh {
  width: 200px;
  height: 50px;
  font-size: 20px;
  font-weight: 600;
}

.boxTdone {
  font-size: 18px;
}

.tdDivCon {
  width: 100%;
  border-bottom: 1px solid #000;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
}

.tdDivCon p {
  margin-bottom: 5px;
}

.boxTdone .tdDivCon:last-child {
  border-bottom: none;
}

.select {
  background-color: #f9f9f9;
  color: #14b2c5;
}
</style>