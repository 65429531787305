<template>
  <base-material-card :icon="resource.icon" :title="title">
    <va-list
      :filters="filters"
    >
      <va-data-table :fields="fields">
      </va-data-table>
    </va-list>
  </base-material-card>
</template>

<script>
export default {
  props: ["resource", "title"],
  data() {
    return {
      filters: [
  'template_time_line_name\t时间线名称',
  'template_time_line_code\t时间线编码',
  'item_code\t项目编码',
  'item_name\t项目名称'
],
      fields: [
  { source: 'template_time_line_name\t时间线名称', sortable: true },
  { source: 'template_time_line_code\t时间线编码', sortable: true },
  { source: 'item_code\t项目编码', sortable: true },
  { source: 'item_name\t项目名称', sortable: true },
  'create_time\t创建时间',
  'remark\t备注'
],
    };
  },
};
</script>
